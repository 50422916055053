import React, { FunctionComponent, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import LeftCell from '../../../../../../layouts/table/LeftCell';
import CenterCell from '../../../../../../layouts/table/CenterCell';
import { crudRights as crud } from '../../../../../../types/accessRights';

interface OwnProps {
  check: (rights: string[], right: crud) => ReactNode;
  entity: {
    section: string;
    children: string[];
  };
  rights: {
    [key: string]: string[];
  };
}

type Props = OwnProps;

const ChildrenRights: FunctionComponent<Props> = ({ entity, rights, check }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {entity.children.map((childEntity, i) => (
        <React.Fragment>
          {!isEmpty(rights[childEntity]) && (
            <Table.Row key={i}>
              <LeftCell content={`- ${t(`identityRights${childEntity}`)}`} />
              <CenterCell content={check(rights[childEntity], crud.create)} />
              <CenterCell content={check(rights[childEntity], crud.read)} />
              <CenterCell content={check(rights[childEntity], crud.update)} />
              <CenterCell content={check(rights[childEntity], crud.delete)} />
            </Table.Row>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default ChildrenRights;
