/* eslint-disable */
import React from 'react';
import { Dropdown, Table } from 'semantic-ui-react';
import classnames from 'classnames';

const Header = ({ selectedMonth, months, kpi, onChange }) => {
  const options = months.map(month => ({
    text: month.label,
    value: `?start=${month.start}&end=${month.end}`,
    key: `${month.start}-${month.end}`,
  }));

  return (
    <Table basic="very" className="table-borderless">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
          />
          {kpi.map((value, index) => (
            <Table.HeaderCell
              key={index}
              style={{
                width: '100px',
              }}
            >
              <div>
                <strong>{value.mainLabel}</strong>
                <br />
                {value.subLabel}
              </div>
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell
            style={{
              width: '25%',
            }}
          />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell
            style={{
              width: '150px',
            }}
          >
            <Dropdown
              options={options}
              value={selectedMonth}
              selectOnBlur={false}
              onChange={(e, { value }) => onChange(value)}
            />
          </Table.Cell>
          {kpi.map((value, index) => (
            <Table.Cell key={index}>
              <div>
                <strong
                  className={classnames({
                    positive: value.color && !value.mainValue.startsWith('-'),
                    negative: value.color && value.mainValue.startsWith('-'),
                  })}
                >
                  {value.mainValue}
                </strong>
                {/* <br />
                <span
                  className={classnames({
                    positive: value.color && !value.mainValue.startsWith('-'),
                    negative: value.color && value.mainValue.startsWith('-'),
                  })}
                >
                  {value.subValue}
                </span>
                */}
              </div>
            </Table.Cell>
          ))}
          <Table.Cell />
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default Header;
