import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reset as resetEmployeeData, retrieve as retrieveEmployeeData } from 'actions/employee-data/show';
import { toast } from 'components';

import CheckRoute from 'components/access/CheckRoute';
import { crudRights, Entities } from 'types/accessRights';
import ShowEmployeeData from './synthesis/show';
import EditEmployeeData from './synthesis/edit';
import ShowEmployeeSchedule from './schedule/show';
import EditEmployeeSchedule from './schedule/edit';
import NotFound from '../../../404';

class ViewEmployee extends Component {
  state = {
    selectedFiscalYear: true,
  };

  componentDidMount() {
    const { getEmployeeData, selectedEmployee, selectedFiscalYear } = this.props;

    if (!isEmpty(selectedFiscalYear)) {
      getEmployeeData(`/employee_datas?employee=${selectedEmployee.id}&fiscalYear=${selectedFiscalYear.id}`);
    } else {
      this.setState({
        selectedFiscalYear: false,
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { t } = this.props;
    const { selectedFiscalYear } = this.state;

    if (!selectedFiscalYear) {
      toast.warning(t('mustCreateFiscalYear'));
      return (
        <Redirect to="/company/fiscal_years/create" />
      );
    }

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/employees/:id/data-synthesis" component={ShowEmployeeData} />
          <Route exact path="/employees/:id/work-schedule" component={ShowEmployeeSchedule} />
          <CheckRoute
            exact
            path="/employees/:id/data-synthesis/edit"
            component={EditEmployeeData}
            entity={Entities.employeeData}
            right={crudRights.update}
          />
          <CheckRoute
            exact
            path="/employees/:id/work-schedule/edit"
            component={EditEmployeeSchedule}
            entity={Entities.employee}
            right={crudRights.update}
          />
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEmployeeData: page => dispatch(retrieveEmployeeData(page)),
  reset: () => dispatch(resetEmployeeData()),
});

const mapStateToProps = state => ({
  selectedEmployee: state.userCompanies.select.selectedEmployee,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);

export default withTranslation()(withRouter(Main));
