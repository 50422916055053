import React from 'react';
import { Image } from 'semantic-ui-react';
import uploadDefaultImage from 'assets/images/uploadDefault.png';

function CompanyLogo(props) {
  const { loadingCompanySettings, contactInformation } = props;

  return (loadingCompanySettings
    ? (
      <div style={{
        width: '182px',
      }}
      >
        <Image src={uploadDefaultImage} />
      </div>
    )
    : (
      <React.Fragment>
        {(contactInformation && contactInformation.picture)
        && (
        <div style={{
          width: '182px',
        }}
        >
          <Image src={contactInformation.picture} />
        </div>
        )}
      </React.Fragment>
    ));
}

export default CompanyLogo;
