import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Switch, withRouter } from 'react-router-dom';
import { DashboardAdmin } from 'layouts';
import { connect } from 'react-redux';
import { error, loading, refresh } from 'actions/auth/auth';
import { retrieve } from 'actions/identity/update';
import PrivateRoute from 'routes/admin/PrivateRoute';
import { ToastContainer } from 'react-toastify';

const EXPIRE_TIME = 86400;

class Admin extends Component {
  state = {
    isAuth: null,
    isExpired: false,
    isAuthenticating: false,
  };

  componentDidMount() {
    const { refreshToken, success, userID, retrieveIdentity } = this.props;

    if (success) {
      const initialDate = new Date(success.date);
      const diff = Math.abs(new Date() - initialDate) / 1000;

      if (diff > EXPIRE_TIME) {
        refreshToken({
          refreshToken: success.refreshToken,
        });
      }
    }

    if (userID) {
      retrieveIdentity(`/identities/${userID}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success && (nextProps.success !== prevState.isAuth)) {
      const initialDate = new Date(nextProps.success.date);
      const diff = Math.abs(new Date() - initialDate) / 1000;

      return {
        isAuth: nextProps.success,
        isExpired: diff > EXPIRE_TIME,
        isAuthenticating: diff > EXPIRE_TIME,
      };
    }

    if (prevState.isExpired === false && prevState.isAuth && prevState.isAuthenticating === false) {
      const initialDate = new Date(prevState.isAuth.date);
      const diff = Math.abs(new Date() - initialDate) / 1000;

      if (diff > EXPIRE_TIME && !nextProps.error) {
        nextProps.refreshToken({
          refreshToken: prevState.isAuth.refreshToken,
        });

        return {
          isAuthenticating: true,
          isExpired: true,
        };
      }
    }

    if (!nextProps.success && (nextProps.success !== prevState.isAuth)) {
      return {
        isAuth: nextProps.success,
        isAuthenticating: false,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { refreshToken, error, userID, retrieveIdentity } = this.props;

    if (prevState.isExpired === false) {
      const initialDate = new Date(prevState.isAuth.date);
      const diff = Math.abs(new Date() - initialDate) / 1000;

      if (diff > EXPIRE_TIME && !error) {
        this.setState({ isExpired: true }); // eslint-disable-line
        refreshToken({
          refreshToken: prevState.isAuth.refreshToken,
        });
      }
    }

    if (prevProps.userID !== userID && userID) {
      retrieveIdentity(`/identities/${userID}`);
    }
  }

  render() {
    const { isAuth, isExpired } = this.state;

    return (
      <DashboardAdmin>
        <Switch>
          <PrivateRoute
            path="/"
            isAuth={!isEmpty(isAuth)}
            isExpired={isExpired}
          />
        </Switch>
        <ToastContainer
          position="bottom-right"
          draggable={false}
          toastClassName="custom-toast-container"
          bodyClassName="custom-toast-body"
        />
      </DashboardAdmin>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  refreshToken: data => dispatch(refresh(data)),
  retrieveIdentity: iri => dispatch(retrieve(iri)),
  reset: () => {
    dispatch(loading(false));
    dispatch(error(null));
  },
});

const mapStateToProps = state => ({
  success: state.auth.created,
  error: state.auth.error,
  userID: state.userCompanies.role.userID,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default withRouter(Main);
