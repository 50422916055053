import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { KpiDTO } from '../../types/dto';
import FormatCell from '../cell/FormatCell';

interface OwnProps {
  data: KpiDTO[];
  title: string;
}

type Props = OwnProps;

const KeyValueTable: FunctionComponent<Props> = ({ title, data }) => {
  const { t } = useTranslation();

  return (
    <Table striped className="table-dashboard">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>
            {title}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isEmpty(data) && data.map((item, index) => (
          <Table.Row key={index}>
            <Table.Cell>{t(item.label)}</Table.Cell>
            <FormatCell color={item.color}>{item.value}</FormatCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default KeyValueTable;
