import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listModel, reset as resetModelList } from 'actions/document-model/list';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/business/routes';
import { crudRights, Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ShowModel from './show';
import EditModel from './edit';
import ListModel from './list';

class Model extends Component {
  componentDidMount() {
    const { getDocumentModels, selectedCompany, selectDocument } = this.props;

    selectDocument(null);
    getDocumentModels(`/document_models?company=${selectedCompany.id}&pagination=false`);
  }

  componentDidUpdate(prevProps) {
    const { updated, created, getDocumentModels, selectedCompany } = this.props;

    if (
      (!isEmpty(updated) && updated !== prevProps.updated)
      || (!isEmpty(created) && created !== prevProps.created)
    ) {
      getDocumentModels(`/document_models?company=${selectedCompany.id}`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { dataDocumentModel, loadingDocumentModel } = this.props;

    if (loadingDocumentModel || isEmpty(dataDocumentModel)) {
      return <ContainerGeneral status={loadingDocumentModel} />;
    }

    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.documentModel}
          path={Routes.listDocumentModels}
          component={ListModel}
        />
        <CheckRoute
          exact
          entity={Entities.documentModel}
          right={crudRights.edit}
          path={Routes.editDocumentModel}
          component={EditModel}
        />
        <CheckRoute
          exact
          entity={Entities.documentModel}
          path={Routes.showDocumentModel}
          component={ShowModel}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDocumentModels: page => dispatch(listModel(page)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset: () => {
    dispatch(resetModelList());
  },
});

const mapStateToProps = state => ({
  dataDocumentModel: state.model.list.data,
  loadingDocumentModel: state.model.list.loading,
  errorDocumentModel: state.model.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,

  updated: state.model.update.updated,
  created: state.model.create.created,
});

export default connect(mapStateToProps, mapDispatchToProps)(Model);
