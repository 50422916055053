import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPurchaseOrderStatus } from 'utils/documentStatus';
import { customerDisplay } from 'components/documents/documentCommon';
import Routes from 'routes/admin/business/routes';
import { uniqueId } from 'components/documents/uniqueId';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import { Entities } from 'types/accessRights';
import { selectDocument } from 'actions/user-companies/select';
import UnexpectedError from 'layouts/error-pages/UnexpectedError';
import { TableList } from 'components';
import ListLoading from 'components/ListLoading';
import { getActualTotal } from 'components/documents/documents';
import { cellDefinition, columnDefinition, handlerDefinition } from '../../../../../utils/tables';

const ListPurchaseOrder = () => {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState({});

  const orders = useSelector(state => state.purchaseOrder.list.data);
  const loading = useSelector(state => state.purchaseOrder.list.loading);
  const error = useSelector(state => state.purchaseOrder.list.error);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectDocument(null));
  }, []);

  useEffect(() => {
    setDocuments(orders['hydra:member'] || {});
  }, [orders]);

  if (error) {
    return <UnexpectedError />;
  }

  const statusCell = document => (
    <BusinessStatusCell document={document} handler={getPurchaseOrderStatus} />
  );

  const actualTotal = document => getActualTotal(document).toFixed(2);

  return (
    <ContainerGeneral>
      <ListLoading loaded={documents.length} total={orders['hydra:totalItems'] || 0} />
      <AddHeader title={t('purchaseOrderHomeTitle')} to={Routes.createPurchaseOrders} entity={Entities.purchaseOrder} />

      <TableList
        columns={[
          handlerDefinition('documentNumber', 'uniqueID', uniqueId),
          columnDefinition('documentsHeaderCreationDate', 'creationDate', 'date'),
          handlerDefinition('formCustomer', 'customer', customerDisplay),
          handlerDefinition('documentTotal', 'totalPrice', actualTotal, 'currency'),
          columnDefinition('formResponseDate', 'responseDate', 'date'),
          cellDefinition('documentStatus', 'status', statusCell, '', getPurchaseOrderStatus),
        ]}
        loading={loading}
        data={documents || []}
        onBusiness
        // onDelete={showDeleteWarning}
        entity={Entities.purchaseOrder}
        sortBy="creationDate"
      />
    </ContainerGeneral>
  );
};

export default ListPurchaseOrder;
