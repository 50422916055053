import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const ShowOffice = () => (
  <div className="section-container">
    Office show
  </div>
);

const mapStateToProps = state => ({
  retrievedOffice: state.office.show.retrieved,
});

const Main = connect(mapStateToProps)(ShowOffice);

export default withTranslation()(withRouter(Main));
