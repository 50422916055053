import React, { FC, useContext } from 'react';
import { Message, Modal, ModalActions, ModalHeader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import CloseButton from 'components/buttons/CloseButton';
import AdvancedList from 'components/advancedList/AdvancedList';
import { Entities } from 'types/accessRights';
import { columnDefinition, handlerDefinition } from 'utils/tables';
import { Payment, State } from 'types/store';
import useCompanySettings from 'hooks/useCompanySettings';
import { getActualTotal, getActualVat } from 'components/documents/documents';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import { BankAccount } from 'types/settings';
import TreasuryContext from './context';
import { allOrId, getDefaultBankId } from './functions';
import { Invoice } from '../../../../types/document';
import { monthAndYear } from '../../../../utils/formatter';

interface OwnProps {
  open: boolean;
  close: () => void;
  dateStart: Moment;
  dateEnd: Moment;
  display: 'turnover' | 'vat';
  label: string;
}

type Props = OwnProps;

const TurnoverDetails: FC<Props> = ({ open, close, dateStart, dateEnd, display, label }) => {
  const { t } = useTranslation();
  const payments = useSelector((state: State) => state.payment.list);
  const invoices = useSelector((state: State) => state.invoice.list);
  const banks = useCompanySettings('BANK_ACCOUNTS', []) as BankAccount[];
  const { bank } = useContext(TreasuryContext);

  if (payments.loading) {
    return <>Loading</>;
  }

  const getTotal = (invoice: Invoice): string => getActualTotal(invoice).toFixed(2);
  const getVat = (invoice: Invoice): string => getActualVat(invoice).toFixed(2);

  function invoiceFilter(list: Invoice[], dateStart: Moment, dateEnd: Moment): Invoice[] {
    return (list || []).filter(invoice => (
      moment(invoice.paymentDate) >= dateStart && moment(invoice.paymentDate) <= dateEnd
    ));
  }

  function paymentFilter(list: Payment[], dateStart: Moment, dateEnd: Moment): Payment[] {
    return (list || []).filter(payment => (
      allOrId(bank, payment.bankAccount) && moment(payment.date).isBetween(dateStart, dateEnd, 'day', '[]')
    ));
  }

  const now = moment();

  const pastMonth = now > dateEnd;
  const futureMonth = now < dateStart;
  const monthInProgress = !futureMonth && !pastMonth;

  const showInvoices = allOrId(bank, getDefaultBankId(banks));

  const turnover = display === 'turnover';
  const vat = display === 'vat';

  return (
    <Modal open={open} className="full-content">
      <ModalHeader>
        {`${label} - ${monthAndYear(dateStart)}`}
      </ModalHeader>
      <Modal.Content>
        {!futureMonth && (
          <>
            <TitleHeader title={t('paymentsListTitle')} />
            <AdvancedList
              entity={Entities.payment}
              loading={payments.loading}
              columns={[
                columnDefinition('invoiceOrderNumber', 'invoice.uniqueID'),
                columnDefinition('formAmount', 'amount', 'currency', turnover),
                columnDefinition('formVat', 'vat', 'currency', vat),
                columnDefinition('quotePaymentDate', 'date', 'date'),
                // 'bankAccount: 1',
              ]}
              data={paymentFilter(
                payments.data['hydra:member'],
                dateStart,
                monthInProgress ? now : dateEnd,
              )}
              maxRow={monthInProgress ? 5 : 10}
            />
            <br />
            <br />
          </>
        )}

        {!pastMonth && (
          showInvoices
            ? (
              <>
                <TitleHeader title={t('invoiceHomeTitle')} />
                <AdvancedList
                  entity={Entities.invoice}
                  loading={payments.loading}
                  columns={[
                    columnDefinition('invoiceOrderNumber', 'uniqueID'),
                    handlerDefinition('formAmount', 'amount', getTotal, 'currency'),
                    handlerDefinition('formVat', 'vat', getVat, 'currency'),
                    columnDefinition('quotePaymentDate', 'paymentDate', 'date'),
                  ]}
                  data={invoiceFilter(
                    invoices.data['hydra:member'],
                    monthInProgress ? now : dateStart,
                    dateEnd,
                  )}
                  maxRow={monthInProgress ? 5 : 10}
                />
              </>
            )
            : <Message hidden={showInvoices} info>{t('defaultAccountOnly')}</Message>
        )}
      </Modal.Content>
      <ModalActions>
        <CloseButton onClick={close} />
      </ModalActions>
    </Modal>
  );
};

export default TurnoverDetails;
