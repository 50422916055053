import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import { ColDef } from 'utils/tables';
import { dateFormat, floatFormat } from 'utils/formatter';
import { Entity } from 'types/entity';

interface OwnProps<T extends Entity> {
  originalData: T[];
  setFilteredData: (data: T[]) => void;
  visibleDef: ColDef<T>[];
}

function ListFilter<T extends Entity>(props: OwnProps<T>): ReactElement {
  const { originalData, setFilteredData, visibleDef } = props;
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const visibleData = (data: T): string => {
    let displayData = '';
    visibleDef.forEach(({ attribute, handler, type }: ColDef<T>) => {
      if (handler) {
        displayData += ` ${handler(data)}`;
      } else if (data[attribute]) {
        if (type === 'date') {
          displayData += ` ${data[attribute] && dateFormat(data[attribute])}`;
        } else if (type === 'number' || type === 'percentage' || type === 'currency') {
          displayData += ` ${floatFormat(parseFloat((data[attribute] || '').toString()), false, 2, ',', '')}`;
        } else {
          displayData += ` ${(data[attribute] || '').toString()}`;
        }
      }
    });

    return displayData.toLowerCase();
  };

  const doFilter = (item: T): boolean => (
    visibleData(item).indexOf(filter.toLowerCase()) >= 0
  );

  useEffect(() => {
    setFilteredData(filter !== '' ? originalData.filter(doFilter) : originalData);
  }, [filter, originalData]);

  return (
    <Form.Input
      icon="search"
      iconPosition="left"
      className="search"
      name="filter"
      placeholder={t('filterPH')}
      value={filter}
      onChange={(event: ChangeEvent, data: InputOnChangeData): void => {
        setFilter(data.value);
      }}
      onClick={(e: ChangeEvent<HTMLInputElement>): void => e.stopPropagation()}
      autoFocus
    />
  );
}

export default ListFilter;
