import React, { FC } from 'react';
import AlignedCell, { ExtendAlignedCellProps } from './AlignedCell';

type Props = ExtendAlignedCellProps & {
  icon?: string;
  onClick: () => void;
};

const SelectableCell: FC<Props> = ({ children, textAlign, icon, ...props }) => (
  <AlignedCell textAlign={textAlign || 'center'} selectable {...props}>
    <div>
      {children}
      <i className={icon || 'window restore outline icon'} />
    </div>
  </AlignedCell>
);

export default SelectableCell;
