export function hoursInDocument(value) {
  return {
    type: 'HOURS_IN_DOCUMENT',
    payload: value,
  };
}

export function actionItemSelected(value) {
  return {
    type: 'ITEMS_SELECTED',
    payload: value,
  };
}

export function showCalculation(value) {
  return {
    type: 'SHOW_CALCULATION',
    payload: value,
  };
}

export function adaptedHours(value) {
  return {
    type: 'ADAPTED_HOURS',
    payload: value,
  };
}

export function plannedHours(value) {
  return {
    type: 'PLANNED_HOURS',
    payload: value,
  };
}

export function labourTurnover(value) {
  return {
    type: 'LABOUR_TURNOVER',
    payload: value,
  };
}

export function labourCost(value) {
  return {
    type: 'LABOUR_COST',
    payload: value,
  };
}

export function totalBeforeTaxes(value) {
  return {
    type: 'TOTAL_BEFORE_TAXES',
    payload: value,
  };
}
export function netTotalBeforeTaxes(value) {
  return {
    type: 'NET_TOTAL_BEFORE_TAXES',
    payload: value,
  };
}
