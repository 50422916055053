import React, { FunctionComponent, ReactNode } from 'react';
import { Modal, ModalContent, ModalHeader } from 'semantic-ui-react';
import { versions } from '../../lang/fr/changelog.json';
import VersionDetail from './VersionDetail';

interface OwnProps {
  open: boolean;
  close: () => void;
}

type Props = OwnProps;

const VersionModal: FunctionComponent<Props> = ({ open, close }) => (
  <Modal className="full-content" open={open} closeIcon="close" onClose={close}>
    <ModalHeader>Versions</ModalHeader>
    <ModalContent scrolling>
      {versions.map((version): ReactNode => (
        <VersionDetail key={version.label} version={version} />
      ))}
    </ModalContent>
  </Modal>
);

export default VersionModal;
