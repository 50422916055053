import React, { FunctionComponent } from 'react';
import { Icon, Table } from 'semantic-ui-react';

interface OwnProps {
  value: boolean;
}

type Props = OwnProps;

const BooleanIconCell: FunctionComponent<Props> = ({ value }) => (
  <Table.Cell textAlign="center">
    <Icon
      size="large"
      color={value ? 'green' : 'red'}
      name={value ? 'checkmark' : 'close'}
    />
  </Table.Cell>
);

export default BooleanIconCell;
