import React, { FunctionComponent, ReactNode } from 'react';

interface OwnProps {
  detail: string[];
  title: string;
}

type Props = OwnProps;

const VersionDescription: FunctionComponent<Props> = ({ title, detail }) => (
  <React.Fragment>
    <h5>{title}</h5>
    <ul>
      {detail.map((improvement, index): ReactNode => <li key={index}>{improvement}</li>)}
    </ul>
  </React.Fragment>
);

export default VersionDescription;
