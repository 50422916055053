import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SubNav from './SubNav';
import SubNavTitle from './SubNavTitle';
import SubNavLinkWithButton from './subNavLinkWithButton';
import { isEmployee } from '../../utils/roles';
import SubNavLink from './SubNavLink';
import { Entities } from '../../types/accessRights';
import CheckRights from '../../components/access/CheckRights';
import SubNavSeparator from './SubNavSeparator';
import useCompany from '../../hooks/useCompany';
import useFiscalYear from '../../hooks/useFiscalYear';
import { State } from '../../types/store';
import Routes, { Route } from '../../routes/routes';

const CompanyNav: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, selectedCompany] = useCompany();
  const [hasFiscalYear] = useFiscalYear();
  const userRole = useSelector((state: State) => state.userCompanies.role.userRole);

  const checkFiscalYear = (routeToCheck: Route): Route => (
    hasFiscalYear ? routeToCheck : Routes.Company.createFiscalYear
  );

  return (
    <SubNav hide={!selectedCompany}>
      <SubNavTitle title={t('companies')} />
      <SubNavLinkWithButton
        hide={isEmployee(userRole)}
        to={Routes.Company.list}
        create={Routes.Company.create}
        title={t('companiesList')}
      />
      <SubNavLink
        entity={Entities.company}
        to={Routes.Company.home}
        title={t('companyDashboard')}
      />
      <SubNavLink
        entity={Entities.company}
        to={Routes.Company.situation}
        title={t('companySituation')}
      />
      <SubNavLink
        entity={Entities.fiscalYear}
        to={Routes.Company.fiscalYears}
        title={t('fiscalYearsHomeTitle')}
      />

      <CheckRights entity={Entities.companySettings}>
        <SubNavTitle title={t('companiesData')} />
        <SubNavLink
          to={Routes.Company.settingsContactInformation}
          title={t('companiesContactInformation')}
        />
        <SubNavLink
          to={Routes.Company.settingsCompanyDetails}
          title={t('companiesCompanyDetails')}
        />
        <SubNavLink
          to={Routes.Company.settingsCompanyShares}
          title={t('companiesCompanyShares')}
        />
        <SubNavLink
          to={Routes.Company.settingsBankAccounts}
          title={t('companiesBankAccounts')}
        />
        <SubNavLink
          to={Routes.Company.settingsOpeningHours}
          title={t('companiesOpeningHours')}
        />
      </CheckRights>

      <CheckRights entity={Entities.companySettings}>
        <SubNavTitle title={t('companiesSettings')} />
        <SubNavLink
          to={Routes.Company.settingsVatRates}
          title={t('companiesVatRates')}
        />
        <SubNavLink
          to={Routes.Company.settingsUnits}
          title={t('companiesUnits')}
        />
        <SubNavLink
          to={Routes.Company.settingsContractTypes}
          title={t('companiesContractTypes')}
        />
        <SubNavLink
          to={Routes.Company.settingsDayOffReasons}
          title={t('companiesDayOffReasons')}
        />
        <SubNavLink
          to={Routes.Company.settingsOfferReasons}
          title={t('companiesOfferReasons')}
        />
        <SubNavLink
          to={Routes.Company.settingsQuoteWonReasons}
          title={t('companiesQuoteWonReasons')}
        />
        <SubNavLink
          to={Routes.Company.settingsQuoteLostReasons}
          title={t('companiesQuoteLostReasons')}
        />
        <SubNavLink
          to={Routes.Company.settingsVarianceReasons}
          title={t('companiesVarianceReasons')}
        />
        <SubNavLink
          hide
          to={Routes.Company.settingsDocumentFooters}
          title={t('companiesDocumentFooters')}
        />
      </CheckRights>

      <SubNavLink
        entity={Entities.holiday}
        to={checkFiscalYear(Routes.Company.holidays)}
        title={t('holidaysShowTitle')}
      />
      <SubNavLink
        entity={Entities.documentNumbering}
        to={checkFiscalYear(Routes.Company.documentNumbering)}
        title={t('documentNumberingTitle')}
      />

      <SubNavSeparator />
      <SubNavLinkWithButton
        entity={Entities.role}
        to={Routes.Company.settingsRoles}
        title={t('companiesRolesManagement')}
      />
    </SubNav>
  );
};

export default CompanyNav;
