import React, { FunctionComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import KeyValueTable from './KeyValueTable';
import RowWithTitle from './RowWithTitle';
import { KpiDTO } from '../../types/dto';


interface OwnProps {
  data: {
    fixed: KpiDTO[];
    variables: KpiDTO[];
    global: KpiDTO[];
  };
}

type Props = OwnProps;

const Charges: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <RowWithTitle title={t('dashboard_companyCharges')}>

      <Grid.Column width={4}>
        <KeyValueTable data={data.fixed} title={t('dashboard_fixed')} />
      </Grid.Column>

      <Grid.Column width={4}>
        <KeyValueTable data={data.variables} title={t('dashboard_variables')} />
      </Grid.Column>

      <Grid.Column width={4}>
        <KeyValueTable data={data.global} title={t('dashboard_global')} />
      </Grid.Column>

    </RowWithTitle>
  );
};

export default Charges;
