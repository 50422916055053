import React, { FunctionComponent, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import LeftCell from '../../../layouts/table/LeftCell';
import PercentageCell from '../../../components/cell/PercentageCell';
import { SituationTuple } from './situationTypes';

export interface SituationRowProps {
  children?: ReactNode;
  data: SituationTuple;
  label: string;
  noGap?: boolean;
}

type Props = SituationRowProps;

const SituationRow: FunctionComponent<Props> = ({ children, data, label, noGap = false }) => (
  <Table.Row>
    <LeftCell content={label} />
    {children}
    {noGap
      ? <LeftCell colSpan={2} />
      : <PercentageCell value={data && data.variance} color noGreen />
    }
  </Table.Row>
);

export default SituationRow;
