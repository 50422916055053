import React from 'react';

const Progress = ({ done, breakEven, breakEvenDate, percentage, rawPercentage }) => (
  <div className="progress-container">
    <div className="label-container">
      <p className="progress-label">
        Objectif:
        <br />
        <span className="right">{breakEven}</span>
      </p>

      <p className="progress-label">
        Date:
        <br />
        <span className="right">{breakEvenDate}</span>
      </p>
    </div>

    <div className="bar-container">
      <div className="ui progress green inverted">
        <div
          className="bar"
          style={{
            width: `${rawPercentage > 100 ? '100' : rawPercentage}%`,
          }}
        >
          <div className="progress">{percentage}</div>
        </div>
      </div>

      <p className="progress-label">
        Réalisé en valeur HT:
        <span>{done}</span>
      </p>
    </div>
  </div>
);

export default Progress;
