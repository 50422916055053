import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SemanticFLOATS } from 'semantic-ui-react';
import { EssorButton } from '../index';

interface OwnProps {
  onClick: () => void;
  disabled?: boolean;
  floated?: SemanticFLOATS;
}

type Props = OwnProps;

const CloseButton: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <EssorButton secondary type="x" size="tiny" {...props}>
      {t('buttonClose')}
    </EssorButton>
  );
};

CloseButton.defaultProps = {
  disabled: false,
  floated: undefined,
};

export default CloseButton;
