import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../../404';
import PricesList from './list';
import PricesCreate from './create';

const PriceHome = () => (
  <React.Fragment>
    <Switch>
      <Route exact path="/articles/prices" component={PricesList} />
      <Route exact path="/articles/prices/create" component={PricesCreate} />
      <Route component={NotFound} />
    </Switch>
  </React.Fragment>
);

export default PriceHome;
