import { DropdownItemProps } from 'semantic-ui-react';
import { Entity } from 'types/entity';
import { Company } from 'types/store';
import { Big as RealBig, BigSource } from 'big.js';
import * as Sentry from '@sentry/react';

interface KeyVal<T> {
  [key: string]: T;
}

interface Named {
  firstName: string;
  lastName: string;
}

export const keyVal = (key: string, val: any): KeyVal<typeof val> => ({
  [key]: val,
});

export const fullName = (named: Named): string => `${named.firstName} ${named.lastName}`;

export function getSetting(company: Company, settingName: string): object {
  const setting = company.settings.find(setting => setting.name === settingName);
  return setting ? setting.value : {};
}

type definedValue = string | number | boolean;

export function option(key: string, text: string, value: definedValue): DropdownItemProps {
  return {
    key,
    text,
    value,
  };
}

export function deDupe<T extends Entity>(data: T[]): T[] {
  const deDuped: T[] = [];
  data.forEach((document) => {
    const found = deDuped.find(element => element.id === document.id);
    if (!found) {
      deDuped.push(document);
    }
  });
  return deDuped;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Big extends RealBig {}
export function Big(value: BigSource): RealBig {
  let big: RealBig;
  try {
    big = RealBig(value);
  } catch (e) {
    Sentry.captureMessage(`Big.js failed to parse value '${value}'`);
    big = RealBig(0);
  }
  return big;
}
