import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { print } from 'actions/document/print';
import { State } from 'types/store';
import { isNotDraft } from 'utils/documentStatus';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import { Document } from 'types/document';
import CellButton, { ExtendProps } from './CellButton';

interface OwnProps extends ExtendProps {
  url: string;
  document: Document;
  onClick?: never;
}

const PrintCellButton: FC<OwnProps> = ({ tip, url, document, ...others }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setFileName } = useContext(PrinterContext);
  const [downloading, setDownloading] = useState(false);
  const loading = useSelector((state: State) => state.document.print.loading);

  const onClick = (): void => {
    setDownloading(true);
    setFileName(isNotDraft(document) ? document.uniqueID : 'Document');
    dispatch(print(url));
  };

  useEffect(() => (!loading && downloading ? setDownloading(false) : undefined), [loading]);

  return (
    <CellButton
      icon="file pdf"
      tip={tip || t('buttonDetails')}
      onClick={onClick}
      loading={downloading}
      disabled={loading}
      {...others}
    />
  );
};
export default PrintCellButton;
