import React from 'react';
import { useTranslation } from 'react-i18next';
import useCompany from 'hooks/useCompany';
import { Redirect } from 'react-router-dom';
import ErrorTemplate from 'layouts/error-pages/ErrorTemplate';

const Page404 = () => {
  const { t } = useTranslation();
  const [hasCompany] = useCompany();

  if (!hasCompany) {
    return <Redirect to="/company/list/" />;
  }

  return (
    <ErrorTemplate
      title={t('notFoundTitle')}
      body={t('notFoundBody')}
      tryTo={t('notFoundTry')}
      solutions={[
        {
          title: t('notFoundRefresh'),
          onClick: () => {
            window.location = window.location;
          },
        },
        {
          title: t('notFoundBack'),
          onClick: () => {
            window.history.back();
          },
        },
        {
          title: t('notFoundHome'),
          href: '/',
        },
      ]}
    />
  );
};

export default Page404;
