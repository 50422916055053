import React, { FunctionComponent } from 'react';
import AlignedCell, { ExtendAlignedCellProps } from './AlignedCell';

type Props = ExtendAlignedCellProps;

const LeftCell: FunctionComponent<Props> = props => (
  <AlignedCell textAlign="left" {...props} />
);

export default LeftCell;
