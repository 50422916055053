import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ContainerGeneral from '../../../../layouts/ContainerGeneral';
import CompanySettingBlock from './CompanySettingBlock';

const CompanySettings = () => {
  const [t] = useTranslation();
  return (
    <ContainerGeneral>
      <Grid>
        <Grid.Row>{t('companiesData')}</Grid.Row>
        <Grid.Row>
          <CompanySettingBlock label={t('companiesContactInformation')} />
          <CompanySettingBlock label={t('companiesCompanyDetails')} />
          <CompanySettingBlock label={t('companiesCompanyShares')} />
          <CompanySettingBlock label={t('companiesBankAccounts')} />
          <CompanySettingBlock label={t('companiesOpeningHours')} />
        </Grid.Row>
        <Grid.Row>{t('companiesSettings')}</Grid.Row>
        <Grid.Row>
          <CompanySettingBlock label={t('companiesVatRates')} />
          <CompanySettingBlock label={t('companiesRolesManagement')} />
          <CompanySettingBlock label={t('companiesUnits')} />
          <CompanySettingBlock label={t('companiesContractTypes')} />
          <CompanySettingBlock label={t('holidaysShowTitle')} />
        </Grid.Row>
        <Grid.Row>
          <CompanySettingBlock label={t('companiesDayOffReasons')} />
          <CompanySettingBlock label={t('companiesOfferReasons')} />
          <CompanySettingBlock label={t('companiesQuoteWonReasons')} />
          <CompanySettingBlock label={t('companiesQuoteLostReasons')} />
          <CompanySettingBlock label={t('companiesVarianceReasons')} />
        </Grid.Row>
        <Grid.Row>
          <CompanySettingBlock label={t('documentNumberingTitle')} />
        </Grid.Row>
      </Grid>
    </ContainerGeneral>
  );
};

export default CompanySettings;
