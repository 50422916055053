import moment from 'moment';
import { t } from 'i18next';
import { STATUS_CLOSED, STATUS_DRAFT, STATUS_PENDING, STATUS_VALIDATED } from 'utils/constants';
import { getActualTotal } from '../components/documents/documents';

export function isLate(document) {
  switch (document['@type']) {
    case 'Quote':
      return document.status === STATUS_PENDING && moment().isAfter(document.responseDate, 'day');
    case 'Invoice':
      return getActualTotal(document).gt(0) && moment().isAfter(document.paymentDate, 'day');
    default:
      return false;
  }
}

export function isPaid(document) {
  return document['@type'] === 'Invoice' && document.status === STATUS_VALIDATED;
}

export function isDraft(document) {
  return !document || (document.status || 0) < STATUS_PENDING;
}

export function isNotDraft(document) {
  return document && (document.status || 0) >= STATUS_PENDING;
}

export function isPending(document) {
  return document && (document.status || 0) === STATUS_PENDING;
}

export function isBillable(document) {
  return document && (
    (document.status || 0) === STATUS_PENDING || (document.status || 0) === STATUS_VALIDATED
  );
}

export function getStatusColoring(document) {
  return {
    color: isPaid(document) ? 'green' : (isLate(document) ? 'red' : 'inherit'),
  };
}

export function hasPurchaseOrder(document) {
  return document && document.purchaseOrder !== null;
}

export function hasInvoices(document) {
  return document && (document.invoice || []).length > 0;
}

function isFullyBilled(document) {
  let onlyPartial = true;
  if (hasInvoices(document)) {
    document.invoice.forEach((invoice) => {
      onlyPartial = onlyPartial && (invoice.content.partialInvoice || false);
    });
  }
  return document && !onlyPartial;
}

export function canTransformToPurchaseOrder(document) {
  return isPending(document) && !hasPurchaseOrder(document) && !hasInvoices(document);
}

export function canTransformToInvoice(document) {
  return isBillable(document) && !hasPurchaseOrder(document) && !isFullyBilled(document);
}

export function getQuoteStatus(quote) {
  let status = '';

  switch (quote.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
      status = isLate(quote)
        ? t('documentStatusExpired')
        : t('documentStatusPending');
      break;
    case STATUS_VALIDATED:
      if (quote.invoice && quote.invoice.length > 0) {
        status = isFullyBilled(quote)
          ? t('documentStatusBilled')
          : t('documentStatusPartiallyBilled');
      } else if (quote.purchaseOrder) {
        status = t('documentStatusOrdered');
      } else {
        status = t('documentStatusAccepted');
      }
      break;
    case STATUS_CLOSED:
      status = t('documentStatusLost');
      break;
    default:
  }
  return status;
}

export function getPurchaseOrderStatus(purchaseOrder) {
  let status = '';

  switch (purchaseOrder.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
      status = t('documentStatusPending');
      break;
    case STATUS_VALIDATED:
      if (purchaseOrder.invoice) {
        status = t('documentStatusBilled');
      }
      break;
    case STATUS_CLOSED:
      status = t('documentStatusLost');
      break;
    default:
  }

  return status;
}

export function getInvoiceStatus(invoice) {
  let status = '';

  switch (invoice.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
      if (getActualTotal(invoice).eq(0)) {
        status = '-';
      } else if (invoice.paymentDate) {
        status = isLate(invoice)
          ? t('documentStatusLate')
          : t('documentStatusInvoicePending');
      } else {
        status = t('documentStatusInvoicePending');
      }
      break;
    case STATUS_VALIDATED:
      status = t('documentStatusPaid');
      break;
    case STATUS_CLOSED:
      status = t('documentStatusLost');
      break;
    default:
  }
  return status;
}

export function getOtherStatus(document) {
  let status = '';

  switch (document.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
      status = t('documentStatusSaved');
      break;
    case STATUS_VALIDATED:
      status = t('documentStatusFilled');
      break;
    case STATUS_CLOSED:
      status = t('documentStatusCancelled');
      break;
    default:
      break;
  }

  return status;
}

function getDocumentStatusByType(document) {
  let status = '';

  switch (document['@type']) {
    case 'Quote':
      status = getQuoteStatus(document);
      break;
    case 'PurchaseOrder':
      status = getPurchaseOrderStatus(document);
      break;
    case 'Invoice':
      status = getInvoiceStatus(document);
      break;
    case 'WorkOrder':
    case 'CreditNote':
      status = getOtherStatus(document);
      break;
    default:
      break;
  }
  return status;
}

export function getDocumentStatus(document) {
  let status = '';

  switch (document.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
    case STATUS_VALIDATED:
      status = getDocumentStatusByType(document);
      break;
    case STATUS_CLOSED:
      status = t('documentStatusLost');
      break;
    default:
      break;
  }
  return status;
}

export function getSupplierOrderStatus(document) {
  let status = '';

  switch (document.status) {
    case STATUS_DRAFT:
      status = t('documentStatusDraft');
      break;
    case STATUS_PENDING:
      status = t('documentStatusPending');
      break;
    case STATUS_VALIDATED:
      if (document.invoice) {
        status = t('documentStatusBilled');
      }
      break;
    case STATUS_CLOSED:
      status = t('documentStatusLost');
      break;
    default:
      break;
  }
  return status;
}
