import React, { FunctionComponent, useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ContainerGeneral from '../../../../../layouts/ContainerGeneral';
import { list, reset } from '../../../../../actions/product/list';
import UnexpectedError from '../../../../../layouts/error-pages/UnexpectedError';
import { Entities } from '../../../../../types/accessRights';
import { Product, State, Stock } from '../../../../../types/store';
import useCompany from '../../../../../hooks/useCompany';
import { columnDefinition, dataHandler, handlerDefinition } from '../../../../../utils/tables';
import AdvancedList from '../../../../../components/advancedList/AdvancedList';

const ListStock: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, company] = useCompany();
  const { loading, data, error } = useSelector((state: State) => state.product.list);
  const history = useHistory();
  const dispatch = useDispatch();

  const id = company ? company.id : 0;

  useEffect(() => {
    dispatch(list(`/products?company=${id}&stockManagement=true`));

    return (): void => {
      dispatch(reset());
    };
  }, []);

  const listProduct = !isEmpty(data) ? data['hydra:member'] : [];

  if (error) {
    return <UnexpectedError />;
  }

  const sumQuantity: dataHandler<Product> = (product: Product): string => (
    product.stock ? product.stock.reduce(
      (a: number, b: Stock) => a + parseFloat(b.quantity), 0,
    ).toString() : ''
  );

  return (
    <ContainerGeneral>
      <Header as="h3">{t('stockSituation')}</Header>

      <AdvancedList<Product>
        columns={[
          columnDefinition('formReference', 'reference'),
          columnDefinition('formLabel', 'label'),
          handlerDefinition('formQuantity', 'stock', sumQuantity, 'number'),
          columnDefinition('formUnit', 'unit'),
        ]}
        loading={loading}
        data={listProduct}
        onView={(item: Product): void => history.push(`/articles${item['@id']}`)}
        entity={Entities.product}
      />
    </ContainerGeneral>
  );
};

export default ListStock;
