import { Action } from 'redux';
import fetch from '../../utils/fetch';
import { ErrorAction, LoadingAction, RetrieveDispatch, SuccessAction } from '../../types/actions';
import { CompanySituation } from '../../routes/admin/situation/situationTypes';

export function error(error: string): ErrorAction {
  return {
    type: 'COMPANY_SITUATION_ERROR',
    error,
  };
}

export function loading(loading: boolean): LoadingAction {
  return {
    type: 'COMPANY_SITUATION_LOADING',
    loading,
  };
}

export function success(data: CompanySituation): SuccessAction {
  return {
    type: 'COMPANY_SITUATION_SUCCESS',
    data,
  };
}

export function retrieve(page: string) {
  return (dispatch: RetrieveDispatch): void => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response => response.json())
      .then((data) => {
        dispatch(success(data));
        dispatch(loading(false));
      })
      .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(): Action {
  return {
    type: 'COMPANY_SITUATION_RESET',
  };
}
