import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useAccessRights from '../../hooks/useAccessRights';
import NotFound from '../../routes/admin/404';
import { crudRights, Entities } from '../../types/accessRights';
import { CheckAccessRights } from '../../types/store';

interface OwnProps extends CheckAccessRights, RouteProps {
  entity: Entities;
}

type Props = OwnProps;

/**
 * Extend Route component behaviour by adding entity / right check and 404ing if not allowed
 */
const CheckRoute: FC<Props> = ({ entity, right, ...others }) => {
  const hasAccess = useAccessRights(entity, (right || crudRights.read));

  if (!hasAccess) {
    const { exact, path } = others;
    return <Route exact={exact} path={path} component={NotFound} />;
  }

  return <Route {...others} />;
};

export default CheckRoute;
