import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectCompany } from 'actions/user-companies/select';
import { list as listCompany, success as setListCompany } from 'actions/company/list';
import { del as deleteCompany, error as errorDelete } from 'actions/company/delete';
import { DeleteConfirmation } from 'components';
import { withTranslation } from 'react-i18next';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import { columnDefinition, handlerDefinition } from 'utils/tables';
import { Entities } from '../../../../types/accessRights';
import { fullName, getSetting } from '../../../../utils/functions';
import AdvancedList from '../../../../components/advancedList/AdvancedList';

class HomeCompanies extends Component {
  state = {
    companiesList: [],
    warningMessage: false,
  };

  componentDidMount() {
    const { getCompanies } = this.props;

    getCompanies();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listCompanies) && nextProps.listCompanies['hydra:member'] !== prevState.companiesList) {
      return {
        companiesList: nextProps.listCompanies['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedCompany, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete) && errorDelete !== prevProps.errorDelete) {
      this.dismissWarning();
      resetError();
    }

    if (!isEmpty(deletedCompany) && deletedCompany !== prevProps.deletedCompany) {
      this.updateList(deletedCompany);
    }
  }

  updateList = (deletedCompany) => {
    const { listCompanies, setListCompany, selectCompany, selectedCompany } = this.props;
    const arrayCompanies = listCompanies['hydra:member'].filter(company => company['@id'] !== deletedCompany['@id']);

    const newListCompany = {
      ...listCompanies,
      'hydra:member': arrayCompanies,
    };

    const foundCompany = newListCompany['hydra:member'].find(company => company['@id'] === selectedCompany['@id']);

    if (!foundCompany) {
      selectCompany(null);
    }

    setListCompany(newListCompany);
    this.dismissWarning();
  };

  setSelectedCompany = (company) => {
    const { selectCompany, history } = this.props;

    selectCompany(company);
    history.push('/company');
  };

  handleDelete = () => {
    const { deleteItem } = this.state;
    const { deleteCompany } = this.props;
    deleteCompany(deleteItem);
  };

  showDeleteWarning = (item) => {
    this.setState({
      deleteItem: item,
      warningMessage: true,
      itemName: item.name || '',
    });
  };

  dismissWarning = () => {
    this.setState({
      deleteItem: null,
      warningMessage: false,
      itemName: '',
    });
  };

  render() {
    const { companiesList, warningMessage, itemName } = this.state;
    const { t, loadingCompanies, loadingDelete } = this.props;

    const referrerName = company => (company.referrer ? fullName(company.referrer) : '');

    const getInfo = (company, key) => (getSetting(company, 'CONTACT_INFORMATION')[key] || '');
    const getDetail = (company, key) => (getSetting(company, 'COMPANY_DETAILS')[key] || '');
    const getOffice = company => company.office.name;

    const columns = [
      columnDefinition('formName', 'name'),
      columnDefinition('formCreationDate', 'dateCreated', 'date'),
      columnDefinition('formLastAccess', 'dateLastAccess', 'date'),
      handlerDefinition('formOffice', 'office.name', getOffice),
      handlerDefinition('Dirigeant', 'referrer', referrerName),
      handlerDefinition('formActivity', 'settings.activity', company => getDetail(company, 'activity'), '', false),
      handlerDefinition('formCommercialName', 'settings.commercialName', company => getDetail(company, 'commercialName'), '', false),
      handlerDefinition('formLegalName', 'settings.legalName', company => getDetail(company, 'legalName'), '', false),
      handlerDefinition('formNaf', 'settings.naf', company => getDetail(company, 'naf'), '', false),
      handlerDefinition('formRcs', 'settings.rcs', company => getDetail(company, 'rcs'), '', false),
      handlerDefinition('formShareCapital', 'settings.shareCapital', company => getDetail(company, 'shareCapital'), '', false),
      handlerDefinition('formSiret', 'settings.siret', company => getDetail(company, 'siret'), '', false),
      handlerDefinition('formSlogan', 'settings.slogan', company => getDetail(company, 'slogan'), '', false),
      handlerDefinition('formVat', 'settings.vat', company => getDetail(company, 'vat'), '', false),
      handlerDefinition('formStreetName', 'settings.streetName', company => getInfo(company, 'streetName'), '', false),
      handlerDefinition('formAdditional', 'settings.additional', company => getInfo(company, 'additional'), '', false),
      handlerDefinition('formZipCode', 'settings.zipCode', company => getInfo(company, 'zipCode'), '', false),
      handlerDefinition('formCity', 'settings.city', company => getInfo(company, 'city'), '', false),
      handlerDefinition('formRegion', 'settings.region', company => getInfo(company, 'region'), '', false),
      handlerDefinition('formCountry', 'settings.country', company => getInfo(company, 'country'), '', false),
      handlerDefinition('formFax', 'settings.fax', company => getInfo(company, 'fax'), '', false),
      handlerDefinition('formPhoneNumber', 'settings.phone', company => getInfo(company, 'phone'), '', false),
      handlerDefinition('formWebsite', 'settings.website', company => getInfo(company, 'website'), '', false),
    ];

    return (
      <ContainerGeneral>
        <AddHeader title={t('companiesHomeTitle')} to="/company/create" />

        <AdvancedList
          columns={columns}
          loading={loadingCompanies}
          data={companiesList}
          onView={this.setSelectedCompany}
          onDelete={this.showDeleteWarning}
          viewTooltip="select"
          entity={Entities.company}
        />

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDelete}
          onClose={this.dismissWarning}
          onDelete={this.handleDelete}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectCompany: company => dispatch(selectCompany(company)),
  deleteCompany: id => dispatch(deleteCompany(id)),
  getCompanies: () => dispatch(listCompany()),
  resetError: () => dispatch(errorDelete(null)),
  setListCompany: list => dispatch(setListCompany(list)),
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  listCompanies: state.company.list.data,
  loadingCompanies: state.company.list.loading,
  errorCompanies: state.company.list.error,

  deletedCompany: state.company.del.deleted,
  loadingDelete: state.company.del.loading,
  errorDelete: state.company.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeCompanies);

export default withTranslation()(withRouter(Main));
