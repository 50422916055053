/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { print, reset } from 'actions/fiscal-year/print';
import { retrieve } from 'actions/company-settings/show';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { updatePrint } from 'utils/print';
import useCompany from 'hooks/useCompany';
import useFiscalYear from 'hooks/useFiscalYear';
import { Message } from 'semantic-ui-react';
import ButtonHeader from 'components/pageHeaders/ButtonHeader';

const Welcome = () => {
  const { t } = useTranslation();
  const [, company] = useCompany();
  const [, fiscalYear] = useFiscalYear();
  const urlDocument = useSelector(state => state.fiscalYear.print.url);
  const loadingPrintDocument = useSelector(state => state.fiscalYear.print.loading);
  const details = useSelector(state => state.companySettings.show.retrieved);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(retrieve(`/company_settings?company=${company.id}&name=COMPANY_DETAILS`));
  }, [company]);

  const { siret } = details ? details['hydra:member'][0].value : '';

  const reverseDate = (date) => {
    const splitted = date.split('-');
    return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
  };

  const companyName = company.name.replace(/\W/g, '-');
  const dateStart = reverseDate(fiscalYear.dateStart);
  const dateEnd = reverseDate(fiscalYear.dateEnd);
  const filename = `previ_${companyName.toLowerCase()}_${siret}_${dateStart}_${dateEnd}`;

  useEffect(() => {
    updatePrint(urlDocument, '', () => dispatch(reset()), filename);
  }, [urlDocument]);

  return (
    <ContainerGeneral>
      <ButtonHeader
        type="print"
        title={t('forecast')}
        loading={loadingPrintDocument}
        disabled={loadingPrintDocument}
        onClick={() => dispatch(print(`/fiscal_years/${fiscalYear.id}/print`))}
        label={t('buttonPrintForecast')}
      />
      <div className="forecast-intro">
        <p>{t('forecastIntro')}</p>
        <p>{t('forecastSteps')}</p>
        <ol>
          <li>
            <strong>{t('forecastStepStaffTitle')}</strong>
            <span>{t('forecastStepStaffText')}</span>
            <em>{t('forecastStepStaffAim')}</em>
          </li>
          <li>
            <strong>{t('forecastStepHoursTitle')}</strong>
            <span>{t('forecastStepHoursText')}</span>
            <em>{t('forecastStepHoursAim')}</em>
          </li>
          <li>
            <strong>{t('forecastStepBudgetsTitle')}</strong>
            <span>{t('forecastStepBudgetsText')}</span>
            <em>{t('forecastStepBudgetsAim')}</em>
          </li>
          <li>
            <strong>{t('forecastStepTableTitle')}</strong>
            <span>{t('forecastStepTableText')}</span>
            <em>{t('forecastStepTableAim')}</em>
          </li>
        </ol>
        <Message warning>
          <strong>NB: </strong>
          {t('forecastStepsWarning')}
        </Message>
      </div>

    </ContainerGeneral>
  );
};

export default Welcome;
