import i18next from 'i18next';

export function getFrequencyOptions() {
  return [
    {
      key: 'day',
      text: i18next.t('formDays'),
      value: 'day',
    },
    {
      key: 'week',
      text: i18next.t('formWeek'),
      value: 'week',
    },
    {
      key: 'month',
      text: i18next.t('formMonth'),
      value: 'month',
    },
    {
      key: 'year',
      text: i18next.t('formYear'),
      value: 'year',
    },
  ];
}


const weeklyOptions = [
  {
    text: i18next.t('monday'),
    value: 1,
  },
  {
    text: i18next.t('tuesday'),
    value: 2,
  },
  {
    text: i18next.t('wednesday'),
    value: 3,
  },
  {
    text: i18next.t('thursday'),
    value: 4,
  },
  {
    text: i18next.t('friday'),
    value: 5,
  },
  {
    text: i18next.t('saturday'),
    value: 6,
  },
  {
    text: i18next.t('sunday'),
    value: 0,
  },
];

function getMonthlyOptions() {
  const options = [
    {
      text: i18next.t('lastDay'),
      value: 31,
      key: 'last',
    },
    {
      text: i18next.t('firstDay'),
      value: 1,
      key: 'first',
    },
  ];

  for (let i = 1; i < 32; i++) {
    let obj;
    switch (i) {
      case 1:
        obj = {
          text: `${i}`,
          value: i,
        };
        break;
      case 2:
      case 22:
        obj = {
          text: `${i}`,
          value: i,
        };
        break;
      case 3:
      case 23:
        obj = {
          text: `${3}`,
          value: i,
        };
        break;
      default:
        obj = {
          text: `${i}`,
          value: i,
        };
        break;
    }
    options.push(obj);
  }

  return options;
}

export function getDaysOptions(frequencyUnit) {
  if (frequencyUnit === 'week') {
    return weeklyOptions;
  }
  if (frequencyUnit === 'month' || frequencyUnit === 'year') {
    return getMonthlyOptions();
  }
  return [];
}
