import React, { FC } from 'react';
import { Grid, StrictGridColumnProps } from 'semantic-ui-react';

const RowColumn: FC<StrictGridColumnProps> = ({ children, ...others }) => (
  <Grid.Row>
    <Grid.Column {...others}>
      {children}
    </Grid.Column>
  </Grid.Row>
);

export default RowColumn;
