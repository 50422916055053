import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendProps } from '../essorButton';
import { EssorButton } from '../index';
import { crudRights } from '../../types/accessRights';

const AddButton: FunctionComponent<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();
  return (
    <EssorButton type="plus" size="tiny" right={crudRights.create} {...others}>
      {label || t('buttonAdd')}
    </EssorButton>
  );
};

export default AddButton;
