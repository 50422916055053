import { VisibilityAction, VisibilityDispatch } from '../../types/actions';

const SET_PO_TO_INVOICE_MODAL = 'MODAL/SET_PO_TO_INVOICE_MODAL';

export const setOpen = (visibility: boolean): VisibilityAction => ({
  type: SET_PO_TO_INVOICE_MODAL,
  visibility,
});

export const open = () => (dispatch: VisibilityDispatch) => {
  dispatch(setOpen(true));
};

export const close = () => (dispatch: VisibilityDispatch) => {
  dispatch(setOpen(false));
};
