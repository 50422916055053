import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Turnover from 'routes/admin/forecast/turnover-balance';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import useFiscalYear from 'hooks/useFiscalYear';
import CheckRoute from 'components/access/CheckRoute';
import { crudRights, Entities } from 'types/accessRights';
import { Forecast as Routes } from 'routes/admin/forecast/routes';
import SubNav from 'layouts/subNav/SubNav';
import RouteNotFound from 'routes/RouteNotFound';
import SubNavLink from 'layouts/subNav/SubNavLink';
import NoFiscalYear from 'layouts/error-pages/NoFiscalYear';
import Welcome from './welcome';
import Budgets from './budgets';
import HourSynthesis from './hour-synthesis';
import ForecastTable from './table';
import BreakEven from './break-even';
import SelfFinancing from './self-financing';
import WorkingCapital from './working-capital';

const Forecast = () => {
  const { t } = useTranslation();
  const [hasFiscalYear, fiscalYear] = useFiscalYear();

  const def = [
    {
      to: Routes.home,
      title: t('forecastHome'),
      component: Welcome,
    },
    {
      to: Routes.hourSynthesis,
      entity: Entities.hourSynthesis,
      title: t('forecastHourSynthesis'),
      component: HourSynthesis,
    },
    {
      to: Routes.budgets,
      entity: Entities.budget,
      title: t('forecastBudgets'),
      component: Budgets,
    },
    {
      to: Routes.table,
      entity: Entities.forecastTable,
      title: t('forecastTable'),
      component: ForecastTable,
    },
    {
      to: Routes.breakEven,
      title: t('forecastBreakEven'),
      component: BreakEven,
    },
    {
      to: Routes.selfFinancing,
      entity: Entities.selfFinancing,
      title: t('forecastSelfFinancing'),
      component: SelfFinancing,
      exact: false,
    },
    {
      to: Routes.workingCapital,
      entity: Entities.workingCapital,
      title: t('forecastWorkingCapital'),
      component: WorkingCapital,
      exact: false,
    },
    {
      separator: true,
      to: Routes.turnoverList,
      entity: Entities.turnoverBalance,
      right: crudRights.read,
      title: t('navTitleTurnoverBalance'),
      component: Turnover,
    },
  ];

  if (!hasFiscalYear) {
    return <NoFiscalYear />;
  }

  return (
    <React.Fragment>
      <SubNav>
        <SubNavTitle title={t('forecast')} subtitle={fiscalYear.label} />

        {def.map((item, index) => (
          <SubNavLink {...item} key={`link${index}`} />
        ))}
      </SubNav>

      <Switch>
        {def.map(({ to, entity, right, ...rest }, index) => {
          if (entity && right) {
            return (
              <CheckRoute
                path={to}
                entity={entity}
                right={right}
                {...rest}
                key={`route${index}`}
              />
            );
          }
          return <Route exact path={to} {...rest} key={`route${index}`} />;
        })}
        <RouteNotFound />
      </Switch>
    </React.Fragment>
  );
};

export default Forecast;
