import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import CompanySettings from 'routes/admin/company/settings/CompanySettings';
import CheckRouting from 'components/access/CheckRouting';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';

import RouteNotFound from 'routes/RouteNotFound';
import CompanyDetailsShow from './show/company-details';
import ContactInformationShow from './show/contact-information';
import OpeningHoursShow from './show/opening-hours';
import VatRatesShow from './show/vat-rates';
import DayOffReasonsShow from './show/day-off-reasons';
import UnitsShow from './show/units';
import ContractTypesShow from './show/contract-types';
import OfferReasonsShow from './show/offer-reasons';
import QuoteWonReasonsShow from './show/quote-won-reasons';
import VarianceReasonsShow from './show/variance-reasons';
import QuoteLostReasonsShow from './show/quote-lost-reasons';
import CompanySharesShow from './show/company-shares';
import BankAccountsShow from './show/bank-accounts';
import CompanyDetailsEdit from './edit/company-details';
import ContactInformationEdit from './edit/contact-information';
import OpeningHoursEdit from './edit/opening-hours';
import VatRatesEdit from './edit/vat-rates';
import DayOffReasonsEdit from './edit/day-off-reasons';
import UnitsEdit from './edit/units';
import ContractTypesEdit from './edit/contract-types';
import OfferReasonsEdit from './edit/offer-reasons';
import QuoteWonReasonsEdit from './edit/quote-won-reasons';
import VarianceReasonsEdit from './edit/variance-reasons';
import QuoteLostReasonsEdit from './edit/quote-lost-reasons';
import CompanySharesEdit from './edit/company-shares';
import BankAccountsEdit from './edit/bank-accounts';
import Role from './roles';

// import DocumentFootersEdit from './edit/document-footers';
// import DocumentFootersShow from './show/document-footers';

const Settings = () => {
  const { company } = useLocation();
  const { url } = useRouteMatch();

  const p = path => `${url}${path}`;

  const contactInfoEdit = props => (
    <ContactInformationEdit {...props} company={company} />
  );

  const companyDetailsEdit = props => (
    <CompanyDetailsEdit {...props} company={company} />
  );

  return (
    <CheckRouting entity={Entities.companySettings} right={crudRights.read}>
      <Switch>
        <Route exact path={p('/')} component={CompanySettings} />
        <Route exact path={p('/contact-information')} component={ContactInformationShow} />
        <Route exact path={p('/company-details')} component={CompanyDetailsShow} />
        <Route exact path={p('/opening-hours')} component={OpeningHoursShow} />
        <Route exact path={p('/vat-rates')} component={VatRatesShow} />
        <Route exact path={p('/day-off-reasons')} component={DayOffReasonsShow} />
        <Route exact path={p('/units')} component={UnitsShow} />
        <Route exact path={p('/contract-types')} component={ContractTypesShow} />
        <Route exact path={p('/offer-reasons')} component={OfferReasonsShow} />
        <Route exact path={p('/quote-won-reasons')} component={QuoteWonReasonsShow} />
        <Route exact path={p('/variance-reasons')} component={VarianceReasonsShow} />
        <Route exact path={p('/quote-lost-reasons')} component={QuoteLostReasonsShow} />
        <Route exact path={p('/company-shares')} component={CompanySharesShow} />
        <Route exact path={p('/bank-accounts')} component={BankAccountsShow} />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/opening-hours/edit')}
          component={OpeningHoursEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/vat-rates/edit')}
          component={VatRatesEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/day-off-reasons/edit')}
          component={DayOffReasonsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/units/edit')}
          component={UnitsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/contract-types/edit')}
          component={ContractTypesEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/offer-reasons/edit')}
          component={OfferReasonsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/quote-won-reasons/edit')}
          component={QuoteWonReasonsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/variance-reasons/edit')}
          component={VarianceReasonsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/quote-lost-reasons/edit')}
          component={QuoteLostReasonsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/company-shares/edit')}
          component={CompanySharesEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/bank-accounts/edit')}
          component={BankAccountsEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/contact-information/edit')}
          render={contactInfoEdit}
        />
        <CheckRoute
          exact
          entity={Entities.companySettings}
          right={crudRights.update}
          path={p('/company-details/edit')}
          render={companyDetailsEdit}
        />
        <Route path={p('/roles')} component={Role} />
        <RouteNotFound />
        {/*
        <Route exact path={p('/document-footers')} component={DocumentFootersShow} />
        <Route exact path={p('/document-footers/edit')} component={DocumentFootersEdit} />
        */}
      </Switch>
    </CheckRouting>
  );
};

export default Settings;
