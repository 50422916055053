import React from 'react';
import { isEmpty } from 'lodash';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { retrieve as retrieveContextualData } from 'actions/contextual-data/show';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SidebarListItem from 'layouts/sidebar/SidebarListItem';

class ContextualData extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { getData, selectedFiscalYear } = this.props;

    if (selectedFiscalYear) {
      getData(`/fiscal_years/${selectedFiscalYear.id}/contextual_data`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedData) && nextProps.retrievedData !== prevState.data) {
      return {
        data: nextProps.retrievedData,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { selectedFiscalYear, getData } = this.props;

    if (!isEmpty(selectedFiscalYear) && selectedFiscalYear !== prevProps.selectedFiscalYear) {
      getData(`/fiscal_years/${selectedFiscalYear.id}/contextual_data`);
    }
  }

  render() {
    const {
      t,
      selectedFiscalYear,
      loadingData,
    } = this.props;

    const { data } = this.state;

    if (!isEmpty(selectedFiscalYear)) {
      return (
        <Segment basic>
          <Dimmer active={loadingData} inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
          <ul>
            {!isEmpty(data) && data.map((item, index) => (
              <SidebarListItem key={index} label={item.label}>{item.value}</SidebarListItem>
            ))}
          </ul>
        </Segment>
      );
    }

    return <React.Fragment />;
  }
}

const mapDispatchToProps = dispatch => ({
  getData: page => dispatch(retrieveContextualData(page)),
});

const mapStateToProps = state => ({
  retrievedData: state.contextualData.show.retrieved,
  loadingData: state.contextualData.show.loading,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
  selectedDocument: state.userCompanies.select.selectedDocument,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ContextualData);

export default withTranslation()(Main);
