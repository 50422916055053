import React, { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SelectableNavLink from '../../../layouts/subNav/SelectableNavLink';
import { isDraft } from '../../../utils/documentStatus';
import { isPurchaseOrder } from '../../../utils/documentType';
import { State } from '../../../types/store';
import { open as openPurchaseOrder } from '../../../actions/modal/PurchaseOrderToInvoice';
import { open as openWorkOrder } from '../../../actions/modal/workOrder';
import { crudRights, Entities } from '../../../types/accessRights';
import CheckRights from '../../../components/access/CheckRights';
import { VisibilityDispatch } from '../../../types/actions';

const SelectedPurchaseOrderMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedDocument = useSelector(
    (state: State) => state.userCompanies.select.selectedDocument,
  );

  if (isDraft(selectedDocument) || !isPurchaseOrder(selectedDocument)) {
    return <React.Fragment />;
  }

  /* let canBook = false;
  if (!isEmpty(selectedDocument) && isPurchaseOrder(selectedDocument)) {
    for (const line of selectedDocument.content.body) {
      if (line.type === 'Product' && line.stockManagement) {
        canBook = true;
        break;
      }
    }
  } */

  return (
    <React.Fragment>
      <CheckRights entity={Entities.invoice} right={crudRights.create}>
        <SelectableNavLink
          label={t('documentToInvoice')}
          onClick={(): Dispatch<VisibilityDispatch> => dispatch(openPurchaseOrder())}
        />
        <CheckRights entity={Entities.workOrder}>
          <SelectableNavLink
            label={t('workOrderInvoice')}
            onClick={(): Dispatch<VisibilityDispatch> => dispatch(openWorkOrder())}
          />
        </CheckRights>
      </CheckRights>
      {/* <CheckRights entity={Entities.workOrder} right={crudRights.create}>
        <SelectableNavLink
          label={t('workOrderPurchase')}
          onClick={(): Dispatch<VisibilityDispatch> => dispatch(openWorkOrder())}
        />
      </CheckRights> */}

      {/* {(canBook && isNotDraft(selectedDocument))
            && (
            <SelectableNavLink label={t('purchaseOrderStockBooking')} onClick={openStockModal} />
      )} */}
    </React.Fragment>
  );
};

export default SelectedPurchaseOrderMenu;
