import React from 'react';
import { Modal } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';

const PromptModal = ({ dataUnsaved, t, setModelContent, onWarningClose }) => (
  <Modal
    open={dataUnsaved}
    size="mini"
  >
    <Modal.Header>{t('warning')}</Modal.Header>
    <Modal.Content scrolling>
      <Modal.Description>
        <p>{t('warningYouHaveUnsavedData')}</p>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <div>
        <EssorButton type="check" onClick={setModelContent} size="small">
          {t('buttonYes')}
        </EssorButton>
        <EssorButton secondary type="x" size="small" onClick={onWarningClose}>
          {t('buttonCancel')}
        </EssorButton>
      </div>
    </Modal.Actions>
  </Modal>
);

export default withTranslation()(PromptModal);
