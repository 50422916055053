import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from 'routes/ErrorBoundary';
import WebRoutes from './web/routes';
import Web from './web/index';
import Admin from './admin/index';
import ScrollToTop from '../components/scroll-to-top';

const Routes = () => (
  <Router>
    <ErrorBoundary>
      <ScrollToTop>
        <Switch>
          <Route path={WebRoutes.login} component={Web} />
          <Route path={WebRoutes.password} component={Web} />
          <Route path="/" component={Admin} />
        </Switch>
      </ScrollToTop>
    </ErrorBoundary>
  </Router>
);

export default Routes;
