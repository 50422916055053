import React, { FunctionComponent, ReactNode } from 'react';
import { Form, StrictFormFieldProps } from 'semantic-ui-react';

interface OwnProps extends StrictFormFieldProps {
  children: ReactNode;
}

type Props = OwnProps;

const InlineField: FunctionComponent<Props> = ({ children, ...others }) => (
  <Form.Group inline>
    <Form.Field {...others}>
      {children}
    </Form.Field>
  </Form.Group>
);

export default InlineField;
