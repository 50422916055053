import React from 'react';
import { Table } from 'semantic-ui-react';
import { floatFormat } from 'utils/formatter';
import { useTranslation } from 'react-i18next';

function SubTotalBodyLine(props) {
  const { t } = useTranslation();
  const { item, subTotal } = props;

  return (
    <React.Fragment>
      <Table.Cell colSpan={7}>{t('documentSubtotal')}</Table.Cell>
      <Table.Cell textAlign="center">
        {floatFormat(subTotal(item.order), true)}
      </Table.Cell>
    </React.Fragment>
  );
}

export default SubTotalBodyLine;
