/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { findIndex, isEmpty } from 'lodash';
import { list as listInvoices, reset as resetInvoiceList } from 'actions/invoice/list';
import { create as createRecovery, error as errorRecovery, loading as loadingRecovery, success as successRecovery } from 'actions/recovery-notice/create';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { ASC, DESC, STATUS_PENDING } from 'utils/constants';

import 'moment/locale/fr';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import CloseButton from 'components/buttons/CloseButton';
import SaveButton from 'components/buttons/SaveButton';
import { crudRights, Entities } from 'types/accessRights';
import AddHeader from 'components/pageHeaders/AddHeader';
import BackHeader from 'components/pageHeaders/BackHeader';
import SmallForm from 'layouts/SmallForm';
import AdvancedList from 'components/advancedList/AdvancedList';
import { customerDisplay, getPaymentData } from 'components/documents/documentCommon';
import { columnDefinition, handlerDefinition } from 'utils/tables';
import { option } from 'utils/functions';
import { apiDateFormat } from '../../../../utils/formatter';
import API_ENTRYPOINT from '../../../../config/_entrypoint';
import DocumentLink from '../../../../components/documents/DocumentLink';

moment.locale('fr');

class ListPayment extends Component {
  state = {
    invoiceList: [],
    openNoticeModal: false,
    showForm: false,
    showList: false,
    invoiceEdit: null,

    level: null,
    date: null,
    comment: '',

    levelError: false,
    dateError: false,
    commentError: false,
  };

  componentDidMount() {
    const { getInvoices, selectedCompany } = this.props;

    const url = new URL('/invoices', API_ENTRYPOINT);
    url.searchParams.append('company', selectedCompany.id);
    url.searchParams.append('pagination', 'false');
    url.searchParams.append('status', STATUS_PENDING.toString());
    url.searchParams.append('paymentDate[strictly_before]', apiDateFormat(moment()));

    getInvoices(url.toString());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataInvoice) && nextProps.dataInvoice['hydra:member'] !== prevState.invoiceData) {
      let invoiceList = nextProps.dataInvoice['hydra:member'];

      invoiceList = invoiceList.map((invoice) => {
        let totalPaid = 0;
        invoice.payment.forEach((payment) => {
          totalPaid += parseFloat(payment.amount) + parseFloat(payment.vat);
        });

        invoice.total = parseFloat(invoice.totalPrice) + parseFloat(invoice.totalVAT);
        invoice.totalPaid = totalPaid;

        const daysLate = invoice.paymentDate ? moment().diff(invoice.paymentDate, 'days') + 1 : '-';

        let noticeLevel = 0;
        invoice.recovery.forEach((item) => {
          noticeLevel = Math.max(item.level, noticeLevel);
        });

        return {
          ...invoice,
          ...getPaymentData(invoice),
          daysLate,
          noticeLevel,
        };
      });

      invoiceList = invoiceList.filter(invoice => {
        return invoice.diff.round(2).abs().gt(0);
      });

      return {
        invoiceList,
        invoiceData: nextProps.dataInvoice['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.createdRecovery)
      && nextProps.createdRecovery !== prevState.recoveryAux) {
      const { invoiceList } = prevState;

      const index = findIndex(invoiceList, {
        '@id': nextProps.createdRecovery.invoice['@id'],
      });

      invoiceList[index].recovery.push(nextProps.createdRecovery);

      return {
        invoiceList,
        recoveryAux: nextProps.createdRecovery,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { createdRecovery } = this.props;

    if (!isEmpty(createdRecovery) && createdRecovery !== prevProps.createdRecovery) {
      this.toggleShow();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  openNoticeModal = (invoice) => {
    this.setState({
      openNoticeModal: true,
      showForm: false,
      showList: true,
      invoiceEdit: invoice,
    });
  };

  toggleShow = () => {
    this.setState(prevState => ({
      showForm: !prevState.showForm,
      showList: !prevState.showList,
      date: null,
      level: null,
      comment: '',
      dateError: false,
      levelError: false,
      commentError: false,
    }));
  };

  closeNoticeModal = () => {
    this.setState({
      openNoticeModal: false,
      showForm: false,
      showList: false,
      invoiceEdit: null,
      date: null,
      level: null,
      comment: '',
      dateError: false,
      levelError: false,
      commentError: false,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmitNotice = () => {
    const { invoiceEdit, level, date, comment } = this.state;
    const { postRecovery, selectedCompany } = this.props;

    this.setState({
      levelError: false,
      dateError: false,
      commentError: false,
    });

    let isValid = true;

    if (!level) {
      isValid = false;

      this.setState({
        levelError: true,
      });
    }

    if (!date) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (comment === '') {
      isValid = false;

      this.setState({
        commentError: true,
      });
    }

    if (!isValid) return;

    const data = {
      level,
      creationDate: apiDateFormat(date),
      comment,
      company: selectedCompany['@id'],
      invoice: invoiceEdit['@id'],
      totalPrice: (invoiceEdit.total - invoiceEdit.totalPaid).toString(),
    };

    postRecovery(data);
  };

  render() {
    const {
      invoiceList,
      openNoticeModal,
      showForm,
      showList,
      invoiceEdit,

      level,
      date,
      comment,

      levelError,
      dateError,
      commentError,
    } = this.state;

    const {
      loadingInvoice,
      loadingCreateRecovery,
      t,
    } = this.props;

    const getLevelDescription = (level) => {
      switch (level) {
        case 1:
          return t('recoveryFirstNotice');
        case 2:
          return t('recoverySecondNotice');
        case 3:
          return t('recoveryFormalNotice');
        default:
          return '-';
      }
    };

    const lvl = level => option(level.toString(), getLevelDescription(level), level);

    const levelOptions = [lvl(1), lvl(2), lvl(3)];

    const getNoticeLevelDescription = notice => getLevelDescription(notice.level);
    const getInvoiceLevelDescription = invoice => getLevelDescription(invoice.noticeLevel);

    const lateDisplay = invoice => `${invoice.daysLate} ${t('documentDaysAbbr')}`;

    const document = document => <DocumentLink document={document} />;

    return (
      <ContainerGeneral>
        <TitleHeader title={t('paymentsCustomerFollow')} />

        <AdvancedList
          entity={Entities.payment}
          loading={loadingInvoice}
          columns={[
            handlerDefinition('invoiceOrderNumber', 'uniqueID', document),
            handlerDefinition('documentDaysLate', 'daysLate', lateDisplay, ''),
            handlerDefinition('documentNoticeLevel', 'noticeLevel', getInvoiceLevelDescription),
            handlerDefinition('formCustomer', 'customer', customerDisplay),
            columnDefinition('documentPaidBefore', 'paymentDate', 'date'),
            columnDefinition('documentLastPayment', 'lastPayment', 'date'),
            columnDefinition('quoteTotalWithTaxes', 'actualTotal', 'currency'),
            columnDefinition('documentTotalPaid', 'totalPaid', 'currency'),
            handlerDefinition('documentBalance', 'diff', invoice => invoice.diff, 'currency'),
          ]}
          data={invoiceList}
          onView={invoice => this.openNoticeModal(invoice)}
          sortBy="daysLate"
          direction={DESC}
        />

        <Modal open={openNoticeModal} closeOnEscape closeOnDimmerClick className="mid-content">
          <Modal.Header content={invoiceEdit && invoiceEdit.uniqueID} />
          <Modal.Content scrolling>
            {showList && (
              <Modal.Description>
                <AddHeader
                  onClick={this.toggleShow}
                  title={t('followUpHomeTitle')}
                  entity={Entities.recoveryNotice}
                />

                <AdvancedList
                  entity={Entities.recoveryNotice}
                  loading={isEmpty(invoiceEdit)}
                  columns={[
                    handlerDefinition('formLevel', 'level', getNoticeLevelDescription),
                    columnDefinition('formComments', 'comment'),
                    columnDefinition('formCreationDate', 'creationDate', 'date'),
                    columnDefinition('documentBalance', 'totalPrice', 'currency'),
                  ]}
                  data={invoiceEdit.recovery}
                  sortBy="level"
                  direction={ASC}
                />
              </Modal.Description>
            )}

            {showForm && (
              <Modal.Description>
                <BackHeader onClick={this.toggleShow} disabled={loadingCreateRecovery} title="" />
                <SmallForm loading={loadingCreateRecovery}>
                  <Form.Group inline>
                    <Form.Select
                      label={t('formLevel')}
                      control={Dropdown}
                      placeholder={t('formPHSelect')}
                      fluid
                      selection
                      selectOnBlur={false}
                      options={levelOptions}
                      name="level"
                      onChange={this.handleSelectChange}
                      value={level}
                      error={levelError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formDate')}
                      name="date"
                      control={DatePicker}
                      selected={date}
                      onChange={this.handleDateChange}
                      locale="fr"
                      autoComplete="off"
                      error={dateError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.TextArea
                      label={t('formComments')}
                      name="comment"
                      value={comment}
                      onChange={this.handleInputChange}
                      error={commentError}
                    />
                  </Form.Group>
                </SmallForm>
              </Modal.Description>
            )}
          </Modal.Content>
          <Modal.Actions>
            {showForm && (
              <SaveButton
                onClick={this.handleSubmitNotice}
                loading={loadingCreateRecovery}
                disabled={loadingCreateRecovery}
                label={t('buttonSubmit')}
                entity={Entities.recoveryNotice}
                right={crudRights.create}
              />
            )}
            <CloseButton
              onClick={this.closeNoticeModal}
              loading={loadingCreateRecovery}
              disabled={loadingCreateRecovery}
            />
          </Modal.Actions>
        </Modal>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getInvoices: page => dispatch(listInvoices(page)),
  postRecovery: data => dispatch(createRecovery(data)),
  reset: () => {
    dispatch(resetInvoiceList());
    dispatch(successRecovery(null));
    dispatch(loadingRecovery(false));
    dispatch(errorRecovery(null));
  },
});

const mapStateToProps = state => ({
  dataInvoice: state.invoice.list.data,
  loadingInvoice: state.invoice.list.loading,
  errorInvoice: state.invoice.list.error,

  createdRecovery: state.recovery.create.created,
  loadingCreateRecovery: state.recovery.create.loading,
  errorCreateRecovery: state.recovery.create.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListPayment);

export default withTranslation()(withRouter(Main));
