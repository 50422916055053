import React, { FunctionComponent, SyntheticEvent, useEffect } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { State } from 'types/store';
import CancelButton from 'components/buttons/CancelButton';
import SaveButton from 'components/buttons/SaveButton';
import useQuoteReasons from 'hooks/useQuoteReasons';
import { QuoteReason } from 'types/settings';

interface OwnProps {
  dataQuoteReason: QuoteReason[];
  open: boolean;
  typeDocument: string;

  selectedQuoteReason: string;
  errorSelectedQuoteReason: boolean;

  selectReason: (reasonList: DropdownProps) => void;
  closeCompanySettingsModal: () => void;
  submitWonReason: (typeDocument: string) => void;
}

export type QuoteToInvoiceModalProps = OwnProps;

const QuoteToInvoiceModal: FunctionComponent<QuoteToInvoiceModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    open,
    typeDocument,
    selectReason,
    closeCompanySettingsModal,
    submitWonReason,
    selectedQuoteReason,
    errorSelectedQuoteReason,
  } = props;
  const dataQuoteReason = useQuoteReasons(typeDocument);
  const loadingCompanySettings = useSelector((state: State) => state.companySettings.list.loading);
  const selectedDocument = useSelector((s: State) => s.userCompanies.select.selectedDocument);

  useEffect(() => {
    if (selectedDocument) {
      selectReason({
        name: 'selectedQuoteReason',
        value: selectedDocument.reason,
      });
    }
  }, [selectedDocument]);


  let optionsCompanySettings: DropdownItemProps[] = [];

  if (!isEmpty(dataQuoteReason)) {
    optionsCompanySettings = dataQuoteReason.map((setting: QuoteReason) => ({
      key: setting.id,
      text: setting.label,
      value: setting.id,
    }));
  }

  let title = 'Conversion';
  switch (typeDocument) {
    case 'ORDER':
      title = t('quoteConvertingTo', {
        document: t('purchaseOrderNumber'),
      });
      break;
    case 'INVOICE':
      title = t('quoteConvertingTo', {
        document: t('documentNumbering_INVOICE'),
      });
      break;
    case 'LOST':
      title = t('quoteLost');
      break;
    default:
      break;
  }

  return (
    <Modal open={open} className="small-width small-height">
      <Modal.Header content={title} />
      <Modal.Content>
        <Modal.Description>
          <Form className="margin-top-bot main-form" size="small">
            <Form.Group inline>
              <Form.Select
                label={typeDocument === 'LOST' ? t('formQuoteLostReason') : t('formQuoteWonReason')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                disabled={loadingCompanySettings}
                selectOnBlur={false}
                loading={loadingCompanySettings}
                noResultsMessage="No results"
                options={optionsCompanySettings}
                name="selectedQuoteReason"
                onChange={(e: SyntheticEvent, data: DropdownProps): void => selectReason(data)}
                value={selectedQuoteReason}
                error={errorSelectedQuoteReason}
                search
                selection
                multiple
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton onClick={closeCompanySettingsModal} />
        <SaveButton onClick={(): void => submitWonReason(typeDocument)} />
      </Modal.Actions>
    </Modal>
  );
};

export default QuoteToInvoiceModal;
