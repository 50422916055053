export interface EntityRights {
  [key: string]: crudRights[];
}

export enum Entities {
  absence = 'Absence',
  budget = 'Budget',
  company = 'Company',
  companySettings = 'CompanySettings',
  creditNote = 'CreditNote',
  customer = 'Customer',
  deliveryForm = 'DeliveryForm',
  disbursement = 'Disbursement',
  documentModel = 'DocumentModel',
  documentNumbering = 'DocumentNumbering',
  employee = 'Employee',
  employeeData = 'EmployeeData',
  encasement = 'Encasement',
  ensemble = 'Ensemble',
  expert = 'Expert',
  fiscalYear = 'FiscalYear',
  followUp = 'FollowUp',
  forecastTable = 'ForecastTable',
  holiday = 'Holiday',
  hourSynthesis = 'HourSynthesis',
  identity = 'Identity',
  invoice = 'Invoice',
  office = 'Office',
  officeSettings = 'OfficeSettings',
  payment = 'Payment',
  product = 'Product',
  purchaseOrder = 'PurchaseOrder',
  quote = 'Quote',
  receipt = 'Receipt',
  recoveryNotice = 'RecoveryNotice',
  role = 'Role',
  scheduleInvoice = 'ScheduleInvoice',
  selfFinancing = 'SelfFinancing',
  service = 'Service',
  stock = 'Stock',
  stockBooking = 'StockBooking',
  stockMovement = 'StockMovement',
  subscription = 'Subscription',
  supplier = 'Supplier',
  supplyOrder = 'SupplyOrder',
  turnoverBalance = 'TurnoverBalance',
  workingCapital = 'WorkingCapital',
  workOrder = 'WorkOrder',

  pageAnalysis = 'PageAnalysis',
  pageArticles = 'PageArticles',
  pageBusiness = 'PageBusiness',
  pageCompany = 'PageCompany',
  pageContacts = 'PageContacts',
  pageEmployees = 'PageEmployees',
  pageExperts = 'PageExperts',
  pageForecast = 'PageForecast',
  pageOffices = 'PageOffices',
  pageFinances = 'PageFinances',
  pageSubscriptions = 'PageSubscriptions',
}

export enum Roles {
  superAdmin = 'ROLE_SUPER_ADMIN',
  admin = 'ROLE_ADMIN',
  expert = 'ROLE_EXPERT',
  employee = 'ROLE_EMPLOYEE',
  none = 'ROLE_NONE',
}

export enum crudRights {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  /* aliases because i keep doing these mistakes */
  edit = 'update',
  show = 'read',
  view = 'read',
}
