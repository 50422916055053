import React from 'react';
import FormatCell from 'components/cell/FormatCell';
import FormattedDate from 'components/documents/formattedDate';

const DateCell = ({ date }) => (
  <FormatCell className="format-date-fr">
    {date ? <FormattedDate date={date} /> : '-'}
  </FormatCell>
);

export default DateCell;
