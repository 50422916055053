import Big from 'big.js';
import moment, { Moment } from 'moment';

export interface BreakEvenResult {
  dateEnd: Moment;
  fixedCharges: Big;
  variableCharges: Big;
  turnover: Big;
  breakEven: Big;
  breakEvenDate: Moment;
  operatingProfit: Big;
  operatingProfitDate: Moment;
}

export const getBreakEven = (
  fixedCharges: Big,
  variableCharges: Big,
  turnover: Big,
): Big => {
  const checkForZero = (turnover.minus(variableCharges)).div(turnover.eq(0) ? 1 : turnover);
  return Big(fixedCharges).div(checkForZero.eq(0) ? 1 : checkForZero);
};

export const getBreakEvenDate = (
  breakEven: Big,
  turnover: Big,
  dateStart: string,
  durationInMonths: number,
): Moment => moment(dateStart).add(
  Big(1)
    .plus(durationInMonths)
    .times(breakEven)
    .div(turnover)
    .toFixed(0),
  'months',
);

export const getOperatingProfit = (
  fixedCharges: Big,
  variableCharges: Big,
  turnover: Big,
): Big => Big(turnover).minus(Big(fixedCharges).plus(variableCharges));

export const getDurationInMonths = (
  dateStart: string,
  dateEnd: string,
): number => Math.ceil(moment(dateStart).diff(moment(dateEnd), 'months', true));


export const getBreakEvenResult = (
  dateStart: string,
  dateEnd: string,
  fixedCharges: Big,
  variableCharges: Big,
  turnover: Big,
): BreakEvenResult => {
  const months = getDurationInMonths(dateEnd, dateStart);
  const breakEven = getBreakEven(fixedCharges, variableCharges, turnover);
  const breakEvenDate = getBreakEvenDate(breakEven, turnover, dateStart, months);
  const operatingProfit = getOperatingProfit(fixedCharges, variableCharges, turnover);
  const operatingProfitDate = getBreakEvenDate(operatingProfit, turnover, dateStart, months);

  return {
    dateEnd: moment(dateEnd),
    fixedCharges,
    variableCharges,
    turnover,
    breakEven,
    breakEvenDate,
    operatingProfit,
    operatingProfitDate,
  };
};
