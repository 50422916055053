import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import { logout } from 'actions/auth/auth';
import { selectCompany, selectEmployee, selectFiscalYear, selectTheme } from 'actions/user-companies/select';
import { setID, setRole } from 'actions/user-companies/role';
import { list as listFiscalYear } from 'actions/fiscal-year/list';
import { Dropdown, Icon, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';

import logo from 'assets/images/essorLogo.png';
import tie from 'assets/images/tie.png';
import { isAdmin, isEmployee } from 'utils/roles';
import VersionModal from 'layouts/header/VersionModal';
import { versions } from 'lang/fr/changelog.json';
import { setUser } from '@sentry/minimal';
import CompanySelector from './CompanySelector';
import { dateFormat } from '../../utils/formatter';

class Header extends Component {
  state = {
    name: 'Loading ...',
    username: 'Loading ...',
    fiscalYearList: null,
    reload: false,
    versionModal: false,
  };

  componentDidMount() {
    const {
      selectedCompany,
      getFiscalYears,
      success,
      setRole,
      setID,
    } = this.props;

    if (success) {
      const jwt = jwtDecode(success.token);

      this.setState({
        name: `${jwt.firstName} ${jwt.lastName}`,
        username: `${jwt.username}`,
      });

      setUser({
        id: jwt.id,
        name: `${jwt.firstName} ${jwt.lastName}`,
        username: `${jwt.username}`,
        role: jwt.roles[0],
      });

      setRole(jwt.roles[0]);
      setID(jwt.id);
    }

    if (selectedCompany) {
      getFiscalYears(`/fiscal_years?company=${selectedCompany['@id']}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataFiscalYear) && nextProps.dataFiscalYear['hydra:member'] !== prevState.fiscalYearList) {
      return {
        fiscalYearList: nextProps.dataFiscalYear['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedFiscalYear,
      getFiscalYears,
      selectedCompany,
      dataFiscalYear,
      selectFiscalYear,
      selectEmployee,
    } = this.props;

    const { reload } = this.state;

    if (prevProps.selectedCompany !== selectedCompany) {
      selectEmployee(null);
    }

    if (!isEmpty(selectedCompany) && selectedCompany !== prevProps.selectedCompany) {
      getFiscalYears(`/fiscal_years?company=${selectedCompany['@id']}`);
    }

    if (!isEmpty(selectedFiscalYear)
      && selectedFiscalYear !== prevProps.selectedFiscalYear
      && reload
    ) {
      window.location.reload();
    }

    if (!isEmpty(dataFiscalYear) && dataFiscalYear['hydra:member'].length >= 1) {
      if (isEmpty(selectedFiscalYear) || (selectedCompany && selectedFiscalYear.company !== selectedCompany['@id'])) {
        let fiscalYear = null;

        for (let i = 0; i < dataFiscalYear['hydra:member'].length; i++) {
          if (dataFiscalYear['hydra:member'][i].defaultYear) {
            fiscalYear = dataFiscalYear['hydra:member'][i];
            break;
          }
        }
        selectFiscalYear(fiscalYear);
      }
    } else if (!isEmpty(dataFiscalYear) && dataFiscalYear['hydra:member'].length === 0) {
      selectFiscalYear(null);
    }
  }

  handleFiscalYearChange = (value) => {
    const { selectFiscalYear } = this.props;

    selectFiscalYear(value);

    this.setState({
      reload: true,
    });
  };

  handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  render() {
    const {
      name,
      username,
      fiscalYearList,
      versionModal,
    } = this.state;

    const {
      logout,
      loadingFiscalYear,
      selectedCompany,
      userRole,
      selectedFiscalYear,
      t,
      theme,
      selectTheme,
    } = this.props;

    const version = versions[0].label;

    const trigger = (
      <div className="nav-user">
        <div className="user-data">
          <div>{name}</div>
          <div>{username}</div>
          <div>
            {(!isEmpty(selectedFiscalYear) && !loadingFiscalYear)
              ? `${dateFormat(selectedFiscalYear.dateStart)}
                - ${dateFormat(selectedFiscalYear.dateEnd)}`
              : ''
            }
          </div>
        </div>
        <div className="avatar">
          <Image src={tie} alt="tie_icon" />
        </div>
      </div>
    );

    const fiscalTrigger = (
      <div className="nav-select">
        {selectedFiscalYear
          ? selectedFiscalYear.label
          : t('selectFiscalYear')
        }
      </div>
    );
    const setModalState = (state) => {
      this.setState({
        versionModal: state,
      });
    };

    return (
      <React.Fragment>
        <div className="forbidden">
          <Image
            src={logo}
            alt="essor_logo"
            style={
              {
                margin: '30px auto',
                width: '350px',
                height: '80px',
              }
            }
          />
          <div
            style={
              {
                display: 'block',
                textAlign: 'center',
                marginTop: '60px',
              }
            }
          >
            <h1>{t('noticeResolutionForbidden')}</h1>
          </div>
        </div>
        <div className="header-container">
          <Link to="/" className="logo">
            <Image src={logo} alt="essor_logo" />
          </Link>
          <div className="navbar">
            <div className="nav-toggle" />

            <div
              style={{
                flex: '1',
              }}
            />

            {/* <Form className="search-options">
              <Form.Group inline>
                <Form.Field control={Select} options={options} defaultValue="contact" compact />
                <Form.Field>
                  <Input />
                </Form.Field>
                <Form.Field>
                  <Button icon>
                    <Icon name="search" />
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form> */}

            <CompanySelector />

            <Dropdown
              className="selection-dropdown fiscal-dropdown"
              trigger={fiscalTrigger}
              disabled={!selectedCompany}
            >
              <Dropdown.Menu>
                {loadingFiscalYear && (
                  <Dropdown.Item disabled>
                    <Icon loading name="spinner" />
                    {t('loading')}
                  </Dropdown.Item>
                )}

                {fiscalYearList && fiscalYearList.length !== 0 && fiscalYearList.length !== null
                  ? (
                    <React.Fragment>
                      {fiscalYearList.map((fiscalYear) => {
                        if (selectedFiscalYear && fiscalYear['@id'] === selectedFiscalYear['@id']) {
                          return null;
                        }
                        return (
                          <Dropdown.Item
                            key={fiscalYear['@id']}
                            text={fiscalYear.label}
                            onClick={() => this.handleFiscalYearChange(fiscalYear)}
                          />
                        );
                      })}
                      <Dropdown.Item
                        as={Link}
                        to="/company/fiscal_years/create"
                        text={t('fiscalYearsCreateTitle')}
                      />
                    </React.Fragment>
                  ) : (
                    <Dropdown.Item
                      as={Link}
                      to="/company/fiscal_years/create"
                      text={t('fiscalYearsCreateTitle')}
                    />
                  )
                }
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown trigger={trigger} icon={null}>
              <Dropdown.Menu>
                <Dropdown.Item text={version} onClick={() => setModalState(true)} className="small" />
                <Dropdown.Divider />
                {(userRole && !isEmployee(userRole)) && (
                  <React.Fragment>
                    <Dropdown.Item
                      as={Link}
                      to="/experts"
                      text={t('users')}
                    />
                    <Dropdown.Divider />
                  </React.Fragment>
                )}

                {(userRole && isAdmin(userRole)) && (
                  <React.Fragment>
                    <Dropdown.Item as={Link} to="/offices" text={t('offices')} />
                    <Dropdown.Divider />
                  </React.Fragment>
                )}

                <Dropdown.Item
                  key="english"
                  flag="us"
                  text="English"
                  onClick={() => this.handleChangeLanguage('en')}
                />
                <Dropdown.Item
                  key="french"
                  flag="fr"
                  text="Français"
                  onClick={() => this.handleChangeLanguage('fr')}
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  text={`${theme === 'dark' ? '☀' : '🌛'} Changer de thème`}
                  onClick={() => selectTheme(theme === 'dark' ? 'light' : 'dark')}
                />
                <Dropdown.Divider />
                <Dropdown.Item text={t('logout')} onClick={logout} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <VersionModal open={versionModal} close={() => setModalState(false)} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectEmployee: emp => dispatch(selectEmployee(emp)),
  getFiscalYears: page => dispatch(listFiscalYear(page)),
  selectFiscalYear: fiscalYear => dispatch(selectFiscalYear(fiscalYear)),
  selectCompany: company => dispatch(selectCompany(company)),
  selectTheme: theme => dispatch(selectTheme(theme)),
  setRole: role => dispatch(setRole(role)),
  setID: id => dispatch(setID(id)),
  logout: () => dispatch(logout()),
});

const mapStateToProps = state => ({
  success: state.auth.created,
  error: state.auth.error,
  loading: state.auth.loading,

  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
  selectedCompany: state.userCompanies.select.selectedCompany,
  theme: state.userCompanies.select.theme,
  userRole: state.userCompanies.role.userRole,

  dataFiscalYear: state.fiscalYear.list.data,
  loadingFiscalYear: state.fiscalYear.list.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withTranslation()(withRouter(Main));
