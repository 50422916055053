import React, { FunctionComponent } from 'react';

interface OwnProps {
  label: string;
  onClick: () => void;
}

type Props = OwnProps;

const SelectableNavLink: FunctionComponent<Props> = ({ label, onClick }) => (
  <li className="menu-item">
    <div className="selectable" onClick={onClick}>
      {label}
    </div>
  </li>
);

export default SelectableNavLink;
