import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { reset, retrieve } from 'actions/product/show';
import Routes from 'routes/admin/articles/routes';

import ContainerGeneral from 'layouts/ContainerGeneral';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import ShowProduct from './product';
import CreateProduct from '../create';
import Stock from '../stock';
import NotFound from '../../../404';
import WrongDocument from '../../../../../layouts/error-pages/WrongDocument';

const Product = (props) => {
  const { match } = props;
  const retrievedProduct = useSelector(state => state.product.show.retrieved);
  const retrievedError = useSelector(state => state.product.show.error);
  const loadingProduct = useSelector(state => state.product.show.loading);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(retrieve(`/products/${match.params.id}`));
      return () => {
        dispatch(reset());
      };
    },
    [match],
  );

  if (retrievedError) {
    return <WrongDocument />;
  }

  if (loadingProduct || isEmpty(retrievedProduct)) {
    return <ContainerGeneral status={loadingProduct || isEmpty(retrievedProduct)} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <CheckRoute
          exact
          path={Routes.editProduct}
          entity={Entities.product}
          right={crudRights.update}
          component={CreateProduct}
        />
        <CheckRoute
          exact
          path={Routes.showProduct}
          entity={Entities.product}
          right={crudRights.read}
          component={ShowProduct}
        />
        <Route
          path="/articles/products/:id/stock"
          entity={Entities.stock}
          right={crudRights.read}
          component={Stock}
        />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(Product);
