import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { reset, retrieve } from 'actions/company-settings/show';
import { Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import TableLoader from 'components/TableLoader';

class OpeningHours extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=OPENING_HOURS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'OPENING_HOURS'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, t } = this.props;

    let getHours = 0;

    const getHoursTotal = (t, f) => {
      const start = moment.duration(f, 'HH:mm');
      const end = moment.duration(t, 'HH:mm');
      const diff = end.subtract(start);
      getHours += diff.as('milliseconds');

      return moment.utc(diff.as('milliseconds')).format('HH:mm');
    };

    const formatTotalHour = (getHours) => {
      let hours = (getHours / 1000) / 3600;

      hours = parseInt(hours, 10);
      const minutes = moment.utc(getHours).format('mm');

      return `${hours}:${minutes}`;
    };

    const dataHours = [];

    if (!isEmpty(value)) {
      const sorter = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];

      sorter.forEach((k) => {
        if (value[k]) {
          dataHours.push({
            day: k,
            hours: value[k],
          });
        }
      });
    }

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesOpeningHours')}
          to="/company/settings/opening-hours/edit"
          entity={Entities.companySettings}
        />
        <TableLoader status={loading}>
          <Table celled structured className="margin-bot">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('formDays')}</Table.HeaderCell>
                <Table.HeaderCell>{t('formFrom')}</Table.HeaderCell>
                <Table.HeaderCell>{t('formTo')}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">{t('companiesTotal')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataHours.map((item, index) => (
                <React.Fragment key={index}>
                  {item.hours.map((hour, index) => (
                    <Table.Row key={`t${index}`}>
                      {index === 0
                      && (
                        <Table.Cell
                          rowSpan={item.hours.length}
                          className="box-right"
                        >
                          {t(item.day)}
                        </Table.Cell>
                      )}
                      <Table.Cell collapsing textAlign="center">
                        {hour.from}
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="center">
                        {hour.to}
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="center">
                        {getHoursTotal(hour.to, hour.from, true)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))}
              <Table.Row>
                <Table.Cell />
                <Table.Cell colSpan={2} className="border-top-color">
                  {t('companiesTotal')}
                </Table.Cell>
                <Table.Cell>{formatTotalHour(getHours)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(OpeningHours);

export default withTranslation()(withRouter(Main));
