import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { withTranslation } from 'react-i18next';
import { getQuoteStatus } from 'utils/documentStatus';
import FormattedDate from 'components/documents/formattedDate';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { customerDisplay, estimate } from 'components/documents/documentCommon';
import { uniqueId } from 'components/documents/uniqueId';
import FollowUpModal from 'routes/admin/business/quote/list/followUpModal';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/business/routes';
import { Entities } from 'types/accessRights';
import SelectableCell from 'layouts/table/SelectableCell';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { cellDefinition, columnDefinition, handlerDefinition } from 'utils/tables';
import ListLoading from 'components/ListLoading';
import { TableList } from 'components';

moment.locale('fr');

class ListQuote extends Component {
  state = {
    documents: {},
    followUpModal: false,
    editQuote: null,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataQuote) && nextProps.dataQuote['hydra:member'] !== prevState.documents) {
      return {
        documents: nextProps.dataQuote['hydra:member'],
      };
    }

    return null;
  }

  selectDocument = (quote) => {
    const { selectDocument, history } = this.props;

    selectDocument(quote);

    history.push(`/business/quotes/${quote.id}`);
  };

  openFollowUpModal = (quote) => {
    this.setState({
      editQuote: quote,
      followUpModal: true,
    });
  };

  closeFollowUpModal = () => {
    this.setState({
      editQuote: null,
      followUpModal: false,
    });
  };

  render() {
    const { documents, followUpModal, editQuote } = this.state;
    const { loadingQuote, dataQuote, t } = this.props;

    const statusCell = document => (
      <BusinessStatusCell document={document} handler={getQuoteStatus} key={`b${document['@id']}`} />
    );

    const followUpCell = document => (
      <SelectableCell onClick={() => this.openFollowUpModal(document)} key={`f${document['@id']}`}>
        <FormattedDate
          date={isEmpty(document.followUp) ? null : document.followUp[0].date}
        />
      </SelectableCell>
    );

    return (
      <ContainerGeneral>
        <ListLoading loaded={documents.length} total={dataQuote['hydra:totalItems'] || 0} />
        <AddHeader
          title={t('quotesHomeTitle')}
          to={Routes.createQuote}
          entity={Entities.quote}
        />
        <TableList
          columns={[
            handlerDefinition('documentNumber', 'uniqueID', uniqueId),
            columnDefinition('documentsHeaderCreationDate', 'creationDate', 'date'),
            handlerDefinition('formCustomer', 'customer', customerDisplay),
            columnDefinition('documentTotal', 'totalPrice', 'currency'),
            handlerDefinition('formEstimation', 'estimation', estimate),
            cellDefinition('documentsHeaderFollowUpDate', 'followUp', followUpCell, 'date'),
            columnDefinition('formResponseDate', 'responseDate', 'date'),
            cellDefinition('documentStatus', 'status', statusCell, '', getQuoteStatus),
          ]}
          filterBtn
          exportBtn
          loading={loadingQuote}
          data={documents || []}
          onBusiness
          // onDelete={showDeleteWarning}
          entity={Entities.quote}
          sortBy="creationDate"
        />

        <FollowUpModal
          visible={followUpModal}
          quote={editQuote}
          closeHandler={this.closeFollowUpModal}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: quote => dispatch(selectDocument(quote)),
});

const mapStateToProps = state => ({
  dataQuote: state.quote.list.data,
  loadingQuote: state.quote.list.loading,
  errorQuote: state.quote.list.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListQuote);

export default withTranslation()(withRouter(Main));
