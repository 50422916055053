import React from 'react';

const showQuote = () => (
  <div className="section-container">
    <div className="section-general">
      Show Quote
    </div>
  </div>
);

export default showQuote;
