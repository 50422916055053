import React from 'react';
import { Grid } from 'semantic-ui-react';

function SingleRowColumn(props) {
  const { width, children } = props;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={width}>
          {children}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default SingleRowColumn;
