import React from 'react';
import { floatFormat } from 'utils/formatter';

function Percentage(props) {
  const { value, decimal = 2, showPositive } = props;

  return (
    <React.Fragment>
      {showPositive && value > 0 && ('+')}
      {`${floatFormat((value || 'NaN').toString(), false, decimal)} %`}
    </React.Fragment>
  );
}

export default Percentage;
