import React from 'react';
import classnames from 'classnames';
import { Big } from 'utils/functions';
import AlignedCell from 'layouts/table/AlignedCell';

function FormatCell({ children, className, value, color, noGreen, align = 'left', header = false }) {
  const negative = (value && Big(value).lt(0)) || children.toString().startsWith('-');

  return (
    <AlignedCell
      className={classnames(`format-number-fr ${className || ''}`, {
        'cell-positive': color && !noGreen && !negative,
        'cell-negative': color && negative,
      })}
      textAlign={align}
      header={header}
      content={children}
    />
  );
}

FormatCell.defaultProps = {
  children: undefined,
  className: undefined,
  color: false,
  noGreen: false,
  value: undefined,
};

export default FormatCell;
