import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmployee } from 'utils/roles';
import { Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import CompanyNav from 'layouts/subNav/CompanyNav';
import CompanySituation from 'routes/admin/situation/CompanySituation';
import Routes from 'routes/routes';
import CreateCompany from './create';
import CreateDocumentNumbering from './document-numbering';
import HomeCompanies from './home';
import ShowCompany from './show';
import FiscalYear from './fiscal-year';
import Holidays from './holidays';
import CompanySettings from './settings';
import NotFound from '../404';

const Company = () => {
  const userRole = useSelector(state => state.userCompanies.role.userRole);

  return (
    <Fragment>
      <CompanyNav />

      <Switch>
        <CheckRoute
          exact
          entity={Entities.company}
          path={Routes.Company.home}
          component={ShowCompany}
        />
        <CheckRoute
          exact
          entity={Entities.company}
          path={Routes.Company.situation}
          component={CompanySituation}
        />
        <CheckRoute
          exact
          entity={Entities.company}
          path={Routes.Company.edit}
          component={CreateCompany}
          key="edit"
        />
        <CheckRoute
          exact
          entity={Entities.holiday}
          path={Routes.Company.holidays}
          component={Holidays}
        />
        <CheckRoute
          exact
          entity={Entities.documentNumbering}
          path={Routes.Company.documentNumbering}
          component={CreateDocumentNumbering}
        />
        <CheckRoute
          exact
          entity={Entities.company}
          path={Routes.Company.list}
          render={props => (isEmployee(userRole) ? (<NotFound />) : (<HomeCompanies {...props} />))}
        />
        <CheckRoute
          exact
          entity={Entities.company}
          path={Routes.Company.create}
          key="create"
          render={props => (isEmployee(userRole) ? (<NotFound />) : (<CreateCompany {...props} />))}
        />
        <CheckRoute
          entity={Entities.fiscalYear}
          path={Routes.Company.fiscalYears}
          component={FiscalYear}
        />
        <CheckRoute
          entity={Entities.companySettings}
          path={Routes.Company.settings}
          component={CompanySettings}
        />
        <RouteNotFound />
      </Switch>
    </Fragment>
  );
};

export default Company;
