import React, { FunctionComponent, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import CurrencyCell from '../cell/CurrencyCell';
import RightCell from '../../layouts/table/RightCell';

interface OwnProps {
  children?: ReactNode;
  title: string;
  value?: number;
}

type Props = OwnProps;

const TotalLine: FunctionComponent<Props> = ({ children, title, value }) => (
  <Table.Row textAlign="right">
    <RightCell content={title} />
    {children ? <RightCell>{children}</RightCell> : <CurrencyCell value={value} />}
  </Table.Row>
);

export default TotalLine;
