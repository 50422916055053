import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocument } from 'actions/user-companies/select';
import { useTranslation } from 'react-i18next';
import { getInvoiceStatus } from 'utils/documentStatus';
import { customerDisplay } from 'components/documents/documentCommon';
import { uniqueId } from 'components/documents/uniqueId';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { getActualTotal } from 'components/documents/documents';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/business/routes';
import { Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import UnexpectedError from 'layouts/error-pages/UnexpectedError';
import ListLoading from 'components/ListLoading';
import { cellDefinition, columnDefinition, handlerDefinition } from 'utils/tables';
import { TableList } from 'components';

const ListInvoice = () => {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState({});

  const { data: invoices, loading, error } = useSelector(state => state.invoice.list);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectDocument(null));
  }, []);

  useEffect(() => {
    setDocuments(invoices['hydra:member'] || {});
  }, [invoices]);

  if (error) {
    return <UnexpectedError />;
  }
  const statusCell = document => (
    <BusinessStatusCell document={document} handler={getInvoiceStatus} />
  );

  const actualTotal = document => getActualTotal(document).toFixed(2);

  return (
    <ContainerGeneral>
      <ListLoading loaded={documents.length} total={invoices['hydra:totalItems'] || 0} />
      <AddHeader to={Routes.createInvoice} title={t('invoiceHomeTitle')} entity={Entities.invoice} />

      <TableList
        columns={[
          handlerDefinition('documentNumber', 'uniqueID', uniqueId),
          columnDefinition('documentsHeaderCreationDate', 'creationDate', 'date'),
          handlerDefinition('formCustomer', 'customer', customerDisplay),
          handlerDefinition('documentTotal', 'totalPrice', actualTotal, 'currency'),
          columnDefinition('quotePaymentDate', 'paymentDate', 'date'),
          cellDefinition('documentStatus', 'status', statusCell, '', getInvoiceStatus),
        ]}
        loading={loading}
        data={documents || []}
        onBusiness
        // onDelete={showDeleteWarning}
        entity={Entities.invoice}
        sortBy="creationDate"
      />
    </ContainerGeneral>
  );
};

export default ListInvoice;
