import React, { FunctionComponent } from 'react';
import { Icon } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface OwnProps {
  icon: SemanticICONS;
  tip: string;
}

type Props = OwnProps;

const InlineTooltip: FunctionComponent<Props> = ({ icon, tip }) => (
  <span className="m-l-5">
    <Icon name={icon} data-tip={tip} />
    <ReactTooltip className="essor-tooltip" effect="solid" place="bottom" />
  </span>
);

export default InlineTooltip;
