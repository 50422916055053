import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { canTransformToInvoice, canTransformToPurchaseOrder, isDraft, isPending } from '../../../utils/documentStatus';
import { change } from '../../../actions/quote/change';
import SelectableNavLink from '../../../layouts/subNav/SelectableNavLink';
import { State } from '../../../types/store';
import { isQuote } from '../../../utils/documentType';

interface OwnProps {
  openCompanySettingsModal: (link: string, typeDocument: string) => void;
}

type Props = OwnProps;

const SelectedQuoteMenu: FunctionComponent<Props> = ({ openCompanySettingsModal }) => {
  const { t } = useTranslation();

  const document = useSelector(
    (state: State) => state.userCompanies.select.selectedDocument,
  );

  const dispatch = useDispatch();

  if (!isQuote(document)) {
    return (<React.Fragment />);
  }

  return (
    <React.Fragment>
      {canTransformToPurchaseOrder(document) && (
        <SelectableNavLink
          label={t('documentToPurchaseOrder')}
          onClick={(): void => openCompanySettingsModal('/to_purchase_order', 'ORDER')}
        />
      )}
      {canTransformToInvoice(document) && (
        <SelectableNavLink
          label={t('documentToInvoice')}
          onClick={(): void => openCompanySettingsModal('/to_invoice', 'INVOICE')}
        />
      )}
      {isDraft(document) && (
        <SelectableNavLink
          label={t('validateQuote')}
          onClick={(): void => { dispatch(change(`${document['@id']}/to_pending`, 'PUT')); }}
        />
      )}
      {isPending(document) && (
        <SelectableNavLink
          label={t('quoteLost')}
          onClick={(): void => openCompanySettingsModal('/to_closed', 'LOST')}
        />
      )}
    </React.Fragment>
  );
};

export default SelectedQuoteMenu;
