import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../types/store';
import { updatePrint } from '../../../utils/print';
import { reset } from '../../../actions/document/print';

export const PrinterContext = React.createContext({
  // tslint:disable-next-line:no-empty
  setFileName: (fileName: string) => {},
});

interface Props {
  children: ReactNode;
}

const DocumentPrinter: FunctionComponent<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('Document');
  const urlDocument = useSelector((state: State) => state.document.print.url);

  useEffect(() => {
    updatePrint(urlDocument, '', () => dispatch(reset()), fileName);
  }, [urlDocument]);

  return (
    <PrinterContext.Provider
      value={{
        setFileName,
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
};

export default DocumentPrinter;
