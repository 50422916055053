import React from 'react';

const showModel = () => (
  <div className="section-container">
    <div className="section-general">
      Show Model
    </div>
  </div>
);

export default showModel;
