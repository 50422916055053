import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listPurchaseOrder, reset as resetPurchaseOrderList, success as setPurchaseOrderList } from 'actions/purchase-order/list';
import { crudRights, Entities } from 'types/accessRights';
import Routes from 'routes/admin/business/routes';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import ListPurchaseOrder from './list';
import CreatePurchaseOrder from './edit';
import ShowPurchaseOrder from './show';

class PurchaseOrder extends Component {
  componentDidMount() {
    const { getPurchaseOrders, selectedCompany, selectDocument, selectedFiscalYear } = this.props;

    let limit = '';
    if (selectedFiscalYear) {
      limit += `&creationDate[after]=${selectedFiscalYear.dateStart}`;
      limit += `&creationDate[before]=${selectedFiscalYear.dateEnd}`;
    }

    selectDocument(null);
    getPurchaseOrders(`/purchase_orders?company=${selectedCompany.id}&pagination=true${limit}`);
  }

  componentDidUpdate(prevProps) {
    const {
      changedPurchaseOrder,
      listPurchaseOrder,
      selectedDocument,
      setPurchaseOrderList,
      history,
    } = this.props;

    if (!isEmpty(changedPurchaseOrder)
      && changedPurchaseOrder !== prevProps.changedPurchaseOrder
      && changedPurchaseOrder['@id'] !== selectedDocument['@id']) {
      if (changedPurchaseOrder['@type'] === selectedDocument['@type']) {
        listPurchaseOrder['hydra:member'].push(changedPurchaseOrder);
        listPurchaseOrder['hydra:totalItems'] += 1;
        setPurchaseOrderList(listPurchaseOrder);
      }

      history.push(`/business${changedPurchaseOrder['@id']}/edit`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <CheckRoute
            entity={Entities.purchaseOrder}
            right={crudRights.read}
            exact
            path={Routes.listPurchaseOrders}
            component={ListPurchaseOrder}
          />
          <CheckRoute
            entity={Entities.purchaseOrder}
            right={crudRights.create}
            exact
            path={Routes.createPurchaseOrders}
            key="create"
            component={CreatePurchaseOrder}
          />
          <CheckRoute
            entity={Entities.purchaseOrder}
            right={crudRights.update}
            exact
            path={Routes.editPurchaseOrders}
            render={props => (
              <CreatePurchaseOrder key={props.match.params.id} {...props} />
            )}
          />
          <CheckRoute
            entity={Entities.purchaseOrder}
            right={crudRights.read}
            exact
            path={Routes.showPurchaseOrders}
            component={ShowPurchaseOrder}
          />
          <RouteNotFound />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPurchaseOrders: page => dispatch(listPurchaseOrder(page)),
  setPurchaseOrderList: purchaseOrders => dispatch(setPurchaseOrderList(purchaseOrders)),
  selectDocument: purchaseOrder => dispatch(selectDocument(purchaseOrder)),
  reset: () => {
    dispatch(resetPurchaseOrderList());
  },
});

const mapStateToProps = state => ({
  listPurchaseOrder: state.purchaseOrder.list.data,
  loadingPurchaseOrder: state.purchaseOrder.list.loading,
  errorPurchaseOrder: state.purchaseOrder.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  changedPurchaseOrder: state.purchaseOrder.change.changed,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);

export default withRouter(Main);
