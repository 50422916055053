import { Roles } from '../types/accessRights';

export function isSuperAdmin(userRole: Roles): boolean {
  return userRole === Roles.superAdmin;
}

export function isAdmin(userRole: Roles): boolean {
  return userRole === Roles.admin || isSuperAdmin(userRole);
}

export function isExpert(userRole: Roles): boolean {
  return userRole === Roles.expert;
}

export function isEmployee(userRole: Roles): boolean {
  return userRole === Roles.employee;
}
