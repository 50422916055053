import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CellButton, { ExtendProps } from './CellButton';

const ViewCellButton: FC<ExtendProps> = ({ tip, ...others }) => {
  const { t } = useTranslation();

  return (
    <CellButton icon="eye" tip={tip || t('buttonDetails')} {...others} />
  );
};

export default ViewCellButton;
