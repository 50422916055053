import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import LeftCell from '../../../layouts/table/LeftCell';

const SituationSeparatorRow: FC = () => (
  <Table.Row>
    <LeftCell colSpan={7} />
  </Table.Row>
);

export default SituationSeparatorRow;
