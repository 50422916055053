import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { update, reset } from 'actions/office-settings/update';
import { Form, Grid, Header } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';

class BankAccounts extends Component {
  state = {
    value: {
      bankName: '',
      IBAN: '',
      BIC: '',
    },
    bankNameError: false,
    IBANError: false,
    BICError: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedSetting)
      && nextProps.retrievedSetting['hydra:member'][0]['@id'] !== prevState.id
      && nextProps.retrievedSetting['hydra:member'][0].name === 'BANK_ACCOUNTS'
    ) {
      return {
        id: nextProps.retrievedSetting['hydra:member'][0]['@id'],
        value: nextProps.retrievedSetting['hydra:member'][0].value,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: value,
        },
      }
    ));
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const { value } = this.state;

    const { update, retrievedSetting } = this.props;

    const data = {
      value,
    };

    update(retrievedSetting['hydra:member'][0], data);
  };

  render() {
    const {
      id,
      bankNameError,
      IBANError,
      BICError,
      value: {
        bankName,
        IBAN,
        BIC,
      },
    } = this.state;

    const {
      retrieveLoading,
      selectedOffice,
      updated,
      updateLoading,
      office,
      match,
      t,
    } = this.props;

    if (updated && office && id) {
      return (
        <Redirect
          push
          to={{
            pathname: `/offices/${match.params.id}/settings/subscription-data/edit`,
            office,
          }}
        />
      );
    }

    if (updated && !office && id) {
      return (
        <Redirect
          push
          to={`/offices/${match.params.id}/settings/bank-accounts`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {office && `${t('step')} 4 / 6: `}
              {t('officesBankAccounts')}
            </Header>
            {!office
              && (
                <EssorButton as={Link} to={`/offices/${selectedOffice.id}/settings/bank-accounts`} type="edit" size="tiny" floated="right">
                  {t('buttonBack')}
                </EssorButton>
              )}
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formBankName')}
                      name="bankName"
                      placeholder={t('formPHBankName')}
                      value={bankName}
                      onChange={this.handleInputChange}
                      error={bankNameError}
                    />
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Input
                      label={t('formIBAN')}
                      name="IBAN"
                      placeholder={t('formPHIBAN')}
                      value={IBAN}
                      onChange={this.handleInputChange}
                      error={IBANError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formBIC')}
                      name="BIC"
                      placeholder={t('formPHBIC')}
                      value={BIC}
                      onChange={this.handleInputChange}
                      error={BICError}
                    />
                  </Form.Group>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {office ? t('buttonNext') : t('buttonSubmit')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  updateError: state.officeSettings.update.updateError,
  updateLoading: state.officeSettings.update.updateLoading,
  updated: state.officeSettings.update.updated,

  retrievedSetting: state.officeSettings.show.retrieved,
  selectedOffice: state.userCompanies.select.selectedOffice,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(BankAccounts);

export default withTranslation()(withRouter(Main));
