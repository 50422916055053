import React from 'react';
import { floatFormat } from 'utils/formatter';

function Float(props) {
  const { value } = props;

  return (
    <React.Fragment>
      {floatFormat(value.toString())}
    </React.Fragment>
  );
}

export default Float;
