import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'semantic-ui-react';

interface OwnProps {
  children: ReactNode;
  loading?: boolean;
}

type Props = OwnProps;

const SmallForm: FunctionComponent<Props> = ({ children, loading }) => (
  <Form loading={loading} className="margin-top-bot main-form" size="small">
    {children}
  </Form>
);

export default SmallForm;
