import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { findIndex, isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listCreditNote, reset as resetCreditNoteList, success as setCreditNoteList } from 'actions/credit-note/list';

import ContainerGeneral from 'layouts/ContainerGeneral';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import CreateCreditNote from './edit';
import ListCreditNote from './list';

class CreditNote extends Component {
  componentDidMount() {
    const { getCreditNotes, selectedCompany, selectDocument } = this.props;

    selectDocument(null);
    getCreditNotes(`/credit_notes?company=${selectedCompany.id}&pagination=false`);
  }

  componentDidUpdate(prevProps) {
    const {
      updatedCreditNote,
      listCreditNote,
      setCreditNoteList,
    } = this.props;

    if (!isEmpty(updatedCreditNote) && updatedCreditNote !== prevProps.updatedCreditNote) {
      const index = findIndex(listCreditNote['hydra:member'], {
        '@id': updatedCreditNote['@id'],
      });

      listCreditNote['hydra:member'][index] = updatedCreditNote;
      setCreditNoteList(listCreditNote);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { listCreditNote, loadingCreditNote } = this.props;

    if (loadingCreditNote || isEmpty(listCreditNote)) {
      return (
        <ContainerGeneral status={loadingCreditNote} />
      );
    }

    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.creditNote}
          path="/business/credit_notes"
          component={ListCreditNote}
        />
        <CheckRoute
          exact
          entity={Entities.creditNote}
          right={crudRights.update}
          path="/business/credit_notes/:id/edit"
          render={props => (
            <CreateCreditNote key={props.match.params.id} {...props} />
          )}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCreditNotes: page => dispatch(listCreditNote(page)),
  setCreditNoteList: quotes => dispatch(setCreditNoteList(quotes)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset: () => {
    dispatch(resetCreditNoteList());
  },
});

const mapStateToProps = state => ({
  listCreditNote: state.creditNote.list.data,
  loadingCreditNote: state.creditNote.list.loading,
  errorCreditNote: state.creditNote.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  updatedCreditNote: state.creditNote.update.updated,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreditNote);

export default withRouter(Main);
