import React, { Component } from 'react';
import { withRouter, Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { list as listSubscription } from 'actions/subscription/list';
import { create as createSubscription, error as errorSubscription, loading as loadingSubscription, success as successSubscription } from 'actions/subscription/create';
import { create as createSchedule, error as errorSchedule, loading as loadingSchedule, success as successSchedule } from 'actions/schedule-invoice/create';
import { list as listModel, reset as resetListModel } from 'actions/document-model/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { Form, Grid, Header, Input, Select, Dimmer, Loader, Table, Segment, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { EssorButton, toast } from 'components';
import { withTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import classnames from 'classnames';
import { floatFormat, getIntCleaveFormat } from 'utils/formatter';
import { getCustomerList, getModelList } from 'components/documents/documentOptions';
import { getDaysOptions, getFrequencyOptions } from '../subscriptionOptions';

moment.locale('fr');

class CreateRecipient extends Component {
  state = {
    model: null,
    customer: null,
    date: null,
    occurrences: '',
    frequency: 1,
    frequencyUnit: null,
    selectedDay: null,
    newDate: null,
    modelError: false,
    customerError: false,
    dateError: false,
    occurrencesError: false,
    frequencyError: false,
    frequencyUnitError: false,
    selectedDayError: false,
    newDateError: false,

    scheduleData: [],
    hasDataChanged: false,
  };

  componentDidMount() {
    const { getModels, getCustomers, selectedCompany } = this.props;

    getModels(`/document_models?company=${selectedCompany.id}&pagination=false`);
    getCustomers(`/customers?company=${selectedCompany.id}&pagination=false`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listModel) && nextProps.listModel['hydra:member'] !== prevState.modelList) {
      return {
        modelList: nextProps.listModel['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { createdSubscription } = this.props;

    if (!isEmpty(createdSubscription)
      && createdSubscription !== prevProps.createdSubscription) {
      this.postSchedule(createdSubscription);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  postSchedule = (subscription) => {
    const { scheduleData } = this.state;
    const { postSchedule, history, selectedCompany, getSubscriptions } = this.props;

    const schedule = scheduleData.map(item => ({
      date: item.date.format('YYYY-MM-DD'),
      subscription: subscription['@id'],
    }));

    const promises = [];

    for (let i = 0; i < schedule.length; i++) {
      promises.push(postSchedule(schedule[i]));
    }

    Promise.all(promises)
      .then(() => {
        getSubscriptions(`/subscriptions?company=${selectedCompany.id}`);
        history.push('/subscriptions');
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });

    if (name === 'frequencyUnit') {
      this.setState({
        selectedDay: null,
      });
    }
  };

  handleDateChange = (date, name) => {
    this.setState({
      [name]: date,
      hasDataChanged: true,
    });
  };

  handleGenerateSchedule = () => {
    const {
      model,
      customer,
      date,
      occurrences,
      frequency,
      frequencyUnit,
      selectedDay,

      modelList,
    } = this.state;

    this.setState({
      modelError: false,
      customerError: false,
      dateError: false,
      occurrencesError: false,
      frequencyError: false,
      frequencyUnitError: false,
      selectedDayError: false,
    });

    let isValid = true;

    if (!model) {
      isValid = false;

      this.setState({
        modelError: true,
      });
    }

    if (!customer) {
      isValid = false;

      this.setState({
        customerError: true,
      });
    }

    if (!date) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (occurrences.trim() === '') {
      isValid = false;

      this.setState({
        occurrencesError: true,
      });
    }

    if (frequency.toString().trim() === '') {
      isValid = false;

      this.setState({
        frequencyError: true,
      });
    }

    if (!frequencyUnit) {
      isValid = false;

      this.setState({
        frequencyUnitError: true,
      });
    }

    if (frequencyUnit !== 'day' && selectedDay === null) {
      isValid = false;

      this.setState({
        selectedDayError: true,
      });
    }

    if (!isValid) return;

    const scheduleData = [];
    const selectedModel = find(modelList, {
      '@id': model,
    });

    const amount = selectedModel.content.total.withTaxes.toFixed(2);

    for (let i = 0; i < occurrences; i++) {
      const item = {
        amount,
      };

      switch (frequencyUnit) {
        case 'day':
          item.date = moment(date).add(i * frequency, 'day');
          break;
        case 'week':
          item.date = moment(date).day(selectedDay).add(7 * i * frequency, 'day');
          break;
        case 'month':
          if (date.date() > selectedDay) {
            item.date = moment(date).add(1, 'month').date(selectedDay).add(i * frequency, 'month');
          } else {
            item.date = moment(date).date(selectedDay).add(i * frequency, 'month');
          }

          break;
        case 'year':
          item.date = moment(date).add(i * frequency, 'year');
          break;
        default: break;
      }

      scheduleData.push(item);
    }

    this.setState({
      amount,
      scheduleData,
    });
  };

  handleOnDelete = (index) => {
    const { scheduleData } = this.state;

    const newArr = scheduleData.filter((item, arrIndex) => index !== arrIndex);

    this.setState({
      scheduleData: newArr,
    });
  };

  handleOnAdd = () => {
    const { newDate, amount, scheduleData } = this.state;
    const { t } = this.props;
    let isValid = true;

    this.setState({
      newDateError: false,
    });

    if (!newDate) {
      isValid = false;

      this.setState({
        newDateError: true,
      });
    }

    for (let i = 0; i < scheduleData.length; i++) {
      if (scheduleData[i].date.isSame(newDate, 'day')) {
        isValid = false;

        this.setState({
          newDateError: true,
        });

        toast.error(t('dateAlreadyAdd'));
        break;
      }
    }

    if (!isValid) return;

    const arr = scheduleData
      .concat({
        date: newDate,
        amount,
      })
      .sort((first, second) => {
        if (first.date.isBefore(second.date, 'day')) {
          return -1;
        }
        if (first.date.isAfter(second.date, 'day')) {
          return 1;
        }

        return 0;
      });

    this.setState({
      newDate: null,
      scheduleData: arr,
    });
  };

  handleScheduleDateChange = (date, index) => {
    const { scheduleData } = this.state;
    const { t } = this.props;
    let isValid = true;

    for (let i = 0; i < scheduleData.length; i++) {
      if (scheduleData[i].date.isSame(date, 'day')) {
        isValid = false;

        toast.error(t('dateAlreadyAdd'));
        break;
      }
    }

    if (!isValid) return;

    const arr = scheduleData.map((item, arrIndex) => {
      if (index === arrIndex) {
        item.date = date;
      }

      return item;
    });

    this.setState({
      scheduleData: arr,
    });
  };

  handleOnSubmit = () => {
    const {
      customer,
      date,
      model,
    } = this.state;

    const {
      postSubscription,
      selectedCompany,
    } = this.props;

    const data = {
      startDate: date.format('YYYY-MM-DD'),
      company: selectedCompany['@id'],
      customer,
      model,
    };

    this.setState({
      hasDataChanged: false,
      loadingSchedule: true,
    });

    postSubscription(data);
  };

  render() {
    const {
      model,
      customer,
      date,
      occurrences,
      frequency,
      frequencyUnit,
      selectedDay,

      newDate,

      modelError,
      customerError,
      dateError,
      occurrencesError,
      frequencyError,
      frequencyUnitError,
      selectedDayError,

      newDateError,

      customerList,
      modelList,
      scheduleData,

      loadingSchedule,
      hasDataChanged,
    } = this.state;

    const {
      loadingListModel,
      loadingListCustomer,
      loadingSubscription,
      t,
    } = this.props;

    const customerOptions = getCustomerList(customerList);
    const modelOptions = getModelList(modelList);
    let scheduleTotal = 0;

    if (!isEmpty(scheduleData)) {
      scheduleTotal = scheduleData.reduce((prev, next) => prev + parseFloat(next.amount), 0);
    }

    const frequencyOptions = getFrequencyOptions();
    const daysOptions = getDaysOptions(frequencyUnit);

    return (
      <div className="section-container">
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('subscriptionsCreateTitle')}
            </Header>
            <EssorButton
              as={Link}
              to="/subscriptions"
              type="chevron left"
              size="tiny"
              floated="right"
              disabled={loadingSubscription || loadingSchedule}
            >
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" size="small">

                  <Form.Group inline>
                    <Form.Select
                      label={t('quoteModel')}
                      name="model"
                      placeholder={t('formPHSelect')}
                      value={model}
                      options={modelOptions}
                      onChange={this.handleSelectChange}
                      loading={loadingListModel}
                      disabled={loadingListModel || !isEmpty(scheduleData)}
                      error={modelError}
                      selectOnBlur={false}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Select
                      label={t('formCustomer')}
                      name="customer"
                      placeholder={t('formPHSelect')}
                      value={customer}
                      options={customerOptions}
                      onChange={this.handleSelectChange}
                      loading={loadingListCustomer}
                      disabled={loadingListCustomer || !isEmpty(scheduleData)}
                      error={customerError}
                      selectOnBlur={false}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formStartDate')}
                      control={DatePicker}
                      selected={date}
                      onChange={date => this.handleDateChange(date, 'date')}
                      locale="fr"
                      disabled={!isEmpty(scheduleData)}
                      error={dateError}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field error={occurrencesError}>
                      <label>{t('subscriptionsOccurrences')}</label>
                      <Input>
                        <Cleave
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }}
                          onChange={this.handleInputChange}
                          name="occurrences"
                          disabled={!isEmpty(scheduleData)}
                          value={occurrences}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field className="multiple-inputs">
                      <label>{t('subscriptionsFrequency')}</label>
                      <Input
                        style={{
                          marginRight: '2%',
                          textAlign: 'right',
                        }}
                        value="1"
                        error={frequencyError}
                      >
                        <Cleave
                          options={getIntCleaveFormat()}
                          onChange={this.handleInputChange}
                          name="frequency"
                          disabled={!isEmpty(scheduleData)}
                          value={frequency}
                          style={{
                            textAlign: 'right',
                          }}
                        />
                      </Input>

                      <Select
                        name="frequencyUnit"
                        placeholder={t('formPHSelect')}
                        value={frequencyUnit}
                        options={frequencyOptions}
                        onChange={this.handleSelectChange}
                        error={frequencyUnitError}
                        disabled={!isEmpty(scheduleData)}
                        selectOnBlur={false}
                      />
                    </Form.Field>
                  </Form.Group>

                  {frequencyUnit !== 'day' && frequencyUnit !== null
                  && (
                    <Form.Group inline>
                      <Form.Field className="multiple-inputs">
                        <label>Jour de création des factures</label>
                        <Select
                          name="selectedDay"
                          placeholder={t('formPHSelect')}
                          value={selectedDay}
                          options={daysOptions}
                          onChange={this.handleSelectChange}
                          error={selectedDayError}
                          disabled={!isEmpty(scheduleData)}
                          selectOnBlur={false}
                        />
                      </Form.Field>
                    </Form.Group>
                  )}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {isEmpty(scheduleData) && (
            <div className="clearfix">
              <EssorButton type="check" onClick={this.handleGenerateSchedule} size="tiny" floated="right">
                {t('subscriptionsGenerateSchedule')}
              </EssorButton>
            </div>
          )}

          {!isEmpty(scheduleData) && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Segment
                    basic
                    className={classnames('table-container', {
                      'is-loading': loadingSubscription || loadingSchedule,
                    })}
                  >
                    <Dimmer active={loadingSubscription || loadingSchedule} inverted>
                      <Loader>{t('loading')}</Loader>
                    </Dimmer>
                    <Table celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{t('formDate')}</Table.HeaderCell>
                          <Table.HeaderCell>{t('formAmount')}</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {scheduleData.map((item, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>
                              <Form>
                                <Form.Group
                                  style={{
                                    margin: '0',
                                  }}
                                >
                                  <Form.Input
                                    control={DatePicker}
                                    selected={item.date}
                                    onChange={date => this.handleScheduleDateChange(date, index)}
                                    locale="fr"
                                    autoComplete="off"
                                    error={dateError}
                                  />
                                </Form.Group>
                              </Form>
                            </Table.Cell>
                            <Table.Cell className="format-number-fr">
                              {floatFormat(item.amount, true)}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <Button
                                className="table-button"
                                icon="trash"
                                onClick={() => this.handleOnDelete(index)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                        <Table.Row>
                          <Table.Cell colSpan={2}>
                            <Form>
                              <Form.Group
                                style={{
                                  margin: '0',
                                }}
                              >
                                <Form.Input
                                  control={DatePicker}
                                  selected={newDate}
                                  onChange={date => this.handleDateChange(date, 'newDate')}
                                  locale="fr"
                                  autoComplete="off"
                                  error={newDateError}
                                />
                              </Form.Group>
                            </Form>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Button
                              className="table-button"
                              icon="plus"
                              onClick={this.handleOnAdd}
                            />
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            {t('documentTotal')}
                          </Table.Cell>
                          <Table.Cell className="format-number-fr">
                            {floatFormat(scheduleTotal, true)}
                          </Table.Cell>
                          <Table.Cell />
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          {!isEmpty(scheduleData) && (
            <div className="clearfix">
              <EssorButton
                type="check"
                onClick={this.handleOnSubmit}
                size="tiny"
                floated="right"
                disabled={loadingSubscription || loadingSchedule}
              >
                {t('buttonSave')}
              </EssorButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getModels: page => dispatch(listModel(page)),
  getCustomers: page => dispatch(listCustomer(page)),
  postSubscription: data => dispatch(createSubscription(data)),
  postSchedule: data => dispatch(createSchedule(data)),
  getSubscriptions: page => dispatch(listSubscription(page)),
  reset: () => {
    dispatch(resetListModel());
    dispatch(resetListCustomer());
    dispatch(successSubscription(null));
    dispatch(loadingSubscription(false));
    dispatch(errorSubscription(null));

    dispatch(successSchedule(null));
    dispatch(loadingSchedule(false));
    dispatch(errorSchedule(null));
  },
});

const mapStateToProps = state => ({
  listModel: state.model.list.data,
  loadingListModel: state.model.list.loading,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,

  createdSubscription: state.subscription.create.created,
  loadingSubscription: state.subscription.create.loading,
  errorSubscription: state.subscription.create.error,

  createdSchedule: state.scheduleInvoice.create.created,
  loadingSchedule: state.scheduleInvoice.create.loading,
  errorSchedule: state.scheduleInvoice.create.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateRecipient);

export default withTranslation()(withRouter(Main));
