import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import EditCellButton from 'components/buttons/EditCellButton';
import TableLoader from 'components/TableLoader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import CheckRights from 'components/access/CheckRights';
import { crudRights, Entities } from 'types/accessRights';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';
import TableEmptyRow from 'components/TableEmptyRow';

class ListModel extends Component {
  state = {
    modelData: null,
    column: null,
    direction: null,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataModel) && nextProps.dataModel['hydra:member'] !== prevState.modelData) {
      return {
        modelData: nextProps.dataModel['hydra:member'],
      };
    }

    return null;
  }

  handleSort = clickedColumn => () => {
    const { column, modelData, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        modelData: sortBy(modelData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      modelData: modelData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  render() {
    const { modelData, column, direction } = this.state;

    const {
      loadingModel,
      t,
    } = this.props;

    return (
      <ContainerGeneral>
        <TitleHeader title={t('modelHomeTitle')} />

        <TableLoader status={loadingModel} table sortable celled striped>
          <HeaderRow>
            <LeftCell
              sorted={column === 'id' ? direction : null}
              onClick={this.handleSort('id')}
              content="#"
            />
            <LeftCell
              sorted={column === 'label' ? direction : null}
              onClick={this.handleSort('label')}
              content={t('formLabel')}
            />
            <CheckRights entity={Entities.documentModel} right={crudRights.update}>
              <CenterCell />
            </CheckRights>
          </HeaderRow>

          <Table.Body>
            {modelData && modelData.map((model, index) => (
              <Table.Row key={index}>
                <LeftCell content={model.id} />
                <LeftCell content={model.label} />

                <CheckRights entity={Entities.documentModel} right={crudRights.update}>
                  <EditCellButton to={`/business/document_models/${model.id}/edit`} />
                </CheckRights>
              </Table.Row>
            ))}
            {modelData.length === 0 && (
              <TableEmptyRow content={t('documentEmptyList')} span={3} />
            )}
          </Table.Body>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: purchase => dispatch(selectDocument(purchase)),
});

const mapStateToProps = state => ({
  dataModel: state.model.list.data,
  loadingModel: state.model.list.loading,
  errorModel: state.model.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListModel);

export default withTranslation()(withRouter(Main));
