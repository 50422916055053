import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import moment from 'moment';
import { reset, retrieve } from 'actions/turnover-balance/show';
import { Form, Grid } from 'semantic-ui-react';
import { floatFormat, percentageFormat } from 'utils/formatter';
import { withTranslation } from 'react-i18next';
import { EssorButton } from 'components';
import NotFound from 'routes/admin/404';

import 'moment/locale/fr';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { crudRights, Entities } from 'types/accessRights';

moment.locale('fr');

class ShowTurnoverBalance extends Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const {
      dataTurnover,
      getTurnover,
      match,
    } = this.props;

    if (find(dataTurnover['hydra:member'], {
      id: parseInt(match.params.turnoverId, 10),
    })) {
      getTurnover(`/turnover_balances/${match.params.turnoverId}`);
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { isValid } = this.state;
    const { retrieved, loading, match, t, selectedCompany } = this.props;

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <EssorButton
              as={Link}
              to="/forecast/turnover-balance"
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
            <ModifyHeader
              title={t('titleTurnoverBalance')}
              to={`/forecast/turnover-balance/${match.params.turnoverId}/edit`}
              entity={Entities.turnoverBalance}
              right={crudRights.update}
            />
          </div>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={loading} size="small">
                  <div className="margin-block">
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formDateStart')}</label>
                        <h5 className="informative-field">
                          {retrieved && moment(retrieved.startDate).format('DD/MM/YYYY')}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formDateEnd')}</label>
                        <h5 className="informative-field">
                          {retrieved && moment(retrieved.endDate).format('DD/MM/YYYY')}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceTotal')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.totalTurnover, true)}</h5>
                      </Form.Field>
                    </Form.Group>
                  </div>

                  <div className="margin-block">
                    <h3>{t('turnoverBalanceRawMaterialsSubtitle')}</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormPurchase')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.mpPurchase, true)}</h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{selectedCompany.calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                        <h5 className="informative-field">
                          {retrieved
                          && (selectedCompany.calculationMode === 'coef'
                            ? floatFormat(retrieved.mpMargin)
                            : percentageFormat(retrieved.mpMargin))
                          }
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormTurnover')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.mpTurnover, true)}</h5>
                      </Form.Field>
                    </Form.Group>
                  </div>

                  <div className="margin-block">
                    <h3>{t('turnoverBalanceGoodsSubtitle')}</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormPurchase')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.mdPurchase, true)}</h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{selectedCompany.calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                        <h5 className="informative-field">
                          {retrieved
                          && (selectedCompany.calculationMode === 'coef'
                            ? floatFormat(retrieved.mdMargin)
                            : percentageFormat(retrieved.mdMargin))
                          }
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormTurnover')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.mdTurnover, true)}</h5>
                      </Form.Field>
                    </Form.Group>
                  </div>

                  <div className="margin-block">
                    <h3>{t('turnoverBalanceOutsourcingSubtitle')}</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormPurchase')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.stPurchase, true)}</h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{selectedCompany.calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                        <h5 className="informative-field">
                          {retrieved
                          && (selectedCompany.calculationMode === 'coef'
                            ? floatFormat(retrieved.stMargin)
                            : percentageFormat(retrieved.stMargin))
                          }
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormTurnover')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.stTurnover, true)}</h5>
                      </Form.Field>
                    </Form.Group>
                  </div>

                  <div className="margin-block">
                    <h3>{t('turnoverBalanceLabourSubtitle')}</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormSoldHours')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.soldHours)}</h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormHourlyRate')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.hourlyRate, true)}</h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('turnoverBalanceFormHourSales')}</label>
                        <h5 className="informative-field">{retrieved && floatFormat(retrieved.hoursTurnover, true)}</h5>
                      </Form.Field>
                    </Form.Group>
                  </div>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTurnover: page => dispatch(retrieve(page)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  retrieved: state.turnoverBalance.show.retrieved,
  loading: state.turnoverBalance.show.loading,
  error: state.turnoverBalance.show.error,
  dataTurnover: state.turnoverBalance.list.data,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowTurnoverBalance);

export default withTranslation()(withRouter(Main));
