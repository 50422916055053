import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setWorkOrderList } from 'actions/work-order/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { change as changeWorkOrder, reset as resetChangeWorkOrder } from 'actions/work-order/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { reset as resetUpdateWorkOrder, retrieve as retrieveWorkOrder, retrieveSuccess as retrieveWorkOrderSuccess, update as updateWorkOrder } from 'actions/work-order/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { selectDocument } from 'actions/user-companies/select';
import { Document } from 'components';
import { STATUS_PENDING } from 'utils/constants';

import 'moment/locale/fr';
import TwelveForm from 'layouts/TwelveForm';
import KeyValue from 'components/keyValue';
import SelectEmployees from 'routes/admin/business/SelectEmployees';
import SaveButtons from 'components/documents/saveButtons';
import { isDraft, isPending } from 'utils/documentStatus';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import ActionButtons from 'components/documents/actionButtons';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import { WORK_ORDER } from 'utils/documentType';
import WrongDocument from '../../../../../layouts/error-pages/WrongDocument';

moment.locale('fr');

class EditWorkOrder extends Component {
  static contextType = PrinterContext;

  state = {
    status: null,
    workOrderId: null,
    documentData: {},

    customerList: null,
    selectedCustomer: null,

    staff: [],
    plannedHoursError: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getWorkOrder,
      getCustomerList,
      selectedCompany,
      getArticles,
      match,
    } = this.props;

    if (match.params.id) {
      getWorkOrder(`/work_orders/${match.params.id}`);
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedWorkOrder)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedWorkOrder.id !== prevState.workOrderId
        || nextProps.retrievedWorkOrder.status !== prevState.status)) {
      const documentData = {
        '@type': nextProps.retrievedWorkOrder['@type'],
        documentId: nextProps.retrievedWorkOrder.status >= STATUS_PENDING
          ? nextProps.retrievedWorkOrder.uniqueID
          : '-',
        content: nextProps.retrievedWorkOrder.content,
        reference: nextProps.retrievedWorkOrder.reference,
        creationDate: moment(nextProps.retrievedWorkOrder.creationDate),
        responseDate: moment(nextProps.retrievedWorkOrder.responseDate),
        invoice: nextProps.retrievedWorkOrder.invoice,
        note: nextProps.retrievedWorkOrder.customerComment,
      };

      return {
        documentData,
        workOrderId: nextProps.retrievedWorkOrder.id,
        status: nextProps.retrievedWorkOrder.status,
        selectedCustomer: nextProps.retrievedWorkOrder.customer['@id'],
        staff: nextProps.retrievedWorkOrder.staff || [],
        loadedWork: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedWorkOrder,
      retrievedError,

      updatedWorkOrder,
      changedWorkOrder,
      loadingRetrieveWorkOrder,
      listWorkOrder,
      loadingWorkOrders,
      getWorkOrder,
      match,

      setWorkOrder,
      changeWorkOrder,
      selectDocument,
      setWorkOrderList,
      history,
    } = this.props;

    const { submitAction } = this.state;

    const { setFileName } = this.context;

    if (retrievedError) {
      return;
    }

    if (loadingWorkOrders === false && isEmpty(retrievedWorkOrder) && !loadingRetrieveWorkOrder) {
      getWorkOrder(`/work_orders/${match.params.id}`);
    }

    if (retrievedWorkOrder !== prevProps.retrievedWorkOrder) {
      if (!isEmpty(retrievedWorkOrder)) {
        selectDocument(retrievedWorkOrder);
      } else {
        selectDocument(null);
      }
    }

    if (!isEmpty(changedWorkOrder)
      && changedWorkOrder['@id'] === selectedDocument['@id']
      && changedWorkOrder.status >= STATUS_PENDING) {
      selectDocument(changedWorkOrder);
    }

    if (!isEmpty(updatedWorkOrder) && updatedWorkOrder !== prevProps.updatedWorkOrder) {
      const index = findIndex(listWorkOrder['hydra:member'], {
        id: updatedWorkOrder.id,
      });

      listWorkOrder['hydra:member'][index] = updatedWorkOrder;
      setWorkOrderList(listWorkOrder);

      switch (submitAction) {
        case 'save':
          history.push('/business/work_orders');
          break;
        case 'validate':
          changeWorkOrder(`/work_orders/${updatedWorkOrder.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
    }

    if (!isEmpty(changedWorkOrder)
      && changedWorkOrder !== prevProps.changedWorkOrder
      && changedWorkOrder['@id'] === selectedDocument['@id']) {
      const index = findIndex(listWorkOrder['hydra:member'], {
        id: changedWorkOrder.id,
      });

      setWorkOrder(changedWorkOrder);
      listWorkOrder['hydra:member'][index] = changedWorkOrder;
      setWorkOrderList(listWorkOrder);
    }

    if (retrievedWorkOrder) {
      setFileName(retrievedWorkOrder.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  onSubmit = (data) => {
    this.setState({
      validDocument: false,
    });

    const {
      updateWorkOrder,
      retrievedWorkOrder,
    } = this.props;
    const { staff } = this.state;

    if (!isEmpty(staff)) {
      data.staff = staff;
    }

    if (!isEmpty(retrievedWorkOrder.content.siteAddress)) {
      data.content.siteAddress = retrievedWorkOrder.content.siteAddress;
    }

    updateWorkOrder(retrievedWorkOrder, data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  handleAction = (type) => {
    const {
      printDocument,
      changeWorkOrder,
      selectedDocument,
    } = this.props;

    if (type === 'edit') {
      changeWorkOrder(`/work_orders/${selectedDocument.id}/to_draft`, 'PUT');
    } else if (type === 'print') {
      printDocument(`/work_orders/${selectedDocument.id}/print`);
    }
  };

  setStaff = (staff) => {
    this.setState({
      staff,
      hasDataChanged: true,
    });
  };

  setPlannedHoursError = (error) => {
    this.setState({
      plannedHoursError: error,
    });
  };

  render() {
    const {
      status,
      documentData,

      customerList,
      selectedCustomer,

      validDocument,
      validContent,

      staff,
      plannedHoursError,

      hasDataChanged,
    } = this.state;

    const {
      loadingWorkOrders,
      loadingRetrieveWorkOrder,
      loadingUpdateWorkOrder,
      loadingChangeWorkOrder,
      retrievedError,
      t,
    } = this.props;

    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (loadingWorkOrders || loadingRetrieveWorkOrder || loadingChangeWorkOrder) {
      return <ContainerGeneral status label={loadingChangeWorkOrder ? t('documentValidate') : null} />;
    }

    if (retrievedError) {
      return <WrongDocument />;
    }

    const loadingWorkOrder = loadingUpdateWorkOrder || loadingChangeWorkOrder;
    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader title={t('workOrderUpdateTitle')} to="/business/work_orders/" />

        <ActionButtons
          isPending={isPending(documentData)}
          handleAction={this.handleAction}
        />

        {(!status || status < STATUS_PENDING) && (
          <TwelveForm loading={loadingRetrieveWorkOrder || loadingWorkOrder}>
            <KeyValue label={t('formCustomer')}>
              {customerObject && customerObject.companyName}
            </KeyValue>
          </TwelveForm>
        )}

        <hr />

        {customerObject && (
          <React.Fragment>
            <Document
              type={WORK_ORDER}
              status={status}
              documentData={documentData}
              customer={customerObject}
              validData={validDocument}
              validContent={validContent}
              getData={(documentData, errors) => this.onSubmit(documentData, errors)}
              hasDataChanged={hasDataChanged}
            />
            <SelectEmployees
              staff={staff}
              setStaff={this.setStaff}
              setPlannedHoursError={this.setPlannedHoursError}
            />
            <SaveButtons
              disabled={loadingWorkOrder || plannedHoursError}
              loading={loadingWorkOrder}
              onClick={this.handleOnSubmit}
              isDraft={isDraft(documentData)}
            />
          </React.Fragment>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWorkOrder: workOrder => dispatch(retrieveWorkOrderSuccess(workOrder)),
  getWorkOrder: page => dispatch(retrieveWorkOrder(page)),
  getCustomerList: page => dispatch(listCustomer(page)),
  getArticles: page => dispatch(listArticle(page)),
  updateWorkOrder: (item, data) => dispatch(updateWorkOrder(item, data)),
  changeWorkOrder: (route, method) => dispatch(changeWorkOrder(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setWorkOrderList: workOrders => dispatch(setWorkOrderList(workOrders)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset: () => {
    dispatch(resetListCustomer());
    dispatch(resetArticle());
    dispatch(resetChangeWorkOrder());
    dispatch(resetUpdateWorkOrder());
  },
});

const mapStateToProps = state => ({
  listWorkOrder: state.workOrder.list.data,
  loadingWorkOrders: state.workOrder.list.loading,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  retrievedWorkOrder: state.workOrder.update.retrieved,
  retrievedError: state.workOrder.update.retrieveError,
  loadingRetrieveWorkOrder: state.workOrder.update.retrieveLoading,
  errorRetrieveWorkOrder: state.workOrder.update.retrieveError,
  updatedWorkOrder: state.workOrder.update.updated,
  loadingUpdateWorkOrder: state.workOrder.update.updateLoading,
  errorUpdateWorkOrder: state.workOrder.update.updateError,

  changedWorkOrder: state.workOrder.change.changed,
  loadingChangeWorkOrder: state.workOrder.change.loading,

  urlDocument: state.document.print.url,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditWorkOrder);

export default withTranslation()(withRouter(Main));
