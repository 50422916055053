import React, { FunctionComponent } from 'react';
import { Header as Title } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FiscalYear } from '../../types/store';
import { Card } from './index';
import { FormattedCurrency } from '../../types/brand';

interface OwnProps {
  data: {
    fullFiscalYear: FormattedCurrency;
  };
  fiscalYear: FiscalYear;
}

type Props = OwnProps;

const OperatingProfitCard: FunctionComponent<Props> = ({ fiscalYear, data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Title as="h5">{t('dashboard_operatingProfit')}</Title>
      <Card color={false} title={fiscalYear.label} amount={data.fullFiscalYear} date={undefined} />
    </div>
  );
};

export default OperatingProfitCard;
