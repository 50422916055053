import React, { ReactNode } from 'react';
import ErrorTemplate from '../layouts/error-pages/ErrorTemplate';
import UnexpectedError from '../layouts/error-pages/UnexpectedError';

interface ErrorState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  state: ErrorState = {
    hasError: false,
  };

  static getDerivedStateFromError(): ErrorState {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // eslint-disable-next-line no-console
    console.log(`${error} : ${errorInfo}`);
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <UnexpectedError />;
    }

    return children;
  }
}

export default ErrorBoundary;
