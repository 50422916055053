import React, { Component } from 'react';
import Cleave from 'cleave.js/dist/cleave-react-node';

class CleaveInit extends Component {
  state = {
    cleave: null,
  };

  constructor(props, context) {
    super(props, context);
    this.onCleaveInit = (cleave) => {
      cleave.setRawValue(props.value);
      this.setState({
        cleave,
      });

      // 'hack' to trigger ensemble items price recalculation at init
      if (props.onBlurOnInit) {
        const e = {
          target: {
            value: props.value,
          },
        };
        props.onBlur(e, props.onBlurOnInit);
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { cleave } = this.state;

    if (prevProps.value !== value) {
      cleave.setRawValue(value);
    }
  }

  onChange(e) {
    const { handler } = this.props;
    handler(e.target.rawValue);
  }

  defaultHandler = () => null;

  render() {
    const { name, placeholder, options, disable, onBlur, onFocus, align = 'right', readOnly } = this.props;

    return (
      <Cleave
        className={readOnly ? 'read-only' : undefined}
        onInit={this.onCleaveInit}
        options={options}
        onChange={readOnly ? this.defaultHandler() : e => this.onChange(e)}
        onBlur={onBlur || this.defaultHandler}
        onFocus={onFocus || this.defaultHandler}
        name={name}
        placeholder={placeholder}
        disabled={disable}
        readOnly={readOnly}
        style={{
          textAlign: align,
        }}
      />
    );
  }
}

export default CleaveInit;
