import React from 'react';
import BlankBodyLine from 'components/documents/BlankBodyLine';
import CommentBodyLine from 'components/documents/CommentBodyLine';
import SubTotalBodyLine from 'components/documents/SubTotalBodyLine';
import BodyLineControls from 'components/documents/BodyLineControls';
import { Table } from 'semantic-ui-react';

function LayoutBodyLine(props) {
  const { index, item, editable, handleDelete, setComment, subTotal } = props;

  const lineProps = {
    index,
    item,
    editable,
    handleDelete,
  };

  const getComponent = () => {
    switch (item.type) {
      case 'blankLine':
        return <BlankBodyLine {...lineProps} />;
      case 'comment':
        return <CommentBodyLine {...lineProps} setComment={setComment} />;
      case 'subtotal':
        return <SubTotalBodyLine {...lineProps} subTotal={subTotal} />;
      default:
        return <React.Fragment />;
    }
  };

  return (
    <Table.Row key={`itemList${index}`}>
      <BodyLineControls
        item={item}
        editable={editable}
        handleDeleteItem={handleDelete}
        rowSpan={0}
      />
      {getComponent()}
    </Table.Row>
  );
}

export default LayoutBodyLine;
