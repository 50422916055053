import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset, retrieve } from 'actions/customer/show';
import { useTranslation } from 'react-i18next';
import Routes from 'routes/admin/contacts/routes';
import { Entities } from 'types/accessRights';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import NotFound from 'routes/admin/404';
import KeyValue from 'components/keyValue';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TwelveForm from 'layouts/TwelveForm';
import { keyVal } from 'utils/functions';
import { fillPlaceholders } from '../../../../routes';

const ShowCustomer = () => {
  const { t } = useTranslation();
  const { retrieved, loading, error } = useSelector(state => state.customer.show);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(retrieve(`/customers/${id}`));
    return () => {
      dispatch(reset());
    };
  }, []);

  if (error) {
    return <NotFound />;
  }

  return (
    <ContainerGeneral status={loading || !retrieved}>
      <ModifyHeader
        title={t('customerShowTitle')}
        entity={Entities.customer}
        to={fillPlaceholders(Routes.editRecipient, keyVal('id', id))}
        back={Routes.listRecipients}
      />

      {retrieved && (
        <TwelveForm>
          <KeyValue label={t('formCompanyName')}>{retrieved.companyName}</KeyValue>
          <KeyValue label={t('formContactName')}>
            {retrieved.details.honorific && `${retrieved.details.honorific} `}
            {retrieved.contactName}
          </KeyValue>
          <KeyValue label={t('formEmail')}>{retrieved.details.email}</KeyValue>
          <KeyValue label={t('formPhoneNumber')}>{retrieved.details.phone}</KeyValue>
          <KeyValue label={t('formCellphoneNumber')}>{retrieved.details.cellphone}</KeyValue>
          <KeyValue label={t('formStreetName')}>{retrieved.details.streetName}</KeyValue>
          <KeyValue label={t('formAdditional')}>{retrieved.details.additional}</KeyValue>
          <KeyValue label={t('formZipCode')}>{retrieved.details.zipCode}</KeyValue>
          <KeyValue label={t('formCity')}>{retrieved.details.city}</KeyValue>
          <KeyValue label={t('formCountry')}>{retrieved.details.country}</KeyValue>
          <KeyValue label={t('formVATNumber')}>{retrieved.details.tvaNumber}</KeyValue>
        </TwelveForm>
      )}
    </ContainerGeneral>
  );
};

export default ShowCustomer;
