import React, { FunctionComponent, SyntheticEvent } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FieldWithLabel from './FieldWithLabel';
import { Service } from '../../types/document';

interface OwnProps {
  editable: boolean;
  handler: (event: SyntheticEvent, data: DropdownProps) => void;
  item: Service;
}

type Props = OwnProps;

const ServiceSecondLine: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <FieldWithLabel label={t('quoteSoldAs')}>
      <p className="info-line">{item.soldAs.toUpperCase()}</p>
    </FieldWithLabel>
  );
};

export default ServiceSecondLine;
