import React from 'react';
import { Switch } from 'react-router-dom';
import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLink from 'layouts/subNav/SubNavLink';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckRoute from 'components/access/CheckRoute';
import { Entities } from 'types/accessRights';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/payments/routes';
import TreasuryHome from 'routes/admin/analysis/treasury';
import List from './list';
import FollowUp from './follow-up';

const Payments = () => {
  const { t } = useTranslation();
  const selectedCompany = useSelector(state => state.userCompanies.select.selectedCompany);

  return (
    <React.Fragment>
      <SubNav hide={!selectedCompany}>
        <SubNavTitle title={t('paymentsShowTitle')} />
        <SubNavLink entity={Entities.payment} to={Routes.home}>
          {t('paymentsCustomersSituationTitle')}
        </SubNavLink>
        <SubNavLink entity={Entities.recoveryNotice} to={Routes.listRecoveryNotice}>
          {t('paymentsCustomerFollow')}
        </SubNavLink>
        <SubNavLink to={Routes.treasury}>{t('analysisTreasuryTitle')}</SubNavLink>
      </SubNav>

      <Switch>
        <CheckRoute
          exact
          entity={Entities.payment}
          path={Routes.home}
          component={List}
        />
        <CheckRoute
          exact
          entity={Entities.recoveryNotice}
          path={Routes.listRecoveryNotice}
          component={FollowUp}
        />
        <CheckRoute
          exact
          entity={Entities.recoveryNotice}
          path={Routes.treasury}
          component={TreasuryHome}
        />
        <RouteNotFound />
      </Switch>
    </React.Fragment>
  );
};

export default Payments;
