import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { retrieve } from 'actions/company-settings/update';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setPurchaseOrderList } from 'actions/purchase-order/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { labourCost, plannedHours } from 'actions/document/liveDocument';
import { create as createPurchaseOrder, error as errorPurchaseOrder, loading as loadingPurchaseOrder, success as successPurchaseOrder } from 'actions/purchase-order/create';
import { change as changePurchaseOrder, reset as resetChangePurchaseOrder } from 'actions/purchase-order/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { reset as resetUpdatePurchaseOrder,
  retrieve as retrievePurchaseOrder,
  retrieveSuccess as updatePurchaseSuccess,
  update as updatePurchaseOrder } from 'actions/purchase-order/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { list as listModel, reset as resetModel } from 'actions/document-model/list';
import { selectDocument } from 'actions/user-companies/select';
import { Dropdown, Form, Grid } from 'semantic-ui-react';
import { CreateCustomer, Document, DocumentTimer, EssorButton } from 'components';
import DatePicker from 'react-datepicker';
import { getCustomerList, getModelList, getOfferReasons } from 'components/documents/documentOptions';
import { handleNextPropLists } from 'components/documents/documentCommon';
import { isDraft, isNotDraft, isPending } from 'utils/documentStatus';
import SaveButtons from 'components/documents/saveButtons';
import ActionButtons from 'components/documents/actionButtons';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import InlineField from 'layouts/InlineField';
import SmallForm from 'layouts/SmallForm';
import ErrorMessages from 'routes/admin/business/ErrorMessages';
import ReplaceContentModal from 'routes/admin/business/ReplaceContentModal';
import SelectEmployees from 'routes/admin/business/SelectEmployees';
import { keyVal } from 'utils/functions';
import SectionLoader from 'components/sectionLoader';
import { PURCHASE_ORDER } from 'utils/documentType';
import WrongDocument from '../../../../../layouts/error-pages/WrongDocument';

moment.locale('fr');

class EditPurchaseOrder extends Component {
  static contextType = PrinterContext;

  state = {
    status: null,
    purchaseOrderId: null,
    documentData: {},

    modelList: null,
    selectedModel: null,
    customerList: null,
    selectedCustomer: null,
    openCustomerModal: false,

    orderReason: null,
    estimatedStartingDate: null,
    comment: '',

    orderReasonError: false,
    plannedHoursError: false,

    staff: [],

    isCreate: false,
    timeSpent: 0,
    loadedPurchase: false,
    messageError: false,
    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getPurchaseOrder,
      getModelList,
      getCustomerList,
      selectedCompany,
      getArticles,
      match,
    } = this.props;

    if (match.params.id) {
      getPurchaseOrder(`/purchase_orders/${match.params.id}`);
    }

    if (match.path === '/business/purchase_orders/create') {
      this.setState({
        isCreate: true,
      });
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getModelList(`/document_models?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedPurchaseOrder)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedPurchaseOrder.id !== prevState.purchaseOrderId
        || nextProps.retrievedPurchaseOrder.status !== prevState.status)) {
      const documentData = {
        documentId: isNotDraft(nextProps.retrievedPurchaseOrder)
          ? nextProps.retrievedPurchaseOrder.uniqueID
          : '-',
        content: nextProps.retrievedPurchaseOrder.content,
        reference: nextProps.retrievedPurchaseOrder.reference,
        creationDate: moment(nextProps.retrievedPurchaseOrder.creationDate),
        responseDate: moment(nextProps.retrievedPurchaseOrder.responseDate),
        invoice: nextProps.retrievedPurchaseOrder.invoice,
        quote: nextProps.retrievedPurchaseOrder.quote,
        status: nextProps.retrievedPurchaseOrder.status,
      };

      return {
        documentData,
        purchaseOrderId: nextProps.retrievedPurchaseOrder.id,
        status: nextProps.retrievedPurchaseOrder.status,
        selectedCustomer: nextProps.retrievedPurchaseOrder.customer['@id'],
        orderReason: nextProps.retrievedPurchaseOrder.orderReason,
        comment: nextProps.retrievedPurchaseOrder.comment || '',
        estimatedStartingDate: nextProps.retrievedPurchaseOrder.estimatedStartingDate
          ? moment(nextProps.retrievedPurchaseOrder.estimatedStartingDate)
          : null,
        staff: nextProps.retrievedPurchaseOrder.staff,
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedPurchaseOrder.timeSpent,
        loadedPurchase: true,
      };
    }

    const handlerReturn = handleNextPropLists(nextProps, prevState);
    if (handlerReturn !== null) {
      return handlerReturn;
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedPurchaseOrder,

      createdPurchaseOrder,
      updatedPurchaseOrder,
      changedPurchaseOrder,
      listPurchaseOrder,

      setPurchaseOrder,
      changePurchaseOrder,
      selectDocument,
      setPurchaseOrderList,
      history,
    } = this.props;

    const { setFileName } = this.context;

    const { submitAction } = this.state;

    if (!isEmpty(retrievedPurchaseOrder)
      && retrievedPurchaseOrder !== prevProps.retrievedPurchaseOrder) {
      selectDocument(retrievedPurchaseOrder);
    }

    if (isEmpty(retrievedPurchaseOrder)
      && retrievedPurchaseOrder !== prevProps.retrievedPurchaseOrder) {
      selectDocument(null);
    }

    if (!isEmpty(changedPurchaseOrder)
      && changedPurchaseOrder['@id'] === selectedDocument['@id']
      && isNotDraft(changedPurchaseOrder)) {
      selectDocument(changedPurchaseOrder);
    }

    if (!isEmpty(createdPurchaseOrder) && createdPurchaseOrder !== prevProps.createdPurchaseOrder) {
      listPurchaseOrder['hydra:member'].push(createdPurchaseOrder);
      listPurchaseOrder['hydra:totalItems'] += 1;
      setPurchaseOrderList(listPurchaseOrder);

      switch (submitAction) {
        case 'save':
          history.push('/business/purchase_orders');
          break;
        case 'edit':
          history.push(`/business/purchase_orders/${createdPurchaseOrder.id}/edit`);
          break;
        case 'validate':
          history.push(`/business/purchase_orders/${createdPurchaseOrder.id}/edit`);
          changePurchaseOrder(`/purchase_orders/${createdPurchaseOrder.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(updatedPurchaseOrder) && updatedPurchaseOrder !== prevProps.updatedPurchaseOrder) {
      const index = findIndex(listPurchaseOrder['hydra:member'], {
        id: updatedPurchaseOrder.id,
      });

      listPurchaseOrder['hydra:member'][index] = updatedPurchaseOrder;
      setPurchaseOrderList(listPurchaseOrder);

      switch (submitAction) {
        case 'save':
          history.push('/business/purchase_orders');
          break;
        case 'validate':
          changePurchaseOrder(`/purchase_orders/${updatedPurchaseOrder.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(changedPurchaseOrder)
      && changedPurchaseOrder !== prevProps.changedPurchaseOrder
      && changedPurchaseOrder['@id'] === selectedDocument['@id']) {
      const index = findIndex(listPurchaseOrder['hydra:member'], {
        id: changedPurchaseOrder.id,
      });

      setPurchaseOrder(changedPurchaseOrder);
      listPurchaseOrder['hydra:member'][index] = changedPurchaseOrder;
      setPurchaseOrderList(listPurchaseOrder);
    }

    if (retrievedPurchaseOrder) {
      setFileName(retrievedPurchaseOrder.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  clearErrors = () => {
    this.setState({
      messageError: [],
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleEstimateDateChange = (date) => {
    this.setState({
      estimatedStartingDate: date,
    });
  };

  setStaff = (staff) => {
    this.setState({
      staff,
      hasDataChanged: true,
    });
  };

  setPlannedHoursError = (error) => {
    this.setState({
      plannedHoursError: error,
    });
  };

  handleOpenCreateCustomer = (e) => {
    e.preventDefault();

    this.setState({
      openCustomerModal: true,
      hasDataChanged: true,
    });
  };

  closeCustomerModal = () => {
    this.setState({
      openCustomerModal: false,
    });
  };

  customerCreateSuccess = (customer) => {
    const { customerList } = this.state;

    customerList.push(customer);

    this.setState({
      customerList,
      selectedCustomer: customer['@id'],
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleDelete = (e, index) => {
    const { staff } = this.state;

    staff.splice(index, 1);

    this.setState({
      staff,
    });
  };

  onSubmit = (data, msgError) => {
    this.setState({
      validDocument: false,
    });

    const {
      selectedCustomer,
      orderReason,
      estimatedStartingDate,
      comment,
      staff,
    } = this.state;

    const {
      postPurchaseOrder,
      formTime,
      updatePurchaseOrder,
      retrievedPurchaseOrder,
      selectedCompany,
    } = this.props;

    this.setState({
      orderReasonError: false,
    });

    let valid = true;

    if (isNotDraft(retrievedPurchaseOrder)) {
      if (orderReason === null) {
        valid = false;

        this.setState({
          orderReasonError: true,
        });
      }
    }

    if (!valid || !isEmpty(msgError)) {
      this.setState({
        messageError: msgError,
      });
      return;
    }

    data.customer = selectedCustomer;
    data.company = selectedCompany['@id'];
    data.timeSpent = formTime;

    if (comment.trim() !== '') {
      data.comment = comment;
      data.content.messages.internalNotice = comment;
    }

    if (estimatedStartingDate) {
      data.estimatedStartingDate = estimatedStartingDate.format();
    }

    if (!isEmpty(staff)) {
      data.staff = staff;
    }

    if (isNotDraft(retrievedPurchaseOrder)) {
      data.orderReason = orderReason;
    }

    retrievedPurchaseOrder
      ? updatePurchaseOrder(retrievedPurchaseOrder, data)
      : postPurchaseOrder(data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  onModelChange = (content) => {
    this.setState({
      validContent: false,
    });

    if (content.length > 0) {
      this.setState({
        warningContentModal: true,
      });
    } else {
      this.setModelContent();
    }
  };

  onWarningClose = () => {
    this.setState({
      selectedModel: null,
      warningContentModal: false,
    });
  };

  setModelContent = (append = false) => {
    const { selectedModel, modelList, documentData } = this.state;

    const model = find(modelList, keyVal('@id', selectedModel));

    let body = documentData.content ? documentData.content.body : [];
    if (append) {
      body.push(...model.content.body);
    } else {
      body = model.content ? model.content.body : [];
    }
    documentData.content = model.content;

    this.setState({
      documentData: {
        ...documentData,
        content: {
          ...documentData.content,
          body,
        },
      },
      warningContentModal: false,
    });
  };

  handleModelChange = (e, { value }) => {
    this.setState({
      selectedModel: value,
      validContent: true,
      hasDataChanged: true,
    });
  };

  handleAction = (type) => {
    const { printDocument, changePurchaseOrder, selectedDocument } = this.props;

    if (type === 'edit') {
      changePurchaseOrder(`/purchase_orders/${selectedDocument.id}/to_draft`, 'PUT');
    } else if (type === 'print') {
      printDocument(`/purchase_orders/${selectedDocument.id}/print`);
    }
  };

  render() {
    const {
      status,
      documentData,

      warningContentModal,

      modelList,
      selectedModel,
      customerList,
      selectedCustomer,
      openCustomerModal,

      orderReason,
      estimatedStartingDate,
      comment,
      staff,

      orderReasonError,

      validDocument,
      validContent,

      isCreate,
      timeSpent,
      loadedPurchase,
      hasDataChanged,
      messageError,
      plannedHoursError,
    } = this.state;

    const {
      loadingPrintDocument,
      listCompanySettings,
      loadingListModel,
      loadingListCustomer,
      loadingCompanySettings,
      loadingRetrievePurchaseOrder,
      loadingCreatePurchaseOrder,
      loadingUpdatePurchaseOrder,
      loadingChangePurchaseOrder,
      retrievedError,
      match,
      t,
    } = this.props;

    const customers = getCustomerList(customerList);
    const models = getModelList(modelList);
    let customerObject = null;
    const offerReasons = getOfferReasons(listCompanySettings);

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (loadingChangePurchaseOrder) {
      let label = '';

      if (loadingChangePurchaseOrder.includes('duplicate')) {
        label = t('documentDuplicateLoader', {
          document: t('documentNumbering_PURCHASE_ORDER').toLowerCase(),
        });
      } else if (loadingChangePurchaseOrder.includes('pending')) {
        label = t('documentValidate');
      } else if (loadingChangePurchaseOrder.includes('model')) {
        label = t('documentToModelLoader');
      } else if (loadingChangePurchaseOrder.includes('invoice')) {
        label = t('documentTransform', {
          from: t('documentNumbering_PURCHASE_ORDER'),
          to: t('documentNumbering_INVOICE'),
        });
      }

      return (
        <ContainerGeneral>
          <SectionLoader status={!!loadingChangePurchaseOrder} label={label} />
        </ContainerGeneral>
      );
    }

    if (retrievedError) {
      return <WrongDocument />;
    }

    const isLoading = loadingCreatePurchaseOrder
      || loadingUpdatePurchaseOrder
      || loadingChangePurchaseOrder;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        {(loadedPurchase || isCreate) && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrievePurchaseOrder}
          />
        )}

        <BackHeader
          title={match.params.id ? t('purchaseOrdersUpdateTitle') : t('purchaseOrdersCreateTitle')}
          to="/business/purchase_orders/"
        />

        <ActionButtons
          isPending={isPending(documentData)}
          handleAction={this.handleAction}
          loadingEditDocument={loadingPrintDocument}
          loadingPrintDocument={loadingPrintDocument}
        />

        {isDraft(documentData) && (
          <TwelveForm loading={isLoading}>
            <Form.Group inline>
              <Form.Select
                label={t('formExistingCustomer')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                fluid
                search
                selection
                loading={loadingListCustomer}
                disabled={loadingListCustomer}
                noResultsMessage="No results"
                options={customers}
                name="selectedCustomer"
                onChange={this.handleSelectChange}
                value={selectedCustomer}
                selectOnBlur={false}
              />
            </Form.Group>

            <InlineField>
              <label>{t('formNewCustomer')}</label>
              <EssorButton
                icon
                type="plus"
                onClick={this.handleOpenCreateCustomer}
              />
            </InlineField>

            {customerObject && (
              <Form.Group inline>
                <Form.Select
                  label={t('quoteModel')}
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  fluid
                  search
                  selection
                  loading={loadingListModel}
                  disabled={loadingListModel}
                  noResultsMessage="No results"
                  options={models}
                  name="selectedModel"
                  onChange={this.handleModelChange}
                  value={selectedModel}
                  selectOnBlur={false}
                />
              </Form.Group>
            )}
          </TwelveForm>
        )}

        <CreateCustomer
          modalOpen={openCustomerModal}
          onClose={this.closeCustomerModal}
          onSuccess={customer => this.customerCreateSuccess(customer)}
        />

        <ReplaceContentModal
          warningContentModal={warningContentModal}
          onWarningClose={this.onWarningClose}
          setModelContent={this.setModelContent}
        />

        <hr />

        {customerObject && (
          <React.Fragment>
            <Document
              type={PURCHASE_ORDER}
              status={status}
              documentData={documentData}
              customer={customerObject}
              validData={validDocument}
              validContent={validContent}
              getData={(documentData, msgError) => this.onSubmit(documentData, msgError)}
              getContent={content => this.onModelChange(content)}
            />

            {isNotDraft(documentData) && (
              <SmallForm loading={isLoading}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <Form.Group inline>
                        <Form.Select
                          label={t('formPurchaseOrderReason')}
                          control={Dropdown}
                          placeholder={t('formPHSelect')}
                          fluid
                          search
                          selection
                          loading={loadingCompanySettings}
                          disabled={loadingCompanySettings}
                          noResultsMessage="No results"
                          options={offerReasons}
                          name="orderReason"
                          onChange={this.handleSelectChange}
                          value={orderReason}
                          error={orderReasonError}
                          selectOnBlur={false}
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Input
                          label={t('formEstimatedDate')}
                          name="estimatedStartingDate"
                          isClearable
                          control={DatePicker}
                          selected={estimatedStartingDate}
                          onChange={this.handleEstimateDateChange}
                          locale="fr"
                          autoComplete="off"
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.TextArea
                          label={t('formComments')}
                          name="comment"
                          placeholder={t('formPHComments')}
                          value={comment}
                          onChange={e => this.handleInputChange(e)}
                        />
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <SelectEmployees
                  staff={staff}
                  setStaff={this.setStaff}
                  setPlannedHoursError={this.setPlannedHoursError}
                />

              </SmallForm>
            )}

            <ErrorMessages messages={messageError} />

            <SaveButtons
              disabled={isLoading || plannedHoursError}
              loading={isLoading}
              onClick={this.handleOnSubmit}
              isDraft={isDraft(documentData)}
            />
          </React.Fragment>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  setPurchaseOrder: purchase => dispatch(updatePurchaseSuccess(purchase)),
  getPurchaseOrder: page => dispatch(retrievePurchaseOrder(page)),
  getCustomerList: page => dispatch(listCustomer(page)),
  getModelList: page => dispatch(listModel(page)),
  getArticles: page => dispatch(listArticle(page)),
  postPurchaseOrder: data => dispatch(createPurchaseOrder(data)),
  updatePurchaseOrder: (item, data) => dispatch(updatePurchaseOrder(item, data)),
  changePurchaseOrder: (route, method) => dispatch(changePurchaseOrder(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setPurchaseOrderList: purchaseOrders => dispatch(setPurchaseOrderList(purchaseOrders)),
  selectDocument: document => dispatch(selectDocument(document)),
  labourCost: total => dispatch(labourCost(total)),
  plannedHours: total => dispatch(plannedHours(total)),
  reset: () => {
    dispatch(resetListCustomer());

    dispatch(resetArticle());
    dispatch(resetModel());

    dispatch(resetChangePurchaseOrder());

    dispatch(resetUpdatePurchaseOrder());

    dispatch(successPurchaseOrder(null));
    dispatch(loadingPurchaseOrder(false));
    dispatch(errorPurchaseOrder(null));

    dispatch(plannedHours(null));
    dispatch(labourCost(null));
  },
});

const mapStateToProps = state => ({
  listPurchaseOrder: state.purchaseOrder.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  listModel: state.model.list.data,
  loadingListModel: state.model.list.loading,
  errorListModel: state.model.list.error,

  createdPurchaseOrder: state.purchaseOrder.create.created,
  loadingCreatePurchaseOrder: state.purchaseOrder.create.loading,
  errorCreatePurchaseOrder: state.purchaseOrder.create.error,

  retrievedPurchaseOrder: state.purchaseOrder.update.retrieved,
  retrievedError: state.purchaseOrder.update.retrieveError,
  loadingRetrievePurchaseOrder: state.purchaseOrder.update.retrieveLoading,
  errorRetrievePurchaseOrder: state.purchaseOrder.update.retrieveError,
  updatedPurchaseOrder: state.purchaseOrder.update.updated,
  loadingUpdatePurchaseOrder: state.purchaseOrder.update.updateLoading,
  errorUpdatePurchaseOrder: state.purchaseOrder.update.updateError,

  changedPurchaseOrder: state.purchaseOrder.change.changed,
  loadingChangePurchaseOrder: state.purchaseOrder.change.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,

  labourCostProp: state.document.liveDoc.labourCost,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditPurchaseOrder);

export default withTranslation()(withRouter(Main));
