import React, { FC, ReactNode } from 'react';

interface SubNavProps {
  children: ReactNode;
  hide?: boolean;
  prefix?: string;
}

export const SubNavContext = React.createContext({
  prefix: '',
});

const SubNav: FC<SubNavProps> = ({ children, hide, prefix = '' }: SubNavProps) => {
  if (hide) {
    return (
      <React.Fragment />
    );
  }

  return (
    <SubNavContext.Provider
      value={{
        prefix,
      }}
    >
      <div className="sub-nav">
        <ul>
          {children}
        </ul>
      </div>
    </SubNavContext.Provider>
  );
};

SubNav.defaultProps = {
  hide: false,
  prefix: '',
};

export default SubNav;
