import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { find, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { entities } from 'utils/constants';
import { retrieve as getRole } from 'actions/role/show';
import { Icon, Table } from 'semantic-ui-react';

import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import TableLoader from 'components/TableLoader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import CenterCell from 'layouts/table/CenterCell';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import { Entities } from 'types/accessRights';
import ChildrenRights from 'routes/admin/company/settings/roles/show/ChildrenRights';
import { useTranslation } from 'react-i18next';
import NotFound from 'routes/admin/404';

const ShowRole = () => {
  const { t } = useTranslation();
  const listRoles = useSelector(state => state.role.list.data);
  const role = useSelector(state => state.role.show.retrieved);
  const loading = useSelector(state => state.role.show.loading);
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      if (find(listRoles['hydra:member'], {
        id: parseInt(id, 10),
      })) {
        dispatch(getRole(`/roles/${id}`));
      } else {
        setNotFound(true);
      }
    }
  },
  [id, listRoles]);

  const showCheck = (rights, right) => {
    if (!rights) {
      return;
    }
    const result = rights.includes(right);
    if (result) {
      return (
        <Icon color="green" name="checkmark" size="large" />
      );
    }
  };

  if (notFound) {
    return <NotFound />;
  }

  return (
    <ContainerGeneral>
      <ModifyHeader
        title={`${t('formRole')} : ${role ? role.name : ''}`}
        back="/company/settings/roles"
        to={`/company/settings/roles/${id}/edit`}
        entity={Entities.role}
      />

      <TableLoader status={loading} table celled definition tableName="definition-table">
        <HeaderRow>
          <Table.HeaderCell />
          <CenterCell content={t('createTitle')} />
          <CenterCell content={t('readTitle')} />
          <CenterCell content={t('updateTitle')} />
          <CenterCell content={t('deleteTitle')} />
        </HeaderRow>
        <Table.Body>
          {!isEmpty(role) && entities.map((entity, i) => (
            <React.Fragment>
              {!isEmpty(role.rights[entity.section]) && (
                <React.Fragment>
                  <Table.Row key={i}>
                    <LeftCell content={t(`identityRights${entity.section}`)} />
                    <CenterCell colSpan={4} />
                  </Table.Row>
                  <ChildrenRights check={showCheck} entity={entity} rights={role.rights} />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Table.Body>
      </TableLoader>
    </ContainerGeneral>
  );
};

export default ShowRole;
