import React, { FC } from 'react';
import { Button, StrictButtonProps, Table } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { crudRights } from '../../types/accessRights';
import useAccessRights from '../../hooks/useAccessRights';
import { CheckAccessRights } from '../../types/store';

export interface ExtendProps extends StrictButtonProps, CheckAccessRights {
  onClick?: () => void;
  to?: string;
  tip?: string;
  icon?: string;
}

interface OwnProps extends ExtendProps {
  tip: string;
  icon: string;
}

type Props = OwnProps;

const CellButton: FC<Props> = ({ tip, icon, entity, right, ...others }) => {
  const { to, onClick } = others;

  if (entity && !useAccessRights(entity, right || crudRights.read)) {
    return <React.Fragment />;
  }

  let newProps = {
    ...others,
  };
  if (!onClick && to) {
    newProps = {
      ...newProps,
      as: Link,
    };
  }

  return (
    <Table.Cell textAlign="center" className="b-r-1 w-50">
      <div className="pos-rel">
        <Button
          className="table-button"
          icon={icon}
          data-tip={tip}
          {...newProps}
        />
        {tip && (<ReactTooltip className="essor-tooltip" effect="solid" place="bottom" />)}
      </div>
    </Table.Cell>
  );
};

export default CellButton;
