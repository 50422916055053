import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { PieChart } from './index';
import { State } from '../../types/store';
import { KpiDTO } from '../../types/dto';
import KeyValueTable from './KeyValueTable';

interface OwnProps {
  quotes: {
    kpi: KpiDTO[];
    chart: {
      color: boolean;
      label: string;
      rawValue: number;
      value: string;
    }[];
  };
  chartTitle: string;
  tableTitle: string;
}

interface PieChartValue {
  name: string;
  color: string;
  y: number;
}


type Props = OwnProps;

const QuotesCard: FunctionComponent<Props> = ({ quotes, tableTitle, chartTitle }) => {
  const loading = useSelector((state: State) => state.dashboard.show.loading);
  const retrieved = useSelector((state: State) => state.dashboard.show.retrieved);

  const colors = ['#566F85', '#9BC4CA', '#EF5F77', '#60C0EA', '#E2E2E2'];
  const chartData = [
    {
      name: 'Reason',
      colorByPoint: true,
      data: [] as PieChartValue[],
    },
  ];

  if (loading || isEmpty(retrieved)) {
    return <React.Fragment />;
  }

  chartData[0].data = quotes.chart.map((item, index) => ({
    name: item.label,
    color: colors[index % 5],
    y: item.rawValue,
  } as PieChartValue));

  return (
    <Grid.Column width={4}>
      <KeyValueTable data={quotes.kpi} title={tableTitle} />
      <div
        style={{
          opacity: !isEmpty(quotes.chart) && quotes.chart[0].label !== 'n/a' ? 1 : 0.2,
        }}
      >
        <PieChart
          title={chartTitle}
          data={chartData}
        />
      </div>
    </Grid.Column>
  );
};

export default QuotesCard;
