import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { find, isEmpty } from 'lodash';
import { Form } from 'semantic-ui-react';
import { list } from 'actions/company-settings/list';
import { withTranslation } from 'react-i18next';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TwelveGrid from 'layouts/TwelveGrid';
import { Entities } from 'types/accessRights';

class ShowEmployee extends React.Component {
  state = {
    contractTypeOptions: [],
  };

  componentDidMount() {
    const { getContractType, selectedCompany, listCompanySettings } = this.props;

    if (isEmpty(listCompanySettings)) {
      getContractType(`/company_settings?name=CONTRACT_TYPE&company=${selectedCompany['@id']}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listCompanySettings)
      && find(nextProps.listCompanySettings['hydra:member'], {
        name: 'CONTRACT_TYPE',
      }).value !== prevState.contractTypeOptions) {
      const contract = find(nextProps.listCompanySettings['hydra:member'], {
        name: 'CONTRACT_TYPE',
      });

      return {
        contractTypeOptions: contract.value,
      };
    }
    return null;
  }

  render() {
    const { selectedEmployee, match, t, loadingEmployee } = this.props;
    const { contractTypeOptions } = this.state;

    const contractType = find(contractTypeOptions, (item) => {
      if (item.id === selectedEmployee.details.contractType) {
        return item;
      }
    });

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={selectedEmployee && `${selectedEmployee.firstName} ${selectedEmployee.lastName}`}
          to={`/employees/${match.params.id}/edit`}
          entity={Entities.employee}
        />

        <TwelveGrid>
          <Form className="margin-top-bot main-form" size="small" loading={loadingEmployee}>
            <Form.Group inline>
              <Form.Field>
                <label>{t('formInDate')}</label>
                <h5 className="informative-field">
                  {selectedEmployee && moment(selectedEmployee.inDate).format('DD/MM/YYYY')}
                </h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formOutDate')}</label>
                <h5 className="informative-field">
                  {selectedEmployee && (selectedEmployee.outDate === null
                    ? ''
                    : moment(selectedEmployee.outDate).format('DD/MM/YYYY')
                  )}
                </h5>
              </Form.Field>
            </Form.Group>

            {(selectedEmployee && selectedEmployee.createIdentity) && (
              <React.Fragment>

                <Form.Group inline>
                  <Form.Field>
                    <label>{t('formEmail')}</label>
                    <h5 className="informative-field">{selectedEmployee && selectedEmployee.username}</h5>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <label>{t('formPassword')}</label>
                    <h5 className="informative-field">{selectedEmployee && selectedEmployee.password}</h5>
                  </Form.Field>
                </Form.Group>

              </React.Fragment>
            )}

            <Form.Group inline>
              <Form.Field>
                <label>{t('formAddress')}</label>
                <h5 className="informative-field">{selectedEmployee && selectedEmployee.details.address}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formZipCode')}</label>
                <h5 className="informative-field">{selectedEmployee && selectedEmployee.details.zipCode}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formCity')}</label>
                <h5 className="informative-field">{selectedEmployee && selectedEmployee.details.city}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formCountry')}</label>
                <h5 className="informative-field">{selectedEmployee && selectedEmployee.details.country}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formContractType')}</label>
                <h5 className="informative-field">{contractType && contractType.label.toUpperCase()}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formJobTitle')}</label>
                <h5 className="informative-field">{selectedEmployee && selectedEmployee.details.jobTitle}</h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formGender')}</label>
                <h5 className="informative-field">
                  {selectedEmployee
                  && (selectedEmployee.details.gender === 'male'
                    ? t('employeesFormMale')
                    : selectedEmployee.details.gender === 'female'
                      ? t('employeesFormFemale')
                      : '-'
                  )}
                </h5>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label>{t('formBirthday')}</label>
                <h5 className="informative-field">
                  {selectedEmployee && (selectedEmployee.details.birthday === null
                    ? ''
                    : moment(selectedEmployee.details.birthday).format('DD/MM/YYYY')
                  )}
                </h5>
              </Form.Field>
            </Form.Group>
          </Form>
        </TwelveGrid>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getContractType: query => dispatch(list(query)),
});

const mapStateToProps = state => ({
  selectedEmployee: state.userCompanies.select.selectedEmployee,
  loadingEmployee: state.companySettings.list.loading,
  selectedCompany: state.userCompanies.select.selectedCompany,
  listCompanySettings: state.companySettings.list.data,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowEmployee);

export default withTranslation()(withRouter(Main));
