import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import { EssorButton } from 'components';
import { create as createSupplier, error as errorCreateSupplier, loading as loadingCreateSupplier, success as successSupplier } from 'actions/supplier/create';
import { create as createStock, error as errorStock, loading as loadingStock, success as successStock } from 'actions/stock/create';
import { list as listSupplier, reset as resetListSupplier } from 'actions/supplier/list';
import { list as listProducts, reset as resetProducts } from 'actions/product/list';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { inputFormat } from 'utils/formatter';

class CreateStockProduct extends Component {
  state = {
    price: '',
    supplierPrice: '',
    quantity: '',
    stockSupplier: null,
    supplierName: '',
    contactName: '',
    streetName: '',
    zipCode: '',
    city: '',
    phone: '',
    email: '',
    website: '',
    newSupplyTime: '',
    comment: '',
    alert: '',
    supplierNameError: false,
    contactNameError: false,
    streetNameError: false,
    zipCodeError: false,
    cityError: false,
    phoneError: false,
    emailError: false,
    stockData: [],
    modalOpen: false,
    supplierList: null,
    newSupplier: false,

    hasDataChanged: false,
    clearState: true,
  };

  componentDidMount() {
    const { getSuppliers, selectedCompany } = this.props;
    getSuppliers(`/suppliers?company=${selectedCompany.id}`);
  }

  componentDidUpdate(prevProps) {
    const { clearState, newSupplier } = this.state;
    const {
      productCreate,
      successStock,
      listProducts,
      resetProducts,
      successSupplier,
      selectedCompany,
      closeModal,
    } = this.props;

    if (successStock && successStock !== prevProps.successStock) {
      closeModal();
      resetProducts();
      listProducts(`/products?company=${selectedCompany.id}`);
    }

    if (clearState !== productCreate) {
      this.clearCreateStock();
    }

    if (!isEmpty(successSupplier) && newSupplier) {
      this.addStock();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listSuppliers) && nextProps.listSuppliers['hydra:member'] !== prevState.supplierList) {
      return {
        supplierList: nextProps.listSuppliers['hydra:member'],
      };
    }
    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  clearCreateStock = () => {
    const { clearState } = this.state;
    this.setState({
      clearState: !clearState,
      price: '',
      supplierPrice: '',
      quantity: '',
      stockSupplier: null,
      supplierName: '',
      contactName: '',
      streetName: '',
      zipCode: '',
      city: '',
      phone: '',
      email: '',
      website: '',
      newSupplyTime: '',
      comment: '',
      alert: '',
      supplierList: null,
      newSupplier: false,
      hasDataChanged: false,
    });
  };

  handleOnSubmit = () => {
    const {
      newSupplier,
      supplierName,
      contactName,
      streetName,
      zipCode,
      city,
      phone,
      email,
      website,
      newSupplyTime,
      comment,
      alert,
      quantity,
      price,
      supplierPrice,
      stockSupplier,
    } = this.state;
    const { postSupplier, postStock, selectedCompany, productID } = this.props;
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;

    this.setState({
      supplierNameError: false,
      contactNameError: false,
      streetNameError: false,
      zipCodeError: false,
      cityError: false,
      phoneError: false,
      emailError: false,
      quantityError: false,
      priceError: false,
      supplierPriceError: false,
      stockSupplierError: false,
    });

    if (quantity.trim() === '') {
      isValid = false;

      this.setState({
        quantityError: true,
      });
    }

    if (price.trim() === '') {
      isValid = false;

      this.setState({
        priceError: true,
      });
    }

    if (supplierPrice.trim() === '') {
      isValid = false;

      this.setState({
        supplierPriceError: true,
      });
    }

    if (newSupplier) {
      if (supplierName.trim() === '') {
        isValid = false;

        this.setState({
          supplierNameError: true,
        });
      }

      if (contactName.trim() === '') {
        isValid = false;

        this.setState({
          contactNameError: true,
        });
      }

      if (streetName.trim() === '') {
        isValid = false;

        this.setState({
          streetNameError: true,
        });
      }

      if (zipCode.trim() === '') {
        isValid = false;

        this.setState({
          zipCodeError: true,
        });
      }

      if (city.trim() === '') {
        isValid = false;

        this.setState({
          cityError: true,
        });
      }

      if (phone.trim() === '') {
        isValid = false;

        this.setState({
          phoneError: true,
        });
      }

      if (email.trim() === '' || !regex.test(email)) {
        isValid = false;

        this.setState({
          emailError: true,
        });
      }

      if (!isValid) return;

      const supplier = {
        company: selectedCompany['@id'],
        supplierName,
        contactName,
        streetName,
        zipCode: parseInt(zipCode, 10),
        city,
        phone,
        email,
        website,
        supplyTime: newSupplyTime,
        comment,
        alert,
      };

      postSupplier(supplier);
      return;
    }

    if (stockSupplier === null) {
      isValid = false;

      this.setState({
        stockSupplierError: true,
      });
    }

    if (!isValid) return;

    this.setState({
      isSupplierCreated: true,
      hasDataChanged: false,
    });

    const stock = {
      product: productID,
      price: inputFormat(price),
      supplierPrice: inputFormat(supplierPrice),
      quantity,
      supplier: stockSupplier,
    };

    postStock(stock);
  };

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleFloatChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
      hasDataChanged: true,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleCheckBoxChange = (e, value) => {
    e.preventDefault();

    const { name } = value;

    this.setState(prevState => (
      {
        [name]: !prevState[name],
        stockSupplier: null,
        hasDataChanged: true,
      }
    ));
  };

  addStock = () => {
    const { price, supplierPrice, quantity } = this.state;

    const { productID, postStock, successSupplier } = this.props;

    const stock = {
      product: productID,
      price,
      supplierPrice,
      quantity,
      supplier: successSupplier['@id'],
    };

    postStock(stock);
    this.setState({
      newSupplier: false,
    });
  };

  render() {
    const {
      price,
      supplierPrice,
      quantity,
      stockSupplier,
      priceError,
      supplierPriceError,
      quantityError,
      stockSupplierError,
      supplierName,
      contactName,
      streetName,
      zipCode,
      city,
      phone,
      email,
      website,
      newSupplyTime,
      comment,
      alert,
      supplierNameError,
      contactNameError,
      streetNameError,
      zipCodeError,
      cityError,
      phoneError,
      emailError,
      supplierList,
      newSupplier,
    } = this.state;

    const {
      loadingSuppliers,
      loadingCreateSupplier,
      loadingCreateStock,
      t,
      productCreate,
      closeModal,
    } = this.props;

    const loadingForm = loadingCreateStock || loadingCreateSupplier;

    let supplies = [];

    if (supplierList && supplierList.length > 0) {
      supplies = supplierList.map((supply, index) => ({
        key: index,
        text: supply.supplierName,
        value: supply['@id'],
      }));
    }

    return (
      <Modal
        open={productCreate}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="mid-content small-width"
      >
        <Modal.Header>{t('stockCreateTitle')}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form className="margin-top-bot main-form" loading={loadingForm} size="small">
              <Form.Group inline>
                <Form.Field error={priceError}>
                  <label>{t('formPrice')}</label>
                  <Input>
                    <Cleave
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 2,
                        numeralDecimalMark: ',',
                      }}
                      onChange={e => this.handleFloatChange(e)}
                      name="price"
                      placeholder={t('formPHPrice')}
                      value={inputFormat(price, true)}
                    />
                  </Input>
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field error={supplierPriceError}>
                  <label>{t('formSupplierPrice')}</label>
                  <Input>
                    <Cleave
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 2,
                        numeralDecimalMark: ',',
                      }}
                      onChange={e => this.handleFloatChange(e)}
                      name="supplierPrice"
                      placeholder={t('formPHPrice')}
                      value={inputFormat(supplierPrice, true)}
                    />
                  </Input>
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field error={quantityError}>
                  <label>{t('formQuantity')}</label>
                  <Input>
                    <Cleave
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 2,
                        numeralDecimalMark: ',',
                      }}
                      onChange={e => this.handleFloatChange(e)}
                      name="quantity"
                      placeholder={t('formPHQuantity')}
                      value={inputFormat(quantity, true)}
                    />
                  </Input>
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Select
                  label={t('formSupplier')}
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  fluid
                  search
                  selection
                  loading={loadingSuppliers}
                  noResultsMessage="No results"
                  options={supplies}
                  disabled={newSupplier}
                  name="stockSupplier"
                  onChange={this.handleSelectChange}
                  value={stockSupplier}
                  error={stockSupplierError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Checkbox
                  label={t('formNewSupplier')}
                  name="newSupplier"
                  checked={newSupplier}
                  onChange={this.handleCheckBoxChange}
                />
              </Form.Group>

              {newSupplier
                && (
                  <React.Fragment>
                    <Form.Group inline>
                      <Form.Input
                        label={t('formSupplierName')}
                        name="supplierName"
                        placeholder={t('formPHSupplierName')}
                        value={supplierName}
                        onChange={this.handleTextChange}
                        error={supplierNameError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formContactName')}
                        name="contactName"
                        placeholder={t('formPHContactName')}
                        value={contactName}
                        onChange={this.handleTextChange}
                        error={contactNameError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formStreetName')}
                        name="streetName"
                        placeholder={t('formPHStreetName')}
                        value={streetName}
                        onChange={this.handleTextChange}
                        error={streetNameError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formZipCode')}
                        name="zipCode"
                        placeholder={t('formPHZipCode')}
                        value={zipCode}
                        onChange={this.handleTextChange}
                        error={zipCodeError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formCity')}
                        name="city"
                        placeholder={t('formPHCity')}
                        value={city}
                        onChange={this.handleTextChange}
                        error={cityError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formPhoneNumber')}
                        name="phone"
                        placeholder={t('formPHPhoneNumber')}
                        value={phone}
                        onChange={this.handleTextChange}
                        error={phoneError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formEmail')}
                        name="email"
                        placeholder="example@email.com"
                        value={email}
                        onChange={this.handleTextChange}
                        error={emailError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formWebsite')}
                        name="website"
                        placeholder="www.example.com"
                        value={website}
                        onChange={this.handleTextChange}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formSupplyTime')}
                        name="newSupplyTime"
                        placeholder={t('formPHSupplyTime')}
                        value={newSupplyTime}
                        onChange={this.handleTextChange}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.TextArea
                        label={t('formComments')}
                        name="comment"
                        placeholder={t('formPHComments')}
                        value={comment}
                        onChange={this.handleTextChange}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.TextArea
                        label={t('formAlert')}
                        name="alert"
                        placeholder={t('formPHAlert')}
                        value={alert}
                        onChange={this.handleTextChange}
                      />
                    </Form.Group>
                  </React.Fragment>
                )}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <EssorButton disabled={loadingForm} secondary type="x" size="tiny" onClick={closeModal}>
            {t('buttonCancel')}
          </EssorButton>

          <EssorButton disabled={loadingForm} type="plus" size="tiny" onClick={this.handleOnSubmit}>
            {t('buttonAdd')}
          </EssorButton>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postSupplier: data => dispatch(createSupplier(data)),
  postStock: data => dispatch(createStock(data)),
  getSuppliers: page => dispatch(listSupplier(page)),
  listProducts: page => dispatch(listProducts(page)),
  resetProducts: () => dispatch(resetProducts()),

  reset: () => {
    dispatch(successStock(null));
    dispatch(loadingStock(false));
    dispatch(errorStock(null));
    dispatch(successSupplier(null));
    dispatch(loadingCreateSupplier(false));
    dispatch(errorCreateSupplier(null));
    dispatch(resetListSupplier());
  },
});

const mapStateToProps = state => ({
  successStock: state.stock.create.created,
  loadingCreateStock: state.stock.create.loading,
  errorCreateStock: state.stock.create.error,

  listSuppliers: state.supplier.list.data,
  loadingSuppliers: state.supplier.list.loading,
  errorSuppliers: state.supplier.list.error,

  successSupplier: state.supplier.create.created,
  loadingCreateSupplier: state.supplier.create.loading,
  errorCreateSupplier: state.supplier.create.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateStockProduct);

export default withTranslation()(withRouter(Main));
