import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listSupplierOrder, reset as resetSupplierOrderList, success as setSupplierOrderList } from 'actions/supplier-order/list';
import CheckRoute from 'components/access/CheckRoute';
import { crudRights, Entities } from 'types/accessRights';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/business/routes';
import ContainerGeneral from 'layouts/ContainerGeneral';
import CreateSupplierOrder from './edit';
import ListSupplierOrder from './list';

class SupplierOrder extends Component {
  componentDidMount() {
    const { getSupplierOrders, selectedCompany, selectDocument } = this.props;

    selectDocument(null);
    getSupplierOrders(`/supplier_orders?company=${selectedCompany.id}&pagination=false`);
  }

  componentDidUpdate(prevProps) {
    const {
      changedSupplierOrder,
      listSupplierOrder,
      selectedDocument,
      setSupplierOrderList,
      history,
    } = this.props;

    if (!isEmpty(changedSupplierOrder)
      && changedSupplierOrder !== prevProps.changedSupplierOrder
      && changedSupplierOrder['@id'] !== selectedDocument['@id']) {
      if (changedSupplierOrder['@type'] === selectedDocument['@type']) {
        listSupplierOrder['hydra:member'].push(changedSupplierOrder);
        listSupplierOrder['hydra:totalItems'] += 1;
        setSupplierOrderList(listSupplierOrder);
      }

      history.push(`/business${changedSupplierOrder['@id']}/edit`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { listSupplierOrder, loadingSupplierOrder } = this.props;

    if (loadingSupplierOrder || isEmpty(listSupplierOrder)) {
      return (
        <ContainerGeneral status={loadingSupplierOrder} />
      );
    }

    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.supplyOrder}
          path={Routes.listSupplierOrders}
          component={ListSupplierOrder}
        />
        <CheckRoute
          exact
          entity={Entities.supplyOrder}
          right={crudRights.create}
          path={Routes.createSupplierOrder}
          component={CreateSupplierOrder}
        />
        <CheckRoute
          exact
          entity={Entities.supplyOrder}
          path={Routes.editSupplierOrder}
          render={props => (
            <CreateSupplierOrder key={props.match.params.id} {...props} />
          )}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSupplierOrders: page => dispatch(listSupplierOrder(page)),
  setSupplierOrderList: supplierOrders => dispatch(setSupplierOrderList(supplierOrders)),
  selectDocument: supplierOrder => dispatch(selectDocument(supplierOrder)),
  reset: () => {
    dispatch(resetSupplierOrderList());
  },
});

const mapStateToProps = state => ({
  listSupplierOrder: state.supplierOrder.list.data,
  loadingSupplierOrder: state.supplierOrder.list.loading,
  errorSupplierOrder: state.supplierOrder.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,

  selectedDocument: state.userCompanies.select.selectedDocument,

  changedSupplierOrder: state.supplierOrder.change.changed,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(SupplierOrder);

export default withRouter(Main);
