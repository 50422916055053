import React from 'react';
import { isDraft } from 'utils/documentStatus';
import EditCellButton from 'components/buttons/EditCellButton';
import ViewCellButton from 'components/buttons/ViewCellButton';

const BusinessIconCell = ({ document }) => (
  isDraft(document)
    ? <EditCellButton to={`/business${document['@id']}/edit`} />
    : <ViewCellButton to={`/business${document['@id']}/edit`} />
);

export default BusinessIconCell;
