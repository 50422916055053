/* eslint-disable */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakdown, Budget } from '../../../../types/store';
import { AddMisc, BudgetData, MonthlyData } from './types';
import TreasuryAllocatedRow from './TreasuryAllocatedRow';
import Hide from '../../../../components/Hide';
import { breakdownWithVAT, budgetWithVAT } from './functions';

interface OwnProps {
  budget: Budget;
  budgetData: BudgetData;
  data: MonthlyData[];
  onClick: AddMisc;
}

type Props = OwnProps;

const TreasuryAllocatedBlock: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { budget, budgetData, data, onClick } = props;

  let unallocated = budgetWithVAT(budget);
  for (const breakdown of budget.breakdown) {
    unallocated -= breakdownWithVAT(breakdown, budget);
  }

  return (
    <Hide condition={!budget.allocated}>
      {budget.breakdown.map((item, index) => (
        <TreasuryAllocatedRow
          key={index}
          budget={budget}
          budgetData={budgetData}
          data={data}
          item={item}
          onClick={onClick}
        />
      ))}

      <TreasuryAllocatedRow
        label={t('other')}
        budget={budget}
        budgetData={budgetData}
        planned={unallocated}
        gap={unallocated - budgetData[budget['@id']]}
        id={budget['@id']}
        data={data}
        item={{} as Breakdown}
        onClick={onClick}
      />
    </Hide>
  );
};

export default TreasuryAllocatedBlock;
