import React, { Component } from 'react';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, find } from 'lodash';
import { create as createCreditNote, success as successCreditNote, loading as loadingCreditNote, error as errorCreditNote } from 'actions/credit-note/create';
import { change as changeCreditNote, reset as resetChangeCreditNote } from 'actions/credit-note/change';
import { selectDocument } from 'actions/user-companies/select';
import { Form, Grid, Header, Segment, Dimmer, Loader, Message } from 'semantic-ui-react';
import { EssorButton, Document, DocumentTimer } from 'components';
import { STATUS_PENDING } from 'utils/constants';
import { floatFormat } from 'utils/formatter';
import { CREDIT_NOTE } from 'utils/documentType';
import NotFound from '../../../../404';

import 'moment/locale/fr';

moment.locale('fr');

class CreateCreditNote extends Component {
  state = {
    isValid: true,

    status: null,
    invoiceId: null,
    documentData: {},

    customerList: null,
    selectedCustomer: null,
    maxAmountCreditNotes: 0,

    isCreate: true,
    timeSpent: 0,
    loadedInvoice: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { selectedDocument } = this.props;

    if (selectedDocument) {
      this.setMaxCreditNoteAmount();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedInvoice)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedInvoice.id !== prevState.invoiceId
        || nextProps.retrievedInvoice.status !== prevState.status)) {
      const documentData = {
        documentId: '-',
        content: nextProps.retrievedInvoice.content,
        reference: nextProps.retrievedInvoice.reference,
      };

      documentData.content.messages.customerNotice = '';
      documentData.content.messages.internalNotice = '';
      documentData.content.messages.paymentNotice = '';

      return {
        documentData,
        invoiceId: nextProps.retrievedInvoice.id,
        status: nextProps.retrievedInvoice.status,
        selectedCustomer: nextProps.retrievedInvoice.customer['@id'],
        invoiceReason: nextProps.retrievedInvoice.invoiceReason,
        comment: nextProps.retrievedInvoice.comment || '',
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedInvoice.timeSpent,
        loadedInvoice: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      createdCreditNote,
      changeCreditNote,
      history,
      selectedDocument,
    } = this.props;

    const { submitAction } = this.state;

    if (!isEmpty(selectedDocument) && selectedDocument !== prevProps.selectedDocument) {
      this.setMaxCreditNoteAmount();
    }

    if (!isEmpty(createdCreditNote) && createdCreditNote !== prevProps.createdCreditNote) {
      switch (submitAction) {
        case 'save':
          history.push('/business/credit_notes');
          break;
        case 'edit':
          history.push(`/business/credit_notes/${createdCreditNote.id}/edit`);
          break;
        case 'validate':
          history.push(`/business/credit_notes/${createdCreditNote.id}/edit`);
          changeCreditNote(`/credit_notes/${createdCreditNote.id}/to_pending`, 'PUT');
          break;
        default: break;
      }
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setMaxCreditNoteAmount = () => {
    const { listCreditNote, selectedDocument } = this.props;

    const totalCreditNotes = listCreditNote['hydra:member'].reduce((a, b) => a + parseFloat(b.content.creditNoteAmount), 0);
    const totalDocument = selectedDocument.totalPrice + selectedDocument.totalVAT;

    this.setState({
      maxAmountCreditNotes: (totalDocument - totalCreditNotes).toFixed(2),
    });
  };

  onSubmit = (data) => {
    this.setState({
      validDocument: false,
      error: null,
    });

    const { maxAmountCreditNotes } = this.state;

    const {
      postCreditNote,
      formTime,
      retrievedInvoice,
      selectedCompany,
    } = this.props;

    let error = null;

    if (data.content.creditNoteAmount <= 0) {
      error = 'creditNoteZeroMessage';
    } else if (parseFloat(data.content.creditNoteAmount) > parseFloat(maxAmountCreditNotes)) {
      error = 'creditNoteAmountMessage';
    }

    if (error !== null) {
      this.setState({
        error,
      });
      return;
    }

    data.invoice = retrievedInvoice['@id'];
    data.customer = retrievedInvoice.customer['@id'];
    data.company = selectedCompany['@id'];
    data.timeSpent = formTime;
    data.creationDate = moment().format('YYYY-MM-DD');

    postCreditNote(data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  render() {
    const {
      isValid,
      status,
      documentData,

      customerList,
      selectedCustomer,
      maxAmountCreditNotes,

      validDocument,
      validContent,

      isCreate,
      timeSpent,
      loadedInvoice,

      hasDataChanged,
      error,
    } = this.state;

    const {
      loadingRetrieveInvoice,
      loadingCreateCreditNote,
      loadingChangeCreditNote,
      match,
      t,
    } = this.props;

    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (loadingCreateCreditNote || loadingChangeCreditNote) {
      let label = '';

      if (loadingCreateCreditNote) {
        label = 'documentToCreditNoteLoader';
      } else if (loadingChangeCreditNote) {
        label = 'documentValidate';
      }

      return (
        <div className="section-container">
          <Segment
            basic
            className="section-loading"
          >
            <Dimmer active={loadingCreateCreditNote} inverted>
              <Loader>
                {t(label)}
              </Loader>
            </Dimmer>
          </Segment>
        </div>
      );
    }

    return (
      <div className="section-container">
        {(loadedInvoice || isCreate)
        && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveInvoice}
          />
        )}
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('creditNotesCreateTitle')}
            </Header>

            <EssorButton
              as={Link}
              to={`/business/invoices/${match.params.id}/edit`}
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          {(!status || status < STATUS_PENDING)
          && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form className="margin-top-bot main-form" loading={loadingRetrieveInvoice || loadingCreateCreditNote || loadingChangeCreditNote} size="small">
                    <Form.Field>
                      <label>{t('formCustomer')}</label>
                      <h5 className="informative-field">
                        {customerObject && customerObject.companyName}
                      </h5>
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <hr />

          {customerObject
          && (
            <React.Fragment>
              <Document
                type={CREDIT_NOTE}
                creditNoteEdit
                status={status}
                documentData={documentData}
                customer={customerObject}
                validData={validDocument}
                validContent={validContent}
                getData={(documentData, errors) => this.onSubmit(documentData, errors)}
                getContent={content => this.onModelChange(content)}
              />

              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {error !== 'creditNoteAmountMessage'
                      ? (
                        <React.Fragment>
                          <Message warning>
                            <p>
                              {t('creditNoteAmountMessage', {
                                amount: floatFormat(maxAmountCreditNotes, true),
                              })}
                            </p>
                          </Message>

                          {!isEmpty(error)
                          && (
                            <Message negative>
                              <p>
                                {t(error)}
                              </p>
                            </Message>
                          )}
                        </React.Fragment>
                      )
                      : (
                        <Message negative>
                          <Message.Header>{t('documentMessageError')}</Message.Header>
                          <Message.List>
                            <Message.Item>
                              {t('creditNoteAmountMessage', {
                                amount: floatFormat(maxAmountCreditNotes, true),
                              })}
                            </Message.Item>
                          </Message.List>
                        </Message>
                      )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <EssorButton
                      disabled={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      loading={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit('validate')}
                    >
                      {t('buttonSaveValidate')}
                    </EssorButton>

                    <EssorButton
                      disabled={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      loading={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit('edit')}
                    >
                      {t('buttonSaveAndEdit')}
                    </EssorButton>

                    <EssorButton
                      disabled={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      loading={
                        loadingCreateCreditNote
                        || loadingChangeCreditNote
                      }
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit('save')}
                    >
                      {t('buttonSave')}
                    </EssorButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postCreditNote: data => dispatch(createCreditNote(data)),
  changeCreditNote: (route, method) => dispatch(changeCreditNote(route, method)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset: () => {
    dispatch(successCreditNote(null));
    dispatch(loadingCreditNote(false));
    dispatch(errorCreditNote(null));

    dispatch(resetChangeCreditNote());
  },
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  retrievedInvoice: state.invoice.update.retrieved,
  loadingRetrieveInvoice: state.invoice.update.retrieveLoading,
  errorRetrieveInvoice: state.invoice.update.retrieveError,

  changedCreditNote: state.creditNote.change.changed,
  loadingChangeCreditNote: state.creditNote.change.loading,

  createdCreditNote: state.creditNote.create.created,
  loadingCreateCreditNote: state.creditNote.create.loading,
  errorCreateCreditNote: state.creditNote.create.error,

  listCreditNote: state.creditNote.list.data,
  loadingListCreditNote: state.creditNote.list.loading,

  formTime: state.counterForm.create.formTime,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateCreditNote);

export default withTranslation()(withRouter(Main));
