import moment from 'moment';

// Should be the only way to get the current currency symbol
export function getCurrency() {
  return '€';
}

const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  // Strip all characters but numerical ones.
  number = number.toString().replace(/[^0-9+\-Ee.]/g, '');
  const n = !Number.isFinite(parseFloat(number)) ? 0 : parseFloat(number);
  const precision = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;

  const toFixedFix = (n, precision) => {
    const k = 10 ** precision;
    return (Math.round(n * k) / k).toString();
  };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  const s = (precision ? toFixedFix(n, precision) : (Math.round(n)).toString()).split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < precision) {
    s[1] = s[1] || '';
    s[1] += new Array(precision - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

export function floatFormat(value = 0, showCurrency = false, decimals = 2, decPoint = ',', thousandsSep = ' ') {
  if (value === null) value = 0;
  let result = numberFormat(value, decimals, decPoint, thousandsSep);

  if (showCurrency) {
    result = `${result} ${getCurrency()}`;
  }
  return result;
}

export function percentageFormat(value) {
  return `${parseFloat(value).toFixed(2).replace('.', ',')} %`;
}

export function dateFormat(date) {
  return moment(date).format('DD/MM/YYYY');
}

export function apiDateFormat(date) {
  return moment(date).format('YYYY-MM-DD');
}

export const monthAndYear = date => (`${moment.localeData('fr').months(date)} ${date.year()}`);
export const monthShortAndYear = date => (`${moment.localeData('fr').monthsShort(date)} ${date.year()}`);

export function timeFormat(min) {
  let hours = Math.round(min / 60).toString();
  let minutes = (min % 60).toString();

  if (hours.length === 1) hours = `0${hours}`;
  if (minutes.length === 1) minutes = `0${minutes}`;

  return `${hours}:${minutes} h`;
}

export function inputFormat(value, reverse = false) {
  let val = value;

  if (value === null || value === undefined) {
    val = 0;
  }
  if (reverse) {
    return val.toString().replace('.', ',');
  }
  return val.toString().replace(',', '.').replace(' ', '');
}

export function formatToFloat(formattedValue) {
  const val = formattedValue || 0;
  return parseFloat(val.toString().replace(',', '.'));
}

// Proper french formatting for CleaveInit for both integers and floats
// Not working with old Cleave [input|parse]Format function, only use with CleaveInit
function getFrenchCleaveFormat(allowNegative, decimals) {
  return {
    numeral: true,
    numeralPositiveOnly: !allowNegative,
    numeralThousandsGroupStyle: 'thousand',
    delimiter: ' ',
    numeralDecimalScale: decimals,
    numeralDecimalMark: decimals > 0 ? ',' : '',
  };
}

// TODO : introduce setting with local format config; french will be default until then
// This must be the only format we use on CleaveInit before we introduce localized more formats
export function getLocalFormat(allowNegative = false, decimals = 2) {
  return getFrenchCleaveFormat(allowNegative, decimals);
}

// Legacy format, lack proper thousand grouping as it breaks legacy cleave code
// Must be removed step by step from usage by replacing Cleave (and format fn) by CleaveInit
export function getFloatCleaveFormat(allowNegative = false, decimals = 2) {
  return {
    numeral: true,
    numeralPositiveOnly: !allowNegative,
    numeralThousandsGroupStyle: 'none',
    delimiter: ' ',
    numeralDecimalScale: decimals,
    numeralDecimalMark: decimals > 0 ? ',' : '',
  };
}
// Same as above.
export function getIntCleaveFormat() {
  return {
    numeral: true,
    numeralThousandsGroupStyle: 'none',
    numeralPositiveOnly: true,
    numeralDecimalScale: 0,
  };
}
