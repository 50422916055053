import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import VersionDescription from './VersionDescription';

interface OwnProps {
  version: {
    label: string;
    features?: string[];
    improvements?: string[];
    bugfixes?: string[];
  };
}

type Props = OwnProps;

const VersionDetail: FunctionComponent<Props> = ({ version }) => {
  const { t } = useTranslation();
  return (
    <Modal.Description>
      <h3>{version.label}</h3>
      {version.features && version.features.length > 0 && (
        <VersionDescription detail={version.features} title={t('features')} />
      )}
      {version.improvements && version.improvements.length > 0 && (
        <VersionDescription detail={version.improvements} title={t('improvements')} />
      )}
      {version.bugfixes && version.bugfixes.length > 0 && (
        <VersionDescription detail={version.bugfixes} title={t('bugfixes')} />
      )}
    </Modal.Description>
  );
};

export default VersionDetail;
