import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonHeader, { ExtendProps } from './ButtonHeader';
import { crudRights } from '../../types/accessRights';

const ModifyHeader: FC<ExtendProps> = ({ label, children, ...others }) => {
  const { t } = useTranslation();
  return (
    <ButtonHeader
      label={label || t('buttonEdit')}
      right={crudRights.update}
      type="edit"
      {...others}
    >
      {children}
    </ButtonHeader>
  );
};

export default ModifyHeader;
