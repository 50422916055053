import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { floatFormat, inputFormat } from 'utils/formatter';
import { reset as resetForecastShow, retrieve as retrieveForecast } from 'actions/forecast/show';
import { find, isEmpty } from 'lodash';
import { create, error as errorTurnover, loading as loadingTurnover, success as successTurnover } from 'actions/turnover-balance/create';
import { reset as resetUpdateTurnover, update } from 'actions/turnover-balance/update';
import { reset as resetTurnoverList } from 'actions/turnover-balance/list';
import { reset as resetTurnoverShow, retrieve as getTurnover } from 'actions/turnover-balance/show';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import NotFound from 'routes/admin/404';
import BackHeader from 'components/pageHeaders/BackHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import PromptUnsavedData from 'components/PromptUnsavedData';
import TableLoader from 'components/TableLoader';
import TwelveGrid from 'layouts/TwelveGrid';

class CreateTurnover extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    totalTurnover: '',
    mpPurchase: '',
    mpMargin: '',
    mdPurchase: '',
    mdMargin: '',
    stPurchase: '',
    stMargin: '',
    mpTurnover: 0,
    mdTurnover: 0,
    stTurnover: 0,
    hourlyRate: '',
    hoursTurnover: '',
    soldHours: '',

    id: 0,
    isCreate: false,
    startDateError: false,
    endDateError: false,
    totalTurnoverError: false,

    hasDataChanged: false,

    notFound: false,
  };

  componentDidMount() {
    const {
      dataTurnover,
      getForecast,
      selectedCompany,
      getTurnover,
      match,
    } = this.props;

    if (match.params.turnoverId) {
      if (find(dataTurnover['hydra:member'], {
        id: parseInt(match.params.turnoverId, 10),
      })) {
        getTurnover(`/turnover_balances/${match.params.turnoverId}`);
      } else {
        this.setState({
          notFound: true,
        });
        return;
      }
    }

    if (!match.params.turnoverId) {
      getForecast(`/companies/${selectedCompany.id}/forecast_result/`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedTurnover) && !isEmpty(nextProps.match.params)
      && nextProps.retrievedTurnover.id !== prevState.id) {
      const {
        id,
        startDate,
        endDate,
        totalTurnover,
        mpPurchase,
        mpMargin,
        mpTurnover,
        mdPurchase,
        mdMargin,
        mdTurnover,
        stPurchase,
        stMargin,
        stTurnover,
        hourlyRate,
        hoursTurnover,
        soldHours,
      } = nextProps.retrievedTurnover;

      return {
        id,
        startDate: moment(startDate),
        endDate: moment(endDate),
        totalTurnover,
        mpPurchase,
        mpMargin,
        mpTurnover,
        mdPurchase,
        mdMargin,
        mdTurnover,
        stPurchase,
        stMargin,
        stTurnover,
        hourlyRate,
        hoursTurnover,
        soldHours,
        retrievedTurnover: nextProps.retrievedTurnover,
      };
    }

    if (!isEmpty(nextProps.retrievedForecast) && !(prevState.isCreate)
      && isEmpty(nextProps.match.params)) {
      return {
        isCreate: true,
        hourlyRate: (nextProps.retrievedForecast.planned.thM || 0).toString(),
        mpMargin: (nextProps.retrievedForecast.planned.valueMP || 0).toString(),
        mdMargin: (nextProps.retrievedForecast.planned.valueMD || 0).toString(),
        stMargin: (nextProps.retrievedForecast.planned.valueST || 0).toString(),
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      successTurnover,
      updatedTurnover,
      resetTurnoverList,
      history,
    } = this.props;

    if (!isEmpty(successTurnover) && successTurnover !== prevProps.successTurnover) {
      resetTurnoverList();
      history.push(`/forecast/turnover-balance/${successTurnover.id}`);
    }

    if (!isEmpty(updatedTurnover) && updatedTurnover !== prevProps.updatedTurnover) {
      resetTurnoverList();
      history.push(`/forecast/turnover-balance/${updatedTurnover.id}`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleOnSubmit = () => {
    const {
      startDate,
      endDate,
      totalTurnover,
      mpPurchase,
      mpMargin,
      mpTurnover,
      mdPurchase,
      mdMargin,
      mdTurnover,
      stPurchase,
      stMargin,
      stTurnover,
      hourlyRate,
      hoursTurnover,
      soldHours,
      retrievedTurnover,
    } = this.state;

    const { selectedCompany, createTurnover, updateTurnover } = this.props;

    let isValid = true;

    this.setState({
      startDateError: false,
      endDateError: false,
      totalTurnoverError: false,
    });

    if (!startDate) {
      isValid = false;

      this.setState({
        startDateError: true,
      });
    }

    if (!endDate || startDate >= endDate) {
      isValid = false;

      this.setState({
        endDateError: true,
      });
    }

    if (!totalTurnover) {
      isValid = false;

      this.setState({
        totalTurnoverError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const data = {
      company: selectedCompany['@id'],
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      totalTurnover: inputFormat(totalTurnover),
      mpPurchase: mpPurchase ? inputFormat(mpPurchase) : '0',
      mpMargin: mpMargin ? inputFormat(mpMargin) : '0',
      mpTurnover: mpTurnover ? inputFormat(mpTurnover) : '0',
      mdPurchase: mdPurchase ? inputFormat(mdPurchase) : '0',
      mdMargin: mdMargin ? inputFormat(mdMargin) : '0',
      mdTurnover: mdTurnover ? inputFormat(mdTurnover) : '0',
      stPurchase: stPurchase ? inputFormat(stPurchase) : '0',
      stMargin: stMargin ? inputFormat(stMargin) : '0',
      stTurnover: stTurnover ? inputFormat(stTurnover) : '0',
      hourlyRate: hourlyRate ? inputFormat(hourlyRate) : '0',
      hoursTurnover: hoursTurnover ? inputFormat(hoursTurnover) : '0',
      soldHours: soldHours ? inputFormat(soldHours) : '0',
    };

    retrievedTurnover ? updateTurnover(retrievedTurnover, data) : createTurnover(data);

    this.setState({
      hasDataChanged: false,
    });
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  calculateTurnover = (aca, mode) => {
    const { selectedCompany } = this.props;
    const parsedAca = parseFloat(aca);
    const parsedMode = parseFloat(mode);

    if (Number.isNaN(parsedAca) || Number.isNaN(parsedMode)) {
      return 0;
    }

    if (selectedCompany.calculationMode === 'rate' && parsedMode !== 100) {
      return parsedAca / (1 - (parsedMode / 100));
    }
    if (selectedCompany.calculationMode === 'coef') {
      return parsedAca * parsedMode;
    }
    return 0;
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
      hasDataChanged: true,
    }, () => {
      this.calculateBalance(name);
    });
  };

  calculateBalance = (name) => {
    const {
      mpPurchase,
      mpMargin,
      mdPurchase,
      mdMargin,
      stPurchase,
      stMargin,
    } = this.state;
    let result;

    if (name === 'mpPurchase' || name === 'mpMargin') {
      result = this.calculateTurnover(mpPurchase, mpMargin);
      this.setState({
        mpTurnover: result,
      }, () => {
        this.calculationHours(name);
      });
    }
    if (name === 'mdPurchase' || name === 'mdMargin') {
      result = this.calculateTurnover(mdPurchase, mdMargin);
      this.setState({
        mdTurnover: result,
      }, () => {
        this.calculationHours(name);
      });
    }
    if (name === 'stPurchase' || name === 'stMargin') {
      result = this.calculateTurnover(stPurchase, stMargin);
      this.setState({
        stTurnover: result,
      }, () => {
        this.calculationHours(name);
      });
    }
    this.calculationHours(name);
  };

  calculationHours(name) {
    const {
      mpTurnover,
      mdTurnover,
      stTurnover,
      totalTurnover,
    } = this.state;
    if ((name === 'totalTurnover' && !isEmpty(totalTurnover)) || totalTurnover) {
      const sumaTurnovers = Number(mpTurnover)
        + Number(mdTurnover) + Number(stTurnover);
      this.setState({
        hoursTurnover: totalTurnover - sumaTurnovers,
      }, () => {
        const { hoursTurnover, hourlyRate } = this.state;
        if (Number(hoursTurnover) && Number(hourlyRate)
          && (!Number.isNaN(hoursTurnover)
            && !Number.isNaN(hourlyRate))) {
          this.setState({
            soldHours: hoursTurnover / hourlyRate,
          });
        } else {
          this.setState({
            soldHours: '',
          });
        }
      });
    } else {
      this.setState({
        hoursTurnover: '',
        soldHours: '',
      });
    }
  }

  render() {
    const {
      t,
      loadingForecast,
      match,
      loadingTurnover,
      selectedCompany,
      loadingCreateTurnover,
      loadingUpdateTurnover,
    } = this.props;
    const {
      startDate,
      endDate,
      totalTurnover,
      mpPurchase,
      mpMargin,
      mpTurnover,
      mdPurchase,
      mdMargin,
      mdTurnover,
      stPurchase,
      stMargin,
      stTurnover,
      hourlyRate,
      hoursTurnover,
      soldHours,
      startDateError,
      endDateError,
      totalTurnoverError,
      notFound,
      hasDataChanged,
    } = this.state;

    const updateID = match.params.turnoverId;
    const { calculationMode } = selectedCompany;
    if (notFound) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    return (
      <ContainerGeneral>
        <PromptUnsavedData hasDataChanged={hasDataChanged} />
        <div className="option-buttons-container clearfix">
          <BackHeader
            title={updateID ? t('updateTurnoverBalance') : t('createTurnoverBalance')}
            to={updateID ? `/forecast/turnover-balance/${updateID}` : '/forecast/turnover-balance/'}
          />
        </div>
        <TableLoader status={loadingForecast || loadingTurnover}>
          <TwelveGrid>
            <Form
              className="margin-top-bot main-form"
              size="small"
              loading={loadingTurnover || loadingCreateTurnover || loadingUpdateTurnover}
            >
              <div className="margin-block">
                <Form.Group inline>
                  <Form.Input
                    label={t('formDateStart')}
                    name="startDate"
                    autoComplete="off"
                    control={DatePicker}
                    selected={startDate}
                    onChange={this.handleStartDateChange}
                    locale="fr"
                    error={startDateError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Input
                    label={t('formDateEnd')}
                    name="endDate"
                    autoComplete="off"
                    control={DatePicker}
                    selected={endDate}
                    onChange={this.handleEndDateChange}
                    locale="fr"
                    error={endDateError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field error={totalTurnoverError}>
                    <label>{t('turnoverBalanceTotal')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        value={inputFormat(totalTurnover, true)}
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="totalTurnover"
                        placeholder={t('turnoverBalanceTotal')}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="margin-block">
                <h3>{t('turnoverBalanceRawMaterialsSubtitle')}</h3>
                <Form.Group inline>
                  <Form.Field>
                    <label>{t('turnoverBalanceFormPurchase')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="mpPurchase"
                        placeholder={t('turnoverBalanceFormPurchasePH')}
                        value={inputFormat(mpPurchase, true)}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <label>{calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="mpMargin"
                        placeholder="10,00"
                        value={inputFormat(mpMargin, true)}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      label={t('turnoverBalanceFormTurnover')}
                      name="mpTurnover"
                      value={floatFormat(inputFormat(mpTurnover))}
                      onChange={this.handleInputChange}
                      placeholder={t('turnoverBalanceFormTurnoverPH')}
                      disabled
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="margin-block">
                <h3>{t('turnoverBalanceGoodsSubtitle')}</h3>
                <Form.Group inline>
                  <Form.Field>
                    <label>{t('turnoverBalanceFormPurchase')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="mdPurchase"
                        placeholder={t('turnoverBalanceFormPurchasePH')}
                        value={inputFormat(mdPurchase, true)}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <label>{calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="mdMargin"
                        placeholder="10,00"
                        value={inputFormat(mdMargin, true)}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      label={t('turnoverBalanceFormTurnover')}
                      name="mdTurnover"
                      value={floatFormat(inputFormat(mdTurnover))}
                      placeholder={t('turnoverBalanceFormTurnoverPH')}
                      onChange={this.handleInputChange}
                      disabled
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="margin-block">
                <h3>{t('turnoverBalanceOutsourcingSubtitle')}</h3>
                <Form.Group inline>
                  <Form.Field>
                    <label>{t('turnoverBalanceFormPurchase')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="stPurchase"
                        placeholder={t('turnoverBalanceFormPurchasePH')}
                        value={inputFormat(stPurchase, true)}
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <label>{calculationMode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}</label>
                    <Input
                      labelPosition="left"
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        value={inputFormat(stMargin, true)}
                        name="stMargin"
                        placeholder="10,00"
                        autoComplete="off"
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      label={t('turnoverBalanceFormTurnover')}
                      name="stTurnover"
                      placeholder={t('turnoverBalanceFormTurnoverPH')}
                      value={floatFormat(inputFormat(stTurnover))}
                      onChange={this.handleInputChange}
                      disabled
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="margin-block">
                <h3>{t('turnoverBalanceLabourSubtitle')}</h3>
                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      label={t('turnoverBalanceFormSoldHours')}
                      name="soldHours"
                      value={floatFormat(inputFormat(soldHours))}
                      disabled
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      labelPosition="left"
                      label={t('turnoverBalanceFormHourlyRate')}
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="hourlyRate"
                        placeholder={t('turnoverBalanceFormHourlyRatePH')}
                        value={inputFormat(hourlyRate, true)}
                        autoComplete="off"
                      />
                    </Form.Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field>
                    <Form.Input
                      label={t('turnoverBalanceFormHourSales')}
                      name="hoursTurnover"
                      value={floatFormat(inputFormat(hoursTurnover))}
                      disabled
                    />
                  </Form.Field>
                </Form.Group>

              </div>
            </Form>
          </TwelveGrid>
        </TableLoader>

        <div className="clearfix">
          <EssorButton
            type="check"
            submit
            onClick={this.handleOnSubmit}
            size="small"
            floated="right"
          >
            {t('buttonSave')}
          </EssorButton>
        </div>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createTurnover: data => dispatch(create(data)),
  getForecast: page => dispatch(retrieveForecast(page)),
  getTurnover: id => dispatch(getTurnover(id)),
  updateTurnover: (item, data) => dispatch(update(item, data)),
  resetTurnoverList: () => dispatch(resetTurnoverList()),

  reset: () => {
    dispatch(successTurnover(null));
    dispatch(loadingTurnover(false));
    dispatch(errorTurnover(null));
    dispatch(resetUpdateTurnover());
    dispatch(resetForecastShow());
    dispatch(resetTurnoverShow());
  },
});

const mapStateToProps = state => ({
  retrievedForecast: state.forecast.show.retrieved,
  loadingForecast: state.forecast.show.loading,

  retrievedTurnover: state.turnoverBalance.show.retrieved,
  loadingTurnover: state.turnoverBalance.show.loading,

  updatedTurnover: state.turnoverBalance.update.updated,
  loadingUpdateTurnover: state.turnoverBalance.update.updateLoading,
  successTurnover: state.turnoverBalance.create.created,
  loadingCreateTurnover: state.turnoverBalance.create.loading,
  dataTurnover: state.turnoverBalance.list.data,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateTurnover);

export default withTranslation()(withRouter(Main));
