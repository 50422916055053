import React, { FunctionComponent, ReactNode } from 'react';
import { StrictTableRowProps, TableHeader, TableRow } from 'semantic-ui-react';

interface OwnProps extends StrictTableRowProps {
  children: ReactNode;
  headerClass?: string;
}

type Props = OwnProps;


export const HeaderRowContext = React.createContext({
  header: false,
});

const value = {
  header: true,
};

const HeaderRow: FunctionComponent<Props> = ({ children, headerClass, ...props }) => (
  <HeaderRowContext.Provider value={value}>
    <TableHeader className={headerClass}>
      <TableRow {...props}>{children}</TableRow>
    </TableHeader>
  </HeaderRowContext.Provider>
);

export default HeaderRow;
