import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import useCompanySituation from '../../../hooks/useCompanySituation';
import ContainerGeneral from '../../../layouts/ContainerGeneral';
import TitleHeader from '../../../components/pageHeaders/TitleHeader';
import TableLoader from '../../../components/TableLoader';
import HeaderRow from '../../../layouts/table/HeaderRow';
import LeftCell from '../../../layouts/table/LeftCell';
import RightCell from '../../../layouts/table/RightCell';
import SituationTitleRow from './SituationTitleRow';
import SituationMonetaryRow from './SituationMonetaryRow';
import SituationSeparatorRow from './SituationSeparatorRow';
import SituationIntegerRow from './SituationIntegerRow';
import SituationDateRow from './SituationDateRow';
import Hide from '../../../components/Hide';

const CompanySituation: FC = () => {
  const { t } = useTranslation();
  const [loading, data] = useCompanySituation();

  if (loading) {
    return <ContainerGeneral status={loading} />;
  }

  return (
    <ContainerGeneral>
      <TitleHeader title={t('situationTitle')} />

      <TableLoader status={loading} table celled className="situation-table">
        <HeaderRow>
          <LeftCell className="th-1" />
          <RightCell content={t('situationRealised')} />
          <RightCell content={t('situationPlanned')} />
          <RightCell content={t('situationRatioed')} />
          <RightCell content={t('situationCurrent')} />
          <RightCell content={t('situationGap')} />
          <RightCell content={t('situationVariance')} />
        </HeaderRow>

        <Table.Body>
          <SituationTitleRow title={t('situationTitleCharges')} />
          <SituationMonetaryRow label={t('situationFixedCharges')} data={data.fixedCharges} />
          <SituationMonetaryRow label={t('situationVariableCharges')} data={data.variableCharges} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationTotalCharges')} data={data.totalCharges} />

          <SituationSeparatorRow />
          <SituationSeparatorRow />

          <SituationTitleRow title={t('situationLabour')} />
          <SituationIntegerRow label={t('situationHoursToSell')} data={data.hoursToSell} />
          <SituationMonetaryRow label={t('situationHourlyCost')} data={data.hourlyCost} />
          <SituationMonetaryRow label={t('situationHourlyRate')} data={data.hourlyRate} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationLabourTurnover')} data={data.labourTurnover} />

          <SituationSeparatorRow />
          <SituationSeparatorRow />

          <SituationTitleRow title={t('situationSales')} />
          <SituationMonetaryRow label={t('situationMpPurchase')} data={data.mpPurchase} />
          <SituationMonetaryRow label={t('situationMpTurnover')} data={data.mpTurnover} />
          <SituationMonetaryRow label={t('situationMpMarginRate')} data={data.mpMarginRate} />
          <SituationMonetaryRow label={t('situationMpMarginValue')} data={data.mpMarginValue} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationMdPurchase')} data={data.mdPurchase} />
          <SituationMonetaryRow label={t('situationMdTurnover')} data={data.mdTurnover} />
          <SituationMonetaryRow label={t('situationMdMarginRate')} data={data.mdMarginRate} />
          <SituationMonetaryRow label={t('situationMdMarginValue')} data={data.mdMarginValue} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationStPurchase')} data={data.stPurchase} />
          <SituationMonetaryRow label={t('situationStTurnover')} data={data.stTurnover} />
          <SituationMonetaryRow label={t('situationStMarginRate')} data={data.stMarginRate} />
          <SituationMonetaryRow label={t('situationStMarginValue')} data={data.stMarginValue} />

          <SituationSeparatorRow />
          <SituationSeparatorRow />

          <SituationTitleRow title={t('situationResultSynthesis')} />
          <SituationMonetaryRow label={t('situationTotalCharges')} data={data.totalCharges} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationMpTurnover')} data={data.mpTurnover} />
          <SituationMonetaryRow label={t('situationMdTurnover')} data={data.mdTurnover} />
          <SituationMonetaryRow label={t('situationStTurnover')} data={data.stTurnover} />
          <SituationMonetaryRow label={t('situationLabourTurnover')} data={data.labourTurnover} />
          <SituationMonetaryRow label={t('situationTurnover')} data={data.turnover} />
          <SituationSeparatorRow />
          <SituationMonetaryRow label={t('situationOperatingProfit')} data={data.operatingProfit} />

          <SituationSeparatorRow />
          <SituationSeparatorRow />

          <SituationTitleRow title={t('situationCompanyIndicators')} />
          <SituationIntegerRow label={t('situationNewCustomers')} data={data.newCustomers} />
          <SituationSeparatorRow />
          <SituationTitleRow title={t('situationNotableThresholds')} />
          <SituationMonetaryRow label={t('situationTurnover')} data={data.turnover} noGap />
          <SituationMonetaryRow label={t('situationTotalFixedCharges')} data={data.fixedCharges} noGap />
          <SituationMonetaryRow label={t('situationTotalVariableCharges')} data={data.variableCharges} noGap />

          <Hide>
            <SituationMonetaryRow
              label={t('situationVariableCostMargin')}
              data={data.variableCostMargin}
              noGap
            />
            <SituationMonetaryRow
              label={t('situationVariableCostMarginRate')}
              data={data.variableCostMarginRate}
              noGap
            />

            <SituationMonetaryRow label={t('situationBreakEven')} data={data.breakEven} noGap />
            <SituationDateRow
              label={t('situationBreakEvenDate')}
              data={data.breakEvenDate}
              noGap
            />
            <SituationMonetaryRow
              label={t('situationResultThreshold')}
              data={data.resultThreshold}
              noGap
            />
            <SituationDateRow
              label={t('situationResultThresholdDate')}
              data={data.resultThresholdDate}
              noGap
            />
          </Hide>
        </Table.Body>
      </TableLoader>
    </ContainerGeneral>
  );
};

export default CompanySituation;
