import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'semantic-ui-react';

interface OwnProps {
  children: ReactNode;
  label: string;
}

type Props = OwnProps;

const FieldWithLabel: FunctionComponent<Props> = ({ label, children }) => (
  <div>
    <Form.Field inline>
      <label
        style={{
          marginBottom: '5px',
        }}
      >
        {label}
      </label>
      {children}
    </Form.Field>
  </div>
);

export default FieldWithLabel;
