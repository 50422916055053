import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';
import 'moment/locale/fr';
import Big from 'big.js';
import { findIndex, isEmpty } from 'lodash';
import { apiDateFormat, floatFormat, formatToFloat, inputFormat } from 'utils/formatter';
import { list as listInvoices, reset as resetInvoiceList } from 'actions/invoice/list';
import { list as listBankAccount, reset as resetBankAccount } from 'actions/company-settings/list';
import { create as createPayment, error as errorPayment, loading as loadingPayment, success as successPayment } from 'actions/payment/create';
import { Checkbox, Dropdown, Form, Icon, Input, Message, Modal, Table } from 'semantic-ui-react';
import { toast } from 'components';
import { withTranslation } from 'react-i18next';
import { getActualTotal, getActualVat } from 'components/documents/documents';
import DateCell from 'components/cell/DateCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import CloseButton from 'components/buttons/CloseButton';
import SaveButton from 'components/buttons/SaveButton';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import { Entities } from 'types/accessRights';
import AdvancedList from 'components/advancedList/AdvancedList';
import { cellDefinition, columnDefinition, handlerDefinition } from 'utils/tables';
import { customerDisplay, getPaymentData } from 'components/documents/documentCommon';
import { keyVal } from 'utils/functions';
import { DESC } from 'utils/constants';

moment.locale('fr');

class ListPayment extends Component {
  state = {
    invoiceData: null,
    invoiceList: [],
    selectedRows: [],
    openPaymentModal: false,
    openPaymentList: false,
    date: null,
    dateError: false,
    selectedBankError: false,
    invoicePaymentList: null,
    warningLess: false,
    warningMore: false,
    selectedBank: '',
    optionsBankAccount: [],
    bankAccount: [],
  };

  componentDidMount() {
    const { getInvoices, selectedCompany, listBankAccount } = this.props;

    getInvoices(`/invoices?company=${selectedCompany.id}&pagination=false&status[]=4&status[]=6`);
    listBankAccount(`/company_settings?company=${selectedCompany.id}&name=BANK_ACCOUNTS`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataInvoice) && nextProps.dataInvoice['hydra:member'] !== prevState.invoiceData) {
      let invoiceList = nextProps.dataInvoice['hydra:member'];

      invoiceList = invoiceList.map((invoice) => {
        if (invoice.content.total.dueAmount) {
          invoice.total = parseFloat(invoice.content.total.dueAmount);
          const actual = parseFloat(getActualTotal(invoice).toFixed(2));
          invoice.totalVAT = parseFloat(getActualVat(invoice).toFixed(2));
          invoice.totalPrice = actual;
        } else {
          invoice.total = parseFloat(invoice.totalPrice) + parseFloat(invoice.totalVAT);
        }

        return {
          ...invoice,
          ...getPaymentData(invoice),
        };
      });

      return {
        invoiceData: nextProps.dataInvoice['hydra:member'],
        invoiceList,
      };
    }

    if (!isEmpty(nextProps.dataBankAccount) && nextProps.dataBankAccount['hydra:member'] !== prevState.bankAccount) {
      let bankDefault = null;
      const optionsBank = nextProps.dataBankAccount['hydra:member'][0].value.map((bank) => {
        if (bank.default) {
          bankDefault = bank.id;
        }
        return {
          key: bank.id,
          text: bank.bankName,
          value: bank.id,
        };
      });
      return {
        bankAccount: nextProps.dataBankAccount['hydra:member'],
        optionsBankAccount: optionsBank,
        selectedBank: bankDefault,
      };
    }

    if (!isEmpty(nextProps.createdPayment) && nextProps.createdPayment !== prevState.paymentAux) {
      const { invoiceList } = prevState;

      const index = findIndex(invoiceList, {
        '@id': nextProps.createdPayment.invoice['@id'],
      });

      invoiceList[index].payment.push(nextProps.createdPayment);

      return {
        invoiceList,
        paymentAux: nextProps.createdPayment,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleCheckBoxChange = (e, { checked }, invoice) => {
    const { selectedRows } = this.state;

    if (checked) {
      selectedRows.push(invoice);
    } else {
      const index = findIndex(selectedRows, {
        '@id': invoice['@id'],
      });

      selectedRows.splice(index, 1);
    }

    this.setState({
      selectedRows,
    });
  };

  openPaymentModal = () => {
    const { selectedRows } = this.state;
    let maxAmount = 0;

    selectedRows.forEach((invoice) => {
      let totalPaid = 0;

      invoice.payment.forEach((payment) => {
        totalPaid += parseFloat(payment.amount) + parseFloat(payment.vat);
      });

      maxAmount += invoice.total - totalPaid;
    });

    this.setState({
      openPaymentModal: true,
      date: moment(),
      maxAmount: maxAmount.toFixed(2),
      amount: inputFormat(maxAmount.toFixed(2), true),
    });
  };

  closePaymentModal = () => {
    this.setState({
      openPaymentModal: false,
      date: null,
      warningLess: false,
      warningMore: false,
    });
  };

  viewPaymentList = (invoice) => {
    this.setState({
      openPaymentList: true,
      invoicePaymentList: invoice,
    });
  };

  closePaymentList = () => {
    this.setState({
      openPaymentList: false,
      invoicePaymentList: null,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const { maxAmount } = this.state;

    this.setState({
      [name]: value,
      warningLess: formatToFloat(value) < maxAmount,
      warningMore: formatToFloat(value) > maxAmount,
    });
  };

  handlePaymentSubmit = () => {
    const { selectedRows, date, amount, maxAmount, selectedBank } = this.state;
    const { postPayment, selectedCompany, t } = this.props;

    let isValid = true;

    this.setState({
      dateError: false,
      amountError: false,
    });

    if (!date) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (!selectedBank) {
      isValid = false;

      this.setState({
        selectedBankError: true,
      });
    }

    if (amount === '' || formatToFloat(amount) > maxAmount) {
      isValid = false;

      this.setState({
        amountError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const promises = [];
    let data;
    let paymentAmount = Big(formatToFloat(amount));

    // const sortedByPrice = sortBy(selectedRows, 'total');
    const sortedByPrice = selectedRows.sort((a, b) => {
      let aPayments = 0;
      let bPayments = 0;

      a.payment.forEach((payment) => {
        aPayments += parseFloat(payment.amount) + parseFloat(payment.vat);
      });

      b.payment.forEach((payment) => {
        bPayments += parseFloat(payment.amount) + parseFloat(payment.vat);
      });

      return ((a.total - aPayments) - (b.total - bPayments));
    });

    for (let i = 0; i < sortedByPrice.length; i++) {
      let paymentTotalVAT = 0;
      let paymentTotalPrice = 0;

      sortedByPrice[i].payment.forEach((payment) => {
        paymentTotalVAT += parseFloat(payment.vat);
        paymentTotalPrice += parseFloat(payment.amount);
      });

      const vatToPay = Big(sortedByPrice[i].totalVAT).minus(paymentTotalVAT);
      const amountToPay = Big(sortedByPrice[i].totalPrice).minus(paymentTotalPrice);

      const dataVAT = paymentAmount.lte(vatToPay) ? paymentAmount : vatToPay;
      const dataAmount = paymentAmount.lte(vatToPay)
        ? Big(0)
        : (
          amountToPay.gte(Big(paymentAmount).minus(vatToPay))
            ? Big(paymentAmount).minus(vatToPay)
            : amountToPay
        );

      data = {
        company: selectedCompany['@id'],
        invoice: sortedByPrice[i]['@id'],
        date: apiDateFormat(date),
        vat: dataVAT.toFixed(2),
        amount: dataAmount.toFixed(2),
        bankAccount: selectedBank,
      };

      promises.push(postPayment(data));
      paymentAmount = paymentAmount.minus(dataAmount.plus(dataVAT));

      if (paymentAmount.lte(0)) {
        break;
      }
    }

    Promise.all(promises)
      .then(() => {
        this.setState({
          selectedRows: [],
        });
        this.closePaymentModal();
        toast.success(t('paymentCreateSuccess'));
      });
  };

  render() {
    const {
      invoiceList,
      selectedRows,
      openPaymentModal,
      openPaymentList,
      invoicePaymentList,
      date,
      amount,
      dateError,
      amountError,
      maxAmount,
      warningLess,
      warningMore,
      optionsBankAccount,
      selectedBank,
      selectedBankError,
    } = this.state;

    const {
      loadingInvoice,
      loadingCreatePayment,
      t,
      loadingBankAccount,
    } = this.props;

    const selectCell = invoice => (
      <Table.Cell collapsing key={invoice['@id']}>
        {(invoice.diff.eq(Big(0)))
          ? <Icon color="green" name="checkmark" fitted />
          : (
            <Checkbox
              name="select"
              checked={findIndex(selectedRows, keyVal('@id', invoice['@id'])) !== -1}
              onChange={(e, data) => this.handleCheckBoxChange(e, data, invoice)}
            />
          )}
      </Table.Cell>
    );

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('paymentsListTitle')}
          label={t('paymentAdd')}
          onClick={this.openPaymentModal}
          disabled={isEmpty(selectedRows)}
          entity={Entities.payment}
        />

        <AdvancedList
          entity={Entities.payment}
          loading={loadingInvoice}
          columns={[
            cellDefinition('✔', 'selected', selectCell, 'boolean', invoice => invoice.diff.eq(Big(0))),
            columnDefinition('invoiceOrderNumber', 'uniqueID'),
            columnDefinition('documentPaidBefore', 'paymentDate', 'date'),
            columnDefinition('documentLastPayment', 'lastPayment', 'date'),
            handlerDefinition('formCustomer', 'customer', customerDisplay),
            columnDefinition('quoteTotalWithTaxes', 'actualTotal', 'currency'),
            columnDefinition('documentTotalPaid', 'totalPaid', 'currency'),
            handlerDefinition('documentBalance', 'diff', invoice => invoice.diff.toFixed(2), 'currency'),
          ]}
          data={invoiceList}
          onView={invoice => this.viewPaymentList(invoice)}
          viewTooltip={t('paymentView')}
          sortBy="lastPayment"
          direction={DESC}
        />

        <Modal open={openPaymentModal} className="mid-content">
          <Modal.Header>{t('paymentAdd')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>

              <Form className="main-form" size="small" loading={loadingCreatePayment}>
                <Form.Group inline>
                  <Form.Input
                    label={t('formDate')}
                    name="date"
                    control={DatePicker}
                    selected={date}
                    onChange={this.handleDateChange}
                    locale="fr"
                    autoComplete="off"
                    error={dateError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field error={amountError}>
                    <label>{t('formAmount')}</label>
                    <Input>
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={this.handleInputChange}
                        name="amount"
                        value={amount}
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Select
                    label={t('formBankName')}
                    control={Dropdown}
                    placeholder={t('formPHBankName')}
                    name="selectedBank"
                    fluid
                    search
                    selection
                    selectOnBlur={false}
                    loading={loadingBankAccount}
                    disabled={loadingBankAccount}
                    noResultsMessage="No results"
                    options={optionsBankAccount}
                    onChange={this.handleSelectChange}
                    error={selectedBankError}
                    value={selectedBank}
                  />
                </Form.Group>
              </Form>
              <Message warning hidden={!warningLess}>
                {t('paymentWarningLessAmount', {
                  maxAmount: floatFormat(maxAmount, true),
                })}
              </Message>
              <Message negative hidden={!warningMore}>
                {t('paymentWarningMoreAmount', {
                  maxAmount: floatFormat(maxAmount, true),
                })}
              </Message>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <CloseButton onClick={this.closePaymentModal} disabled={loadingCreatePayment} />
            <SaveButton onClick={this.handlePaymentSubmit} disabled={loadingCreatePayment} />
          </Modal.Actions>
        </Modal>

        <Modal open={openPaymentList} className="full-content">
          <Modal.Header>
            {invoicePaymentList && invoicePaymentList.uniqueID}
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('formDate')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('formAmount')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('formVat')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {!isEmpty(invoicePaymentList) && invoicePaymentList.payment.map(payment => (
                    <Table.Row key={payment.id}>
                      <DateCell date={payment.date} />
                      <CurrencyCell value={payment.amount} />
                      <CurrencyCell value={payment.vat} />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <CloseButton onClick={this.closePaymentList} />
          </Modal.Actions>
        </Modal>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getInvoices: page => dispatch(listInvoices(page)),
  listBankAccount: data => dispatch(listBankAccount(data)),
  postPayment: data => dispatch(createPayment(data)),
  reset: () => {
    dispatch(resetInvoiceList());
    dispatch(successPayment(null));
    dispatch(loadingPayment(false));
    dispatch(errorPayment(null));
    dispatch(resetBankAccount());
  },
});

const mapStateToProps = state => ({
  dataInvoice: state.invoice.list.data,
  loadingInvoice: state.invoice.list.loading,
  errorInvoice: state.invoice.list.error,

  createdPayment: state.payment.create.created,
  loadingCreatePayment: state.payment.create.loading,
  errorCreatePayment: state.payment.create.error,

  selectedCompany: state.userCompanies.select.selectedCompany,

  dataBankAccount: state.companySettings.list.data,
  loadingBankAccount: state.companySettings.list.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListPayment);

export default withTranslation()(withRouter(Main));
