import React from 'react';
import { dateFormat } from 'utils/formatter';

function FormattedDate(props) {
  const { date } = props;

  return (
    <React.Fragment>
      {date ? dateFormat(date) : '-'}
    </React.Fragment>
  );
}

export default FormattedDate;
