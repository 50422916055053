import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Big from 'big.js';
import CheckRights from '../access/CheckRights';
import { Budget } from '../../types/store';
import EditCellButton from '../buttons/EditCellButton';
import PercentageCell from '../cell/PercentageCell';
import CurrencyCell from '../cell/CurrencyCell';
import CellButton from '../buttons/CellButton';
import { crudRights, Entities } from '../../types/accessRights';
import RightCell from '../../layouts/table/RightCell';
import LeftCell from '../../layouts/table/LeftCell';

interface OwnProps {
  budget: Budget;
  index: number;
  editModal: (budget: Budget, edit: boolean) => void;
  deleteModal: (budget: Budget) => void;
}

type Props = OwnProps;

const BudgetRow: FunctionComponent<Props> = ({ budget, index, editModal /* , deleteModal */ }) => {
  const { t } = useTranslation();

  const noPlanned = ({ planned }: Budget): boolean => Big(planned).eq(0);
  const noRealised = ({ realised }: Budget): boolean => Big(realised).eq(0);

  return (
    <Table.Row key={index}>
      <LeftCell>{budget.account}</LeftCell>
      <LeftCell>{budget.label}</LeftCell>

      <CurrencyCell value={budget.realised} decimal={0} emptyZero />

      <CurrencyCell value={budget.planned} decimal={0} emptyZero />


      <Table.Cell textAlign="center">{budget.type}</Table.Cell>

      {noPlanned(budget) || noRealised(budget)
        ? <RightCell content="" />
        : (
          <PercentageCell
            value={((budget.planned - budget.realised) / budget.realised) * 100}
            decimal={0}
            showPositive
            color
            noGreen
          />
        )}

      <CellButton
        icon={budget.allocated ? 'file alternate' : 'file outline'}
        tip={budget.allocated ? t('budgetAllocated') : t('budgetNotAllocated')}
      />
      <CheckRights entity={Entities.budget} right={crudRights.update}>
        <EditCellButton onClick={(): void => editModal(budget, true)} />
      </CheckRights>
      {/* Budget deletion is not implemented on api side
      <CheckRights entity={Entities.budget} right={crudRights.delete}>
        <DeleteCellButton onClick={(): void => deleteModal(budget)} />
      </CheckRights> */}
    </Table.Row>
  );
};

export default BudgetRow;
