import { Entities } from 'types/accessRights';
import { keyVal } from 'utils/functions';

// Document is a draft, it can be modified
export const STATUS_DRAFT = 2;
// Document has been sent to a customer, awaiting response
export const STATUS_PENDING = 4;
// Document has been accepted by the customer, success!
export const STATUS_VALIDATED = 6;
// Document has been refused by the customer or closed by the user
export const STATUS_CLOSED = 8;
// Encasement
export const TYPE_MISC = 'misc';
export const TYPE_ADJUST = 'adjust';
// Disbursement
// export const TYPE_MISC = 'misc';
export const TYPE_VAT = 'vat';
export const TYPE_BUDGET = 'budget';

export const ASC = 'ascending';
export const DESC = 'descending';

export const backToHome = keyVal('fromHome', true);

// Legal form options
export const legalFormOptions = {
  ABL: 'Association à but lucratif',
  ABNL: 'Association à but non lucratif',
  EARL: 'Entreprise agricole à responsabilité limitée',
  EI: 'Entreprise individuelle',
  EIRL: 'Entreprise individuelle à responsabilité limitée (01.01.2010)',
  EURL: 'Entreprise unipersonnelle à responsabilité limitée',
  GAEC: 'Groupement agricole d\'exploitation en commun',
  GEIE: 'Groupement européen d\'intérêt économique',
  GIE: 'Groupement d\'intérêt économique',
  SARL: 'Société à responsabilité limitée',
  SA: 'Société anonyme',
  SAS: 'Société par actions simplifiée',
  SASU: 'Société par actions simplifiée unipersonnelle',
  SC: 'Société civile',
  SCA: 'Société en commandite par actions',
  SCI: 'Société civile immobilière',
  SCIC: 'Société coopérative d\'intérêt collectif',
  SCM: 'Société civile de moyens',
  SCOP: 'Société coopérative ouvrière de production',
  SCP: 'Société civile professionnelle',
  SCS: 'Société en commandite simple',
  SEL: 'Société d\'exercice libéral',
  SELAFA: 'Société d\'exercice libéral à forme anonyme',
  SELARL: 'Société d\'exercice libéral à responsabilité limitée',
  SELAS: 'Société d\'exercice libéral par actions simplifiée',
  SELCA: 'Société d\'exercice libéral en commandite par actions',
  SEM: 'Société d\'économie mixte',
  SEML: 'Société d\'économie mixte locale',
  SEP: 'Société en participation',
  SICA: 'Société d\'intérêt collectif agricole',
  SNC: 'Société en nom collectif',
};

export const honorificOptions = [
  {
    text: 'M.',
    value: 'M.',
    key: 'm',
  },
  {
    text: 'Mme',
    value: 'Mme',
    key: 'mme',
  },
  {
    text: 'M. & Mme',
    value: 'M. & Mme',
    key: 'mmme',
  },
];

export const entities = [
  {
    section: Entities.pageCompany,
    children: [
      Entities.company,
      Entities.companySettings,
      Entities.documentNumbering,
      Entities.holiday,
      Entities.fiscalYear,
      Entities.role,
    ],
  },
  {
    section: Entities.pageEmployees,
    children: [
      Entities.employee,
      Entities.employeeData,
      Entities.identity,
      Entities.absence,
    ],
  },
  {
    section: Entities.pageForecast,
    children: [
      Entities.hourSynthesis,
      Entities.budget,
      Entities.forecastTable,
      Entities.selfFinancing,
      Entities.turnoverBalance,
      Entities.workingCapital,
    ],
  },
  {
    section: Entities.pageContacts,
    children: [
      Entities.supplier,
      Entities.customer,
    ],
  },
  {
    section: Entities.pageArticles,
    children: [
      Entities.product,
      Entities.service,
      Entities.ensemble,
      Entities.stock,
      Entities.stockBooking,
      Entities.stockMovement,
    ],
  },
  {
    section: Entities.pageBusiness,
    children: [
      Entities.quote,
      Entities.followUp,
      Entities.purchaseOrder,
      Entities.invoice,
      Entities.creditNote,
      Entities.documentModel,
      Entities.receipt,
      Entities.deliveryForm,
      Entities.workOrder,
      Entities.supplyOrder,
    ],
  },
  {
    section: Entities.pageSubscriptions,
    children: [
      Entities.subscription,
      Entities.scheduleInvoice,
    ],
  },
  {
    section: Entities.pageFinances,
    children: [
      Entities.payment,
      Entities.recoveryNotice,
      Entities.encasement,
      Entities.disbursement,
    ],
  },
  {
    section: Entities.pageExperts,
    children: [
      Entities.expert,
      Entities.office,
      Entities.officeSettings,
    ],
  },
  // Entities.pageAnalysis,
  // Entities.pageOffices,
];
