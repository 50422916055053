import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { reset as resetRetrieveSupplier, retrieve as retrieveSupplier } from 'actions/supplier/show';
import { find, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Routes from 'routes/admin/contacts/routes';
import RouteNotFound from 'routes/RouteNotFound';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import ShowSupplier from './show';
import UpdatePrices from './update-prices';
import EditSupplier from '../create';
import NotFound from '../../../404';

class ViewSupplier extends React.Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const { getSupplier, listSupplier, match } = this.props;

    if (match.params.id) {
      if (find(listSupplier['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getSupplier(`/suppliers/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });
      }
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { isValid } = this.state;
    const { retrievedSupplier, loadingRetrieveSupplier } = this.props;

    if (!isValid) {
      return <NotFound />;
    }

    if (loadingRetrieveSupplier || isEmpty(retrievedSupplier)) {
      return <ContainerGeneral status={loadingRetrieveSupplier} />;
    }

    return (
      <Switch>
        <CheckRoute
          exact
          path={Routes.showSupplier}
          component={ShowSupplier}
          entity={Entities.supplier}
          right={crudRights.read}
        />
        <CheckRoute
          exact
          path={Routes.editSupplier}
          component={EditSupplier}
          entity={Entities.supplier}
          right={crudRights.update}
        />
        <CheckRoute
          exact
          path={Routes.updateSupplierPrices}
          component={UpdatePrices}
          entity={Entities.supplier}
          right={crudRights.update}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSupplier: page => dispatch(retrieveSupplier(page)),
  reset: () => dispatch(resetRetrieveSupplier()),
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,

  retrievedSupplier: state.supplier.show.retrieved,
  loadingRetrieveSupplier: state.supplier.show.loading,

  listSupplier: state.supplier.list.data,

  updated: state.supplier.update.updated,
  created: state.supplier.create.created,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ViewSupplier);

export default withRouter(Main);
