import { Grid } from 'semantic-ui-react';
import React from 'react';

const CompanySettingBlock: React.FC<{label: string}> = ({ label }) => (
  <Grid.Column width={3}>
    <div className="company-setting-block">
      {label}
    </div>
  </Grid.Column>
);

export default CompanySettingBlock;
