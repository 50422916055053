import React from 'react';
import { ASC, DESC } from 'utils/constants';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import AlignedCell from 'layouts/table/AlignedCell';

const SortableHeader = ({ items, column, direction, span, handler }) => {
  const getNextDirection = (entry) => {
    let nextDirection = ASC;

    if (column === entry.column) {
      nextDirection = (direction === ASC) ? DESC : ASC;
    }

    handler(entry.column, nextDirection);
  };

  return (
    <HeaderRow>
      {items.map((entry, index) => (
        entry.label ? (
          <AlignedCell
            key={index}
            textAlign={entry.textAlign || 'left'}
            sorted={column === entry.column ? direction : null}
            onClick={() => getNextDirection(entry)}
            content={entry.label}
          />
        ) : <LeftCell key={index} />
      ))}
      {span > 0 && (<LeftCell colSpan={span} />)}
    </HeaderRow>
  );
};

export default SortableHeader;
