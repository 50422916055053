// eslint-disable-next-line import/prefer-default-export
export enum Forecast {
  home = '/forecast',
  hourSynthesis = '/forecast/hour-synthesis',
  budgets = '/forecast/budgets',
  table = '/forecast/table',
  breakEven = '/forecast/break-even',
  selfFinancing = '/forecast/self-financing',
  editSelfFinancing = '/forecast/self-financing/edit',
  workingCapital = '/forecast/working-capital',
  editWorkingCapital = '/forecast/working-capital/edit',
  turnoverList = '/forecast/turnover-balance',
  turnoverCreate = '/forecast/turnover-balance/create',
  turnoverShow = '/forecast/turnover-balance/:turnoverId',
  turnoverEdit = '/forecast/turnover-balance/:turnoverId/edit',
}
