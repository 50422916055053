import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { update, reset } from 'actions/office-settings/update';
import { Form, Header, Grid } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';

class CompanyInformation extends Component {
  state = {
    value: {
      legalName: '',
      commercialName: '',
      slogan: '',
      activity: '',
      shareCapital: '',
      naf: '',
      siret: '',
      vat: '',
      rcs: '',
    },
    legalNameError: false,
    commercialNameError: false,
    sloganError: false,
    activityError: false,
    shareCapitalError: false,
    nafError: false,
    siretError: false,
    vatError: false,
    rcsError: false,
  };

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedSetting)
      && nextProps.retrievedSetting['hydra:member'][0]['@id'] !== prevState.id
      && nextProps.retrievedSetting['hydra:member'][0].name === 'COMPANY_DETAILS'
    ) {
      return {
        id: nextProps.retrievedSetting['hydra:member'][0]['@id'],
        value: nextProps.retrievedSetting['hydra:member'][0].value,
      };
    }

    return null;
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: value,
        },
      }
    ));
  };

  handleOnSubmit = () => {
    const { value } = this.state;

    const { update, retrievedSetting } = this.props;

    const data = {
      value,
    };

    update(retrievedSetting['hydra:member'][0], data);
  };

  render() {
    const {
      id,
      legalNameError,
      commercialNameError,
      sloganError,
      activityError,
      shareCapitalError,
      nafError,
      siretError,
      vatError,
      rcsError,
      value: {
        legalName,
        commercialName,
        slogan,
        activity,
        shareCapital,
        naf,
        siret,
        vat,
        rcs,
      },
    } = this.state;

    const {
      retrieveLoading,
      selectedOffice,
      updated,
      updateLoading,
      office,
      match,
      t,
    } = this.props;

    if (office && updated && id) {
      return (
        <Redirect
          push
          to={{
            pathname: `/offices/${match.params.id}/settings/bank-accounts/edit`,
            office,
          }}
        />
      );
    }

    if (updated && !office && id) {
      return (
        <Redirect
          push
          to={`/offices/${match.params.id}/settings/company-details`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {office && `${t('step')} 3 / 6: `}
              {t('officesCompanyDetails')}
            </Header>
            {!office
              && (
                <EssorButton as={Link} to={`/offices/${selectedOffice.id}/settings/company-details`} type="chevron left" size="tiny" floated="right">
                  {t('buttonBack')}
                </EssorButton>
              )}
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formLegalName')}
                      name="legalName"
                      placeholder={t('formPHLegalName')}
                      value={legalName}
                      onChange={this.handleInputChange}
                      error={legalNameError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formCommercialName')}
                      name="commercialName"
                      placeholder={t('formPHCommercialName')}
                      value={commercialName}
                      onChange={this.handleInputChange}
                      error={commercialNameError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formSlogan')}
                      name="slogan"
                      placeholder={t('formPHSlogan')}
                      value={slogan}
                      onChange={this.handleInputChange}
                      error={sloganError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formActivity')}
                      name="activity"
                      placeholder={t('formPHActivity')}
                      value={activity}
                      onChange={this.handleInputChange}
                      error={activityError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formShareCapital')}
                      name="shareCapital"
                      placeholder={t('formPHShareCapital')}
                      value={shareCapital}
                      onChange={this.handleInputChange}
                      error={shareCapitalError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      maxLength="5"
                      label={t('formNaf')}
                      name="naf"
                      placeholder={t('formPHNaf')}
                      value={naf}
                      onChange={this.handleInputChange}
                      error={nafError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formSiret')}
                      name="siret"
                      placeholder={t('formPHSiret')}
                      value={siret}
                      onChange={this.handleInputChange}
                      error={siretError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formVat')}
                      name="vat"
                      placeholder={t('formPHVat')}
                      value={vat}
                      onChange={this.handleInputChange}
                      error={vatError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formRcs')}
                      name="rcs"
                      placeholder={t('formPHRcs')}
                      value={rcs}
                      onChange={this.handleInputChange}
                      error={rcsError}
                    />
                  </Form.Group>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="small" floated="right">
              {office ? t('buttonNext') : t('buttonSave')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  updateError: state.officeSettings.update.updateError,
  updateLoading: state.officeSettings.update.updateLoading,
  updated: state.officeSettings.update.updated,

  retrievedSetting: state.officeSettings.show.retrieved,
  selectedOffice: state.userCompanies.select.selectedOffice,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);

export default withTranslation()(withRouter(Main));
