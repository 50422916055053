import React, { FunctionComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import KeyValueTable from './KeyValueTable';
import RowWithTitle from './RowWithTitle';
import { ComplexWithFiscalYearDTO, KpiDTO } from '../../types/dto';

interface OwnProps {
  data: {
    labourCost: KpiDTO[];
    absence: KpiDTO[];
    soldHours: KpiDTO[];
    hoursLeftToSell: KpiDTO[];
    misc: ComplexWithFiscalYearDTO[];
  };
}

type Props = OwnProps;

const HumanResources: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <RowWithTitle title={t('dashboard_humanResources')} className="coloured">

      <Grid.Column width={3}>
        <KeyValueTable data={data.labourCost} title="Coût horaire main d'oeuvre" />
      </Grid.Column>
      <Grid.Column width={3}>
        <KeyValueTable data={data.soldHours} title="Heures vendues" />
      </Grid.Column>
      <Grid.Column width={3}>
        <KeyValueTable data={data.hoursLeftToSell} title="Heures à vendre" />
      </Grid.Column>
      <Grid.Column width={3}>
        <KeyValueTable data={data.absence} title="Top 3 Absences" />
      </Grid.Column>

      {/*
      <Grid.Column width={3}>
        <KeyValueTable data={data.labourCost} title="Coût horaire main d'oeuvre" />
        <KeyValueTable data={data.absence} title="Top 3 Absences" />
      </Grid.Column>

      <Grid.Column width={3}>
        <KeyValueTable data={data.soldHours} title="Heures vendues" />
        <KeyValueTable data={data.hoursLeftToSell} title="Heures à vendre" />
      </Grid.Column>

      <Grid.Column width={6}>
        <Table striped className="table-dashboard">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell className="format-number-fr">
                {t('dashboard_fiscalYear')}
              </Table.HeaderCell>
              <Table.HeaderCell className="format-number-fr">
                {t('dashboard_selectedPeriod')}
              </Table.HeaderCell>
              <Table.HeaderCell className="format-number-fr">
                {t('dashboard_growth')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.misc.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell>{item.label}</Table.Cell>
                <FormatCell>{item.fiscalYearAmount}</FormatCell>
                <FormatCell>{item.periodAmount}</FormatCell>
                <FormatCell color>{item.growth}</FormatCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
      */}
    </RowWithTitle>
  );
};

export default HumanResources;
