import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/company-settings/show';
import { Form, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import uploadDefaultImage from 'assets/images/uploadDefault.png';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import TwelveForm from 'layouts/TwelveForm';
import { Entities } from 'types/accessRights';

class ContactInformation extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=CONTACT_INFORMATION`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'CONTACT_INFORMATION'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesContactInformation')}
          to="/company/settings/contact-information/edit"
          entity={Entities.companySettings}
        />
        <TwelveForm loading={loading}>
          <Form.Group inline>
            <Form.Field>
              <label
                style={{
                  alignSelf: 'flex-start',
                }}
              >
                {t('formPicture')}
              </label>
              <div
                className="informative-field"
                style={{
                  width: '182px',
                }}
              >
                {value && value.picture
                  ? <Image src={value.picture} />
                  : <Image src={uploadDefaultImage} />
                }
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formStreetName')}</label>
              <h5 className="informative-field">
                {value && (value.streetName === '' ? '-' : value.streetName)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formAdditional')}</label>
              <h5 className="informative-field">
                {value && (value.additional === '' ? '-' : value.additional)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formZipCode')}</label>
              <h5 className="informative-field">
                {value && (value.zipCode === '' ? '-' : value.zipCode)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formCity')}</label>
              <h5 className="informative-field">
                {value && (value.city === '' ? '-' : value.city)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formCountry')}</label>
              <h5 className="informative-field">
                {value && (value.country === '' ? '-' : value.country)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formPhoneNumber')}</label>
              <h5 className="informative-field">
                {value && (value.phone === '' ? '-' : value.phone)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formFax')}</label>
              <h5 className="informative-field">
                {value && (value.fax === '' ? '-' : value.fax)}
              </h5>
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>{t('formWebsite')}</label>
              <h5 className="informative-field">
                {value && (value.website === '' ? '-' : value.website)}
              </h5>
            </Form.Field>
          </Form.Group>
        </TwelveForm>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(ContactInformation);

export default withTranslation()(withRouter(Main));
