import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset, retrieve } from 'actions/ensemble/show';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import Page404 from 'routes/admin/404';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import KeyValue from 'components/keyValue';
import Currency from 'components/currency';
import CurrencyCell from 'components/cell/CurrencyCell';
import FloatCell from 'components/cell/FloatCell';
import useMarginCalculation from 'hooks/useMarginCalculation';
import { Big } from 'utils/functions';
import TwelveForm from 'layouts/TwelveForm';
import GridWrapper from 'layouts/GridWrapper';
import { Entities } from 'types/accessRights';
import ViewCellButton from 'components/buttons/ViewCellButton';

const ShowEnsemble = ({ match }) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(true);
  const listEnsemble = useSelector(state => state.ensemble.list.data);
  const retrieved = useSelector(state => state.ensemble.show.retrieved);
  const loading = useSelector(state => state.ensemble.show.loading);
  const dispatch = useDispatch();
  const marginCalc = useMarginCalculation();

  useEffect(
    () => {
      if (find(listEnsemble['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        dispatch(retrieve(`/ensembles/${match.params.id}`));
      } else {
        setValid(false);
      }
      return () => {
        dispatch(reset());
      };
    },
    [],
  );


  if (!isValid) {
    return <Page404 />;
  }

  return (
    <ContainerGeneral>
      <ModifyHeader
        title={t('ensemblesShowTitle')}
        to={`/articles/ensembles/${match.params.id}/edit`}
        back="/articles/ensembles"
        entity={Entities.ensemble}
      />

      <TwelveForm loading={loading}>
        <KeyValue label={t('formLabel')}>
          {retrieved && retrieved.label}
        </KeyValue>

        <KeyValue label={t('formReference')}>
          {retrieved && (retrieved.reference === '' ? '-' : retrieved.reference)}
        </KeyValue>

        <KeyValue label={t('formSellingPrice')}>
          {retrieved && <Currency value={retrieved.sellingPrice} />}
        </KeyValue>
      </TwelveForm>
      <GridWrapper width={16}>
        <Table celled selectable className="margin-bot">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>{t('formReference')}</Table.HeaderCell>
              <Table.HeaderCell>{t('ensemblesTableName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('ensemblesTableType')}</Table.HeaderCell>
              <Table.HeaderCell>{t('formSellingPrice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('ensemblesTableQuantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('formTotalPrice')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {retrieved && map(retrieved.items, (item) => {
              if (item['@type'] === 'Product') {
                item.unitPrice = marginCalc.getBigPriceFromCost(
                  Big(item.purchaseUnitCost),
                  Big(item.marginRate),
                );
              }
              return (
                <Table.Row key={item['@id']}>
                  <Table.Cell>{item.reference}</Table.Cell>
                  <Table.Cell>{item.label}</Table.Cell>
                  <Table.Cell>{item['@type']}</Table.Cell>
                  <CurrencyCell value={item.unitPrice} />
                  <FloatCell value={item.quantity} />
                  <CurrencyCell value={item.unitPrice * item.quantity} />
                  <ViewCellButton to={`/articles${item['@id']}`} />
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </GridWrapper>
    </ContainerGeneral>
  );
};

export default withRouter(ShowEnsemble);
