import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listDeliveryForm, reset as resetListDeliveryForm } from 'actions/delivery/list';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import ListDeliveryForm from './list';
import ShowDeliveryForm from './show';
import NotFound from '../../404';

class DeliveryFrom extends Component {
  componentDidMount() {
    const { getDeliveryForms, selectedCompany, selectDocument } = this.props;

    selectDocument(null);
    getDeliveryForms(`/delivery_forms?company=${selectedCompany.id}&pagination=false`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { listDeliveryForm, loadingDeliveryForm, t } = this.props;

    if (loadingDeliveryForm || isEmpty(listDeliveryForm)) {
      return (
        <div className="section-container">
          <Segment
            basic
            className="section-loading"
          >
            <Dimmer active={loadingDeliveryForm} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
          </Segment>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/business/delivery_forms" component={ListDeliveryForm} />
          <Route exact path="/business/delivery_forms/:id/edit" component={ShowDeliveryForm} />
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDeliveryForms: page => dispatch(listDeliveryForm(page)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset() {
    dispatch(resetListDeliveryForm());
  },
});

const mapStateToProps = state => ({
  listDeliveryForm: state.delivery.list.data,
  loadingDeliveryForm: state.delivery.list.loading,
  errorDeliveryForm: state.delivery.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(DeliveryFrom);

export default withTranslation()(withRouter(Main));
