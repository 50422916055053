import React from 'react';
import PrintButton from 'components/buttons/PrintButton';
import EditButton from 'components/buttons/EditButton';
import { keyVal } from 'utils/functions';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { useSelector } from 'react-redux';

const ActionButtons = ({ isPending, handleAction }) => {
  const loading = useSelector(state => state.document.print.loading);

  return (
    <ButtonContainer>
      {isPending && (
        <EditButton
          loading={loading}
          disabled={loading}
          onClick={() => handleAction('edit')}
          floated="right"
          style={keyVal('marginLeft', '1em')}
        />
      )}
      <PrintButton
        loading={loading}
        disabled={loading}
        onClick={() => handleAction('print')}
        floated="right"
      />
    </ButtonContainer>
  );
};

export default ActionButtons;
