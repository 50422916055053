/* eslint-disable */
import React, { useState } from 'react';
import { Header, Icon, Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { floatFormat } from 'utils/formatter';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SectionLoader from 'components/sectionLoader';
import Big from 'big.js';
import SidebarListItem from 'layouts/sidebar/SidebarListItem';
import FloatInput from 'components/input/FloatInput';
import Float from 'components/float';
import Currency from 'components/currency';
import EssorButton from 'components/essorButton';
import { isService } from 'components/documents/articles';
import CurrencyCell from 'components/cell/CurrencyCell';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';
import RightCell from 'layouts/table/RightCell';
import Nbsp from 'components/Nbsp';

const LiveAnalysis = () => {
  const { t } = useTranslation();

  const [adaptedHours, setAdaptedHours] = useState('');

  const selectedFiscalYear = useSelector(state => state.userCompanies.select.selectedFiscalYear);

  const dataQuote = useSelector(state => state.quote.list.data);
  const dataInvoice = useSelector(state => state.invoice.list.data);
  const dataPurchaseOrder = useSelector(state => state.purchaseOrder.list.data);

  const labourCost = useSelector(state => state.document.liveDoc.labourCost);
  const showCalculation = useSelector(state => state.document.liveDoc.showCalculation);
  const totalBeforeTaxes = useSelector(state => state.document.liveDoc.totalBeforeTaxes) || 0;
  const netTotalBeforeTaxes = useSelector(state => state.document.liveDoc.netTotalBeforeTaxes) || 0;
  const plannedHoursDoc = useSelector(state => state.document.liveDoc.plannedHours);
  const selectedItems = useSelector(state => state.document.liveDoc.actionItemSelected);

  let document = false;

  let hoursInDocument = Big(0);
  let labourTurnover = Big(0);
  let labourMargin = Big(0);
  let mpCost = Big(0);
  let mpBilled = Big(0);
  let mpMargin = Big(0);
  let mdCost = Big(0);
  let mdBilled = Big(0);
  let mdMargin = Big(0);
  let stCost = Big(0);
  let stBilled = Big(0);
  let stMargin = Big(0);
  let plannedCharges = Big(0);
  let plannedTurnover = Big(0);
  let chargesCoefficient = Big(0);
  let documentCharges = Big(0);
  let documentMargin = Big(0);
  let result = Big(0);
  let totalLabourCost = Big(0);
  let actualHours = Big(adaptedHours || 0);

  let discountRatio = Big(1);

  if (!isEmpty(dataQuote) || !isEmpty(dataPurchaseOrder) || !isEmpty(dataInvoice)) {
    document = true;

    if (Big(totalBeforeTaxes).gt(0) && Big(netTotalBeforeTaxes).lt(totalBeforeTaxes)) {
      discountRatio = Big(netTotalBeforeTaxes).div(totalBeforeTaxes);
    }

    if (!isEmpty(selectedItems) && showCalculation && selectedFiscalYear
      && !isEmpty(selectedFiscalYear.forecast)) {
      selectedItems.forEach((item) => {
        const itemQuantity = Big(item.quantity || 0);
        const itemTotalPrice = Big(item.totalPrice || 0);
        switch (item.soldAs) {
          case 'h':
            if (isService(item)) {
              hoursInDocument = hoursInDocument.plus(itemQuantity);
              labourTurnover = labourTurnover.plus(itemTotalPrice);
              break;
            }
            break;
          case 'mp':
            mpBilled = itemTotalPrice.plus(mpBilled);
            mpCost = Big(item.unitCost).times(itemQuantity).plus(mpCost);
            break;
          case 'md':
            mdBilled = itemTotalPrice.plus(mdBilled);
            mdCost = Big(item.unitCost).times(itemQuantity).plus(mdCost);
            break;
          case 'hst':
            stBilled = itemTotalPrice.plus(stBilled);
            stCost = Big(item.unitCost).times(itemQuantity).plus(stCost);
            break;
          default:
            break;
        }
      });

      if (adaptedHours <= 0 || adaptedHours === '') {
        actualHours = Big(plannedHoursDoc || hoursInDocument);
      }

      if (!labourCost) {
        if (selectedFiscalYear && !isEmpty(selectedFiscalYear.forecast)) {
          totalLabourCost = actualHours
            .times(Big(selectedFiscalYear.forecast.planned.theoreticalHourlyCost || 0));
        } else {
          totalLabourCost = Big(0);
        }
      } else {
        totalLabourCost = Big(labourCost).times(actualHours);
      }

      plannedCharges = selectedFiscalYear && !isEmpty(selectedFiscalYear.forecast)
        ? Big(selectedFiscalYear.forecast.planned.totalCharges || 0)
        : Big(0);
      plannedTurnover = selectedFiscalYear && !isEmpty(selectedFiscalYear.forecast)
        ? Big(selectedFiscalYear.forecast.planned.turnover || 0)
        : Big(0);
      chargesCoefficient = plannedTurnover.gt(Big(0))
        ? Big(1).minus(plannedCharges.div(plannedTurnover))
        : Big(0);

      //apply ratio to every turnover types to compute margins right
      labourTurnover = discountRatio.times(labourTurnover);
      mpBilled = discountRatio.times(mpBilled);
      mdBilled = discountRatio.times(mdBilled);
      stBilled = discountRatio.times(stBilled);

      labourMargin = labourTurnover.minus(totalLabourCost);
      mpMargin = mpBilled.minus(mpCost);
      mdMargin = mdBilled.minus(mdCost);
      stMargin = stBilled.minus(stCost);

      const documentTurnover = Big(netTotalBeforeTaxes || 0);
      documentCharges = documentTurnover.times(chargesCoefficient);
      documentMargin = labourMargin.plus(mpMargin).plus(mdMargin).plus(stMargin);
      result = documentMargin.minus(documentCharges);
    }
  }

  return (
    <SectionLoader status={!document || !showCalculation}>
      <div className="sidebar-title m-b-10">{t('documentTitle')}</div>
      {!isEmpty(selectedFiscalYear) && !isEmpty(selectedFiscalYear.forecast)
        ? (
          <ul>
            <SidebarListItem
              label={t('documentSoldHours')}
              tooltip={t('documentSoldHoursTooltip')}
            >
              <Float value={hoursInDocument} />
            </SidebarListItem>

            <SidebarListItem
              label={t('documentPlannedHours')}
              tooltip={t('documentPlannedHoursTooltip')}
            >
              <Float value={plannedHoursDoc || hoursInDocument} />
            </SidebarListItem>

            <SidebarListItem
              label={t('documentAdaptedHours')}
              tooltip={t('documentAdaptedHoursTooltip')}
            >
              <FloatInput
                onChange={value => setAdaptedHours(value)}
                name="adaptedHours"
                placeholder="0"
                value={adaptedHours}
                style={{
                  width: '100px',
                  marginTop: '5px',
                  marginLeft: '50%',
                }}
              />
            </SidebarListItem>

            <SidebarListItem label={t('documentLabourTurnover')}>
              <Currency value={labourTurnover} />
            </SidebarListItem>

            <SidebarListItem label={t('documentLabourCost')}>
              <Currency value={totalLabourCost} />
            </SidebarListItem>

            <li className="menu-title">
              <Table celled structured compact>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      {`${t('documentPlannedHours')}: ${floatFormat(plannedHoursDoc || hoursInDocument)}`}
                    </Table.Cell>
                    <Table.Cell>
                      {`${t('documentBilled')}: ${floatFormat(labourTurnover, true)}`}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      {`${t('documentAdaptedHours')}: ${floatFormat(adaptedHours)}`}
                    </Table.Cell>
                    <Table.Cell>
                      {`${t('documentCost')}: ${floatFormat(totalLabourCost, true)}`}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </li>

            <li
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <label>{t('documentLabourMargin')}</label>
              <label><Currency value={labourMargin.toString()} /></label>
            </li>

            <li className="menu-title">
              <Table structured compact celled definition className="definition-table m-t-20">
                <HeaderRow>
                  <LeftCell><Nbsp /></LeftCell>
                  <RightCell>{t('documentCost')}</RightCell>
                  <RightCell>{t('documentBilled')}</RightCell>
                  <RightCell>{t('documentMargin')}</RightCell>
                </HeaderRow>
                <Table.Body>
                  <Table.Row>
                    <CenterCell title={t('documentRawMaterialsTitle')}>{t('documentRawMaterials')}</CenterCell>
                    <CurrencyCell value={mpCost.toFixed(2)} />
                    <CurrencyCell value={mpBilled.toFixed(2)} />
                    <CurrencyCell value={mpMargin.toFixed(2)} />
                  </Table.Row>
                  <Table.Row>
                    <CenterCell title={t('documentGoodsTitle')}>{t('documentGoods')}</CenterCell>
                    <CurrencyCell value={mdCost.toFixed(2)} />
                    <CurrencyCell value={mdBilled.toFixed(2)} />
                    <CurrencyCell value={mdMargin.toFixed(2)} />
                  </Table.Row>
                  <Table.Row>
                    <CenterCell title={t('documentSubContractingTitle')}>{t('documentSubContracting')}</CenterCell>
                    <CurrencyCell value={stCost.toFixed(2)} />
                    <CurrencyCell value={stBilled.toFixed(2)} />
                    <CurrencyCell value={stMargin.toFixed(2)} />
                  </Table.Row>
                </Table.Body>
              </Table>
            </li>

            <li className="menu-title">
                <span className="menu-item-info">
                  {result >= 0
                    ? (
                      <React.Fragment>
                        <Icon color="green" name="checkmark" size="large" />
                        <label
                          style={{
                            color: 'green',
                            fontSize: '15px',
                          }}
                        >
                          {`${t('documentProfit')} :  ${floatFormat(result, true)}`}
                        </label>
                      </React.Fragment>
                    )
                    : (
                      <React.Fragment>
                        <Icon color="red" name="x" size="large" />
                        <label
                          style={{
                            color: 'red',
                            fontSize: '15px',
                          }}
                        >
                          {`${t('documentLoss')} :  ${floatFormat(result, true)}`}
                        </label>
                      </React.Fragment>
                    )}
                </span>
            </li>
          </ul>
        )
        : (
          <div
            style={{
              padding: '40px 10px',
            }}
          >
            <Header as="h3">{t('documentWarningLackForecast')}</Header>
            <div
              style={{
                textAlign: 'center',
                paddingTop: '15px',
              }}
            >
              <EssorButton as={Link} to="/forecast/table" type="check" size="tiny">
                {t('buttonCreate')}
              </EssorButton>
            </div>
          </div>
        )
      }
    </SectionLoader>
  );
};

export default LiveAnalysis;
