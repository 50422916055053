import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, snakeCase } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listDocument, reset as resetListDocument } from 'actions/document/list';
import { Header, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { getDocumentStatus } from 'utils/documentStatus';
import TableLoader from 'components/TableLoader';
import FormattedDate from 'components/documents/formattedDate';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import BusinessIconCell from 'routes/admin/business/businessIconCell';
import UniqueId from 'components/documents/uniqueId';
import CurrencyCell from 'components/cell/CurrencyCell';
import { getHeaderItem, sortedDocuments } from 'components/documents/documentCommon';
import SortableHeader from 'components/sortableHeader';
import { DESC } from 'utils/constants';
import { getActualTotal } from 'components/documents/documents';
import TableEmptyRow from 'components/TableEmptyRow';

class BusinessHome extends Component {
  state = {
    documentData: {},
    column: 'creationDate',
    direction: DESC,
  };

  componentDidMount() {
    const { selectDocument, getDocuments, selectedCompany } = this.props;

    selectDocument(null);
    getDocuments(`/company/documents/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataDocument) && nextProps.dataDocument['hydra:member'] !== prevState.documentData) {
      return {
        documentData: nextProps.dataDocument['hydra:member'],
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleSort = (clickedColumn, nextDirection) => {
    this.setState({
      column: clickedColumn,
      direction: nextDirection,
    });
  };

  render() {
    const { documentData, column, direction } = this.state;

    const {
      loadingDocument,
      t,
    } = this.props;

    return (
      <div className="section-container">

        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('businessTitleHome')}</Header>
          </div>

          <TableLoader status={loadingDocument}>
            <Table sortable celled striped>
              <SortableHeader
                column={column}
                direction={direction}
                handler={this.handleSort}
                items={[
                  getHeaderItem('type', t('formType')),
                  getHeaderItem('uniqueID', t('documentNumber')),
                  getHeaderItem('creationDate', t('documentsHeaderCreationDate')),
                  getHeaderItem('customer', t('formCustomer')),
                  getHeaderItem('totalPrice', t('documentTotal'), 'right'),
                  getHeaderItem('status', t('documentStatus')),
                  {},
                ]}
              />

              <Table.Body>
                {sortedDocuments(documentData, column, direction).map((document, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {t(`documentNumbering_${snakeCase(document.title).toUpperCase()}`)}
                    </Table.Cell>
                    <Table.Cell><UniqueId document={document} /></Table.Cell>
                    <Table.Cell><FormattedDate date={document.creationDate} /></Table.Cell>
                    <Table.Cell>{document.companyName || document.customerName}</Table.Cell>
                    <CurrencyCell value={getActualTotal(document).toFixed(2)} />
                    <BusinessStatusCell document={document} handler={getDocumentStatus} />
                    <BusinessIconCell type="invoices" document={document} />
                  </Table.Row>
                ))}
                {documentData.length === 0 && (
                  <TableEmptyRow content={t('documentEmptyList')} span={7} />
                )}
              </Table.Body>
            </Table>
          </TableLoader>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: purchase => dispatch(selectDocument(purchase)),
  getDocuments: page => dispatch(listDocument(page)),
  reset: () => dispatch(resetListDocument()),
});

const mapStateToProps = state => ({
  dataDocument: state.document.list.data,
  loadingDocument: state.document.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(BusinessHome);

export default withTranslation()(withRouter(Main));
