import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { reset as resetUpdateFiscalYear, update as updateFiscalYear } from 'actions/fiscal-year/update';
import { reset as resetForecast, retrieve as retrieveForecast } from 'actions/forecast/show';
import { selectFiscalYear } from 'actions/user-companies/select';
import { withTranslation } from 'react-i18next';
import 'moment/locale/fr';
import ForecastTableRender from 'routes/admin/forecast/table/ForecastTableRender';
import { getCalculationClass } from 'utils/marginCalculation';
import Big from 'big.js';

moment.locale('fr');

class ForecastShow extends Component {
  state = {
    calculationMode: null,
    realised: {
      isGhost: true,
      thM: 0,
      globalTurnover: 0,
      hoursTotal: 0,
      hoursToSellAdapted: 0,
      labourTurnover: 0,
      totalCharges: 0,
      totalFixedCharges: 0,
      totalVariablesCharges: 0,
      acaMP: 0,
      marginRate: 0,
      valueMP: 0,
      CA_MP: 0,
      acaMD: 0,
      valueMD: 0,
      CA_MD: 0,
      valueST: 0,
      acaST: 0,
      newCustomersNumber: 0,
      mdTurnover: 0,
      mpTurnover: 0,
      stTurnover: 0,
    },
    planned: {
      happyBonus: 0,
      rateMP: 0,
      rateMD: 0,
      thM: 0,
      hoursTotal: 0,
      hoursToSellAdapted: 0,
      totalCharges: 0,
      totalFixedCharges: 0,
      totalVariablesCharges: 1,
      acaMP: 0,
      marginRate: 0,
      valueMP: 0,
      CA_MP: 0,
      acaMD: 0,
      valueMD: 0,
      CA_MD: 0,
      valueST: 0,
      acaST: 0,
      newCustomersNumber: 0,
    },
    percentage: {},

    hasDataChanged: false,
  };

  componentDidMount() {
    const { getForecast, selectedCompany, selectedFiscalYear } = this.props;
    getForecast(`/companies/${selectedCompany.id}/forecast_result/${selectedFiscalYear.id}`);
    window.addEventListener('resize', this.autoResize);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedForecast)
      && prevState.forecastId !== nextProps.retrievedForecast.fiscalYearId
    ) {
      return {
        calculationMode: nextProps.retrievedForecast.calculationMode,
        realised: nextProps.retrievedForecast.realised,
        planned: nextProps.retrievedForecast.planned,
        forecastId: nextProps.retrievedForecast.fiscalYearId,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      planned,
      forecastId,
    } = this.state;

    const {
      selectFiscalYear,
      updatedFiscalYear,
    } = this.props;

    if (prevState.forecastId !== forecastId) {
      this.calculateValues('valueMD', planned.valueMD);
      this.calculateValues('valueMP', planned.valueMP);
      this.calculateValues('valueST', planned.valueST);
    }

    if (!isEmpty(updatedFiscalYear) && updatedFiscalYear !== prevProps.updatedFiscalYear) {
      selectFiscalYear(updatedFiscalYear);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    window.removeEventListener('resize', this.autoResize);
    reset();
  }

  getPercentage = (planned, realised) => {
    const parsedPlanned = parseFloat(planned);
    const parsedRealised = parseFloat(realised);

    if (parsedRealised === 0 || Number.isNaN(parsedRealised) || Number.isNaN(parsedPlanned)) {
      return 0;
    }

    return 100 * (parsedPlanned - parsedRealised) / parsedRealised;
  };

  marginCalc = () => {
    const { selectedCompany } = this.props;
    return getCalculationClass(selectedCompany);
  };

  calculateTurnover = (aca, mode) => (
    this.marginCalc().getBigPriceFromCost(
      Big(parseFloat(aca) || 0),
      Big(parseFloat(mode) || 0),
    ).toFixed(2)
  );

  calculateMargin = (purchase, turnover) => (
    this.marginCalc().getBigMargin(Big(purchase || 0), Big(turnover || 0)).toFixed(2)
  );

  calculateValues = (key, value) => {
    const { calculationMode } = this.state;
    let result = 0;
    const valueFormat = parseFloat(value);
    switch (calculationMode) {
      case 'coef':
        result = (valueFormat !== '' && valueFormat !== 0 && valueFormat !== null)
          ? (((parseFloat(valueFormat) - 1) / parseFloat(valueFormat)) * 100).toFixed(2)
          : 0.00;
        break;
      case 'rate':
        result = (valueFormat !== '' && valueFormat !== 0 && valueFormat !== null)
          ? ((1) / (1 - (parseFloat(valueFormat) / 100))).toFixed(2)
          : 0.00;
        break;
      default:
        break;
    }

    this.setState(prevState => (
      {
        percentage: {
          ...prevState.percentage,
          [key]: result,
        },
      }
    ));
  };

  handleCleaveChange = (name, value) => {
    const { planned, realised } = this.state;

    if (!name) {
      return;
    }

    const newValue = value;

    const [object, key] = name.split('.');

    if (key) {
      this.setState(prevState => (
        {
          [object]: {
            ...prevState[object],
            [key]: newValue,
          },
        }
      ));

      switch (object) {
        case 'realised':
          realised[key] = newValue;
          break;
        case 'planned':
          planned[key] = newValue;
          break;
        default:
          break;
      }

      if (key === 'valueMD' || key === 'valueMP' || key === 'valueST') {
        if (object === 'planned') {
          this.calculateValues(key, newValue);
        }
      } else if (!isEmpty(realised[key]) && !isEmpty(planned[key])) {
        this.setState(prevState => (
          {
            percentage: {
              ...prevState.percentage,
              [key]: (parseFloat(realised[key]) !== 0)
                ? (((parseFloat(planned[key]) - parseFloat(realised[key])) * 100)
                  / parseFloat(realised[key])).toFixed(2)
                : 0.00,
            },
          }
        ));
      } else if (isEmpty(realised[key]) || isEmpty(planned[key])) {
        this.setState(prevState => (
          {
            percentage: {
              ...prevState.percentage,
              [key]: 0.00,
            },
          }
        ));
      }
    }
    this.setState({
      hasDataChanged: true,
    });
  };

  submit = () => {
    const { planned, realised, forecastId } = this.state;
    const { updateFiscalYear } = this.props;

    planned.turnover = Big(planned.thM * planned.hoursToSellAdapted)
      .plus(this.calculateTurnover(planned.acaST, planned.valueST))
      .plus(this.calculateTurnover(planned.acaMD, planned.valueMD))
      .plus(this.calculateTurnover(planned.acaMP, planned.valueMP));

    const data = {
      forecast: {
        planned,
        realised,
      },
    };

    const fiscalYear = {
      '@id': `/fiscal_years/${forecastId}`,
    };

    updateFiscalYear(fiscalYear, data);

    this.setState({
      hasDataChanged: false,
    });
  };

  autoResize = (e) => {
    this.setState({
      resize: e,
    });
  };

  render = () => {
    const { calculationMode, realised, planned, percentage, hasDataChanged } = this.state;

    const { loadingForecast } = this.props;

    const forecast = {};

    forecast.realisedChargesHours = realised.hoursToSellAdapted !== 0
      ? (realised.totalCharges / realised.hoursToSellAdapted)
      : 0;
    forecast.plannedChargesHours = planned.hoursToSellAdapted !== 0
      ? ((parseFloat(planned.totalCharges) + parseFloat(planned.happyBonus))
        / planned.hoursToSellAdapted)
      : 0;

    forecast.realisedHourlySale = realised.labourTurnover;
    forecast.plannedHourlySale = planned.thM * planned.hoursToSellAdapted;

    forecast.realisedTotalCharges = parseFloat(realised.totalVariablesCharges)
      + parseFloat(realised.totalFixedCharges);
    forecast.plannedTotalCharges = parseFloat(planned.totalVariablesCharges)
      + parseFloat(planned.totalFixedCharges);

    // RAW MATS
    realised.valueMP = this.calculateMargin(realised.acaMP, realised.mpTurnover);
    forecast.realisedRawMaterials = realised.mpTurnover;
    forecast.plannedRawMaterials = this.calculateTurnover(planned.acaMP, planned.valueMP);

    forecast.realisedMarginPurchase = forecast.realisedRawMaterials - realised.acaMP;
    forecast.plannedMarginPurchase = forecast.plannedRawMaterials - planned.acaMP;

    // MERCHANDISE
    realised.valueMD = this.calculateMargin(realised.acaMD, realised.mdTurnover);
    forecast.realisedMerchandiseTurnover = realised.mdTurnover;
    forecast.plannedMerchandiseTurnover = this.calculateTurnover(planned.acaMD, planned.valueMD);

    forecast.realisedGoodsPurchase = forecast.realisedMerchandiseTurnover - realised.acaMD;
    forecast.plannedGoodsPurchase = forecast.plannedMerchandiseTurnover - planned.acaMD;

    // OUTSOURCING
    forecast.realisedSaleOutsourcing = Big(realised.stTurnover).toFixed(2);
    this.calculateMargin(realised.acaST, realised.stTurnover);


    forecast.plannedSaleOutsourcing = this.calculateTurnover(planned.acaST, planned.valueST);

    forecast.realisedSubcontracting = forecast.realisedSaleOutsourcing - realised.acaST;
    forecast.plannedSubcontracting = forecast.plannedSaleOutsourcing - planned.acaST;

    forecast.realisedHourlySale -= forecast.realisedSaleOutsourcing;

    forecast.realisedTurnover = Big(forecast.realisedSaleOutsourcing)
      .plus(Big(forecast.realisedMerchandiseTurnover))
      .plus(Big(forecast.realisedRawMaterials))
      .plus(Big(forecast.realisedHourlySale));

    forecast.plannedTurnover = Big(forecast.plannedHourlySale)
      .plus(Big(forecast.plannedSaleOutsourcing))
      .plus(Big(forecast.plannedMerchandiseTurnover))
      .plus(Big(forecast.plannedRawMaterials))
      .toFixed(2);


    forecast.realisedOperatingProfit = forecast.realisedTurnover - forecast.realisedTotalCharges;
    forecast.plannedOperatingProfit = forecast.plannedTurnover - forecast.plannedTotalCharges;

    const row = (realisedValue, plannedValue) => ({
      realised: realisedValue,
      planned: plannedValue,
      percentage: this.getPercentage(plannedValue, realisedValue),
    });

    const forecastData = {
      hourlyRate: row(realised.thM, planned.thM),
      hourlyCost: row(forecast.realisedChargesHours, forecast.plannedChargesHours),
      hoursTotal: row(realised.hoursTotal, planned.hoursTotal),
      hoursToSell: row(realised.hoursToSellAdapted, planned.hoursToSellAdapted),
      labourTurnover: row(forecast.realisedHourlySale, forecast.plannedHourlySale),

      fixedCharges: row(realised.totalFixedCharges, planned.totalFixedCharges),
      variablesCharges: row(realised.totalVariablesCharges, planned.totalVariablesCharges),
      totalCharges: row(forecast.realisedTotalCharges, forecast.plannedTotalCharges),

      mp: {
        purchase: row(realised.acaMP, planned.acaMP),
        marginRate: row(realised.valueMP, planned.valueMP),
        turnover: row(forecast.realisedRawMaterials, forecast.plannedRawMaterials),
        marginValue: row(forecast.realisedMarginPurchase, forecast.plannedMarginPurchase),
      },
      md: {
        purchase: row(realised.acaMD, planned.acaMD),
        marginRate: row(realised.valueMD, planned.valueMD),
        turnover: row(forecast.realisedMerchandiseTurnover, forecast.plannedMerchandiseTurnover),
        marginValue: row(forecast.realisedGoodsPurchase, forecast.plannedGoodsPurchase),
      },
      st: {
        purchase: row(realised.acaST, planned.acaST),
        marginRate: row(realised.valueST, planned.valueST),
        turnover: row(forecast.realisedSaleOutsourcing, forecast.plannedSaleOutsourcing),
        marginValue: row(forecast.realisedSubcontracting, forecast.plannedSubcontracting),
      },

      turnover: row(forecast.realisedTurnover, forecast.plannedTurnover),
      operatingProfit: row(forecast.realisedOperatingProfit, forecast.plannedOperatingProfit),
      newCustomersNumber: row(realised.newCustomersNumber, planned.newCustomersNumber),
    };

    return (
      <ForecastTableRender
        data={forecastData}
        forecast={forecast}
        submit={this.submit}
        hasDataChanged={hasDataChanged}
        handleCleaveChange={this.handleCleaveChange}
        realised={realised}
        planned={planned}
        calculationMode={calculationMode}
        percentage={percentage}
        loading={loadingForecast}
      />
    );
  };
}

const mapDispatchToProps = dispatch => ({
  getForecast: page => dispatch(retrieveForecast(page)),
  updateFiscalYear: (item, data) => dispatch(updateFiscalYear(item, data)),
  selectFiscalYear: fiscalYear => dispatch(selectFiscalYear(fiscalYear)),
  reset: () => {
    dispatch(resetForecast());
    dispatch(resetUpdateFiscalYear());
  },
});

const mapStateToProps = state => ({
  retrievedForecast: state.forecast.show.retrieved,
  loadingForecast: state.forecast.show.loading,

  updatedFiscalYear: state.fiscalYear.update.updated,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ForecastShow);

export default withTranslation()(withRouter(Main));
