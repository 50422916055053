import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isNotDraft } from 'utils/documentStatus';
import InlineField from 'layouts/InlineField';
import { useTranslation } from 'react-i18next';
import { getCurrency } from 'utils/formatter';
import Big from 'big.js';
import { showPrices } from './documentOptions';
import { RECEIPT } from '../../utils/documentType';

const DocumentRelation = ({ label, document }) => {
  const { t } = useTranslation();

  const getLink = () => {
    if (document['@type'] === RECEIPT) {
      return `/business${document.invoice}/receipt`;
    }

    return `/business${document['@id']}/edit`;
  };

  if (document && document.uniqueID) {
    let docTitle = `${label}`;

    if (document.content && document.content.total.dueAmount && showPrices(document['@type'])) {
      const amount = Big(document.content.total.dueAmount);
      docTitle = `${docTitle} - ${amount.toFixed(2)}${getCurrency()}`;
    }

    if (isNotDraft(document)) {
      docTitle = `${docTitle} - ${document.uniqueID}`;
    } else {
      docTitle = `${docTitle} - ${t('documentStatusDraft')}`;
    }

    return (
      <InlineField>
        <Button
          as={Link}
          to={getLink()}
          className="table-button"
          data-tip="view"
          label={docTitle}
        />
      </InlineField>
    );
  }

  return <React.Fragment />;
};

export default DocumentRelation;
