import React from 'react';
import { useTranslation } from 'react-i18next';
import ContainerGeneral from '../layouts/ContainerGeneral';

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <ContainerGeneral>
      <div className="not-found">
        <h5>{`⚠ ${t('comingSoonTitle')} ⚠`}</h5>
        <div>{t('comingSoonBody')}</div>
        <div>{t('comingSoonSupport')}</div>
        <div>
          <div>{t('comingSoonTry')}</div>
        </div>
        <ul>
          <li>
            <div onClick={() => { window.history.back(); }}>{t('notFoundBack')}</div>
          </li>
          <li>
            <a href="/">{t('notFoundHome')}</a>
          </li>
        </ul>
      </div>
    </ContainerGeneral>
  );
};

export default ComingSoon;
