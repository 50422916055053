import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import DetailTooltip from 'components/icons/DetailTooltip';
import { Document } from 'types/document';
import { documentDetails } from './documentCommon';

interface OwnProps {
  document: Document;
}

type Props = OwnProps;

const DocumentLink: FunctionComponent<Props> = ({ document }) => (
  <Link to={`/business${document['@id']}/edit`} className="table-link">
    {document.uniqueID}
    <DetailTooltip tip={documentDetails(document)} />
  </Link>
);

export default DocumentLink;
