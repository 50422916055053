import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { list, reset as resetStock } from 'actions/stock/list';
import { Table } from 'semantic-ui-react';
import { CreateStockProduct } from 'components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import TwelveForm from 'layouts/TwelveForm';
import { crudRights, Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import TableLoader from 'components/TableLoader';
import FloatCell from 'components/cell/FloatCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import ViewCellButton from 'components/buttons/ViewCellButton';
import KeyValue from 'components/keyValue';
import Currency from 'components/currency';
import FormPicture from 'layouts/FormPicture';
import CheckRights from 'components/access/CheckRights';
import EditCellButton from 'components/buttons/EditCellButton';
import { getCalculationClass, getMarginName } from 'utils/marginCalculation';
import Big from 'big.js';
import Margin from 'layouts/Margin';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';
import HeaderRow from 'layouts/table/HeaderRow';
import TwelveGrid from 'layouts/TwelveGrid';

class ShowProduct extends Component {
  state = {
    unitPrice: Big(0),
    stockData: [],
    modalOpen: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { getStocks, match, location } = this.props;

    if (location.createStock) {
      this.openModal();
    }

    this.calculatePurchaseUnitCost();
    getStocks(`/stocks?product=/products/${match.params.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataStock) && (nextProps.dataStock['hydra:member'] !== prevState.stockData)) {
      return {
        stockData: nextProps.dataStock['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;

    if (!isEmpty(product) && product !== prevProps.product) {
      this.calculatePurchaseUnitCost();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  calculatePurchaseUnitCost = () => {
    const { selectedCompany, product: { purchaseUnitCost, marginRate } } = this.props;

    const calc = getCalculationClass(selectedCompany);

    this.setState({
      unitPrice: calc.getBigPriceFromCost(Big(purchaseUnitCost || 0), Big(marginRate || 0)),
    });
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const {
      unitPrice,
      stockData,
      modalOpen,
      hasDataChanged,
    } = this.state;

    const {
      product,
      loading,
      loadingStock,
      selectedCompany,
      match,
      location,
      t,
    } = this.props;

    return (
      <ContainerGeneral status={!product} prompt={hasDataChanged}>
        <ModifyHeader
          title={t('productsShowTitle')}
          to={`/articles/products/${match.params.id}/edit`}
          back={!(location.state) ? '/articles/products' : '/articles/stocks/situation'}
          entity={Entities.product}
        />

        <TwelveForm loading={loading}>
          {product.picture && <FormPicture url={product.picture.contentUrl} />}

          <KeyValue label={t('formLabel')}>
            {product.label}
          </KeyValue>

          <KeyValue label={t('formReference')}>
            {(product.reference === '' ? '-' : product.reference)}
          </KeyValue>

          <KeyValue label={product.stockManagement ? t('formPurchaseAverageCost') : t('formPurchaseUnitCost')}>
            <Currency value={product.purchaseUnitCost} />
          </KeyValue>

          <KeyValue label={getMarginName(selectedCompany)}>
            <Margin value={product.marginRate} />
          </KeyValue>

          <KeyValue label={t('formSellingPrice')}>
            <Currency value={unitPrice} />
          </KeyValue>

          <KeyValue label={t('formUnit')}>
            {product.unit}
          </KeyValue>

          {!product.stockManagement && (
            <React.Fragment>
              <KeyValue label={t('formSupplier')}>
                {product.supplier ? product.supplier.supplierName : '-'}
              </KeyValue>

              <KeyValue label={t('formSupplyTime')}>
                {product.supplyTime !== '' ? product.supplyTime : '-'}
              </KeyValue>
            </React.Fragment>
          )}
        </TwelveForm>

        {product.stockManagement && (
          <CheckRights entity={Entities.stock} right={crudRights.read}>
            <TwelveGrid>
              <AddHeader
                title={t('stockHomeTitle')}
                onClick={this.openModal}
                entity={Entities.stock}
              />

              <CreateStockProduct
                productCreate={modalOpen}
                closeModal={() => this.closeModal()}
                productID={product['@id']}
              />

              <TableLoader status={loadingStock} table celled structured className="margin-bot">
                <HeaderRow>
                  <LeftCell content="Fournisseur" />
                  <RightCell>{t('formQuantity')}</RightCell>
                  <RightCell>{t('formPrice')}</RightCell>
                  <LeftCell colSpan={2} />
                </HeaderRow>

                <Table.Body>
                  {stockData && stockData.map((stock, index) => (
                    <Table.Row key={index}>
                      <LeftCell content={stock.supplier.supplierName} />
                      <FloatCell value={stock.quantity} />
                      <CurrencyCell value={stock.price} />
                      <ViewCellButton to={`/articles/products/${match.params.id}/stock/${stock.id}`} />
                      <CheckRights entity={Entities.stock} right={crudRights.update}>
                        <EditCellButton to={`/articles/products/${match.params.id}/stock/${stock.id}/edit`} />
                      </CheckRights>
                    </Table.Row>
                  ))}
                </Table.Body>
              </TableLoader>
            </TwelveGrid>
          </CheckRights>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStocks: page => dispatch(list(page)),
  reset: () => {
    dispatch(resetStock());
  },
});

const mapStateToProps = state => ({
  product: state.product.show.retrieved,
  loading: state.product.show.loading,
  error: state.product.show.error,

  dataStock: state.stock.list.data,
  loadingStock: state.stock.list.loading,
  errorStock: state.stock.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowProduct);

export default withTranslation()(withRouter(Main));
