import React, { FC, ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
}

type Props = OwnProps;

const Info: FC<Props> = ({ children }) => <h5 className="informative-field">{children}</h5>;

export default Info;
