import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { entities } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TableLoader from 'components/TableLoader';
import HeaderRow from 'layouts/table/HeaderRow';
import CenterCell from 'layouts/table/CenterCell';
import LeftCell from 'layouts/table/LeftCell';
import ChildrenRights from 'routes/admin/company/settings/roles/show/ChildrenRights';
import { useParams } from 'react-router-dom';

const ShowProfileRole = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { identity: { rights } } = useSelector(s => s.userCompanies.select.selectedEmployee);
  const loading = useSelector(state => state.employee.show.loading);

  const showCheck = (rights, right) => {
    if (!rights) {
      return;
    }

    if (rights.includes(right)) {
      return (
        <Icon color="green" name="checkmark" size="large" />
      );
    }
  };

  return (
    <ContainerGeneral>
      <ModifyHeader
        title={t('userRights')}
        to={`/employees/${id}/roles/edit`}
        entity={Entities.identity}
      />

      <TableLoader status={loading} table celled definition tableName="definition-table">
        <HeaderRow>
          <Table.HeaderCell />
          <CenterCell content={t('createTitle')} />
          <CenterCell content={t('readTitle')} />
          <CenterCell content={t('updateTitle')} />
          <CenterCell content={t('deleteTitle')} />
        </HeaderRow>
        <Table.Body>
          {!isEmpty(rights) && entities.map((entity, i) => (
            <React.Fragment>
              {!isEmpty(rights[entity.section]) && (
                <React.Fragment>
                  <Table.Row key={i}>
                    <LeftCell content={t(`identityRights${entity.section}`)} />
                    <CenterCell colSpan={4} />
                  </Table.Row>
                  <ChildrenRights check={showCheck} entity={entity} rights={rights} />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Table.Body>
      </TableLoader>
    </ContainerGeneral>
  );
};

export default ShowProfileRole;
