import React, { ChangeEvent, FC, ReactElement } from 'react';
import { Message, Modal, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ProductShell } from 'utils/documentModalCommon';
import { getOtherStatus } from 'utils/documentStatus';
import { State } from 'types/store';
import { AccessoryDocument, Document } from 'types/document';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import Routes from 'routes/admin/business/routes';
import { Entities } from 'types/accessRights';
import { cellDefinition, columnDefinition, handlerDefinition } from 'utils/tables';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { Entity } from 'types/entity';
import { deDupe } from 'utils/functions';
import CreateButton from './buttons/CreateButton';
import CloseButton from './buttons/CloseButton';
import TableLoader from './TableLoader';
import AddButton from './buttons/AddButton';
import BackButton from './buttons/BackButton';
import ProductSelectionItem from './ProductSelectionItem';
import TableEmptyRow from './TableEmptyRow';
import { uniqueId } from './documents/uniqueId';
import { customerDisplay } from './documents/documentCommon';
import AdvancedList from './advancedList/AdvancedList';

interface OwnProps {
  addProducts: boolean;
  data: AccessoryDocument[];
  dataTable: ProductShell[];
  errorSelectedProducts: boolean;
  isOpen: boolean;
  loadingCreate: boolean;
  loadingList: boolean;
  message: string;
  path: string;
  selectedProducts: ProductShell[];
  title: string;

  closeSelf: () => void;
  handleAddProducts: () => void;
  handleInputChange: (e: ChangeEvent, index: number, value: string) => void;
  handleCheckBoxChange: (value: ProductShell) => void;
  handleSetCommentProduct: (editor: string, id: number) => void;
  handleSubmit: () => void;
}

type Props = OwnProps;

const ProductSelectionModal: FC<Props> = (props: Props) => {
  const {
    addProducts,
    data,
    dataTable,
    errorSelectedProducts,
    isOpen,
    loadingCreate,
    loadingList,
    message,
    path,
    selectedProducts,
    title,

    closeSelf,
    handleAddProducts,
    handleInputChange,
    handleCheckBoxChange,
    handleSetCommentProduct,
    handleSubmit,
  } = props;

  const { t } = useTranslation();

  const selectedDocument: Document = useSelector(
    (state: State) => state.userCompanies.select.selectedDocument,
  );

  const isWorkOrder = path === Routes.listWorkOrders;

  const addButtonDisabled = loadingList || isEmpty(dataTable);

  const statusCell = (document: Entity): ReactElement => (
    <BusinessStatusCell document={document} handler={getOtherStatus} />
  );

  return (
    <Modal open={isOpen} className="full-content">
      <Modal.Header>{`${t(title)} - ${selectedDocument.uniqueID}`}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <div className="clearfix m-r-14">
            {addProducts
              ? <BackButton onClick={handleAddProducts} floated="right" />
              : (
                <AddButton
                  entity={isWorkOrder ? Entities.workOrder : Entities.deliveryForm}
                  onClick={handleAddProducts}
                  disabled={!isWorkOrder && addButtonDisabled}
                  floated="right"
                />
              )
            }
          </div>

          {isEmpty(dataTable) && !loadingList && (
            <Message positive className="m-14 m-b-0">{t(message)}</Message>
          )}

          {addProducts
            ? (
              <TableLoader status={loadingCreate} table celled structured>
                <HeaderRow>
                  <LeftCell content={t('formReference')} />
                  <LeftCell content={t('formType')} />
                  <LeftCell content={t('formQuantity')} />
                  <LeftCell />
                </HeaderRow>
                <Table.Body>
                  {!isEmpty(dataTable) && dataTable.map((value: ProductShell, i: number) => (
                    <ProductSelectionItem
                      key={i}
                      value={value}
                      selectedProducts={selectedProducts}
                      setQuantity={handleInputChange}
                      selectProduct={handleCheckBoxChange}
                      setComment={handleSetCommentProduct}
                    />
                  ))}
                  {isEmpty(dataTable) && (
                    <TableEmptyRow content={t('productSelectionEmptyList')} span={4} />
                  )}
                </Table.Body>
              </TableLoader>
            )
            : (
              <div className="clearfix m-t-10">
                <AdvancedList
                  columns={[
                    handlerDefinition('documentNumber', 'uniqueID', uniqueId),
                    handlerDefinition('formCustomer', 'customer', customerDisplay),
                    columnDefinition('documentsHeaderCreationDate', 'creationDate', 'date'),
                    cellDefinition('documentStatus', 'status', statusCell, '', getOtherStatus),
                  ]}
                  loading={loadingList}
                  data={deDupe(data || [])}
                  entity={Entities.workOrder}
                  sortBy="creationDate"
                  onBusiness
                />
              </div>
            )
          }
          <Message negative hidden={!errorSelectedProducts}>
            {t('deliveryErrorNotSelectedProduct')}
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div>
          {addProducts && <CreateButton onClick={handleSubmit} />}
          <CloseButton onClick={closeSelf} />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default ProductSelectionModal;
