import { find, isEmpty, sortBy } from 'lodash';
import { isNotDraft } from 'utils/documentStatus';
import { DESC } from 'utils/constants';
import i18n from 'i18n';
import Big from 'big.js';

export function handleNextPropLists(nextProps, prevState) {
  if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
    return {
      customerList: nextProps.listCustomer['hydra:member'],
    };
  }

  if (!isEmpty(nextProps.listEmployee) && nextProps.listEmployee['hydra:member'] !== prevState.employeeList) {
    return {
      employeeList: nextProps.listEmployee['hydra:member'],
    };
  }

  if (!isEmpty(nextProps.listModel) && nextProps.listModel['hydra:member'] !== prevState.modelList) {
    return {
      modelList: nextProps.listModel['hydra:member'],
    };
  }

  if (!isEmpty(nextProps.listCompanySettings) && nextProps.listCompanySettings['@id'] !== prevState.settingsId) {
    const banks = find(nextProps.listCompanySettings['hydra:member'], {
      name: 'BANK_ACCOUNTS',
    });

    return {
      settingsId: nextProps.listCompanySettings['@id'],
      banks,
    };
  }

  if (!isEmpty(nextProps.createdFollowUp)) {
    return {
      followUpComment: '',
      followUpDate: null,
      followUpType: null,
    };
  }

  return null;
}

export function updatePrint(urlDocument, prevUrl, resetPrint, retrievedDocument) {
  if (!isEmpty(urlDocument) && urlDocument !== prevUrl) {
    const link = document.createElement('a');
    const documentName = isNotDraft(retrievedDocument) ? retrievedDocument.uniqueID : 'Document';
    link.href = urlDocument;
    link.setAttribute('download', `${documentName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    resetPrint();
  }
}


const fullName = customer => (
  customer.details.honorific
    ? `${customer.details.honorific} ${customer.contactName}`
    : customer.contactName
);

export const customerDisplay = document => (
  document.customer
    ? document.customer.companyName || fullName(document.customer)
    : document.companyName || document.contactName
);

export const invoiceOrigin = document => (
  document.purchaseOrder ? document.purchaseOrder : document.quote
);

export const documentOrigin = document => (
  document.purchaseOrder ? document.purchaseOrder : document.invoice
);

export const documentDetails = document => (
  `${customerDisplay(document)} - ${document.content.subject}`
);

export function sortedDocuments(stateData, column, direction) {
  let sort = column;
  if (column === 'customer') {
    sort = document => customerDisplay(document);
  } else if (column === 'origin') {
    sort = document => documentOrigin(document).uniqueID;
  }
  const data = sortBy(stateData, [sort]);
  return (direction === DESC ? data.reverse() : data);
}

export function getHeaderItem(column, label, textAlign = 'left', type) {
  const rightAlignType = ['number', 'currency', 'percentage'];
  return {
    column,
    label,
    textAlign: rightAlignType.indexOf(type) !== -1 ? 'right' : textAlign,
  };
}

export function getQuoteID(purchaseOrder) {
  return purchaseOrder.quote ? purchaseOrder.quote['@id'] : '';
}

export function estimate(document) {
  let choice;
  switch (document.estimation) {
    case 1:
      choice = i18n.t('quoteAgreement');
      break;
    case 2:
      choice = i18n.t('quoteShortList');
      break;
    case 3:
      choice = i18n.t('quoteUndecided');
      break;
    case 4:
      choice = i18n.t('quoteUnlikely');
      break;
    default:
      choice = '-';
  }

  return choice;
}

export function getPaymentData(invoice) {
  let lastPayment = null;
  let totalPaid = Big(0);
  const actualTotal = Big(invoice.total);

  invoice.payment.forEach((payment) => {
    lastPayment = (!lastPayment || payment.date > lastPayment) ? payment.date : lastPayment;
    totalPaid = Big(payment.amount).plus(Big(payment.vat)).plus(totalPaid);
  });

  const diff = actualTotal.minus(totalPaid).times(-1);

  return {
    actualTotal,
    diff,
    totalPaid,
    lastPayment,
  };
}
