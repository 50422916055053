import React, { Component } from 'react';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { retrieve } from 'actions/company-settings/update';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, find, findIndex } from 'lodash';
import { success as setSupplierOrderList } from 'actions/supplier-order/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { labourCost, plannedHours } from 'actions/document/liveDocument';
import { create as createSupplierOrder, success as successSupplierOrder, loading as loadingSupplierOrder, error as errorSupplierOrder } from 'actions/supplier-order/create';
import { change as changeSupplierOrder, reset as resetChangeSupplierOrder } from 'actions/supplier-order/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { retrieve as retrieveSupplierOrder, update as updateSupplierOrder, reset as resetUpdateSupplierOrder, retrieveSuccess as updateSupplierSuccess } from 'actions/supplier-order/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { selectDocument } from 'actions/user-companies/select';
import { Form, Grid, Header, Dropdown, Segment, Dimmer, Loader, Message } from 'semantic-ui-react';
import { EssorButton, Document, DocumentTimer } from 'components';
import { STATUS_PENDING } from 'utils/constants';
import { SUPPLIER_ORDER } from 'utils/documentType';
import NotFound from '../../../404';

import 'moment/locale/fr';

moment.locale('fr');

class EditSupplierOrder extends Component {
  state = {
    isValid: true,

    status: null,
    supplierOrderId: null,
    documentData: {},

    customerList: null,
    selectedCustomer: null,

    isCreate: false,
    timeSpent: 0,
    loadedSupplier: false,
    messageError: false,
    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getSupplierOrder,
      getSupplierList,
      selectedCompany,
      getArticles,
      listSupplierOrder,
      match,
    } = this.props;

    if (match.params.id) {
      if (find(listSupplierOrder['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getSupplierOrder(`/supplier_orders/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });

        return;
      }
    }

    if (match.path === '/business/supplier_orders/create') {
      this.setState({
        isCreate: true,
      });
    }

    getSupplierList(`/suppliers?company=${selectedCompany.id}&pagination=false`);

    getArticles(`/products?company=${selectedCompany.id}&pagination=false`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedSupplierOrder)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedSupplierOrder.id !== prevState.supplierOrderId
        || nextProps.retrievedSupplierOrder.status !== prevState.status)) {
      const documentData = {
        documentId: nextProps.retrievedSupplierOrder.status >= STATUS_PENDING
          ? nextProps.retrievedSupplierOrder.uniqueID
          : '-',
        content: nextProps.retrievedSupplierOrder.content,
        reference: nextProps.retrievedSupplierOrder.reference,
        creationDate: moment(nextProps.retrievedSupplierOrder.creationDate),
        note: nextProps.retrievedSupplierOrder.comment,
      };

      return {
        documentData,
        supplierOrderId: nextProps.retrievedSupplierOrder.id,
        status: nextProps.retrievedSupplierOrder.status,
        selectedCustomer: nextProps.retrievedSupplierOrder.supplier['@id'],
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedSupplierOrder.timeSpent,
        loadedSupplier: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedSupplierOrder,

      createdSupplierOrder,
      updatedSupplierOrder,
      changedSupplierOrder,
      listSupplierOrder,

      setSupplierOrder,
      changeSupplierOrder,
      selectDocument,
      setSupplierOrderList,
      history,

      urlDocument,
      resetPrint,
    } = this.props;

    const { submitAction, status } = this.state;

    if (!isEmpty(retrievedSupplierOrder)
      && retrievedSupplierOrder !== prevProps.retrievedSupplierOrder) {
      selectDocument(retrievedSupplierOrder);
    }

    if (isEmpty(retrievedSupplierOrder)
      && retrievedSupplierOrder !== prevProps.retrievedSupplierOrder) {
      selectDocument(null);
    }

    if (!isEmpty(changedSupplierOrder)
      && changedSupplierOrder['@id'] === selectedDocument['@id']
      && changedSupplierOrder.status >= STATUS_PENDING) {
      selectDocument(changedSupplierOrder);
    }

    if (!isEmpty(createdSupplierOrder) && createdSupplierOrder !== prevProps.createdSupplierOrder) {
      listSupplierOrder['hydra:member'].push(createdSupplierOrder);
      listSupplierOrder['hydra:totalItems'] += 1;
      setSupplierOrderList(listSupplierOrder);

      switch (submitAction) {
        case 'save':
          history.push('/business/supplier_orders');
          break;
        case 'edit':
          history.push(`/business/supplier_orders/${createdSupplierOrder.id}/edit`);
          break;
        case 'validate':
          history.push(`/business/supplier_orders/${createdSupplierOrder.id}/edit`);
          changeSupplierOrder(`/supplier_orders/${createdSupplierOrder.id}/to_pending`, 'PUT');
          break;
        default: break;
      }
      this.clearErrors();
    }

    if (!isEmpty(updatedSupplierOrder) && updatedSupplierOrder !== prevProps.updatedSupplierOrder) {
      const index = findIndex(listSupplierOrder['hydra:member'], {
        id: updatedSupplierOrder.id,
      });

      listSupplierOrder['hydra:member'][index] = updatedSupplierOrder;
      setSupplierOrderList(listSupplierOrder);

      switch (submitAction) {
        case 'save':
          history.push('/business/supplier_orders');
          break;
        case 'validate':
          changeSupplierOrder(`/supplier_orders/${updatedSupplierOrder.id}/to_pending`, 'PUT');
          break;
        default: break;
      }
      this.clearErrors();
    }

    if (!isEmpty(changedSupplierOrder)
      && changedSupplierOrder !== prevProps.changedSupplierOrder
      && changedSupplierOrder['@id'] === selectedDocument['@id']) {
      const index = findIndex(listSupplierOrder['hydra:member'], {
        id: changedSupplierOrder.id,
      });

      setSupplierOrder(changedSupplierOrder);
      listSupplierOrder['hydra:member'][index] = changedSupplierOrder;
      setSupplierOrderList(listSupplierOrder);
    }

    if (!isEmpty(urlDocument) && urlDocument !== prevProps.urlDocument) {
      const link = document.createElement('a');
      const documentName = status >= STATUS_PENDING
        ? retrievedSupplierOrder.uniqueID
        : 'Document';
      link.href = urlDocument;
      link.setAttribute('download', `${documentName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      resetPrint();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  clearErrors = () => {
    this.setState({
      messageError: [],
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  onSubmit = (data, msgError) => {
    this.setState({
      validDocument: false,
    });

    const { selectedCustomer } = this.state;

    const {
      postSupplierOrder,
      formTime,
      updateSupplierOrder,
      retrievedSupplierOrder,
      selectedCompany,
    } = this.props;

    if (!isEmpty(msgError)) {
      this.setState({
        messageError: msgError,
      });
      return;
    }

    data.supplier = selectedCustomer;
    data.company = selectedCompany['@id'];
    data.timeSpent = formTime;

    retrievedSupplierOrder
      ? updateSupplierOrder(retrievedSupplierOrder, data)
      : postSupplierOrder(data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  handlePrintDocument = () => {
    const { printDocument, selectedDocument } = this.props;
    printDocument(`/supplier_orders/${selectedDocument.id}/print`);
  };

  render() {
    const {
      isValid,
      status,
      documentData,

      customerList,
      selectedCustomer,

      validDocument,

      isCreate,
      timeSpent,
      loadedSupplier,
      hasDataChanged,
      messageError,
      plannedHoursError,
    } = this.state;

    const {
      loadingPrintDocument,
      loadingListCustomer,
      loadingRetrieveSupplierOrder,
      loadingCreateSupplierOrder,
      loadingUpdateSupplierOrder,
      loadingChangeSupplierOrder,
      match,
      t,
    } = this.props;

    let customers = [];
    let customerObject = null;

    if (customerList && customerList.length > 0) {
      customers = customerList.map((customer, index) => ({
        key: index,
        text: customer.supplierName,
        value: customer['@id'],
      }));
    }

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (loadingChangeSupplierOrder) {
      let label = '';

      if (loadingChangeSupplierOrder.includes('pending')) {
        label = 'Pending';
      } else if (loadingChangeSupplierOrder.includes('model')) {
        label = 'Model';
      } else if (loadingChangeSupplierOrder.includes('duplicate')) {
        label = 'Duplicate';
      }

      return (
        <div className="section-container">
          <Segment
            basic
            className="section-loading"
          >
            <Dimmer active={!!loadingChangeSupplierOrder} inverted>
              <Loader>
                {label === 'Duplicate'
                && t('documentDuplicateLoader', {
                  document: t('documentNumbering_PURCHASE_ORDER').toLowerCase(),
                })}

                {label === 'Pending'
                && t('documentValidate')
                }

                {label === 'Model'
                && t('documentToModelLoader')
                }
              </Loader>
            </Dimmer>
          </Segment>
        </div>
      );
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    return (
      <div className="section-container">
        {(loadedSupplier || isCreate)
        && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveSupplierOrder}
          />
        )}
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {match.params.id ? t('supplierOrdersUpdateTitle') : t('supplierOrderCreateTitle')}
            </Header>

            <EssorButton
              as={Link}
              to="/business/supplier_orders/"
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          {status
          && (
            <div className="option-buttons-container clearfix">
              <EssorButton
                loading={loadingPrintDocument}
                disabled={loadingPrintDocument}
                onClick={this.handlePrintDocument}
                type="print"
                size="tiny"
                floated="right"
              >
                {t('buttonPrint')}
              </EssorButton>
            </div>
          )}

          {(!status || status < STATUS_PENDING)
          && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form className="margin-top-bot main-form" loading={loadingRetrieveSupplierOrder || loadingCreateSupplierOrder || loadingUpdateSupplierOrder} size="small">
                    <Form.Group inline>
                      <Form.Select
                        label={t('formSupplier')}
                        control={Dropdown}
                        placeholder={t('formPHSelect')}
                        fluid
                        search
                        selection
                        loading={loadingListCustomer}
                        disabled={loadingListCustomer || !!customerObject}
                        noResultsMessage="No results"
                        options={customers}
                        name="selectedCustomer"
                        onChange={this.handleSelectChange}
                        value={selectedCustomer}
                        selectOnBlur={false}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <hr />

          {customerObject
          && (
            <React.Fragment>
              <Document
                type={SUPPLIER_ORDER}
                status={status}
                documentData={documentData}
                customer={customerObject}
                validData={validDocument}
                getData={(documentData, msgError) => this.onSubmit(documentData, msgError)}
                hasDataChanged={hasDataChanged}
              />

              {!isEmpty(messageError)
              && (
                <Message negative>
                  <Message.Header>{t('documentMessageError')}</Message.Header>
                  <Message.List>
                    {
                      messageError.map((error, i) => (
                        <Message.Item key={i}>{t(`${error}`)}</Message.Item>
                      ))
                    }
                  </Message.List>
                </Message>
              )}
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {status < STATUS_PENDING
                    && (
                      <EssorButton
                        disabled={
                          loadingCreateSupplierOrder
                          || loadingUpdateSupplierOrder
                          || loadingChangeSupplierOrder
                          || plannedHoursError
                        }
                        loading={
                          loadingCreateSupplierOrder
                          || loadingUpdateSupplierOrder
                          || loadingChangeSupplierOrder
                        }
                        type="check"
                        size="tiny"
                        floated="right"
                        onClick={() => this.handleOnSubmit('validate')}
                      >
                        {t('buttonSaveValidate')}
                      </EssorButton>
                    )}

                    <EssorButton
                      disabled={
                        loadingCreateSupplierOrder
                        || loadingUpdateSupplierOrder
                        || loadingChangeSupplierOrder
                        || plannedHoursError
                      }
                      loading={
                        loadingCreateSupplierOrder
                        || loadingUpdateSupplierOrder
                        || loadingChangeSupplierOrder
                      }
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit('edit')}
                    >
                      {t('buttonSaveAndEdit')}
                    </EssorButton>

                    <EssorButton
                      disabled={
                        loadingCreateSupplierOrder
                        || loadingUpdateSupplierOrder
                        || loadingChangeSupplierOrder
                        || plannedHoursError
                      }
                      loading={
                        loadingCreateSupplierOrder
                        || loadingUpdateSupplierOrder
                        || loadingChangeSupplierOrder
                      }
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit('save')}
                    >
                      {t('buttonSave')}
                    </EssorButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  setSupplierOrder: supplier => dispatch(updateSupplierSuccess(supplier)),
  getSupplierOrder: page => dispatch(retrieveSupplierOrder(page)),
  getSupplierList: page => dispatch(listCustomer(page)),
  getArticles: page => dispatch(listArticle(page)),
  postSupplierOrder: data => dispatch(createSupplierOrder(data)),
  updateSupplierOrder: (item, data) => dispatch(updateSupplierOrder(item, data)),
  changeSupplierOrder: (route, method) => dispatch(changeSupplierOrder(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setSupplierOrderList: supplierOrders => dispatch(setSupplierOrderList(supplierOrders)),
  selectDocument: document => dispatch(selectDocument(document)),
  labourCost: total => dispatch(labourCost(total)),
  plannedHours: total => dispatch(plannedHours(total)),
  reset: () => {
    dispatch(resetListCustomer());

    dispatch(resetArticle());

    dispatch(resetChangeSupplierOrder());

    dispatch(resetUpdateSupplierOrder());

    dispatch(successSupplierOrder(null));
    dispatch(loadingSupplierOrder(false));
    dispatch(errorSupplierOrder(null));

    dispatch(plannedHours(null));
    dispatch(labourCost(null));
  },
});

const mapStateToProps = state => ({
  listSupplierOrder: state.supplierOrder.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  createdSupplierOrder: state.supplierOrder.create.created,
  loadingCreateSupplierOrder: state.supplierOrder.create.loading,
  errorCreateSupplierOrder: state.supplierOrder.create.error,

  retrievedSupplierOrder: state.supplierOrder.update.retrieved,
  loadingRetrieveSupplierOrder: state.supplierOrder.update.retrieveLoading,
  errorRetrieveSupplierOrder: state.supplierOrder.update.retrieveError,
  updatedSupplierOrder: state.supplierOrder.update.updated,
  loadingUpdateSupplierOrder: state.supplierOrder.update.updateLoading,
  errorUpdateSupplierOrder: state.supplierOrder.update.updateError,

  changedSupplierOrder: state.supplierOrder.change.changed,
  loadingChangeSupplierOrder: state.supplierOrder.change.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,

  labourCostProp: state.document.liveDoc.labourCost,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditSupplierOrder);

export default withTranslation()(withRouter(Main));
