import React, { useEffect, useState } from 'react';
import useCompany from 'hooks/useCompany';
import useFiscalYear from 'hooks/useFiscalYear';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SectionLoader from 'components/sectionLoader';
import { Grid } from 'semantic-ui-react';
import TreasuryCards from 'components/company-dashboard/TreasuryCards';
import BreakEvenCard from 'components/company-dashboard/BreakEvenCard';
import Header from 'components/company-dashboard/header';
import { retrieve as retrieveDashboard } from 'actions/dashboard/show';
import ContainerGeneral from 'layouts/ContainerGeneral';
import Payments from 'components/company-dashboard/Payments';
import NewCustomers from 'components/company-dashboard/NewCustomers';
import IncomeAndExpenses from 'components/company-dashboard/IncomeAndExpenses';
import BusinessMgmt from 'components/company-dashboard/BusinessMgmt';
import OperatingProfitCard from 'components/company-dashboard/OperatingProfitCard';
import moment from 'moment';
import Turnover from 'components/company-dashboard/Turnover';
import GrossProfit from 'components/company-dashboard/GrossProfit';
import Charges from 'components/company-dashboard/Charges';
import HumanResources from 'components/company-dashboard/HumanResources';
import NoFiscalYear from 'layouts/error-pages/NoFiscalYear';

const ShowCompany = () => {
  const [hasCompany, company] = useCompany();
  const [hasFiscalYear, fiscalYear] = useFiscalYear();
  const loadingData = useSelector(state => state.dashboard.show.loading);
  const data = useSelector(state => state.dashboard.show.retrieved);

  const dispatch = useDispatch();
  const getData = uri => dispatch(retrieveDashboard(uri));

  const cur = moment().format('YYYY-MM');

  let initialPeriod = `?start=${cur}&end=${cur}`;
  if (hasFiscalYear && moment(fiscalYear.dateEnd).diff(moment()) < 0) {
    const fyStart = moment(fiscalYear.dateStart).format('YYYY-MM');
    const fyEnd = moment(fiscalYear.dateEnd).format('YYYY-MM');

    initialPeriod = `?start=${fyStart}&end=${fyEnd}`;
  }
  const [selectedPeriod, setPeriod] = useState(initialPeriod);

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (hasFiscalYear) {
      getData(`/fiscal_years/${fiscalYear.id}/dashboard${selectedPeriod}`);
    }
  }, [company, selectedPeriod, refresh, fiscalYear]);

  if (!hasFiscalYear) {
    return <NoFiscalYear />;
  }

  if (!hasCompany || isEmpty(data)) {
    return (
      <ContainerGeneral>
        <SectionLoader status />
      </ContainerGeneral>
    );
  }

  if (data.code) {
    return (
      <ContainerGeneral>
        <div>{data.message}</div>
      </ContainerGeneral>
    );
  }

  const { months, kpi, breakEven, quotes } = data;

  const temperedKpi = [
    kpi[0],
    kpi[2],
    kpi[3],
    kpi[4],
    kpi[5],
    kpi[8],
  ];

  temperedKpi[2].mainValue = quotes.pending.kpi[1].value;
  temperedKpi[3].mainValue = data.payments[0].amount;
  temperedKpi[4].mainValue = data.payments[1].amount;

  return (
    <div className="section-container">
      <SectionLoader status={loadingData}>
        <div>
          <Header
            selectedMonth={selectedPeriod}
            months={months}
            kpi={temperedKpi}
            onChange={period => setPeriod(period)}
          />
          <div className="section-dash">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <div
                          onClick={() => setRefresh(refresh + 1)}
                          style={{
                            display: 'none',
                          }}
                        >
                          REFRESH
                        </div>
                        <OperatingProfitCard fiscalYear={fiscalYear} data={data.operatingProfit} />
                        <TreasuryCards
                          currentPeriod={{
                            start: moment(data.currentPeriod.start),
                            end: moment(data.currentPeriod.end),
                          }}
                          nextPeriod={{
                            start: moment(data.nextPeriod.start),
                            end: moment(data.nextPeriod.end),
                          }}
                          data={data.treasury}
                        />

                        <IncomeAndExpenses data={data.incomeAndExpenses} />
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <BreakEvenCard data={breakEven} />
                        <NewCustomers data={data.newCustomers} />
                        <Payments data={data.payments} />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        {/*
                        <Table striped className="table-dashboard">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                {t('dashboard_turnover')}
                              </Table.HeaderCell>
                              <Table.HeaderCell />
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>{t('dashboard_totalTurnover')}</Table.Cell>
                              <CurrencyCell value="1234" />
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>{t('dashboard_growth')}</Table.Cell>
                              <CurrencyCell value="1234" />
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        */}

                        <Turnover data={data.turnover} />
                        <GrossProfit data={data.grossMargin} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>

              <Charges data={data.charges} />

              <HumanResources data={data.humanResources} />

              <BusinessMgmt quotes={quotes} />

            </Grid>
          </div>
        </div>
      </SectionLoader>
    </div>
  );
};

export default ShowCompany;
