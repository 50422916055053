// w need these disable because i18next don't provide proper TS declarations, cf their README
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore-next-line
import i18n from 'i18next';
import Big from 'big.js';
import { Company } from '../types/store';

export const PROFIT = 'rate';
export const MARGIN = 'grossMargin';
export const COEF = 'coef';

export interface MarginCalculation {
  getBigPriceFromCost: (cost: Big, rateOrCoef: Big) => Big;
  getBigCostFromPrice: (price: Big, rateOrCoef: Big) => Big;
  getBigMargin: (cost: Big, price: Big) => Big;
}

export class MarginRate implements MarginCalculation {
  getBigPriceFromCost = (cost: Big, rate: Big): Big => (
    rate.eq(100) ? cost : cost.div(Big(1).minus(rate.div(100)))
  );

  getBigCostFromPrice = (price: Big, rate: Big): Big => (
    price.times(Big(1).minus(rate.div(100)))
  );

  getBigMargin = (cost: Big, price: Big): Big => (
    Big(1).minus(cost.div(price.eq(0) ? 1 : price)).times(100)
  );
}

export class GrossMargin implements MarginCalculation {
  getBigPriceFromCost = (cost: Big, rate: Big): Big => Big(0);

  getBigCostFromPrice = (price: Big, rate: Big): Big => Big(0);

  getBigMargin = (cost: Big, price: Big): Big => Big(0);
}

export class SellingCoef implements MarginCalculation {
  getBigPriceFromCost = (cost: Big, coef: Big): Big => cost.times(coef);

  getBigCostFromPrice = (price: Big, coef: Big): Big => price.div(coef);

  getBigMargin = (cost: Big, price: Big): Big => (
    cost.eq(0) ? Big(0) : price.div(cost)
  );
}

export const getCalculationClass = (company: Company): MarginCalculation => {
  switch (company.calculationMode) {
    case PROFIT:
      return new MarginRate();
    case MARGIN:
      return new GrossMargin();
    case COEF:
      return new SellingCoef();
    default:
      throw Error(`Unknown calculation mode ${company.calculationMode}`);
  }
};

export const getMarginName = (company: Company): string => {
  switch (company.calculationMode) {
    case PROFIT:
      return i18n.t('formMarginRate');
    case MARGIN:
      return i18n.t('Taux de marge');
    case COEF:
      return i18n.t('formSellingCoefficient');
    default:
      throw Error(`Unknown calculation mode ${company.calculationMode}`);
  }
};

export const modeIsPercent = (company: Company): boolean => (
  company.calculationMode === PROFIT || company.calculationMode === MARGIN
);
