import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { findIndex, isEmpty, padEnd, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { list as listSupplier, reset as resetSupplier } from 'actions/supplier/list';
import { list as listBudgets, resetActual as resetBudgets } from 'actions/budget/list';
import { reset as resetBanks, retrieve as retrieveBanks } from 'actions/company-settings/show';
import { list as listInvoices, reset as resetInvoices } from 'actions/invoice/list';
import { list as listPayments, reset as resetPayments } from 'actions/payment/list';
import { list as listEncasements, reset as resetListEncasement } from 'actions/encasement/list';
import { create as createEncasement, error as errorEncasement, loading as loadingEncasement, success as successEncasement } from 'actions/encasement/create';
import { reset as resetUpdateEncasement, update as updateEncasement } from 'actions/encasement/update';
import { del as deleteEncasement, error as errorDeleteEncasement, loading as loadingDeleteEncasement, success as successDeleteEncasement } from 'actions/encasement/delete';
import { list as listDisbursements, reset as resetListDisbursement } from 'actions/disbursement/list';
import { create as createDisbursement, error as errorDisbursement, loading as loadingDisbursement, success as successDisbursement } from 'actions/disbursement/create';
import { reset as resetUpdateDisbursement, update as updateDisbursement } from 'actions/disbursement/update';
import { del as deleteDisbursement, error as errorDeleteDisbursement, loading as loadingDeleteDisbursement, success as successDeleteDisbursement } from 'actions/disbursement/delete';
import { Dropdown, Form, Input, Modal, Ref, Table, Visibility } from 'semantic-ui-react';
import classnames from 'classnames';
import { STATUS_PENDING, TYPE_ADJUST, TYPE_BUDGET, TYPE_MISC, TYPE_VAT } from 'utils/constants';
import { DeleteConfirmation, toast } from 'components';
import DatePicker from 'react-datepicker';
import { getFloatCleaveFormat, inputFormat, monthShortAndYear } from 'utils/formatter';
import Cleave from 'cleave.js/react';

import 'moment/locale/fr';
import { getActualDueAmount, getActualDueVat } from 'components/documents/documents';
import Big from 'big.js';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SmallForm from 'layouts/SmallForm';
import Currency from 'components/currency';
import TableLoader from 'components/TableLoader';
import TreasuryHeader from 'routes/admin/analysis/treasury/TreasuryHeader';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import TreasuryRow from 'routes/admin/analysis/treasury/TreasuryRow';
import TreasuryToggleRow from 'routes/admin/analysis/treasury/TreasuryToggleRow';
import Hide from 'components/Hide';
import AdvancedList from 'components/advancedList/AdvancedList';
import { columnDefinition, hiddenColumn } from 'utils/tables';
import { Entities } from 'types/accessRights';
import AddHeader from 'components/pageHeaders/AddHeader';
import BackHeader from 'components/pageHeaders/BackHeader';
import SaveButton from 'components/buttons/SaveButton';
import CancelButton from 'components/buttons/CancelButton';
import CloseButton from 'components/buttons/CloseButton';
import { keyVal } from 'utils/functions';
import InlineInput from 'layouts/InlineInput';
import KeyValue from 'components/keyValue';
import { ALL_BANKS, allBank, allOrId, getBankDataFromId, getDefaultBankId } from './functions';
import TreasuryContext from './context';
import MonthlyTurnoverRow from './MonthlyTurnoverRow';
import MonthlyVatRow from './MonthlyVatRow';

moment.locale('fr');

class TreasuryHome extends Component {
  state = {
    totalMonths: [],
    overlayFixed: false,
    encasementRowsCollapse: false,
    disbursementRowsCollapse: false,
    selectedBank: ALL_BANKS,
    supplierList: [],
    supplierOptions: [],
    bankList: null,
    budgetList: [],
    encasementList: [],
    disbursementList: [],
    invoiceList: [],
    paymentList: [],
    modalData: [],
    adjustList: [],
    encasementMisc: [],
    vatList: [],
    disbursementMisc: [],
    disbursementBudget: [],
    miscellaneousModal: false,

    nextMonth: null,
    selectedMonth: null,
    minDate: null,
    maxDate: null,
    date: null,
    supplierName: null,
    label: '',
    amount: '',
    vat: '',
    totalWithTaxes: 0,

    dateError: false,
    labelError: false,
    amountError: false,
    isFullscreen: false,
  };

  fixedHeaderRef = createRef();

  auxScrollbarRef = createRef();

  containerTableRef = createRef();

  tableRef = createRef();

  bar = createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listSupplier) && nextProps.listSupplier['hydra:member'] !== prevState.supplierList) {
      const supplierList = nextProps.listSupplier['hydra:member'];
      const supplierOptions = supplierList.map(supplier => ({
        key: supplier['@id'],
        text: supplier.supplierName,
        value: supplier.supplierName,
      }));

      return {
        supplierList,
        supplierOptions,
      };
    }

    if (
      !isEmpty(nextProps.retrievedBanks)
      && nextProps.retrievedBanks['hydra:member'][0].value !== prevState.bankList
      && nextProps.retrievedBanks['hydra:member'][0].name === 'BANK_ACCOUNTS'
    ) {
      return {
        bankList: nextProps.retrievedBanks['hydra:member'][0].value,
      };
    }

    if (!isEmpty(nextProps.listInvoice) && nextProps.listInvoice['hydra:member'] !== prevState.invoiceList) {
      return {
        invoiceList: nextProps.listInvoice['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listPayment) && nextProps.listPayment['hydra:member'] !== prevState.paymentList) {
      return {
        paymentList: nextProps.listPayment['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listEncasement) && nextProps.listEncasement !== prevState.encasement) {
      return {
        encasement: nextProps.listEncasement,
        encasementList: nextProps.listEncasement['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listDisbursement)
      && nextProps.listDisbursement !== prevState.disbursement) {
      return {
        disbursement: nextProps.listDisbursement,
        disbursementList: nextProps.listDisbursement['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listBudget) && nextProps.listBudget['hydra:member'] !== prevState.budgetList) {
      return {
        budgetList: nextProps.listBudget['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.updatedEncasement)) {
      const encasements = prevState.encasementList;
      const index = findIndex(encasements, {
        '@id': nextProps.updatedEncasement['@id'],
      });

      encasements[index] = nextProps.updatedEncasement;

      return {
        encasementList: encasements,
      };
    }

    if (!isEmpty(nextProps.deletedEncasement)) {
      const encasements = prevState.encasementList;
      const index = findIndex(encasements, {
        '@id': nextProps.deletedEncasement['@id'],
      });

      encasements.splice(index, 1);

      return {
        encasementList: encasements,
      };
    }

    if (!isEmpty(nextProps.updatedDisbursement)) {
      const disbursements = prevState.disbursementList;
      const index = findIndex(disbursements, {
        '@id': nextProps.updatedDisbursement['@id'],
      });

      disbursements[index] = nextProps.updatedDisbursement;

      return {
        disbursementList: disbursements,
      };
    }

    if (!isEmpty(nextProps.deletedDisbursement)) {
      const disbursements = prevState.disbursementList;
      const index = findIndex(disbursements, {
        '@id': nextProps.deletedDisbursement['@id'],
      });

      disbursements.splice(index, 1);

      return {
        disbursementList: disbursements,
      };
    }

    return null;
  }

  componentDidMount() {
    const {
      getSuppliers,
      getEncasements,
      getDisbursements,
      getInvoices,
      getPayments,
      getBanks,
      getBudgets,
      selectedCompany,
      selectedFiscalYear,
    } = this.props;

    let isValid = true;
    const monthlyData = [];
    const dateStart = moment(selectedCompany.dateCreated).startOf('year');
    const dateEnd = moment(selectedFiscalYear.dateEnd);
    const auxDate = dateStart;

    while (isValid) {
      if (auxDate.isSame(dateEnd, 'month')) {
        monthlyData.push({
          date: dateEnd.clone(),
          label: monthShortAndYear(dateEnd),
        });

        isValid = false;
      } else {
        monthlyData.push({
          date: auxDate.clone(),
          label: monthShortAndYear(auxDate),
        });

        auxDate.add(1, 'months');
      }
    }

    getSuppliers(`/suppliers?company=${selectedCompany.id}`);
    getBanks(`/company_settings?company=${selectedCompany.id}&name=BANK_ACCOUNTS`);
    getBudgets(`/budgets?fiscalYear=${selectedFiscalYear.id}&type[]=CV&&type[]=CF&pagination=false`);
    getInvoices(`/invoices?company=${selectedCompany.id}&status=${STATUS_PENDING}&paymentDate[after]=${moment()
      .format('YYYY-MM-DD')}&pagination=false`);
    getPayments(`/payments?company=${selectedCompany.id}&date[before]=${moment()
      .format('YYYY-MM-DD')}&pagination=false`);
    getEncasements(`/encasements?company=${selectedCompany.id}&pagination=false`);
    getDisbursements(`/disbursements?company=${selectedCompany.id}&pagination=false`);

    this.setState({
      totalMonths: monthlyData,
    }, () => {
      const { totalMonths } = this.state;
      let index = 0;

      for (let i = 0; i < totalMonths.length; i++) {
        if (totalMonths[i].date.isSame(moment(), 'month')) {
          index = i;
          break;
        }
      }

      this.bar.current.style.width = `${this.tableRef.current.clientWidth}px`;
      this.fixedHeaderRef.current.scrollLeft = (index - 2) * 113 + 1;
      this.auxScrollbarRef.current.scrollLeft = (index - 2) * 113 + 1;
      this.containerTableRef.current.scrollLeft = (index - 2) * 113 + 1;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      encasement,
      disbursement,
      encasementList,
      disbursementList,
      copying,
      bankList,
      selectedBank,
    } = this.state;
    const {
      createdEncasement,
      updatedEncasement,
      deletedEncasement,
      createdDisbursement,
      updatedDisbursement,
      deletedDisbursement,
      resetCreateEncasement,
      resetUpdateEncasement,
      resetDeleteEncasement,
      resetCreateDisbursement,
      resetUpdateDisbursement,
      resetDeleteDisbursement,
      t,
    } = this.props;

    if (!isEmpty(createdEncasement) && createdEncasement !== prevProps.createdEncasement) {
      this.updateEncasementList(createdEncasement);
      if (!copying) {
        toast.success(t('encasementCreateSuccess'));
        resetCreateEncasement();
        this.hideEditForm();
      }
    }

    if (!isEmpty(updatedEncasement) && updatedEncasement !== prevProps.updatedEncasement) {
      resetUpdateEncasement();
      this.updateEncasementLists(true);
      this.hideEditForm();
    }

    if (!isEmpty(deletedEncasement) && deletedEncasement !== prevProps.deletedEncasement) {
      resetDeleteEncasement();
      this.updateEncasementLists(true);
      this.dismissWarning();
    }

    if (!isEmpty(encasementList) && encasementList !== prevState.encasementList) {
      this.updateEncasementLists();
    }

    if (!isEmpty(createdDisbursement) && createdDisbursement !== prevProps.createdDisbursement) {
      this.updateDisbursementList(createdDisbursement);
      if (!copying) {
        toast.success(t('disbursementCreateSuccess'));
        resetCreateDisbursement();
        this.hideEditForm();
      }
    }

    if (!isEmpty(updatedDisbursement) && updatedDisbursement !== prevProps.updatedDisbursement) {
      resetUpdateDisbursement();
      this.updateDisbursementLists(true);
      this.hideEditForm();
    }

    if (!isEmpty(deletedDisbursement) && deletedDisbursement !== prevProps.deletedDisbursement) {
      resetDeleteDisbursement();
      this.updateDisbursementLists(true);
      this.dismissWarning();
    }

    if (!isEmpty(disbursementList) && disbursementList !== prevState.disbursementList) {
      this.updateDisbursementLists();
    }

    if (!isEmpty(encasement) && !isEmpty(disbursement) && bankList !== null && !selectedBank) {
      this.updateSelectedBank();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  updateSelectedBank = () => {
    let selectedBank = sessionStorage.getItem('selectedBank');
    selectedBank = parseInt(selectedBank, 10) || ALL_BANKS;

    this.setState({
      selectedBank,
    }, () => {
      this.updateEncasementLists();
      this.updateDisbursementLists();
    });
  };

  updateEncasementList = (encasement) => {
    const { encasementList, copying } = this.state;

    const encasements = [...encasementList, encasement];

    this.setState({
      encasementList: encasements,
    }, () => {
      if (!copying) {
        this.updateEncasementLists(true);
      }
    });
  };

  updateDisbursementList = (disbursement) => {
    const { disbursementList, copying } = this.state;

    const disbursements = [...disbursementList, disbursement];

    this.setState({
      disbursementList: disbursements,
    }, () => {
      if (!copying) {
        this.updateDisbursementLists(true);
      }
    });
  };

  updateEncasementLists = (boolean = false) => {
    const {
      encasementList,
      paymentList,
      selectedBank,
    } = this.state;

    if (!selectedBank) {
      return;
    }

    const adjustList = [];
    const encasementMisc = [];
    const payments = [];

    if (!isEmpty(encasementList)) {
      encasementList.forEach((item) => {
        if (!allBank(selectedBank)) {
          if (item.bankAccount === selectedBank) {
            if (item.type === TYPE_ADJUST) {
              adjustList.push(item);
            } else {
              encasementMisc.push(item);
            }
          }
        } else if (item.type === TYPE_ADJUST) {
          adjustList.push(item);
        } else {
          encasementMisc.push(item);
        }
      });
    }

    if (!isEmpty(paymentList)) {
      paymentList.forEach((item) => {
        if (allOrId(selectedBank, item.bankAccount)) {
          payments.push(item);
        }
      });
    }

    this.setState({
      adjustList,
      encasementMisc,
      paymentList: payments,
    }, () => {
      if (boolean) {
        this.updateModalData();
      }
    });
  };

  updateDisbursementLists = (boolean = false) => {
    const {
      disbursementList,
      selectedBank,
    } = this.state;

    if (!selectedBank) {
      return;
    }

    const vatList = [];
    const disbursementMisc = [];
    const disbursementBudget = [];

    if (!isEmpty(disbursementList)) {
      disbursementList.forEach((item) => {
        if (!allBank(selectedBank)) {
          if (item.bankAccount === selectedBank) {
            if (item.type === TYPE_VAT) {
              vatList.push(item);
            } else if (item.type === TYPE_MISC) {
              disbursementMisc.push(item);
            } else {
              disbursementBudget.push(item);
            }
          }
        } else if (item.type === TYPE_VAT) {
          vatList.push(item);
        } else if (item.type === TYPE_MISC) {
          disbursementMisc.push(item);
        } else {
          disbursementBudget.push(item);
        }
      });
    }

    this.setState({
      vatList,
      disbursementMisc,
      disbursementBudget,
    }, () => {
      if (boolean) {
        this.updateModalData();
      }
    });
  };

  handleSelectChange = (e, { value, name }) => {
    e.preventDefault();

    this.setState({
      [name]: value,
    }, () => {
      if (name === 'selectedBank') {
        sessionStorage.setItem('selectedBank', value);
        this.updateEncasementLists();
        this.updateDisbursementLists();
      }
    });
  };

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      supplierOptions: [
        ...prevState.supplierOptions,
        {
          text: value,
          value,
        },
      ],
    }));
  };

  handleFullScreen = (isFullscreen) => {
    this.setState({
      isFullscreen,
    });
  };

  toggleRows = (type) => {
    if (type === 'encasement') {
      this.setState(prevState => ({
        encasementRowsCollapse: !prevState.encasementRowsCollapse,
      }));
    } else if (type === 'disbursement') {
      this.setState(prevState => ({
        disbursementRowsCollapse: !prevState.disbursementRowsCollapse,
      }));
    }
  };

  updateModalData = () => {
    const {
      adjustList,
      encasementMisc,
      vatList,
      disbursementMisc,
      disbursementBudget,
      option,
      budget,
      allocate,
      type,
      selectedMonth,
      bankList,
    } = this.state;

    let modalData = [];

    switch (option) {
      case 'encasement':
        if (type === TYPE_ADJUST) {
          modalData = adjustList.filter(data => moment(data.date)
            .isSame(selectedMonth.date, 'month'));
        } else if (type === TYPE_MISC) {
          modalData = encasementMisc.filter(data => moment(data.date)
            .isSame(selectedMonth.date, 'month'));
        }
        break;
      case 'disbursement':
        if (type === TYPE_VAT) {
          modalData = vatList.filter(data => moment(data.date).isSame(selectedMonth.date, 'month'));
        } else if (type === TYPE_MISC) {
          modalData = disbursementMisc.filter(data => moment(data.date)
            .isSame(selectedMonth.date, 'month'));
        } else if (type === TYPE_BUDGET) {
          modalData = disbursementBudget.filter((data) => {
            if (moment(data.date).isSame(selectedMonth.date, 'month')) {
              if (budget && allocate) {
                return (data.budget === budget && data.allocation === allocate);
              }
              if (budget && !allocate) {
                return (data.budget === budget && !data.allocation);
              }
            }

            return null;
          });
        }
        break;
      default:
        break;
    }

    modalData.forEach((item) => {
      item.withTaxes = parseFloat(item.amount) + parseFloat(item.vat);
      const bank = getBankDataFromId(item.bankAccount, bankList);
      item.bankName = bank ? bank.bankName : 'n/a';
    });

    this.setState({
      modalData,
    });
  };

  addMiscellaneous = (
    option, type, selectedMonth, nextMonth, budget = undefined, allocate = undefined,
  ) => {
    const minDate = moment(selectedMonth.date).startOf('month');
    const maxDate = moment(selectedMonth.date).endOf('month');

    this.setState({
      selectedMonth,
      nextMonth: nextMonth || null,
      minDate,
      maxDate,
      option,
      type,
      budget,
      allocate,
      modalForm: false,
      miscellaneousModal: true,
    }, this.updateModalData);
  };

  closeModal = () => {
    this.hideEditForm();

    this.setState({
      miscellaneousModal: false,
      type: '',
      option: '',
      budget: null,
    });
  };

  showEditForm = (data = null) => {
    const { supplierOptions, type } = this.state;

    if (data) {
      const supplier = findIndex(supplierOptions, {
        value: data.supplierName,
      });

      if (supplier === -1) {
        this.setState(prevState => ({
          supplierOptions: [
            ...prevState.supplierOptions,
            {
              text: data.supplierName,
              value: data.supplierName,
            },
          ],
        }));
      }
    }

    this.setState({
      modalForm: true,
      editItem: data,
      date: data ? moment(data.date) : null,
      supplierName: data ? data.supplierName : null,
      label: data ? data.label : '',
      amount: data ? data.amount : '',
      vat: type === TYPE_VAT
        ? '0'
        : data
          ? data.vat
          : '',
      totalWithTaxes: data ? (parseFloat(data.amount) + parseFloat(data.vat)) : 0,
    });
  };

  hideEditForm = () => {
    this.setState({
      modalForm: false,
      editItem: null,
      date: null,
      supplierName: null,
      label: '',
      amount: '',
      vat: '',
      totalWithTaxes: 0,
      dateError: false,
      labelError: false,
      amountError: false,
    });
  };

  showDeleteWarning = (toDelete) => {
    this.setState({
      toDelete,
      warningMessage: true,
    });
  };

  dismissWarning = () => {
    this.setState({
      toDelete: null,
      warningMessage: false,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleFloatChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
    }, () => {
      const { vat, amount } = this.state;
      const vatAmount = vat !== '' ? vat : '0';

      this.setState({
        totalWithTaxes: amount !== ''
          ? (parseFloat(vatAmount) + parseFloat(amount)).toFixed(2)
          : 0,
      });
    });
  };

  handleOnDelete = () => {
    const {
      option,
      toDelete,
    } = this.state;

    const {
      deleteEncasement,
      deleteDisbursement,
    } = this.props;

    if (option === 'encasement') {
      deleteEncasement(toDelete);
    } else {
      deleteDisbursement(toDelete);
    }
  };

  handleSubmit = () => {
    const {
      editItem,
      option,
      type,
      date,
      supplierName,
      label,
      amount,
      vat,
      budget,
      allocate,
      selectedBank,
    } = this.state;

    const {
      postEncasement,
      updateEncasement,
      postDisbursement,
      updateDisbursement,
      selectedCompany,
    } = this.props;

    let isValid = true;

    this.setState({
      dateError: false,
      labelError: false,
      amountError: false,
    });

    if (!date) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (label === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (amount === '') {
      isValid = false;

      this.setState({
        amountError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const data = {
      company: selectedCompany['@id'],
      type,
      label,
      amount: Big(amount || 0).toFixed(2),
      vat: vat !== '' ? Big(vat || 0).toFixed(2) : '0',
      date: date.format('YYYY-MM-DD'),
      bankAccount: selectedBank,
    };

    if (option === 'encasement') {
      editItem ? updateEncasement(editItem, data) : postEncasement(data);
    } else {
      if (type === TYPE_BUDGET) {
        data.supplierName = supplierName || '';
        data.budget = budget;
        data.allocation = allocate;
      }

      editItem ? updateDisbursement(editItem, data) : postDisbursement(data);
    }
  };

  handleCopyItems = () => {
    const {
      modalData,
      nextMonth,
      option,
      type,
      budget,
      allocate,
      totalMonths,
    } = this.state;

    const {
      postEncasement,
      postDisbursement,
      resetCreateEncasement,
      t,
    } = this.props;

    const monthIndex = findIndex(totalMonths, {
      label: nextMonth.label,
    });

    this.setState({
      copying: true,
    }, () => {
      const promises = [];

      modalData.forEach((data) => {
        const item = {
          company: data.company,
          type: data.type,
          label: data.label,
          amount: Big(data.amount || 0).toFixed(2),
          vat: Big(data.vat || 0).toFixed(2),
          bankAccount: data.bankAccount,
          date: moment(data.date).add(1, 'months').format('YYYY-MM-DD'),
        };

        if (option === 'encasement') {
          promises.push(postEncasement(item));
        } else {
          if (data.type === TYPE_BUDGET) {
            item.supplierName = data.supplierName;
            item.budget = data.budget;
            item.allocation = data.allocation;
          }
          promises.push(postDisbursement(item));
        }
      });

      Promise.all(promises)
        .then(() => {
          toast.success(t('treasuryCopySuccess'));
          this.setState({
            copying: false,
          }, () => {
            resetCreateEncasement();

            if (option === 'encasement') {
              this.updateEncasementLists();
            } else {
              this.updateDisbursementLists();
            }

            this.addMiscellaneous(
              option,
              type,
              nextMonth,
              totalMonths[monthIndex + 1],
              budget,
              allocate,
            );
          });
        })
        .catch(() => {
          this.setState({
            copying: false,
          });
        });
    });
  };

  stickOverlay = () => this.setState({
    overlayFixed: true,
  });

  unStickOverlay = () => this.setState({
    overlayFixed: false,
  });

  scrollTable = (e) => {
    const { scrollLeft } = e.target;
    const moddedScroll = 113 * Big(scrollLeft).div(113).toFixed(0);

    this.containerTableRef.current.scrollLeft = moddedScroll;

    this.fixedHeaderRef.current.scrollLeft = moddedScroll;
    this.auxScrollbarRef.current.scrollLeft = moddedScroll;
  };

  accountFormat = (account) => {
    if (account.length !== 9) {
      account = padEnd(account, 9, '0');
    }

    const one = account.slice(0, 3);
    const two = account.slice(3, 6);
    const three = account.slice(6, 9);

    return `${one}.${two}.${three}`;
  };

  render() {
    const {
      selectedBank,
      encasementRowsCollapse,
      disbursementRowsCollapse,
      supplierOptions,
      bankList,
      budgetList,
      invoiceList,
      paymentList,
      overlayFixed,
      totalMonths,
      miscellaneousModal,
      type,

      adjustList,
      encasementMisc,
      vatList,
      disbursementMisc,
      disbursementBudget,
      modalData,

      minDate,
      maxDate,
      date,
      supplierName,
      label,
      amount,
      vat,
      totalWithTaxes,

      dateError,
      labelError,
      amountError,

      modalForm,
      selectedMonth,
      nextMonth,
      warningMessage,
      copying,

      toDelete,
      isFullscreen,
    } = this.state;

    const {
      selectedFiscalYear,
      loadingSupplier,
      loadingBank,
      loadingBudget,
      loadingInvoice,
      loadingPayment,
      loadingListEncasement,
      loadingCreateEncasement,
      loadingUpdateEncasement,
      loadingDeleteEncasement,

      loadingListDisbursement,
      loadingCreateDisbursement,
      loadingUpdateDisbursement,
      loadingDeleteDisbursement,
      t,
    } = this.props;

    const allOrDefault = () => allOrId(selectedBank, getDefaultBankId(bankList));

    let bankOptions = [];

    if (!isEmpty(bankList)) {
      bankOptions = bankList.map((bank, index) => ({
        key: index,
        text: `${bank.bankName}${bank.default ? ` (${t('formDefault')})` : ''}`,
        value: bank.id,
      }));
    }

    bankOptions.unshift({
      key: 'all',
      text: t('treasuryAllBank'),
      value: ALL_BANKS,
    });

    let budgetSortedList = [];

    if (!isEmpty(budgetList)) {
      budgetSortedList = sortBy(budgetList.filter(budget => !budget.account.startsWith('68')), ['account'])
        .reverse();
    }

    const withTaxes = item => parseFloat(item.amount) + parseFloat(item.vat);

    let dataMonth = [];

    if (!isEmpty(totalMonths)) {
      dataMonth = totalMonths.map((month) => {
        let monthAdjust = 0;
        let monthTurnover = 0;
        let monthCollectedVat = 0;
        let monthEncasementMisc = 0;
        let monthEncasementVat = 0;
        let monthPaidVat = 0;
        const monthBudget = [];
        let monthDisbursementMisc = 0;
        let monthDeductibleVat = 0;

        adjustList.forEach((item) => {
          if (moment(item.date).isSame(month.date, 'month')) {
            monthAdjust += withTaxes(item);
            monthEncasementVat += parseFloat(item.vat);
          }
        });

        invoiceList.forEach((item) => {
          if (moment(item.paymentDate).isSame(month.date, 'month')) {
            if (allOrDefault()) {
              monthTurnover += parseFloat(getActualDueAmount(item).toFixed(2));
              monthCollectedVat += parseFloat(getActualDueVat(item).toFixed(2));
            }
          }
        });

        paymentList.forEach((item) => {
          if (allOrId(selectedBank, item.bankAccount)) {
            if (moment(item.date).isSame(month.date, 'month')) {
              monthTurnover += parseFloat(item.amount);
              monthCollectedVat += parseFloat(item.vat);
            }
          }
        });

        encasementMisc.forEach((item) => {
          if (moment(item.date).isSame(month.date, 'month')) {
            monthEncasementMisc += withTaxes(item);
            monthEncasementVat += parseFloat(item.vat);
          }
        });

        vatList.forEach((item) => {
          if (moment(item.date).isSame(month.date, 'month')) {
            monthPaidVat += parseFloat(item.amount);

            // TODO We are not sure whether to add PaidVAT amount property in the DeductibleVAT
            // monthDeductibleVat += parseFloat(item.amount);
          }
        });

        disbursementBudget.forEach((item) => {
          if (moment(item.date).isSame(month.date, 'month')) {
            monthBudget.push(item);
            monthDeductibleVat += parseFloat(item.vat);
          }
        });

        disbursementMisc.forEach((item) => {
          if (moment(item.date).isSame(month.date, 'month')) {
            monthDisbursementMisc += withTaxes(item);
            monthDeductibleVat += parseFloat(item.vat);
          }
        });

        return {
          ...month,
          monthAdjust,
          monthTurnover,
          monthCollectedVat,
          monthEncasementMisc,
          monthEncasementVat,

          monthPaidVat,
          monthBudget,
          monthDisbursementMisc,
          monthDeductibleVat,
        };
      });
    }

    let balanceAdjustmentTotal = 0;
    let turnoverTotal = 0;
    let collectedVatTotal = 0;
    let vatBalanceTotal = 0;
    let encasementMiscTotal = 0;
    let encasementTotal = 0;// Big(0);
    let paidVatTotal = 0;
    let disbursementMiscTotal = 0;
    let deductibleVatTotal = 0;
    let disbursementTotal = 0;
    let monthlySpendingTotal = 0;

    if (!isEmpty(dataMonth)) {
      for (let i = 0; i < dataMonth.length; i++) {
        dataMonth[i].budgetDis = {};
        dataMonth[i].firstBalance = dataMonth[i - 1]
          ? dataMonth[i - 1].lastBalance
          : 0;

        dataMonth[i].monthVatBalance = dataMonth[i - 1]
          ? dataMonth[i - 1].monthVatBalance - dataMonth[i].monthCollectedVat
          - dataMonth[i].monthEncasementVat + dataMonth[i].monthPaidVat
          + dataMonth[i].monthDeductibleVat
          : 0;

        dataMonth[i].monthEncasementTotal = dataMonth[i].monthAdjust
          + dataMonth[i].monthTurnover
          + dataMonth[i].monthCollectedVat
          + dataMonth[i].monthEncasementMisc;

        let monthBudgetTotal = 0;

        // eslint-disable-next-line no-loop-func
        dataMonth[i].monthBudget.forEach((item) => { // disbursement
          const itemTotal = withTaxes(item);
          monthBudgetTotal += itemTotal;

          if (!dataMonth[i].budgetDis[item.budget]) {
            dataMonth[i].budgetDis[item.budget] = {};
          }

          if (dataMonth[i][item.budget]) {
            dataMonth[i][item.budget] += itemTotal;
          } else {
            dataMonth[i][item.budget] = itemTotal;
          }

          if (item.allocation) {
            if (dataMonth[i].budgetDis[item.budget][item.allocation]) {
              dataMonth[i].budgetDis[item.budget][item.allocation] += itemTotal;
            } else {
              dataMonth[i].budgetDis[item.budget][item.allocation] = itemTotal;
            }
          } else if (dataMonth[i].budgetDis[item.budget][item.budget]) {
            dataMonth[i].budgetDis[item.budget][item.budget] += itemTotal;
          } else {
            dataMonth[i].budgetDis[item.budget][item.budget] = itemTotal;
          }
        });

        dataMonth[i].monthDisbursementTotal = dataMonth[i].monthPaidVat
          + monthBudgetTotal
          + dataMonth[i].monthDisbursementMisc;

        dataMonth[i].monthSpending = dataMonth[i].monthEncasementTotal
          - dataMonth[i].monthDisbursementTotal;

        if (dataMonth[i].date.diff(moment(selectedFiscalYear.dateStart).startOf('month')) >= 0) {
          balanceAdjustmentTotal += dataMonth[i].monthAdjust;
          turnoverTotal += dataMonth[i].monthTurnover;
          collectedVatTotal += dataMonth[i].monthCollectedVat;
          encasementMiscTotal += dataMonth[i].monthEncasementMisc;
          vatBalanceTotal = dataMonth[dataMonth.length - 1].monthVatBalance;
          encasementTotal += dataMonth[i].monthEncasementTotal;

          paidVatTotal += dataMonth[i].monthPaidVat;
          disbursementMiscTotal += dataMonth[i].monthDisbursementMisc;
          deductibleVatTotal += dataMonth[i].monthDeductibleVat;
          disbursementTotal += dataMonth[i].monthDisbursementTotal;
        }
        monthlySpendingTotal += dataMonth[i].monthSpending;

        dataMonth[i].lastBalance = dataMonth[i].firstBalance + dataMonth[i].monthSpending;
      }
    }

    const displayMonths = totalMonths.filter(month => (
      month.date.diff(moment(selectedFiscalYear.dateStart).startOf('month')) >= 0
    ));
    dataMonth = dataMonth.filter(month => (
      month.date.diff(moment(selectedFiscalYear.dateStart).startOf('month')) >= 0
    ));

    encasementTotal = Big(encasementTotal);

    const loadingListsNoBank = loadingBudget || loadingInvoice || loadingPayment
      || loadingListEncasement || loadingListDisbursement;
    const loadingLists = loadingBank || loadingListsNoBank;

    const loadingCreate = loadingCreateEncasement || loadingCreateDisbursement;
    const loadingUpdate = loadingUpdateEncasement || loadingUpdateDisbursement;
    const loadingDelete = loadingDeleteEncasement || loadingDeleteDisbursement;
    const loadingCU = loadingCreate || loadingUpdate;
    const loadingCC = copying || loadingCreate;
    const loadingCCU = copying || loadingCreate || loadingUpdate;

    const overlayClasses = {
      'fixed-content': overlayFixed,
      'hide-content': !overlayFixed,
    };

    const plannedTurnover = selectedFiscalYear.forecast.planned
      ? selectedFiscalYear.forecast.planned.turnover : 0;

    const contextValue = {
      bank: selectedBank,
      data: dataMonth,
    };

    const buttonLoading = loading => ({
      loading,
      disabled: loading,
    });

    return (
      <TreasuryContext.Provider value={contextValue}>
        <ContainerGeneral fullscreen handleFullscreen={this.handleFullScreen}>
          <TitleHeader title={t('analysisTreasuryTitleWithTaxes')} />

          <SmallForm>
            <Form.Group inline className="m-w-1000">
              <Form.Select
                label={t('formSelectBank')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                name="selectedBank"
                fluid
                selection
                loading={loadingLists}
                disabled={loadingLists}
                options={bankOptions}
                onChange={this.handleSelectChange}
                value={selectedBank}
                selectOnBlur={false}
              />
            </Form.Group>
          </SmallForm>

          <Visibility
            offset={isFullscreen ? -65 : 15}
            once={false}
            onBottomPassed={this.stickOverlay}
            onBottomVisible={this.unStickOverlay}
          />
          <div className={classnames('table-container', 'm-w-1000', overlayClasses)}>
            <div
              className="treasury-table-container"
              onScroll={e => this.scrollTable(e)}
              ref={this.fixedHeaderRef}
            >
              <Table celled className="treasury-table">
                <TreasuryHeader
                  months={displayMonths}
                  loading={loadingLists}
                  banks={bankOptions}
                  selected={selectedBank}
                  handle={this.handleSelectChange}
                />
              </Table>
            </div>
          </div>

          <TableLoader status={loadingListsNoBank}>
            <div
              className="treasury-table-container"
              onScroll={e => this.scrollTable(e)}
              ref={this.auxScrollbarRef}
            >
              <div ref={this.bar} />
            </div>
            <div className="treasury-table-container no-scroll" ref={this.containerTableRef}>
              <Ref innerRef={this.tableRef}>
                <Table celled className="treasury-table">
                  <TreasuryHeader months={displayMonths} />
                  <Table.Body>
                    <TreasuryRow monthKey="firstBalance" label={t('treasuryBalanceFirstDay')} />

                    <TreasuryToggleRow
                      displayMonths={displayMonths}
                      collapse={encasementRowsCollapse}
                      title={t('treasuryEncasement')}
                      toggle={() => this.toggleRows('encasement')}
                    />

                    <Hide condition={encasementRowsCollapse}>
                      <TreasuryRow
                        monthKey="monthAdjust"
                        label={t('treasuryBalanceAdjustment')}
                        total={balanceAdjustmentTotal}
                        onClick={this.addMiscellaneous}
                        option="encasement"
                        miscType={TYPE_ADJUST}
                      />

                      <MonthlyTurnoverRow planned={plannedTurnover} total={turnoverTotal} />

                      <MonthlyVatRow total={collectedVatTotal} />

                      <TreasuryRow
                        label={t('treasuryMiscellaneous')}
                        monthKey="monthEncasementMisc"
                        total={encasementMiscTotal}
                        onClick={this.addMiscellaneous}
                        option="encasement"
                        miscType={TYPE_MISC}
                      />

                      <TreasuryRow
                        label={t('treasuryVatBalance')}
                        monthKey="monthVatBalance"
                        total={vatBalanceTotal}
                      />
                    </Hide>

                    <TreasuryRow
                      label={t('treasuryEncasement')}
                      monthKey="monthEncasementTotal"
                      total={encasementTotal}
                    />

                    <TreasuryToggleRow
                      displayMonths={displayMonths}
                      collapse={disbursementRowsCollapse}
                      title={t('treasuryDisbursement')}
                      toggle={() => this.toggleRows('disbursement')}
                    />

                    <Hide condition={disbursementRowsCollapse}>
                      <TreasuryRow
                        label={t('treasuryPaidVat')}
                        monthKey="monthPaidVat"
                        total={paidVatTotal}
                        onClick={this.addMiscellaneous}
                        option="disbursement"
                        miscType={TYPE_VAT}
                      />

                      {!isEmpty(budgetSortedList) && budgetSortedList.map(budget => (
                        <TreasuryRow
                          key={budget['@id']}
                          label={budget.label}
                          alt={`${this.accountFormat(budget.account)} ${budget.label}`}
                          monthKey={budget['@id']}
                          onClick={this.addMiscellaneous}
                          option="disbursement"
                          miscType={TYPE_BUDGET}
                          budget={budget}
                        />
                      ))}

                      <TreasuryRow
                        label={t('treasuryMiscellaneous')}
                        monthKey="monthDisbursementMisc"
                        total={disbursementMiscTotal}
                        onClick={this.addMiscellaneous}
                        option="disbursement"
                        miscType={TYPE_MISC}
                      />

                      <TreasuryRow
                        label={t('treasuryDeductibleVat')}
                        monthKey="monthDeductibleVat"
                        total={deductibleVatTotal}
                      />
                    </Hide>

                    <TreasuryRow
                      label={`TOTAL ${t('treasuryDisbursement').toUpperCase()}`}
                      monthKey="monthDisbursementTotal"
                      total={disbursementTotal}
                    />

                    <TreasuryRow
                      label={t('treasuryMonthlySpending')}
                      monthKey="monthSpending"
                      total={monthlySpendingTotal}
                      top
                    />

                    <TreasuryRow
                      label={t('treasuryBalanceLastDay')}
                      monthKey="lastBalance"
                      bottom
                    />
                  </Table.Body>
                </Table>
              </Ref>
            </div>
          </TableLoader>

          <Modal open={miscellaneousModal} className="full-content">
            <Modal.Header content={`${t(type)} ${selectedMonth && selectedMonth.label}`} />
            <Modal.Content scrolling>
              <Modal.Description>
                {!modalForm && (
                  <>
                    {!allBank(selectedBank) && (
                      <AddHeader title="" onClick={() => this.showEditForm()} disabled={loadingCC} />
                    )}

                    <AdvancedList
                      entity={Entities.disbursement}
                      loading={loadingCC}
                      columns={[
                        columnDefinition('formLabel', 'label', ''),
                        columnDefinition('formBankName', 'bankName', ''),
                        columnDefinition('formBeforeTaxes', 'amount', 'currency'),
                        columnDefinition('formVat', 'vat', 'currency'),
                        hiddenColumn('formWithTaxes', 'withTaxes', 'currency'),
                        columnDefinition('formDate', 'date', 'date'),
                      ]}
                      data={modalData}
                      onEdit={this.showEditForm}
                      onDelete={this.showDeleteWarning}
                      sortBy={date}
                      maxRow={15}
                    />
                  </>
                )}

                {modalForm && (
                  <div>
                    <BackHeader title="" onClick={this.hideEditForm} />
                    <SmallForm loading={loadingCU}>
                      <Form.Group inline>
                        <Form.Input
                          label={t('formDate')}
                          name="date"
                          control={DatePicker}
                          minDate={minDate}
                          maxDate={maxDate}
                          selected={date}
                          onChange={this.handleDateChange}
                          locale="fr"
                          autoComplete="off"
                          error={dateError}
                        />
                      </Form.Group>

                      {type === TYPE_BUDGET && (
                        <Form.Group inline>
                          <Form.Select
                            label={t('formSupplier')}
                            control={Dropdown}
                            placeholder={t('formPHSupplierName')}
                            fluid
                            search
                            selection
                            allowAdditions
                            name="supplierName"
                            loading={loadingSupplier}
                            options={supplierOptions}
                            onAddItem={this.handleAddition}
                            onChange={this.handleSelectChange}
                            value={supplierName}
                            selectOnBlur={false}
                          />
                        </Form.Group>
                      )}

                      <Form.Group inline>
                        <Form.Input
                          label={t('formLabel')}
                          name="label"
                          placeholder={t('formPHLabel')}
                          onChange={this.handleTextChange}
                          value={label}
                          error={labelError}
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Field error={amountError}>
                          <label>
                            {type === TYPE_VAT ? t('treasuryAmount') : t('treasuryAmountBeforeTaxes')}
                          </label>
                          <Input>
                            <Cleave
                              options={getFloatCleaveFormat()}
                              onChange={e => this.handleFloatChange(e, true)}
                              name="amount"
                              placeholder={t('formPHSellingPrice')}
                              value={inputFormat(amount, true)}
                            />
                          </Input>
                        </Form.Field>
                      </Form.Group>

                      {type !== TYPE_VAT && (
                        <InlineInput title={t('treasuryVATAmount')}>
                          <Cleave
                            options={getFloatCleaveFormat()}
                            onChange={e => this.handleFloatChange(e)}
                            name="vat"
                            placeholder={t('formPHSellingPrice')}
                            value={inputFormat(vat, true)}
                          />
                        </InlineInput>
                      )}

                      <KeyValue label={t('quoteTotalWithTaxes')}>
                        <Currency value={totalWithTaxes} />
                      </KeyValue>

                    </SmallForm>
                  </div>
                )}
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              {!modalForm && (
                <>
                  {nextMonth && (
                    <SaveButton
                      onClick={this.handleCopyItems}
                      label={t('treasuryCopyTo', keyVal('month', nextMonth.label))}
                      {...buttonLoading(loadingCCU)}
                    />
                  )}
                  <CloseButton onClick={this.closeModal} {...buttonLoading(loadingCU)} />
                </>
              )}
              {modalForm && (
                <>
                  <SaveButton onClick={this.handleSubmit} {...buttonLoading(loadingCU)} />
                  <CancelButton onClick={this.closeModal} {...buttonLoading(loadingCU)} />
                </>
              )}
            </Modal.Actions>
          </Modal>

          <DeleteConfirmation
            show={warningMessage}
            name={toDelete && toDelete.name}
            loading={loadingDelete}
            onClose={this.dismissWarning}
            onDelete={this.handleOnDelete}
          />
        </ContainerGeneral>
      </TreasuryContext.Provider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSuppliers: page => dispatch(listSupplier(page)),
  getBanks: page => dispatch(retrieveBanks(page)),
  getBudgets: page => dispatch(listBudgets(page)),
  getInvoices: page => dispatch(listInvoices(page)),
  getPayments: page => dispatch(listPayments(page)),
  getEncasements: page => dispatch(listEncasements(page)),
  getDisbursements: page => dispatch(listDisbursements(page)),
  postEncasement: data => dispatch(createEncasement(data)),
  updateEncasement: (item, data) => dispatch(updateEncasement(item, data)),
  deleteEncasement: item => dispatch(deleteEncasement(item)),
  postDisbursement: data => dispatch(createDisbursement(data)),
  updateDisbursement: (item, data) => dispatch(updateDisbursement(item, data)),
  deleteDisbursement: item => dispatch(deleteDisbursement(item)),
  resetCreateEncasement: () => {
    dispatch(successEncasement(null));
    dispatch(loadingEncasement(false));
    dispatch(errorEncasement(null));
  },
  resetUpdateEncasement: () => dispatch(resetUpdateEncasement()),
  resetDeleteEncasement: () => {
    dispatch(successDeleteEncasement(null));
    dispatch(loadingDeleteEncasement(false));
    dispatch(errorDeleteEncasement(null));
  },
  resetCreateDisbursement: () => {
    dispatch(successDisbursement(null));
    dispatch(loadingDisbursement(false));
    dispatch(errorDisbursement(null));
  },
  resetUpdateDisbursement: () => dispatch(resetUpdateDisbursement()),
  resetDeleteDisbursement: () => {
    dispatch(successDeleteDisbursement(null));
    dispatch(loadingDeleteDisbursement(false));
    dispatch(errorDeleteDisbursement(null));
  },
  reset: () => {
    dispatch(resetSupplier());
    dispatch(resetBanks());
    dispatch(resetBudgets());
    dispatch(resetInvoices());
    dispatch(resetPayments());
    dispatch(resetListEncasement());
    dispatch(resetListDisbursement());
  },
});

const mapStateToProps = state => ({
  listSupplier: state.supplier.list.data,
  loadingSupplier: state.supplier.list.loading,
  errorSupplier: state.supplier.list.error,

  retrievedBanks: state.companySettings.show.retrieved,
  loadingBanks: state.companySettings.show.loading,
  errorBanks: state.companySettings.show.error,

  listBudget: state.budget.list.data,
  loadingBudget: state.budget.list.loading,
  errorBudget: state.budget.list.error,

  listInvoice: state.invoice.list.data,
  loadingInvoice: state.invoice.list.loading,
  errorInvoice: state.invoice.list.error,

  listPayment: state.payment.list.data,
  loadingPayment: state.payment.list.loading,
  errorPayment: state.payment.list.error,

  listEncasement: state.encasement.list.data,
  loadingListEncasement: state.encasement.list.loading,
  errorListEncasement: state.encasement.list.error,

  createdEncasement: state.encasement.create.created,
  loadingCreateEncasement: state.encasement.create.loading,
  errorCreateEncasement: state.encasement.create.error,

  updatedEncasement: state.encasement.update.updated,
  loadingUpdateEncasement: state.encasement.update.updateLoading,
  errorUpdateEncasement: state.encasement.update.updateError,

  deletedEncasement: state.encasement.del.deleted,
  loadingDeleteEncasement: state.encasement.del.loading,
  errorDeleteEncasement: state.encasement.del.error,

  listDisbursement: state.disbursement.list.data,
  loadingListDisbursement: state.disbursement.list.loading,
  errorListDisbursement: state.disbursement.list.error,

  createdDisbursement: state.disbursement.create.created,
  loadingCreateDisbursement: state.disbursement.create.loading,
  errorCreateDisbursement: state.disbursement.create.error,

  updatedDisbursement: state.disbursement.update.updated,
  loadingUpdateDisbursement: state.disbursement.update.updateLoading,
  errorUpdateDisbursement: state.disbursement.update.updateError,

  deletedDisbursement: state.disbursement.del.deleted,
  loadingDeleteDisbursement: state.disbursement.del.loading,
  errorDeleteDisbursement: state.disbursement.del.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(TreasuryHome);

export default withTranslation()(withRouter(Main));
