import React from 'react';
import { Header, Form, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { find, isEmpty } from 'lodash';
import { retrieve as getRole, reset as resetRole } from 'actions/role/show';
import { reset as resetListRole } from 'actions/role/list';
import { update as updateRole, reset as resetUpdateRole } from 'actions/role/update';
import { create as createRole, success as resetCreateRole } from 'actions/role/create';
import { connect } from 'react-redux';
import { EssorButton, RoleEdit } from 'components';
import NotFound from '../../../../404';

class EditRole extends React.Component {
  state = {
    role: null,
    rights: [],
    notFound: false,
    name: null,
    errorNameRight: false,
  }

  componentDidMount() {
    const { match, listRole, getRole } = this.props;
    if (match.params.id) {
      if (find(listRole['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getRole(`/roles/${match.params.id}`);
      } else {
        this.setState({
          notFound: true,
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedRole)
      && nextProps.retrievedRole !== prevState.role && nextProps.match.params.id) {
      return {
        role: nextProps.retrievedRole,
        rights: nextProps.retrievedRole.rights,
        name: nextProps.retrievedRole.name,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { createdRole, resetListRole, updatedRole } = this.props;
    if (!isEmpty(createdRole) && prevProps.createdRole !== createdRole) {
      resetListRole();
    }

    if (!isEmpty(updatedRole) && prevProps.updatedRole !== updatedRole) {
      resetListRole();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleChangeInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (rights) => {
    const { createRole, selectedCompany, updateRole } = this.props;
    const { name, role } = this.state;

    this.setState({
      errorNameRight: false,
    });


    let isValid = true;

    if (name === null) {
      isValid = false;
      this.setState({
        errorNameRight: true,
      });
    }

    if (!isValid) return;

    const data = {
      name,
      company: selectedCompany['@id'],
      rights: {
        ...rights,
      },
    };

    if (role) {
      updateRole(role, data);
    } else {
      createRole(data);
    }
  }

  render() {
    const {
      t,
      match,
      loadingRetrievedRole,
      createdRole,
      loadingCreateRole,
      loadingUpdateRole,
      updatedRole,
    } = this.props;
    const { notFound, errorNameRight, rights, role, name } = this.state;

    const create = !match.params.id;

    if (notFound) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (createdRole || updatedRole) {
      return (
        <Redirect
          push
          to={createdRole ? `/company/settings/roles/${createdRole.id}` : `/company/settings/roles/${updatedRole.id}`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">

          <div className="option-buttons-container clearfix">
            <Header as="h3">{create ? t('roleTitleCreate') : t('roleTitleUpdate')}</Header>

            <EssorButton
              as={Link}
              to={match.params.id ? `/company/settings/roles/${match.params.id}` : '/company/settings/roles'}
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Segment basic>
            <Dimmer
              active={
                loadingRetrievedRole
                || loadingCreateRole
                || loadingUpdateRole
              }
              inverted
            >
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            {
              (create || !isEmpty(role))
              && (
                <React.Fragment>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        label={t('formRole')}
                        fluid
                        placeholder={t('formPHRole')}
                        onChange={this.handleChangeInput}
                        name="name"
                        value={name || ''}
                        error={errorNameRight}
                      />
                    </Form.Group>
                  </Form>
                  <RoleEdit
                    rights={rights}
                    getRights={data => this.handleSubmit(data)}
                  />
                </React.Fragment>
              )
            }
          </Segment>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRole: api => dispatch(getRole(api)),
  createRole: data => dispatch(createRole(data)),
  updateRole: (item, data) => dispatch(updateRole(item, data)),
  resetListRole: () => dispatch(resetListRole()),
  reset() {
    dispatch(resetRole());
    dispatch(resetUpdateRole());
    dispatch(resetCreateRole(null));
  },
});

const mapStateToProps = state => ({
  listRole: state.role.list.data,

  retrievedRole: state.role.show.retrieved,
  loadingRetrievedRole: state.role.show.loading,

  createdRole: state.role.create.created,
  loadingCreateRole: state.role.create.loading,

  updatedRole: state.role.update.updated,
  loadingUpdateRole: state.role.update.updateLoading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditRole);

export default withTranslation()(withRouter(Main));
