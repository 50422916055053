import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Grid, Form } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { dateFormat } from 'utils/formatter';

class ShowSubscriptionData extends Component {
  state = {
    value: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'SUBSCRIPTION_DATA'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, selectedOffice, t } = this.props;

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('officesSubscriptionData')}</Header>
            <EssorButton as={Link} to={`/offices/${selectedOffice.id}/settings/subscription-data/edit`} type="edit" size="tiny" floated="right">
              {t('buttonEdit')}
            </EssorButton>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={loading} size="small">
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formSubscriptionAmount')}</label>
                      <h5 className="informative-field">
                        {value && (value.subscriptionAmount === '' ? '-' : value.subscriptionAmount)}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formAccreditationAmount')}</label>
                      <h5 className="informative-field">
                        {value && (value.accreditationAmount === '' ? '-' : value.accreditationAmount)}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formRenewDate')}</label>
                      <h5 className="informative-field">
                        {value && (value.renewDate === null ? '-' : dateFormat(value.renewDate))}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formStartDate')}</label>
                      <h5 className="informative-field">
                        {value && (value.startDate === null ? '-' : dateFormat(value.startDate))}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formMembershipLevy')}</label>
                      <h5 className="informative-field">
                        {value && (value.membershipLevy === '' ? '-' : value.membershipLevy)}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formTimeCreditBase')}</label>
                      <h5 className="informative-field">
                        {value && (value.timeCreditBase === '' ? '-' : value.timeCreditBase)}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formTimeCreditUsed')}</label>
                      <h5 className="informative-field">
                        {value && (value.timeCreditUsed === '' ? '-' : value.timeCreditUsed)}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.officeSettings.show.error,
  loading: state.officeSettings.show.loading,
  retrieved: state.officeSettings.show.retrieved,

  selectedOffice: state.userCompanies.select.selectedOffice,
});

const Main = connect(mapStateToProps)(ShowSubscriptionData);

export default withTranslation()(withRouter(Main));
