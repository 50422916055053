import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CellButton, { ExtendProps } from './CellButton';
import { crudRights } from '../../types/accessRights';

const DeleteCellButton: FC<ExtendProps> = ({ tip, ...others }) => {
  const { t } = useTranslation();
  return (
    <CellButton
      icon="trash alternate"
      tip={tip || t('delete')}
      right={crudRights.delete}
      {...others}
    />
  );
};

export default DeleteCellButton;
