import React, { Component } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Table, Checkbox, Input, Label, Grid, Form, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { floatFormat, inputFormat, percentageFormat } from 'utils/formatter';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import classnames from 'classnames';

class PricesTable extends Component {
  state = {
    globalPercentage: 0,
  };

  handleInputChange = (e) => {
    const { value } = e.target;

    this.setState({
      globalPercentage: inputFormat(value),
    });
  };

  render() {
    const { globalPercentage } = this.state;
    const { data, onChange, onChecked, percentageApply, loading, t } = this.props;

    const allowedGlobal = find(data, 'checked');

    return (
      <React.Fragment>
        <Grid
          style={{
            marginTop: '0',
          }}
        >
          <Grid.Row
            style={{
              padding: '0',
            }}
          >
            <Grid.Column width={10}>
              <Form className="margin-top-bot main-form" size="small">
                <Form.Group inline>
                  <Form.Field>
                    <label>{t('formGlobalPercentage')}</label>
                    <Input
                      disabled={loading}
                      labelPosition="right"
                      fluid
                    >
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        name="updatedCost"
                        value={inputFormat(globalPercentage, true)}
                        onChange={this.handleInputChange}
                      />
                      <Label>%</Label>
                    </Input>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={3}>
              <EssorButton
                type="check"
                size="tiny"
                disabled={!allowedGlobal || loading}
                onClick={() => percentageApply(globalPercentage)}
              >
                {t('buttonApply')}
              </EssorButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment
          basic
          className={classnames('table-container', {
            'is-loading': loading,
          })}
        >
          <Dimmer active={loading} inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
          <Table celled structured className="margin-bot">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>{t('formReference')}</Table.HeaderCell>
                <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                <Table.HeaderCell>{t('contactsLastUpdate')}</Table.HeaderCell>
                <Table.HeaderCell>{t('contactsCurrentPrice')}</Table.HeaderCell>
                <Table.HeaderCell>{t('contactsUpdatePrice')}</Table.HeaderCell>
                <Table.HeaderCell>%</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data && data.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={item.checked}
                      onChange={() => onChecked(item)}
                    />
                  </Table.Cell>
                  <Table.Cell>{item.reference}</Table.Cell>
                  <Table.Cell>{item.label}</Table.Cell>
                  <Table.Cell>{moment(item.lastUpdated).format('DD/MM/YYYY')}</Table.Cell>
                  <Table.Cell className="format-number-fr">
                    {floatFormat(item.cost, true)}
                  </Table.Cell>
                  <Table.Cell className="format-number-fr custom-cell">
                    {item.checked
                      ? (
                        <Input>
                          <Cleave
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'none',
                              numeralDecimalScale: 2,
                              numeralDecimalMark: ',',
                            }}
                            name="updatedCost"
                            value={inputFormat(item.updatedCost, true)}
                            onChange={e => onChange(e, item)}
                          />
                        </Input>
                      )
                      : floatFormat(item.updatedCost, true)
                    }
                  </Table.Cell>
                  <Table.Cell className="format-number-fr custom-cell">
                    {item.checked
                      ? (
                        <Input labelPosition="left" fluid>
                          <Label>%</Label>
                          <Cleave
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'none',
                              numeralDecimalScale: 2,
                              numeralDecimalMark: ',',
                            }}
                            name="percentage"
                            value={inputFormat(item.percentage, true)}
                            onChange={e => onChange(e, item)}
                          />
                        </Input>
                      )
                      : percentageFormat(item.percentage)
                    }
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(PricesTable));
