import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorTemplate from './ErrorTemplate';
import Routes from '../../routes/routes';

const WrongDocument: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      title={t('notFoundTitle')}
      body={t('notFoundDocBody')}
      tryTo={t('notFoundTry')}
      solutions={[
        {
          title: t('notFoundBack'),
          onClick: (): void => {
            window.history.back();
          },
        },
        {
          title: t('notFoundBusiness'),
          href: Routes.Business.home,
        },
      ]}
    />
  );
};

export default WrongDocument;
