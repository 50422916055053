import { combineReducers } from 'redux';
import create from './create';
import list from './list';
import update from './update';
import show from './show';
import print from './print';

export default combineReducers({
  create, list, update, show, print,
});
