import { Icon } from 'semantic-ui-react';
import React, { FC } from 'react';
import SubNavLink from './SubNavLink';
import { CheckAccessRights } from '../../types/store';
import useAccessRights from '../../hooks/useAccessRights';
import { crudRights } from '../../types/accessRights';
import { Route } from '../../routes/routes';

interface OwnProps extends CheckAccessRights {
  children?: React.ReactNode;
  create?: Route;
  hide?: boolean;
  to: Route;
  title?: string;
}

/**
 * Allow entity/right check on "add" button only
 */
const SubNavLinkWithButton: FC<OwnProps> = (props) => {
  const { children, create, to, hide, entity, title } = props;
  const createAccess = entity ? useAccessRights(entity, crudRights.create) : true;
  const readAccess = entity ? useAccessRights(entity, crudRights.read) : true;

  if (hide || !readAccess) {
    return <React.Fragment />;
  }

  const createRoute = `${to}/create` as unknown as Route;

  return (
    <li>
      {createAccess && (
        <SubNavLink to={create || createRoute} className="menu-item add" noLi>
          <Icon name="plus circle" />
        </SubNavLink>
      )}
      <SubNavLink to={to} noLi>{title || children}</SubNavLink>
    </li>
  );
};

SubNavLinkWithButton.defaultProps = {
  hide: false,
};

export default SubNavLinkWithButton;
