import React, { Component } from 'react';
import { list as listDocument, reset as resetDocument } from 'actions/document/list';
import { list as listSettings, reset as resetSettings } from 'actions/company-settings/list';
import { Header, Form, Dropdown, Grid, Message, Input, Table } from 'semantic-ui-react';
import { isEmpty, groupBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { EssorButton } from 'components';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { floatFormat, percentageFormat } from 'utils/formatter';

class Margins extends Component {
  state = {
    selectedItem: null,
    firstPeriodFrom: null,
    firstPeriodTo: null,
    secondPeriodFrom: null,
    secondPeriodTo: null,
    selectedItemError: false,
    firstPeriodFromError: false,
    firstPeriodToError: false,
    secondPeriodFromError: false,
    secondPeriodToError: false,
    compare: false,
    arrayError: [],
    cleaves: [],
    firstList: [],
    secondList: [],
    reasonList: {},
  };

  componentDidMount() {
    const { getReasons, selectedCompany } = this.props;

    getReasons(`/company_settings?company=${selectedCompany.id}&name=OFFER_REASONS`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listDocument) && nextProps.listDocument['hydra:member'] !== prevState.firstList) {
      return ({
        firstList: nextProps.listDocument['hydra:member'],
      });
    }

    if (!isEmpty(nextProps.secondListDocument) && nextProps.secondListDocument['hydra:member'] !== prevState.secondList) {
      return ({
        secondList: nextProps.secondListDocument['hydra:member'],
      });
    }

    if (!isEmpty(nextProps.listReasons) && nextProps.listReasons['hydra:member'][0] !== prevState.reasonList) {
      return ({
        reasonList: nextProps.listReasons['hydra:member'][0],
      });
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  onFirstCleaveInit = (cleave) => {
    this.setState({
      firstCleave: cleave,
    });
  };

  onSecondCleaveInit = (cleave) => {
    this.setState({
      secondCleave: cleave,
    });
  };

  getLabel = (second = false) => {
    const { firstPeriodFrom, firstPeriodTo, secondPeriodFrom, secondPeriodTo } = this.state;
    const { t } = this.props;
    const months = [
      'januaryAbr',
      'februaryAbr',
      'marchAbr',
      'aprilAbr',
      'mayAbr',
      'juneAbr',
      'julyAbr',
      'augustAbr',
      'septemberAbr',
      'octoberAbr',
      'novemberAbr',
      'decemberAbr',
    ];

    let from;
    let to;

    if (second) {
      from = secondPeriodFrom.split('/');
      to = secondPeriodTo.split('/');
    } else {
      from = firstPeriodFrom.split('/');
      to = firstPeriodTo.split('/');
    }

    return `${t(months[parseInt(from[0], 10) - 1])} ${from[1]} - ${t(months[parseInt(to[0], 10) - 1])} ${to[1]}`;
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isValid = true;

    this.setState({
      firstPeriodFromError: false,
      firstPeriodToError: false,
      secondPeriodFromError: false,
      secondPeriodToError: false,
    });

    if (value.length < 7) {
      isValid = false;
    }

    this.setState({
      [name]: value,
      [`${name}Error`]: !isValid,
    }, () => {
      this.autoCompleteDate();
    });
  };

  autoCompleteDate = () => {
    const { secondPeriodFrom, firstPeriodTo, firstPeriodFrom } = this.state;

    if (secondPeriodFrom && firstPeriodTo && firstPeriodFrom && firstPeriodTo.length === 7
      && secondPeriodFrom.length === 7 && firstPeriodFrom.length === 7) {
      const firstFrom = this.parseDate(firstPeriodFrom);
      const firstTo = this.parseDate(firstPeriodTo);
      const secondFrom = this.parseDate(secondPeriodFrom);

      const x = firstTo.diff(firstFrom, 'month');

      const dateFinal = secondFrom.add(x, 'M');

      this.setState({
        secondPeriodTo: moment(dateFinal).format('MM/YYYY'),
      });
    }
  };

  handleCheckBoxChange = (e, { name }) => {
    this.setState(prevState => ({
      [name]: !prevState[name],
      secondPeriodFromError: false,
      secondPeriodToError: false,
      secondPeriodFrom: null,
      secondPeriodTo: null,
    }));
  };

  handleSelectChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  parseDate = (date, end = false) => {
    const arr = date.split('/');

    if (end) {
      return moment([parseInt(arr[1], 10), (parseInt(arr[0], 10) - 1)]).endOf('month');
    }

    return moment([parseInt(arr[1], 10), (parseInt(arr[0], 10) - 1)]);
  };

  handleOnSubmit = () => {
    const {
      selectedItem,
      firstPeriodFrom,
      firstPeriodTo,
      secondPeriodFrom,
      secondPeriodTo,
      compare,
    } = this.state;

    const { getDocuments, selectedCompany } = this.props;

    this.setState({
      selectedItemError: false,
      firstPeriodFromError: false,
      firstPeriodToError: false,
      secondPeriodFromError: false,
      secondPeriodToError: false,
      arrayError: [],
    });

    let validDates = true;

    if (!selectedItem) {
      validDates = false;

      this.setState({
        selectedItemError: true,
      });
    }

    if (firstPeriodFrom === '' || firstPeriodFrom === null) {
      validDates = false;
      this.setState({
        firstPeriodFromError: true,
      });
    }

    if (firstPeriodTo === '' || firstPeriodTo === null) {
      validDates = false;
      this.setState({
        firstPeriodToError: true,
      });
    }

    if (compare) {
      if (secondPeriodFrom === '' || secondPeriodFrom === null) {
        validDates = false;
        this.setState({
          secondPeriodFromError: true,
        });
      }
    }

    if (!validDates) return;

    const arrayError = [];
    let isValidPeriod1 = true;
    let isValidPeriods = true;

    const firstFrom = this.parseDate(firstPeriodFrom);
    const firstTo = this.parseDate(firstPeriodTo, true);
    let secondFrom;
    let secondTo;

    if (compare) {
      secondFrom = this.parseDate(secondPeriodFrom);
      secondTo = this.parseDate(secondPeriodTo, true);
    }

    if (firstFrom.isAfter(firstTo, 'month')) {
      arrayError.push('priceAverageFirstPeriodError1');
      isValidPeriod1 = false;
    } else if (firstFrom.isSame(firstTo, 'month')) {
      arrayError.push('priceAverageFirstPeriodError2');
      isValidPeriod1 = false;
    }

    if (!isValidPeriod1) {
      this.setState({
        firstPeriodFromError: true,
        firstPeriodToError: true,
      });
    }

    if (compare && isValidPeriod1) {
      if (secondFrom.isBetween(firstFrom, firstTo, 'month', '[]')
        || secondTo.isBetween(firstFrom, firstTo, 'month', '[]')
        || firstFrom.isBetween(secondFrom, secondTo, 'month', '[]')) {
        arrayError.push('priceAverageAllPeriodError');
        isValidPeriods = false;
      }

      if (!isValidPeriods) {
        this.setState({
          secondPeriodFromError: true,
        });
      }
    }

    if (!isValidPeriod1 || !isValidPeriods) {
      this.setState({
        arrayError,
      });
      return;
    }

    getDocuments(`/${selectedItem}?company=${selectedCompany.id}&creationDate[after]=${firstFrom.format('YYYY-MM-DD')}&creationDate[before]=${firstTo.format('YYYY-MM-DD')}&pagination=false`);

    if (compare) {
      getDocuments(`/${selectedItem}?company=${selectedCompany.id}&creationDate[after]=${secondFrom.format('YYYY-MM-DD')}&creationDate[before]=${secondTo.format('YYYY-MM-DD')}&pagination=false`, true);
    }
  };

  handleReset = () => {
    const { firstCleave, secondCleave } = this.state;
    const { resetDocumentList } = this.props;

    this.setState({
      selectedItem: null,
      firstPeriodFrom: null,
      firstPeriodTo: null,
      secondPeriodFrom: null,
      secondPeriodTo: null,
      compare: false,
      firstList: [],
      secondList: [],
    });

    firstCleave.setRawValue('');
    secondCleave.setRawValue('');

    resetDocumentList();
  };

  render() {
    const {
      selectedItem,
      selectedItemError,
      firstPeriodFromError,
      firstPeriodToError,
      secondPeriodFromError,
      secondPeriodToError,
      secondPeriodTo,
      arrayError,
      compare,

      firstList,
      secondList,
      reasonList,
    } = this.state;

    const {
      selectedCompany,
      listDocument,
      secondListDocument,
      listLoading,
      secondListLoading,
      loadingReasons,
      t,
    } = this.props;

    const showComparison = !isEmpty(listDocument)
      && (compare ? !isEmpty(secondListDocument) : true);
    const reasons = reasonList.value;

    const options = [
      {
        text: t('documentNumbering_QUOTE'),
        value: 'quotes',
      },
      {
        text: t('documentNumbering_PURCHASE_ORDER'),
        value: 'purchase_orders',
      },
      {
        text: t('documentNumbering_INVOICE'),
        value: 'invoices',
      },
    ];

    const firstListValues = {
      labourHours: 0,
      labourTurnover: 0,

      subcontratingHours: 0,
      subcontratingTurnover: 0,

      rawMaterialTotalCost: 0,
      rawMaterialTurnover: 0,

      goodsTotalCost: 0,
      goodsTurnover: 0,

      totalBeforeTaxes: 0,
      totalWithTaxes: 0,
    };
    const secondListValues = {
      labourHours: 0,
      labourTurnover: 0,

      subcontratingHours: 0,
      subcontratingTurnover: 0,

      rawMaterialTotalCost: 0,
      rawMaterialTurnover: 0,

      goodsTotalCost: 0,
      goodsTurnover: 0,

      totalBeforeTaxes: 0,
      totalWithTaxes: 0,
    };
    let firstListBreakdown = [];
    let secondListBreakdown = [];

    if (!isEmpty(firstList)) {
      firstList.forEach((document) => {
        document.content.body.forEach((item) => {
          switch (item.soldAs) {
            case 'md':
              firstListValues.goodsTotalCost += parseFloat(item.unitCost) * item.quantity;
              firstListValues.goodsTurnover += parseFloat(item.totalPrice);
              break;
            case 'mp':
              firstListValues.rawMaterialTotalCost += parseFloat(item.unitCost) * item.quantity;
              firstListValues.rawMaterialTurnover += parseFloat(item.totalPrice);
              break;
            case 'h':
              firstListValues.labourHours += parseFloat(item.quantity);
              firstListValues.labourTurnover += parseFloat(item.totalPrice);
              break;
            case 'hst':
              firstListValues.subcontratingHours += parseFloat(item.quantity);
              firstListValues.subcontratingTurnover += parseFloat(item.totalPrice);
              break;
            default: break;
          }
        });

        firstListValues.totalWithTaxes
          += parseFloat(document.totalPrice) + parseFloat(document.totalVAT);
        firstListValues.totalBeforeTaxes += parseFloat(document.totalPrice);
      });

      let groupedList = [];

      switch (selectedItem) {
        case 'quotes':
          groupedList = groupBy(firstList, 'quoteReason');
          break;
        case 'purchase_orders':
          groupedList = groupBy(firstList, 'orderReason');
          break;
        case 'invoices':
          groupedList = groupBy(firstList, 'invoiceReason');
          break;
        default: break;
      }

      firstListBreakdown = Object.keys(groupedList)
        .filter(key => key !== '0' && key !== 'null')
        .map((item) => {
          let reason;
          let totalBeforeTaxes = 0;

          switch (selectedItem) {
            case 'quotes':
              reason = groupedList[item][0].quoteReason;
              break;
            case 'purchase_orders':
              reason = groupedList[item][0].orderReason;
              break;
            case 'invoices':
              reason = groupedList[item][0].invoiceReason;
              break;
            default: break;
          }

          let promTotalService = 0;
          let promTotalProduct = 0;

          for (let i = 0; i < groupedList[item].length; i++) {
            let promService = 0;
            let serviceQuantity = 0;
            let promProduct = 0;
            let productQuantity = 0;

            totalBeforeTaxes += parseFloat(groupedList[item][i].totalPrice);

            for (let x = 0; x < groupedList[item][i].content.body.length; x++) {
              switch (groupedList[item][i].content.body[x].type) {
                case 'Service':
                  if (groupedList[item][i].content.body[x].soldAs === 'h') {
                    serviceQuantity += 1;
                    promService
                      += groupedList[item][i].content.body[x].totalPrice
                      / groupedList[item][i].content.body[x].quantity;
                  }
                  break;
                case 'Product':
                  productQuantity += 1;
                  promProduct
                    += groupedList[item][i].content.body[x].totalPrice
                    / (groupedList[item][i].content.body[x].unitCost
                    * groupedList[item][i].content.body[x].quantity);
                  break;
                default: break;
              }
            }

            promTotalService += serviceQuantity !== 0
              ? promService / serviceQuantity
              : 0;
            promTotalProduct += productQuantity !== 0
              ? promProduct / productQuantity
              : 0;
          }

          const averageLabour = promTotalService / groupedList[item].length;
          const averageMargin = promTotalProduct / groupedList[item].length;
          reason = reasons.find(item => item.id === reason);

          return ({
            reason: reason.label,
            totalBeforeTaxes,
            quantity: groupedList[item].length,
            averageLabour,
            averageMargin,
          });
        });
    }

    if (!isEmpty(secondList)) {
      secondList.forEach((document) => {
        document.content.body.forEach((item) => {
          switch (item.soldAs) {
            case 'md':
              secondListValues.goodsTotalCost += parseFloat(item.unitCost) * item.quantity;
              secondListValues.goodsTurnover += parseFloat(item.totalPrice);
              break;
            case 'mp':
              secondListValues.rawMaterialTotalCost += parseFloat(item.unitCost) * item.quantity;
              secondListValues.rawMaterialTurnover += parseFloat(item.totalPrice);
              break;
            case 'h':
              secondListValues.labourHours += parseFloat(item.quantity);
              secondListValues.labourTurnover += parseFloat(item.totalPrice);
              break;
            case 'hst':
              secondListValues.subcontratingHours += parseFloat(item.quantity);
              secondListValues.subcontratingTurnover += parseFloat(item.totalPrice);
              break;
            default: break;
          }
        });

        secondListValues.totalWithTaxes
          += parseFloat(document.totalPrice) + parseFloat(document.totalVAT);
        secondListValues.totalBeforeTaxes += parseFloat(document.totalPrice);
      });

      let groupedList = [];

      switch (selectedItem) {
        case 'quotes':
          groupedList = groupBy(secondList, 'quoteReason');
          break;
        case 'purchase_orders':
          groupedList = groupBy(secondList, 'orderReason');
          break;
        case 'invoices':
          groupedList = groupBy(secondList, 'invoiceReason');
          break;
        default: break;
      }

      secondListBreakdown = Object.keys(groupedList)
        .filter(key => key !== '0' && key !== 'null')
        .map((item) => {
          let reason;
          let totalBeforeTaxes = 0;

          switch (selectedItem) {
            case 'quotes':
              reason = groupedList[item][0].quoteReason;
              break;
            case 'purchase_orders':
              reason = groupedList[item][0].orderReason;
              break;
            case 'invoices':
              reason = groupedList[item][0].invoiceReason;
              break;
            default: break;
          }

          let promTotalService = 0;
          let promTotalProduct = 0;

          for (let i = 0; i < groupedList[item].length; i++) {
            let promService = 0;
            let serviceQuantity = 0;
            let promProduct = 0;
            let productQuantity = 0;

            totalBeforeTaxes += parseFloat(groupedList[item][i].totalPrice);

            for (let x = 0; x < groupedList[item][i].content.body.length - 1; x++) {
              switch (groupedList[item][i].content.body[x].type) {
                case 'Service':
                  if (groupedList[item][i].content.body[x].soldAs === 'h') {
                    serviceQuantity += 1;
                    promService
                      += groupedList[item][i].content.body[x].totalPrice
                      / groupedList[item][i].content.body[x].quantity;
                  }
                  break;
                case 'Product':
                  productQuantity += 1;
                  promProduct
                    += groupedList[item][i].content.body[x].totalPrice
                    / (groupedList[item][i].content.body[x].unitCost
                    * groupedList[item][i].content.body[x].quantity);
                  break;
                default: break;
              }
            }

            promTotalService += serviceQuantity !== 0
              ? promService / serviceQuantity
              : 0;
            promTotalProduct += productQuantity !== 0
              ? promProduct / productQuantity
              : 0;
          }

          const averageLabour = promTotalService / groupedList[item].length;
          const averageMargin = promTotalProduct / groupedList[item].length;
          reason = reasons.find(item => item.id === reason);

          return ({
            reason: reason.label,
            totalBeforeTaxes,
            quantity: groupedList[item].length,
            averageLabour,
            averageMargin,
          });
        });
    }

    firstListValues.goodsMarginTotal = firstListValues.goodsTurnover
      - firstListValues.goodsTotalCost;
    if (selectedCompany.calculationMode === 'rate' && firstListValues.goodsTurnover !== 0) {
      firstListValues.goodsMargin = 100
        - (firstListValues.goodsTotalCost / firstListValues.goodsTurnover);
    } else if (selectedCompany.calculationMode === 'coef' && firstListValues.goodsTotalCost !== 0) {
      firstListValues.goodsMargin = firstListValues.goodsTurnover / firstListValues.goodsTotalCost;
    } else {
      firstListValues.goodsMargin = 0;
    }

    firstListValues.rawMaterialMarginTotal = firstListValues.rawMaterialTurnover
      - firstListValues.rawMaterialTotalCost;
    if (selectedCompany.calculationMode === 'rate' && firstListValues.rawMaterialTurnover !== 0) {
      firstListValues.rawMaterialMargin = 100
        - (firstListValues.rawMaterialTotalCost / firstListValues.rawMaterialTurnover);
    } else if (selectedCompany.calculationMode === 'coef' && firstListValues.rawMaterialTotalCost !== 0) {
      firstListValues.rawMaterialMargin = firstListValues.rawMaterialTurnover
        / firstListValues.rawMaterialTotalCost;
    } else {
      firstListValues.rawMaterialMargin = 0;
    }

    firstListValues.labourHourlyRate = firstListValues.labourHours !== 0
      ? firstListValues.labourTurnover / firstListValues.labourHours
      : 0;
    firstListValues.subcontratingHourlyRate = firstListValues.subcontratingHours !== 0
      ? firstListValues.subcontratingTurnover / firstListValues.subcontratingHours
      : 0;

    secondListValues.goodsMarginTotal = secondListValues.goodsTurnover
      - secondListValues.goodsTotalCost;
    if (selectedCompany.calculationMode === 'rate' && secondListValues.goodsTurnover !== 0) {
      secondListValues.goodsMargin = 100
        - (secondListValues.goodsTotalCost / secondListValues.goodsTurnover);
    } else if (selectedCompany.calculationMode === 'coef' && secondListValues.goodsTotalCost !== 0) {
      secondListValues.goodsMargin = secondListValues.goodsTurnover
        / secondListValues.goodsTotalCost;
    } else {
      secondListValues.goodsMargin = 0;
    }

    secondListValues.rawMaterialMarginTotal = secondListValues.rawMaterialTurnover
      - secondListValues.rawMaterialTotalCost;
    if (selectedCompany.calculationMode === 'rate' && secondListValues.rawMaterialTurnover !== 0) {
      secondListValues.rawMaterialMargin = 100
        - (secondListValues.rawMaterialTotalCost / secondListValues.rawMaterialTurnover);
    } else if (selectedCompany.calculationMode === 'coef' && secondListValues.rawMaterialTotalCost !== 0) {
      secondListValues.rawMaterialMargin = secondListValues.rawMaterialTurnover
        / secondListValues.rawMaterialTotalCost;
    } else {
      secondListValues.rawMaterialMargin = 0;
    }

    secondListValues.labourHourlyRate = secondListValues.labourHours !== 0
      ? secondListValues.labourTurnover / secondListValues.labourHours
      : 0;
    secondListValues.subcontratingHourlyRate = secondListValues.subcontratingHours !== 0
      ? secondListValues.subcontratingTurnover / secondListValues.subcontratingHours
      : 0;

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('analysisMarginsTitle')}
            </Header>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={listLoading || secondListLoading} size="small">
                  <Form.Group inline>
                    <Form.Select
                      label={t('documentNumberingType')}
                      control={Dropdown}
                      placeholder={t('formPHSelect')}
                      selectOnBlur={false}
                      search
                      selection
                      noResultsMessage="No results"
                      options={options}
                      name="selectedItem"
                      value={selectedItem}
                      onChange={this.handleSelectChange}
                      error={selectedItemError}
                      disabled={showComparison}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field className="multiple-inputs">
                      <label>
                        {t('analysisSelectPeriod')}
                      </label>
                      <Input
                        error={firstPeriodFromError}
                        disabled={showComparison}
                      >
                        <Cleave
                          options={{
                            date: true,
                            numeralThousandsGroupStyle: 'none',
                            datePattern: ['m', 'Y'],
                          }}
                          onChange={this.handleInputChange}
                          name="firstPeriodFrom"
                          placeholder={t('MM / YYYY')}
                          autoComplete="off"
                          onInit={this.onFirstCleaveInit}
                        />
                      </Input>
                      <label className="separator">
                        -
                      </label>
                      <Input
                        error={firstPeriodToError}
                        disabled={showComparison}
                      >
                        <Cleave
                          options={{
                            date: true,
                            numeralThousandsGroupStyle: 'none',
                            datePattern: ['m', 'Y'],
                          }}
                          onChange={this.handleInputChange}
                          name="firstPeriodTo"
                          placeholder={t('MM / YYYY')}
                          autoComplete="off"
                          onInit={this.onSecondCleaveInit}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('analysisCompare')}
                      name="compare"
                      checked={compare}
                      onChange={this.handleCheckBoxChange}
                      disabled={showComparison}
                    />
                  </Form.Group>

                  {compare
                  && (
                    <Form.Group inline>
                      <Form.Field className="multiple-inputs">
                        <label>
                          {t('analysisSelectPeriod')}
                        </label>
                        <Input
                          error={secondPeriodFromError}
                          disabled={showComparison}
                        >
                          <Cleave
                            options={{
                              date: true,
                              numeralThousandsGroupStyle: 'none',
                              datePattern: ['m', 'Y'],
                            }}
                            onChange={this.handleInputChange}
                            name="secondPeriodFrom"
                            placeholder={t('MM / YYYY')}
                            autoComplete="off"
                          />
                        </Input>
                        <label className="separator">
                          -
                        </label>
                        <Input
                          style={{
                            opacity: 1,
                          }}
                          error={secondPeriodToError}
                          disabled
                          name="secondPeriodTo"
                          placeholder={t('MM / YYYY')}
                          value={secondPeriodTo || ''}
                        />
                      </Form.Field>
                    </Form.Group>
                  )}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {!showComparison
          && (
            <div className="option-buttons-container clearfix">
              <EssorButton
                type="check"
                onClick={this.handleOnSubmit}
                size="small"
                floated="right"
                disabled={listLoading || secondListLoading || loadingReasons}
              >
                {t('buttonSearch')}
              </EssorButton>
            </div>
          )}

          {!isEmpty(arrayError)
          && (
            <Message negative>
              <Message.Header>{t('priceAverageHeaderPeriodError')}</Message.Header>
              <Message.List>
                {arrayError.map((error, i) => (
                  <Message.Item key={i}>{t(`${error}`)}</Message.Item>
                ))}
              </Message.List>
            </Message>
          )}

          {showComparison
          && (
            <React.Fragment>
              <Table celled className="definition-table margin-bot">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {this.getLabel()}
                    </Table.HeaderCell>
                    {compare
                    && (
                      <Table.HeaderCell
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {this.getLabel(true)}
                      </Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      {t('analysisNumberOfDocuments')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {firstList.length}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {secondList.length}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  {/* $Labour */}
                  <Table.Row>
                    <Table.Cell className="table-title">
                      {t('analysisLabour')}
                    </Table.Cell>
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisLabourHours')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {firstListValues.labourHours}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {secondListValues.labourHours}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisLabourHourlyRate')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.labourHourlyRate, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.labourHourlyRate, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisLabourTurnover')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.labourTurnover, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.labourTurnover, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell />
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  {/* $Subcontrating */}
                  <Table.Row>
                    <Table.Cell className="table-title">
                      {t('analysisSubcontrating')}
                    </Table.Cell>
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisSubcontratingHours')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {firstListValues.subcontratingHours}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {secondListValues.subcontratingHours}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisSubcontratingHourlyRate')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.subcontratingHourlyRate, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.subcontratingHourlyRate, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisSubcontratingTurnover')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.subcontratingTurnover, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.subcontratingTurnover, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell />
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  {/* $Raw material */}
                  <Table.Row>
                    <Table.Cell className="table-title">
                      {t('analysisRawMaterial')}
                    </Table.Cell>
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisRawMaterialTotalCost')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.rawMaterialTotalCost, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.rawMaterialTotalCost, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {`${t('analysisRawMaterial')} ${(selectedCompany.calculationMode === 'rate' ? t('forecastMarginRate') : t('forecastCoef')).toLowerCase()}`}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {selectedCompany.calculationMode === 'rate'
                        ? percentageFormat(firstListValues.rawMaterialMargin)
                        : floatFormat(firstListValues.rawMaterialMargin)
                      }
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {selectedCompany.calculationMode === 'rate'
                          ? percentageFormat(secondListValues.rawMaterialMargin)
                          : floatFormat(secondListValues.rawMaterialMargin)
                        }
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisRawMaterialMarginTotal')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.rawMaterialMarginTotal, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.rawMaterialMarginTotal, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisRawMaterialTurnover')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.rawMaterialTurnover, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.rawMaterialTurnover, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell />
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  {/* $Goods */}
                  <Table.Row>
                    <Table.Cell className="table-title">
                      {t('analysisGoods')}
                    </Table.Cell>
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisGoodsTotalCost')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.goodsTotalCost, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.goodsTotalCost, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {`${t('analysisGoods')} ${(selectedCompany.calculationMode === 'rate' ? t('forecastMarginRate') : t('forecastCoef')).toLowerCase()}`}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {selectedCompany.calculationMode === 'rate'
                        ? percentageFormat(firstListValues.goodsMargin)
                        : floatFormat(firstListValues.goodsMargin)
                      }
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {selectedCompany.calculationMode === 'rate'
                          ? percentageFormat(secondListValues.goodsMargin)
                          : floatFormat(secondListValues.goodsMargin)
                        }
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisGoodsMarginTotal')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.goodsMarginTotal, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.goodsMarginTotal, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('analysisGoodsTurnover')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.goodsTurnover, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.goodsTurnover, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell />
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  {/* $Totals */}
                  <Table.Row>
                    <Table.Cell className="table-title">
                      {t('companiesTotal')}
                    </Table.Cell>
                    <Table.Cell />
                    {compare
                    && (
                      <Table.Cell />
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('quoteNetTotalBeforeTaxes')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.totalBeforeTaxes, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.totalBeforeTaxes, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      {t('quoteTotalWithTaxes')}
                    </Table.Cell>
                    <Table.Cell className="format-number-fr">
                      {floatFormat(firstListValues.totalWithTaxes, true)}
                    </Table.Cell>
                    {compare
                    && (
                      <Table.Cell className="format-number-fr">
                        {floatFormat(secondListValues.totalWithTaxes, true)}
                      </Table.Cell>
                    )}
                  </Table.Row>
                </Table.Body>
              </Table>

              <div className="option-buttons-container clearfix margin-top margin-bot">
                <Header as="h4">
                  {this.getLabel()}
                </Header>
              </div>

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('formReason')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('quoteNetTotalBeforeTaxes')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('analysisNumberOfDocuments')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('analysisAverageLabour')}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t('analysisAverage')} ${(selectedCompany.calculationMode === 'rate' ? t('forecastMarginRate') : t('forecastCoef')).toLowerCase()}`}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {firstListBreakdown.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{item.reason}</Table.Cell>
                      <Table.Cell className="format-number-fr">{floatFormat(item.totalBeforeTaxes, true)}</Table.Cell>
                      <Table.Cell className="format-number-fr">{item.quantity}</Table.Cell>
                      <Table.Cell className="format-number-fr">{item.averageLabour}</Table.Cell>
                      <Table.Cell className="format-number-fr">{item.averageMargin}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              {compare
              && (
                <React.Fragment>
                  <div className="option-buttons-container clearfix margin-top margin-bot">
                    <Header as="h4">
                      {this.getLabel(true)}
                    </Header>
                  </div>

                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('formReason')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('quoteNetTotalBeforeTaxes')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('analysisNumberOfDocuments')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('analysisAverageLabour')}</Table.HeaderCell>
                        <Table.HeaderCell>
                          {`${t('analysisAverage')} ${(selectedCompany.calculationMode === 'rate' ? t('forecastMarginRate') : t('forecastCoef')).toLowerCase()}`}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {secondListBreakdown.map((item, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{item.reason}</Table.Cell>
                          <Table.Cell className="format-number-fr">
                            {floatFormat(item.totalBeforeTaxes, true)}
                          </Table.Cell>
                          <Table.Cell className="format-number-fr">
                            {item.quantity}
                          </Table.Cell>
                          <Table.Cell className="format-number-fr">
                            {floatFormat(item.averageLabour)}
                          </Table.Cell>
                          <Table.Cell className="format-number-fr">
                            {floatFormat(item.averageMargin)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </React.Fragment>
              )}

              <div className="option-buttons-container clearfix">
                <EssorButton
                  type="redo"
                  onClick={this.handleReset}
                  size="small"
                  floated="right"
                >
                  {t('analysisNewConsult')}
                </EssorButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDocuments: (page, compare) => dispatch(listDocument(page, compare)),
  getReasons: page => dispatch(listSettings(page)),
  resetDocumentList: () => dispatch(resetDocument()),
  reset: () => {
    dispatch(resetDocument());
    dispatch(resetSettings());
  },
});

const mapStateToProps = state => ({
  listDocument: state.document.list.data,
  listLoading: state.document.list.loading,

  secondListDocument: state.document.list.secondData,
  secondListLoading: state.document.list.secondLoading,

  listReasons: state.companySettings.list.data,
  loadingReasons: state.companySettings.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Margins);

export default withTranslation()(withRouter(Main));
