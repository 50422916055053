import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { reset as resetUpdateEmployeeData, update as updateEmployeeData } from 'actions/employee-data/update';
import { retrieved } from 'actions/employee-data/show';
import { Form, Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { getCurrency, getLocalFormat } from 'utils/formatter';
import { keyVal } from 'utils/functions';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import SaveButton from 'components/buttons/SaveButton';
import InlineInput from 'layouts/InlineInput';
import TwelveForm from 'layouts/TwelveForm';
import CurrencyCleave from 'components/input/CurrencyCleave';
import Big from 'big.js';

class UpdateEmployeeData extends Component {
  state = {
    employeeData: null,
    exploitationRate: 0,
    efficiencyRate: 0,
    employerTaxRate: 0,
    grossMonthlyPay: 0,
    grossAnnualBonus: 0,
    hoursToSell: false,

    exploitationError: false,
    efficiencyError: false,
    taxError: false,
    payError: false,
    bonusError: false,

    hasDataChanged: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedEmployeeData)
      && !isEmpty(nextProps.retrievedEmployeeData['hydra:member'])
      && nextProps.retrievedEmployeeData['hydra:member'][0] !== prevState.employeeData
    ) {
      const {
        exploitationRate,
        efficiencyRate,
        employerTaxRate,
        grossMonthlyPay,
        grossAnnualBonus,
        hoursToSell,
      } = nextProps.retrievedEmployeeData['hydra:member'][0];

      return {
        employeeData: nextProps.retrievedEmployeeData['hydra:member'][0],
        exploitationRate,
        efficiencyRate,
        employerTaxRate,
        grossMonthlyPay,
        grossAnnualBonus,
        hoursToSell,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate() {
    const { updated, setEmployeeData } = this.props;

    if (!isEmpty(updated)) {
      const employeeData = {};
      employeeData['hydra:member'] = [];
      employeeData['hydra:member'].push(updated);
      setEmployeeData(employeeData);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (stateKey, value) => {
    this.setState({
      [stateKey]: value,
      hasDataChanged: true,
    });
  };

  handleCheckBoxChange = (e, { name }) => {
    e.preventDefault();

    this.setState(prevState => (
      {
        [name]: !prevState[name],
        hasDataChanged: true,
      }
    ));
  };

  handleOnSubmit = () => {
    const {
      employeeData,
      exploitationRate,
      efficiencyRate,
      employerTaxRate,
      grossMonthlyPay,
      grossAnnualBonus,
      hoursToSell,
    } = this.state;

    const { updateEmployeeData } = this.props;

    let isValid = true;

    this.setState({
      exploitationError: false,
      efficiencyError: false,
      taxError: false,
      payError: false,
      bonusError: false,
    });

    if (hoursToSell) {
      if (exploitationRate === '' || (parseFloat(exploitationRate) > 100)) {
        isValid = false;

        this.setState({
          exploitationError: true,
        });
      }

      if (efficiencyRate === '' || (parseFloat(efficiencyRate) > 100)) {
        isValid = false;

        this.setState({
          efficiencyError: true,
        });
      }
    }

    if (grossMonthlyPay === '') {
      isValid = false;

      this.setState({
        payError: true,
      });
    }

    if (grossAnnualBonus === '') {
      isValid = false;

      this.setState({
        bonusError: true,
      });
    }

    if (employerTaxRate === '' || (parseFloat(employerTaxRate) > 100)) {
      isValid = false;

      this.setState({
        taxError: true,
      });
    }

    if (!isValid) return;

    const data = {
      hoursToSell,
      exploitationRate: Big(exploitationRate).toFixed(2),
      efficiencyRate: Big(efficiencyRate).toFixed(2),
      grossMonthlyPay: Big(grossMonthlyPay).toFixed(2),
      grossAnnualBonus: Big(grossAnnualBonus).toFixed(2),
      employerTaxRate: Big(employerTaxRate).toFixed(2),
      daysOff: Big(0).toFixed(2),
    };

    this.setState({
      hasDataChanged: false,
    });

    updateEmployeeData(employeeData, data);
  };

  render() {
    const {
      hoursToSell,
      exploitationRate,
      efficiencyRate,
      grossMonthlyPay,
      grossAnnualBonus,
      employerTaxRate,

      exploitationError,
      efficiencyError,
      payError,
      bonusError,
      taxError,
      hasDataChanged,
    } = this.state;

    const {
      updated,
      loadingEmployeeData,
      updateLoading,
      updateError,
      match,
      t,
    } = this.props;

    const backLink = `/employees/${match.params.id}/data-synthesis`;
    if (updated) {
      return <Redirect push to={keyVal('pathname', backLink)} />;
    }

    const cur = getCurrency();

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader title={t('employeeDataAndSynthesisUpdate')} to={backLink} />

        <TwelveForm loading={loadingEmployeeData || updateLoading}>
          <InlineInput error={taxError} title={t('formEmployerTaxRate')} label="%">
            <CurrencyCleave
              options={getLocalFormat()}
              onChange={value => this.handleInputChange('employerTaxRate', value)}
              placeholder={t('formPHEmployerTaxRate')}
              value={employerTaxRate}
            />
          </InlineInput>

          <InlineInput error={payError} title={t('formGrossMonthlyPay')} label={cur}>
            <CurrencyCleave
              value={grossMonthlyPay}
              placeholder={t('formPHGrossMonthlyPay')}
              onChange={value => this.handleInputChange('grossMonthlyPay', value)}
              options={getLocalFormat()}
            />
          </InlineInput>

          <InlineInput error={bonusError} title={t('formGrossAnnualBonus')} label={cur}>
            <CurrencyCleave
              value={grossAnnualBonus}
              placeholder={t('formPHGrossAnnualBonus')}
              onChange={value => this.handleInputChange('grossAnnualBonus', value)}
              options={getLocalFormat()}
            />
          </InlineInput>

          <Form.Group inline className="m-t-20">
            <Form.Checkbox
              label={t('formHoursToSell')}
              name="hoursToSell"
              checked={hoursToSell}
              onChange={this.handleCheckBoxChange}
            />
          </Form.Group>

          {hoursToSell && (
            <Fragment>
              <InlineInput error={exploitationError} title={t('formExploitationRate')} label="%">
                <CurrencyCleave
                  options={getLocalFormat()}
                  onChange={value => this.handleInputChange('exploitationRate', value)}
                  placeholder={t('formPHExploitationRate')}
                  value={exploitationRate}
                />
              </InlineInput>

              <InlineInput error={efficiencyError} title={t('formEfficiencyRate')} label="%">
                <CurrencyCleave
                  options={getLocalFormat()}
                  onChange={value => this.handleInputChange('efficiencyRate', value)}
                  placeholder={t('formPHEfficiencyRate')}
                  value={efficiencyRate}
                />
              </InlineInput>
            </Fragment>
          )}
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} floated="right" />

        <Message negative hidden={!updateError}>{updateError}</Message>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateEmployeeData: (item, data) => dispatch(updateEmployeeData(item, data)),
  setEmployeeData: employeeData => dispatch(retrieved(employeeData)),
  reset: () => {
    dispatch(resetUpdateEmployeeData());
  },
});

const mapStateToProps = state => ({
  retrievedEmployeeData: state.employeeData.show.retrieved,
  loadingEmployeeData: state.employeeData.show.loading,
  errorEmployeeData: state.employeeData.show.error,

  updateError: state.employeeData.update.updateError,
  updateLoading: state.employeeData.update.updateLoading,
  updated: state.employeeData.update.updated,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(UpdateEmployeeData);

export default withTranslation()(withRouter(Main));
