/* eslint-disable */
import { isEmpty } from 'lodash';

export function updatePrint(
  urlDocument: string,
  prevUrl: string,
  resetPrint: () => void,
  name: string,
): void {
  if (!isEmpty(urlDocument)) {
    const link = document.createElement('a');
    link.href = urlDocument;
    link.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
    resetPrint();
  }
}
