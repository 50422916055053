import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Currency from 'components/currency';
import KeyValue from 'components/keyValue';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import SingleRowColumn from 'layouts/SingleRowColumn';
import ContainerGeneral from 'layouts/ContainerGeneral';
import withBreakEven from 'routes/admin/forecast/break-even/withBreakEven';
import { capitalize } from 'lodash';

function BreakEven(props) {
  const { t } = useTranslation();
  const { loading, data } = props;

  return (
    <ContainerGeneral>
      <TitleHeader title={t('forecastCalculatingBreak')} />
      <SingleRowColumn width={15}>
        <Form className="margin-top-bot main-form" loading={loading} size="small">

          <KeyValue label={t('forecastTurnover')}>
            <Currency value={data.turnover} />
          </KeyValue>

          <KeyValue label={t('forecastFixedCharges')}>
            <Currency value={data.fixedCharges} />
          </KeyValue>

          <KeyValue label={t('forecastVariableExpenses')}>
            <Currency value={data.variableCharges} />
          </KeyValue>

          <KeyValue label={t('forecastResultBreak')}>
            {data.fixedCharges.plus(data.variableCharges).gt(data.turnover)
              ? t('breakEvenLoss')
              : <Currency value={data.breakEven} />
            }
          </KeyValue>

          <KeyValue label={t('forecastDeadLine')}>
            {data.breakEvenDate.isAfter(data.dateEnd)
              ? t('breakEvenAfter')
              : capitalize(data.breakEvenDate.format('MMMM YYYY'))
            }
          </KeyValue>

          <KeyValue label={t('forecastOperating')}>
            <Currency value={data.operatingProfit} />
          </KeyValue>

          <KeyValue label={t('forecastOperatingDate')}>
            {data.operatingProfitDate.isAfter(data.dateEnd)
              ? t('breakEvenAfter')
              : capitalize(data.operatingProfitDate.format('MMMM YYYY'))
            }
          </KeyValue>

        </Form>
      </SingleRowColumn>
    </ContainerGeneral>
  );
}

export default withBreakEven(BreakEven);
