/* eslint-disable */
import React from 'react';
import { NavLink, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreateOffice from './create';
import OfficeSettings from './settings';
import HomeOffice from './home';
import ShowOffice from './show';
import NotFound from '../404';

const Office = ({ selectedOffice, t }) => (
  <React.Fragment>
    <div className="sub-nav">
      <ul>
        <li className="menu-item menu-title">
          {t('offices')}
        </li>

        <li>
          <NavLink
            to="/offices"
            activeClassName="active"
            className="menu-item"
          >
            {t('officeList')}
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/offices/create"
            activeClassName="active"
            className="menu-item"
          >
            {t('officeAdd')}
          </NavLink>
        </li>

        {selectedOffice
        && (
          <React.Fragment>
            <li className="menu-item menu-title">
              {t('companiesSettings')}
            </li>

            <li>
              <NavLink
                to={`/offices/${selectedOffice.id}/settings/contact-information`}
                activeClassName="active"
                className="menu-item"
              >
                {t('officesContactInformation')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/offices/${selectedOffice.id}/settings/company-details`}
                activeClassName="active"
                className="menu-item"
              >
                {t('officesCompanyDetails')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/offices/${selectedOffice.id}/settings/bank-accounts`}
                activeClassName="active"
                className="menu-item"
              >
                {t('officesBankAccounts')}
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/offices/${selectedOffice.id}/settings/subscription-data`}
                activeClassName="active"
                className="menu-item"
              >
                {t('officesSubscriptionData')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/offices/${selectedOffice.id}/settings/subscribed-option`}
                activeClassName="active"
                className="menu-item"
              >
                {t('officesSubscribedOption')}
              </NavLink>
            </li>
          </React.Fragment>
        )}
      </ul>
    </div>

    <Switch>
      <Route exact path="/offices/create" key="create" component={CreateOffice} />
      <Route exact path="/offices/:id/edit" key="edit" component={CreateOffice} />
      <Route exact path="/offices/:id" component={ShowOffice} />
      <Route exact path="/offices" component={HomeOffice} />
      <Route path="/offices/:id/settings" component={OfficeSettings} />
      <Route component={NotFound} />
    </Switch>
  </React.Fragment>
);

const mapStateToProps = state => ({
  selectedOffice: state.userCompanies.select.selectedOffice,
  userRole: state.userCompanies.role.userRole,
});

const Main = connect(mapStateToProps)(Office);

export default withTranslation()(Main);
