import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { DeleteConfirmation } from 'components';
import { del as deleteCustomer, error as errorDelete } from 'actions/customer/delete';
import { list, reset, success } from 'actions/customer/list';
import { useTranslation } from 'react-i18next';
import Routes from 'routes/admin/contacts/routes';
import { Entities } from 'types/accessRights';
import AddHeader from 'components/pageHeaders/AddHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import useCompany from 'hooks/useCompany';
import { columnDefinition, handlerDefinition, hiddenHandler } from 'utils/tables';
import ListLoading from 'components/ListLoading';
import { ASC } from 'utils/constants';
import AdvancedList from 'components/advancedList/AdvancedList';

const HomeRecipient = () => {
  const { t } = useTranslation();
  const [recipients, setRecipients] = useState([]);
  const [warning, setWarning] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const { data, loading } = useSelector(state => state.customer.list);
  const updated = useSelector(state => state.customer.update.updated);
  const created = useSelector(state => state.customer.create.created);

  const del = useSelector(state => state.customer.del);
  const delError = useSelector(state => state.customer.del.error);
  const delLoading = useSelector(state => state.customer.del.loading);

  const [, company] = useCompany();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () => {
      dispatch(list(`/customers?company=${company['@id']}&pagination=true`));
      return () => {
        dispatch(reset());
      };
    }, [updated, created, company],
  );

  const dismissWarning = () => {
    setToDelete(null);
    setWarning(false);
  };

  useEffect(() => {
    if (!isEmpty(data) && data['hydra:member']) {
      setRecipients(data['hydra:member']);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(del.error)) {
      dismissWarning();
      dispatch(errorDelete());
    }
  }, [delError]);

  useEffect(() => {
    if (del.deleted) {
      const filtered = recipients.filter(recipient => recipient['@id'] !== del.deleted['@id']);

      const newListRecipient = {
        ...data,
        'hydra:member': filtered,
        'hydra:totalItems': data['hydra:totalItems'] - 1,
      };

      dispatch(success(newListRecipient));
      dismissWarning();
    }
  }, [del.deleted]);

  const handleDelete = () => {
    dispatch(deleteCustomer(toDelete));
  };

  const showDeleteWarning = (item) => {
    setToDelete(item);
    setWarning(true);
  };

  const details = (customer, key) => customer.details[key] || '';

  return (
    <ContainerGeneral>
      <ListLoading loaded={recipients.length} total={data['hydra:totalItems'] || 0} />
      <AddHeader
        title={t('customerHome')}
        entity={Entities.customer}
        to={Routes.createRecipient}
      />
      <AdvancedList
        columns={[
          columnDefinition('formHonorific', 'details.honorific'),
          columnDefinition('formContactName', 'contactName'),
          columnDefinition('formCompanyName', 'companyName'),
          handlerDefinition('formEmail', 'details.email', customer => details(customer, 'email')),
          hiddenHandler('formStreetName', 'details.streetName', customer => details(customer, 'streetName')),
          hiddenHandler('formAdditional', 'details.additional', customer => details(customer, 'additional')),
          hiddenHandler('formZipCode', 'details.zipCode', customer => details(customer, 'zipCode')),
          hiddenHandler('formCity', 'details.city', customer => details(customer, 'city')),
          hiddenHandler('formCountry', 'details.country', customer => details(customer, 'country')),
          hiddenHandler('formPhoneNumber', 'details.phone', customer => details(customer, 'phone')),
          hiddenHandler('formCellphoneNumber', 'details.cellphone', customer => details(customer, 'cellphone')),
        ]}
        loading={loading}
        data={recipients || []}
        onView={recipient => history.push(`/contacts/recipients/${recipient.id}`)}
        onEdit={recipient => history.push(`/contacts/recipients/${recipient.id}/edit`)}
        onDelete={showDeleteWarning}
        entity={Entities.customer}
        sortBy="contactName"
        direction={ASC}
      />

      <DeleteConfirmation
        show={warning}
        name={toDelete ? `${toDelete.details.honorific || ''} ${toDelete.contactName}` : ''}
        loading={delLoading}
        onClose={dismissWarning}
        onDelete={handleDelete}
      />
    </ContainerGeneral>
  );
};

export default HomeRecipient;
