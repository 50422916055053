import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve, update } from 'actions/company-settings/update';
import { Form, Message, Modal, Table } from 'semantic-ui-react';
import { toast } from 'components';
import Cleave from 'cleave.js/react';
import { getLocalFormat, inputFormat, percentageFormat } from 'utils/formatter';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import SaveButton from 'components/buttons/SaveButton';
import AddButton from 'components/buttons/AddButton';
import GridWrapper from 'layouts/GridWrapper';
import SmallForm from 'layouts/SmallForm';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import RightCell from 'layouts/table/RightCell';
import EditCellButton from 'components/buttons/EditCellButton';
import ModalButtons from 'components/ModalButtons';
import DeleteCellButton from 'components/buttons/DeleteCellButton';

class CompanyShares extends Component {
  state = {
    value: [],
    fullName: '',
    shares: '',
    fullNameError: false,
    sharesError: false,
    total: 0,
    hasError: false,
    modal: false,
    idModal: null,
    dataClone: null,
    sharesStatic: 0,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=COMPANY_SHARES`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = prevState;
    const { retrieved } = nextProps;
    if ((retrieved && retrieved['hydra:member'][0].name === 'COMPANY_SHARES' && !id)) {
      let total = 0;
      retrieved['hydra:member'][0].value.forEach((item) => { total += parseFloat(item.shares); });

      return {
        id: retrieved['hydra:member'][0]['@id'],
        value: retrieved['hydra:member'][0].value,
        total,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const { dataClone, modal } = this.state;

    if (modal) {
      dataClone[name] = value;
      this.setState({
        dataClone,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { total, dataClone, modal, sharesStatic } = this.state;
    const { name, value } = e.target;

    if ((total + parseFloat(inputFormat(value)) - parseFloat(sharesStatic)) > 100) {
      this.setState({
        hasError: true,
        sharesError: true,
      });
    }

    if ((total + parseFloat(inputFormat(value)) - parseFloat(sharesStatic)) <= 100
        && (total + parseFloat(inputFormat(value)) - parseFloat(sharesStatic)) > 0) {
      this.setState({
        hasError: false,
        sharesError: false,
      });
    }

    if (modal) {
      dataClone[name] = inputFormat(value);
      this.setState({
        dataClone,
      });
    } else {
      this.setState({
        [name]: inputFormat(value),
      });
    }
  };

  capitalizeLabel = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleAddItem = () => {
    const {
      fullName,
      shares,
      value,
      sharesCleave,
      total,
    } = this.state;

    this.setState({
      fullNameError: false,
      sharesError: false,
      hasError: false,
    });

    let isValid = true;

    if (fullName.trim() === '') {
      isValid = false;

      this.setState({
        fullNameError: true,
      });
    }

    if (typeof shares === 'string' && shares.trim() === '') {
      isValid = false;

      this.setState({
        sharesError: true,
      });
    }

    if (!isValid) return;

    const data = {
      fullName,
      shares: shares.toString(),
    };

    value.push(data);

    this.setState({
      total: total + Number(shares),
      value,
      fullName: '',
      shares: '',
      hasDataChanged: true,
    });

    sharesCleave.setRawValue('');
  };

  cleanShares = (cleave) => {
    this.setState({
      sharesCleave: cleave,
    });
  };

  openModal = (e, index, row) => {
    const dataClone = Object.assign({}, e);

    this.setState({
      dataClone,
      modal: true,
      sharesStatic: dataClone.shares,
      idModal: index,
      inputsNumeric: row || [],
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
      dataClone: null,
      sharesStatic: 0,
    });
  };

  handleOnSubmit = () => {
    const { idModal, dataClone, value, modal, sharesStatic, total } = this.state;
    const { update, retrieved, t, history } = this.props;

    if (modal) {
      const updateValueData = [...value];
      updateValueData[idModal] = dataClone;

      this.setState({
        value: updateValueData,
        modal: false,
        total: ((parseFloat(total) - parseFloat(sharesStatic))
          + parseFloat(dataClone.shares)),
        sharesStatic: 0,
        hasDataChanged: true,
      });
    } else {
      const data = {
        value,
      };

      this.setState({
        hasDataChanged: false,
      });

      update(retrieved['hydra:member'][0], data)
        .then(() => {
          toast.success(t('capitalUpdateSuccess'));
          history.push('/company/settings/company-shares');
        });
      this.setState({
        hasDataChanged: false,
      });
    }
  };

  handleDelete = (obj, index) => {
    const { value, total } = this.state;
    value.splice(index, 1);

    this.setState({
      value,
      total: total - obj.shares,
      hasError: !(total - obj.shares <= 100),
      hasDataChanged: true,
    });
  };

  render() {
    const {
      fullName,
      shares,
      fullNameError,
      sharesError,
      value,
      hasError,
      modal,
      idModal,
      dataClone,
      hasDataChanged,
      inputsNumeric,
    } = this.state;

    const { retrieveLoading, updateLoading, updateError, t } = this.props;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader
          title={t('companiesCompanyShares')}
          to="/company/settings/company-shares"
        />
        <TwelveForm loading={retrieveLoading || updateLoading}>
          <Form.Group inline>
            <Form.Input
              label={t('formFullName')}
              name="fullName"
              placeholder={t('formPHFullName')}
              value={fullName}
              onChange={this.handleTextChange}
              error={fullNameError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input error={sharesError} label={t('formShares')}>
              <Cleave
                value={inputFormat(shares, true)}
                name="shares"
                placeholder={t('formPHShares')}
                onInit={this.cleanShares}
                onChange={this.handleInputChange}
                options={getLocalFormat()}
              />
            </Form.Input>
          </Form.Group>

          <Message negative hidden={!hasError}>
            <p>{t('companiesMessageErrorTotal')}</p>
          </Message>

          <Message negative hidden={!updateError}>
            <p>{updateError}</p>
          </Message>
        </TwelveForm>

        <AddButton
          onClick={this.handleAddItem}
          disabled={hasError}
          floated="right"
          label={t('buttonSubmit')}
        />

        {!isEmpty(value) && (
          <React.Fragment>
            <GridWrapper width={14}>
              <SmallForm loading={retrieveLoading || updateLoading}>
                <div className="select-list margin-top">
                  <Table celled structured className="margin-bot">
                    <HeaderRow>
                      <LeftCell>{t('formFullName')}</LeftCell>
                      <RightCell>{t('formShares')}</RightCell>
                      <LeftCell colSpan={2} />
                    </HeaderRow>

                    <Table.Body>
                      {!isEmpty(value) && value.map((companyShare, index) => (
                        <Table.Row key={index}>
                          <LeftCell>
                            {companyShare.fullName}
                          </LeftCell>
                          <RightCell className="format-number-fr">
                            {percentageFormat(companyShare.shares)}
                          </RightCell>
                          <EditCellButton
                            onClick={() => this.openModal(companyShare, index, [1])}
                          />
                          <DeleteCellButton
                            onClick={() => this.handleDelete(companyShare, index)}
                          />
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </SmallForm>
            </GridWrapper>

            <SaveButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right" />
          </React.Fragment>
        )}

        <Modal open={modal} className="full-content">
          <Modal.Header>{t('companiesEditTitle')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <SmallForm>
                {idModal !== null && dataClone && (
                  Object.entries(dataClone).map((item, indexCl) => (
                    item[0] !== 'id' && item[0] !== 'default'
                      ? inputsNumeric.find(num => num === indexCl)
                        ? (
                          <Form.Group inline key={indexCl}>
                            <Form.Input label={t(`form${this.capitalizeLabel(item[0])}`)}>
                              <Cleave
                                value={inputFormat(item[1], true)}
                                name={item[0]}
                                placeholder={t(`form${this.capitalizeLabel(item[0])}`)}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                options={getLocalFormat()}
                              />
                            </Form.Input>
                          </Form.Group>
                        )
                        : (
                          <Form.Group inline key={indexCl}>
                            <Form.Input
                              label={t(`form${this.capitalizeLabel(item[0])}`)}
                              name={item[0]}
                              placeholder={t(`form${this.capitalizeLabel(item[0])}`)}
                              value={item[1]}
                              onChange={this.handleTextChange}
                            />
                          </Form.Group>
                        )
                      : item[0] === 'default' && (
                      <Form.Group inline key={indexCl}>
                        <Form.Checkbox
                          label={t(`form${this.capitalizeLabel(item[0])}`)}
                          name={item[0]}
                          checked={item[1]}
                          disabled={!!item[1]}
                          onChange={this.handleCheckBoxChange}
                        />
                      </Form.Group>
                      ))))}
              </SmallForm>

              <Message negative hidden={!hasError}>
                <p>{t('companiesMessageErrorTotal')}</p>
              </Message>
            </Modal.Description>
          </Modal.Content>
          <ModalButtons
            disabled={updateLoading}
            cancel={this.closeModal}
            save={this.handleOnSubmit}
          />
        </Modal>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  retrieveError: state.companySettings.update.retrieveError,
  retrieveLoading: state.companySettings.update.retrieveLoading,
  updateError: state.companySettings.update.updateError,
  updateLoading: state.companySettings.update.updateLoading,
  retrieved: state.companySettings.update.retrieved,
  updated: state.companySettings.update.updated,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(CompanyShares);

export default withTranslation()(withRouter(Main));
