import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseSituation } from '../routes/admin/situation/situationTypes';
import { retrieve } from '../actions/situation/retrieve';
import useCompany from './useCompany';
import { State } from '../types/store';

const useCompanySituation = (date?: string): UseSituation => {
  const [, company] = useCompany();
  const loading = useSelector((state: State) => state.situation.retrieve.loading);
  const situation = useSelector((state: State) => state.situation.retrieve.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (company) {
      dispatch(retrieve(`/companies/${company.id}/situation`));
    }
  },
  [date, company]);

  return [loading, situation];
};

export default useCompanySituation;
