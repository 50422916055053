import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { inputFormat } from './formatter';
import { AccessoryDocument, Article, Document, Product } from '../types/document';
import { HydraList } from '../types/entity';
import { isInvoice } from './documentType';
import { Company } from '../types/store';

export interface ProductSelectionProps<T extends AccessoryDocument> {
  closeSelf: () => void;
  isOpen: boolean;
  reset: () => void;
  selectedCompany: Company;
  selectedDocument: Document;

  create: (doc: object) => void;
  getList: (uri: string) => void;
  loadingList: boolean;
  loadingCreate: boolean;
  success: T;
  list: HydraList<T>;
}

export interface ProductSelectionState {
  documents: AccessoryDocument[];
  documentsOut: AccessoryDocument[];
  arrayProducts: ProductShell[];
  selectedProducts: ProductShell[];
  errorSelectedProducts: boolean;
  addProducts: boolean;
  dataTable: ProductShell[];
}

export interface ProductShell extends Product {
  quantity: number;
  error: boolean;
  workOrderComment: string;
  index: number;
}

export function getProductList(document: Document): ProductShell[] {
  const arrayProducts: ProductShell[] = [];
  document.content.body.forEach((item: Article, index: number) => {
    if (item.type === 'Product') {
      item.index = index;
      item.error = false;
      item.workOrderComment = '';

      // we use 'as unknown' because Article covers Service as well
      arrayProducts.push(item as Product as ProductShell);
    }
  });

  return arrayProducts;
}

export function updateDataTable(
  data: AccessoryDocument[],
  products: ProductShell[],
  workOrder = false,
): ProductShell[] {
  const ids: number [] = [];
  let dataTable = cloneDeep(products);

  if (!isEmpty(data)) {
    for (const product of dataTable) {
      let result = 0;

      for (const document of data) {
        const obj = document.content.body.find((item: Article) => item.id === product.id);

        if (obj) {
          result += obj.quantity;
        }
      }

      if (!workOrder && result !== 0) {
        const newQuantity = product.quantity - result;

        if (newQuantity <= 0) {
          ids.push(product.id);
        } else {
          product.quantity = newQuantity;
        }
      }
    }

    dataTable = dataTable.filter(data => !ids.includes(data.id));
  }

  return dataTable;
}

export function computeProductArray(
  value: number,
  products: ProductShell[],
  id: number,
  diff: string,
): ProductShell[] {
  const formatValue = parseFloat(inputFormat(value));

  return products.map((product: ProductShell) => {
    if (product.id === id) {
      product.error = !(formatValue > 0 && formatValue <= parseFloat(diff));
      product.quantity = formatValue;
    }

    return product;
  });
}

export function handleCheckBox(products: ProductShell[], value: ProductShell): ProductShell[] {
  let newArrayProducts: ProductShell[];
  const obj = products.find((product: ProductShell) => product.index === value.index);

  if (obj) {
    newArrayProducts = products.filter((product: ProductShell) => product.index !== value.index);
  } else {
    value.error = false;
    newArrayProducts = products.concat({
      ...value,
    });
  }

  return newArrayProducts;
}

export function computeSubmitData(products: ProductShell[], document: Document, company: any): any {
  products.forEach((product: ProductShell) => delete product.error);

  const content = {
    ...document.content,
    body: {
      ...products,
    },
    visibility: document.content.visibility,
  };

  const data: Partial<AccessoryDocument> = {
    customer: document.customer['@id'],
    company: company['@id'],
    content,
    customerComment: document.customerComment,
    reference: document.reference,
    creationDate: moment().format('YYYY-MM-DD'),
    comment: document.comment,

  };

  if (isInvoice(document)) {
    data.invoice = document['@id'];
  } else {
    data.purchaseOrder = document['@id'];
  }

  return data;
}
