import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import LeftCell from '../../../layouts/table/LeftCell';

interface OwnProps {
  title: string;
}

type Props = OwnProps;

const SituationTitleRow: FunctionComponent<Props> = ({ title }) => (
  <Table.Row>
    <LeftCell className="title" content={title} />
    <LeftCell colSpan={6} />
  </Table.Row>
);

export default SituationTitleRow;
