import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EssorButton } from '../index';
import { ExtendProps } from '../essorButton';

type Props = ExtendProps;

const CancelButton: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <EssorButton secondary type="x" size="tiny" {...props}>
      {t('buttonCancel')}
    </EssorButton>
  );
};

CancelButton.defaultProps = {
  disabled: false,
  floated: undefined,
};

export default CancelButton;
