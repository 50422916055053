import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { reset as resetWorkingCapital, retrieve as retrieveWorkingCapital } from 'actions/working-capital/show';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/routes';
import ContainerGeneral from 'layouts/ContainerGeneral';
import useFiscalYear from 'hooks/useFiscalYear';
import ShowWorkingCapital from './show';
import EditWorkingCapital from './edit';

const WorkingCapital = () => {
  const [, fiscalYear] = useFiscalYear();

  const workingCapital = useSelector(state => state.workingCapital.show.retrieved);
  const loading = useSelector(state => state.workingCapital.show.loading);
  const created = useSelector(state => state.workingCapital.create.created);
  const updated = useSelector(state => state.workingCapital.update.updated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(retrieveWorkingCapital(`/working_capitals?fiscalYear=${fiscalYear.id}`));
    }
    return () => {
      dispatch(resetWorkingCapital());
    };
  },
  [fiscalYear, created, updated]);

  if (loading || isEmpty(workingCapital)) {
    return <ContainerGeneral status={loading || isEmpty(workingCapital)} />;
  }

  return (
    <Switch>
      <CheckRoute
        exact
        path={Routes.Forecast.workingCapital}
        component={ShowWorkingCapital}
        entity={Entities.workingCapital}
        right={crudRights.read}
      />
      <CheckRoute
        exact
        path={Routes.Forecast.editWorkingCapital}
        component={EditWorkingCapital}
        entity={Entities.workingCapital}
        right={crudRights.update}
      />
      <RouteNotFound />
    </Switch>
  );
};

export default WorkingCapital;
