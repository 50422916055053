import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/company-settings/show';
import { Icon, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import TableLoader from 'components/TableLoader';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';

class BankAccounts extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=BANK_ACCOUNTS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'BANK_ACCOUNTS'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    if (nextProps.retrieved && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && Array.isArray(nextProps.retrieved['hydra:member'][0].value)) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesBankAccounts')}
          to="/company/settings/bank-accounts/edit"
          entity={Entities.companySettings}
        />

        <TableLoader status={loading}>
          <Table celled striped>
            <HeaderRow>
              <LeftCell content={t('formBankName')} />
              <LeftCell content={t('formIBAN')} />
              <LeftCell content={t('formBIC')} />
              <CenterCell content={t('formDefault')} />
            </HeaderRow>

            <Table.Body>
              {value && value.map((bankAccount, index) => (
                <Table.Row key={`v${index}`}>
                  <LeftCell content={bankAccount.bankName} />
                  <LeftCell content={bankAccount.IBAN} />
                  <LeftCell content={bankAccount.BIC} />
                  <CenterCell>
                    <Icon
                      color={bankAccount.default ? 'green' : 'red'}
                      name={bankAccount.default ? 'check' : 'close'}
                      size="large"
                    />
                  </CenterCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(BankAccounts);

export default withTranslation()(withRouter(Main));
