import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorTemplate from './ErrorTemplate';

interface OwnProps {
}

type Props = OwnProps;

const UnexpectedError: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      title={t('errorBoundaryTitle')}
      body={t('errorBoundaryBody')}
      tryTo={t('errorBoundaryTry')}
      solutions={[
        {
          title: t('errorBoundaryRefresh'),
          onClick: (): void => window.history.go(0),
        },
        {
          title: t('errorBoundaryHome'),
          href: '/',
        },
      ]}
    />);
};

export default UnexpectedError;
