import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker/es';
import KeyValue from 'components/keyValue';
import FormattedDate from 'components/documents/formattedDate';
import { useTranslation } from 'react-i18next';

const DocumentCreationDate = ({ isEditable, creationDate, onChange, error }) => {
  const { t } = useTranslation();

  return (isEditable
    ? (
      <Form.Group inline>
        <Form.Input
          label={t('formDate')}
          name="creationDate"
          control={DatePicker}
          selected={creationDate}
          onChange={onChange}
          locale="fr"
          autoComplete="off"
          error={error}
        />
      </Form.Group>
    )
    : <KeyValue label={t('formDate')}><FormattedDate date={creationDate} /></KeyValue>
  );
};

export default DocumentCreationDate;
