import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/company-settings/show';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { legalFormOptions } from 'utils/constants';
import TwelveForm from 'layouts/TwelveForm';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import KeyValue from 'components/keyValue';

class CompanyDetails extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=COMPANY_DETAILS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'COMPANY_DETAILS'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { selectedCompany, loading, t } = this.props;

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesCompanyDetails')}
          to="/company/settings/company-details/edit"
          entity={Entities.companySettings}
        />

        <TwelveForm loading={loading}>
          <KeyValue label={t('formCalculationMode')}>
            {selectedCompany && (
              selectedCompany.calculationMode === 'rate'
                ? t('formMarginRate')
                : t('formSellingCoefficient')
            )}
          </KeyValue>
          <KeyValue label={t('formCommercialName')}>
            {value && (value.commercialName === '' ? '-' : value.commercialName)}
          </KeyValue>
          <KeyValue label={t('formSlogan')}>
            {value && (value.slogan === '' ? '-' : value.slogan)}
          </KeyValue>
          <KeyValue label={t('formActivity')}>
            {value && (value.activity === '' ? '-' : value.activity)}
          </KeyValue>
          <KeyValue label={t('formShareCapital')}>
            {value && (value.shareCapital === '' ? '-' : value.shareCapital)}
          </KeyValue>
          <KeyValue label={t('formNaf')}>
            {value && (value.naf === '' ? '-' : value.naf)}
          </KeyValue>
          <KeyValue label={t('formSiret')}>
            {value && (value.siret === '' ? '-' : value.siret)}
          </KeyValue>
          <KeyValue label={t('formVat')}>
            {value && (value.vat === '' ? '-' : value.vat)}
          </KeyValue>
          <KeyValue label={t('formRcs')}>
            {value && (value.rcs === '' ? '-' : value.rcs)}
          </KeyValue>
          <KeyValue label={t('formLegalForm')}>
            {value && (
              value.legalForm === undefined
                ? '-'
                : (
                  value.legalForm === 'ABL' || value.legalForm === 'ABNL'
                    ? legalFormOptions[value.legalForm]
                    : `${value.legalForm}: ${legalFormOptions[value.legalForm]}`
                )
            )}
          </KeyValue>
        </TwelveForm>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);

export default withTranslation()(withRouter(Main));
