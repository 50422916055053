import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

function SectionLoader(props) {
  const { t } = useTranslation();
  const { status, label, children, className } = props;

  return (
    <Segment
      basic
      className={className || classnames({
        'section-loading': status,
      })}
    >
      <Dimmer active={status} inverted>
        <Loader>{label || t('loading')}</Loader>
      </Dimmer>
      {children}
    </Segment>
  );
}

export default SectionLoader;
