import React, { FC } from 'react';
import SelectableCell from '../../../../layouts/table/SelectableCell';
import { Translation } from '../../../../hooks/useTrans';

interface OwnProps {
  collapse: boolean;
  title: Translation;
  toggle: () => void;
}

type Props = OwnProps;

const ToggleCell: FC<Props> = ({ collapse, title, toggle }) => (
  <SelectableCell
    textAlign="left"
    icon={`toggle ${collapse ? 'off' : 'on'} icon`}
    onClick={toggle}
    className="cell-fixed-left fixed-1 pointer"
  >
    {title.toUpperCase()}
  </SelectableCell>
);

export default ToggleCell;
