import React, { FunctionComponent, useContext } from 'react';
import { StrictTableCellProps, TableCell, TableHeaderCell } from 'semantic-ui-react';
import { HeaderRowContext } from './HeaderRow';

export interface ExtendAlignedCellProps extends StrictTableCellProps {
  header?: boolean;
  onClick?: () => void;
  rowSpan?: number;
  colSpan?: number;
  title?: string;
}

export interface AlignedCellProps extends ExtendAlignedCellProps {
  textAlign: 'center' | 'left' | 'right';
}

type Props = AlignedCellProps;

const AlignedCell: FunctionComponent<Props> = ({ header, ...others }) => {
  const { header: contextHeader } = useContext(HeaderRowContext);

  return (contextHeader || header) ? <TableHeaderCell {...others} /> : <TableCell {...others} />;
};


export default AlignedCell;
