import React from 'react';
import FormatCell from 'components/cell/FormatCell';
import Percentage from 'components/percentage';

function PercentageCell(props) {
  const { value, decimal = 2, ...others } = props;

  return (
    <FormatCell value={value} align="right" {...others}>
      <Percentage value={value} decimal={decimal} {...others} />
    </FormatCell>
  );
}

export default PercentageCell;
