import { useSelector } from 'react-redux';
import { State } from '../types/store';
import { isAdmin } from '../utils/roles';
import { crudRights, EntityRights } from '../types/accessRights';

export default function useAccessRights(entity: keyof EntityRights, right: crudRights): boolean {
  const role = useSelector((state: State) => state.userCompanies.role.userRole);
  const rights: EntityRights = useSelector((state: State) => state.userCompanies.role.userRights);

  if (isAdmin(role)) {
    return true;
  }

  const keyExists = rights && entity in rights;
  const entityHasRights = keyExists && rights[entity].length > 0;

  return keyExists && entityHasRights && rights[entity].indexOf(right) !== -1;
}
