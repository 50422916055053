import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonHeader, { ExtendProps } from './ButtonHeader';
import { crudRights } from '../../types/accessRights';

const AddHeader: FC<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();
  return (
    <ButtonHeader
      label={label || t('buttonAdd')}
      right={crudRights.create}
      type="plus"
      {...others}
    />
  );
};

export default AddHeader;
