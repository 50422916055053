import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import useCompany from 'hooks/useCompany';
import { useTranslation } from 'react-i18next';
import { selectCompany, selectFiscalYear } from 'actions/user-companies/select';
import { useDispatch, useSelector } from 'react-redux';
import { Company, State } from 'types/store';
import Routes from 'routes/admin/company/routes';
import { list as getCompanies, reset } from 'actions/company/list';
import ListFilter from 'components/advancedList/ListFilter';
import { columnDefinition, handlerDefinition } from 'utils/tables';
import { isEmployee } from '../../utils/roles';

const CompanySelector: FunctionComponent = () => {
  const { t } = useTranslation();
  const [hasCompany, company] = useCompany();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, loading } = useSelector((state: State) => state.company.list);
  const role = useSelector((state: State) => state.userCompanies.role.userRole);

  const [companies, setCompanies] = useState([] as Company[]);
  const [filtered, setFiltered] = useState([] as Company[]);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    setCompanies(data['hydra:member'] || []);
    setFiltered(data['hydra:member'] || []);
    return (): void => {
      reset();
    };
  }, [data]);

  useEffect(() => {
    if (isEmployee(role) && companies.length > 0) {
      dispatch(selectCompany(companies[0]));
    }
  }, [role, companies]);

  const changeCompany = (company: Company): void => {
    dispatch(selectCompany(company));
    dispatch(selectFiscalYear(undefined));
    history.push('/company');
  };

  const getOfficeName = (company: Company): string => company.office.name;

  if (isEmployee(role)) {
    return (
      <div className="company-default">
        {hasCompany ? company.name : `${t('loading')} ...`}
      </div>
    );
  }

  const trigger = <div className="nav-select">{hasCompany ? company.name : t('selectCompany')}</div>;

  return (
    <Dropdown className="selection-dropdown" trigger={trigger}>
      <Dropdown.Menu>
        {companies.length > 10 && (
          <ListFilter<Company>
            originalData={companies}
            setFilteredData={setFiltered}
            visibleDef={[
              columnDefinition('name', 'name'),
              handlerDefinition('office', 'office.name', getOfficeName),
            ]}
          />
        )}

        {loading && (
          <Dropdown.Item disabled>
            <Icon loading name="spinner" />
            {t('loading')}
          </Dropdown.Item>
        )}

        {filtered.map(item => (
          company && item['@id'] === company['@id']
            ? undefined
            : (
              <Dropdown.Item
                key={item['@id']}
                text={item.name}
                onClick={(): void => changeCompany(item)}
              />
            )))}

        <Dropdown.Item as={Link} to={Routes.create} text={t('companiesCreateTitle')} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CompanySelector;
