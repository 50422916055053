import React, { FC, SyntheticEvent, useContext } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CenterCell from '../../layouts/table/CenterCell';
import { isEnsemble } from './articles';
import { hasOptionCheckbox, hasSecondLine } from './documentOptions';
import { DocumentContext } from './DocumentContainer';

interface Item {
  id: number;
  items: Item[];
  optional: boolean;
  order: number;
}

interface DocContext {
  selectedItemList: Item[];
  updateContext: (key: string, val: Item[]) => void;
}

interface OwnProps {
  documentType?: string;
  editable: boolean;
  item: Item;
  rowSpan?: number;
  handleDeleteItem: (item: Item) => void;
  handleItemCheckBoxChange?: (e: SyntheticEvent, item: Item) => void;
}

type Props = OwnProps;

const BodyLineControls: FC<Props> = ({ documentType, item, editable, rowSpan, ...funcs }) => {
  const { handleDeleteItem, handleItemCheckBoxChange } = funcs;
  const context = useContext(DocumentContext) as DocContext;
  const { selectedItemList, updateContext } = context;

  const calcRowSpan = (): number => (
    isEnsemble(item) ? (2 * item.items.length + 1) : (hasSecondLine(documentType) ? 2 : 1)
  );

  const switchItem = (item: Item, up = true): void => {
    const tmp = up ? item.order - 1 : item.order + 1;

    selectedItemList[tmp].order = item.order;
    selectedItemList[item.order].order = tmp;

    selectedItemList.sort((a, b) => (a.order > b.order ? 1 : -1));

    updateContext('selectedItemList', selectedItemList);
  };

  return (
    <CenterCell rowSpan={rowSpan || calcRowSpan()} collapsing>
      {editable && (
        <div>
          <Icon
            link
            className="table-button"
            name="caret square up outline"
            onClick={(): void => switchItem(item)}
            disabled={item.order === 0}
          />
        </div>
      )}

      {editable && (
        <div>
          <Icon
            link
            className="table-button"
            name="trash alternate"
            onClick={(): void => handleDeleteItem(item)}
          />
        </div>
      )}

      {editable && hasOptionCheckbox(documentType) && handleItemCheckBoxChange && (
        <div className="m-t-10">
          <Checkbox
            name="optional"
            checked={item.optional}
            onChange={(e: SyntheticEvent): void => handleItemCheckBoxChange(e, item)}
            disabled={!editable}
          />
        </div>
      )}

      {item.id && (
        <div>
          <Link to={`/articles${item.id}`} target="_blank">
            <Icon link className="table-button" name="external" />
          </Link>
        </div>
      )}

      {editable && (
        <div>
          <Icon
            link
            className="table-button"
            name="caret square down outline"
            onClick={(): void => switchItem(item, false)}
            disabled={item.order === selectedItemList.length - 1}
          />
        </div>
      )}
    </CenterCell>
  );
};

export default BodyLineControls;
