enum Articles {
  home = '/articles',
  updatePrices = '/articles/prices',
  listMovements = '/articles/movement',
  createMovement = '/articles/movement/create',
  showMovement = '/articles/movement/:id',
  editMovement = '/articles/movement/:id/edit',
  listServices = '/articles/services',
  createService = '/articles/services/create',
  showService = '/articles/services/:id',
  editService = '/articles/services/:id/edit',
  listProducts = '/articles/products',
  createProduct = '/articles/products/create',
  showProduct = '/articles/products/:id',
  editProduct = '/articles/products/:id/edit',
  listEnsembles = '/articles/ensembles',
  createEnsemble = '/articles/ensembles/create',
  showEnsemble = '/articles/ensembles/:id',
  editEnsemble = '/articles/ensembles/:id/edit',
  routeStocks = '/articles/stocks',
  listStocks = '/articles/stocks/situation',
  showStock = '/articles/products/:productId/stock/:stockId/',
  editStock = '/articles/products/:productId/stock/:stockId/edit',
}

export default Articles;
