import { SUPPLIER_ORDER } from 'utils/documentType';

function createSupplierProduct(article, selectedCompany, stockId = -1) {
  return {
    key: `pro${article.id}`,
    text: `${article.reference} - ${article.label}`,
    value: JSON.stringify({
      type: 'Product',
      id: article['@id'],
      reference: article.reference,
      label: article.label,
      items: null,
      price: stockId !== -1 ? article.stock[stockId].supplierPrice : article.purchaseUnitCost,
      stockManagement: stockId !== -1,
      margin: selectedCompany.calculationMode === 'coef' ? 1 : 100,
      unit: article.unit,
    }),
  };
}

export function filterArticleList(articles, selectedCompany, supplierID) {
  return articles['hydra:member']
    .filter((item) => {
      if (item.supplier && item.supplier['@id'] === supplierID) {
        return true;
      }

      for (let i = 0; i < item.stock.length; i++) {
        if (item.stock[i].supplier['@id'] === supplierID) {
          return true;
        }
      }

      return false;
    })
    .map((article) => {
      if (article.supplier) {
        return createSupplierProduct(article, selectedCompany);
      }

      for (let i = 0; i < article.stock.length; i++) {
        if (article.stock[i].supplier['@id'] === supplierID) {
          return createSupplierProduct(article, selectedCompany, i);
        }
      }

      return null;
    });
}

export function isSupplierOrder(type) {
  return type === SUPPLIER_ORDER;
}
