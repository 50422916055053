import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { update as updateFiscalYear, reset as resetFiscalYear } from 'actions/fiscal-year/update';
import { selectFiscalYear } from 'actions/user-companies/select';
import { Button, Dimmer, Header, Icon, Loader, Modal, Segment, Table } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

class ListFiscalYear extends Component {
  state = {
    fiscalYearData: null,
    openFiscalYearConfirm: false,
    fiscalYear: null,
  };

  static getDerivedStateFromProps(nextProps) {
    if (!isEmpty(nextProps.dataFiscalYear)) {
      return {
        fiscalYearData: nextProps.dataFiscalYear['hydra:member'],
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  openFiscalYearConfirm = (value) => {
    this.setState({
      openFiscalYearConfirm: true,
      fiscalYear: value,
    });
  };

  closeFiscalYearConfirm = () => {
    this.setState({
      openFiscalYearConfirm: false,
      fiscalYear: null,
    });
  };

  handleFiscalYearChange = () => {
    const { fiscalYear } = this.state;
    const { updateFiscalYear, selectFiscalYear } = this.props;

    const data = {
      defaultYear: true,
    };

    selectFiscalYear(fiscalYear);
    updateFiscalYear(fiscalYear, data);

    this.setState({
      openFiscalYearConfirm: false,
    });
  };

  render() {
    const { fiscalYearData, openFiscalYearConfirm, fiscalYear } = this.state;

    const {
      loadingFiscalYear,
      t,
    } = this.props;

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('fiscalYearsHomeTitle')}</Header>
            <EssorButton as={Link} to="/company/fiscal_years/create" type="plus" size="tiny" floated="right">
              {t('buttonAdd')}
            </EssorButton>
          </div>

          <Segment
            basic
            className={classnames('table-container', {
              'is-loading': loadingFiscalYear,
            })}
          >
            <Dimmer active={loadingFiscalYear} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('formDateStart')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('formDateEnd')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('formDefaultYear')}</Table.HeaderCell>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {fiscalYearData && fiscalYearData.map((fiscalYear, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{fiscalYear.label}</Table.Cell>
                    <Table.Cell>{moment(fiscalYear.dateStart).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell>{moment(fiscalYear.dateEnd).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon color={fiscalYear.defaultYear ? 'green' : 'red'} name={fiscalYear.defaultYear ? 'checkmark' : 'close'} size="large" />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button onClick={() => this.openFiscalYearConfirm(fiscalYear)} className="table-button" data-tip={t('selectFiscalYearTooltip')} icon="refresh" />
                      <ReactTooltip className="essor-tooltip" effect="solid" place="bottom" />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button as={Link} to={`/company/fiscal_years/${fiscalYear.id}`} className="table-button" data-tip={t('viewFiscalYearTooltip')} icon="eye" />
                      <ReactTooltip className="essor-tooltip" effect="solid" place="bottom" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
        </div>

        <Modal
          open={openFiscalYearConfirm}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={this.closeFiscalYearConfirm}
        >
          <Modal.Header>{t('warning')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p>
                {t('confirmFiscalYear', {
                  fiscalYear: fiscalYear ? fiscalYear.label : '-',
                })}
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <div>
              <EssorButton type="check" onClick={this.handleFiscalYearChange} size="tiny">
                {t('buttonYes')}
              </EssorButton>
              <EssorButton secondary type="x" size="tiny" onClick={this.closeFiscalYearConfirm}>
                {t('buttonCancel')}
              </EssorButton>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectFiscalYear: fiscalYear => dispatch(selectFiscalYear(fiscalYear)),
  updateFiscalYear: (item, data) => dispatch(updateFiscalYear(item, data)),
  reset: () => dispatch(resetFiscalYear()),
});

const mapStateToProps = state => ({
  dataFiscalYear: state.fiscalYear.list.data,
  loadingFiscalYear: state.fiscalYear.list.loading,
  errorFiscalYear: state.fiscalYear.list.error,

  updatedFiscalYear: state.fiscalYear.update.updated,
  loadingUpdateFiscalYear: state.fiscalYear.update.updateLoading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListFiscalYear);

export default withTranslation()(withRouter(Main));
