import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import TableLoader from 'components/TableLoader';
import { Entities } from 'types/accessRights';
import Routes from 'routes/admin/subscriptions/routes';
import DateCell from 'components/cell/DateCell';
import FormatCell from 'components/cell/FormatCell';
import ViewCellButton from 'components/buttons/ViewCellButton';
import CurrencyCell from 'components/cell/CurrencyCell';

const Subscriptions = () => {
  const { t } = useTranslation();
  const listSubscriptions = useSelector(state => state.subscription.list.data);
  const loadingListSubscriptions = useSelector(state => state.subscription.list.loading);

  const subscriptionList = isEmpty(listSubscriptions)
    ? []
    : listSubscriptions['hydra:member'];

  return (
    <ContainerGeneral>
      <AddHeader
        title={t('subscriptionsShowTitle')}
        to={Routes.createSubscription}
        entity={Entities.subscription}
      />
      <TableLoader status={loadingListSubscriptions}>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('subscriptionsShowTitle')}</Table.HeaderCell>
              <Table.HeaderCell>{t('subscriptionScheduleInvoice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('subscriptionsCreatedInvoice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('subscriptionsLastInvoice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('subscriptionsNextInvoice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('formAmount')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {subscriptionList.map((subscription) => {
              let createdQuantity = 0;
              let lastInvoice;
              let nextInvoice;
              let amount = 0;

              subscription.schedule.forEach((item) => {
                if (!item.invoice) {
                  amount += parseFloat(subscription.model.content.total.beforeTaxes);

                  if (!nextInvoice || moment(item.date).isBefore(nextInvoice)) {
                    nextInvoice = moment(item.date);
                  }
                }

                if (item.invoice) {
                  createdQuantity += 1;
                  amount += parseFloat(item.invoice.totalPrice);

                  if (!lastInvoice || moment(item.date).isAfter(lastInvoice)) {
                    lastInvoice = moment(item.date);
                  }
                }
              });

              return (
                <Table.Row key={subscription['@id']}>
                  <Table.Cell>
                    {subscription.customer.companyName || subscription.customer.contactName}
                  </Table.Cell>
                  <FormatCell>{subscription.schedule.length}</FormatCell>
                  <FormatCell>{createdQuantity}</FormatCell>
                  <DateCell date={lastInvoice} />
                  <DateCell date={nextInvoice} />
                  <CurrencyCell value={amount} />
                  <ViewCellButton to={`/subscriptions/${subscription.id}`} />
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </TableLoader>
    </ContainerGeneral>
  );
};

export default Subscriptions;
