import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import CenterCell from '../layouts/table/CenterCell';

interface OwnProps {
  content: string;
  span: number;
}

type Props = OwnProps;

const TableEmptyRow: FunctionComponent<Props> = ({ content, span }) => (
  <Table.Row>
    <CenterCell colSpan={span} content={content} />
  </Table.Row>
);

export default TableEmptyRow;
