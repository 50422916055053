import React, { Dispatch, FC } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import SelectableNavLink from 'layouts/subNav/SelectableNavLink';
import SubNavLink from 'layouts/subNav/SubNavLink';
import { State } from 'types/store';
import { isDraft } from 'utils/documentStatus';
import { isInvoice } from 'utils/documentType';
import { open as openDeliveryForm } from 'actions/modal/deliveryForm';
import { VisibilityDispatch } from 'types/actions';
import CheckRights from 'components/access/CheckRights';
import { crudRights, Entities } from 'types/accessRights';
import { open as openWorkOrder } from 'actions/modal/workOrder';
import { HydraList } from 'types/entity';
import Routes from '../../routes';

interface OwnProps {
  createReceipt: () => void;
  documentReceipt: HydraList<any>;
}

const SelectedInvoiceMenu: FC<OwnProps> = ({ createReceipt, documentReceipt }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadingListReceipt = useSelector((state: State) => state.receipt.list.loading);
  const selectedDocument = useSelector((s: State) => s.userCompanies.select.selectedDocument);
  const listCreditNote = useSelector((s: State) => s.creditNote.list.data);

  if (isDraft(selectedDocument) || !isInvoice(selectedDocument)) {
    return <React.Fragment />;
  }
  let totalCreditNotes = 999999999;

  if (!isEmpty(listCreditNote) && !isEmpty(selectedDocument) && isInvoice(selectedDocument)) {
    totalCreditNotes = listCreditNote['hydra:member'].reduce(
      (a, b) => a + parseFloat(b.content.creditNoteAmount), 0,
    );
  }

  const id = {
    id: selectedDocument.id,
  };

  return (
    <React.Fragment>
      <CheckRights entity={Entities.deliveryForm}>
        <SelectableNavLink
          label={t('documentDeliveryInvoice')}
          onClick={(): Dispatch<VisibilityDispatch> => dispatch(openDeliveryForm())}
        />
      </CheckRights>
      <CheckRights entity={Entities.workOrder}>
        <SelectableNavLink
          label={t('workOrderInvoice')}
          onClick={(): Dispatch<VisibilityDispatch> => dispatch(openWorkOrder())}
        />
      </CheckRights>

      {!loadingListReceipt && (documentReceipt['hydra:totalItems'] <= 0
        ? (
          <CheckRights entity={Entities.receipt} right={crudRights.create}>
            <SelectableNavLink label={t('documentToReceipt')} onClick={createReceipt} />
          </CheckRights>
        )
        : (
          <CheckRights entity={Entities.receipt} right={crudRights.read}>
            <SubNavLink to={generatePath(Routes.Business.receipt, id)}>
              {t('documentViewReceipt')}
            </SubNavLink>
          </CheckRights>
        ))}
      {(totalCreditNotes < selectedDocument.totalPrice + selectedDocument.totalVAT) && (
        <CheckRights entity={Entities.creditNote} right={crudRights.create}>
          <SubNavLink to={generatePath(Routes.Business.creditNote, id)}>
            {t('documentToCreditNote')}
          </SubNavLink>
        </CheckRights>
      )}
    </React.Fragment>
  );
};

export default SelectedInvoiceMenu;
