import React from 'react';
import { MonthlyData } from './types';
import { ALL_BANKS } from './functions';

interface TreasuryContext {
  bank: number | 'all';
  data: MonthlyData[];
}

const TreasuryContext = React.createContext({
  bank: ALL_BANKS,
  data: [],
} as TreasuryContext);

export default TreasuryContext;
