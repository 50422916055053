import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import API_ENTRYPOINT from '../../config/_entrypoint';
import fetch from '../../utils/fetch';
import { JsonError } from '../../types/responses';

const ForgottenPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (): void => {
    setSuccess(false);
    setError('');

    if (isEmpty(email)) {
      setError(t('resetEmailEmpty'));
    } else {
      setLoading(true);

      fetch(
        new URL('/resetPassword', API_ENTRYPOINT).toString(),
        {
          method: 'POST',
          body: JSON.stringify({
            email,
          }),
        },
        (json: JsonError): void => {
          throw Error(json.error.exception[0].message);
        },
      ).then((response) => {
        if (response.ok) {
          return response;
        }

        return response
          .json()
          .then();
      }).then(() => {
        setSuccess(true);
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        setError(e.message);
      });
    }
  };

  return (
    <div className="login-form">
      <Grid textAlign="center" className="login-grid" verticalAlign="middle">
        <Grid.Column width={4}>
          <Form size="large">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('resetPHEmail')}
              name="username"
              disabled={loading}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
              autoComplete="username"
              type="email"
            />
            <Button
              className="color-primary"
              color="teal"
              fluid
              size="large"
              loading={loading}
              onClick={handleSubmit}
            >
              {t('buttonSubmit')}
            </Button>
          </Form>

          <Message negative hidden={isEmpty(error)} content={error} />
          <Message positive hidden={!success} content={t('resetEmailSent')} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgottenPassword;
