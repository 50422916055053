import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import KeyValueTable from './KeyValueTable';
import { KpiDTO } from '../../types/dto';

interface OwnProps {
  data: KpiDTO[];
}

type Props = OwnProps;

const IncomeAndExpenses: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <KeyValueTable data={data} title={t('dashboard_incomeAndExpenses')} />
  );
};

export default IncomeAndExpenses;
