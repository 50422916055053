import React, { useRef, useState } from 'react';
import { Ref, Table, Visibility } from 'semantic-ui-react';
import classnames from 'classnames';
import SaveButton from 'components/buttons/SaveButton';
import { useTranslation } from 'react-i18next';
import { keyVal } from 'utils/functions';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import HeaderRow from 'layouts/table/HeaderRow';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';
import TableLoader from 'components/TableLoader';
import ForecastMonetaryRow from 'routes/admin/forecast/table/ForecastMonetaryRow';
import ForecastNumericRow from 'routes/admin/forecast/table/ForecastNumericRow';
import ForecastSeparatorRow from 'routes/admin/forecast/table/ForecastSeparatorRow';
import ForecastTitleRow from 'routes/admin/forecast/table/ForecastTitleRow';
import ForecastProductRows from 'routes/admin/forecast/table/ForecastProductRows';
import ForecastInputRow from 'routes/admin/forecast/table/ForecastInputRow';

const ForecastTableRender = (props) => {
  const { t } = useTranslation();
  const [stickyTop, setSticky] = useState(false);
  const {
    data,
    submit,
    hasDataChanged,
    handleCleaveChange,
    loading,
    realised,
    calculationMode,
  } = props;

  const tableRef = useRef();

  let tableWidth = 200;

  if (tableRef.current) {
    tableWidth = tableRef.current.clientWidth;
  }

  const stickyHeadClass = stickyTop ? 'show-table-fix' : 'hide-table-head';
  const loaderClasses = classnames('table-container', 'forecast-table', keyVal('is-loading', loading));

  return (
    <ContainerGeneral prompt={hasDataChanged}>
      <TitleHeader title={t('forecastHomeTitle')} />

      <Visibility
        offset={55}
        once={false}
        onBottomPassed={() => setSticky(true)}
        onBottomVisible={() => setSticky(false)}
      />

      <div className="content-table-fixed">
        <Table celled className={stickyHeadClass} style={keyVal('width', tableWidth)}>
          <HeaderRow>
            <RightCell className="th1-fix" />
            <RightCell className="th-col-2" content={t('formRealised')} />
            <RightCell className="th-col-3" content={t('formPlanned')} />
            <RightCell className="th-col-4" content="%" />
          </HeaderRow>
        </Table>
      </div>

      <TableLoader status={loading} className={loaderClasses}>

        <Ref innerRef={tableRef}>
          <Table celled className="definition-table">
            <HeaderRow headerClass={classnames(keyVal('hide-table-head', stickyTop))}>
              <LeftCell className="th-col-1 forecast-column-1" />
              <RightCell className="th-col-2" content={t('formRealised')} />
              <RightCell className="th-col-3" content={t('formPlanned')} />
              <RightCell className="th-col-4" content="%" />
            </HeaderRow>

            <Table.Body>
              <ForecastTitleRow title={t('forecastLabour')} />

              <ForecastInputRow
                title={t('forecastAverageHourRate')}
                stateKey="thM"
                data={data.hourlyRate}
                onChange={handleCleaveChange}
                ghost={realised.isGhost}
                mode="currency"
              />

              <ForecastMonetaryRow label={t('forecastHourlyCost')} data={data.hourlyCost} />

              <ForecastSeparatorRow />

              <ForecastNumericRow
                label={t('forecastHoursTotal')}
                data={data.hoursTotal}
              />

              <ForecastNumericRow
                label={t('forecastHoursToSell')}
                tooltip={t('forecastTooltipHoursToSell')}
                data={data.hoursToSell}
              />

              <ForecastSeparatorRow />

              <ForecastMonetaryRow
                label={t('forecastLabourTurnover')}
                tooltip={t('forecastTooltipLabourTurnover')}
                data={data.labourTurnover}
              />

              <ForecastSeparatorRow />
              <ForecastSeparatorRow />

              <ForecastTitleRow title={t('forecastGlobalChargesCompany')} />

              <ForecastMonetaryRow
                label={t('forecastFixedCharges')}
                data={data.fixedCharges}
              />

              <ForecastMonetaryRow
                label={t('forecastVariableExpenses')}
                data={data.variablesCharges}
              />
              <ForecastSeparatorRow />

              <ForecastMonetaryRow
                label={t('forecastTotalCharges')}
                data={data.totalCharges}
              />

              <ForecastSeparatorRow />
              <ForecastSeparatorRow />

              <ForecastTitleRow title={t('forecastProductsSaleCompanies')} />

              <ForecastProductRows
                mode={calculationMode}
                stateKey="valueMP"
                ghost={realised.isGhost}
                handleCleaveChange={handleCleaveChange}
                purchaseLabel={t('forecastRawMaterialsPurchase')}
                purchaseTooltip={t('forecastTooltipMpPurchase')}
                turnoverLabel={t('forecastRawMaterialsTurnover')}
                turnoverTooltip={t('forecastTooltipMpTurnover')}
                marginLabel={t('forecastRawMaterialsPurchaseMargin')}
                data={data.mp}
              />

              <ForecastSeparatorRow />

              <ForecastProductRows
                mode={calculationMode}
                stateKey="valueMD"
                ghost={realised.isGhost}
                handleCleaveChange={handleCleaveChange}
                purchaseLabel={t('forecastGoodsPurchase')}
                purchaseTooltip={t('forecastTooltipMdPurchase')}
                turnoverLabel={t('forecastGoodsTurnover')}
                turnoverTooltip={t('forecastTooltipMdTurnover')}
                marginLabel={t('forecastGoodsPurchaseMargin')}
                data={data.md}
              />

              <ForecastSeparatorRow />

              <ForecastProductRows
                mode={calculationMode}
                stateKey="valueST"
                ghost={realised.isGhost}
                handleCleaveChange={handleCleaveChange}
                purchaseLabel={t('forecastOutsourcingPurchase')}
                purchaseTooltip={t('forecastTooltipStPurchase')}
                turnoverLabel={t('forecastOutsourcingTurnover')}
                turnoverTooltip={t('forecastTooltipStTurnover')}
                marginLabel={t('forecastOutsourcingPurchaseMargin')}
                data={data.st}
              />

              <ForecastSeparatorRow />
              <ForecastSeparatorRow />

              <ForecastTitleRow title={t('forecastSummaryCompanies')} />
              <ForecastMonetaryRow label={t('forecastTotalExpenses')} data={data.totalCharges} />

              <ForecastSeparatorRow />

              <ForecastMonetaryRow
                label={t('forecastRawMaterialsTurnover')}
                data={data.mp.turnover}
              />
              <ForecastMonetaryRow label={t('forecastGoodsTurnover')} data={data.md.turnover} />
              <ForecastMonetaryRow
                label={t('forecastOutsourcingTurnover')}
                data={data.st.turnover}
              />
              <ForecastMonetaryRow label={t('forecastLabourTurnover')} data={data.labourTurnover} />
              <ForecastMonetaryRow label={t('forecastTurnover')} data={data.turnover} />

              <ForecastSeparatorRow />

              <ForecastMonetaryRow label={t('forecastOperating')} data={data.operatingProfit} />

              <ForecastSeparatorRow />
              <ForecastSeparatorRow />

              <ForecastTitleRow title={t('forecastIndicatorsCompany')} />
              <ForecastNumericRow
                label={t('forecastNumberCustomers')}
                data={data.newCustomersNumber}
              />

            </Table.Body>
          </Table>

        </Ref>
        <SaveButton onClick={submit} fixed />

      </TableLoader>
    </ContainerGeneral>
  );
};

export default ForecastTableRender;
