import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { change as changeQuote } from 'actions/quote/change';
import { change as changePurchaseOrder } from 'actions/purchase-order/change';
import { change as changeInvoice } from 'actions/invoice/change';
import { selectDocument } from 'actions/user-companies/select';
import { create as createReceipt, error as errorCreateReceipt, loading as loadingCreateReceipt, success as successCreateReceipt } from 'actions/receipt/create';
import { error, loading, success } from 'actions/stock-booking/create';
import { reset as resetListCompanySettings } from 'actions/company-settings/list';
import { reset as resetUpdateQuote, update as updateQuote } from 'actions/quote/update';
import moment from 'moment';
import ToInvoiceModal from 'routes/admin/business/ToInvoiceModal';
import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLinkWithButton from 'layouts/subNav/subNavLinkWithButton';
import SubNavLink from 'layouts/subNav/SubNavLink';
import SelectedTypeMenu from 'routes/admin/business/SelectedTypeMenu';
import QuoteToInvoiceModal from 'routes/admin/business/QuoteToInvoiceModal';
import WorkOrderModal from 'components/WorkOrderModal';
import DeliveryFormModal from 'components/DeliveryFormModal';
import { getDocumentType, isInvoice, isPurchaseOrder, isQuote } from 'utils/documentType';
import ComingSoon from 'components/ComingSoon';
import { Entities } from 'types/accessRights';
import CheckRights from 'components/access/CheckRights';
import Routes from 'routes/admin/business/routes';
import RouteNotFound from 'routes/RouteNotFound';
import DocumentPrinter from 'routes/admin/business/DocumentPrinter';
import { setOpen } from 'actions/modal/PurchaseOrderToInvoice';
import { isPrimaryType } from 'components/documents/documentOptions';
import HomeBusiness from './home';
import Invoice from './invoice';
import PurchaseOrder from './purchase-order';
import Quotes from './quote';
import Models from './document-model';
import CreditNotes from './credit-note';
import DeliveryForm from './delivery-form';
import WorkOrders from './work-orders';
import SupplierOrders from './supplier-order';

class Business extends Component {
  state = {
    dataQuoteReason: [],
    openModal: false,
    selectedQuoteReason: '',
    errorSelectedQuoteReason: false,
    link: '',
    typeDocument: '',
    documentReceipt: null,
  };


  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  changeDocument = (changeLink) => {
    const { link, typeDocument } = this.state;

    const {
      postChangeQuote,
      postChangePurchaseOrder,
      postChangeInvoice,
      selectedDocument,
    } = this.props;

    const quoteMethod = typeDocument === 'LOST' ? 'PUT' : 'POST';

    switch (selectedDocument['@type']) {
      case 'Quote':
        postChangeQuote(`${selectedDocument['@id']}${link || changeLink}`, quoteMethod);
        break;
      case 'PurchaseOrder':
        postChangePurchaseOrder(`${selectedDocument['@id']}${changeLink}`, 'POST');
        break;
      case 'Invoice':
        postChangeInvoice(`${selectedDocument['@id']}${changeLink}`, 'POST');
        break;
      default:
        break;
    }
  };

  openCompanySettingsModal = (link, typeDocument) => {
    this.setState({
      openModal: true,
      link,
      typeDocument,
    });
  };

  closeCompanySettingsModal = () => {
    this.setState({
      openModal: false,
      link: null,
    });
  };

  submitReason = (quoteVictoryStatus) => {
    const { selectedQuoteReason, link } = this.state;
    const { selectedDocument, updateQuote, openToInvoice } = this.props;

    let isValid = true;

    if (selectedQuoteReason === '') {
      isValid = false;
      this.setState({
        errorSelectedQuoteReason: true,
      });
    }

    if (!isValid) {
      return;
    }

    const item = {
      reason: selectedQuoteReason,
    };

    updateQuote(selectedDocument, item);

    this.setState({
      openModal: false,
    });
    if (quoteVictoryStatus !== 'LOST' && link === '/to_invoice') {
      openToInvoice();
    } else {
      this.changeDocument();
    }
  };

  handleSelectReason = ({ name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  createReceipt = () => {
    const { selectedDocument, postReceipt } = this.props;

    const data = {
      customer: selectedDocument.customer['@id'],
      company: selectedDocument.company,
      content: selectedDocument.content,
      totalPrice: selectedDocument.totalPrice.toString(),
      totalVAT: selectedDocument.totalVAT.toString(),
      customerComment: selectedDocument.customerComment,
      reference: selectedDocument.reference,
      creationDate: moment().format('YYYY-MM-DD'),
      comment: selectedDocument.comment,
    };

    if (selectedDocument['@type'] === 'Invoice') {
      data.invoice = selectedDocument['@id'];
      data.purchaseOrder = selectedDocument.purchaseOrder
        ? selectedDocument.purchaseOrder['@id']
        : null;
    } else {
      data.purchaseOrder = selectedDocument['@id'];
      data.invoice = selectedDocument.invoice
        ? selectedDocument.invoice['@id']
        : null;
    }

    postReceipt(data);
  };


  render() {
    const { selectedCompany, selectedDocument, t } = this.props;

    const {
      selectedQuoteReason,
      openModal,
      errorSelectedQuoteReason,
      dataQuoteReason,
      typeDocument,
      documentReceipt,
    } = this.state;

    const selectedTypeMenu = () => (
      <SelectedTypeMenu
        documentReceipt={documentReceipt}
        changeDocument={this.changeDocument}
        openCompanySettingsModal={this.openCompanySettingsModal}
        createReceipt={this.createReceipt}
      />
    );

    const isPrimary = selectedDocument && isPrimaryType(getDocumentType(selectedDocument));

    return (
      <DocumentPrinter>
        <SubNav hide={!selectedCompany}>
          <SubNavTitle title={t('companiesBusinessManagement')} />

          <SubNavLinkWithButton
            entity={Entities.quote}
            to={Routes.listQuotes}
            title={t('quotesHomeTitle')}
          />
          <CheckRights entity={Entities.quote}>
            {isQuote(selectedDocument) && (selectedTypeMenu())}
          </CheckRights>

          <CheckRights entity={Entities.purchaseOrder}>
            <SubNavLinkWithButton to={Routes.listPurchaseOrders} entity={Entities.purchaseOrder}>
              {t('purchaseOrderHomeTitle')}
            </SubNavLinkWithButton>
            {isPurchaseOrder(selectedDocument) && (selectedTypeMenu())}
          </CheckRights>

          <CheckRights entity={Entities.invoice}>
            <SubNavLinkWithButton to={Routes.listInvoices} entity={Entities.invoice}>
              {t('invoiceHomeTitle')}
            </SubNavLinkWithButton>
            {isInvoice(selectedDocument) && (selectedTypeMenu())}
          </CheckRights>

          <CheckRights entity={Entities.supplyOrder}>
            <SubNavLinkWithButton to={Routes.listSupplierOrders} entity={Entities.supplyOrder}>
              {t('supplierOrderHomeTitle')}
            </SubNavLinkWithButton>
            {/* isSupplierOrder(selectedDocument) && (selectedTypeMenu()) */}
          </CheckRights>

          <SubNavLink entity={Entities.creditNote} to={Routes.listCreditNotes}>
            {t('creditNoteHomeTitle')}
          </SubNavLink>
          <SubNavLink entity={Entities.deliveryForm} to={Routes.listDeliveryForms}>
            {t('deliveryFormHomeTitle')}
          </SubNavLink>
          <SubNavLink entity={Entities.workOrder} to={Routes.listWorkOrders}>
            {t('workOrderTitle')}
          </SubNavLink>
          <SubNavLink entity={Entities.documentModel} to={Routes.listDocumentModels}>
            {t('modelHomeTitle')}
          </SubNavLink>
          <SubNavLink hide to="/compta">{t('modelExportCompta')}</SubNavLink>
        </SubNav>

        <ToInvoiceModal />

        <QuoteToInvoiceModal
          dataQuoteReason={dataQuoteReason}
          open={openModal}
          typeDocument={typeDocument}
          selectedQuoteReason={selectedQuoteReason}
          errorSelectedQuoteReason={errorSelectedQuoteReason}
          selectReason={this.handleSelectReason}
          closeCompanySettingsModal={this.closeCompanySettingsModal}
          submitWonReason={this.submitReason}
        />

        {isPrimary && <WorkOrderModal />}
        {isPrimary && <DeliveryFormModal />}

        { /*
          This will need a store-based open mechanism if used again
          <StockBookingModal />
        */}

        <Switch>
          <Route exact entity={Entities.invoice} path={Routes.home}>
            <HomeBusiness />
          </Route>
          <Route exact path={Routes.createModel}><ComingSoon /></Route>
          <Route exact path={Routes.order}><ComingSoon /></Route>
          <Route exact path={Routes.export}><ComingSoon /></Route>
          <Route entity={Entities.invoice} path={Routes.listInvoices}>
            <Invoice />
          </Route>
          <Route entity={Entities.purchaseOrder} path={Routes.listPurchaseOrders}>
            <PurchaseOrder />
          </Route>
          <Route entity={Entities.quote} path={Routes.listQuotes}>
            <Quotes />
          </Route>
          <Route entity={Entities.documentModel} path={Routes.listDocumentModels}>
            <Models />
          </Route>
          <Route entity={Entities.creditNote} path={Routes.listCreditNotes}>
            <CreditNotes />
          </Route>
          <Route entity={Entities.deliveryForm} path={Routes.listDeliveryForms}>
            <DeliveryForm />
          </Route>
          <Route entity={Entities.workOrder} path={Routes.listWorkOrders}>
            <WorkOrders />
          </Route>
          <Route entity={Entities.supplierOrder} path={Routes.listSupplierOrders}>
            <SupplierOrders />
          </Route>
          <RouteNotFound />
        </Switch>
      </DocumentPrinter>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: quote => dispatch(selectDocument(quote)),
  postReceipt: data => dispatch(createReceipt(data)),
  postChangeQuote: (data, method) => dispatch(changeQuote(data, method)),
  postChangePurchaseOrder: (data, method) => dispatch(changePurchaseOrder(data, method)),
  postChangeInvoice: (data, method) => dispatch(changeInvoice(data, method)),
  updateQuote: (item, value) => dispatch(updateQuote(item, value)),
  openToInvoice: () => dispatch(setOpen(true)),
  reset: () => {
    dispatch(success(null));
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(resetListCompanySettings());
    dispatch(resetUpdateQuote());

    dispatch(successCreateReceipt(null));
    dispatch(loadingCreateReceipt(false));
    dispatch(errorCreateReceipt(null));
  },
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Business);

export default withTranslation()(Main);
