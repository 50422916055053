import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ComplexWithNumberDTO } from '../../types/dto';
import FormatCell from '../cell/FormatCell';

interface OwnProps {
  data: ComplexWithNumberDTO[];
}

type Props = OwnProps;

const Payments: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Table striped className="table-dashboard">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('dashboard_payments')}</Table.HeaderCell>
          {data.map((item, index) => (
            <Table.HeaderCell className="format-number-fr" key={index}>
              {t(item.label)}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{t('dashboard_number')}</Table.Cell>
          {data.map((item, index) => (
            <FormatCell key={index}>{item.number}</FormatCell>
          ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('dashboard_beforeTaxes')}</Table.Cell>
          {data.map((item, index) => (
            <FormatCell key={index}>{item.amount}</FormatCell>
          ))}
        </Table.Row>
        {/* <Table.Row>
          <Table.Cell>{t('dashboard_growth')}</Table.Cell>
          {data.map((item, index) => (item.growth
            ? (
              <FormatCell color key={index}>{item.growth}</FormatCell>
            )
            : (<Table.Cell key={index} />)))}
        </Table.Row> */}
      </Table.Body>
    </Table>
  );
};

export default Payments;
