import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import QuestionTooltip from '../../../../components/icons/QuestionTooltip';
import CurrencyCell from '../../../../components/cell/CurrencyCell';
import PercentageCell from '../../../../components/cell/PercentageCell';
import LeftCell from '../../../../layouts/table/LeftCell';
import RightCell from '../../../../layouts/table/RightCell';
import { RowTuple } from './forecastTypes';

interface OwnProps {
  label: string;
  tooltip?: string;
  data?: RowTuple;
}

type Props = OwnProps;

const ForecastMonetaryRow: FC<Props> = ({ label, tooltip, data = {} }) => (
  <Table.Row>
    <LeftCell>
      {label}
      {tooltip && <QuestionTooltip tip={tooltip} />}
    </LeftCell>
    <CurrencyCell value={data.realised} />
    <CurrencyCell value={data.planned} color noGreen />
    {data.percentage !== 0
      ? <PercentageCell showPositive color noGreen value={data.percentage} />
      : <RightCell />
    }
  </Table.Row>
);

export default ForecastMonetaryRow;
