import React, { Component } from 'react';
import { connect } from 'react-redux';
import { retrieve, update } from 'actions/company-settings/update';
import { find, findIndex, isEmpty } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { actionItemSelected, netTotalBeforeTaxes, showCalculation, totalBeforeTaxes } from 'actions/document/liveDocument';
import { Checkbox, Form, Grid, Input, Select, Table } from 'semantic-ui-react';
import { success as setNewListCompanySettings } from 'actions/company-settings/list';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { EssorButton, TextEditor } from 'components';
import { getCalculationClass } from 'utils/marginCalculation';
import { isDraft, isNotDraft } from 'utils/documentStatus';
import { getArticles, hasComment, isLayoutLine } from 'components/documents/documentOptions';
import VisibilityHeader from 'components/documents/visibilityHeader';
import CompanyAddress from 'components/documents/companyAddress';
import LayoutBodyLine from 'components/documents/LayoutBodyLine';
import ArticleBodyLine from 'components/documents/ArticleBodyLine';
import TotalInputCell from 'routes/admin/business/TotalInputCell';
import DiscountInput from 'components/input/DiscountInput';
import SecondLineWrapper from 'components/documents/SecondLineWrapper';
import { changeItem, getActualTotal, getActualVat } from 'components/documents/documents';
import { isEnsemble, isProduct } from 'components/documents/articles';
import RowColumn from 'layouts/RowColumn';
import SmallForm from 'layouts/SmallForm';
import RecipientAddress from 'components/documents/RecipientAddress';
import Info from 'layouts/Info';
import { CREDIT_NOTE, DELIVERY_FORM, DOCUMENT_MODEL, INVOICE, isInvoice, QUOTE, WORK_ORDER } from 'utils/documentType';
import TotalLine from 'components/documents/TotalLine';
import Notes from 'components/documents/Notes';
import KeyValue from 'components/keyValue';
import { Big, keyVal } from 'utils/functions';
import DraftEditor from 'components/draftEditor/DraftEditor';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';
import FloatCell from 'components/cell/FloatCell';
import PercentageSelect from 'components/input/PercentageSelect';
import DocumentContainer from 'components/documents/DocumentContainer';
import { ErrorBoundary } from '@sentry/react';
import { getDocumentTypeLabel, isEditable, lineOptions, showPrices } from './documentOptions';
import DocumentReference from './reference';
import DocumentCreationDate from './creationDate';
import RelatedDocuments from './RelatedDocuments';
import { getItemFromArticle, getItemFromItem } from './articles';
import PaymentLegalText from './paymentLegalText';
import { invoiceOrigin } from './documentCommon';

moment.locale('fr');

const textArticle = '<p><u><strong>Article L441-6 du Code de Commerce</strong></u></p><p>La présente facture sera payable au plus tard dans les 7 jours suivant sa date d&#39;édition. Passé ce délai, sans obligation d&#39;envoi d&#39;une relance, conformément à l&#39;article L441 - 6 du Code de Commerce il sera appliqué une pénalité calculée à un taux annuel de 12 % sans que ce taux soit inférieur à 3 fois le taux d&#39;intérêt légal. Une indemnité forfaitaire de recouvrement de 40 Euros sera aussi exigible.</p>';

class Document extends Component {
  state = {
    selectedItemList: [],
    defaultRate: null,
    selectedLine: null,

    globalDiscount: 0,
    globalDiscountType: 'e',
    creditNoteAmount: 0,
    dueAmount: 0,
    alreadyBilled: Big(0),
    partialInvoice: false,

    visibility: {
      reference: true,
      label: true,
      unitCost: true,
      unit: true,
      vat: true,
      quantity: true,
      discount: true,
      total: true,
    },

    reference: '',
    creationDate: moment(),
    responseDate: moment().add(15, 'd'),
    subject: '',
    showLatePaymentLawText: false,
    latePaymentLawText: textArticle,
    note: '',

    subjectError: false,
    selectedListError: false,
    creationDateError: false,
    responseDateError: false,

    vat: [],
    totalPrice: 0,
    netTotalPrice: 0,
    totalTax: 0,
    activityFormatData: [],
    activitySecondFormatData: [],

    handleChangeItem: (value, item, name) => {
      const { selectedItemList } = this.state;
      const { actionItemSelected } = this.props;
      const index = findIndex(selectedItemList, keyVal('order', item.order));

      selectedItemList[index] = changeItem(value, item, name, this.marginCalc());
      actionItemSelected([...selectedItemList]);
      this.setState({
        selectedItemList,
      }, this.setVatValues);
    },

    handleChangeSelectItem: (e, data, item) => {
      const {
        name,
        value,
      } = data;
      const { selectedItemList } = this.state;
      const { actionItemSelected } = this.props;

      const itemIndex = findIndex(selectedItemList, keyVal('order', item.order));

      item[name] = value;

      if (name === 'activity1') {
        const { activityFormatData } = this.state;
        let index = findIndex(activityFormatData, keyVal('label', value));
        index = index >= 0 ? index : findIndex(activityFormatData, keyVal('id', value));
        selectedItemList[itemIndex].activity1 = index;
        selectedItemList[itemIndex].activity2 = null;
      }

      if (item.discountType === 'p') {
        item.discount = 100 - (item.totalPrice * 100 / (item.price * item.quantity));
      } else if (item.discountType === 'e') {
        item.discount = (item.price * item.quantity) - item.totalPrice;
      }
      selectedItemList[itemIndex] = item;
      actionItemSelected([...selectedItemList]);
      this.setState({
        selectedItemList,
      }, this.setVatValues);
    },

    updateContext: (key, value) => (
      this.setState({
        [key]: value,
      }, this.setVatValues)
    ),

    // handleDeleteItem: () => this.handleDeleteItem(),
    // handleItemCheckBoxChange: () => this.handleItemCheckBoxChange(),
    // handleOnBlurItemPrice: () => this.handleOnBlurItemPrice(),
    // setLabel: () => this.setLabel(),
  };

  updateBilled = () => {
    const { documentData, type } = this.props;
    const { partialInvoice, netTotalPrice, totalTax, dueAmount } = this.state;

    let alreadyBilled = Big(0);
    if (type === INVOICE && !partialInvoice) {
      const origin = invoiceOrigin(documentData);

      if (origin && origin.invoice && origin.invoice.length > 1) {
        origin.invoice.forEach((invoice) => {
          if (isInvoice(invoice)) {
            alreadyBilled = alreadyBilled
              .plus(getActualTotal(invoice))
              .plus(getActualVat(invoice));
          }
        });
      }
      const leftToBill = Big(netTotalPrice + totalTax).minus(alreadyBilled);
      if (!alreadyBilled.plus(Big(dueAmount)).eq(Big(netTotalPrice + totalTax))) {
        this.setBilled(leftToBill, alreadyBilled);
      }
    }
  };

  componentDidMount() {
    const {
      documentData,
      type,
      actionItemSelected,
      customer,
      showCalculation,
    } = this.props;

    showCalculation(!isEmpty(customer));

    if (!isEmpty(documentData)) {
      const selectedItemList = documentData.content.body.map((article, index) => (
        getItemFromArticle(article, type, true, null, index)
      ));

      actionItemSelected(selectedItemList);

      this.setState({
        content: documentData.content,
        latePaymentLawText: documentData.content.latePaymentLawText || textArticle,
        restartLatePaymentLawText: documentData.content.latePaymentLawText,
        documentId: documentData.documentId,
        creationDate: documentData.creationDate || moment(),
        responseDate: documentData.responseDate || moment().add(15, 'd'),
        reference: documentData.reference,
        note: (documentData.content.messages || {}).customerNotice || '',

        showLatePaymentLawText: documentData.content.showLatePaymentLawText || true,

        visibility: documentData.content.visibility,
        globalDiscount: documentData.content.globalDiscount,
        globalDiscountType: documentData.content.globalDiscountType,
        partialInvoice: documentData.content.partialInvoice || false,
        subject: documentData.content.subject,
        quote: documentData.quote,
        purchaseOrder: documentData.purchaseOrder,
        invoice: documentData.invoice,
        selectedItemList,
      }, this.setVatValues);
    } else {
      this.setState({
        content: {},
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const retrievedActivities = find(nextProps.listCompanySettings['hydra:member'], keyVal('name', 'ACTIVITIES'));

    if (!isEmpty(nextProps.documentData.content)
      && (JSON.stringify(nextProps.documentData.content) !== JSON.stringify(prevState.content)
        || nextProps.documentData.documentId !== prevState.documentId)) {
      const selectedItemList = nextProps.documentData.content.body.map((article, index) => (
        getItemFromArticle(article, nextProps.type, true, null, index)
      ));
      nextProps.actionItemSelected([...selectedItemList]);
      return {
        content: nextProps.documentData.content,
        latePaymentLawText: nextProps.documentData.content.latePaymentLawText,
        restartLatePaymentLawText: nextProps.documentData.content.latePaymentLawText,
        documentId: nextProps.documentData.documentId,
        creationDate: nextProps.documentData.creationDate || prevState.creationDate,
        responseDate: nextProps.documentData.responseDate || prevState.responseDate,
        reference: nextProps.documentData.reference,
        note: (nextProps.documentData.content.messages || {}).customerNotice || '',

        showLatePaymentLawText: nextProps.documentData.content.showLatePaymentLawText || false,

        visibility: nextProps.documentData.content.visibility,
        globalDiscount: nextProps.documentData.content.globalDiscount,
        globalDiscountType: nextProps.documentData.content.globalDiscountType,
        partialInvoice: nextProps.documentData.content.partialInvoice || false,
        creditNoteAmount: nextProps.documentData.content.creditNoteAmount || 0,
        dueAmount: nextProps.documentData.content.total.dueAmount || 0,
        subject: nextProps.documentData.content.subject,
        selectedItemList,
      };
    }

    if (!isEmpty(retrievedActivities)
      && (prevState.activitySecondFormatData !== retrievedActivities.value)) {
      return {
        activityFormatData: retrievedActivities.value,
        activitySecondFormatData: retrievedActivities.value,
      };
    }

    if (!isEmpty(nextProps.documentData.content)
      && nextProps.documentData.documentId !== prevState.documentId) {
      return keyVal('documentId', nextProps.documentData.documentId);
    }

    if (!isEmpty(nextProps.listCompanySettings)) {
      const vatRates = find(nextProps.listCompanySettings['hydra:member'], keyVal('name', 'VAT_RATES'));

      let rate;

      if (vatRates) {
        for (let i = 0; i < vatRates.value.length; i++) {
          ({ rate } = vatRates.value[i]);

          if (vatRates.value[i].default) {
            return keyVal('defaultRate', rate);
          }
        }
      }
    }

    return null;
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate(prevProps, prevState) {
    const { content } = this.state;
    const {
      validData,
      validContent,
    } = this.props;

    if (content !== prevState.content) {
      this.setVatValues();
    }

    if (validData && validData !== prevProps.validData) {
      this.validateForm();
    }

    if (validContent && validContent !== prevProps.validContent) {
      this.returnContent();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setDueAmount = (value) => {
    this.setState({
      dueAmount: value,
    });
  }

  setBilled = (leftToBill, alreadyBilled) => {
    this.setState({
      dueAmount: leftToBill,
      alreadyBilled,
    });
  }

  setCreditNoteAmount = (value) => {
    this.setState({
      creditNoteAmount: value,
    });
  };

  setGlobalDiscount = (value) => {
    this.setState(
      {
        globalDiscount: value,
      },
      () => {
        this.setVatValues();
      },
    );
  };

  setPartial = (e, field) => {
    const { checked } = field;

    this.setState({
      partialInvoice: !checked,
    }, this.updateBilled);
  };

  marginCalc = () => {
    const { selectedCompany } = this.props;

    return getCalculationClass(selectedCompany);
  };

  postActivities = () => {
    const { activityFormatData } = this.state;
    const {
      updateActivities,
      listCompanySettings,
    } = this.props;

    const retrievedActivities = find(listCompanySettings['hydra:member'], keyVal('name', 'ACTIVITIES'));

    updateActivities(retrievedActivities, keyVal('value', activityFormatData));

    listCompanySettings['hydra:member'].forEach((list) => {
      if (list.name === 'ACTIVITIES') {
        list.value = activityFormatData;
        return list;
      }
      return list;
    });

    setNewListCompanySettings(listCompanySettings);
  };

  validateForm = () => {
    const {
      getData,
      selectedCompany,
      type,
      documentData,
    } = this.props;

    const {
      creationDate,
      creditNoteAmount,
      dueAmount,
      globalDiscount,
      globalDiscountType,
      latePaymentLawText,
      netTotalPrice,
      note,
      partialInvoice,
      reference,
      responseDate,
      restartLatePaymentLawText,
      selectedItemList,
      showLatePaymentLawText,
      subject,
      totalPrice,
      totalTax,
      vat,
      visibility,
    } = this.state;

    const arrayError = [];

    this.setState({
      subjectError: false,
      selectedListError: false,
      creationDateError: false,
      responseDateError: false,
    });

    let isValid = true;

    if (type !== WORK_ORDER && selectedItemList.length === 0) {
      isValid = false;
      arrayError.push('documentMessageErrorSelectedList');

      this.setState({
        selectedListError: true,
      });
    }

    if (type !== DOCUMENT_MODEL && subject.trim() === '') {
      isValid = false;
      arrayError.push('documentMessageErrorSubject');

      this.setState({
        subjectError: true,
      });
    }

    if (!creationDate) {
      isValid = false;
      arrayError.push('documentMessageErrorCreationDate');

      this.setState({
        creationDateError: true,
      });
    }

    if (type === QUOTE && (!responseDate || responseDate.isBefore(creationDate, 'day'))) {
      isValid = false;
      !responseDate
        ? arrayError.push('documentMessageErrorResponseDate')
        : arrayError.push('documentMessageErrorDateWrong');

      this.setState({
        responseDateError: true,
      });
    }

    if (!isValid) {
      getData(null, arrayError);
      return;
    }

    const body = selectedItemList.map(
      item => getItemFromItem(item, type, selectedCompany.calculationMode),
    );

    const total = {
      beforeTaxes: totalPrice,
      withDiscount: netTotalPrice,
      vat,
      withTaxes: netTotalPrice + totalTax,
    };

    const messages = keyVal('customerNotice', note);

    const content = {
      body,
      total,
      subject,
      globalDiscount,
      globalDiscountType,
      visibility,
      messages,
    };

    if (type === CREDIT_NOTE) {
      content.creditNoteAmount = creditNoteAmount;
    }

    if (type === DOCUMENT_MODEL) {
      getData({
        content,
      });
      return;
    }

    if (type === INVOICE) {
      content.showLatePaymentLawText = showLatePaymentLawText;
      if (showLatePaymentLawText) {
        content.latePaymentLawText = latePaymentLawText;
      } else {
        isEmpty(documentData)
          ? content.latePaymentLawText = textArticle
          : content.latePaymentLawText = restartLatePaymentLawText;
      }
    }

    const data = {
      content,
      reference: isEmpty(reference) ? '' : reference,
      totalPrice: netTotalPrice.toString(),
      totalVAT: totalTax.toString(),
    };

    if (type === QUOTE) {
      data.responseDate = responseDate.format();
    }

    if (type === INVOICE) {
      data.content.partialInvoice = partialInvoice;
      if (dueAmount > 0) {
        data.content.total.dueAmount = dueAmount;
      }
    }

    if (type !== WORK_ORDER && type !== DELIVERY_FORM) {
      data.creationDate = creationDate.format();
    }

    getData(data);
  };

  returnContent = () => {
    const { selectedItemList } = this.state;
    const { getContent } = this.props;

    getContent(selectedItemList);
  };

  setVatValues = () => {
    const {
      selectedItemList,
      globalDiscount,
      globalDiscountType,
    } = this.state;
    const {
      totalBeforeTaxes,
      netTotalBeforeTaxes,
    } = this.props;

    const vat = [];
    let discountPercent = 0;
    let totalPrice = 0;
    let totalTax = 0;

    if (!isEmpty(selectedItemList)) {
      selectedItemList.forEach((item) => {
        if (item.price && !item.optional) {
          totalPrice += item.totalPrice;
        }
      });

      if (parseFloat(globalDiscount) > 0) {
        if (globalDiscountType === 'e') {
          discountPercent = (globalDiscount * 100) / totalPrice;
        } else {
          discountPercent = globalDiscount;
        }
      }

      selectedItemList.forEach((item, index) => {
        if (item.price && !item.optional) {
          let alreadyAdded;

          if (isEnsemble(item)) {
            item.items.forEach((article) => {
              alreadyAdded = findIndex(vat, keyVal('percentage', article.item.vatRate));

              if (alreadyAdded !== -1) {
                return;
              }

              const obj = this.parseVAT(
                article.item.vatRate, selectedItemList, index, discountPercent,
              );
              totalTax += obj.value;
              vat.push(obj);
            });
          } else if (item.vatRate !== undefined) {
            alreadyAdded = findIndex(vat, keyVal('percentage', item.vatRate));

            if (alreadyAdded !== -1) {
              return;
            }

            const obj = this.parseVAT(item.vatRate, selectedItemList, index, discountPercent);
            totalTax += obj.value;
            vat.push(obj);
          }
        }
      });
    }

    let netTotalPrice = totalPrice;

    if (parseFloat(globalDiscount) > 0) {
      if (globalDiscountType === 'e') {
        netTotalPrice -= globalDiscount;
      } else {
        netTotalPrice = (100 - globalDiscount) * netTotalPrice / 100;
      }
    }

    totalBeforeTaxes(totalPrice);
    netTotalBeforeTaxes(netTotalPrice);

    this.setState({
      vat,
      totalPrice,
      netTotalPrice,
      totalTax,
    }, this.updateBilled);
  };

  parseVAT = (percentage, selectedItemList, index, discountPercent) => {
    let totalForRate = 0;
    for (let i = index; i < selectedItemList.length; i++) {
      if (isEnsemble(selectedItemList[i]) && !selectedItemList[i].optional) {
        for (let n = 0; n < selectedItemList[i].items.length; n++) {
          if (selectedItemList[i].items[n].item.vatRate === percentage) {
            const { totalPrice } = selectedItemList[i].items[n].item;
            totalForRate += totalPrice * selectedItemList[i].quantity;
          }
        }
      } else if (selectedItemList[i].vatRate === percentage
        && !selectedItemList[i].optional) {
        totalForRate += selectedItemList[i].totalPrice;
      }
    }

    return {
      percentage,
      value: ((totalForRate * percentage) / 100) * ((100 - discountPercent) / 100),
    };
  };

  getSubtotal = (index) => {
    const { selectedItemList } = this.state;

    let subtotal = 0;

    for (let i = index - 1; i >= 0; i--) {
      if (selectedItemList[i].type === 'subtotal') {
        break;
      } else if (selectedItemList[i].totalPrice && !selectedItemList[i].optional) {
        subtotal += selectedItemList[i].totalPrice;
      }
    }

    return subtotal.toFixed(2);
  };

  handleVisibility = (name) => {
    const { documentData } = this.props;

    if (isNotDraft(documentData)) {
      return;
    }

    this.setState(prevState => ({
      visibility: {
        ...prevState.visibility,
        [name]: !prevState.visibility[name],
      },
    }));
  };

  handleCreationDateChange = (creationDate) => {
    this.setState({
      creationDate,
    });
  };

  handleResponseDateChange = (responseDate) => {
    this.setState({
      responseDate,
    });
  };

  handleCheckBoxChange = (e, { name }) => {
    e.preventDefault();

    this.setState(prevState => (
      {
        [name]: !prevState[name],
      }
    ));
  };

  handleItemCheckBoxChange = (e, item) => {
    const { selectedItemList } = this.state;

    selectedItemList[item.order].optional = !selectedItemList[item.order].optional;

    this.setState({
      selectedItemList,
    }, this.setVatValues);
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const {
      name,
      value,
    } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleDiscountType = (e, { value }) => {
    e.preventDefault();
    const {
      totalPrice,
      globalDiscount,
      globalDiscountType,
    } = this.state;
    let discount = globalDiscount;

    if (globalDiscountType !== value) {
      if (value === 'p') {
        discount = 100 - ((totalPrice - globalDiscount) * 100) / totalPrice;
      } else if (value === 'e') {
        discount = (totalPrice * globalDiscount) / 100;
      }
    }

    this.setState({
      globalDiscountType: value,
      globalDiscount: discount,
    }, this.setVatValues);
  };

  handleSelectLine = (e, {
    name,
    value,
  }) => {
    e.preventDefault();

    this.setState({
      [name]: value,
    });
  };

  getNextItemId = () => {
    const { selectedItemList } = this.state;

    if (selectedItemList.length === 0) {
      return 0;
    }

    const { max } = Math;
    return max.apply(Math, selectedItemList.map(item => item.order || 0)) + 1;
  };

  handleAddLine = () => {
    const {
      selectedLine,
      selectedItemList,
    } = this.state;

    if (!selectedLine) {
      return;
    }

    const line = {
      type: selectedLine,
      order: this.getNextItemId(),
    };

    if (selectedLine === 'comment') {
      line.comment = '';
    }
    selectedItemList.push(line);

    this.setState({
      selectedLine: null,
      selectedItemList,
    });
  };

  handleAddItem = (e, { value }) => {
    const {
      selectedItemList,
      defaultRate,
    } = this.state;
    const {
      listArticle,
      actionItemSelected,
    } = this.props;
    const item = JSON.parse(value);

    item.order = this.getNextItemId();

    if (isEnsemble(item)) {
      let obj = null;

      item.items.forEach((article, index) => {
        const objIndex = findIndex(listArticle, keyVal('id', article.item));

        obj = Object.assign({}, listArticle[objIndex]);

        if (isProduct(obj)) {
          obj.soldAs = 'md';
          obj.unitCost = obj.price;
          obj.price = this.marginCalc().getBigPriceFromCost(Big(obj.unitCost), Big(obj.margin));
        } else {
          obj.soldAs = 'h';
        }

        obj.activity1 = null;
        obj.activity2 = null;
        obj.vatRate = defaultRate;
        // obj.discountType = 'p';
        // obj.discount= 0;
        obj.totalPrice = obj.price * article.quantity;
        obj.originalPrice = obj.price;
        item.items[index].item = obj;
      });
    } else if (isProduct(item)) {
      item.unitCost = item.price;
      item.soldAs = 'md';
      item.vatRate = defaultRate;
      item.activity1 = null;
      item.activity2 = null;

      item.price = this.marginCalc().getBigPriceFromCost(Big(item.unitCost), Big(item.margin));
    } else {
      item.soldAs = 'h';
      item.vatRate = defaultRate;
      item.activity1 = null;
      item.activity2 = null;
    }

    item.discountType = 'e';
    item.discount = 0;
    item.quantity = 1;
    item.optional = false;
    item.totalPrice = item.price * item.quantity;

    selectedItemList.push(item);

    actionItemSelected([...selectedItemList]);
    this.setState({
      selectedItemList,
    }, this.setVatValues);
  };

  handleAddition = (e, data, item, article) => {
    const {
      value,
      name,
    } = data;
    const {
      activityFormatData,
      selectedItemList,
    } = this.state;

    const indexCreate = isEmpty(activityFormatData) ? 0 : activityFormatData.length;
    const index = findIndex(selectedItemList, keyVal('order', item.order));
    let ensembleArticle = '';

    if (article) {
      ensembleArticle = findIndex(item.items, keyVal('item', article.item));
    }

    if (name === 'activity1') {
      const objectItem = {
        id: indexCreate,
        label: value,
        items: [],
      };
      if (article) {
        selectedItemList[index].items[ensembleArticle].item.activity1 = objectItem.id;
      } else {
        selectedItemList[index].activity1 = objectItem.id;
      }

      this.setState({
        activityFormatData: [...activityFormatData, objectItem],
        selectedItemList,
        objectItem,
      });
    } else {
      let indexCreateItems;
      if (article) {
        indexCreateItems = isEmpty(activityFormatData[article.item.activity1].items)
          ? 0
          : activityFormatData[article.item.activity1].items.length;
      } else {
        indexCreateItems = isEmpty(activityFormatData[item.activity1].items)
          ? 0
          : activityFormatData[item.activity1].items.length;
      }
      const objectItem = {
        id: indexCreateItems,
        label: value,
      };

      if (article) {
        activityFormatData[article.item.activity1].items.push(objectItem);
        selectedItemList[index].items[ensembleArticle].item.activity2 = objectItem.id;
      } else {
        activityFormatData[item.activity1].items.push(objectItem);
        selectedItemList[index].activity2 = objectItem.id;
      }
      this.setState({
        activityFormatData,
        objectItem,
        selectedItemList,
      }, () => {
        const { selectedItemList } = this.state;
        item[name] = objectItem.id;
        this.setState({
          selectedItemList,
        });
      });
    }

    this.postActivities();
  };

  setComment = (item, editorContent) => {
    const { selectedItemList } = this.state;

    const index = findIndex(selectedItemList, keyVal('order', item.order));

    selectedItemList[index].comment = editorContent;

    this.setState({
      selectedItemList,
    });
  };

  handleEnsembleSelectChange = (e, data, item, article) => {
    const {
      value,
      name,
    } = data;
    const { selectedItemList } = this.state;

    const ensembleIndex = findIndex(selectedItemList, keyVal('order', item.order));

    const ensembleArticle = findIndex(item.items, keyVal('item', article.item));

    article.item[name] = value;
    item.items[ensembleArticle] = article;
    selectedItemList[ensembleIndex] = item;

    if (name === 'activity1') {
      article.item.activity2 = null;
    }
    actionItemSelected([...selectedItemList]);
    this.setState({
      selectedItemList,
    }, () => {
      if (name === 'vatRate') {
        this.setVatValues();
      }
    });
  };

  handleDeleteItem = (item, itemIndex = null) => {
    const { selectedItemList } = this.state;
    const { actionItemSelected } = this.props;

    const index = itemIndex || findIndex(selectedItemList, keyVal('order', item.order));

    selectedItemList.splice(index, 1);
    actionItemSelected(selectedItemList);

    const tmpList = selectedItemList.map((item, index) => ({
      ...item,
      order: index,
    }));

    this.setState({
      selectedItemList: tmpList,
    }, this.setVatValues);
  };

  setLabel = (item, editorContent, article) => {
    const { selectedItemList } = this.state;

    let getIndexItem = findIndex(selectedItemList, keyVal('order', item.order));
    if (!isEmpty(article)) {
      getIndexItem = findIndex(selectedItemList, keyVal('order', article.order));
      const ensembleArticle = findIndex(article.items, {
        item,
      });

      selectedItemList[getIndexItem].items[ensembleArticle].item.label = editorContent;
    } else {
      selectedItemList[getIndexItem].label = editorContent;
    }

    this.setState({
      selectedItemList,
    });
  };

  setItemCost = (item, cost, article = undefined) => {
    const { selectedItemList } = this.state;
    const unitCost = Big(cost || 0).valueOf();

    let getIndexItem = findIndex(selectedItemList, keyVal('order', item.order));
    if (article) {
      getIndexItem = findIndex(selectedItemList, keyVal('order', article.order));
      const ensembleArticle = findIndex(article.items, {
        item,
      });

      selectedItemList[getIndexItem].items[ensembleArticle].item.unitCost = unitCost;
    } else {
      selectedItemList[getIndexItem].unitCost = unitCost;
    }

    this.setState({
      selectedItemList,
    }, () => changeItem(item.margin, item, 'margin', this.marginCalc()));
  };

  setWorkOrderComment = (item, editorContent) => {
    const { selectedItemList } = this.state;

    const getIndexItem = findIndex(selectedItemList, keyVal('order', item.order));

    selectedItemList[getIndexItem].workOrderComment = editorContent;

    this.setState({
      selectedItemList,
    });
  };

  handlePaymentText = (editorState) => {
    this.setState({
      latePaymentLawText: editorState,
    });
  };

  getSettingByName = (settingName, companySettings) => {
    const companyDetails = find(companySettings['hydra:member'], keyVal('name', settingName));
    return companyDetails ? companyDetails.value : undefined;
  };

  render() {
    const {
      documentId,
      selectedItemList,
      selectedLine,

      globalDiscount,
      globalDiscountType,
      creditNoteAmount,
      dueAmount,
      alreadyBilled,
      partialInvoice,

      visibility,

      reference,
      creationDate,
      responseDate,
      subject,
      showLatePaymentLawText,
      note,

      subjectError,
      selectedListError,
      creationDateError,
      responseDateError,
      creditNoteAmountError,
      dueAmountError,

      vat,
      totalPrice,
      netTotalPrice,
      totalTax,

      activityFormatData,
      latePaymentLawText,
      handleChangeSelectItem,
    } = this.state;

    const {
      documentData,
      creditNoteEdit,
      status,
      customer,
      type,
      selectedCompany,
      listCompanySettings,
      listArticle,
      loadingCompanySettings,
      loadingListArticle,
      paymentDate,
      t,
    } = this.props;

    const itemList = getArticles(listArticle, selectedCompany, customer, type);
    let companyDetails = null;
    let contactInformation = null;
    let vatRates = [];

    const editable = isEditable(type, status);
    const draft = isDraft(documentData);

    if (!isEmpty(listCompanySettings)) {
      companyDetails = this.getSettingByName('COMPANY_DETAILS', listCompanySettings);
      contactInformation = this.getSettingByName('CONTACT_INFORMATION', listCompanySettings);
      vatRates = this.getSettingByName('VAT_RATES', listCompanySettings);
    }

    if (vatRates) {
      vatRates = vatRates.map((rates, index) => ({
        key: `rate${index}`,
        text: rates.rate,
        value: rates.rate,
      }));
    }

    const total = netTotalPrice + totalTax;

    return (
      <ErrorBoundary>
        <DocumentContainer contextValue={this.state}>
          {type !== DOCUMENT_MODEL && (
            <Grid.Row>
              {type !== WORK_ORDER && (
                <CompanyAddress
                  companyDetails={companyDetails}
                  contactInformation={contactInformation}
                  loadingCompanySettings={loadingCompanySettings}
                />
              )}

              <Grid.Column width={7}>
                <SmallForm loading={false}>
                  <KeyValue label={getDocumentTypeLabel(type)}>{documentId || '-'}</KeyValue>

                  <DocumentReference
                    onChange={this.handleInputChange}
                    reference={reference}
                    isEditable={editable}
                  />

                  <DocumentCreationDate
                    onChange={this.handleCreationDateChange}
                    reference={reference}
                    isEditable={editable}
                    creationDate={creationDate}
                    error={creationDateError}
                  />

                  {(type === QUOTE) && (
                    <Form.Group inline>
                      {draft
                        ? (
                          <Form.Input
                            label={t('quoteSignBefore')}
                            name="responseDate"
                            control={DatePicker}
                            minDate={creationDate}
                            selected={responseDate}
                            onChange={this.handleResponseDateChange}
                            locale="fr"
                            autoComplete="off"
                            error={responseDateError}
                          />
                        )
                        : (
                          <Form.Field>
                            <label>{t('quoteSignBefore')}</label>
                            <Info>{moment(responseDate).format('DD/MM/YYYY')}</Info>
                          </Form.Field>
                        )
                      }
                    </Form.Group>
                  )}

                  <RecipientAddress
                    recipient={customer}
                    type={type}
                    content={documentData.content}
                  />
                </SmallForm>
              </Grid.Column>
            </Grid.Row>
          )}

          <RelatedDocuments document={documentData} />

          <RowColumn>
            <Info>{t('formSubject')}</Info>
            {editable
              ? (
                <Input
                  fluid
                  name="subject"
                  onChange={this.handleInputChange}
                  value={subject}
                  error={subjectError}
                />
              )
              : (
                <p>{subject}</p>
              )
            }
          </RowColumn>

          <RowColumn>
            <Table celled structured size="small">
              <VisibilityHeader
                visibility={visibility}
                handler={this.handleVisibility}
                showPrices={showPrices(type)}
              />

              <Table.Body>
                {isEmpty(selectedItemList) && (
                  <Table.Row>
                    <CenterCell content={t('documentNoSelectedArticles')} colSpan={9} />
                  </Table.Row>
                )}
                {!isEmpty(selectedItemList) && selectedItemList.map((item, index) => {
                  if (isLayoutLine(item.type)) {
                    return (
                      <LayoutBodyLine
                        key={index}
                        index={index}
                        item={item}
                        editable={editable}
                        handleDelete={this.handleDeleteItem}
                        setComment={this.setComment}
                        subTotal={this.getSubtotal}
                        documentType={type}
                      />
                    );
                  }

                  return (
                    <React.Fragment key={`itemList${index}`}>
                      <ArticleBodyLine
                        index={index}
                        item={item}
                        documentType={type}
                        documentData={documentData}
                        vatRates={vatRates}
                        editable={editable}
                        handleDeleteItem={this.handleDeleteItem}
                        handleItemCheckBoxChange={this.handleItemCheckBoxChange}
                        setLabel={this.setLabel}
                        loading={loadingCompanySettings}
                      />

                      {/* ENSEMBLE LINES */}
                      {item.items && item.items.map((article, index) => (
                        <React.Fragment key={`art${index}`}>
                          <Table.Row>
                            <LeftCell content={article.item.reference} />
                            <CenterCell>
                              <DraftEditor
                                editorLabel={article.item.label}
                                editorOnlyShow={!editable}
                                setLabel={editorContent => this.setLabel(
                                  article.item,
                                  editorContent,
                                  item,
                                )}
                              />
                            </CenterCell>
                            <CenterCell>
                              {parseFloat(article.item.price).toFixed(2)}
                            </CenterCell>
                            <CenterCell>
                              {article.item.unit}
                            </CenterCell>
                            <LeftCell>
                              <PercentageSelect
                                placeholder={t('formPHSelect')}
                                size="small"
                                fluid
                                name="vatRate"
                                loading={loadingCompanySettings}
                                disabled={loadingCompanySettings}
                                options={vatRates}
                                noResultsMessage="No results"
                                displayOnly={!editable}
                                value={article.item.vatRate}
                                onChange={
                                  (e, data) => this.handleEnsembleSelectChange(
                                    e, data, item, article,
                                  )
                                }
                              />
                            </LeftCell>
                            <FloatCell value={article.quantity} />
                            <LeftCell />
                            <LeftCell />
                          </Table.Row>

                          <SecondLineWrapper
                            activityFormatData={activityFormatData}
                            docType={type}
                            editable={editable}
                            item={article.item}
                            itemType=""
                            handleAddition={
                              (e, d) => this.handleAddition(e, d, item, article)
                            }
                            handleChangeSelectItem={
                              (e, d) => this.handleEnsembleSelectChange(e, d, item, article)
                            }
                            setItemCost={
                              (article, rawValue) => this.setItemCost(article, rawValue, item)
                            }
                          />
                        </React.Fragment>
                      ))}

                      <SecondLineWrapper
                        activityFormatData={activityFormatData}
                        docType={type}
                        editable={editable}
                        item={item}
                        itemType={item.type}
                        handleAddition={(e, d) => this.handleAddition(e, d, item)}
                        handleChangeSelectItem={(e, d) => handleChangeSelectItem(e, d, item)}
                        setItemCost={this.setItemCost}
                      />

                      {type === WORK_ORDER && (
                        <Table.Row>
                          <Table.Cell />
                          <Table.Cell colSpan="8">
                            <TextEditor
                              editorLabel={item.workOrderComment}
                              editorOnlyShow={!draft}
                              setLabel={content => this.setWorkOrderComment(item, content)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </React.Fragment>
                  );
                })}
              </Table.Body>
            </Table>
          </RowColumn>

          {editable && (
            <React.Fragment>
              <RowColumn width={8}>
                <Select
                  placeholder={t('formPHSelectArticle')}
                  fluid
                  search
                  loading={loadingListArticle}
                  disabled={loadingListArticle}
                  noResultsMessage="No results"
                  options={itemList}
                  onChange={this.handleAddItem}
                  value={null}
                  error={selectedListError}
                />
              </RowColumn>

              <Grid.Row>
                <Grid.Column width={6}>
                  <Select
                    placeholder={t('formPHSelect')}
                    fluid
                    search
                    noResultsMessage="No results"
                    name="selectedLine"
                    options={lineOptions}
                    onChange={this.handleSelectLine}
                    value={selectedLine}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <EssorButton
                    fluid
                    icon
                    type="plus"
                    size="small"
                    onClick={this.handleAddLine}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}

          <Grid.Row>
            {hasComment(type) && (
              <Notes document={documentData} onChange={this.handleInputChange} value={note} />
            )}

            {showPrices(type) && (
              <Grid.Column floated="right" width={6}>
                <Table celled definition size="small">
                  <Table.Body>
                    <TotalLine title={t('quoteTotalBeforeTaxes')} value={totalPrice} />

                    <TotalLine title={t('documentGlobalDiscount')}>
                      <DiscountInput
                        handler={value => this.setGlobalDiscount(value)}
                        name="discount"
                        value={globalDiscount}
                        type={globalDiscountType}
                        typeHandler={(e, data) => this.handleDiscountType(e, data)}
                        typeName="globalDiscountType"
                        displayOnly={!editable}
                      />
                    </TotalLine>

                    <TotalLine title={t('quoteNetTotalBeforeTaxes')} value={netTotalPrice} />

                    {isEmpty(vat)
                      ? <TotalLine title={t('quoteTotalVAT')} value={0} />
                      : (
                        vat.map((rate, index) => (
                          <TotalLine
                            key={`singleRate${index}`}
                            title={`${t('formVat')} ${rate.percentage}%`}
                            value={rate.value}
                          />
                        ))
                      )
                    }

                    <TotalLine title={t('quoteTotalWithTaxes')} value={total} />

                    {(type === INVOICE && alreadyBilled.gt(0)) && (
                      <TotalLine title={t('alreadyBilled')} value={alreadyBilled.toFixed(2)} />
                    )}

                    {(type === INVOICE && partialInvoice) && (
                      <TotalInputCell
                        label={t('dueAmount')}
                        name="dueAmount"
                        value={dueAmount}
                        error={dueAmountError}
                        handler={this.setDueAmount}
                        showInput={draft}
                      />
                    )}

                    {(type === INVOICE && !partialInvoice) && (
                      <TotalLine title={t('dueAmount')} value={dueAmount} />
                    )}

                    {(type === CREDIT_NOTE) && (
                      <TotalInputCell
                        label={t('creditNoteAmount')}
                        name="creditNoteAmount"
                        value={creditNoteAmount}
                        error={creditNoteAmountError}
                        handler={this.setCreditNoteAmount}
                        showInput={draft || creditNoteEdit}
                      />
                    )}
                  </Table.Body>
                </Table>
                {type === INVOICE && (
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <CenterCell>
                          {draft
                            ? (
                              <Checkbox
                                name="isPartial"
                                checked={!partialInvoice}
                                label={t('balanceInvoice')}
                                onChange={this.setPartial}
                              />
                            )
                            : partialInvoice ? t('partialInvoice') : t('balanceInvoice')
                          }
                        </CenterCell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                )}
              </Grid.Column>
            )}
          </Grid.Row>
          {type === INVOICE && (
            <PaymentLegalText
              showLatePaymentText={showLatePaymentLawText}
              latePaymentLawText={latePaymentLawText}
              handleCheckBoxChange={this.handleCheckBoxChange}
              handlePaymentText={this.handlePaymentText}
              paymentDate={paymentDate}
              creationDate={documentData.creationDate}
            />
          )}
        </DocumentContainer>
      </ErrorBoundary>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  setNewListCompanySettings: api => dispatch(setNewListCompanySettings(api)),
  updateActivities: (item, values) => dispatch(update(item, values)),
  showCalculation: item => dispatch(showCalculation(item)),
  totalBeforeTaxes: total => dispatch(totalBeforeTaxes(total)),
  netTotalBeforeTaxes: total => dispatch(netTotalBeforeTaxes(total)),
  actionItemSelected: itemsSelected => dispatch(actionItemSelected(itemsSelected)),
  reset() {
    dispatch(showCalculation(null));
    dispatch(totalBeforeTaxes(null));
    dispatch(netTotalBeforeTaxes(null));
    dispatch(actionItemSelected(null));
  },
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,

  listArticle: state.article.list.data,
  loadingListArticle: state.article.list.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Document);

export default withTranslation()(withRouter(Main));
