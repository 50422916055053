import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/company-settings/show';
import { Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import TableLoader from 'components/TableLoader';
import PercentageCell from 'components/cell/PercentageCell';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';
import HeaderRow from 'layouts/table/HeaderRow';

class CompanyShares extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=COMPANY_SHARES`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'COMPANY_SHARES'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesCompanyShares')}
          to="/company/settings/company-shares/edit"
          entity={Entities.companySettings}
        />
        <TableLoader status={loading}>
          <Table celled striped>
            <HeaderRow>
              <LeftCell header content={t('formFullName')} />
              <RightCell header content={t('formShares')} />
            </HeaderRow>

            <Table.Body>
              {value && value.map((companyShare, index) => (
                <Table.Row key={`v${index}`}>
                  <LeftCell content={companyShare.fullName} />
                  <PercentageCell value={companyShare.shares} />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(CompanyShares);

export default withTranslation()(withRouter(Main));
