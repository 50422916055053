import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EssorButton } from '../index';
import CloseButton from './CloseButton';
import { ExtendProps } from '../essorButton';

const BackButton: FC<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();

  return (
    <EssorButton as={Link} type="chevron left" size="tiny" {...others}>
      {label || t('buttonBack')}
    </EssorButton>
  );
};

CloseButton.defaultProps = {
  disabled: false,
  floated: undefined,
};

export default BackButton;
