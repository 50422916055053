import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { list, reset, success as successListProduct } from 'actions/product/list';
import { del as deleteProduct, error as errorDelete } from 'actions/product/delete';
import { CreateStockProduct, DeleteConfirmation } from 'components';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import { columnDefinition } from 'utils/tables';
import { Entities } from 'types/accessRights';
import { useTranslation } from 'react-i18next';
import useCompany from 'hooks/useCompany';
import ListLoading from 'components/ListLoading';
import { COEF } from 'utils/marginCalculation';
import API_ENTRYPOINT from 'config/_entrypoint';
import AdvancedList from 'components/advancedList/AdvancedList';

const ListProduct = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [productID, setProductID] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const products = useSelector(state => state.product.list.data);
  const loading = useSelector(state => state.product.list.loading);
  const deletedProduct = useSelector(state => state.product.del.deleted);
  const loadingDelete = useSelector(state => state.product.del.loading);
  const delError = useSelector(state => state.product.del.error);
  const [, company] = useCompany();

  const dispatch = useDispatch();
  const history = useHistory();

  const url = new URL('/products', API_ENTRYPOINT);
  url.searchParams.append('company', company['@id']);
  url.searchParams.append('pagination', 'true');

  useEffect(() => {
    dispatch(list(url.toString()));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    setData(products['hydra:member'] || {});
  }, [products]);

  useEffect(() => {
    dispatch(errorDelete(null));
  }, [delError]);

  const showDeleteModal = (item) => {
    setDeleteItem(item);
    setWarningMessage(true);
  };

  const closeDeleteModal = () => {
    setDeleteItem(null);
    setWarningMessage(false);
  };

  useEffect(() => {
    if (products['hydra:member'] && deletedProduct) {
      const arr = products['hydra:member'].filter(product => product['@id'] !== deletedProduct['@id']);

      dispatch(reset());
      dispatch(successListProduct({
        ...products,
        'hydra:member': arr,
      }));
      closeDeleteModal();
    }
  }, [deletedProduct]);

  const handleDelete = () => {
    dispatch(deleteProduct(deleteItem));
  };

  const openModal = (id) => {
    setShowModal(true);
    setProductID(id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const columns = [
    columnDefinition('formLabel', 'label'),
    columnDefinition('formUnit', 'unit'),
  ];
  if (company.calculationMode === COEF) {
    columns.push(columnDefinition('formSellingCoefficient', 'marginRate', 'number'));
  } else {
    columns.push(columnDefinition('formMarginRate', 'marginRate', 'percentage'));
  }
  columns.push(columnDefinition('formSellingPrice', 'purchaseUnitCost', 'currency'));

  return (
    <ContainerGeneral>
      <ListLoading loaded={data.length} total={products['hydra:totalItems'] || 0} />
      <AddHeader
        title={t('productsHomeTitle')}
        to="/articles/products/create"
        entity={Entities.product}
      />

      <AdvancedList
        columns={columns}
        loading={loading}
        data={data}
        onView={item => history.push(`/articles${item['@id']}`)}
        onEdit={item => history.push(`/articles${item['@id']}/edit`)}
        addStockProduct={id => openModal(id)}
        onDelete={showDeleteModal}
        entity={Entities.product}
        showFilters
      />

      <CreateStockProduct
        productCreate={showModal}
        productID={productID}
        closeModal={() => closeModal()}
      />

      <DeleteConfirmation
        show={warningMessage}
        name={deleteItem ? deleteItem.label : ''}
        loading={loadingDelete}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
    </ContainerGeneral>
  );
};

export default ListProduct;
