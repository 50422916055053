import React, { FunctionComponent, ReactNode } from 'react';
import { Switch } from 'react-router-dom';
import useAccessRights from '../../hooks/useAccessRights';
import RouteNotFound from '../../routes/RouteNotFound';
import { crudRights, Entities } from '../../types/accessRights';

interface OwnProps {
  children: ReactNode;
  entity: Entities;
  right: crudRights;
}

type Props = OwnProps;

/**
 * Checks a whole block including routes and 404 if entity right is not correct
 */
const CheckRouting: FunctionComponent<Props> = ({ children, entity, right }) => {
  const hasAccess = useAccessRights(entity, right);

  if (!hasAccess) {
    return <Switch><RouteNotFound /></Switch>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default CheckRouting;
