const SET_PO_TO_INVOICE_MODAL = 'MODAL/SET_PO_TO_INVOICE_MODAL';

const purchaseOrderToInvoice = (state = false, action: any) => {
  if (action.type === SET_PO_TO_INVOICE_MODAL) {
    return action.visibility;
  }
  return state;
};

export default purchaseOrderToInvoice;
