import React, { FunctionComponent } from 'react';
import { Header as Title } from 'semantic-ui-react';
import { Progress } from './index';

interface OwnProps {
  data: {
    goal: string;
    rawDone: number;
    done: string;
    percentage: string;
    rawPercentage: number;
    date: string;
  };
}

type Props = OwnProps;

const BreakEvenCard: FunctionComponent<Props> = ({ data }) => (
  <React.Fragment>
    <Title as="h5">Seuil de rentabilité</Title>

    <Progress
      done={data.done}
      breakEven={data.goal}
      breakEvenDate={data.date}
      percentage={data.percentage}
      rawPercentage={data.rawPercentage}
    />
  </React.Fragment>
);

export default BreakEvenCard;
