import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectOffice } from 'actions/user-companies/select';
import { list, reset } from 'actions/office/list';
import { TableList } from 'components';
import { withTranslation } from 'react-i18next';
import ContainerGeneral from '../../../../layouts/ContainerGeneral';
import AddHeader from '../../../../components/pageHeaders/AddHeader';
import { columnDefinition } from '../../../../utils/tables';

class HomeOffice extends Component {
  state = {
    officesList: [],
  };

  componentDidMount() {
    const { getOffices } = this.props;

    getOffices();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listOffices) && nextProps.listOffices['hydra:member'] !== prevState.officesList) {
      return {
        officesList: nextProps.listOffices['hydra:member'],
      };
    }
    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setSelectedOffice = (office) => {
    const { selectOffice, history } = this.props;

    selectOffice(office);
    history.push(`/offices/${office.id}/settings/contact-information`);
  };

  render() {
    const { officesList } = this.state;

    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('officesHomeTitle')}
          to="/offices/create"
        />

        <TableList
          columns={[
            columnDefinition('ID', 'id'),
            columnDefinition('formName', 'name'),
            columnDefinition('Status', 'status'),
            columnDefinition('formLastAccess', 'dateLastAccess', 'date'),
          ]}
          loading={loading}
          data={officesList}
          onView={this.setSelectedOffice}
          filterAttribute="name"
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getOffices: page => dispatch(list(page)),
  selectOffice: office => dispatch(selectOffice(office)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  listOffices: state.office.list.data,
  loading: state.office.list.loading,
  error: state.office.list.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeOffice);

export default withTranslation()(withRouter(Main));
