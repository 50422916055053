import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import Routes from './routes';

const HomeArticles: FunctionComponent = () => (
  <Redirect
    to={{
      pathname: Routes.listProducts,
    }}
  />
);

export default HomeArticles;
