export const getItemFromArticle = (article, type, manageStock = false, mode = null, order) => {
  let item = {};

  if (!article) {
    return item;
  }


  if (article.id) {
    item = {
      type: article.type,
      id: article.id,
      label: article.label,
      reference: article.reference,
      quantity: article.quantity,
      vatRate: article.vatRate,
      price: article.price,
      totalPrice: article.totalPrice,
      discount: article.discount,
      discountType: article.discountType,
      optional: article.optional,
      activity1: article.activity1,
      activity2: article.activity2,
      unit: article.unit,
    };

    if (manageStock) {
      item.stockManagement = article.stockManagement;
    }
  }

  if (order !== undefined) {
    item.order = order;
  }

  if (article.workOrderComment) {
    item.workOrderComment = article.workOrderComment;
  }

  switch (article.type) {
    case 'Ensemble':
      item.items = article.items;
      break;
    case 'Product':
      item.calculationMode = mode || article.calculationMode;
      item.margin = article.margin;
      item.soldAs = article.soldAs;
      item.unitCost = article.unitCost;
      break;
    case 'Service':
      item.soldAs = article.soldAs;
      break;
    case 'comment':
      item.comment = article.comment;
      item.type = 'comment';
      break;
    case 'blankLine':
    case 'subtotal':
    default:
      item.type = article.type;
      break;
  }

  return item;
};

export function getItemFromItem(item, type, mode) {
  return getItemFromArticle(item, type, true, mode);
}

export function isProduct(item) {
  return item.type === 'Product';
}

export function isService(item) {
  return item.type === 'Service';
}

export function isArticle(item) {
  return isProduct(item) || isService(item);
}

export function isEnsemble(item) {
  return item.type === 'Ensemble';
}
