import React, { Component } from 'react';
import { Link, Redirect, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { reset as resetProductList } from 'actions/product/list';
import { retrieve as retrieveStock, update as updateStock, reset as resetUpdateStock } from 'actions/stock/update';
import { Form, Grid, Header, Input } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import { inputFormat } from 'utils/formatter';
import Cleave from 'cleave.js/react';
import NotFound from '../../../../404';

class UpdateStock extends Component {
  state = {
    id: '',
    quantity: '',
    supplierPrice: '',
    price: '',
    supplier: '',

    quantityError: false,
    supplierPriceError: false,
    priceError: false,
    isValid: true,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { retrievedProduct, retrieveStock, match } = this.props;

    if (find(retrievedProduct.stock, {
      '@id': `/stocks/${match.params.stockId}`,
    })) {
      retrieveStock(`/stocks/${match.params.stockId}`);
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrieved) && prevState.id !== nextProps.retrieved['@id']) {
      return {
        id: nextProps.retrieved['@id'],
        quantity: nextProps.retrieved.quantity,
        supplierPrice: nextProps.retrieved.supplierPrice,
        price: nextProps.retrieved.price,
        supplier: nextProps.retrieved.supplier,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { updated, resetProductList } = this.props;

    if (!isEmpty(updated) && updated !== prevProps.updated) {
      resetProductList();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
      hasDataChanged: true,
    });
  };

  handleOnSubmit = () => {
    const {
      quantity,
      price,
      supplierPrice,
    } = this.state;
    const { updateStock, retrieved } = this.props;

    let isValid = true;

    this.setState({
      quantityError: false,
      priceError: false,
      supplierPriceError: false,
    });

    if (quantity === '') {
      isValid = false;

      this.setState({
        quantityError: true,
      });
    }

    if (price === '') {
      isValid = false;

      this.setState({
        priceError: true,
      });
    }

    if (supplierPrice === '') {
      isValid = false;

      this.setState({
        supplierPriceError: true,
      });
    }

    if (!isValid) return;

    const data = {
      quantity,
      price,
      supplierPrice,
    };

    this.setState({
      hasDataChanged: false,
    });

    updateStock(retrieved, data);
  };

  render() {
    const {
      quantity,
      price,
      supplierPrice,
      supplier,
      quantityError,
      priceError,
      supplierPriceError,
      isValid,
      hasDataChanged,
    } = this.state;

    const {
      t,
      updated,
      retrieveLoading,
      updateLoading,
      match,
    } = this.props;

    const updateID = match.params.stockId;

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (updated) {
      return (
        <Redirect
          push
          to={`/articles/products/${match.params.productId}/stock/${updateID}`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('stockUpdateTitle')}
            </Header>
            <EssorButton
              as={Link}
              to={`/articles/products/${match.params.productId}/stock/${updateID}`}
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Field error={priceError}>
                      <label>{t('formPrice')}</label>
                      <Input>
                        <Cleave
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 2,
                            numeralDecimalMark: ',',
                          }}
                          onChange={e => this.handleInputChange(e, true)}
                          name="price"
                          placeholder={t('formPHPrice')}
                          value={inputFormat(price, true)}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field error={supplierPriceError}>
                      <label>{t('formSupplierPrice')}</label>
                      <Input>
                        <Cleave
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 2,
                            numeralDecimalMark: ',',
                          }}
                          onChange={e => this.handleInputChange(e, true)}
                          name="supplierPrice"
                          placeholder={t('formPHPrice')}
                          value={inputFormat(supplierPrice, true)}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field error={quantityError}>
                      <label>{t('formQuantity')}</label>
                      <Input>
                        <Cleave
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 2,
                            numeralDecimalMark: ',',
                          }}
                          onChange={e => this.handleInputChange(e, true)}
                          name="quantity"
                          placeholder={t('formPHQuantity')}
                          value={inputFormat(quantity, true)}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formSupplier')}</label>
                      <h5 className="informative-field">
                        {supplier.supplierName}
                      </h5>
                    </Form.Field>
                  </Form.Group>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {t('buttonSave')}
            </EssorButton>
          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieveStock: data => dispatch(retrieveStock(data)),
  updateStock: (item, data) => dispatch(updateStock(item, data)),
  resetProductList: () => dispatch(resetProductList()),
  reset: () => dispatch(resetUpdateStock()),
});

const mapStateToProps = state => ({
  retrieveError: state.stock.update.retrieveError,
  retrieveLoading: state.stock.update.retrieveLoading,
  updateError: state.stock.update.updateError,
  updateLoading: state.stock.update.updateLoading,
  retrieved: state.stock.update.retrieved,
  updated: state.stock.update.updated,

  retrievedProduct: state.product.show.retrieved,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(UpdateStock);

export default withTranslation()(withRouter(Main));
