import React, { FunctionComponent } from 'react';
import { Form, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  url: string;
}

type Props = OwnProps;

const FormPicture: FunctionComponent<Props> = ({ url }) => {
  const { t } = useTranslation();
  return (
    <Form.Group inline>
      <Form.Field className="formPicture">
        <label>{t('formPicture')}</label>
        <div className="informative-field">
          <Image src={url} />
        </div>
      </Form.Field>
    </Form.Group>
  );
};

export default FormPicture;
