import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { find, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { success as successListSubscription } from 'actions/subscription/list';
import { reset as resetSubscription, retrieve as retrieveSubscription } from 'actions/subscription/show';
import { del as deleteSchedule, error as errorDelete } from 'actions/schedule-invoice/delete';
import { change as changeSchedule, reset as resetChange } from 'actions/schedule-invoice/change';
import { Form, Grid, Header, Table } from 'semantic-ui-react';
import { DeleteConfirmation, EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isNotDraft } from 'utils/documentStatus';
import NotFound from 'routes/admin/404';
import DeleteCellButton from 'components/buttons/DeleteCellButton';
import CheckRights from 'components/access/CheckRights';
import { crudRights, Entities } from 'types/accessRights';
import DateCell from 'components/cell/DateCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import CreateButton from 'components/buttons/CreateButton';
import TableLoader from 'components/TableLoader';

class ShowSubscription extends Component {
  state = {
    scheduleData: [],
    isValid: true,
    warningMessage: false,
  };

  componentDidMount() {
    const { getSubscription, listSubscription, match } = this.props;

    if (find(listSubscription['hydra:member'], {
      id: parseInt(match.params.id, 10),
    })) {
      getSubscription(`/subscriptions/${match.params.id}`);
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedSubscription)
      && nextProps.retrievedSubscription !== prevState.retrieved) {
      return {
        retrieved: nextProps.retrievedSubscription,
        scheduleData: nextProps.retrievedSubscription.schedule,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedSchedule, errorDelete, resetError, changedSchedule } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.dismissWarning();
      resetError();
    }

    if (!isEmpty(deletedSchedule)
      && deletedSchedule !== prevProps.deletedSchedule) {
      this.updateList(deletedSchedule);
    }

    if (!isEmpty(changedSchedule)
      && changedSchedule !== prevProps.changedSchedule) {
      this.updateList(changedSchedule);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  updateList = (item) => {
    const { creating, scheduleData, retrieved } = this.state;
    const { setListSubscription, listSubscription } = this.props;
    let arr;

    if (creating) {
      arr = scheduleData.map((schedule) => {
        if (schedule['@id'] === creating['@id']) {
          schedule.invoice = item;
        }

        return schedule;
      });
    } else {
      arr = scheduleData.filter(schedule => schedule['@id'] !== item['@id']);
      this.dismissWarning();
    }

    const subscriptionList = listSubscription['hydra:member'].map((subscription) => {
      if (subscription['@id'] === retrieved['@id']) {
        return ({
          ...retrieved,
          schedule: arr,
        });
      }

      return subscription;
    });

    const newList = {
      ...listSubscription,
      'hydra:member': subscriptionList,
      'hydra:totalItems': listSubscription['hydra:totalItems'] - 1,
    };

    setListSubscription(newList);

    this.setState({
      scheduleData: arr,
    });
  };

  handleDelete = () => {
    const { toDelete } = this.state;
    const { deleteSchedule } = this.props;
    deleteSchedule(toDelete);
  };

  showDeleteWarning = (item) => {
    this.setState({
      toDelete: item,
      warningMessage: true,
      itemName: moment(item.date)
        .format('DD/MM/YYYY'),
    });
  };

  dismissWarning = () => {
    this.setState({
      toDelete: null,
      warningMessage: false,
      itemName: '',
    });
  };

  handleOnCreate = (item) => {
    const { changeSchedule } = this.props;
    this.setState({
      creating: item,
    });
    changeSchedule(`/schedule_invoices/${item.id}/to_invoice`);
  };

  render() {
    const {
      isValid,
      warningMessage,
      itemName,
      scheduleData,
    } = this.state;

    const {
      retrievedSubscription,
      loadingSubscription,
      loadingDelete,
      loadingChangeSchedule,
      t,
    } = this.props;

    const modelAmount = retrievedSubscription
      ? parseFloat(retrievedSubscription.model.content.total.beforeTaxes)
      : 0;
    let next = {};
    let total = 0;

    const sortedSchedule = sortBy(scheduleData, 'date');
    if (!isEmpty(sortedSchedule)) {
      for (let i = 0; i < sortedSchedule.length; i++) {
        if (!sortedSchedule[i].invoice) {
          if (isEmpty(next)) {
            next = sortedSchedule[i];
          }
          total += modelAmount;
        } else {
          total += sortedSchedule[i].invoice.totalPrice;
        }
      }
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('subscriptionsShowTitle')}</Header>
            <EssorButton
              as={Link}
              to="/subscriptions"
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form
                  className="margin-top-bot main-form"
                  loading={loadingSubscription}
                  size="small"
                >
                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('identityRightsCustomer')}</label>
                      <h5 className="informative-field">
                        {retrievedSubscription
                        && (retrievedSubscription.customer.companyName
                          || retrievedSubscription.customer.contactName)}
                      </h5>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('formStartDate')}</label>
                      <h5 className="informative-field">
                        {retrievedSubscription
                        && moment(retrievedSubscription.startDate)
                          .format('DD/MM/YYYY')}
                      </h5>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field>
                      <label>{t('quoteModel')}</label>
                      <h5 className="informative-field">
                        {retrievedSubscription
                        && retrievedSubscription.model.label}
                      </h5>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {!isEmpty(scheduleData)
          && (
            <TableLoader status={loadingSubscription || !!loadingChangeSchedule}>
              <Table celled structured className="margin-bot">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('formDate')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">{t('formAmount')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{t('formInvoice')}</Table.HeaderCell>
                    <CheckRights entity={Entities.scheduleInvoice} right={crudRights.delete}>
                      <Table.HeaderCell textAlign="center">{t('deleteTitle')}</Table.HeaderCell>
                    </CheckRights>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {(sortBy(scheduleData, 'date')).map((item) => {
                    const amount = item.invoice ? item.invoice.totalPrice : modelAmount;

                    return (
                      <Table.Row key={item['@id']} className={item['@id']}>
                        <DateCell date={moment(item.date)} />
                        <CurrencyCell value={amount} />
                        <Table.Cell selectable textAlign="center">
                          {next['@id'] === item['@id']
                          && (
                            <CreateButton
                              entity={Entities.invoice}
                              onClick={() => this.handleOnCreate(item)}
                            />
                          )}
                          {item.invoice
                          && (
                            <Link to={`/business${item.invoice['@id']}/edit`} target="_blank">
                              {isNotDraft(item.invoice) ? item.invoice.uniqueID : t('documentStatusDraft')}
                            </Link>
                          )}
                        </Table.Cell>

                        <CheckRights entity={Entities.scheduleInvoice} right={crudRights.delete}>
                          {!item.invoice ? (
                            <DeleteCellButton onClick={() => this.showDeleteWarning(item)} />
                          ) : (
                            <Table.Cell textAlign="center">-</Table.Cell>
                          )}
                        </CheckRights>
                      </Table.Row>
                    );
                  })}
                  <Table.Row>
                    <Table.Cell>{t('documentTotal')}</Table.Cell>
                    <CurrencyCell value={total} />
                    <Table.Cell colSpan={2} />
                  </Table.Row>
                </Table.Body>
              </Table>
            </TableLoader>
          )}
        </div>

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDelete}
          onClose={this.dismissWarning}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSubscription: id => dispatch(retrieveSubscription(id)),
  deleteSchedule: item => dispatch(deleteSchedule(item)),
  changeSchedule: route => dispatch(changeSchedule(route)),
  setListSubscription: list => dispatch(successListSubscription(list)),
  resetError: () => dispatch(errorDelete(null)),
  reset: () => {
    dispatch(resetChange());
    dispatch(resetSubscription());
  },
});

const mapStateToProps = state => ({
  listSubscription: state.subscription.list.data,

  retrievedSubscription: state.subscription.show.retrieved,
  loadingSubscription: state.subscription.show.loading,

  deletedSchedule: state.scheduleInvoice.del.deleted,
  loadingDelete: state.scheduleInvoice.del.loading,
  errorDelete: state.scheduleInvoice.del.error,

  changedSchedule: state.scheduleInvoice.change.changed,
  loadingChangeSchedule: state.scheduleInvoice.change.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowSubscription);

export default withTranslation()(withRouter(Main));
