import React, { FunctionComponent } from 'react';
import moment from 'moment';
import DateCell from '../../../components/cell/DateCell';
import SituationRow, { SituationRowProps } from './SituationRow';

type Props = SituationRowProps;

const SituationDateRow: FunctionComponent<Props> = (props) => {
  const { data, noGap } = props;

  return (
    <SituationRow {...props}>
      <DateCell date={data && moment(data.realised)} />
      <DateCell date={data && moment(data.planned)} />
      <DateCell date={data && moment(data.ratioed)} />
      <DateCell date={data && moment(data.current)} />
      {!noGap && <DateCell date={data && data.gap} />}
    </SituationRow>
  );
};

export default SituationDateRow;
