import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { Big } from 'utils/functions';
import QuestionTooltip from 'components/icons/QuestionTooltip';
import PercentageCell from 'components/cell/PercentageCell';
import LeftCell from 'layouts/table/LeftCell';
import RightCell from 'layouts/table/RightCell';
import IntegerCell from 'components/cell/IntegerCell';
import { RowTuple } from './forecastTypes';

interface OwnProps {
  label: string;
  tooltip?: string;
  data: RowTuple;
}

type Props = OwnProps;

const ForecastNumericRow: FC<Props> = ({ label, tooltip, data = {} }) => (
  <Table.Row>
    <LeftCell>
      {label}
      {tooltip && <QuestionTooltip tip={tooltip} />}
    </LeftCell>
    <IntegerCell value={Big(data.realised || 0)} />
    <IntegerCell value={Big(data.planned || 0)} />
    {data.percentage !== 0
      ? <PercentageCell showPositive value={data.percentage} />
      : <RightCell />
    }
  </Table.Row>
);

export default ForecastNumericRow;
