import React, { FunctionComponent, SyntheticEvent, useContext } from 'react';
import { DropdownProps, Table } from 'semantic-ui-react';
import { BigSource } from 'big.js';
import DiscountInput from '../input/DiscountInput';
import Currency from '../currency';
import { DocumentContext } from './DocumentContainer';
import { DocumentContextInterface, DocumentItem, Ensemble } from '../../types/document';
import { isEnsemble } from './articles';
import useMarginCalculation from '../../hooks/useMarginCalculation';
import { computeEnsembleDiscount } from './documents';

interface OwnProps {
  item: DocumentItem;
  editable: boolean;
}

type Props = OwnProps;

const ItemDiscount: FunctionComponent<Props> = ({ item, editable }) => {
  const context = useContext(DocumentContext) as DocumentContextInterface;
  const {
    selectedItemList,
    updateContext,
    currentDiscount,
    handleChangeItem,
    handleChangeSelectItem,
  } = context;

  const marginCalculation = useMarginCalculation();

  const handleOnFocusItemDiscount = (ensemble: Ensemble): void => {
    if (!isEnsemble(ensemble)) {
      return;
    }

    const currentDiscount = (ensemble.price * ensemble.quantity) - ensemble.totalPrice;
    updateContext('currentDiscount', currentDiscount);
  };

  const handleOnBlurItemDiscount = (event: SyntheticEvent, ensemble: Ensemble): void => {
    if (isEnsemble(ensemble)) {
      updateContext('selectedItemList',
        computeEnsembleDiscount(ensemble, selectedItemList, currentDiscount, marginCalculation));
    }
  };

  return (
    <React.Fragment>
      <Table.Cell collapsing textAlign="center">

        <DiscountInput
          name="discount"
          value={item.discount}
          type={item.discountType}
          displayOnly={!editable}

          handler={(value: BigSource): void => handleChangeItem(value, item, 'discount')}
          typeHandler={(e: SyntheticEvent, data: DropdownProps): void => (
            handleChangeSelectItem(e, data, item)
          )}

          // only for Ensemble, will return first thing if item is an Article
          onBlur={(e: SyntheticEvent): void => handleOnBlurItemDiscount(e, item as Ensemble)}
          onFocus={(): void => handleOnFocusItemDiscount(item as Ensemble)}
        />

      </Table.Cell>
      <Table.Cell textAlign="center" collapsing>
        <Currency value={item.totalPrice || 0} />
      </Table.Cell>
    </React.Fragment>
  );
};

export default ItemDiscount;
