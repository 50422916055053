import React from 'react';
import classnames from 'classnames';
import MonthAndYear from 'components/dates/MonthAndYear';

const Card = ({ amount, date, title = '', color }) => (
  <div
    className={classnames('card', {
      negative: color && (amount && amount.startsWith('-')),
      positive: color && (amount && !amount.startsWith('-')),
    })}
  >
    <div className="card-title">
      {date ? <MonthAndYear date={date} /> : title}
    </div>
    <div className="card-description">
      {amount}
    </div>
  </div>
);

export default Card;
