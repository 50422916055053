import React, { FunctionComponent, ReactNode } from 'react';
import GridWrapper from './GridWrapper';

interface OwnProps {
  children: ReactNode;
}

type Props = OwnProps;

const TwelveGrid: FunctionComponent<Props> = ({ children }) => (
  <GridWrapper width={12}>
    {children}
  </GridWrapper>
);

export default TwelveGrid;
