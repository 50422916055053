import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { Table } from 'semantic-ui-react';
import ToggleCell from './ToggleCell';
import { Translation } from '../../../../hooks/useTrans';
import PlannedCell from './PlannedCell';
import GapCell from './GapCell';
import TypeCell from './TypeCell';
import TreasuryTotalCell from './TreasuryTotalCell';

interface OwnProps {
  displayMonths: [];
  collapse: boolean;
  title: Translation;
  toggle: () => void;
}

type Props = OwnProps;

const TreasuryToggleRow: FunctionComponent<Props> = ({ displayMonths, ...others }) => (
  <Table.Row className="section-border-top section-border-side">
    <ToggleCell {...others} />
    <TypeCell />
    <PlannedCell value={0} />
    <GapCell value={0} />

    {!isEmpty(displayMonths) && displayMonths.map((monthData, index) => (
      <Table.Cell key={index} className="cell-month">&nbsp;</Table.Cell>
    ))}

    <TreasuryTotalCell value={0} />
  </Table.Row>
);

export default TreasuryToggleRow;
