import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FiscalYear, State } from '../types/store';

type UseFiscalYear = [boolean, (FiscalYear | undefined)];

export default function useFiscalYear(): UseFiscalYear {
  const fiscalYear = useSelector((state: State) => state.userCompanies.select.selectedFiscalYear);

  return [
    !isEmpty(fiscalYear),
    fiscalYear,
  ];
}
