enum Business {
  home = '/business',
  createModel = '/business/document_models/create',
  order = '/business/commande',
  export = '/business/compta',
  listInvoices = '/business/invoices',
  createInvoice = '/business/invoices/create',
  showInvoices = '/business/invoices/:id',
  editInvoice = '/business/invoices/:id/edit',
  listPurchaseOrders = '/business/purchase_orders',
  createPurchaseOrders = '/business/purchase_orders/create',
  showPurchaseOrders = '/business/purchase_orders/:id',
  editPurchaseOrders = '/business/purchase_orders/:id/edit',
  listQuotes = '/business/quotes',
  createQuote = '/business/quotes/create',
  showQuote = '/business/quotes/:id',
  editQuote = '/business/quotes/:id/edit',
  listDocumentModels = '/business/document_models',
  showDocumentModel = '/business/document_models/:id',
  editDocumentModel = '/business/document_models/:id/edit',
  listCreditNotes = '/business/credit_notes',
  listDeliveryForms = '/business/delivery_forms',
  listWorkOrders = '/business/work_orders',
  listSupplierOrders = '/business/supplier_orders',
  createSupplierOrder = '/business/supplier_orders/create',
  showSupplierOrder = '/business/supplier_orders/:id',
  editSupplierOrder = '/business/supplier_orders/:id/edit',
  receipt = '/business/invoices/:id/receipt',
  creditNote = '/business/invoices/:id/credit_note',
}

export default Business;
