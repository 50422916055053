import React, { FunctionComponent, SyntheticEvent } from 'react';
import { DropdownProps, Form, Table } from 'semantic-ui-react';
import { BigSource } from 'big.js';
import { hasSecondLine } from './documentOptions';
import ArticleActivities, { MainActivity } from './ArticleActivities';
import ProductSecondLine from './ProductSecondLine';
import ServiceSecondLine from './ServiceSecondLine';
import { isProduct, isService } from './articles';
import { Article, Product, Service } from '../../types/document';

interface OwnProps {
  activityFormatData: MainActivity[];
  docType: string;
  editable: boolean;
  handleAddition: (event: SyntheticEvent, data: DropdownProps) => void;
  handleChangeSelectItem: (event: SyntheticEvent, data: DropdownProps) => void;
  setItemCost: (item: Article, rawValue: BigSource) => void;
  item: Article;
  itemType: string;
}

type Props = OwnProps;

const SecondLineWrapper: FunctionComponent<Props> = (props: Props) => {
  const {
    activityFormatData,
    docType,
    editable,
    handleAddition,
    handleChangeSelectItem,
    setItemCost,
    item,
    itemType,
  } = props;

  return (
    <React.Fragment>
      {(itemType !== 'Ensemble' && hasSecondLine(docType)) && (
        <Table.Row>
          <Table.Cell colSpan={8}>
            <Form size="small" className="full-line-cell">
              <ArticleActivities
                activityFormatData={activityFormatData}
                editable={editable}
                handleAddition={handleAddition}
                handleChangeSelectItem={handleChangeSelectItem}
                item={item}
              />
              {isProduct(item) && (
                <ProductSecondLine
                  editable={editable}
                  handler={handleChangeSelectItem}
                  item={item as Product}
                  onChange={(rawValue: BigSource): void => setItemCost(item, rawValue)}
                />
              )}

              {isService(item) && (
                <ServiceSecondLine
                  editable={editable}
                  handler={handleChangeSelectItem}
                  item={item as Service}
                />
              )}
            </Form>
          </Table.Cell>
        </Table.Row>
      )}
    </React.Fragment>
  );
};

export default SecondLineWrapper;
