import React, { FC } from 'react';
import { modeIsPercent } from '../utils/marginCalculation';
import Percentage from '../components/percentage';
import Float from '../components/float';
import useCompany from '../hooks/useCompany';
import Nbsp from '../components/Nbsp';

interface OwnProps {
  value: number;
}

type Props = OwnProps;

const Margin: FC<Props> = ({ value }) => {
  const [, company] = useCompany();

  if (!company) {
    return <Nbsp />;
  }

  return modeIsPercent(company) ? <Percentage value={value} /> : <Float value={value} />;
};

export default Margin;
