import React, { Component } from 'react';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import { TableList } from 'components';
import moment from 'moment';
import DatePicker from 'react-datepicker/es';
import { getFollowUpTypes } from 'components/documents/documentOptions';
import { find, findIndex } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { create as createFollowUp, error as errorFollowUp, loading as loadingFollowUp, success as successFollowUp } from 'actions/follow-up/create';
import { reset as resetFollowUp, update as updateFollowUp } from 'actions/follow-up/update';
import { selectDocument } from 'actions/user-companies/select';
import BackHeader from '../../../../../components/pageHeaders/BackHeader';
import AddHeader from '../../../../../components/pageHeaders/AddHeader';
import { columnDefinition } from '../../../../../utils/tables';
import { Entities } from '../../../../../types/accessRights';
import { ASC } from '../../../../../utils/constants';
import SaveButton from '../../../../../components/buttons/SaveButton';
import CancelButton from '../../../../../components/buttons/CancelButton';

class FollowUpModal extends Component {
  state = {
    typeOptions: null,

    showFollowUpForm: false,
    followUpData: null,

    date: null,
    comment: '',
    type: null,
    quote: null,

    dateError: false,
    typeError: false,
  };

  componentDidMount() {
    const { quote } = this.props;

    this.setState({
      quote,
      followUpData: quote ? quote.followUp : null,
      typeOptions: getFollowUpTypes(),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      updatedFollowUp,
      createdFollowUp,
      resetUpdateFollowUp,
      resetCreateFollowUp,
    } = this.props;

    if (updatedFollowUp && updatedFollowUp !== prevProps.updatedFollowUp) {
      resetUpdateFollowUp();
    }

    if (createdFollowUp && createdFollowUp !== prevProps.createdFollowUp) {
      resetCreateFollowUp();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updatedFollowUp) {
      const { followUpData, quote } = prevState;

      const index = findIndex(followUpData, {
        '@id': nextProps.updatedFollowUp['@id'],
      });

      followUpData[index] = nextProps.updatedFollowUp;
      quote.followUpData = followUpData;

      return {
        followUpData,
        showFollowUpForm: false,
        date: null,
        comment: '',
        type: null,
      };
    }

    if (nextProps.createdFollowUp) {
      const { followUpData, quote } = prevState;

      followUpData.unshift(nextProps.createdFollowUp);
      quote.followUpData = followUpData;

      return {
        followUpData,
        showFollowUpForm: false,
        date: null,
        comment: '',
        type: null,
      };
    }

    const { quote } = nextProps;
    return {
      quote,
      followUpData: quote ? quote.followUp : null,
      typeOptions: getFollowUpTypes(),
    };
  }

  showFollowUpForm = (followUp) => {
    if (followUp) {
      this.setState({
        editFollowUp: followUp,
        date: moment(followUp.date),
        comment: followUp.comment,
        type: followUp.type,
      });
    }

    this.setState({
      showFollowUpForm: true,
    });
  };

  closeFollowUpForm = () => {
    this.setState({
      showFollowUpForm: false,
      editFollowUp: null,
      date: null,
      comment: '',
      type: null,
    });
  };

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      typeOptions: [
        {
          text: value,
          value,
        },
        ...prevState.typeOptions,
      ],
    }));
  };

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnFollowUpSubmit = () => {
    const {
      quote,
      editFollowUp,
      date,
      comment,
      type,
    } = this.state;

    const {
      postFollowUp,
      updateFollowUp,
      selectedCompany,
    } = this.props;

    this.setState({
      dateError: false,
      typeError: false,
    });

    let isValid = true;

    if (!date) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (!type) {
      isValid = false;

      this.setState({
        typeError: true,
      });
    }

    if (!isValid) return;

    const data = {
      company: selectedCompany['@id'],
      type,
      date: date.format(),
    };

    if (comment.trim() !== '') {
      data.comment = comment;
    }

    if (editFollowUp) {
      updateFollowUp(editFollowUp, data);
    } else {
      data.quote = quote['@id'];

      postFollowUp(data);
    }
  };

  render() {
    const {
      followUpData,
      showFollowUpForm,
      dateError,
      typeError,
      typeOptions,
      date,
      comment,
      type,
    } = this.state;

    const {
      t,
      visible,
      loadingCreateFollowUp,
      loadingUpdateFollowUp,
      closeHandler,
    } = this.props;

    const isLoading = loadingCreateFollowUp || loadingUpdateFollowUp;

    if (type) {
      const isAdd = find(typeOptions, {
        value: type,
      });

      if (!isAdd) {
        typeOptions.push({
          text: type,
          value: type,
        });
      }
    }

    return (
      <Modal open={visible} className="small-content">
        <Modal.Header>{t('followUpHomeTitle')}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {showFollowUpForm
              ? (
                <React.Fragment>
                  <BackHeader title="" onClick={this.closeFollowUpForm} />

                  <Form className="margin-top-bot main-form" loading={isLoading} size="small">
                    <Form.Group inline>
                      <Form.Input
                        label={t('formDate')}
                        name="date"
                        minDate={moment()}
                        control={DatePicker}
                        selected={date}
                        onChange={this.handleDateChange}
                        locale="fr"
                        autoComplete="off"
                        error={dateError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Select
                        label={t('formType')}
                        control={Dropdown}
                        placeholder={t('formPHSelect')}
                        fluid
                        selection
                        search
                        allowAdditions
                        options={typeOptions}
                        name="type"
                        onAddItem={this.handleAddition}
                        onChange={this.handleSelectChange}
                        value={type}
                        error={typeError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formComments')}
                        name="comment"
                        value={comment}
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>

                  </Form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <AddHeader title="" onClick={() => this.showFollowUpForm()} />

                  <TableList
                    columns={[
                      columnDefinition('formType', 'type'),
                      columnDefinition('formComments', 'comment'),
                      columnDefinition('formDate', 'date', 'date'),
                    ]}
                    data={followUpData || []}
                    onEdit={this.showFollowUpForm}
                    entity={Entities.invoice}
                    sortBy="date"
                    direction={ASC}
                  />
                </React.Fragment>
              )
            }

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div>
            {showFollowUpForm && <SaveButton onClick={this.handleOnFollowUpSubmit} />}
            <CancelButton onClick={() => closeHandler()} />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  createdFollowUp: state.followUp.create.created,
  loadingCreateFollowUp: state.followUp.create.loading,
  errorCreateFollowUp: state.followUp.create.error,

  updatedFollowUp: state.followUp.update.updated,
  loadingUpdateFollowUp: state.followUp.update.updateLoading,
  errorUpdateFollowUp: state.followUp.update.updateError,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const mapDispatchToProps = dispatch => ({
  postFollowUp: data => dispatch(createFollowUp(data)),
  updateFollowUp: (item, data) => dispatch(updateFollowUp(item, data)),
  resetUpdateFollowUp: () => dispatch(resetFollowUp()),
  selectDocument: quote => dispatch(selectDocument(quote)),
  resetCreateFollowUp: () => {
    dispatch(successFollowUp(null));
    dispatch(errorFollowUp(null));
    dispatch(loadingFollowUp(false));
  },
});

const Main = connect(mapStateToProps, mapDispatchToProps)(FollowUpModal);
export default withTranslation()(Main);
