import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer as routing } from 'react-router-redux';

import absence from './absence';
import article from './article';
import auth from './auth';
import budget from './budget';
import bulkPrice from './bulk-update';
import calculationMode from './calculation-mode';
import company from './company';
import companySettings from './company-settings';
import contextualData from './contextual-data';
import counterForm from './document-timer';
import creditNote from './credit-note';
import customer from './customer';
import dashboard from './dashboard';
import delivery from './delivery';
import disbursement from './disbursement';
import document from './document';
import documentNumbering from './document-numbering';
import employee from './employee';
import employeeData from './employee-data';
import encasement from './encasement';
import ensemble from './ensemble';
import expert from './expert';
import fiscalYear from './fiscal-year';
import followUp from './follow-up';
import forecast from './forecast';
import holiday from './holiday';
import hourSynthesis from './hour-synthesis';
import identity from './identity';
import invoice from './invoice';
import layout, { layoutRight } from './layout';
import media from './media';
import modal from './modal';
import model from './document-model';
import movement from './stock-movement';
import office from './office';
import officeSettings from './office-settings';
import payment from './payment';
import product from './product';
import purchaseOrder from './purchase-order';
import quote from './quote';
import receipt from './receipt';
import recovery from './recovery-notice';
import role from './role';
import scheduleInvoice from './schedule-invoice';
import selfFinancing from './self-financing';
import service from './service';
import situation from './situation';
import stock from './stock';
import stockBooking from './stock-booking';
import subscription from './subscription';
import supplier from './supplier';
import supplierOrder from './supplier-order';
import turnoverBalance from './turnover-balance';
import userCompanies from './user-companies';
import workingCapital from './working-capital';
import workOrder from './work-order';

const EssorApp = combineReducers({
  absence,
  article,
  auth,
  budget,
  bulkPrice,
  calculationMode,
  company,
  companySettings,
  contextualData,
  counterForm,
  creditNote,
  customer,
  dashboard,
  delivery,
  disbursement,
  document,
  documentNumbering,
  employee,
  employeeData,
  encasement,
  ensemble,
  expert,
  fiscalYear,
  followUp,
  forecast,
  form,
  holiday,
  hourSynthesis,
  identity,
  invoice,
  layout,
  layoutRight,
  media,
  modal,
  model,
  movement,
  office,
  officeSettings,
  payment,
  product,
  purchaseOrder,
  quote,
  receipt,
  recovery,
  role,
  routing,
  scheduleInvoice,
  selfFinancing,
  service,
  situation,
  stock,
  stockBooking,
  subscription,
  supplier,
  supplierOrder,
  turnoverBalance,
  userCompanies,
  workingCapital,
  workOrder,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    localStorage.removeItem('EssorAppStorage');

    return {
      ...state,
      auth: {
        created: null,
        error: null,
        loading: false,
      },
      userCompanies: {
        select: {
          selectedCompany: null,
          selectedFiscalYear: null,
          selectedDocument: null,
          selectedEmployee: null,
          selectedOffice: null,
        },
        role: {
          userRole: null,
        },
      },
    };
  }

  return EssorApp(state, action);
};

export default rootReducer;
