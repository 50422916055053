import React, { FC } from 'react';
import Big from 'big.js';
import Percentage from '../percentage';
import Currency from '../currency';
import Nbsp from '../Nbsp';

interface OwnProps {
  type: 'e' | 'p';
  value: number;
}

const Discount: FC<OwnProps> = ({ value, type }) => (
  Big(value || 0).eq(0)
    ? <Nbsp />
    : (type === 'p' ? <Percentage value={value} /> : <Currency value={value} />)
);

export default Discount;
