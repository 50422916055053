import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import BankAccounts from './bank-accounts';
import CompanyDetails from './company-details';
import ContactInformation from './contact-information';
import SubscribedOptions from './subscribed-option';
import SubscriptionData from './subscription-data';

import NotFound from '../../404/index';

const Settings = (props) => {
  const { match, location: { office } } = props;

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path="/offices/:id/settings"
          render={() => (
            <Redirect
              to={`/offices/${match.params.id}/settings/contact-information`}
            />
          )}
        />

        <Route
          path="/offices/:id/settings/contact-information"
          render={props => (
            <ContactInformation {...props} office={office} />
          )}
        />
        <Route
          path="/offices/:id/settings/company-details"
          render={props => (
            <CompanyDetails {...props} office={office} />
          )}
        />
        <Route
          path="/offices/:id/settings/bank-accounts"
          render={props => (
            <BankAccounts {...props} office={office} />
          )}
        />
        <Route
          path="/offices/:id/settings/subscription-data"
          render={props => (
            <SubscriptionData {...props} office={office} />
          )}
        />
        <Route
          path="/offices/:id/settings/subscribed-option"
          render={props => (
            <SubscribedOptions {...props} office={office} />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Settings));
