import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/company-settings/show';
import { Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import TableLoader from 'components/TableLoader';

class VarianceReasons extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=VARIANCE_REASONS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrieved)
      && nextProps.retrieved['hydra:member'][0].value !== prevState.value
      && nextProps.retrieved['hydra:member'][0].name === 'VARIANCE_REASONS'
    ) {
      return {
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    return null;
  }

  render() {
    const { value } = this.state;

    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('companiesVarianceReasons')}
          to="/company/settings/variance-reasons/edit"
          entity={Entities.companySettings}
        />
        <TableLoader status={loading}>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {value && value.map((varianceReason, index) => (
                <Table.Row key={`v${index}`}>
                  <Table.Cell>
                    {varianceReason.label}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  error: state.companySettings.show.error,
  loading: state.companySettings.show.loading,
  retrieved: state.companySettings.show.retrieved,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(VarianceReasons);

export default withTranslation()(withRouter(Main));
