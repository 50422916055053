import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve } from 'actions/service/show';
import { floatFormat } from 'utils/formatter';
import { withTranslation } from 'react-i18next';
import { find } from 'lodash';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TwelveForm from 'layouts/TwelveForm';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import KeyValue from 'components/keyValue';
import Page404 from 'routes/admin/404';

class ShowService extends Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const { getService, match, listService } = this.props;

    if (find(listService['hydra:member'], {
      id: parseInt(match.params.id, 10),
    })
    ) {
      getService(`/services/${match.params.id}`);
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { isValid } = this.state;
    const { retrieved, loading, t, match } = this.props;

    if (!isValid) {
      return <Page404 />;
    }

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('servicesShowTitle')}
          to={`/articles/services/${match.params.id}/edit`}
          back="/articles/services"
        />
        <TwelveForm loading={loading}>
          <KeyValue label={t('formLabel')}>
            {retrieved && retrieved.label}
          </KeyValue>

          <KeyValue label={t('formReference')}>
            {retrieved && (retrieved.reference === '' ? '-' : retrieved.reference)}
          </KeyValue>

          <KeyValue label={t('formUnit')}>
            {retrieved && retrieved.unit}
          </KeyValue>

          <KeyValue label={t('formSellingPrice')}>
            {retrieved && floatFormat(retrieved.unitPrice, true)}
          </KeyValue>
        </TwelveForm>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getService: page => dispatch(retrieve(page)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  listService: state.service.list.data,
  retrieved: state.service.show.retrieved,
  loading: state.service.show.loading,
  error: state.service.show.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowService);

export default withTranslation()(withRouter(Main));
