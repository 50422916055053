import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Checkbox, Table } from 'semantic-ui-react';
import { entities } from 'utils/constants';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import SaveButton from 'components/buttons/SaveButton';

class RoleEdit extends Component {
  state = {
    rights: [],
  };

  componentDidMount() {
    const { rights } = this.props;
    this.setState({
      rights,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.rights) && nextProps.rights !== prevState.firstRight) {
      return {
        firstRight: nextProps.rights,
        rights: nextProps.rights,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { mergeRole } = this.props;
    const { rights } = this.state;

    if (!isEmpty(mergeRole) && !isEmpty(rights) && prevProps.mergeRole !== mergeRole) {
      this.mergeRole();
    }
  }

  mergeRole = () => {
    const { mergeRole } = this.props;
    const { rights } = this.state;
    const merge = {
      ...rights,
    };

    Object.keys(mergeRole).forEach((key) => {
      if (merge[key]) {
        merge[key] = [
          ...merge[key],
          ...mergeRole[key],
        ];
      } else {
        merge[key] = [
          ...mergeRole[key],
        ];
      }
    });

    this.setState({
      rights: merge,
    });
  };

  handleSectionBoxChange = (e, entity) => {
    e.preventDefault();
    const { rights } = this.state;

    if (isEmpty(rights[entity.section])) {
      this.selectAllChildren(entity, ['read']);
    } else {
      this.unselectAllChildren(entity);
    }
  };

  handleCheckBoxChange = (e, entity, right) => {
    e.preventDefault();
    const { rights } = this.state;

    let obj;

    if (rights[entity]) {
      const exist = rights[entity].includes(right);
      if (exist) {
        const i = rights[entity].findIndex(item => item === right);
        rights[entity].splice(i, 1);
        obj = [
          ...rights[entity],
        ];
      } else {
        obj = [
          ...rights[entity],
          right,
        ];
      }
    } else {
      obj = [
        right,
      ];
    }

    this.setState(prevState => (
      {
        rights: {
          ...prevState.rights,
          [entity]: obj,
        },
      }
    ));
  };

  handleSubmit = () => {
    const { getRights } = this.props;
    const { rights } = this.state;

    Object.keys(rights).forEach((right) => {
      if (isEmpty(rights[right])) {
        delete rights[right];
      }
    });

    getRights(rights);
  };

  selectAll = (entity, onlyRights = undefined) => {
    const setRights = onlyRights || ['create', 'read', 'update', 'delete'];

    this.setState(prevState => (
      {
        rights: {
          ...prevState.rights,
          [entity]: setRights,
        },
      }
    ));
  };

  unselectAll = (entity) => {
    this.setState(prevState => (
      {
        rights: {
          ...prevState.rights,
          [entity]: [],
        },
      }
    ));
  };

  selectAllChildren = (entityObject, onlyRights = undefined) => {
    entityObject.children.forEach((entity) => {
      this.selectAll(entity, onlyRights);
    });

    this.setState(prevState => (
      {
        rights: {
          ...prevState.rights,
          [entityObject.section]: ['read'],
        },
      }
    ));
  };

  unselectAllChildren = (entityObject) => {
    entityObject.children.forEach((entity) => {
      this.unselectAll(entity);
    });

    this.setState(prevState => (
      {
        rights: {
          ...prevState.rights,
          [entityObject.section]: [],
        },
      }
    ));
  };

  render() {
    const { rights } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        {entities.map((entity, i) => (
          <Table celled structured key={i}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Checkbox
                    label={t(`identityRights${entity.section}`)}
                    checked={rights[entity.section] ? rights[entity.section].includes('read') : false}
                    onChange={e => this.handleSectionBoxChange(e, entity)}
                  />
                  <span className="roleSelector" onClick={() => this.selectAllChildren(entity)}>
                    {t('roleSelectAll')}
                  </span>
                  <span className="roleSelector" onClick={() => this.unselectAllChildren(entity)}>
                    {t('roleUnselectAll')}
                  </span>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {entity.children.map((child, i) => (
                    <Table celled structured key={i} className="table-in-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan="4">
                            <span>{t(`identityRights${child}`)}</span>
                            <span className="roleSelector" onClick={() => this.selectAll(child)}>
                              {t('roleSelectAll')}
                            </span>
                            <span className="roleSelector" onClick={() => this.unselectAll(child)}>
                              {t('roleUnselectAll')}
                            </span>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Checkbox
                              label={t('createTitle')}
                              checked={rights[child] ? rights[child].includes('create') : false}
                              onChange={e => this.handleCheckBoxChange(e, child, 'create')}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              label={t('readTitle')}
                              checked={rights[child] ? rights[child].includes('read') : false}
                              onChange={e => this.handleCheckBoxChange(e, child, 'read')}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              label={t('updateTitle')}
                              checked={rights[child] ? rights[child].includes('update') : false}
                              onChange={e => this.handleCheckBoxChange(e, child, 'update')}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              label={t('deleteTitle')}
                              checked={rights[child] ? rights[child].includes('delete') : false}
                              onChange={e => this.handleCheckBoxChange(e, child, 'delete')}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  ))}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ))}
        <SaveButton onClick={this.handleSubmit} fixed />
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(RoleEdit));
