import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import Currency from '../../../../components/currency';
import SelectableCell from '../../../../layouts/table/SelectableCell';
import { AddMisc } from './types';
import CurrencyCell from '../../../../components/cell/CurrencyCell';

interface OwnProps {
  onClick?: AddMisc;
  value: number;
}

type Props = OwnProps;

const MonthCell: FunctionComponent<Props> = ({ onClick, value }) => (
  onClick
    ? (
      <SelectableCell
        textAlign="right"
        className={classnames('format-number-fr', 'cell-month', {
          red: value < 0,
        })}
        onClick={onClick as () => void}
      >
        <Currency value={value} emptyZero />
      </SelectableCell>
    )
    : <CurrencyCell className="cell-month" value={value} emptyZero color noGreen />
);

export default MonthCell;
