import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import { list as listProduct, reset as resetListProduct } from 'actions/product/list';
import { create as createBulkPrice, success as successCreateBulkPrice, loading as loadingCreateBulkPrice, error as errorCreateBulkPrice } from 'actions/bulk-update/create';
import { Form, Grid, Header } from 'semantic-ui-react';
import { EssorButton, PricesTable, toast } from 'components';
import { withTranslation } from 'react-i18next';
import { inputFormat } from 'utils/formatter';
import DatePicker from 'react-datepicker';

import 'moment/locale/fr';

moment.locale('fr');

class UpdatePrices extends Component {
  state = {
    itemData: null,
    effectDate: null,
    effectDateError: false,
  };

  componentDidMount() {
    const { getProducts, selectedCompany } = this.props;

    getProducts(`/products?company=${selectedCompany.id}`);
  }

  componentDidUpdate() {
    const { itemData } = this.state;
    const { listProduct } = this.props;

    if (!isEmpty(listProduct) && itemData === null) {
      this.setSupplierData();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setSupplierData = () => {
    const { listProduct } = this.props;

    let products = [];

    listProduct['hydra:member'].forEach((product) => {
      if (product.stockManagement) {
        for (let i = 0; i < product.stock.length; i++) {
          products = products.concat({
            id: product.stock[i].id,
            type: product.stock[i]['@type'],
            reference: product.reference,
            label: product.label,
            '@id': product.stock[i]['@id'],
            cost: product.stock[i].supplierPrice,
            updatedCost: product.stock[i].supplierPrice,
            lastUpdated: product.stock[i].lastUpdated,
            percentage: 0,
            checked: false,
          });
        }
      } else {
        products = products.concat({
          id: product.id,
          type: product['@type'],
          reference: product.reference,
          label: product.label,
          '@id': product['@id'],
          cost: product.purchaseUnitCost,
          updatedCost: product.purchaseUnitCost,
          lastUpdated: product.lastUpdated,
          percentage: 0,
          checked: false,
        });
      }
    });

    this.setState({
      itemData: products,
    });
  };

  handleInputChange = (e, item) => {
    const { name, value } = e.target;
    const { itemData } = this.state;

    const newData = itemData.map((obj) => {
      if (obj['@id'] === item['@id']) {
        const newObj = {
          ...obj,
          [name]: value === '' ? '0' : inputFormat(value),
        };

        return this.updateValues(newObj, name);
      }
      return obj;
    });

    this.setState({
      itemData: newData,
    });
  };

  updateValues = (item, name = 'percentage') => {
    switch (name) {
      case 'updatedCost':
        item.percentage = (((item.updatedCost - item.cost) / item.cost) * 100).toFixed(2);
        break;
      case 'percentage':
        item.updatedCost = (((100 + parseFloat(item.percentage)) * item.cost) / 100).toFixed(2);
        break;
      default: break;
    }

    return item;
  };

  handleDateChange = (date) => {
    this.setState({
      effectDate: date,
    });
  };

  handleCheckboxChange = (item) => {
    const { itemData } = this.state;

    const newArray = itemData.map((obj) => {
      if (item['@id'] === obj['@id']) {
        obj.checked = !obj.checked;
      }

      return obj;
    });

    this.setState({
      itemData: newArray,
    });
  };

  handlePercentageApply = (percentage) => {
    const { itemData } = this.state;

    const arr = itemData.map((item) => {
      if (item.checked) {
        return this.updateValues({
          ...item,
          percentage: parseFloat(percentage).toFixed(2),
        });
      }

      return item;
    });

    this.setState({
      itemData: arr,
    });
  };

  handleSubmit = () => {
    const { itemData, effectDate } = this.state;
    const { selectedCompany, postBulkPrice, t } = this.props;
    let isValid = true;

    this.setState({
      effectDateError: false,
    });

    if (!effectDate) {
      isValid = false;

      this.setState({
        effectDateError: true,
      });
    }

    if (!find(itemData, 'checked')) {
      isValid = false;

      toast.error(t('documentNoArticlesSelected'));
    }

    if (!isValid) return;

    const stock = itemData
      .filter(item => item.checked && item.type === 'Stock')
      .map(item => ({
        id: item.id,
        supplierPrice: item.updatedCost,
      }));
    const product = itemData
      .filter(item => item.checked && item.type === 'Product')
      .map(item => ({
        id: item.id,
        purchaseUnitCost: item.updatedCost,
      }));

    const data = {
      company: selectedCompany['@id'],
      date: effectDate.format('YYYY-MM-DD'),
      product,
      stock,
    };

    postBulkPrice(data);
  };

  render() {
    const { itemData, effectDate, effectDateError } = this.state;
    const { t, loadingListProduct, loadingCreateBulkPrice, createdBulkPrice } = this.props;

    if (!isEmpty(createdBulkPrice)) {
      return (
        <Redirect
          push
          to="/articles/prices/"
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('stockPricesUpdate')}</Header>
            <EssorButton as={Link} to="/articles/prices" type="chevron left" size="tiny" floated="right">
              {t('buttonBack')}
            </EssorButton>
          </div>

          <Grid>
            <Grid.Row
              style={{
                paddingBottom: '0',
              }}
            >
              <Grid.Column width={10}>
                <Form className="margin-top-bot main-form" size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formEffectDate')}
                      control={DatePicker}
                      selected={effectDate}
                      minDate={moment()}
                      onChange={this.handleDateChange}
                      locale="fr"
                      error={effectDateError}
                      autoComplete="off"
                      disabled={loadingListProduct || loadingCreateBulkPrice}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <PricesTable
            data={itemData}
            loading={loadingListProduct || loadingCreateBulkPrice}
            onChecked={this.handleCheckboxChange}
            onChange={this.handleInputChange}
            percentageApply={this.handlePercentageApply}
          />
        </div>
        <div className="fixed-button">
          <EssorButton
            type="check"
            primary
            onClick={this.handleSubmit}
          >
            {t('buttonSave')}
          </EssorButton>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: page => dispatch(listProduct(page)),
  postBulkPrice: data => dispatch(createBulkPrice(data)),
  reset: () => {
    dispatch(resetListProduct());
    dispatch(successCreateBulkPrice(null));
    dispatch(loadingCreateBulkPrice(false));
    dispatch(errorCreateBulkPrice(null));
  },
});

const mapStateToProps = state => ({
  listProduct: state.product.list.data,
  loadingListProduct: state.product.list.loading,

  createdBulkPrice: state.bulkPrice.create.created,
  loadingCreateBulkPrice: state.bulkPrice.create.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(UpdatePrices);

export default withTranslation()(withRouter(Main));
