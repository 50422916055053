import React, { FunctionComponent, ReactNode } from 'react';
import TwelveGrid from './TwelveGrid';
import SmallForm from './SmallForm';

interface OwnProps {
  children: ReactNode;
  loading?: boolean;
}

type Props = OwnProps;

const TwelveForm: FunctionComponent<Props> = ({ children, loading }) => (
  <TwelveGrid>
    <SmallForm loading={loading}>
      {children}
    </SmallForm>
  </TwelveGrid>
);

export default TwelveForm;
