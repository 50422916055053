import React from 'react';
import { Form } from 'semantic-ui-react';
import KeyValue from 'components/keyValue';
import { useTranslation } from 'react-i18next';

const DocumentReference = ({ isEditable, onChange, reference }) => {
  const { t } = useTranslation();

  return (isEditable
    ? (
      <Form.Group inline>
        <Form.Input
          label={t('formReference')}
          placeholder={t('formPHReference')}
          onChange={onChange}
          name="reference"
          value={reference}
        />
      </Form.Group>
    )
    : <KeyValue label={t('formReference')}>{reference}</KeyValue>
  );
};


export default DocumentReference;
