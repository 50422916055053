import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { reset as resetSelfFinancing, retrieve as retrieveSelfFinancing } from 'actions/self-financing/show';
import { crudRights, Entities } from 'types/accessRights';

import ContainerGeneral from 'layouts/ContainerGeneral';
import CheckRoute from 'components/access/CheckRoute';
import Routes from 'routes/routes';
import useFiscalYear from 'hooks/useFiscalYear';
import ShowSelfFinancing from './show';
import EditSelfFinancing from './edit';
import NotFound from '../../404';

const SelfFinancing = () => {
  const [, fiscalYear] = useFiscalYear();

  const retrievedSelfFinancing = useSelector(state => state.selfFinancing.show.retrieved);
  const loadingSelfFinancing = useSelector(state => state.selfFinancing.show.loading);
  const created = useSelector(state => state.selfFinancing.create.created);
  const updated = useSelector(state => state.selfFinancing.update.updated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveSelfFinancing(`/self_financings?fiscalYear=${fiscalYear.id}`));

    return () => {
      dispatch(resetSelfFinancing());
    };
  },
  [fiscalYear, created, updated]);


  if (loadingSelfFinancing || isEmpty(retrievedSelfFinancing)) {
    return <ContainerGeneral status={loadingSelfFinancing} />;
  }

  return (
    <Switch>
      <CheckRoute
        exact
        path={Routes.Forecast.selfFinancing}
        component={ShowSelfFinancing}
        entity={Entities.selfFinancing}
      />
      <CheckRoute
        exact
        path={Routes.Forecast.editSelfFinancing}
        component={EditSelfFinancing}
        entity={Entities.selfFinancing}
        right={crudRights.update}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default SelfFinancing;
