import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import { reset as resetCompanySettings, retrieve as retrieveCompanySettings } from 'actions/company-settings/show';
import { list as listHolidays, reset as resetHolidays } from 'actions/holiday/list';
import { reset as resetUpdateHoliday, update as updateHoliday } from 'actions/holiday/update';
import { del as deleteHoliday, error as errorDeleteHoliday, loading as loadingDeleteHoliday, success as successDeleteHoliday } from 'actions/holiday/delete';
import { create as createHoliday, error as errorCreateHoliday, loading as loadingCreateHoliday, success as successHoliday } from 'actions/holiday/create';
import { Form, Modal, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import 'moment/locale/fr';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import ModalButtons from 'components/ModalButtons';
import SmallForm from 'layouts/SmallForm';
import HeaderRow from 'layouts/table/HeaderRow';
import TableLoader from 'components/TableLoader';
import DateCell from 'components/cell/DateCell';
import EditCellButton from 'components/buttons/EditCellButton';
import DeleteCellButton from 'components/buttons/DeleteCellButton';
import { DeleteConfirmation } from 'components';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';

moment.locale('fr');

class ShowProduct extends Component {
  state = {
    holidayLabel: '',
    date: null,
    holidayLabelError: false,
    dateError: false,
    toDelete: null,
    toEdit: null,
    holidayData: null,
    isHolidaysLoaded: false,
    deleteHolidayModalOpen: false,
    createHolidayModalOpen: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getHolidays,
      getValidDays,
      selectedCompany,
      selectedFiscalYear,
      reset,
    } = this.props;
    reset();
    getHolidays(`/holidays?fiscalYear=${selectedFiscalYear.id}`);
    getValidDays(`/company_settings?company=${selectedCompany.id}&name=OPENING_HOURS`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.successHoliday || nextProps.updatedHoliday) {
      const {
        resetCreateHoliday,
        resetUpdateHoliday,
        resetHolidayList,
        getHolidays,
        selectedFiscalYear,
      } = nextProps;
      resetCreateHoliday();
      resetUpdateHoliday();
      resetHolidayList();
      getHolidays(`/holidays?fiscalYear=${selectedFiscalYear.id}`);

      return {
        holidayData: null,
        holidayLabel: '',
        date: null,
        isHolidaysLoaded: false,
        createHolidayModalOpen: false,
      };
    }

    if (nextProps.successDeleteHoliday) {
      const { resetDeleteHoliday, resetHolidayList, getHolidays, selectedFiscalYear } = nextProps;
      resetDeleteHoliday();
      resetHolidayList();
      getHolidays(`/holidays?fiscalYear=${selectedFiscalYear.id}`);

      return {
        isHolidaysLoaded: false,
        deleteHolidayModalOpen: false,
      };
    }

    if (!isEmpty(nextProps.dataHoliday) && !prevState.isHolidaysLoaded) {
      return {
        holidayData: nextProps.dataHoliday['hydra:member'],
        isHolidaysLoaded: true,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  openDeleteHolidayModal = (id) => {
    const { holidayData } = this.state;
    const item = find(holidayData, {
      '@id': id,
    });

    this.setState({
      deleteHolidayModalOpen: true,
      toDelete: item,
    });
  };

  closeDeleteHolidayModal = () => {
    this.setState({
      deleteHolidayModalOpen: false,
      toDelete: null,
    });
  };

  handleDeleteHoliday = () => {
    const { toDelete } = this.state;
    const { deleteHoliday } = this.props;

    deleteHoliday(toDelete);
  };

  openCreateHolidayModal = (id) => {
    const { holidayData } = this.state;
    const item = find(holidayData, {
      '@id': id,
    });

    this.setState({
      createHolidayModalOpen: true,
      toEdit: item || null,
      holidayLabel: item ? item.label : '',
      date: item ? moment(item.date) : null,
    });
  };

  closeCreateHolidayModal = () => {
    this.setState({
      createHolidayModalOpen: false,
      holidayLabel: '',
      date: null,
      holidayLabelError: false,
      dateError: false,
      toEdit: null,
      hasDataChanged: false,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
      hasDataChanged: true,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      date,
      hasDataChanged: true,
    });
  };

  handleHolidaySubmit = () => {
    const { holidayLabel, date, toEdit } = this.state;
    const {
      selectedFiscalYear,
      postHoliday,
      updateHoliday,
    } = this.props;

    const dateEnd = moment(selectedFiscalYear.dateEnd);
    const dateStart = moment(selectedFiscalYear.dateStart);
    const id = selectedFiscalYear['@id'];

    let isValid = true;

    this.setState({
      holidayLabelError: false,
      dateError: false,
    });

    if (holidayLabel.trim() === '') {
      isValid = false;

      this.setState({
        holidayLabelError: true,
      });
    }

    if (!date || date > dateEnd || date < dateStart) {
      isValid = false;

      this.setState({
        dateError: true,
      });
    }

    if (!isValid) return;

    const data = {
      label: holidayLabel,
      date: date.format(),
      fiscalYear: id,
    };

    this.setState({
      hasDataChanged: false,
    });

    toEdit ? updateHoliday(toEdit, data) : postHoliday(data);
  };

  fillBusinessDays = () => {
    const { holidayData } = this.state;
    const { selectedFiscalYear, retrievedCompanySettings, t } = this.props;

    if (!selectedFiscalYear) return;

    let { dateStart, dateEnd } = selectedFiscalYear;

    dateStart = moment(dateStart);
    dateEnd = moment(dateEnd);

    const months = [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december'),
    ];
    const table = [];
    const validDaysArray = [];

    if (dateStart === '' || retrievedCompanySettings === null || dateEnd === '' || holidayData === null) {
      return null;
    }

    // Getting business days for company

    const validDays = retrievedCompanySettings['hydra:member'][0].value;

    for (const key in validDays) {
      if (Object.prototype.hasOwnProperty.call(validDays, key)) {
        switch (key) {
          case 'monday':
            if (validDays[key].length > 0) validDaysArray.push(1);
            break;
          case 'tuesday':
            if (validDays[key].length > 0) validDaysArray.push(2);
            break;
          case 'wednesday':
            if (validDays[key].length > 0) validDaysArray.push(3);
            break;
          case 'thursday':
            if (validDays[key].length > 0) validDaysArray.push(4);
            break;
          case 'friday':
            if (validDays[key].length > 0) validDaysArray.push(5);
            break;
          case 'saturday':
            if (validDays[key].length > 0) validDaysArray.push(6);
            break;
          case 'sunday':
            if (validDays[key].length > 0) validDaysArray.push(0);
            break;
          default:
            break;
        }
      }
    }

    let aux;
    let monthAux;

    // Total number of iterations based on start date and end date
    const loop = dateEnd.month() > dateStart.month()
      ? (12 * (dateEnd.year() - dateStart.year()) + dateEnd.month() - dateStart.month())
      : (12 * (dateEnd.year() - dateStart.year()) - dateStart.month() + dateEnd.month());

    for (let i = 0; i <= loop; i++) {
      let businessDays = 0;
      let holidayCount = 0;

      switch (i) {
        case 0: { // First iteration
          monthAux = dateStart.month();
          const lastDay = (dateStart.month() === dateEnd.month() && dateStart.year() === dateEnd.year()) // eslint-disable-line
            ? dateEnd.date()
            : dateStart.daysInMonth();
          // From initial date to last day in month
          for (let j = dateStart.date(); j <= lastDay; j++) {
            aux = moment().year(dateStart.year()).month(dateStart.month()).date(j);
            // If day is include on valid days count for business days
            if (validDaysArray.includes(aux.day())) businessDays += 1;
          }
          // Holidays check
          for (let m = 0; m < holidayData.length; m++) {
            const d = moment(holidayData[m].date);
            // If the holiday date coincide with date, it counts for holiday
            if (d.month() === dateStart.month() && d.year() === dateStart.year()) {
              holidayCount += 1;

              if (validDaysArray.includes(d.day())) businessDays -= 1;
            }
          }

          table.push(
            <Table.Row key={`${i}${months[monthAux]}`}>
              <Table.Cell>
                {`${months[monthAux]} - ${aux.year()}`}
              </Table.Cell>
              <Table.Cell>{businessDays}</Table.Cell>
              <Table.Cell>{holidayCount}</Table.Cell>
              <Table.Cell>{aux.daysInMonth()}</Table.Cell>
            </Table.Row>,
          );
          break;
        }
        case loop: { // Last iteration
          monthAux = dateEnd.month();
          for (let j = 1; j <= dateEnd.date(); j++) {
            aux = moment().year(dateEnd.year()).month(dateEnd.month()).date(j);

            if (validDaysArray.includes(aux.day())) businessDays += 1;
          }

          for (let n = 0; n < holidayData.length; n++) {
            const d = moment(holidayData[n].date);

            if (d.month() === dateEnd.month() && d.year() === dateEnd.year()) {
              holidayCount += 1;

              if (validDaysArray.includes(d.day())) businessDays -= 1;
            }
          }

          table.push(
            <Table.Row key={`${i}${months[monthAux]}`}>
              <Table.Cell>
                {`${months[monthAux]} - ${aux.year()}`}
              </Table.Cell>
              <Table.Cell>{businessDays}</Table.Cell>
              <Table.Cell>{holidayCount}</Table.Cell>
              <Table.Cell>{aux.daysInMonth()}</Table.Cell>
            </Table.Row>,
          );
          break;
        }
        default: {
          // Calculating date's month and year
          let actualMonth = dateStart.month() + i;
          const actualYear = actualMonth >= 12
            ? (dateStart.year() + parseInt(actualMonth / 12, 10))
            : dateStart.year();
          actualMonth %= 12; // Set month on range 0-11
          // Set date on moment object
          const forLoopDate = moment().year(actualYear).month(actualMonth).date(1);
          forLoopDate.date(forLoopDate.daysInMonth());

          for (let j = 1; j <= forLoopDate.date(); j++) {
            aux = moment().year(forLoopDate.year()).month(forLoopDate.month()).date(j);

            if (validDaysArray.includes(aux.day())) businessDays += 1;
          }

          for (let n = 0; n < holidayData.length; n++) {
            const d = moment(holidayData[n].date);

            if (d.month() === forLoopDate.month() && d.year() === forLoopDate.year()) {
              holidayCount += 1;

              if (validDaysArray.includes(d.day())) businessDays -= 1;
            }
          }

          table.push(
            <Table.Row key={`${i}${months[actualMonth]}`}>
              <Table.Cell>
                {`${months[actualMonth]} - ${aux.year()}`}
              </Table.Cell>
              <Table.Cell>{businessDays}</Table.Cell>
              <Table.Cell>{holidayCount}</Table.Cell>
              <Table.Cell>{aux.daysInMonth()}</Table.Cell>
            </Table.Row>,
          );
          break;
        }
      }
    }

    return table;
  };

  render() {
    const {
      holidayLabel,
      date,
      holidayLabelError,
      dateError,
      holidayData,
      toEdit,
      toDelete,
      createHolidayModalOpen,
      deleteHolidayModalOpen,
      hasDataChanged,
    } = this.state;

    const {
      loadingHoliday,
      loadingCreateHoliday,
      loadingDeleteHoliday,
      loadingCompanySettings,
      loadingUpdateHoliday,
      t,
    } = this.props;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <AddHeader title={t('holidaysHomeTitle')} onClick={this.openCreateHolidayModal} />

        <Modal open={createHolidayModalOpen} className="small-height small-width">
          <Modal.Header>{toEdit ? t('holidaysUpdateTitle') : t('holidaysCreateTitle')}</Modal.Header>
          <Modal.Content scrolling className="show-overflow">
            <Modal.Description>
              <SmallForm loading={loadingCreateHoliday || loadingUpdateHoliday}>
                <Form.Group inline>
                  <Form.Input
                    label={t('formLabel')}
                    name="holidayLabel"
                    placeholder={t('formPHLabel')}
                    value={holidayLabel}
                    onChange={this.handleInputChange}
                    error={holidayLabelError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Input
                    label={t('formDate')}
                    name="date"
                    control={DatePicker}
                    selected={date}
                    onChange={this.handleDateChange}
                    locale="fr"
                    autoComplete="off"
                    error={dateError}
                  />
                </Form.Group>
              </SmallForm>
            </Modal.Description>
          </Modal.Content>
          <ModalButtons
            disabled={loadingCreateHoliday || loadingUpdateHoliday}
            cancel={this.closeCreateHolidayModal}
            save={this.handleHolidaySubmit}
          />
        </Modal>

        <TableLoader status={loadingHoliday} table celled striped>
          <HeaderRow>
            <LeftCell />
            <LeftCell content={t('formLabel')} />
            <RightCell content={t('formDate')} />
            <LeftCell />
            <LeftCell />
          </HeaderRow>

          <Table.Body>
            {holidayData && holidayData.map((holiday, index) => (
              <Table.Row key={index}>
                <LeftCell content={index + 1} />
                <LeftCell content={holiday.label} />
                <DateCell date={holiday.date} />
                <EditCellButton
                  tip="Modifier"
                  onClick={() => this.openCreateHolidayModal(holiday['@id'])}
                />
                <DeleteCellButton
                  tip="Supprimer"
                  onClick={() => this.openDeleteHolidayModal(holiday['@id'])}
                />
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoader>

        <DeleteConfirmation
          show={deleteHolidayModalOpen}
          name={toDelete ? toDelete.label : ''}
          title={t('holidayDeleteTitle')}
          loading={loadingDeleteHoliday}
          onClose={this.closeDeleteHolidayModal}
          onDelete={this.handleDeleteHoliday}
        />

        <TableLoader status={loadingHoliday || loadingCompanySettings} table celled striped>
          <HeaderRow>
            <LeftCell content={t('month')} />
            <LeftCell content={t('businessDays')} />
            <LeftCell content={t('holidays')} />
            <LeftCell content={t('monthDays')} />
          </HeaderRow>

          <Table.Body>
            {this.fillBusinessDays()}
          </Table.Body>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getValidDays: page => dispatch(retrieveCompanySettings(page)),
  getHolidays: page => dispatch(listHolidays(page)),

  postHoliday: data => dispatch(createHoliday(data)),
  updateHoliday: (item, data) => dispatch(updateHoliday(item, data)),
  deleteHoliday: item => dispatch(deleteHoliday(item)),

  resetCreateHoliday: () => {
    dispatch(successHoliday(null));
    dispatch(loadingCreateHoliday(false));
    dispatch(errorCreateHoliday(null));
  },
  resetDeleteHoliday: () => {
    dispatch(successDeleteHoliday(null));
    dispatch(loadingDeleteHoliday(false));
    dispatch(errorDeleteHoliday(null));
  },
  resetUpdateHoliday: () => dispatch(resetUpdateHoliday()),
  resetHolidayList: () => dispatch(resetHolidays()),
  reset: () => {
    dispatch(resetHolidays());
    dispatch(resetCompanySettings());
  },
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  dataHoliday: state.holiday.list.data,
  loadingHoliday: state.holiday.list.loading,
  errorHoliday: state.holiday.list.error,
  retrievedCompanySettings: state.companySettings.show.retrieved,
  loadingCompanySettings: state.companySettings.show.loading,
  errorCompanySettings: state.companySettings.show.error,
  updatedHoliday: state.holiday.update.updated,
  loadingUpdateHoliday: state.holiday.update.updateLoading,
  errorUpdateHoliday: state.holiday.update.updateError,
  successDeleteHoliday: state.holiday.del.deleted,
  loadingDeleteHoliday: state.holiday.del.loading,
  errorDeleteHoliday: state.holiday.del.error,
  successHoliday: state.holiday.create.created,
  loadingCreateHoliday: state.holiday.create.loading,
  errorCreateHoliday: state.holiday.create.error,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(ShowProduct);

export default withTranslation()(withRouter(Main));
