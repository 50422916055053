import React, { FunctionComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../../components/buttons/SaveButton';
import CancelButton from '../../../components/buttons/CancelButton';

interface OwnProps {
  warningContentModal: boolean;
  onWarningClose: () => void;
  setModelContent: (append?: boolean) => void;
}

type Props = OwnProps;

const ReplaceContentModal: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    warningContentModal,
    onWarningClose,
    setModelContent,
  } = props;

  return (
    <Modal open={warningContentModal} onClose={onWarningClose}>
      <Modal.Header>{t('warning')}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <p>{t('replaceItemsMessage')}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <SaveButton onClick={(): void => setModelContent(false)} label={t('buttonYes')} />
          <SaveButton onClick={(): void => setModelContent(true)} label={t('buttonAppend')} />
          <CancelButton onClick={onWarningClose} />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default ReplaceContentModal;
