import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { create as createCompany, error as errorCompany, loading as loadingCompany, success as successCompany } from 'actions/company/create';
import { list as listOffice, reset as resetOffice } from 'actions/office/list';
import { reset as resetUpdateCompany, update as updateCompany } from 'actions/company/update';
import { retrieve as retrieveExpert } from 'actions/expert/show';
import { selectCompany } from 'actions/user-companies/select';
import { list as listCompany } from 'actions/company/list';
import { Dropdown, Form, Grid, Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isAdmin } from 'utils/roles';
import SaveButton from 'components/buttons/SaveButton';
import ContainerGeneral from 'layouts/ContainerGeneral';
import PromptUnsavedData from 'components/PromptUnsavedData';
import BackHeader from 'components/pageHeaders/BackHeader';

class CreateCompany extends Component {
  state = {
    office: '',
    name: '',

    calculationMode: null,
    officesList: null,
    officeNameError: false,
    calculationModeError: false,
    companyNameError: false,
    isEditing: false,
    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getOffices,
      getExpert,
      userID,
    } = this.props;

    /* if (isAdmin(userRole)) {
       getOffices();
     } else {
       this.setState({
         office: selectedCompany.office,
       });
     } */

    getOffices();
    getExpert(`/experts?identity=/identities/${userID}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    if (!isEmpty(nextProps.selectedCompany) && nextProps.match.path === '/company/edit'
      && nextProps.selectedCompany.id !== prevState.id) {
      return {
        id: nextProps.selectedCompany.id,
        office: nextProps.selectedCompany.office,
        name: nextProps.selectedCompany.name,
        calculationMode: nextProps.selectedCompany.calculationMode,
        isEditing: true,
      };
    }

    if (!isEmpty(nextProps.retrievedExpert) && prevState.office !== nextProps.retrievedExpert['hydra:member'][0].office['@id']) {
      return {
        office: nextProps.retrievedExpert['hydra:member'][0].office['@id'],
      };
    }

    if (!isEmpty(nextProps.listOffice) && nextProps.listOffice['hydra:member'] !== prevState.officesList) {
      return {
        officesList: nextProps.listOffice['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { updated, success, selectCompany, getCompanies } = this.props;

    if (updated !== prevProps.updated) {
      selectCompany(updated);
      getCompanies();
    }

    if (success !== prevProps.success) {
      selectCompany(success);
      getCompanies();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
      hasDataChanged: true,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleOnSubmit = () => {
    const { office, name, calculationMode, isEditing } = this.state;
    const { postCompany, updateCompany, selectedCompany } = this.props;
    let isValid = true;

    this.setState({
      officeNameError: false,
      companyNameError: false,
      calculationModeError: false,
    });

    if (office === '') {
      isValid = false;
      this.setState({
        officeNameError: true,
      });
    }

    if (name === '') {
      isValid = false;

      this.setState({
        companyNameError: true,
      });
    }

    if (!calculationMode) {
      isValid = false;

      this.setState({
        calculationModeError: true,
      });
    }

    if (!isValid) {
      return;
    }
    const data = {
      office,
      name,
      calculationMode,
    };

    isEditing ? updateCompany(selectedCompany, data) : postCompany(data);
  };

  render() {
    const {
      office,
      name,
      calculationMode,
      officeNameError,
      companyNameError,
      calculationModeError,
      officesList,
      hasDataChanged,
    } = this.state;

    const {
      userRole,
      loadingCompany,
      loadingOffice,
      loadingExpert,
      updateLoading,
      errorCompany,
      success,
      updated,
      t,
      match,
    } = this.props;

    const updateID = match.path === '/company/edit';

    let offices = [];

    if (officesList && officesList.length > 0) {
      offices = officesList.map(office => ({
        key: office.id,
        text: office.name,
        value: office['@id'],
      }));
    }

    const calculationOptions = [
      {
        key: '1',
        text: t('formSellingCoefficient'),
        value: 'coef',
      },
      {
        key: '2',
        text: t('formMarginRate'),
        value: 'rate',
      },
    ];

    if (success) {
      const redirect = {
        pathname: '/company/settings/company-details/edit',
        company: true,
      };
      return (
        <Redirect push to={redirect} />
      );
    }

    if (updated) {
      return (
        <Redirect push to="/company" />
      );
    }

    return (
      <ContainerGeneral>
        <PromptUnsavedData hasDataChanged={hasDataChanged} />
        <BackHeader
          to={updateID ? '/company' : '/company/list'}
          title={updateID ? t('companiesUpdateTitle') : `${t('step')} 1 / 3: ${t('companiesCreateTitle')}`}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <Form
                className="margin-top-bot main-form"
                loading={loadingCompany || updateLoading || loadingExpert}
                size="small"
              >
                {!updateID && isAdmin(userRole)
                && (
                  <Form.Group inline>
                    <Form.Select
                      label={t('formOffice')}
                      control={Dropdown}
                      placeholder={t('formPHSelect')}
                      name="office"
                      fluid
                      search
                      selection
                      loading={loadingOffice}
                      disabled={loadingOffice}
                      noResultsMessage="No results"
                      options={offices}
                      onChange={this.handleSelectChange}
                      error={officeNameError}
                      value={office}
                    />
                  </Form.Group>
                )}
                <Form.Group inline>
                  <Form.Input
                    label={t('formName')}
                    name="name"
                    placeholder={t('formPHName')}
                    value={name}
                    onChange={this.handleInputChange}
                    error={companyNameError}
                  />
                </Form.Group>

                {!updateID
                && (
                  <Form.Group inline>
                    <Form.Select
                      label={t('formCalculationMode')}
                      control={Dropdown}
                      placeholder={t('formPHSelect')}
                      name="calculationMode"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={calculationOptions}
                      onChange={this.handleSelectChange}
                      error={calculationModeError}
                      value={calculationMode}
                    />
                  </Form.Group>
                )}

              </Form>

              <Message negative hidden={!errorCompany}>
                <p>{errorCompany}</p>
              </Message>

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="clearfix">
          <SaveButton onClick={this.handleOnSubmit} floated="right" />
        </div>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postCompany: data => dispatch(createCompany(data)),
  getOffices: () => dispatch(listOffice()),
  updateCompany: (item, data) => dispatch(updateCompany(item, data)),
  selectCompany: company => dispatch(selectCompany(company)),
  getCompanies: () => dispatch(listCompany()),
  getExpert: page => dispatch(retrieveExpert(page)),
  reset: () => {
    dispatch(loadingCompany(false));
    dispatch(errorCompany(null));
    dispatch(successCompany(null));
    dispatch(resetOffice());
    dispatch(resetUpdateCompany());
  },
});

const mapStateToProps = state => ({
  successAuth: state.auth.created,

  success: state.company.create.created,
  loadingCompany: state.company.create.loading,
  errorCompany: state.company.create.error,
  loadingOffice: state.office.list.loading,
  listOffice: state.office.list.data,

  selectedCompany: state.userCompanies.select.selectedCompany,
  userRole: state.userCompanies.role.userRole,

  updated: state.company.update.updated,
  updateError: state.company.update.updateError,
  updateLoading: state.company.update.updateLoading,

  retrieved: state.expert.update.retrieved,
  userID: state.userCompanies.role.userID,

  loadingExpert: state.expert.show.loading,
  errorExpert: state.expert.show.loading,
  retrievedExpert: state.expert.show.retrieved,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateCompany);

export default withTranslation()(withRouter(Main));
