enum Company {
  home = '/company',
  situation = '/company/situation',
  edit = '/company/edit',
  holidays = '/company/holidays',
  documentNumbering = '/company/document_numbering',
  list = '/company/list',
  create = '/company/create',
  fiscalYears = '/company/fiscal_years',
  createFiscalYear = '/company/fiscal_years/create',
  settings = '/company/settings',
  settingsContactInformation = '/company/settings/contact-information',
  settingsCompanyDetails = '/company/settings/company-details',
  settingsCompanyShares = '/company/settings/company-shares',
  settingsBankAccounts = '/company/settings/bank-accounts',
  settingsOpeningHours = '/company/settings/opening-hours',
  settingsVatRates = '/company/settings/vat-rates',
  settingsUnits = '/company/settings/units',
  settingsContractTypes = '/company/settings/contract-types',
  settingsDayOffReasons = '/company/settings/day-off-reasons',
  settingsOfferReasons = '/company/settings/offer-reasons',
  settingsQuoteWonReasons = '/company/settings/quote-won-reasons',
  settingsQuoteLostReasons = '/company/settings/quote-lost-reasons',
  settingsVarianceReasons = '/company/settings/variance-reasons',
  settingsDocumentFooters = '/company/settings/document-footers',
  settingsRoles = '/company/settings/roles',
}

export default Company;
