import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Form, Grid, Table } from 'semantic-ui-react';
import { find, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CleaveOptions } from 'cleave.js/options';
import { useDispatch, useSelector } from 'react-redux';
import Big from 'big.js';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import CleaveInit from '../../../components/input/CleaveInit';
import { getFloatCleaveFormat, inputFormat } from '../../../utils/formatter';
import { EssorButton } from '../../../components';
import RowColumn from '../../../layouts/RowColumn';
import HeaderRow from '../../../layouts/table/HeaderRow';
import LeftCell from '../../../layouts/table/LeftCell';
import { Employee, EmployeeData, State } from '../../../types/store';
import useFiscalYear from '../../../hooks/useFiscalYear';
import { labourCost, plannedHours } from '../../../actions/document/liveDocument';
import { getEmployeeList } from '../../../components/documents/documentOptions';
import DeleteCellButton from '../../../components/buttons/DeleteCellButton';
import { list as listEmployee } from '../../../actions/employee/list';
import useCompany from '../../../hooks/useCompany';
import { IRI } from '../../../types/brand';

interface EmployeeWithHours {
  employee: Employee;
  hours: number;
}

interface StaffList {
  employee: IRI;
  hours: number;
}

interface OwnProps {
  staff: StaffList[];
  setStaff: (staff: StaffList[]) => void;
  setPlannedHoursError: (error: boolean) => void;
}

type Props = OwnProps;

const SelectEmployees: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const { staff, setStaff, setPlannedHoursError } = props;
  const empty = undefined as unknown;

  const [, company] = useCompany();
  const [, fiscalYear] = useFiscalYear();
  const employeeData = useSelector((state: State) => state.employee.list.data);
  const loading = useSelector((state: State) => state.employee.list.loading);

  const [selectedEmployee, setSelectedEmployee] = useState(empty as Employee);
  const [employeeHours, setEmployeeHours] = useState(0);
  const [employeeHoursError, setEmployeeHoursError] = useState(false);
  const [selectedEmployeeError, setSelectedEmployeeError] = useState(false);

  const filterAssigned = (employee: DropdownItemProps): boolean => (
    !staff.find((i: StaffList): boolean => i.employee === employee.key)
  );

  const employeeList: Employee[] = employeeData['hydra:member'];
  const employees = getEmployeeList(employeeList, fiscalYear).filter(filterAssigned);

  const dispatch = useDispatch();
  const setLabourCost = (total: string): void => { dispatch(labourCost(total)); };
  const setPlannedHours = (hours: string): void => { dispatch(plannedHours(hours)); };

  useEffect(() => {
    if (company) {
      dispatch(listEmployee(`/employees?company=${company.id}`));
    }
  }, [fiscalYear]);

  const computeLabourCost = (): void => {
    let sumHourlyCost = Big(0);
    let sumHours = Big(0);

    if (!isEmpty(staff)) {
      const employeesShow: EmployeeWithHours[] = staff.map(item => ({
        employee: find(employeeList, {
          '@id': item.employee,
        }) as Employee,
        hours: item.hours,
      } as EmployeeWithHours));

      employeesShow.forEach((item: EmployeeWithHours): void => {
        const data = item.employee.data.find((i: EmployeeData) => (
          fiscalYear && i.fiscalYear === fiscalYear['@id']
        )) as EmployeeData;

        if (data && data.hoursSynthesis.total) {
          const hourlyCost = Big(data.hoursSynthesis.total.hourlyCost).times(item.hours || 0);
          sumHourlyCost = sumHourlyCost.plus(hourlyCost);
          sumHours = sumHours.plus(item.hours || 0);
        }
      });
    }

    const employeeAverageCost = sumHours.gt(0) ? sumHourlyCost.div(sumHours) : Big(0);
    setLabourCost(employeeAverageCost.toFixed(2));
    setPlannedHours(sumHours.toFixed(2));
  };

  const selectEmployee = (e: SyntheticEvent, formSelect: DropdownProps): void => {
    e.preventDefault();
    setSelectedEmployee(formSelect.value as unknown as Employee);
  };

  const resetForm = (): void => {
    setSelectedEmployee(empty as Employee);
    setEmployeeHours(empty as number);
  };

  const addStaff = (): void => {
    let isValid = true;

    setSelectedEmployeeError(false);
    setEmployeeHoursError(false);

    if (!selectedEmployee) {
      isValid = false;
      setSelectedEmployeeError(true);
    }

    if (employeeHours <= 0) {
      isValid = false;
      setEmployeeHoursError(true);
    }

    if (!isValid) {
      return;
    }

    const employee: Employee = JSON.parse(selectedEmployee.toString());

    const data = {
      employee: employee['@id'],
      hours: employeeHours,
    };

    staff.push(data);
    setStaff(staff);
    computeLabourCost();
    resetForm();
  };

  const updateStaff = (hours: number, staffData: EmployeeWithHours): void => {
    let errorHours = false;

    staff.forEach((item) => {
      if (staffData.employee['@id'] === item.employee) {
        item.hours = hours;
      }
      if (!item.hours) {
        errorHours = true;
      }
    });
    setStaff(staff);
    computeLabourCost();
    setPlannedHoursError(errorHours);
  };

  const deleteStaff = (index: number): void => {
    staff.splice(index, 1);
    setStaff(staff);
    computeLabourCost();
  };

  let staffShow: EmployeeWithHours[] = [];
  if (!isEmpty(staff) && employeeList && employeeList.length > 0) {
    staffShow = staff.map((item) => {
      const employee: Employee | undefined = find(employeeList, {
        '@id': item.employee,
      });

      return {
        employee,
        hours: item.hours,
      } as EmployeeWithHours;
    });
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={7}>
          <Form.Group className="select-list">
            <Form.Select
              label={t('formStaffAttribution')}
              onChange={selectEmployee}
              control={Dropdown}
              placeholder={t('formPHSelect')}
              fluid
              search
              selection
              loading={loading}
              disabled={loading}
              noResultsMessage="No results"
              options={employees}
              value={selectedEmployee ? selectedEmployee.toString() : ''}
              error={selectedEmployeeError}
              selectOnBlur={false}
            />
          </Form.Group>
        </Grid.Column>

        <Grid.Column width={3}>
          <h5 className="small">{t('companiesHours')}</h5>
          <Form.Input disabled={loading} error={employeeHoursError}>
            <CleaveInit
              options={getFloatCleaveFormat()}
              name="employeeHours"
              value={employeeHours}
              handler={(value: number): void => setEmployeeHours(value)}
            />
          </Form.Input>
        </Grid.Column>

        <Grid.Column width={2}>
          <Form.Group className="select-list">
            <Form.Field>
              <label>{' '}</label>
              <EssorButton size="small" fluid icon type="plus" onClick={addStaff} />
            </Form.Field>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>

      {!isEmpty(staffShow) && (
        <RowColumn width={12}>
          <div className="select-list">
            <label>{t('purchaseOrderStaff')}</label>

            <Table celled structured className="margin-bot">
              <HeaderRow>
                <LeftCell content={t('employeesShowTitle')} />
                <LeftCell content={t('documentPlannedHours')} />
                <LeftCell />
              </HeaderRow>
              <Table.Body>
                {staffShow.map((item: EmployeeWithHours, index: number) => (
                  <Table.Row key={item.employee['@id']}>
                    <LeftCell>
                      {`${item.employee.firstName} ${item.employee.lastName}`}
                    </LeftCell>

                    <LeftCell width="3">
                      <Form.Input fluid error={item.hours <= 0} className="m-t-0">
                        <CleaveInit
                          options={getFloatCleaveFormat() as CleaveOptions}
                          name="planned"
                          handler={(value: number): void => updateStaff(value, item)}
                          placeholder={10}
                          value={inputFormat(item.hours, true)}
                        />
                      </Form.Input>
                    </LeftCell>

                    <DeleteCellButton onClick={(): void => deleteStaff(index)} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </RowColumn>
      )}
    </Grid>
  );
};

export default SelectEmployees;
