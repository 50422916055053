/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { percentageFormat } from 'utils/formatter';

const PieChart = ({ title, data }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: 'vertical',
      itemMarginBottom: 3,
      symbolPadding: 5,
      symbolHeight: 0.1,
      symbolRadius: 0,
      useHTML: true,
      symbolWidth: 0,
      labelFormatter() {
        return (`
        <div>
          <div
            style="background-color:${this.color};padding:5px;display:inline-block;color:#FFFFFF;font-weight:normal;margin-right:5px"
          >
            ${percentageFormat(this.percentage)}
          </div>
          <span style="display:inline-block">${this.name}</span>
        </div>
      `);
      },
    },
    series: data,
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default PieChart;
