import React, { FunctionComponent, ReactNode } from 'react';
import useAccessRights from '../../hooks/useAccessRights';
import { crudRights, Entities } from '../../types/accessRights';
import { CheckAccessRights } from '../../types/store';

interface OwnProps extends CheckAccessRights {
  children: ReactNode;
  entity: Entities;
}

type Props = OwnProps;

/**
 * Check a display block for entity / right and return an empty fragment if not allowed
 */
const CheckRights: FunctionComponent<Props> = ({ children, entity, right }) => {
  const hasAccess = useAccessRights(entity, (right || crudRights.read));

  if (!hasAccess) {
    return <React.Fragment />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default CheckRights;
