import React from 'react';
import { Input } from 'semantic-ui-react';
import { getFloatCleaveFormat } from 'utils/formatter';
import CurrencyCleave from 'components/input/CurrencyCleave';

const FloatInput = ({ error, style, displayOnly, children, ...others }) => (
  <React.Fragment>
    {displayOnly
      ? children
      : (
        <Input size="small" fluid error={error} style={style}>
          <CurrencyCleave options={getFloatCleaveFormat()} {...others} />
        </Input>
      )}
  </React.Fragment>
);

export default FloatInput;
