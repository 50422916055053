import { RetrieveAction } from '../../types/actions';
import { CompanySituation } from '../../routes/admin/situation/situationTypes';

interface SituationState {
  data: CompanySituation;
  error: string;
  loading: boolean;
}

const initialState: SituationState = {
  data: {},
  error: '',
  loading: true,
};

export function retrieve(state = initialState, action: RetrieveAction): SituationState {
  if (action.type === 'COMPANY_SITUATION_ERROR') {
    return {
      ...state,
      error: action.error,
    };
  }
  if (action.type === 'COMPANY_SITUATION_LOADING') {
    return {
      ...state,
      loading: action.loading,
    };
  }
  if (action.type === 'COMPANY_SITUATION_SUCCESS') {
    return {
      ...state,
      data: action.data,
    };
  }
  return state;
}

export default retrieve;
