import React, { FC } from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { State } from '../types/store';

const Theme: FC = () => {
  const theme = useSelector((s: State) => s.userCompanies.select.theme);

  let ActualTheme;
  const loaderStyle = {
    color: '#333',
    position: 'absolute' as 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    padding: '5em',
    zIndex: 99999999,
    backgroundColor: 'white',
  };

  const loadingText = ' ';

  if (theme === 'dark') {
    ActualTheme = Loadable({
      loader: () => import('./ThemeDark'),
      loading: () => (<div style={loaderStyle}>{loadingText}</div>),
    });
  } else {
    ActualTheme = Loadable({
      loader: () => import('./ThemeLight'),
      loading: () => (<div style={loaderStyle}>{loadingText}</div>),
    });
  }

  return <ActualTheme />;
};

export default Theme;
