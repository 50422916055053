import React, { FunctionComponent } from 'react';
import { Header as Title } from 'semantic-ui-react';
import { Card } from './index';
import { FormattedCurrency } from '../../types/brand';
import { DatePeriod } from '../../types/dto';

interface OwnProps {
  data: {
    currentPeriod: FormattedCurrency;
    nextPeriod: FormattedCurrency;
  };
  currentPeriod: DatePeriod;
  nextPeriod: DatePeriod;
}

type Props = OwnProps;

const TreasuryCards: FunctionComponent<Props> = ({ currentPeriod, nextPeriod, data }) => (
  <div>
    <Title as="h5">Trésorerie</Title>
    <Card color date={currentPeriod} amount={data.currentPeriod} />
    <Card color date={nextPeriod} amount={data.nextPeriod} />
  </div>
);

export default TreasuryCards;
