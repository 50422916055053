import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';

interface OwnProps {
  title: string;
}

type Props = OwnProps;

const ForecastTitleRow: FunctionComponent<Props> = ({ title }) => (
  <Table.Row>
    <Table.Cell className="table-title">{title}</Table.Cell>
    <Table.Cell colSpan={3} />
  </Table.Row>
);

export default ForecastTitleRow;
