import { ReactNode } from 'react';
import { Entity } from 'types/entity';

export interface ColDef<T extends Entity> {
  name: string;
  type: ColType;
  attribute: string;
  show: boolean;
  handler?: dataHandler<T>;
  cellHandler?: cellHandler<T>;
}

export type ColType = ''
  | 'number'
  | 'percentage'
  | 'currency'
  | 'date'
  | 'boolean';

export type SupportedTypes = number | string | boolean;
export type dataHandler<T extends Entity> = (item: T) => string;
export type cellHandler<T extends Entity> = (item: T) => ReactNode;

export function columnDefinition(name: string, attribute: string, type: ColType = '', show = true): ColDef<Entity> {
  return {
    attribute,
    name,
    show,
    type,
  };
}

export function hiddenColumn(name: string, attribute: string, type: ColType = ''): ColDef<Entity> {
  return columnDefinition(name, attribute, type, false);
}

export function cellDefinition<T extends Entity>(name: string, attribute: string, cellHandler: cellHandler<T>, type: ColType = '', handler?: dataHandler<T>): ColDef<T> {
  return {
    ...columnDefinition(name, attribute, type),
    cellHandler,
    handler,
  };
}

export function handlerDefinition<T extends Entity>(name: string, attribute: string, handler: dataHandler<T>, type: ColType = '', show = true): ColDef<T> {
  return {
    ...columnDefinition(name, attribute, type, show),
    handler,
  };
}

export function hiddenHandler<T extends Entity>(name: string, attribute: string, handler: dataHandler<T>, type: ColType = ''): ColDef<T> {
  return handlerDefinition(name, attribute, handler, type, false);
}
