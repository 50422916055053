import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { create, error, loading, success } from 'actions/customer/create';
import { Form, Modal } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import { honorificOptions } from '../../utils/constants';

class CreateCustomerModal extends Component {
  state = {
    companyName: '',
    contactName: '',
    honorific: '',
    streetName: '',
    zipCode: '',
    additional: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    tvaNumber: '',
    cellphone: '',
    contactNameError: false,
    streetNameError: false,
    zipCodeError: false,
    cityError: false,
    additionalError: false,
    countryError: false,
    phoneError: false,
    emailError: false,
    tvaNumberError: false,
    cellphoneError: false,

    hasDataChanged: false,
  };

  componentDidUpdate(prevProps) {
    const { successCustomer, onSuccess } = this.props;

    if (prevProps.successCustomer !== successCustomer) {
      onSuccess(successCustomer);
      this.closeModal();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;

    reset();
  }

  closeModal = () => {
    const { onClose } = this.props;

    this.setState({
      companyName: '',
      contactName: '',
      contactNameError: false,
      hasDataChanged: false,
    });

    onClose();
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleOnSubmit = () => {
    const {
      companyName,
      contactName,
      honorific,
      email,
      cellphone,
      phone,
      streetName,
      additional,
      zipCode,
      city,
      country,
      tvaNumber,
    } = this.state;

    const {
      postCustomer,
      selectedCompany,
    } = this.props;

    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = true;

    this.setState({
      contactNameError: false,
      streetNameError: false,
      additionalError: false,
      countryError: false,
      zipCodeError: false,
      cityError: false,
      phoneError: false,
      emailError: false,
      tvaNumberError: false,
      cellphoneError: false,
    });

    if (country.trim() === '') {
      isValid = false;

      this.setState({
        countryError: true,
      });
    }

    if (contactName.trim() === '') {
      isValid = false;

      this.setState({
        contactNameError: true,
      });
    }

    if (streetName.trim() === '') {
      isValid = false;

      this.setState({
        streetNameError: true,
      });
    }

    if (zipCode.trim() === '' || !Number.isInteger(parseFloat(zipCode))) {
      isValid = false;

      this.setState({
        zipCodeError: true,
      });
    }

    if (city.trim() === '') {
      isValid = false;

      this.setState({
        cityError: true,
      });
    }

    if (email.trim() === '' || !regex.test(email)) {
      isValid = false;

      this.setState({
        emailError: true,
      });
    }

    if (!isValid) return;

    const data = {
      company: selectedCompany['@id'],
      companyName,
      contactName,
      creationDate: moment().format(),
      details: {
        honorific,
        phone,
        email,
        tvaNumber,
        cellphone,
        streetName,
        additional,
        country,
        zipCode,
        city,
      },
    };

    this.setState({
      hasDataChanged: false,
    });

    postCustomer(data);
  };

  render() {
    const {
      companyName,
      contactName,
      honorific,
      email,
      phone,
      cellphone,
      streetName,
      additional,
      zipCode,
      city,
      country,
      tvaNumber,
      contactNameError,
      emailError,
      phoneError,
      cellphoneError,
      streetNameError,
      additionalError,
      zipCodeError,
      cityError,
      countryError,
      tvaNumberError,
      hasDataChanged,
    } = this.state;

    const {
      modalOpen,
      loadingCreateCustomer,
      t,
    } = this.props;

    return (
      <Modal
        open={modalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Prompt
          when={hasDataChanged}
          message={t('warningYouHaveUnsavedData')}
        />
        <Modal.Header>{t('recipientCreateTitle')}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form className="margin-top-bot main-form" loading={loadingCreateCustomer} size="small">
              <Form.Group inline>
                <Form.Input
                  label={t('formCompanyName')}
                  name="companyName"
                  placeholder={t('formPHCompanyName')}
                  onChange={this.handleInputChange}
                  value={companyName}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Select
                  label={t('formHonorific')}
                  name="honorific"
                  placeholder={t('formPHSelect')}
                  value={honorific}
                  options={honorificOptions}
                  onChange={this.handleSelectChange}
                  selectOnBlur={false}
                  clearable
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formContactName')}
                  name="contactName"
                  placeholder={t('formPHContactName')}
                  onChange={this.handleInputChange}
                  value={contactName}
                  error={contactNameError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formEmail')}
                  name="email"
                  placeholder="example@email.com"
                  value={email}
                  onChange={this.handleInputChange}
                  error={emailError}
                  autoComplete="off"
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formPhoneNumber')}
                  name="phone"
                  placeholder={t('formPHPhoneNumber')}
                  value={phone}
                  onChange={this.handleInputChange}
                  error={phoneError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formCellphoneNumber')}
                  name="cellphone"
                  placeholder={t('formPHCellphoneNumber')}
                  value={cellphone}
                  onChange={this.handleInputChange}
                  error={cellphoneError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formStreetName')}
                  name="streetName"
                  placeholder={t('formPHStreetName')}
                  onChange={this.handleInputChange}
                  value={streetName}
                  error={streetNameError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formAdditional')}
                  name="additional"
                  placeholder={t('formPHAdditional')}
                  onChange={this.handleInputChange}
                  value={additional}
                  error={additionalError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formZipCode')}
                  name="zipCode"
                  placeholder={t('formPHZipCode')}
                  onChange={this.handleInputChange}
                  value={zipCode}
                  error={zipCodeError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formCity')}
                  name="city"
                  placeholder={t('formPHCity')}
                  onChange={this.handleInputChange}
                  value={city}
                  error={cityError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formCountry')}
                  name="country"
                  placeholder={t('formPHCountry')}
                  onChange={this.handleInputChange}
                  value={country}
                  error={countryError}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formVATNumber')}
                  name="tvaNumber"
                  placeholder={t('formPHVATNumber')}
                  value={tvaNumber}
                  onChange={this.handleInputChange}
                  error={tvaNumberError}
                />
              </Form.Group>

            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" disabled={loadingCreateCustomer}>
              {t('buttonSave')}
            </EssorButton>
            <EssorButton secondary type="x" size="tiny" onClick={this.closeModal} disabled={loadingCreateCustomer}>
              {t('buttonCancel')}
            </EssorButton>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postCustomer: data => dispatch(create(data)),
  reset: () => {
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(success(null));
  },
});

const mapStateToProps = state => ({
  successCustomer: state.customer.create.created,
  loadingCreateCustomer: state.customer.create.loading,
  errorCreateCustomer: state.customer.create.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateCustomerModal);

export default withTranslation()(withRouter(Main));
