import React from 'react';
import FormatCell from 'components/cell/FormatCell';
import Float from 'components/float';

const FloatCell = ({ value, ...others }) => (
  <FormatCell value={value} align="right" {...others}>
    <Float value={value} />
  </FormatCell>
);

export default FloatCell;
