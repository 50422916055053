import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { create, error, loading, success } from 'actions/self-financing/create';
import { reset as resetUpdateSelfFinancing, update as updateSelfFinancing } from 'actions/self-financing/update';
import { Input, Label, Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { getCurrency, getFloatCleaveFormat, inputFormat } from 'utils/formatter';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import SaveButton from 'components/buttons/SaveButton';
import InlineField from 'layouts/InlineField';
import CleaveInit from 'components/input/CleaveInit';
import { Big } from 'utils/functions';

class CreateSelfFinancing extends Component {
  state = {
    selfFinancing: null,
    operatingProfit: '',
    provisionAndDepreciation: '',
    result: '',
    operatingProfitError: false,
    provisionAndDepreciationError: false,

    hasDataChanged: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedSelfFinancing['hydra:member'])
      && prevState.selfFinancing !== nextProps.retrievedSelfFinancing['hydra:member'][0]
    ) {
      return {
        selfFinancing: nextProps.retrievedSelfFinancing['hydra:member'][0],
        operatingProfit: nextProps.retrievedSelfFinancing['hydra:member'][0].operatingProfit,
        provisionAndDepreciation: nextProps.retrievedSelfFinancing['hydra:member'][0].provisionAndDepreciation,
        result: nextProps.retrievedSelfFinancing['hydra:member'][0].result,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputBlur = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: parseFloat(inputFormat(value)).toFixed(2).toString(),
    });
  };

  handleInputChange = (name, value) => {
    const { selectedFiscalYear } = this.props;

    let { dateStart, dateEnd } = selectedFiscalYear;

    dateStart = moment(dateStart);
    dateEnd = moment(dateEnd);

    const totalMonths = (moment(dateEnd).year() - moment(dateStart).year()) * 12 - dateStart.month() + dateEnd.month() + 1; // eslint-disable-line

    this.setState({
      [name]: value,
      hasDataChanged: true,
    }, () => {
      const { operatingProfit, provisionAndDepreciation } = this.state;

      this.setState({
        result: operatingProfit !== '' && provisionAndDepreciation !== ''
          ? (Big(provisionAndDepreciation).plus(operatingProfit)).div(totalMonths)
          : '0',
      });
    });
  };

  handleOnSubmit = () => {
    const {
      selfFinancing,
      operatingProfit,
      provisionAndDepreciation,
      result,
    } = this.state;

    const { postSelfFinancing, updateSelfFinancing, selectedFiscalYear } = this.props;

    let isValid = true;

    this.setState({
      operatingProfitError: false,
      provisionAndDepreciationError: false,
    });

    if (operatingProfit.trim() === '') {
      isValid = false;

      this.setState({
        operatingProfitError: true,
      });
    }

    if (provisionAndDepreciation.trim() === '') {
      isValid = false;

      this.setState({
        provisionAndDepreciationError: true,
      });
    }

    if (!isValid) return;

    const data = {
      operatingProfit,
      provisionAndDepreciation,
      result: result.toFixed(2).toString(),
      fiscalYear: selectedFiscalYear['@id'],
    };

    this.setState({
      hasDataChanged: false,
    });

    selfFinancing ? updateSelfFinancing(selfFinancing, data) : postSelfFinancing(data);
  };

  render() {
    const {
      selfFinancing,
      operatingProfit,
      provisionAndDepreciation,
      result,
      operatingProfitError,
      provisionAndDepreciationError,
      hasDataChanged,
    } = this.state;

    const {
      loading,
      error,
      success,
      retrieveLoading,
      updateLoading,
      updated,
      t,
    } = this.props;

    if (success || updated) {
      return <Redirect push to="/forecast/self-financing" />;
    }

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader
          title={selfFinancing ? t('selfFinancingsUpdateTitle') : t('selfFinancingsCreateTitle')}
          to="/forecast/self-financing"
        />
        <TwelveForm loading={loading || retrieveLoading || updateLoading}>
          <InlineField error={operatingProfitError}>
            <label>{t('formOperatingProfit')}</label>
            <Input labelPosition="right">
              <CleaveInit
                options={getFloatCleaveFormat()}
                handler={value => this.handleInputChange('operatingProfit', value)}
                onBlur={this.handleInputBlur}
                name="operatingProfit"
                placeholder={t('formPHOperatingProfit')}
                value={operatingProfit}
              />
              <Label>{getCurrency()}</Label>
            </Input>
          </InlineField>

          <InlineField error={provisionAndDepreciationError}>
            <label>{t('formProvisionAndDepreciation')}</label>
            <Input labelPosition="right">
              <CleaveInit
                options={getFloatCleaveFormat()}
                handler={value => this.handleInputChange('provisionAndDepreciation', value)}
                onBlur={this.handleInputBlur}
                name="provisionAndDepreciation"
                placeholder={t('formPHProvisionAndDepreciation')}
                value={provisionAndDepreciation}
              />
              <Label>{getCurrency()}</Label>
            </Input>
          </InlineField>

          <InlineField>
            <label>{t('formResult')}</label>
            <Input labelPosition="right">
              <CleaveInit
                options={getFloatCleaveFormat()}
                name="result"
                placeholder={t('formPHResult')}
                value={result}
                readOnly
              />
              <Label>{getCurrency()}</Label>
            </Input>
          </InlineField>

          <Message negative hidden={!error}>{error}</Message>
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} floated="right" />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postSelfFinancing: data => dispatch(create(data)),
  updateSelfFinancing: (item, values) => dispatch(updateSelfFinancing(item, values)),
  reset: () => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(resetUpdateSelfFinancing());
  },
});

const mapStateToProps = state => ({
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  success: state.selfFinancing.create.created,
  loading: state.selfFinancing.create.loading,
  error: state.selfFinancing.create.error,

  retrievedSelfFinancing: state.selfFinancing.show.retrieved,
  retrieveError: state.selfFinancing.show.error,
  retrieveLoading: state.selfFinancing.show.loading,

  updateError: state.selfFinancing.update.updateError,
  updateLoading: state.selfFinancing.update.updateLoading,
  updated: state.selfFinancing.update.updated,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateSelfFinancing);

export default withTranslation()(withRouter(Main));
