import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DashboardWeb } from 'layouts';
import Theme from 'layouts/Theme';
import Routes from 'routes/web/routes';
import ForgottenPassword from 'routes/web/ForgottenPassword';
import ChangePassword from 'routes/web/ChangePassword';
import Login from 'routes/web/login/index';

const Web = () => (
  <React.Fragment>
    <Theme />
    <DashboardWeb>
      <Switch>
        <Route exact path={Routes.login} component={Login} />
        <Route exact path={Routes.forgottenPassword} component={ForgottenPassword} />
        <Route path={Routes.changePassword} component={ChangePassword} />
      </Switch>
    </DashboardWeb>
  </React.Fragment>
);

export default Web;
