import { VisibilityAction, VisibilityDispatch } from '../../types/actions';

const SET_WORK_ORDER_MODAL = 'MODAL/SET_WORK_ORDER_MODAL';

export const setOpen = (visibility: boolean): VisibilityAction => ({
  type: SET_WORK_ORDER_MODAL,
  visibility,
});

export const open = () => (dispatch: VisibilityDispatch) => {
  dispatch(setOpen(true));
};

export const close = () => (dispatch: VisibilityDispatch) => {
  dispatch(setOpen(false));
};
