import { toast } from 'components';
import i18n from 'i18n';
import fetch from '../../utils/fetch';

export function error(error) {
  return {
    type: 'FISCAL_YEAR_PRINT_ERROR', error,
  };
}

export function loading(loading) {
  return {
    type: 'FISCAL_YEAR_PRINT_LOADING', loading,
  };
}

export function retrieved(retrieved) {
  return {
    type: 'FISCAL_YEAR_PRINT_SUCCESS', retrieved,
  };
}

export function print(id) {
  const options = {};
  options.responseType = 'blob';
  options.headers = new Headers();
  options.headers.set('Accept', 'application/pdf');

  return (dispatch) => {
    dispatch(loading(true));

    return fetch(id, options)
      .then(response => response.blob())
      .then((data) => {
        dispatch(loading(false));
        dispatch(retrieved(URL.createObjectURL(new Blob([data]))));
      })
      .catch((e) => {
        toast.error(i18n.t('toastError'));
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return {
    type: 'FISCAL_YEAR_PRINT_RESET',
  };
}
