import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

function VisibilityHeaderCell(props) {
  const { label, type, visibility, handler, className, show, title } = props;

  if (show === false) {
    return <React.Fragment />;
  }

  return (
    <Table.HeaderCell className={className} title={title}>
      <div className="flex-cell">
        {label}
        <Icon
          link
          name={visibility ? 'eye' : 'eye slash'}
          onClick={() => handler(type)}
        />
      </div>
    </Table.HeaderCell>
  );
}

export default VisibilityHeaderCell;
