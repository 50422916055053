import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Form, Grid, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Info from 'layouts/Info';
import { isDraft } from 'utils/documentStatus';
import KeyValue from 'components/keyValue';
import { isQuote, isWorkOrder } from 'utils/documentType';
import { Document } from 'types/document';

interface OwnProps {
  document: Document;
  onChange: (e: SyntheticEvent) => void;
  value: string;
}

type Props = OwnProps;

const Notes: FunctionComponent<Props> = ({ document, value, ...others }) => {
  const { t } = useTranslation();

  return (
    <Grid.Column floated="left" width={6}>
      <Info>
        {isQuote(document) || isWorkOrder(document)
          ? t('formNotes')
          : t('formCustomerComment')
        }
      </Info>
      <Form className="main-form">
        {isDraft(document)
          ? <TextArea rows={4} name="note" value={value} {...others} />
          : <KeyValue>{value}</KeyValue>
        }
      </Form>
    </Grid.Column>
  );
};

export default Notes;
