import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import HoursSynthesis from 'components/hoursSynthesis';
import Percentage from 'components/percentage';
import Bool from 'components/bool';
import Currency from 'components/currency';
import KeyValue from 'components/keyValue';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import SmallForm from 'layouts/SmallForm';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TwelveGrid from 'layouts/TwelveGrid';

class ShowData extends Component {
  state = {
    data: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedEmployeeData)
      && !isEmpty(nextProps.retrievedEmployeeData['hydra:member'])
      && nextProps.retrievedEmployeeData['hydra:member'][0] !== prevState.employeeData
    ) {
      return {
        data: nextProps.retrievedEmployeeData['hydra:member'][0],
      };
    }

    return null;
  }

  render() {
    const {
      loadingEmployeeData,
      match,
      t,
    } = this.props;

    const { data } = this.state;

    return (
      <ContainerGeneral status={loadingEmployeeData}>
        {!isEmpty(data) && (
          <SmallForm>
            <ModifyHeader
              to={`/employees/${match.params.id}/data-synthesis/edit`}
              title={t('employeeDataAndSynthesis')}
              entity={Entities.employeeData}
            />

            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <KeyValue label={t('formEmployerTaxRate')}>
                    <Percentage value={data.employerTaxRate} />
                  </KeyValue>
                  <KeyValue label={t('formGrossMonthlyPay')}>
                    <div className="format-number-fr">
                      <Currency value={data.grossMonthlyPay} />
                    </div>
                  </KeyValue>
                  <KeyValue label={t('formGrossAnnualBonus')}>
                    <div className="format-number-fr">
                      <Currency value={data.grossAnnualBonus} />
                    </div>
                  </KeyValue>
                </Grid.Column>

                <Grid.Column width={8}>
                  <KeyValue label={t('formHoursToSell')}>
                    <Bool value={data && data.hoursToSell} />
                  </KeyValue>
                  {data.hoursToSell && (
                    <Fragment>
                      <KeyValue label={t('formExploitationRate')}>
                        <Percentage value={data.exploitationRate} />
                      </KeyValue>
                      <KeyValue label={t('formEfficiencyRate')}>
                        <Percentage value={data.efficiencyRate} />
                      </KeyValue>
                    </Fragment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <TwelveGrid>
              <HoursSynthesis
                hoursSynthesis={data.hoursSynthesis}
                hoursToSell={data.hoursToSell}
              />
            </TwelveGrid>
          </SmallForm>
        )}
      </ContainerGeneral>
    );
  }
}

const mapStateToProps = state => ({
  retrievedEmployeeData: state.employeeData.show.retrieved,
  loadingEmployeeData: state.employeeData.show.loading,
  errorEmployeeData: state.employeeData.show.error,
});

const Main = connect(mapStateToProps)(ShowData);

export default withTranslation()(withRouter(Main));
