import React from 'react';
import { Table } from 'semantic-ui-react';
import { TextEditor } from 'components/index';

function CommentBodyLine(props) {
  const { item, editable, setComment } = props;

  return (
    <Table.Cell colSpan={8}>
      <TextEditor
        editorLabel={item.comment}
        editorOnlyShow={editable ? undefined : item.comment}
        setLabel={editorContent => setComment(item, editorContent)}
      />
    </Table.Cell>
  );
}

export default CommentBodyLine;
