import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import Big from 'big.js';
import useMarginCalculation from '../hooks/useMarginCalculation';
import FloatCell from './cell/FloatCell';
import PercentageCell from './cell/PercentageCell';
import CurrencyCell from './cell/CurrencyCell';
import DateCell from './cell/DateCell';
import BooleanIconCell from './icons/BooleanIconCell';
import { ColType, SupportedTypes } from '../utils/tables';

interface OwnProps {
  data: SupportedTypes;
  type: ColType;
  marginRate?: number;
  unitPrice?: boolean;
}

type Props = OwnProps;

const TableListFormattedCell: FC<Props> = ({ data, type, unitPrice, marginRate }) => {
  if (type && typeof unitPrice === 'undefined') {
    switch (type.length >= 1) {
      case type === 'number':
        return (<FloatCell value={data} />);
      case type === 'percentage':
        return (<PercentageCell value={data} />);
      case type === 'currency':
        return (<CurrencyCell value={data} />);
      case type === 'date':
        return (<DateCell date={data} />);
      case type === 'boolean':
        return (<BooleanIconCell value={data as boolean} />);
      default:
        break;
    }
  } else if (typeof unitPrice === 'boolean') {
    const marginCalc = useMarginCalculation();
    const margin = marginCalc.getBigPriceFromCost(Big(data as number), Big(marginRate as number));
    return (<CurrencyCell value={margin} />);
  }

  return (<Table.Cell>{data}</Table.Cell>);
};

export default TableListFormattedCell;
