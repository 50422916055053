import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { reset } from 'actions/stock/list';
import AddHeader from 'components/pageHeaders/AddHeader';
import Articles from 'routes/admin/articles/routes';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { useTranslation } from 'react-i18next';
import { Entities } from 'types/accessRights';
import { backToHome } from 'utils/constants';
import { columnDefinition, handlerDefinition } from '../../../../../utils/tables';
import AdvancedList from '../../../../../components/advancedList/AdvancedList';

const HomeMovement = () => {
  const { t } = useTranslation();
  const dataMovement = useSelector(state => state.movement.list.data);
  const loading = useSelector(state => state.movement.list.loading);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () => {
      if (!isEmpty(dataMovement)) {
        setData(dataMovement['hydra:member']);
      }
      return () => {
        dispatch(reset());
      };
    },
    [dataMovement],
  );

  const typeDisplay = ({ type }) => t(type === 'IN' ? 'stockIn' : 'stockOut');

  return (
    <ContainerGeneral>
      <AddHeader
        title={t('movementHomeTitle')}
        to={Articles.createMovement}
        label={t('buttonCreateStockOut')}
        entity={Entities.stockMovement}
      />
      <AdvancedList
        columns={[
          columnDefinition('ensembleProduct', 'product.label'),
          handlerDefinition('stockType', 'type', typeDisplay),
          columnDefinition('formCreationDate', 'creationDate', 'date'),
          columnDefinition('formQuantity', 'quantity', 'number'),
          columnDefinition('formPrice', 'unitPrice', 'currency'),
        ]}
        filterBtn
        exportBtn
        loading={loading}
        data={data}
        onView={item => history.push(`/articles/movement/${item.id}`)}
        onEdit={item => history.push(`/articles/movement/${item.id}/edit`, backToHome)}
        keyWord="sellingPrice"
        entity={Entities.stockMovement}
      />
    </ContainerGeneral>
  );
};

export default HomeMovement;
