import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, findIndex, isEmpty, sortBy } from 'lodash';
import { list as listHourSynthesis, reset as resetListHourSynthesis } from 'actions/hour-synthesis/list';
import { reset as resetUpdateHourSynthesis, update as updateHourSynthesis } from 'actions/hour-synthesis/update';
import { reset as resetRetrievedHourSynthesis, retrieve as retrieveHourSynthesis } from 'actions/hour-synthesis/show';
import { Input, Label, Table } from 'semantic-ui-react';
import { getLocalFormat, inputFormat } from 'utils/formatter';
import { toast } from 'components';
import { withTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import SaveButton from 'components/buttons/SaveButton';
import TableLoader from 'components/TableLoader';
import { crudRights, Entities } from 'types/accessRights';
import HoursSynthesis from 'components/hoursSynthesis';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import RightCell from 'layouts/table/RightCell';
import FloatCell from 'components/cell/FloatCell';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import PercentageCell from 'components/cell/PercentageCell';
import SectionGeneral from 'layouts/SectionGeneral';
import SectionContainer from 'layouts/SectionContainer';

class ShowCompany extends Component {
  state = {
    hourSynthesisData: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataHourSynthesis) && nextProps.dataHourSynthesis['hydra:member'] !== prevState.hourSynthesisData) {
      return {
        hourSynthesisData: nextProps.dataHourSynthesis['hydra:member'],
      };
    }

    return null;
  }

  componentDidMount() {
    const { getHourSynthesisList, selectedFiscalYear } = this.props;

    getHourSynthesisList(`/hour_syntheses?fiscalYear=${selectedFiscalYear.id}`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { hourSynthesisData } = this.state;
    const keys = name.split('.');

    const item = find(hourSynthesisData, {
      id: parseInt(keys[0], 10),
    });
    const index = findIndex(hourSynthesisData, {
      id: parseInt(keys[0], 10),
    });

    item[keys[1]] = inputFormat(value);

    switch (keys[1]) {
      case 'adaptedHours':
        if (parseFloat(item.adaptedHours) && parseFloat(item.hoursToSell)
          && parseFloat(item.hoursToSell) !== 0) {
          item.percentage = (item.adaptedHours * 100 / item.hoursToSell).toFixed(2).toString();
        } else {
          item.percentage = '0';
        }
        break;
      case 'percentage':
        if (parseFloat(item.hoursToSell) && parseFloat(item.percentage)) {
          item.adaptedHours = Math.ceil(item.hoursToSell * item.percentage / 100).toString();
        } else {
          item.adaptedHours = '0';
        }
        break;
      default:
        break;
    }

    hourSynthesisData[index] = item;

    this.setState({
      hourSynthesisData,
    });
  };

  handleOnSubmit = () => {
    const { hourSynthesisData } = this.state;
    const { updateHourSynthesis, t } = this.props;
    const promises = [];

    hourSynthesisData.forEach((item) => {
      promises.push(updateHourSynthesis(item, item));
    });

    Promise.all(promises).then(() => toast.success(t('hourSynthesisUpdateSuccess')));
  };

  render() {
    const { hourSynthesisData } = this.state;

    const {
      selectedFiscalYear,
      loadingUpdateHourSynthesis,
      loadingListHourSynthesis,
      t,
    } = this.props;

    let workedDaysTotal = 0;
    let hoursToSellTotal = 0;
    let adaptedHoursTotal = 0;

    if (!isEmpty(hourSynthesisData)) {
      hourSynthesisData.forEach((item) => {
        workedDaysTotal += parseFloat(item.workedDays);
        hoursToSellTotal += parseFloat(item.hoursToSell);
        adaptedHoursTotal += parseFloat(item.adaptedHours);
      });
    }

    const percentageTotal = hoursToSellTotal !== 0
      ? (adaptedHoursTotal * 100 / hoursToSellTotal)
      : 0;

    const loading = loadingListHourSynthesis || loadingUpdateHourSynthesis;

    return (
      <SectionContainer>
        <SectionGeneral>
          <HoursSynthesis
            hoursSynthesis={selectedFiscalYear.hoursSynthesis}
            hoursToSell
          />
        </SectionGeneral>

        {!isEmpty(hourSynthesisData) && (
          <SectionGeneral>
            <TitleHeader title={t('forecastMonthlyHourSynthesis')} />

            <TableLoader status={loading} table celled striped>
              <HeaderRow>
                <LeftCell content={t('forecastDate')} />
                <RightCell content={t('forecastWorkedDays')} />
                <RightCell content={t('forecastHourSell')} />
                <RightCell collapsing content={t('forecastAdaptedHours')} />
                <RightCell className="synthesis-header-cell" content="%" />
              </HeaderRow>

              <Table.Body>
                {sortBy(hourSynthesisData, ['year', 'month']).map(item => (
                  <Table.Row key={item['@id']}>
                    <Table.Cell>{`${item.year} - ${item.month}`}</Table.Cell>
                    <FloatCell value={item.workedDays} />
                    <FloatCell value={item.hoursToSell} />
                    <Table.Cell>
                      <Input className="synthesis-cell">
                        <Cleave
                          options={getLocalFormat(false, 0)}
                          name={`${item.id}.adaptedHours`}
                          value={inputFormat(item.adaptedHours, true)}
                          onChange={this.handleInputChange}
                        />
                      </Input>
                    </Table.Cell>
                    <Table.Cell>
                      <Input labelPosition="right" className="synthesis-cell">
                        <Cleave
                          options={getLocalFormat()}
                          name={`${item.id}.percentage`}
                          value={inputFormat(item.percentage, true)}
                          onChange={this.handleInputChange}
                        />
                        <Label>%</Label>
                      </Input>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <LeftCell header>{t('companiesTotal')}</LeftCell>
                <FloatCell header value={workedDaysTotal} />
                <FloatCell header value={hoursToSellTotal} />
                <FloatCell header value={adaptedHoursTotal} />
                <PercentageCell header value={percentageTotal} />
              </Table.Footer>
            </TableLoader>

            <SaveButton
              onClick={this.handleOnSubmit}
              disabled={hoursToSellTotal > adaptedHoursTotal}
              floated="right"
              entity={Entities.hourSynthesis}
              right={crudRights.update}
            />
          </SectionGeneral>
        )}
      </SectionContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getHourSynthesis: page => dispatch(retrieveHourSynthesis(page)),
  getHourSynthesisList: page => dispatch(listHourSynthesis(page)),
  updateHourSynthesis: (item, data) => dispatch(updateHourSynthesis(item, data)),
  resetUpdateHourSynthesis: () => dispatch(resetUpdateHourSynthesis()),
  reset: () => {
    dispatch(resetListHourSynthesis());
    dispatch(resetUpdateHourSynthesis());
    dispatch(resetRetrievedHourSynthesis());
  },
});

const mapStateToProps = state => ({
  retrievedHourSynthesis: state.hourSynthesis.show.retrieved,
  loadingRetrieveHourSynthesis: state.hourSynthesis.show.loading,
  errorRetrieveHourSynthesis: state.hourSynthesis.show.error,

  updatedHourSynthesis: state.hourSynthesis.update.updated,
  loadingUpdateHourSynthesis: state.hourSynthesis.update.updateLoading,
  errorUpdateHourSynthesis: state.hourSynthesis.update.updateError,

  dataHourSynthesis: state.hourSynthesis.list.data,
  loadingListHourSynthesis: state.hourSynthesis.list.loading,
  errorListHourSynthesis: state.hourSynthesis.list.error,

  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowCompany);

export default withTranslation()(withRouter(Main));
