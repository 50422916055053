import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { auth, error, loading } from 'actions/auth/auth';

import Routes from 'routes/web/routes';
import { withTranslation } from 'react-i18next';

import { Button, Form, Grid, Image, Message } from 'semantic-ui-react';
import checkBlue from 'assets/images/checkblue.png';

class Login extends Component {
  state = {
    username: '',
    password: '',
    usernameError: false,
    passwordError: false,
  };

  componentDidMount() {
    const { reset } = this.props;
    reset();
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleDataUser = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { loginUser, reset } = this.props;

    reset();

    let isValid = true;

    this.setState({
      usernameError: false,
      passwordError: false,
    });

    if (username === '') {
      isValid = false;

      this.setState({
        usernameError: true,
      });
    }

    if (password === '') {
      isValid = false;

      this.setState({
        passwordError: true,
      });
    }

    if (!isValid) return;

    const data = {
      username,
      password,
    };

    loginUser(data);
  };

  render() {
    const { usernameError, passwordError } = this.state;
    const { loading, error, success, t } = this.props;

    if (!loading && success) {
      return <Redirect to="/" />;
    }

    return (
      <div className="login-form">
        <Grid textAlign="center" className="login-grid" verticalAlign="middle">
          <Grid.Column width={4}>
            <Form size="large">
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Addresse mail"
                name="username"
                disabled={loading}
                onChange={this.handleDataUser}
                error={usernameError}
                autoComplete="username"
                autoFocus
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Mot de passe"
                type="password"
                name="password"
                disabled={loading}
                onChange={this.handleDataUser}
                error={passwordError}
                autoComplete="current-password"
              />

              <Button
                className="color-primary login-button"
                color="teal"
                fluid
                size="large"
                loading={loading}
                onClick={this.handleLogin}
              >
                {t('signIn')}
                <Image src={checkBlue} className="check" />
              </Button>
            </Form>

            {error
            && (
              <Message negative>
                <p>{t('failLogin')}</p>
              </Message>
            )
            }
            <div className="password-forgot">
              <a href={Routes.forgottenPassword}>{t('forgotYourPassword')}</a>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(auth(data)),
  reset: () => {
    dispatch(loading(false));
    dispatch(error(null));
  },
});

const mapStateToProps = state => ({
  success: state.auth.created,
  error: state.auth.error,
  loading: state.auth.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withTranslation()(withRouter(Main));
