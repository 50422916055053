import React, { FunctionComponent, ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
}

type Props = OwnProps;

const ButtonContainer: FunctionComponent<Props> = ({ children }) => (
  <div className="option-buttons-container clearfix">{children}</div>
);

export default ButtonContainer;
