import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SemanticICONS, StrictButtonProps } from 'semantic-ui-react';
import TitleHeader from './TitleHeader';
import BackButton from '../buttons/BackButton';
import EssorButton from '../essorButton';
import { CheckAccessRights } from '../../types/store';

/**
 * Props for headers extending this component
 */
export interface ExtendProps extends CheckAccessRights, StrictButtonProps {
  label?: string;
  title: string;
  to?: string;
  onClick?: () => void;
  back?: string;
}

/**
 * Self props
 */
interface OwnProps extends ExtendProps {
  label: string;
  type: SemanticICONS;
}

type Props = OwnProps;

const ButtonHeader: FC<Props> = ({ title, label, back, children, ...others }: Props) => {
  if (others.to) {
    others.as = Link;
  }
  return (
    <TitleHeader title={title}>
      <EssorButton size="tiny" floated="right" {...others}>
        {label}
      </EssorButton>
      {children}
      {back && (<BackButton to={back} floated="right" />)}
    </TitleHeader>
  );
};

ButtonHeader.defaultProps = {
  disabled: false,
  label: '',
};

export default ButtonHeader;
