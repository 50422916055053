import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FloatCell from 'components/cell/FloatCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import GridWrapper from 'layouts/GridWrapper';
import HeaderRow from 'layouts/table/HeaderRow';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';
import Info from 'layouts/Info';
import TableLoader from 'components/TableLoader';

const HoursSynthesis = ({ hoursSynthesis, hoursToSell, loading = false }) => {
  const { t } = useTranslation();

  const emptyValue = {
    hours: 0,
    hourlyCost: 0,
    totalCost: 0,
  };

  let sellingHours = emptyValue;
  let workedHours = emptyValue;
  let total = emptyValue;

  if (hoursSynthesis) {
    sellingHours = hoursSynthesis.sellingHours || emptyValue;
    workedHours = hoursSynthesis.workedHours || emptyValue;
    total = hoursSynthesis.total || emptyValue;
  }

  return (
    <GridWrapper width={16}>
      <Info>{t('companiesHoursSynthesis')}</Info>

      <TableLoader status={loading} table celled className="margin-bot">
        <HeaderRow>
          <RightCell />
          <RightCell content={t('companiesHours')} />
          <RightCell content={t('companiesHourlyCost')} />
          <RightCell content={t('companiesTotalCost')} />
        </HeaderRow>

        <Table.Body>
          {hoursToSell !== false
            ? (
              <Fragment>
                <Table.Row>
                  <LeftCell content={t('companiesTheoreticalHours')} />
                  <FloatCell value={total.hours} />
                  <CurrencyCell value={total.hourlyCost} />
                  <CurrencyCell value={total.totalCost} />
                </Table.Row>
                <Table.Row>
                  <LeftCell content={t('companiesSellingHours')} />
                  <FloatCell value={sellingHours.hours} />
                  <CurrencyCell value={sellingHours.hourlyCost} />
                  <CurrencyCell value={sellingHours.totalCost} />
                </Table.Row>
              </Fragment>
            ) : (
              <Table.Row>
                <LeftCell content={t('companiesWorkingHours')} />
                <FloatCell value={workedHours.hours} />
                <CurrencyCell value={workedHours.hourlyCost} />
                <CurrencyCell value={workedHours.totalCost} />
              </Table.Row>
            )
          }
        </Table.Body>
      </TableLoader>
    </GridWrapper>
  );
};

export default HoursSynthesis;
