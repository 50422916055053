import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { findIndex, isEmpty } from 'lodash';
import { Checkbox, Dropdown, Table } from 'semantic-ui-react';
import TableListFormattedCell from 'components/TableListFormattedCell';
import ViewCellButton from 'components/buttons/ViewCellButton';
import { getHeaderItem, sortedDocuments } from 'components/documents/documentCommon';
import SortableHeader from 'components/sortableHeader';
import EditCellButton from 'components/buttons/EditCellButton';
import CheckRights from 'components/access/CheckRights';
import DeleteCellButton from 'components/buttons/DeleteCellButton';
import { crudRights, Entities } from 'types/accessRights';
import { useTranslation } from 'react-i18next';
import TableEmptyRow from 'components/TableEmptyRow';
import ListFilter from 'components/advancedList/ListFilter';
import StockCellButton from 'components/buttons/StockCellButton';
import Pagination from 'components/advancedList/Pagination';
import BusinessIconCell from 'routes/admin/business/businessIconCell';
import PrintCellButton from 'components/buttons/PrintCellButton';
import TableLoader from 'components/TableLoader';
import Flexbox from 'layouts/Flexbox';
import { DESC } from 'utils/constants';
import { ColDef, ColType } from 'utils/tables';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { IRI } from 'types/brand';
import { Entity } from 'types/entity';

// loading: PropTypes.bool.isRequired, // Boolean for loading data
//   columns: PropTypes.array.isRequired, // Object for table format and data showed
//   data: PropTypes.oneOfType([ // Data for table
//   PropTypes.array,
//   PropTypes.object,
// ]).isRequired,

type SortOrder = 'ascending' | 'descending';

interface OwnProps<T extends Entity> {
  entity: Entities;
  loading: boolean;
  onView?: (item: T) => void;
  onEdit?: (item: T) => void;
  onBusiness?: boolean;
  onDelete?: (item: T) => void;
  viewTooltip?: string;
  addStockProduct?: (iri: IRI) => void;
  columns: ColDef<T>[];
  data: T[];
  sortBy?: string;
  direction?: SortOrder;
  maxRow?: number;
}

function AdvancedList<T extends Entity>(props: OwnProps<T>): ReactElement {
  const { t } = useTranslation();
  const {
    entity,
    loading,
    onView,
    onEdit,
    onBusiness,
    onDelete,
    addStockProduct,
    viewTooltip,
    columns,
    data,
    sortBy = '',
    direction = DESC,
    maxRow,
  } = props;

  const [sortCol, setSortCol] = useState('');
  const [sortDir, setSortDir] = useState(DESC);
  const [filteredData, setFilteredData] = useState([] as T[]);
  const [columnDef, setColumns] = useState([] as ColDef<T>[]);
  const [slice, setSlice] = useState({
    start: 0,
    end: 1,
  });

  useEffect(() => {
    setSortCol(sortBy);
    setSortDir(direction);
    setFilteredData(data);
    setColumns(columns);
  }, [data]);

  const handleSort = (clickedColumn: string, nextDir: SortOrder): void => {
    setSortCol(clickedColumn);
    setSortDir(nextDir);
  };

  const selectDisplayOption = (e: FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
    const { value, checked = false } = data;
    const columnIndex = findIndex(columnDef, ['attribute', value]);

    columnDef[columnIndex].show = checked;
    setColumns([...columnDef]);
  };

  const handleSlice = (start: number, end: number): void => {
    setSlice({
      start,
      end,
    });
  };

  const { start, end } = slice;

  let colQuantity = 0;

  const colSpaces = {
    addStockProduct: 1,
    onBusiness: 2,
    onView: 1,
    onEdit: 1,
    onDelete: 1,
  };

  for (const [key, value] of Object.entries(colSpaces)) {
    // eslint-disable-next-line no-prototype-builtins
    colQuantity += props.hasOwnProperty(key) ? value : 0;
  }

  const visible = (columnDef: ColDef<T>[]): ColDef<T>[] => columnDef.filter(column => column.show);

  const cellAlign = (type: ColType): 'left' | 'right' => {
    switch (type) {
      case 'currency':
      case 'date':
      case 'percentage':
        return 'right';
      default:
        return 'left';
    }
  };

  const span = colQuantity + visible(columnDef).length;
  return (
    <>
      <Flexbox>
        <ListFilter
          originalData={data}
          setFilteredData={setFilteredData}
          visibleDef={visible(columnDef)}
        />
        <Pagination retrieved={filteredData || []} setSlice={handleSlice} max={maxRow} />
        <Dropdown
          multiple
          className="tiny-select selection"
          placeholder={t('buttonOptionSelect')}
          value={[]}
        >
          <Dropdown.Menu>
            {columnDef.map((column, index) => (
              <Dropdown.Item key={index}>
                <Checkbox
                  label={t([column.name])}
                  value={column.attribute}
                  onChange={selectDisplayOption}
                  checked={column.show}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Flexbox>

      <TableLoader
        status={loading && data.length < 1}
        className={classnames('table-container', {
          'is-loading': loading,
        })}
        table
        sortable
        celled
        striped
      >
        <SortableHeader
          column={sortCol}
          direction={sortDir}
          handler={handleSort}
          items={visible(columnDef).map(({ attribute, name, type }: ColDef<T>) => (
            getHeaderItem(attribute, t(name), cellAlign(type), type)
          ))}
          span={colQuantity}
        />

        <Table.Body>
          {sortedDocuments(filteredData, sortCol, sortDir).slice(start, end).map((item, i) => (
            <Table.Row key={i}>
              {visible(columnDef).map(({ attribute, type, handler, cellHandler }, index) => {
                if (attribute === 'purchaseUnitCost') {
                  return (
                    <TableListFormattedCell
                      key={index}
                      data={item[attribute]}
                      marginRate={item.marginRate}
                      type={type}
                      unitPrice
                    />
                  );
                }

                const keys = attribute.split('.');
                const colData = keys.length > 1 ? item[keys[0]][keys[1]] : item[attribute];

                if (cellHandler) {
                  return cellHandler(item);
                }

                return (
                  <TableListFormattedCell
                    key={index}
                    data={handler ? handler(item) : colData}
                    type={type}
                  />
                );
              })}
              {onView && (
                <CheckRights entity={entity} right={crudRights.read}>
                  <ViewCellButton
                    onClick={(): void => onView(item)}
                    tip={viewTooltip ? t(`${viewTooltip}`) : t('buttonDetails')}
                  />
                </CheckRights>
              )}
              {onEdit && (
                <CheckRights entity={entity} right={crudRights.update}>
                  <EditCellButton onClick={(): void => onEdit(item)} />
                </CheckRights>
              )}

              {onBusiness && (
                <>
                  <PrintCellButton url={`${item['@id']}/print`} document={item} />
                  <CheckRights entity={Entities.quote} right={crudRights.update}>
                    <BusinessIconCell document={item} />
                  </CheckRights>
                </>
              )}

              <CheckRights entity={Entities.stock} right={crudRights.create}>
                {addStockProduct && (
                  <StockCellButton product={item} onClick={addStockProduct} />
                )}
              </CheckRights>

              {onDelete && (
                <CheckRights entity={entity} right={crudRights.delete}>
                  <DeleteCellButton onClick={(): void => onDelete(item)} />
                </CheckRights>
              )}
            </Table.Row>
          ))
          }
          {loading && (
            <TableEmptyRow content={`${t('loading')}...`} span={span} />
          )}

          {isEmpty(filteredData) && (
            <TableEmptyRow content={t('documentEmptyList')} span={span} />
          )}
        </Table.Body>
      </TableLoader>
    </>
  );
}

export default AdvancedList;
