import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  messages: string[];
}

type Props = OwnProps;

const ErrorMessages: FunctionComponent<Props> = ({ messages = [] }) => {
  const { t } = useTranslation();
  return (
    <Message negative hidden={isEmpty(messages)}>
      <Message.Header>{t('documentMessageError')}</Message.Header>
      <Message.List>
        {(messages || []).map((error, i) => (
          <Message.Item key={i}>{t(`${error}`)}</Message.Item>
        ))}
      </Message.List>
    </Message>
  );
};

export default ErrorMessages;
