import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import fetch from '../../utils/fetch';
import API_ENTRYPOINT from '../../config/_entrypoint';
import { JsonError } from '../../types/responses';

const ChangePassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const handleSubmit = (): void => {
    setSuccess(false);
    setError('');

    if (isEmpty(password)) {
      setError(t('passwordEmptyError'));
    } else if (password.length < 8) {
      setError(t('passwordRequirementsError'));
    } else if (password !== confirm) {
      setError(t('passwordConfirmationError'));
    } else {
      setLoading(true);
      fetch(
        new URL('/changePassword', API_ENTRYPOINT).toString(),
        {
          method: 'POST',
          body: JSON.stringify({
            token,
            password,
          }),
        },
        (json: JsonError): void => {
          throw Error(json.error.exception[0].message);
        },
      ).then((response) => {
        if (response.ok) {
          return response;
        }

        return response
          .json()
          .then();
      }).then(() => {
        setSuccess(true);
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        setError(e.message);
      });
    }
  };

  return (
    <div className="login-form">
      <Grid textAlign="center" className="login-grid" verticalAlign="middle">
        <Grid.Column width={4}>
          <Form size="large">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('passwordPHPassword')}
              name="username"
              disabled={loading}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setPassword(e.target.value)}
              autoComplete="new-password"
              type="password"
            />
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('passwordPHConfirm')}
              name="username"
              disabled={loading}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setConfirm(e.target.value)}
              autoComplete="new-password"
              type="password"
            />

            <Button
              className="color-primary"
              color="teal"
              fluid
              size="large"
              loading={loading}
              onClick={handleSubmit}
            >
              {t('buttonSubmit')}
            </Button>
          </Form>

          <Message info content={t('passwordRules')} />
          <Message negative hidden={isEmpty(error)} content={error} />
          <Message positive hidden={!success} content={t('passwordChanged')} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ChangePassword;
