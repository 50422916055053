import React, { useContext } from 'react';
import { findIndex } from 'lodash';
import { Table } from 'semantic-ui-react';
import { showPrices } from 'components/documents/documentOptions';
import { useTranslation } from 'react-i18next';
import FloatInput from 'components/input/FloatInput';
import Float from 'components/float';
import Currency from 'components/currency';
import { DocumentContext } from 'components/documents/DocumentContainer';
import { isEnsemble, isProduct, isService } from 'components/documents/articles';
import { inputFormat } from 'utils/formatter';
import { Big } from 'utils/functions';
import useMarginCalculation from 'hooks/useMarginCalculation';
import ItemDiscount from 'components/documents/ItemDiscount';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';
import PercentageSelect from 'components/input/PercentageSelect';
import BodyLineControls from 'components/documents/BodyLineControls';
import DraftEditor from 'components/draftEditor/DraftEditor';

const ArticleBodyLine = (props) => {
  const { t } = useTranslation();
  const { handleDeleteItem, handleItemCheckBoxChange, setLabel } = props;
  const { item, documentType, editable, vatRates, loading } = props;
  const context = useContext(DocumentContext);
  const marginCalc = useMarginCalculation();
  const { selectedItemList } = context;

  const handleOnBlurEnsemblePrice = (e, article) => {
    const { value } = e.target;

    if (article.type !== 'Ensemble') {
      return;
    }

    const index = findIndex(selectedItemList, {
      order: article.order,
    });

    let minValue = 0;
    let productQuantity = 0;
    let totalValue = 0;

    for (let i = 0; i < article.items.length; i++) {
      totalValue += article.items[i].item.totalPrice;

      if (isProduct(article.items[i].item)) {
        productQuantity += 1;
      }
      if (isService(article.items[i].item)) {
        minValue += article.items[i].item.totalPrice;
      }
    }

    if (inputFormat(value) - minValue < 0) {
      article.price = minValue;
    }

    const toProduct = (totalValue - article.price) / productQuantity;

    for (let j = 0; j < article.items.length; j++) {
      if (isProduct(article.items[j].item)) {
        article.items[j].item.price -= toProduct / article.items[j].quantity;
        article.items[j].item.totalPrice = article.items[j].item.price * article.items[j].quantity;

        const bigCost = new Big(article.items[j].item.unitCost);
        const bigPrice = new Big(article.items[j].item.price);
        article.items[j].item.margin = marginCalc.getBigMargin(bigCost, bigPrice);
      }
    }

    selectedItemList[index] = article;

    context.updateContext('selectedItemList', selectedItemList);
  };

  return (
    <Table.Row>
      <BodyLineControls
        item={item}
        editable={editable}
        handleDeleteItem={handleDeleteItem}
        handleItemCheckBoxChange={handleItemCheckBoxChange}
        documentType={documentType}
      />

      <LeftCell content={item.reference} />

      <LeftCell>
        <DraftEditor
          editorLabel={item.label}
          editorOnlyShow={!editable}
          setLabel={editorContent => setLabel(item, editorContent)}
        />
      </LeftCell>

      {showPrices(documentType) && (
        <CenterCell collapsing>
          <FloatInput
            onChange={value => context.handleChangeItem(value, item, 'price')}
            onBlur={e => handleOnBlurEnsemblePrice(e, item)}
            onBlurOnInit={item}
            name="price"
            value={item.price}
            displayOnly={!editable}
          >
            <Currency value={item.price} />
          </FloatInput>
        </CenterCell>
      )}

      <Table.Cell collapsing textAlign="center">{item.unit || '-'}</Table.Cell>

      {showPrices(documentType) && (
        <CenterCell collapsing>
          {isEnsemble(item)
            ? '-'
            : (
              <PercentageSelect
                placeholder={t('formPHSelect')}
                size="small"
                fluid
                name="vatRate"
                loading={loading}
                disabled={loading}
                options={vatRates}
                noResultsMessage="No results"
                value={item.vatRate}
                onChange={(e, data) => context.handleChangeSelectItem(e, data, item)}
                displayOnly={!editable}
              />
            )}
        </CenterCell>
      )}
      <CenterCell collapsing>
        <FloatInput
          onChange={value => context.handleChangeItem(value, item, 'quantity')}
          name="quantity"
          value={item.quantity}
          displayOnly={!editable}
        >
          <Float value={item.quantity} />
        </FloatInput>
      </CenterCell>

      {showPrices(documentType) && <ItemDiscount item={item} editable={editable} />}
    </Table.Row>
  );
};

export default ArticleBodyLine;
