import React, { FC, ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { isEmployee } from '../../utils/roles';
import NotFound from './404';
import FullPageLoader from './FullPageLoader';
import { State } from '../../types/store';
import useCompany from '../../hooks/useCompany';
import useFiscalYear from '../../hooks/useFiscalYear';
import { getBaseRoutes, getCompanyProtectedRoutes, getExpertRoutes, getFYProtectedRoutes, RouteDefinition } from '../RoutesDefinition';
import CheckRoute from '../../components/access/CheckRoute';

interface PrivateRouteProps {
  isAuth: boolean;
  isExpired: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ isAuth, isExpired, ...rest }: PrivateRouteProps) => {
  const { loading, error } = useSelector((state: State) => state.auth);
  const { loadingID } = useSelector((state: State) => state.identity.update.retrieveLoading);
  const role = useSelector((state: State) => state.userCompanies.role.userRole);
  const userRights = useSelector((state: State) => state.userCompanies.role.userRights);
  const [hasCompany] = useCompany();
  const [hasFiscalYear] = useFiscalYear();

  const homeRedirect = role ? (!isEmployee(role) ? '/company/list' : '/company') : '/';

  if (isAuth && !isEmpty(userRights) && !isExpired && !error && !loading && !loadingID) {
    const routes: RouteDefinition[] = getBaseRoutes();

    if (hasCompany) {
      routes.push(...getCompanyProtectedRoutes());
    }
    if (hasFiscalYear) {
      routes.push(...getFYProtectedRoutes());
    }
    if (role && !isEmployee(role)) {
      routes.push(...getExpertRoutes());
    }

    return (
      <Switch>
        <Route
          exact
          path={['/', '/dashboard']}
          render={(): ReactElement => (<Redirect to={homeRedirect} />)}
        />
        {routes.map((route, index) => (
          <CheckRoute key={index} {...route} />
        ))}
        <Route component={NotFound} />
      </Switch>
    );
  }

  if (!isAuth && !loading) {
    return (
      <Route
        {...rest}
        render={(props): ReactElement => (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )}
      />
    );
  }
  return (
    <FullPageLoader />
  );
};

export default PrivateRoute;
