import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { withTranslation } from 'react-i18next';
import { customerDisplay, documentOrigin } from 'components/documents/documentCommon';
import { uniqueId } from 'components/documents/uniqueId';
import { Entities } from 'types/accessRights';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { getOtherStatus } from 'utils/documentStatus';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import DocumentLink from '../../../../../components/documents/DocumentLink';
import AdvancedList from '../../../../../components/advancedList/AdvancedList';
import { cellDefinition, columnDefinition, handlerDefinition } from '../../../../../utils/tables';

class HomeWorkOrder extends Component {
  state = {
    documents: null,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataWorkOrder)
      && nextProps.dataWorkOrder['hydra:member'] !== prevState.documents) {
      return {
        documents: nextProps.dataWorkOrder['hydra:member'],
      };
    }

    return null;
  }

  render() {
    const { documents } = this.state;
    const { t, loadingWorkOrder } = this.props;

    const document = document => <DocumentLink document={documentOrigin(document)} />;

    const statusCell = document => (
      <BusinessStatusCell document={document} handler={getOtherStatus} />
    );

    return (
      <ContainerGeneral>
        <TitleHeader title={t('workOrderTitle')} />

        <AdvancedList
          columns={[
            handlerDefinition('documentNumber', 'uniqueID', uniqueId),
            handlerDefinition('formCustomer', 'customer', customerDisplay),
            handlerDefinition('deliveryCreatedFrom', 'origin', document),
            columnDefinition('documentsHeaderCreationDate', 'creationDate', 'date'),
            cellDefinition('documentStatus', 'status', statusCell, '', getOtherStatus),
          ]}
          loading={loadingWorkOrder}
          data={documents || []}
          onBusiness
          entity={Entities.workOrder}
          sortBy="creationDate"
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: document => dispatch(selectDocument(document)),
});

const mapStateToProps = state => ({
  dataWorkOrder: state.workOrder.list.data,
  loadingWorkOrder: state.workOrder.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeWorkOrder);

export default withTranslation()(withRouter(Main));
