import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, retrieve, update } from 'actions/company-settings/update';
import { Form } from 'semantic-ui-react';
import { toast } from 'components';
import { withTranslation } from 'react-i18next';
import { isEmpty, last } from 'lodash';
import BackHeader from 'components/pageHeaders/BackHeader';
import PromptUnsavedData from 'components/PromptUnsavedData';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SettingsListAndModal from 'components/company-settings-edit/SettingsListAndModal';

class BankAccounts extends Component {
  state = {
    value: [],
    bankName: '',
    IBAN: '',
    BIC: '',
    bankNameError: false,
    IBANError: false,
    BICError: false,
    isSettingsLoaded: false,
    defaultBool: false,

    id: null,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=BANK_ACCOUNTS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.retrieved && nextProps.retrieved['hydra:member'][0].name === 'BANK_ACCOUNTS' && !prevState.isSettingsLoaded) {
      return {
        isSettingsLoaded: true,
        value: nextProps.retrieved['hydra:member'][0].value,
        id: last(nextProps.retrieved['hydra:member'][0].value) ? last(nextProps.retrieved['hydra:member'][0].value).id : 0,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckBoxChange = (e) => {
    e.preventDefault();

    this.setState(prevState => (
      {
        defaultBool: !prevState.defaultBool,
      }
    ));
  };

  handleAddItem = () => {
    const {
      value,
      bankName,
      IBAN,
      BIC,
      id,
    } = this.state;
    let { defaultBool } = this.state;

    this.setState({
      bankNameError: false,
      IBANError: false,
      BICError: false,
    });

    let isValid = true;

    if (bankName.trim() === '') {
      isValid = false;

      this.setState({
        bankNameError: true,
      });
    }

    if (IBAN.trim() === '') {
      isValid = false;

      this.setState({
        IBANError: true,
      });
    }

    if (BIC.trim() === '') {
      isValid = false;

      this.setState({
        BICError: true,
      });
    }

    if (!isValid) return;

    if (isEmpty(value)) {
      defaultBool = true;
    }

    if (defaultBool) {
      value.forEach((item) => {
        item.default = false;
      });
    }

    const data = {
      id: id + 1,
      bankName,
      IBAN,
      BIC,
      default: defaultBool,
    };

    value.push(data);

    this.setState({
      value,
      bankName: '',
      IBAN: '',
      BIC: '',
      defaultBool: false,
      id: data.id,
      hasDataChanged: true,
    });
  };

  handleDelete = (e) => {
    const { value } = this.state;
    const index = e.target.getAttribute('data-id');

    value.splice(index, 1);

    this.setState({
      value,
    });
  };

  handleOnSubmit = () => {
    const { value } = this.state;

    const { update, retrieved, history, t } = this.props;

    const data = {
      value,
    };

    this.setState({
      hasDataChanged: false,
    });

    update(retrieved['hydra:member'][0], data)
      .then(() => {
        toast.success(t('bankAccountUpdateSuccess'));
        history.push('/company/settings/bank-accounts');
      });

    this.setState({
      hasDataChanged: false,
    });
  };

  onSubmitList = (item, hasDataChanged) => {
    this.setState({
      value: item,
      hasDataChanged,
    });
  };

  render() {
    const {
      bankName,
      IBAN,
      BIC,
      bankNameError,
      IBANError,
      BICError,
      value,
      defaultBool,
      hasDataChanged,
    } = this.state;

    const { retrieveLoading, updateLoading, t, updateError } = this.props;

    return (
      <ContainerGeneral>
        <PromptUnsavedData hasDataChanged={hasDataChanged} />
        <BackHeader title={t('companiesBankAccounts')} to="/company/settings/bank-accounts" />
        <SettingsListAndModal
          dataValue={value}
          onEditList={(valueEdit, changed) => this.onSubmitList(valueEdit, changed)}
          loading={retrieveLoading || updateLoading}
          inputsNumeric={[]}
          width={14}
          onSave={this.handleOnSubmit}
          onAdd={this.handleAddItem}
          error={!updateError}
        >
          <Form.Group inline>
            <Form.Input
              label={t('formBankName')}
              name="bankName"
              placeholder={t('formPHBankName')}
              value={bankName}
              onChange={this.handleInputChange}
              error={bankNameError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formIBAN')}
              name="IBAN"
              placeholder={t('formPHIBAN')}
              value={IBAN}
              onChange={this.handleInputChange}
              error={IBANError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formBIC')}
              name="BIC"
              placeholder={t('formPHBIC')}
              value={BIC}
              onChange={this.handleInputChange}
              error={BICError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Checkbox
              label={t('formDefault')}
              name="defaultBool"
              checked={defaultBool}
              onChange={this.handleCheckBoxChange}
            />
          </Form.Group>
        </SettingsListAndModal>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  retrieveError: state.companySettings.update.retrieveError,
  retrieveLoading: state.companySettings.update.retrieveLoading,
  updateError: state.companySettings.update.updateError,
  updateLoading: state.companySettings.update.updateLoading,
  retrieved: state.companySettings.update.retrieved,
  updated: state.companySettings.update.updated,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(BankAccounts);

export default withTranslation()(withRouter(Main));
