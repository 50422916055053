const SET_MAIN_NAV_VISIBILITY = 'LAYOUT/SET_MAIN_NAV_VISIBILITY';
const SET_SIDEBAR_VISIBILITY = 'LAYOUT/SET_SIDEBAR_VISIBILITY';

export const setMainNavVisibility = visibility => ({
  type: SET_MAIN_NAV_VISIBILITY,
  visibility,
});

export const toggleMainNav = () => (dispatch, getState) => {
  const visibility = getState().layout.mainNavIsOpen;
  dispatch(setMainNavVisibility(!visibility));
};

export const setSidebarVisibility = visibility => ({
  type: SET_SIDEBAR_VISIBILITY,
  visibility,
});

export const toggleSidebar = () => (dispatch, getState) => {
  const visibility = getState().layoutRight.sidebarIsVisible;
  dispatch(setSidebarVisibility(!visibility));
};
