import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setDeliveryList } from 'actions/delivery/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { change as changeDelivery, reset as resetChangeDelivery } from 'actions/delivery/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { reset as resetUpdateDelivery, retrieve as retrieveDelivery, retrieveSuccess as retrieveDeliverySuccess } from 'actions/delivery/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { selectDocument } from 'actions/user-companies/select';
import Routes from 'routes/admin/business/routes';
import { Document } from 'components';
import { STATUS_PENDING } from 'utils/constants';
import BackHeader from 'components/pageHeaders/BackHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import GridWrapper from 'layouts/GridWrapper';
import SaveButton from 'components/buttons/SaveButton';
import ActionButtons from 'components/documents/actionButtons';
import NotFound from 'routes/admin/404';
import { DELIVERY_FORM } from 'utils/documentType';

class ShowDeliveryForm extends Component {
  state = {
    isValid: true,
    status: null,
    deliveryId: null,
    documentData: {},
    customerList: null,
    selectedCustomer: null,
    isCreate: false,
    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getDeliveryForm,
      getCustomerList,
      selectedCompany,
      getArticles,
      listDelivery,
      match,
    } = this.props;

    if (match.params.id) {
      if (find(listDelivery['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getDeliveryForm(`/delivery_forms/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });

        return;
      }
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedDelivery)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedDelivery.id !== prevState.deliveryId
        || nextProps.retrievedDelivery.status !== prevState.status)) {
      const documentData = {
        documentId: nextProps.retrievedDelivery.status >= STATUS_PENDING
          ? nextProps.retrievedDelivery.uniqueID
          : '-',
        content: nextProps.retrievedDelivery.content,
        reference: nextProps.retrievedDelivery.reference,
        creationDate: moment(nextProps.retrievedDelivery.creationDate),
        responseDate: moment(nextProps.retrievedDelivery.responseDate),
        invoice: nextProps.retrievedDelivery.invoice,
      };

      return {
        documentData,
        deliveryId: nextProps.retrievedDelivery.id,
        status: nextProps.retrievedDelivery.status,
        selectedCustomer: nextProps.retrievedDelivery.customer['@id'],
        comment: nextProps.retrievedDelivery.comment || '',
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedDelivery.timeSpent,
        loadedPurchase: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedDelivery,
      changedDelivery,
      listDelivery,
      setDelivery,
      selectDocument,
      setDeliveryList,
    } = this.props;

    if (!isEmpty(retrievedDelivery)
      && retrievedDelivery !== prevProps.retrievedDelivery) {
      selectDocument(retrievedDelivery);
    }

    if (isEmpty(retrievedDelivery)
      && retrievedDelivery !== prevProps.retrievedDelivery) {
      selectDocument(null);
    }

    if (!isEmpty(changedDelivery)
      && changedDelivery['@id'] === selectedDocument['@id']
      && changedDelivery.status >= STATUS_PENDING) {
      selectDocument(changedDelivery);
    }

    if (!isEmpty(changedDelivery)
      && changedDelivery !== prevProps.changedCreditNote
      && changedDelivery['@id'] === selectedDocument['@id']) {
      const index = findIndex(listDelivery['hydra:member'], {
        id: changedDelivery.id,
      });

      setDelivery(changedDelivery);
      listDelivery['hydra:member'][index] = changedDelivery;
      setDeliveryList(listDelivery);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  onSubmit = () => {
    this.setState({
      validDocument: false,
    });

    const { changeDelivery, retrievedDelivery } = this.props;
    changeDelivery(`/delivery_forms/${retrievedDelivery.id}/to_pending`, 'PUT');
  };

  handleOnSubmit = () => {
    this.setState({
      validDocument: true,
    });
  };

  handlePrintDocument = () => {
    const { printDocument, selectedDocument } = this.props;
    printDocument(`/delivery_forms/${selectedDocument.id}/print`);
  };

  render() {
    const {
      isValid,
      status,
      documentData,

      customerList,
      selectedCustomer,

      validDocument,
      validContent,

      hasDataChanged,
    } = this.state;

    const {
      loadingPrintDocument,
      loadingChangeDelivery,
      t,
    } = this.props;

    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (!isValid) {
      return <NotFound />;
    }

    if (loadingChangeDelivery) {
      return <ContainerGeneral status={loadingChangeDelivery} />;
    }

    return (
      <ContainerGeneral>
        <BackHeader title={t('showDeliveryTitle')} to={Routes.listDeliveryForms} />
        {status && (
          <ActionButtons
            loadingPrintDocument={loadingPrintDocument}
            handleAction={this.handlePrintDocument}
          />
        )}

        <hr />

        {customerObject && (
          <React.Fragment>
            <Document
              type={DELIVERY_FORM}
              status={STATUS_PENDING}
              documentData={documentData}
              customer={customerObject}
              validData={validDocument}
              validContent={validContent}
              getData={data => this.onSubmit(data)}
              hasDataChanged={hasDataChanged}
            />

            {status < STATUS_PENDING && (
              <GridWrapper width={16}>
                <SaveButton
                  disabled={loadingChangeDelivery}
                  loading={loadingChangeDelivery}
                  floated="right"
                  onClick={() => this.handleOnSubmit('validate')}
                  label={t('buttonSaveValidate')}
                />
              </GridWrapper>
            )}
          </React.Fragment>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDelivery: delivery => dispatch(retrieveDeliverySuccess(delivery)),
  getDeliveryForm: page => dispatch(retrieveDelivery(page)),
  getCustomerList: page => dispatch(listCustomer(page)),
  getArticles: page => dispatch(listArticle(page)),
  changeDelivery: (route, method) => dispatch(changeDelivery(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setDeliveryList: creditNotes => dispatch(setDeliveryList(creditNotes)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset() {
    dispatch(resetListCustomer());
    dispatch(resetArticle());
    dispatch(resetChangeDelivery());
    dispatch(resetUpdateDelivery());
  },
});
const mapStateToProps = state => ({
  listDelivery: state.delivery.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,

  retrievedDelivery: state.delivery.update.retrieved,
  loadingRetrieveDelivery: state.delivery.update.retrieveLoading,
  errorRetrieveDelivery: state.delivery.update.retrieveError,

  changedDelivery: state.delivery.change.changed,
  loadingChangeDelivery: state.delivery.change.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowDeliveryForm);

export default withTranslation()(withRouter(Main));
