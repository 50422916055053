import React from 'react';
import { Dropdown, Input, Label } from 'semantic-ui-react';
import { discountOptions } from 'components/documents/documentOptions';
import { getFloatCleaveFormat } from 'utils/formatter';
import CleaveInit from 'components/input/CleaveInit';
import Discount from 'components/documents/Discount';

function DiscountInput(props) {
  const { type, typeHandler, typeName, displayOnly, value } = props;

  if (displayOnly) {
    return <Discount value={value} type={type} />;
  }

  return (
    <Input size="small" fluid labelPosition="right">
      <CleaveInit options={getFloatCleaveFormat()} {...props} />
      <Label className="no-padding">
        <Dropdown
          options={discountOptions}
          value={type}
          name={typeName || 'discountType'}
          onChange={typeHandler}
          className="p-11"
        />
      </Label>
    </Input>
  );
}

export default DiscountInput;
