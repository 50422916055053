import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { findIndex, isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listWorkOrder, reset as resetWorkOrderList, success as setWorkOrderList } from 'actions/work-order/list';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import { Entities } from 'types/accessRights';
import EditWorkOrders from './edit';
import ListWorkOrder from './list';

class WorkOrders extends Component {
  componentDidMount() {
    const { getWorkOrders, selectedCompany, selectDocument } = this.props;

    selectDocument(null);
    getWorkOrders(`/work_orders?company=${selectedCompany.id}&pagination=true`);
  }

  componentDidUpdate(prevProps) {
    const {
      updatedWorkOrder,
      listWorkOrder,
      setWorkOrderList,
    } = this.props;

    if (!isEmpty(updatedWorkOrder) && updatedWorkOrder !== prevProps.updatedWorkOrder) {
      const index = findIndex(listWorkOrder['hydra:member'], {
        '@id': updatedWorkOrder['@id'],
      });

      listWorkOrder['hydra:member'][index] = updatedWorkOrder;
      setWorkOrderList(listWorkOrder);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.workOrder}
          path="/business/work_orders"
          component={ListWorkOrder}
        />
        <CheckRoute
          exact
          entity={Entities.workOrder}
          path="/business/work_orders/:id/edit"
          component={EditWorkOrders}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getWorkOrders: page => dispatch(listWorkOrder(page)),
  setWorkOrderList: quotes => dispatch(setWorkOrderList(quotes)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset() {
    dispatch(resetWorkOrderList());
  },
});

const mapStateToProps = state => ({
  listWorkOrder: state.workOrder.list.data,
  loadingWorkOrder: state.workOrder.list.loading,
  errorWorkOrder: state.workOrder.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  updatedWorkOrder: state.workOrder.update.updated,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrders);
