import React from 'react';
import { Header, Segment, Dimmer, Loader, Form, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { list as getListRole, reset as resetListRole } from 'actions/role/list';
import { update as updateIdentity, reset as resetUpdateIdentity } from 'actions/identity/update';
import { selectEmployee } from 'actions/user-companies/select';
import { RoleEdit, EssorButton } from 'components';
import classnames from 'classnames';

class EditProfileRole extends React.Component {
  state = {
    listRole: null,
    selectedRole: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listRole) && nextProps.listRole['hydra:member'] !== prevState.listRole) {
      return {
        listRole: nextProps.listRole['hydra:member'],
      };
    }
    return null;
  }

  componentDidMount() {
    const { getListRole, selectedCompany } = this.props;
    getListRole(`/roles?company=${selectedCompany['@id']}`);
  }

  componentDidUpdate(prevProps) {
    const { updatedIdentity, selectedEmployee, selectEmployee } = this.props;

    if (!isEmpty(updatedIdentity) && updatedIdentity !== prevProps.updatedIdentity) {
      selectEmployee({
        ...selectedEmployee,
        identity: {
          ...updatedIdentity,
        },
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleSelectChange = (e, { value, name }) => {
    e.preventDefault();

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (rights) => {
    const { updateEmployeeIdentity, selectedEmployee } = this.props;
    const data = {
      ...selectedEmployee.identity,
      rights: {
        ...rights,
      },
    };

    updateEmployeeIdentity(selectedEmployee.identity, data);
  };

  handleAddRoles = () => {
    const { selectedRole } = this.state;
    const role = JSON.parse(selectedRole);

    this.setState({
      mergedRole: role,
    }, this.clearSelectRole);
  };

  clearSelectRole = () => {
    this.setState({
      selectedRole: null,
    });
  };

  render() {
    const {
      selectedEmployee,
      t,
      loadingIdentity,
      updatedIdentity,
      match,
      loadingListRole,
    } = this.props;

    const { listRole, selectedRole, mergedRole } = this.state;

    let optionsRole = [];

    if (!isEmpty(listRole)) {
      optionsRole = listRole.map((role, i) => ({
        key: i,
        text: role.name,
        value: JSON.stringify(role.rights),
      }));
    }

    if (updatedIdentity) {
      return (
        <Redirect to={`/employees/${match.params.id}/roles`} />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('entityEditProfile')}</Header>
            <EssorButton as={Link} to={`/employees/${match.params.id}/roles`} type="chevron left" size="tiny" floated="right">
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Segment
            basic
            className={classnames('table-container', {
              'is-loading': loadingIdentity,
            })}
          >
            <Dimmer active={loadingIdentity} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            <div className="option-buttons-container clearfix">
              <Form>
                <Form.Group inline>
                  <Form.Select
                    label={t('formRole')}
                    placeholder={t('formPHSelect')}
                    control={Dropdown}
                    name="selectedRole"
                    selection
                    clearable
                    loading={loadingListRole}
                    options={optionsRole}
                    onChange={this.handleSelectChange}
                    value={selectedRole}
                    selectOnBlur={false}
                  />
                  <EssorButton
                    type="plus"
                    size="tiny"
                    floated="right"
                    disabled={!selectedRole}
                    onClick={this.handleAddRoles}
                  >
                    {t('buttonAdd')}
                  </EssorButton>
                </Form.Group>
              </Form>
            </div>
            <RoleEdit
              rights={selectedEmployee.identity.rights}
              mergeRole={mergedRole}
              getRights={rights => this.handleSubmit(rights)}
            />
          </Segment>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateEmployeeIdentity: (items, value) => dispatch(updateIdentity(items, value)),
  getListRole: api => dispatch(getListRole(api)),
  selectEmployee: employee => dispatch(selectEmployee(employee)),
  reset() {
    dispatch(resetUpdateIdentity());
    dispatch(resetListRole());
  },
});

const mapStateToProps = state => ({
  selectedEmployee: state.userCompanies.select.selectedEmployee,

  updatedIdentity: state.identity.update.updated,
  loadingIdentity: state.identity.update.updateLoading,

  listRole: state.role.list.data,
  loadingListRole: state.role.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditProfileRole);
export default withTranslation()(withRouter(Main));
