import React, { Component, ReactNode, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { DraftEditorCommand, DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { keyVal } from '../../utils/functions';

interface Props {
  editorLabel: string;
  setLabel: (editorContent: string) => void;
  editorOnlyShow?: boolean;
  textArea?: boolean;
}

class DraftEditor extends Component<Props> {
  state = {
    editorState: EditorState.createEmpty(),
  };

  editor = React.createRef();

  componentDidMount(): void {
    const { editorLabel, setLabel } = this.props;

    this.setState({
      editorState: EditorState.createWithContent(stateFromHTML(editorLabel)),
    }, () => {
      setLabel(stateToHTML(stateFromHTML(editorLabel)));
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { editorLabel } = this.props;
    const { editorState } = this.state;

    if (editorLabel !== prevProps.editorLabel
      && editorLabel.indexOf('<p>') !== -1
      && stateToHTML(editorState.getCurrentContent()) !== editorLabel) {
      this.updateLabel();
    }
  }

  updateLabel = (): void => {
    const { editorLabel } = this.props;
    this.setState({
      editorState: EditorState.createWithContent(stateFromHTML(editorLabel)),
    });
  };

  onChangeEditor = (editorState: EditorState): void => {
    const { setLabel } = this.props;
    this.setState({
      editorState,
    }, () => {
      setLabel(stateToHTML(editorState.getCurrentContent()));
    });
  };

  handleKeyCommand = (command: DraftEditorCommand): DraftHandleValue => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChangeEditor(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  onTab = (e: any): void => {
    const { editorState } = this.state;
    const maxDepth = 4;
    this.onChangeEditor(RichUtils.onTab(e, editorState, maxDepth));
  };

  onToggleStyle = (e: SyntheticEvent, style: string): void => {
    e.preventDefault();
    const { editorState } = this.state;

    this.onChangeEditor(RichUtils.toggleInlineStyle(editorState, style));
  };

  onFocus = (): void => {
    // this.editor.current.focus();
  };

  render(): ReactNode {
    const { editorState } = this.state;
    const { editorOnlyShow, textArea } = this.props;
    const currentStyle = editorState.getCurrentInlineStyle();

    const getClassName = (style: string): string => (
      classnames('editor-button', keyVal('active', currentStyle.has(style)))
    );

    return (
      <div className={classnames('editor-container', keyVal('textarea', textArea))}>
        {editorOnlyShow
          ? (
            <Editor
              editorState={editorState}
              readOnly
              onChange={(): void => undefined}
            />
          )
          : (
            <React.Fragment>
              <div className="editor-controls">
                <span
                  className={getClassName('UNDERLINE')}
                  onMouseDown={(e: SyntheticEvent): void => this.onToggleStyle(e, 'UNDERLINE')}
                >
                  <Icon name="underline" fitted size="small" />
                </span>
                <span
                  className={getClassName('BOLD')}
                  onMouseDown={(e: SyntheticEvent): void => this.onToggleStyle(e, 'BOLD')}
                >
                  <Icon name="bold" fitted size="small" />
                </span>
                <span
                  className={getClassName('ITALIC')}
                  onMouseDown={(e: SyntheticEvent): void => this.onToggleStyle(e, 'ITALIC')}
                >
                  <Icon name="italic" fitted size="small" />
                </span>
              </div>
              <div className="editors" onClick={this.onFocus}>
                <Editor
                  editorState={editorState}
                  onChange={(editorState): void => this.onChangeEditor(editorState)}
                  handleKeyCommand={this.handleKeyCommand}
                  onTab={this.onTab}
                />
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}

export default DraftEditor;
