import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { del as deleteAbsence, error as errorDelete } from 'actions/absence/delete';
import { list as listAbsence, reset as resetAbsence } from 'actions/absence/list';
import { create as createAbsence, error as errorCreateAbsence, loading as loadingCreateAbsence, success as successCreateAbsence } from 'actions/absence/create';
import { list as listEmployee, reset as resetEmployee } from 'actions/employee/list';
import { Dimmer, Dropdown, Form, Input, Label, Loader, Modal, Segment, Table } from 'semantic-ui-react';
import { DeleteConfirmation } from 'components';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';
import { floatFormat, inputFormat, percentageFormat } from 'utils/formatter';
import 'moment/locale/fr';
import AddHeader from 'components/pageHeaders/AddHeader';
import CancelButton from 'components/buttons/CancelButton';
import SaveButton from 'components/buttons/SaveButton';
import CheckRights from 'components/access/CheckRights';
import ViewCellButton from 'components/buttons/ViewCellButton';
import FloatCell from 'components/cell/FloatCell';
import { crudRights, Entities } from 'types/accessRights';

moment.locale('fr');

class FiscalYear extends Component {
  state = {
    dayOffReasons: [],
    employeeList: null,
    absenceList: null,
    openModal: false,
    modalDetail: null,

    employee: null,
    dateStart: moment(),
    dateBack: null,
    reason: null,
    rate: '',
    // baseAllowance: '',
    // actualAllowance: '',
    // periodCost: '',
    // originalRate: '',

    employeeError: false,
    dateStartError: false,
    dateBackError: false,
    reasonError: false,
    rateError: false,

    warningMessage: false,
  };

  componentDidMount() {
    const {
      getAbsences,
      getEmployees,
      selectedCompany,
      selectedFiscalYear,
    } = this.props;

    getAbsences(`/absences?company=${selectedCompany.id}&fiscalYear=${selectedFiscalYear.id}`);
    getEmployees(`/employees?company=${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataAbsence) && nextProps.dataAbsence['hydra:member'] !== prevState.firstList) {
      return {
        absenceList: nextProps.dataAbsence['hydra:member'],
        firstList: nextProps.dataAbsence['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.dataEmployee) && nextProps.dataEmployee['hydra:member'] !== prevState.employeeList) {
      return {
        employeeList: nextProps.dataEmployee['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listCompanySettings)
      && find(nextProps.listCompanySettings['hydra:member'], {
        name: 'DAY_OFF_REASONS',
      })
      && find(nextProps.listCompanySettings['hydra:member'], {
        name: 'DAY_OFF_REASONS',
      }).value !== prevState.dayOffReasons) {
      const reasons = find(nextProps.listCompanySettings['hydra:member'], {
        name: 'DAY_OFF_REASONS',
      });

      return {
        dayOffReasons: reasons.value,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      createdAbsence,
      resetCreateAbsence,
      deletedAbsence,
      errorDelete,
      resetError,
    } = this.props;

    if (!isEmpty(createdAbsence) && createdAbsence !== prevProps.createdAbsence) {
      resetCreateAbsence();
      this.closeModal();
      this.updateAbsenceList(createdAbsence);
    }

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.dismissWarning();
      resetError();
    }

    if (!isEmpty(deletedAbsence)
      && deletedAbsence !== prevProps.deletedAbsence) {
      this.deleteFromList();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  deleteFromList = () => {
    const {
      deletedAbsence,
      absenceList,
    } = this.props;
    const newArray = absenceList.filter(item => item['@id'] !== deletedAbsence['@id']);

    this.setState({
      absenceList: newArray,
    });

    this.dismissWarning();
  };

  updateAbsenceList = (absence) => {
    const { absenceList } = this.state;

    const array = absenceList.concat(absence);

    this.setState({
      absenceList: array,
    });
  };

  handleDelete = () => {
    const { toDelete } = this.state;
    const { deleteAbsence } = this.props;
    deleteAbsence(toDelete);
  };

  showDeleteWarning = (toDelete) => {
    this.setState({
      toDelete,
      warningMessage: true,
    });
  };

  dismissWarning = () => {
    this.setState({
      toDelete: null,
      warningMessage: false,
    });
  };

  openModal = () => {
    this.setState({
      openModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
      modalDetail: null,
      employee: null,
      dateStart: moment(),
      dateBack: null,
      reason: null,
      rate: '',
    });
  };

  openDetailModal = (absence) => {
    const { dayOffReasons } = this.state;
    const { selectedFiscalYear } = this.props;
    let fiscalYearDays;
    let totalCost = 0;
    let hoursToSell = false;

    const reason = find(dayOffReasons, {
      id: absence.type,
    });

    if (!isEmpty(selectedFiscalYear.forecast)) {
      fiscalYearDays = selectedFiscalYear.forecast.realised.daysInTheFiscalYear;
    } else {
      fiscalYearDays = moment(selectedFiscalYear.dateEnd)
        .diff(selectedFiscalYear.dateStart, 'days');
    }

    if (!isEmpty(absence.employee.data)) {
      const data = find(absence.employee.data, {
        fiscalYear: selectedFiscalYear['@id'],
      });

      ({ hoursToSell } = data);

      if (!isEmpty(data.hoursSynthesis)) {
        ({ totalCost } = data.hoursSynthesis.total);
      }
    }

    const baseAllowance = (totalCost / fiscalYearDays) * reason.rate * absence.duration;
    const actualAllowance = (totalCost / fiscalYearDays) * absence.rate * absence.duration;
    const periodCost = totalCost - actualAllowance;
    const modalDetail = {
      ...absence,
      baseAllowance,
      actualAllowance,
      periodCost,
      reason,
      hoursToSell,
    };

    this.setState({
      openModal: true,
      modalDetail,
    });
  };

  handleInputChange = (e, format = false) => {
    const {
      name,
      value,
    } = e.target;

    this.setState({
      [name]: format ? inputFormat(value) : value,
    }, this.updateFields);
  };

  updateFields = () => {
    const {
      employee,
      rate,
      // originalRate,
      dateStart,
      dateBack,
      // employeeList,
    } = this.state;
    // const { selectedFiscalYear } = this.props;

    if (isEmpty(employee) || isEmpty(rate) || isEmpty(dateStart) || isEmpty(dateBack)) {
      // return;
    }

    /*
    let fiscalYearDays;
    let totalCost = 0;

    const duration = dateBack.diff(dateStart, 'days') + 1;

    if (!isEmpty(selectedFiscalYear.forecast)) {
      fiscalYearDays = selectedFiscalYear.forecast.realised.daysInTheFiscalYear;
    } else {
      fiscalYearDays = moment(selectedFiscalYear.dateEnd)
        .diff(selectedFiscalYear.dateStart, 'days');
    }

    const selectedEmployee = find(employeeList, {
      '@id': employee,
    });

    if (!isEmpty(selectedEmployee.data)) {
      const data = find(selectedEmployee.data, {
        fiscalYear: selectedFiscalYear['@id'],
      });

      if (!isEmpty(data.hoursSynthesis)) {
        ({ totalCost } = data.hoursSynthesis.total);
      }
    }
    */

    // const baseAllowance = (totalCost / fiscalYearDays) * originalRate * duration;
    // const actualAllowance = (totalCost / fiscalYearDays) * rate * duration;
    // const periodCost = totalCost - actualAllowance;

    // this.setState({
    // baseAllowance,
    // actualAllowance,
    // periodCost,
    // });
  };

  handleSelectChange = (e, {
    value,
    name,
  }) => {
    this.setState({
      [name]: value,
    }, this.updateFields);
  };

  handleReasonChange = (e, { value }) => {
    const obj = JSON.parse(value);

    this.setState({
      reason: value,
      rate: obj.rate,
      // originalRate: obj.rate,
    }, this.updateFields);
  };

  handleDateChange = (date, name) => {
    this.setState({
      [name]: date,
    }, this.updateFields);
  };

  handleOnSubmit = () => {
    const {
      employee,
      dateStart,
      dateBack,
      reason,
      rate,
    } = this.state;

    const {
      postAbsence,
      selectedCompany,
      selectedFiscalYear,
    } = this.props;
    let isValid = true;

    this.setState({
      employeeError: false,
      dateStartError: false,
      dateBackError: false,
      reasonError: false,
      rateError: false,
    });

    if (!employee) {
      isValid = false;
      this.setState({
        employeeError: true,
      });
    }

    if (!dateStart) {
      isValid = false;
      this.setState({
        dateStartError: true,
      });
    }

    if (!dateBack || dateBack.isSameOrBefore(dateStart, 'days')) {
      isValid = false;
      this.setState({
        dateBackError: true,
      });
    }

    if (!reason) {
      isValid = false;
      this.setState({
        reasonError: true,
      });
    }

    if (rate === '' || parseFloat(rate) > 100) {
      isValid = false;
      this.setState({
        rateError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const obj = JSON.parse(reason);
    const duration = (dateBack.diff(dateStart, 'days') + 1).toString();

    const data = {
      employee,
      dateStart: dateStart.format('YYYY-MM-DD'),
      dateBack: dateBack.format('YYYY-MM-DD'),
      type: obj.id,
      rate,
      duration,
      fiscalYear: selectedFiscalYear['@id'],
      company: selectedCompany['@id'],
    };

    postAbsence(data);
  };

  render() {
    const {
      dayOffReasons,
      employeeList,
      absenceList,
      openModal,
      modalDetail,

      employee,
      dateStart,
      dateBack,
      reason,
      rate,
      // baseAllowance,
      // actualAllowance,
      // periodCost,

      employeeError,
      dateStartError,
      dateBackError,
      reasonError,
      rateError,

      warningMessage,
      toDelete,
    } = this.state;

    const {
      loadingEmployee,
      loadingAbsence,
      loadingCreateAbsence,
      loadingCompanySettings,
      loadingDelete,
      t,
    } = this.props;

    let employeeOptions = [];
    let reasonsOptions = [];

    if (!isEmpty(employeeList)) {
      employeeOptions = employeeList.map(employee => ({
        key: employee['@id'],
        text: `${employee.lastName} ${employee.firstName}`,
        value: employee['@id'],
      }));
    }

    if (!isEmpty(dayOffReasons)) {
      reasonsOptions = dayOffReasons.map(reason => ({
        key: reason.id,
        text: reason.label,
        value: JSON.stringify(reason),
      }));
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <AddHeader
            title={t('employeeLeavesManagement')}
            onClick={this.openModal}
            entity={Entities.absence}
          />

          <Segment
            basic
            className={classnames('table-container', {
              'is-loading': loadingAbsence,
            })}
          >
            <Dimmer active={loadingAbsence} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('formJobTitle')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('absencePeriod')}</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">{t('absenceDurationDays')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('formReason')}</Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {absenceList && absenceList.map((absence, index) => {
                  const reason = find(dayOffReasons, {
                    id: absence.type,
                  });

                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {`${absence.employee.lastName} ${absence.employee.firstName}`}
                      </Table.Cell>
                      <Table.Cell>
                        {absence.employee.details.jobTitle}
                      </Table.Cell>
                      <Table.Cell>
                        {`${moment(absence.dateStart).format('DD/MM/YYYY')} -
                        ${moment(absence.dateBack).format('DD/MM/YYYY')}`}
                      </Table.Cell>
                      <FloatCell value={absence.duration} />

                      <Table.Cell>
                        {reason ? reason.label : t('loading')}
                      </Table.Cell>

                      <CheckRights entity={Entities.absence} right={crudRights.read}>
                        <ViewCellButton onClick={() => this.openDetailModal(absence)} />
                      </CheckRights>

                      {/* <CheckRights entity={Entities.absence} right={crudRights.delete}>
                        <DeleteCellButton onClick={() => this.showDeleteWarning(absence)} />
                      </CheckRights> */}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        </div>

        <Modal
          open={openModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={this.closeModal}
          className="full-content"
        >
          <Modal.Header>{modalDetail ? t('absenceDetail') : t('absenceAdd')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {modalDetail
                ? (
                  <Form className="margin-top-bot main-form" size="small">
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formFullName')}</label>
                        <h5 className="informative-field">
                          {modalDetail && `${modalDetail.employee.lastName} ${modalDetail.employee.firstName}`}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formJobTitle')}</label>
                        <h5 className="informative-field">
                          {modalDetail && modalDetail.employee.details.jobTitle}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formHoursToSell')}</label>
                        <h5 className="informative-field">
                          {modalDetail && modalDetail.hoursToSell ? 'Yes' : 'No'}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formDateStart')}</label>
                        <h5 className="informative-field">
                          {modalDetail && moment(modalDetail.dateStart).format('DD/MM/YYYY')}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formDateBack')}</label>
                        <h5 className="informative-field">
                          {modalDetail && moment(modalDetail.dateBack).format('DD/MM/YYYY')}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absenceDurationDays')}</label>
                        <h5 className="informative-field">
                          {modalDetail && floatFormat(modalDetail.duration)}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formReason')}</label>
                        <h5 className="informative-field">
                          {modalDetail && modalDetail.reason.label}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absenceReasonRate')}</label>
                        <h5 className="informative-field">
                          {modalDetail && percentageFormat(modalDetail.reason.rate)}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absenceRealRate')}</label>
                        <h5 className="informative-field">
                          {modalDetail && percentageFormat(modalDetail.rate)}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    {/*
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absenceBaseAllowance')}</label>
                        <h5 className="informative-field">
                          {modalDetail && floatFormat(modalDetail.baseAllowance, true)}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absenceActualAllowance')}</label>
                        <h5 className="informative-field">
                          {modalDetail && floatFormat(modalDetail.actualAllowance, true)}
                        </h5>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('absencePeriodCost')}</label>
                        <h5 className="informative-field">
                          {modalDetail && floatFormat(modalDetail.periodCost, true)}
                        </h5>
                      </Form.Field>
                    </Form.Group>
                    */}
                  </Form>
                ) : (
                  <Form
                    className="margin-top-bot main-form"
                    loading={loadingCreateAbsence}
                    size="small"
                  >
                    <Form.Group inline>
                      <Form.Select
                        label={t('formEmployee')}
                        control={Dropdown}
                        placeholder={t('formPHSelect')}
                        fluid
                        search
                        selection
                        selectOnBlur={false}
                        name="employee"
                        loading={loadingEmployee}
                        disabled={loadingEmployee}
                        options={employeeOptions}
                        onChange={this.handleSelectChange}
                        value={employee}
                        error={employeeError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formDateStart')}
                        control={DatePicker}
                        selected={dateStart}
                        onChange={date => this.handleDateChange(date, 'dateStart')}
                        locale="fr"
                        autoComplete="off"
                        error={dateStartError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Input
                        label={t('formDateBack')}
                        control={DatePicker}
                        selected={dateBack}
                        onChange={date => this.handleDateChange(date, 'dateBack')}
                        locale="fr"
                        autoComplete="off"
                        error={dateBackError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Select
                        label={t('formReason')}
                        control={Dropdown}
                        placeholder={t('formPHSelect')}
                        fluid
                        search
                        selection
                        selectOnBlur={false}
                        name="reason"
                        loading={loadingCompanySettings}
                        disabled={loadingCompanySettings}
                        options={reasonsOptions}
                        onChange={this.handleReasonChange}
                        value={reason}
                        error={reasonError}
                      />
                    </Form.Group>

                    <Form.Group inline>
                      <Form.Field error={rateError}>
                        <label>
                          {t('absenceReasonRate')}
                        </label>
                        <Input labelPosition="right">
                          <Cleave
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'none',
                              numeralDecimalScale: 2,
                              numeralDecimalMark: ',',
                            }}
                            onChange={e => this.handleInputChange(e, true)}
                            name="rate"
                            placeholder={t('formPHRate')}
                            value={inputFormat(rate, true)}
                          />
                          <Label>%</Label>
                        </Input>
                      </Form.Field>
                    </Form.Group>
                    {/*
                      <Form.Group inline>
                        <Form.Field>
                          <label>{t('absenceBaseAllowance')}</label>
                          <h5 className="informative-field">
                            {floatFormat(baseAllowance, true)}
                          </h5>
                        </Form.Field>
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Field>
                          <label>{t('absenceActualAllowance')}</label>
                          <h5 className="informative-field">
                            {floatFormat(actualAllowance, true)}
                          </h5>
                        </Form.Field>
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Field>
                          <label>{t('absencePeriodCost')}</label>
                          <h5 className="informative-field">
                            {floatFormat(periodCost, true)}
                          </h5>
                        </Form.Field>
                      </Form.Group>
                  */}
                  </Form>
                )
              }
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {!modalDetail
            && (
              <SaveButton
                disabled={loadingCreateAbsence}
                loading={loadingCreateAbsence}
                onClick={this.handleOnSubmit}
              />
            )}

            <CancelButton
              disabled={loadingCreateAbsence}
              loading={loadingCreateAbsence}
              onClick={this.closeModal}
            />
          </Modal.Actions>
        </Modal>

        <DeleteConfirmation
          show={warningMessage}
          name={toDelete && `${toDelete.dateStart} - ${toDelete.dateBack}`}
          loading={loadingDelete}
          onClose={this.dismissWarning}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteAbsence: id => dispatch(deleteAbsence(id)),
  getAbsences: page => dispatch(listAbsence(page)),
  getEmployees: page => dispatch(listEmployee(page)),
  postAbsence: data => dispatch(createAbsence(data)),
  resetError: () => dispatch(errorDelete(null)),
  resetCreateAbsence: () => {
    dispatch(successCreateAbsence(null));
    dispatch(loadingCreateAbsence(false));
    dispatch(errorCreateAbsence(null));
  },
  reset: () => {
    dispatch(resetAbsence());
    dispatch(resetEmployee());
  },
});

const mapStateToProps = state => ({
  dataAbsence: state.absence.list.data,
  loadingAbsence: state.absence.list.loading,
  errorAbsence: state.absence.list.error,

  createdAbsence: state.absence.create.created,
  loadingCreateAbsence: state.absence.create.loading,
  errorCreateAbsence: state.absence.create.error,

  dataEmployee: state.employee.list.data,
  loadingEmployee: state.employee.list.loading,
  errorEmployee: state.employee.list.error,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  deletedAbsence: state.absence.del.deleted,
  loadingDelete: state.absence.del.loading,
  errorDelete: state.absence.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(FiscalYear);

export default withTranslation()(withRouter(Main));
