import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, findIndex, isEmpty, map } from 'lodash';
import { reset as resetEnsembleList } from 'actions/ensemble/list';
import { create, error, loading, success } from 'actions/ensemble/create';
import { reset as resetUpdateEnsemble, retrieve as retrieveEnsemble, update as updateEnsemble } from 'actions/ensemble/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { Button, Dropdown, Form, Grid, Icon, Input, Message, Table } from 'semantic-ui-react';
import { EssorButton } from 'components';
import Cleave from 'cleave.js/react';
import { withTranslation } from 'react-i18next';
import { floatFormat, inputFormat } from 'utils/formatter';
import ContainerGeneral from 'layouts/ContainerGeneral';
import PromptUnsavedData from 'components/PromptUnsavedData';
import BackHeader from 'components/pageHeaders/BackHeader';
import SmallForm from 'layouts/SmallForm';
import Page404 from 'routes/admin/404';

class CreateEnsemble extends Component {
  state = {
    id: null,
    company: null,
    reference: '',
    label: '',
    sellingPrice: '',
    quantity: '',
    selectedItem: null,
    labelError: false,
    sellingPriceError: false,
    quantityError: false,
    selectedItemError: false,
    articlesList: null,
    warningMessage: false,
    noItems: false,
    selectedItems: [],
    isValid: true,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      listEnsemble,
      retrieveEnsemble,
      getArticles,
      selectedCompany,
      match,
    } = this.props;

    if (match.params.id) {
      if (find(listEnsemble['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        retrieveEnsemble(`/ensembles/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });

        return;
      }
    }

    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrieved) && !isEmpty(nextProps.match.params)
      && nextProps.retrieved.id !== prevState.id) {
      return {
        id: nextProps.retrieved.id,
        reference: nextProps.retrieved.reference,
        label: nextProps.retrieved.label,
        sellingPrice: nextProps.retrieved.sellingPrice,
        selectedItems: null,
      };
    }

    if (!isEmpty(nextProps.listArticle) && nextProps.listArticle !== prevState.articlesList) {
      return {
        articlesList: nextProps.listArticle,
      };
    }

    if (nextProps.selectedCompany && nextProps.selectedCompany !== prevState.company) {
      return {
        company: nextProps.selectedCompany['@id'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { selectedItems, articlesList } = this.state;
    const {
      success,
      resetEnsembleList,
      retrieved,
    } = this.props;

    if (!isEmpty(success) && success !== prevProps.success) {
      resetEnsembleList();
    }

    if (!isEmpty(retrieved) && !isEmpty(articlesList) && selectedItems === null) {
      this.setSelectedItems();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  onCleaveInit = (cleave) => {
    this.setState({
      quantityCleave: cleave,
    });
  };

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleFloatChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
      hasDataChanged: true,
    });

    if (name === 'quantity') {
      this.setState({
        warningMessage: false,
        hasDataChanged: true,
      });
    }
  };

  handleDelete = (e, itemId) => {
    const { selectedItems } = this.state;
    const index = findIndex(selectedItems, {
      id: itemId,
    });

    selectedItems.splice(index, 1);

    this.setState({
      selectedItems,
    });
  };

  setSelectedItems = () => {
    const { articlesList } = this.state;
    const { retrieved, selectedCompany } = this.props;

    const article = retrieved.items.map((item) => {
      const articleFilter = find(articlesList, {
        id: item['@id'],
      });

      if (!isEmpty(articleFilter)) {
        articleFilter.quantity = item.quantity;
        if (articleFilter.type === 'Product') {
          if (selectedCompany.calculationMode === 'coef') {
            articleFilter.price = item.purchaseUnitCost * articleFilter.margin;
          } else if (parseFloat(articleFilter.margin) !== 100) {
            articleFilter.price /= (1 - (articleFilter.margin / 100));
          }
        }

        articleFilter.totalPrice = articleFilter.price * articleFilter.quantity;
      }

      return articleFilter;
    });

    this.setState({
      selectedItems: article,
    });
  };

  handleAddItem = () => {
    const { selectedItem, quantity, selectedItems, quantityCleave } = this.state;
    const { selectedCompany } = this.props;
    let isValid = true;

    this.setState({
      quantityError: false,
      selectedItemError: false,
    });

    if (quantity.trim() === '') {
      isValid = false;

      this.setState({
        quantityError: true,
      });
    }

    if (selectedItem === null) {
      isValid = false;

      this.setState({
        selectedItemError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const item = JSON.parse(selectedItem);

    item.quantity = quantity;

    if (item.type === 'Product') {
      if (selectedCompany.calculationMode === 'coef') {
        item.price *= item.margin;
      } else if (parseFloat(item.margin) !== 100) {
        item.price /= (1 - (item.margin / 100));
      }
    }

    item.totalPrice = item.price * item.quantity;

    if (!find(selectedItems, {
      id: item.id,
    })) {
      selectedItems.push(item);

      this.setState({
        selectedItems,
        warningMessage: false,
        selectedItem: null,
        quantity: '',
        hasDataChanged: false,
      });

      quantityCleave.setRawValue('');
    } else {
      this.setState({
        warningMessage: true,
      });
    }
  };

  addQuantity = () => {
    const { selectedItem, selectedItems, quantity, quantityCleave } = this.state;
    const item = JSON.parse(selectedItem);
    const index = findIndex(selectedItems, {
      id: item.id,
    });
    let object = selectedItems[index];

    const newQuantity = parseInt(object.quantity, 10) + parseInt(quantity, 10);
    object = {
      ...object,
      quantity: newQuantity,
      totalPrice: newQuantity * object.price,
    };

    selectedItems[index] = object;
    quantityCleave.setRawValue('');

    this.setState({
      selectedItems,
      warningMessage: false,
      selectedItem: null,
      quantity: '',
    });
  };

  dismissWarning = () => {
    const { quantityCleave } = this.state;
    quantityCleave.setRawValue('');
    this.setState({
      selectedItem: null,
      quantity: '',
      warningMessage: false,
    });
  };

  handleOnSubmit = () => {
    const {
      company,
      reference,
      label,
      sellingPrice,
      selectedItems,
    } = this.state;

    const { postEnsemble, updateEnsemble, retrieved } = this.props;
    let isValid = true;

    this.setState({
      labelError: false,
      sellingPriceError: false,
      noItems: false,
    });

    if (company === null) {
      isValid = false;
    }

    if (label.trim() === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (sellingPrice === '' || sellingPrice < 0) {
      isValid = false;

      this.setState({
        sellingPriceError: true,
      });
    }

    if (selectedItems.length === 0) {
      isValid = false;

      this.setState({
        noItems: true,
      });
    }

    if (!isValid) {
      return;
    }

    const items = [];

    for (let i = 0; i < selectedItems.length; i++) {
      items.push({
        item: selectedItems[i].id,
        quantity: selectedItems[i].quantity,
      });
    }

    const data = {
      company,
      reference,
      label,
      sellingPrice,
      items,
    };

    retrieved ? updateEnsemble(retrieved, data) : postEnsemble(data);
  };

  handleItemSelect = (e, obj) => {
    e.preventDefault();

    this.setState({
      selectedItem: obj.value,
      warningMessage: false,
      hasDataChanged: true,
    });
  };

  render() {
    const {
      reference,
      label,
      sellingPrice,
      selectedItem,
      quantity,
      articlesList,
      selectedItems,
      labelError,
      sellingPriceError,
      quantityError,
      selectedItemError,
      warningMessage,
      noItems,
      isValid,
      hasDataChanged,
    } = this.state;

    const {
      loading,
      error,
      success,
      updated,
      updateError,
      retrieveLoading,
      updateLoading,
      loadingListArticle,
      match,
      location,
      t,
    } = this.props;

    const updateID = match.params.id;
    const items = [];
    let subtotal = 0;

    if (!isEmpty(articlesList)) {
      articlesList.forEach((item) => {
        if (item.type !== 'Ensemble') {
          items.push({
            key: item.id,
            text: item.label,
            value: JSON.stringify(item),
          });
        }
      });
    }

    if (!isEmpty(selectedItems)) {
      for (let i = 0; i < selectedItems.length; i++) {
        subtotal += selectedItems[i].totalPrice;
      }
    }

    if (!isValid) {
      return <Page404 />;
    }

    if (success || updated) {
      return (
        <Redirect
          push
          to={updated ? `/articles/ensembles/${updateID}` : `/articles/ensembles/${success.id}`}
        />
      );
    }

    const fromHome = location.state && location.state.fromHome;

    return (
      <ContainerGeneral>
        <PromptUnsavedData hasDataChanged={hasDataChanged} />

        <BackHeader
          title={updateID ? t('ensemblesUpdateTitle') : t('ensemblesCreateTitle')}
          to={updateID && !fromHome ? `/articles/ensembles/${updateID}` : '/articles/ensembles/'}
        />

        <SmallForm loading={loading || retrieveLoading || updateLoading}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>

                <Form.Group inline>
                  <Form.Input
                    label={t('formReference')}
                    name="reference"
                    placeholder={t('formPHReference')}
                    value={reference}
                    onChange={this.handleTextChange}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Input
                    label={t('formLabel')}
                    name="label"
                    placeholder={t('formPHLabel')}
                    value={label}
                    onChange={this.handleTextChange}
                    error={labelError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field error={sellingPriceError}>
                    <label>{t('formSellingPrice')}</label>
                    <Input>
                      <Cleave
                        options={{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onChange={e => this.handleFloatChange(e)}
                        name="sellingPrice"
                        placeholder={t('formPHSellingPrice')}
                        value={inputFormat(sellingPrice, true)}
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Group className="select-list">
                  <Form.Select
                    label={t('formItems')}
                    control={Dropdown}
                    placeholder={t('formPHSelect')}
                    fluid
                    search
                    selection
                    loading={loadingListArticle}
                    selectOnBlur={false}
                    noResultsMessage="No results"
                    options={items}
                    disabled={loadingListArticle}
                    onChange={this.handleItemSelect}
                    value={selectedItem}
                    error={selectedItemError}
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Group className="select-list">
                  <Form.Field error={quantityError}>
                    <label>{t('formQuantity')}</label>
                    <Input>
                      <Cleave
                        options={{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'none',
                          numeralDecimalScale: 2,
                          numeralDecimalMark: ',',
                        }}
                        onInit={this.onCleaveInit}
                        onChange={e => this.handleFloatChange(e)}
                        name="quantity"
                        placeholder={t('formPHQuantity')}
                        value={inputFormat(quantity, true)}
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </Grid.Column>

              <Grid.Column width={2}>
                <Form.Group className="select-list">
                  <Form.Field>
                    <label>{' '}</label>
                    <EssorButton
                      fluid
                      icon
                      type="plus"
                      size="small"
                      onClick={this.handleAddItem}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={12}>
                {warningMessage
                && (
                  <Message visible={warningMessage} warning icon>
                    <Icon name="warning sign" />
                    <Message.Content>
                      <Message.Header>{t('alreadyAdd')}</Message.Header>
                      <p>{t('addPrevious')}</p>
                      <Button positive compact onClick={this.addQuantity}>{t('buttonYes')}</Button>
                      <Button
                        negative
                        compact
                        onClick={this.dismissWarning}
                      >
                        {t('buttonCancel')}
                      </Button>
                    </Message.Content>
                  </Message>
                )
                }

                <div className="select-list">
                  <label>{t('ensemblesSelectedItems')}</label>

                  <Table celled selectable className="margin-bot">
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell>{t('ensemblesTableName')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ensemblesTableType')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('formSellingPrice')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ensemblesTableQuantity')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('formTotalPrice')}</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {!isEmpty(selectedItems) && map(selectedItems, item => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {item.label}
                          </Table.Cell>
                          <Table.Cell>
                            {t(`ensemble${item.type}`)}
                          </Table.Cell>

                          <Table.Cell className="format-number-fr">
                            {floatFormat(item.price, true)}
                          </Table.Cell>

                          <Table.Cell collapsing textAlign="center">
                            {floatFormat(item.quantity)}
                          </Table.Cell>

                          <Table.Cell className="format-number-fr">
                            {floatFormat(item.totalPrice, true)}
                          </Table.Cell>

                          <Table.Cell collapsing textAlign="center">
                            <Icon name="x" onClick={e => this.handleDelete(e, item.id)} />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                      <Table.Row>
                        <Table.Cell colSpan={4}>
                          Subtotal
                        </Table.Cell>
                        <Table.Cell className="format-number-fr">
                          {floatFormat(subtotal, true)}
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>

                <Message negative hidden={!noItems}>
                  <p>{t('documentNoArticlesSelected')}</p>
                </Message>

                <Message negative hidden={!updateError}>
                  <p>{updateError}</p>
                </Message>

                <Message negative hidden={!error}>
                  <p>{error}</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SmallForm>

        <div className="clearfix">
          <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
            {t('buttonSave')}
          </EssorButton>
        </div>

      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postEnsemble: data => dispatch(create(data)),
  retrieveEnsemble: page => dispatch(retrieveEnsemble(page)),
  updateEnsemble: (item, values) => dispatch(updateEnsemble(item, values)),
  getArticles: page => dispatch(listArticle(page)),
  resetEnsembleList: () => dispatch(resetEnsembleList()),
  reset: () => {
    dispatch(resetArticle());
    dispatch(resetUpdateEnsemble());
    dispatch(success(null));
    dispatch(error(null));
    dispatch(loading(false));
  },
});

const mapStateToProps = state => ({
  success: state.ensemble.create.created,
  loading: state.ensemble.create.loading,
  error: state.ensemble.create.error,

  retrieveError: state.ensemble.update.retrieveError,
  retrieveLoading: state.ensemble.update.retrieveLoading,
  updateError: state.ensemble.update.updateError,
  updateLoading: state.ensemble.update.updateLoading,
  retrieved: state.ensemble.update.retrieved,
  updated: state.ensemble.update.updated,

  listArticle: state.article.list.data,
  loadingListArticle: state.article.list.loading,
  errorListArticle: state.article.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  listEnsemble: state.ensemble.list.data,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateEnsemble);

export default withTranslation()(withRouter(Main));
