import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import moment from 'moment';
import { reset, retrieve } from 'actions/stock-movement/show';
import { withTranslation } from 'react-i18next';

import 'moment/locale/fr';
import Page404 from 'routes/admin/404';
import ContainerGeneral from 'layouts/ContainerGeneral';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import Routes from 'routes/admin/articles/routes';
import { Entities } from 'types/accessRights';
import TwelveForm from 'layouts/TwelveForm';
import KeyValue from 'components/keyValue';
import FormattedDate from 'components/documents/formattedDate';
import Float from 'components/float';

moment.locale('fr');

class ShowMovement extends Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const {
      listMovement,
      getMovement,
      match,
    } = this.props;

    if (find(listMovement['hydra:member'], {
      id: parseInt(match.params.id, 10),
    })) {
      getMovement(`/stock_movements/${match.params.id}`);
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { isValid } = this.state;
    const { retrieved, loading, match, t } = this.props;

    if (!isValid) {
      return <Page404 />;
    }

    return (
      <ContainerGeneral>
        <ModifyHeader
          title={t('movementShowTitle')}
          to={`/articles/movement/${match.params.id}/edit`}
          back={Routes.listMovements}
          entity={Entities.stockMovement}
        />

        <TwelveForm loading={loading}>
          {retrieved && (
            <React.Fragment>
              <KeyValue label={t('formCreationDate')}>
                <FormattedDate value={retrieved.creationDate} />
              </KeyValue>

              <KeyValue label={t('ensembleProduct')}>
                {retrieved.product.label}
              </KeyValue>

              <KeyValue label={t('formQuantity')}>
                <Float value={retrieved.quantity} />
              </KeyValue>

              <KeyValue label={t('formType')}>
                {retrieved.type}
              </KeyValue>
            </React.Fragment>
          )}
        </TwelveForm>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMovement: page => dispatch(retrieve(page)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  retrieved: state.movement.show.retrieved,
  loading: state.movement.show.loading,
  error: state.movement.show.error,

  listMovement: state.movement.list.data,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowMovement);

export default withTranslation()(withRouter(Main));
