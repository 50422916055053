import React, { FunctionComponent } from 'react';
import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import FormatCell from './FormatCell';

interface OwnProps {
  value: BigSource;
  color?: boolean;
  noGreen?: boolean;
}

type Props = OwnProps;

const IntegerCell: FunctionComponent<Props> = ({ value, ...others }) => (
  <FormatCell value={value} {...others}>
    {Big(value).toFixed(0)}
  </FormatCell>
);

export default IntegerCell;
