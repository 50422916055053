import { combineReducers } from 'redux';
import create from './create';
import list from './list';
import show from './show';
import update from './update';
import change from './change';
import del from './delete';

export default combineReducers({
  create, list, show, update, change, del,
});
