import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { list, reset } from 'actions/expert/list';
import { TableList } from 'components';
import { withTranslation } from 'react-i18next';
import ContainerGeneral from '../../../../layouts/ContainerGeneral';
import AddHeader from '../../../../components/pageHeaders/AddHeader';
import { columnDefinition } from '../../../../utils/tables';

class HomeExpert extends Component {
  state = {
    data: {},
    isLoaded: false,
  };

  componentDidMount() {
    const { getExperts } = this.props;

    getExperts();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && !prevState.isLoaded) {
      return {
        data: nextProps.data['hydra:member'],
        isLoaded: true,
      };
    }
    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { data } = this.state;
    const { loading, t } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader title={t('expertsHomeTitle')} to="/experts/create" />

        <TableList
          columns={[
            columnDefinition('formFirstName', 'firstName'),
            columnDefinition('formLastName', 'lastName'),
            columnDefinition('formJobTitle', 'jobTitle'),
            columnDefinition('formEmail', 'identity.username'),
            columnDefinition('formOffice', 'office.name'),
            columnDefinition('formLastAccess', 'dateLastAccess', 'date'),
          ]}
          loading={loading}
          data={data}
          filterAttribute="firstName"
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getExperts: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  data: state.expert.list.data,
  loading: state.expert.list.loading,
  error: state.expert.list.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeExpert);

export default withTranslation()(withRouter(Main));
