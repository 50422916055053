import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import MainNav from './mainNav/MainNav';
import Sidebar from './sidebar/Sidebar';
import Theme from './Theme';
import { keyVal } from '../utils/functions';
import { State } from '../types/store';

interface OwnProps {
  children: ReactNode;
}

type Props = OwnProps;

const MainView: FC<Props> = ({ children }) => {
  const mainNavIsOpen = useSelector((state: State) => state.layout.mainNavIsOpen);

  return (
    <div className="main-container">
      <Theme />
      <MainNav />
      <div className={classnames('main-view', keyVal('active', mainNavIsOpen))}>
        {children}
      </div>
      <Sidebar />
    </div>
  );
};

export default MainView;
