import React from 'react';
import { withTranslation } from 'react-i18next';

function Bool(props) {
  const { value, t } = props;

  return (
    <React.Fragment>
      {value ? t('boolYes') : t('boolNo') }
    </React.Fragment>
  );
}

export default withTranslation()(Bool);
