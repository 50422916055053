import React, { ReactElement, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Entity } from 'types/entity';

interface OwnProps<T extends Entity> {
  retrieved: T[];
  setSlice: (start: number, end: number) => void;
  max?: number;
}

function Pagination<T extends Entity>({ retrieved, setSlice, max }: OwnProps<T>): ReactElement {
  const [pageSize] = useState(max || 20);
  const [pageIndex, setPageIndex] = useState(0);

  const total = retrieved.length;

  useEffect(() => {
    setSlice(pageSize * pageIndex, pageSize * (pageIndex + 1));
  }, [total, pageSize, pageIndex]);

  if (total <= pageSize) {
    return <></>;
  }

  const maxIndex = Math.ceil(total / pageSize) - 1;

  return (
    <nav aria-label="Page navigation" className="pagination">
      <span onClick={(): void => setPageIndex(0)}><Icon name="angle double left" /></span>
      <span onClick={(): void => setPageIndex(Math.max(pageIndex - 1, 0))}><Icon name="angle left" /></span>
      <span>{`${pageIndex + 1} / ${Math.ceil(total / pageSize)}`}</span>
      <span onClick={(): void => setPageIndex(Math.min(pageIndex + 1, maxIndex))}><Icon name="angle right" /></span>
      <span onClick={(): void => setPageIndex(maxIndex)}><Icon name="angle double right" /></span>
    </nav>
  );
}
export default Pagination;
