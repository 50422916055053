const SET_MAIN_NAV_VISIBILITY = 'LAYOUT/SET_MAIN_NAV_VISIBILITY';
const SET_SIDEBAR_VISIBILITY = 'LAYOUT/SET_SIDEBAR_VISIBILITY';

const layout = (state = false, action) => {
  if (action.type === SET_MAIN_NAV_VISIBILITY) {
    return {
      ...state,
      mainNavIsOpen: action.visibility,
    };
  }
  return state;
};

export const layoutRight = (state = false, action) => {
  if (action.type === SET_SIDEBAR_VISIBILITY) {
    return {
      ...state,
      sidebarIsVisible: action.visibility,
    };
  }
  return state;
};

export default layout;
