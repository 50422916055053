import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { Table, TableBody } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { DESC } from 'utils/constants';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import TableLoader from 'components/TableLoader';
import SortableHeader from 'components/sortableHeader';
import { customerDisplay, documentDetails, documentOrigin, getHeaderItem, sortedDocuments } from 'components/documents/documentCommon';
import LeftCell from 'layouts/table/LeftCell';
import PrintCellButton from 'components/buttons/PrintCellButton';
import CheckRights from 'components/access/CheckRights';
import { crudRights, Entities } from 'types/accessRights';
import BusinessIconCell from 'routes/admin/business/businessIconCell';
import DateCell from 'components/cell/DateCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { getOtherStatus } from 'utils/documentStatus';
import UniqueId from 'components/documents/uniqueId';
import DetailTooltip from 'components/icons/DetailTooltip';
import TableEmptyRow from 'components/TableEmptyRow';

class ListCreditNote extends Component {
  state = {
    creditNoteData: null,
    column: 'creationDate',
    direction: DESC,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataCreditNote) && nextProps.dataCreditNote['hydra:member'] !== prevState.creditNoteData) {
      return {
        creditNoteData: nextProps.dataCreditNote['hydra:member'],
      };
    }

    return null;
  }

  handleSort = (clickedColumn, nextDirection) => {
    this.setState({
      column: clickedColumn,
      direction: nextDirection,
    });
  };

  render() {
    const { creditNoteData, column, direction } = this.state;

    const {
      loadingCreditNote,
      t,
    } = this.props;

    return (
      <ContainerGeneral>
        <TitleHeader title={t('creditNoteHomeTitle')} />

        <TableLoader status={loadingCreditNote} table sortable celled striped>
          <SortableHeader
            column={column}
            direction={direction}
            handler={this.handleSort}
            items={[
              getHeaderItem('uniqueID', t('documentNumber')),
              getHeaderItem('creationDate', t('documentsHeaderCreationDate')),
              getHeaderItem('customer', t('formCustomer')),
              getHeaderItem('totalPrice', t('quoteTotalWithTaxes'), 'right'),
              getHeaderItem('from', t('deliveryCreatedFrom')),
              getHeaderItem('status', t('documentStatus')),
            ]}
            span={2}
          />

          <TableBody>
            {sortedDocuments(creditNoteData, column, direction).map((creditNote, index) => (
              <Table.Row key={index}>
                <LeftCell><UniqueId document={creditNote} /></LeftCell>
                <DateCell date={moment(creditNote.creationDate)} />
                <LeftCell>{customerDisplay(creditNote)}</LeftCell>
                <CurrencyCell value={creditNote.content.creditNoteAmount} />
                <LeftCell>
                  {documentOrigin(creditNote).uniqueID}
                  <DetailTooltip tip={documentDetails(creditNote)} />
                </LeftCell>
                <BusinessStatusCell document={creditNote} handler={getOtherStatus} />
                <PrintCellButton url={`${creditNote['@id']}/print`} document={creditNote} />
                <CheckRights entity={Entities.creditNote} right={crudRights.update}>
                  <BusinessIconCell document={creditNote} />
                </CheckRights>
              </Table.Row>
            ))}
            {creditNoteData.length === 0 && (
              <TableEmptyRow content={t('documentEmptyList')} span={8} />
            )}
          </TableBody>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: creditNote => dispatch(selectDocument(creditNote)),
});

const mapStateToProps = state => ({
  dataCreditNote: state.creditNote.list.data,
  loadingCreditNote: state.creditNote.list.loading,
  errorCreditNote: state.creditNote.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListCreditNote);

export default withTranslation()(withRouter(Main));
