import i18next from 'i18next';
import { find, isEmpty } from 'lodash';
import { filterArticleList, isSupplierOrder } from 'components/documents/supplierArticles';
import { STATUS_PENDING } from 'utils/constants';
import { CREDIT_NOTE, DELIVERY_FORM, DOCUMENT_MODEL, INVOICE, PURCHASE_ORDER, QUOTE, RECEIPT, SUPPLIER_ORDER, WORK_ORDER } from 'utils/documentType';

export const productSoldOptions = [
  {
    key: 'mp',
    text: 'Matière première',
    value: 'mp',
  },
  {
    key: 'md',
    text: 'Marchandise',
    value: 'md',
  },
  {
    key: 'hst',
    text: 'Sous-traitance',
    value: 'hst',
  },
];

export const serviceSoldOptions = [
  {
    key: 'h',
    text: 'Main d\'œuvre',
    value: 'h',
  },
];
export const discountOptions = [
  {
    key: 'E',
    text: '\u20AC',
    value: 'e',
  },
  {
    key: 'P',
    text: '%',
    value: 'p',
  },
];

export const lineOptions = [
  {
    key: 'line',
    text: i18next.t('documentBlankLine'),
    value: 'blankLine',
  },
  {
    key: 'comment',
    text: i18next.t('documentComment'),
    value: 'comment',
  },
  {
    key: 'subtotal',
    text: i18next.t('documentSubtotal'),
    value: 'subtotal',
  },
];

const primaryTypes = [INVOICE, QUOTE, PURCHASE_ORDER];
const editable = [...primaryTypes, DOCUMENT_MODEL, CREDIT_NOTE, SUPPLIER_ORDER];
const hidePrices = [WORK_ORDER, DELIVERY_FORM, RECEIPT];

export function isPrimaryType(type) {
  return primaryTypes.indexOf(type) !== -1;
}

export function isEditable(type, status) {
  return status < STATUS_PENDING && editable.indexOf(type) !== -1;
}

export function hasSecondLine(type) {
  return isPrimaryType(type);
}

export function hasOptionCheckbox(type) {
  return type === QUOTE || type === DOCUMENT_MODEL;
}

export function hasComment(type) {
  return [...primaryTypes, CREDIT_NOTE, WORK_ORDER, DOCUMENT_MODEL].indexOf(type) !== -1;
}

export function showPrices(documentType) {
  return hidePrices.indexOf(documentType) === -1;
}

export function isLayoutLine(type) {
  const layoutTypes = ['blankLine', 'comment', 'subtotal'];

  return layoutTypes.indexOf(type) !== -1;
}

export function getDocumentTypeLabel(type) {
  switch (type) {
    case QUOTE: return i18next.t('formQuoteNumber');
    case PURCHASE_ORDER: return i18next.t('purchaseOrderNumber');
    case INVOICE: return i18next.t('invoiceOrderNumber');
    case CREDIT_NOTE: return i18next.t('creditNoteOrderNumber');
    case RECEIPT: return i18next.t('receiptOrderNumber');
    case WORK_ORDER: return i18next.t('workOrderNumber');
    case DELIVERY_FORM: return i18next.t('deliveryNumber');
    case SUPPLIER_ORDER: return i18next.t('supplierOrderNumber');
    default: return 'Document';
  }
}

export function getCustomerList(customerList) {
  let customers = [];
  if (customerList && customerList.length > 0) {
    customers = customerList.map((customer, index) => ({
      key: index,
      text: customer.companyName || customer.contactName,
      value: customer['@id'],
    }));
  }
  return customers;
}

export function getModelList(modelList) {
  let models = [];
  if (modelList && modelList.length > 0) {
    models = modelList.map((model, index) => ({
      key: index,
      text: model.label,
      value: model['@id'],
    }));
  }
  return models;
}

export function getEmployeeList(employeeList, fiscalYear) {
  let employees = [];
  if (fiscalYear && employeeList && employeeList.length > 0) {
    employees = employeeList.filter((employee) => {
      for (let i = 0; i < employee.data.length; i++) {
        if (employee.data[i].fiscalYear === fiscalYear['@id']) {
          if (employee.data[i].hoursSynthesis.total) {
            return employee.data[i].hoursToSell;
          }
        }
      }
      return false;
    }).map(employee => ({
      key: employee['@id'],
      text: `${employee.firstName} ${employee.lastName}`,
      value: JSON.stringify(employee),
    }));
  }
  return employees;
}

export function getOfferReasons(listCompanySettings) {
  let offerReasons = [];
  if (!isEmpty(listCompanySettings)) {
    offerReasons = find(listCompanySettings['hydra:member'], {
      name: 'OFFER_REASONS',
    });
    // TODO Check what's happening here
    offerReasons = offerReasons ? offerReasons.value : [];
  }

  if (!isEmpty(offerReasons)) {
    offerReasons = offerReasons.map((reasons, index) => ({
      key: `reasons${index}`,
      text: reasons.label,
      value: reasons.id,
    }));
  }
  return offerReasons;
}

export function getArticles(listArticle, selectedCompany, customer, type) {
  let itemList = [];

  if (!isEmpty(listArticle)) {
    if (isSupplierOrder(type)) {
      itemList = filterArticleList(listArticle, selectedCompany, customer['@id']);
    } else {
      itemList = listArticle.map(article => ({
        key: `pro${article.id}`,
        text: `${article.reference} - ${article.label}`,
        value: JSON.stringify(article),
      }));
    }
  }
  return itemList;
}

export function getFollowUpTypes() {
  return [
    {
      key: 'postal',
      text: i18next.t('quotePostalMail'),
      value: 'postal',
    },
    {
      key: 'p',
      text: i18next.t('quotePhoneCall'),
      value: 'phone',
    },
    {
      key: 'e',
      text: i18next.t('quoteEmail'),
      value: 'email',
    },
    {
      key: 'm',
      text: i18next.t('quoteMeeting'),
      value: 'meeting',
    },
  ];
}

export function getEstimationOptions() {
  return [
    {
      key: 'agreement',
      text: i18next.t('quoteAgreement'),
      value: 1,
    },
    {
      key: 'list',
      text: i18next.t('quoteShortList'),
      value: 2,
    },
    {
      key: 'undecided',
      text: i18next.t('quoteUndecided'),
      value: 3,
    },
    {
      key: 'unlikely',
      text: i18next.t('quoteUnlikely'),
      value: 4,
    },
  ];
}
