import React from 'react';
import { isNotDraft } from 'utils/documentStatus';

export function uniqueId(document) {
  return isNotDraft(document) ? document.uniqueID : '-';
}

function UniqueId(props) {
  const { document } = props;

  return <>{uniqueId(document)}</>;
}

export default UniqueId;
