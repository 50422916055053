import React from 'react';
import { Switch } from 'react-router-dom';

import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/articles/routes';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import ShowStock from './show';
import EditStock from './edit';
import ListStock from './ListStock';

const Stock = () => (
  <Switch>
    <CheckRoute
      exact
      path={Routes.editStock}
      component={EditStock}
      entity={Entities.stock}
      right={crudRights.update}
    />
    <CheckRoute
      exact
      path={Routes.showStock}
      component={ShowStock}
      entity={Entities.stock}
      right={crudRights.read}
    />
    <CheckRoute
      exact
      path={Routes.listStocks}
      component={ListStock}
      entity={Entities.stock}
      right={crudRights.read}
    />
    <RouteNotFound />
  </Switch>
);

export default Stock;
