import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import TopNotif from './TopNotif';

interface OwnProps {
  loaded: number;
  total: number;
}

type Props = OwnProps;

const ListLoading: FunctionComponent<Props> = ({ loaded, total }) => {
  const { t } = useTranslation();
  return (
    <TopNotif
      show={loaded < total}
      content={`${t('loading')} ${loaded} / ${total}`}
    />
  );
};

export default ListLoading;
