import { ComponentClass, FunctionComponent } from 'react';
import Company from './admin/company';
import Contacts from './admin/contacts';
import Subscriptions from './admin/subscriptions';
import Payments from './admin/payments';
import Employees from './admin/employee';
import Forecast from './admin/forecast';
import Articles from './admin/articles';
import Business from './admin/business';
import Analysis from './admin/analysis';
import Experts from './admin/expert';
import Offices from './admin/office';
import Routes from './routes';
import { Entities } from '../types/accessRights';

export interface RouteDefinition {
  entity: Entities;
  path: string;
  component: FunctionComponent | ComponentClass;
}

export function getBaseRoutes(): RouteDefinition[] {
  return [
    {
      entity: Entities.pageCompany,
      path: Routes.Company.home,
      component: Company,
    },
  ];
}

export function getCompanyProtectedRoutes(): RouteDefinition[] {
  return [
    {
      entity: Entities.pageContacts,
      path: Routes.Contacts.home,
      component: Contacts,
    },
    {
      entity: Entities.pageSubscriptions,
      path: Routes.Subscriptions.home,
      component: Subscriptions,
    },
    {
      entity: Entities.pageFinances,
      path: Routes.Finances.home,
      component: Payments,
    },
    {
      entity: Entities.pageEmployees,
      path: Routes.Employees.home,
      component: Employees,
    },
    {
      entity: Entities.pageArticles,
      path: Routes.Articles.home,
      component: Articles,
    },
    {
      entity: Entities.pageBusiness,
      path: Routes.Business.home,
      component: Business,
    },
    {
      entity: Entities.pageAnalysis,
      path: Routes.Analysis.home,
      component: Analysis,
    },
    {
      entity: Entities.pageForecast,
      path: Routes.Forecast.home,
      component: Forecast,
    },
  ];
}

export function getFYProtectedRoutes(): RouteDefinition[] {
  return [
    // no more routes protected on the fiscal year for now, we use error messages instead
  ];
}

export function getExpertRoutes(): RouteDefinition[] {
  return [
    {
      entity: Entities.pageOffices,
      path: Routes.Offices.home,
      component: Offices,
    },
    {
      entity: Entities.pageExperts,
      path: Routes.Experts.home,
      component: Experts,
    },
  ];
}
