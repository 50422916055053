import React from 'react';
import VisibilityHeaderCell from 'components/documents/visibilityHeaderCell';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import HeaderRow from 'layouts/table/HeaderRow';

const VisibilityHeader = (props) => {
  const { t } = useTranslation();
  const { visibility, handler, showPrices } = props;

  const config = [
    {
      label: t('documentReference'),
      type: 'reference',
    },
    {
      label: t('formLabel'),
      type: 'label',
    },
    {
      label: t('documentUnitPrice'),
      title: t('quotePreTax'),
      type: 'unitCost',
      show: showPrices,
      className: 'default-cell',
    },
    {
      label: t('formUnit'),
      type: 'unit',
    },
    {
      label: t('formVat'),
      type: 'vat',
      show: showPrices,
    },
    {
      label: t('quoteQty'),
      type: 'quantity',
      className: 'default-cell',
    },
    {
      label: t('documentDiscount'),
      type: 'discount',
      show: showPrices,
      className: 'double-cell',
    },
    {
      label: t('documentTotal'),
      title: t('quotePreTaxTotal'),
      type: 'total',
      show: showPrices,
      className: 'default-cell',
    },
  ];


  return (
    <HeaderRow>
      <Table.HeaderCell />
      {config.map(item => (
        <VisibilityHeaderCell
          key={`VisibilityHeader${item.label}`}
          label={item.label}
          title={item.title}
          type={item.type}
          visibility={visibility[item.type]}
          handler={handler}
          show={item.show}
          className={item.className}
        />
      ))}
    </HeaderRow>
  );
};

export default VisibilityHeader;
