import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { DeleteConfirmation, TableList } from 'components';
import { success as setListSupplier } from 'actions/supplier/list';
import { del as deleteSupplier, error as errorDelete } from 'actions/supplier/delete';
import { withTranslation } from 'react-i18next';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/contacts/routes';
import { Entities } from 'types/accessRights';
import { columnDefinition } from 'utils/tables';

class HomeSupplier extends Component {
  state = {
    data: {},
    warningMessage: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && nextProps.data['hydra:member'] !== prevState.data) {
      return {
        data: nextProps.data['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedSupplier, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.dismissWarning();
      resetError();
    }

    if (!isEmpty(deletedSupplier)
      && deletedSupplier !== prevProps.deletedSupplier) {
      this.updateList();
    }
  }

  updateList = () => {
    const { setListSupplier, deletedSupplier, data } = this.props;
    const newArray = data['hydra:member'].filter(item => item['@id'] !== deletedSupplier['@id']);

    const obj = {
      ...data,
      'hydra:member': newArray,
    };

    setListSupplier(obj);
    this.dismissWarning();
  };

  handleDelete = () => {
    const { toDelete } = this.state;
    const { deleteSupplier } = this.props;
    deleteSupplier(toDelete);
  };

  showDeleteWarning = (toDelete) => {
    this.setState({
      toDelete,
      warningMessage: true,
    });
  };

  dismissWarning = () => {
    this.setState({
      toDelete: null,
      warningMessage: false,
    });
  };

  render() {
    const { data, warningMessage, toDelete } = this.state;
    const { loading, t, loadingDelete, history } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('suppliersHomeTitle')}
          to={Routes.createSupplier}
          entity={Entities.supplier}
        />

        <TableList
          columns={[
            columnDefinition('formSupplierName', 'supplierName'),
            columnDefinition('formContactName', 'contactName'),
            columnDefinition('formStreetName', 'streetName'),
            columnDefinition('formWebsite', 'website'),
          ]}
          filterBtn
          exportBtn
          loading={loading}
          data={data}
          onView={item => history.push(`/contacts/suppliers/${item.id}`)}
          onEdit={item => history.push(`/contacts/suppliers/${item.id}/edit`)}
          onDelete={this.showDeleteWarning}
          entity={Entities.supplier}
        />

        <DeleteConfirmation
          show={warningMessage}
          name={toDelete && toDelete.contactName ? toDelete.contactName : ''}
          loading={loadingDelete}
          onClose={this.dismissWarning}
          onDelete={this.handleDelete}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteSupplier: id => dispatch(deleteSupplier(id)),
  setListSupplier: list => dispatch(setListSupplier(list)),
  resetError: () => dispatch(errorDelete(null)),
});

const mapStateToProps = state => ({
  data: state.supplier.list.data,
  loading: state.supplier.list.loading,
  selectedCompany: state.userCompanies.select.selectedCompany,

  deletedSupplier: state.supplier.del.deleted,
  loadingDelete: state.supplier.del.loading,
  errorDelete: state.supplier.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeSupplier);

export default withTranslation()(withRouter(Main));
