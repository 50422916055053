enum Contacts {
  home = '/contacts',
  listRecipients = '/contacts/recipients',
  showRecipient = '/contacts/recipients/:id',
  createRecipient = '/contacts/recipients/create',
  editRecipient = '/contacts/recipients/:id/edit',
  listSuppliers = '/contacts/suppliers',
  showSupplier = '/contacts/suppliers/:id',
  createSupplier = '/contacts/suppliers/create',
  editSupplier = '/contacts/suppliers/:id/edit',
  updateSupplierPrices = '/contacts/suppliers/:id/update-prices',
}

export default Contacts;
