import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getCalculationClass } from 'utils/marginCalculation';
import { connect } from 'react-redux';
import { retrieve as retrieveForecast } from 'actions/forecast/show';
import Big from 'big.js';
import { getBreakEvenResult } from 'routes/admin/forecast/break-even/breakEven';

function withBreakEven(WrappedComponent) {
  class HocWithBreakEven extends React.Component {
    state = {
      planned: {},
      realised: {},
    };

    componentDidMount() {
      const { getForecast, selectedCompany, selectedFiscalYear } = this.props;

      getForecast(`/companies/${selectedCompany.id}/forecast_result/${selectedFiscalYear.id}`);
    }

    static getDerivedStateFromProps(nextProps) {
      if (!isEmpty(nextProps.retrievedForecast)) {
        return {
          planned: nextProps.retrievedForecast.planned,
          realised: nextProps.retrievedForecast.realised,
        };
      }
      return null;
    }

    marginCalc = () => {
      const { selectedCompany } = this.props;

      return getCalculationClass(selectedCompany);
    };

    computeBreakEven() {
      const { planned } = this.state;
      const { selectedFiscalYear: { dateStart, dateEnd } } = this.props;
      const margin = this.marginCalc();

      if (isEmpty(planned)) {
        return {
          turnover: Big(0),
          fixedCharges: Big(0),
          variableCharges: Big(0),
          breakEven: Big(0),
          breakEvenDate: moment(),
          operatingProfit: Big(0),
          operatingProfitDate: moment(),
        };
      }

      const turnover = margin
        .getBigPriceFromCost(Big(planned.acaMP || 0), Big(planned.valueMP || 0))
        .plus(margin.getBigPriceFromCost(Big(planned.acaMD || 0), Big(planned.valueMD || 0)))
        .plus(margin.getBigPriceFromCost(Big(planned.acaST || 0), Big(planned.valueST || 0)))
        .plus(Big(planned.hoursToSellAdapted || 0).times(Big(planned.thM || 0)));

      const fixedCharges = Big(planned.totalFixedCharges || 1);
      const variableCharges = Big(planned.totalVariablesCharges || 1);

      return getBreakEvenResult(
        dateStart, dateEnd, fixedCharges, variableCharges, turnover.eq(0) ? Big(1) : turnover,
      );
    }

    realisedBreakEven() {
      const { realised } = this.state;
      const { selectedFiscalYear } = this.props;

      const breakEven = {};

      breakEven.turnover = this.marginCalc().getBigPriceFromCost(realised.acaMP, realised.valueMP)
        .plus(this.marginCalc().getBigPriceFromCost(realised.acaMD, realised.valueMD))
        .plus(this.marginCalc().getBigPriceFromCost(realised.acaST, realised.valueST))
        .plus(this.marginCalc().getBigPriceFromCost(realised.hoursToSellAdapted, realised.thM));

      breakEven.totalFixedCharges = parseFloat(realised.totalFixedCharges) !== null
        ? parseFloat(realised.totalFixedCharges)
        : 0;
      breakEven.totalVariablesCharges = parseFloat(realised.totalVariablesCharges) !== null
        ? parseFloat(realised.totalVariablesCharges)
        : 0;
      breakEven.variableCostsMargin = breakEven.turnover - breakEven.totalVariablesCharges;
      breakEven.variableCostsMarginPercentage = breakEven.turnover === 0
        ? 0
        : breakEven.variableCostsMargin / breakEven.turnover;
      breakEven.result = breakEven.variableCostsMarginPercentage === 0
        ? 0
        : breakEven.totalFixedCharges / breakEven.variableCostsMarginPercentage;
      breakEven.neutralPointDate = breakEven.turnover === 0
        ? 0
        : breakEven.result * realised.daysInTheFiscalYear / breakEven.turnover;

      breakEven.newDate = moment(selectedFiscalYear.dateStart)
        .add(breakEven.neutralPointDate.toFixed(2), 'd');

      return breakEven;
    }

    render() {
      const { loadingForecast, ...others } = this.props;

      return (
        <WrappedComponent
          data={this.computeBreakEven()}
          loading={loadingForecast}
          {...others}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    retrievedForecast: state.forecast.show.retrieved,
    loadingForecast: state.forecast.show.loading,
    errorForecast: state.forecast.show.error,

    selectedCompany: state.userCompanies.select.selectedCompany,
    selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
  });

  const mapDispatchToProps = dispatch => ({
    getForecast: page => dispatch(retrieveForecast(page)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(HocWithBreakEven);
}

export default withBreakEven;
