import React from 'react';

const showInvoice = () => (
  <div className="section-container">
    <div className="section-general">
      Show Invoice
    </div>
  </div>
);

export default showInvoice;
