import React, { Component } from 'react';
import { Link, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setCreditNoteList } from 'actions/credit-note/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { change as changeCreditNote, reset as resetChangeCreditNote } from 'actions/credit-note/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { reset as resetUpdateCreditNote, retrieve as retrieveCreditNote, retrieveSuccess as retrieveCreditNoteSuccess, update as updateCreditNote } from 'actions/credit-note/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { selectDocument } from 'actions/user-companies/select';
import { Dimmer, Form, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { Document, DocumentTimer, EssorButton } from 'components';
import { floatFormat } from 'utils/formatter';
import { isDraft, isNotDraft, isPending } from 'utils/documentStatus';
import NotFound from 'routes/admin/404';

import 'moment/locale/fr';
import ActionButtons from 'components/documents/actionButtons';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import { CREDIT_NOTE } from 'utils/documentType';

moment.locale('fr');

class EditCreditNote extends Component {
  static contextType = PrinterContext;

  state = {
    isValid: true,

    status: null,
    creditNoteId: null,
    documentData: {},

    customerList: null,
    selectedCustomer: null,

    isCreate: false,
    timeSpent: 0,
    loadedPurchase: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getCreditNote,
      getCustomerList,
      selectedCompany,
      getArticles,
      listCreditNote,
      match,
    } = this.props;

    if (match.params.id) {
      if (find(listCreditNote['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getCreditNote(`/credit_notes/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });

        return;
      }
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedCreditNote)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedCreditNote.id !== prevState.creditNoteId
        || nextProps.retrievedCreditNote.status !== prevState.status)) {
      const documentData = {
        documentId: isNotDraft(nextProps.retrievedCreditNote)
          ? nextProps.retrievedCreditNote.uniqueID
          : '-',
        content: nextProps.retrievedCreditNote.content,
        reference: nextProps.retrievedCreditNote.reference,
        creationDate: moment(nextProps.retrievedCreditNote.creationDate),
        responseDate: moment(nextProps.retrievedCreditNote.responseDate),
        invoice: nextProps.retrievedCreditNote.invoice,
        status: nextProps.retrievedCreditNote.status,
      };

      return {
        documentData,
        creditNoteId: nextProps.retrievedCreditNote.id,
        status: nextProps.retrievedCreditNote.status,
        selectedCustomer: nextProps.retrievedCreditNote.customer['@id'],
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedCreditNote.timeSpent,
        loadedPurchase: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedCreditNote,

      updatedCreditNote,
      changedCreditNote,
      listCreditNote,

      setCreditNote,
      changeCreditNote,
      selectDocument,
      setCreditNoteList,
      history,
    } = this.props;

    const { setFileName } = this.context;

    const { submitAction } = this.state;

    if (!isEmpty(retrievedCreditNote)
      && retrievedCreditNote !== prevProps.retrievedCreditNote) {
      selectDocument(retrievedCreditNote);
      this.updateMaxAmount();
    }

    if (isEmpty(retrievedCreditNote)
      && retrievedCreditNote !== prevProps.retrievedCreditNote) {
      selectDocument(null);
    }

    if (!isEmpty(changedCreditNote)
      && changedCreditNote['@id'] === selectedDocument['@id']
      && isNotDraft(changedCreditNote.status)) {
      selectDocument(changedCreditNote);
    }

    if (!isEmpty(updatedCreditNote) && updatedCreditNote !== prevProps.updatedCreditNote) {
      const index = findIndex(listCreditNote['hydra:member'], {
        id: updatedCreditNote.id,
      });

      listCreditNote['hydra:member'][index] = updatedCreditNote;
      setCreditNoteList(listCreditNote);

      switch (submitAction) {
        case 'save':
          history.push('/business/credit_notes');
          break;
        case 'validate':
          changeCreditNote(`/credit_notes/${updatedCreditNote.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
    }

    if (!isEmpty(changedCreditNote)
      && changedCreditNote !== prevProps.changedCreditNote
      && changedCreditNote['@id'] === selectedDocument['@id']) {
      const index = findIndex(listCreditNote['hydra:member'], {
        id: changedCreditNote.id,
      });

      setCreditNote(changedCreditNote);
      listCreditNote['hydra:member'][index] = changedCreditNote;
      setCreditNoteList(listCreditNote);
    }

    if (retrievedCreditNote) {
      setFileName(retrievedCreditNote.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  updateMaxAmount = () => {
    const {
      retrievedCreditNote: {
        invoice,
        totalPrice,
        totalVAT,
        content: { creditNoteAmount },
      },
      listCreditNote,
    } = this.props;

    const totalCreditNoteAmount = listCreditNote['hydra:member'].reduce((initial, next) => {
      if (next.invoice === invoice) {
        return initial + parseFloat(next.content.creditNoteAmount);
      }

      return initial;
    }, 0);

    const maxAmount = totalPrice + totalVAT - (totalCreditNoteAmount - creditNoteAmount);

    this.setState({
      maxAmount: maxAmount.toFixed(2),
    });
  };

  onSubmit = (data) => {
    this.setState({
      validDocument: false,
      error: null,
    });

    const { maxAmount } = this.state;

    const {
      formTime,
      updateCreditNote,
      retrievedCreditNote,
    } = this.props;

    let error = null;

    if (data.content.creditNoteAmount <= 0) {
      error = 'creditNoteZeroMessage';
    } else if (parseFloat(data.content.creditNoteAmount) > parseFloat(maxAmount)) {
      error = 'creditNoteAmountMessage';
    }

    if (error !== null) {
      this.setState({
        error,
      });
      return;
    }

    data.timeSpent = formTime;

    updateCreditNote(retrievedCreditNote, data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  handleAction = (type) => {
    const { printDocument, changeCreditNote, selectedDocument } = this.props;

    if (type === 'edit') {
      changeCreditNote(`/credit_notes/${selectedDocument.id}/to_draft`, 'PUT');
    } else if (type === 'print') {
      printDocument(`/credit_notes/${selectedDocument.id}/print`);
    }
  };

  render() {
    const {
      isValid,
      status,
      documentData,

      customerList,
      selectedCustomer,

      validDocument,
      validContent,

      isCreate,
      timeSpent,
      loadedPurchase,
      hasDataChanged,
      maxAmount,
      error,
    } = this.state;

    const {
      loadingPrintDocument,
      loadingRetrieveCreditNote,
      loadingUpdateCreditNote,
      loadingChangeCreditNote,
      t,
    } = this.props;

    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (loadingChangeCreditNote) {
      return (
        <div className="section-container">
          <Segment
            basic
            className="section-loading"
          >
            <Dimmer active={loadingChangeCreditNote} inverted>
              <Loader>
                {t('documentValidate')}
              </Loader>
            </Dimmer>
          </Segment>
        </div>
      );
    }
    const loading = loadingRetrieveCreditNote || loadingChangeCreditNote || loadingUpdateCreditNote;
    return (
      <div className="section-container">
        {(loadedPurchase || isCreate)
        && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveCreditNote}
          />
        )}
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('creditNotesUpdateTitle')}
            </Header>

            <EssorButton
              as={Link}
              to="/business/credit_notes/"
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          <ActionButtons
            isPending={isPending(documentData)}
            handleAction={this.handleAction}
            loadingEditDocument={loadingPrintDocument}
            loadingPrintDocument={loadingPrintDocument}
            t={t}
          />

          {isDraft(documentData)
          && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form
                    className="margin-top-bot main-form"
                    loading={loading}
                    size="small"
                  >
                    <Form.Group inline>
                      <Form.Field>
                        <label>{t('formCustomer')}</label>
                        <h5 className="informative-field">
                          {customerObject && customerObject.companyName}
                        </h5>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <hr />

          {customerObject
          && (
            <React.Fragment>
              <Document
                type={CREDIT_NOTE}
                status={status}
                documentData={documentData}
                customer={customerObject}
                validData={validDocument}
                validContent={validContent}
                getData={(documentData, errors) => this.onSubmit(documentData, errors)}
                hasDataChanged={hasDataChanged}
              />

              {isDraft(documentData)
              && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {error !== 'creditNoteAmountMessage'
                        ? (
                          <React.Fragment>
                            <Message warning>
                              <p>
                                {t('creditNoteAmountMessage', {
                                  amount: floatFormat(maxAmount, true),
                                })}
                              </p>
                            </Message>

                            {!isEmpty(error)
                            && (
                              <Message negative>
                                <p>
                                  {t(error)}
                                </p>
                              </Message>
                            )}
                          </React.Fragment>
                        )
                        : (
                          <Message negative>
                            <Message.Header>{t('documentMessageError')}</Message.Header>
                            <Message.List>
                              <Message.Item>
                                {t('creditNoteAmountMessage', {
                                  amount: floatFormat(maxAmount, true),
                                })}
                              </Message.Item>
                            </Message.List>
                          </Message>
                        )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {isDraft(documentData)
              && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <EssorButton
                        disabled={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        loading={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        type="check"
                        size="tiny"
                        floated="right"
                        onClick={() => this.handleOnSubmit('validate')}
                      >
                        {t('buttonSaveValidate')}
                      </EssorButton>

                      <EssorButton
                        disabled={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        loading={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        type="check"
                        size="tiny"
                        floated="right"
                        onClick={() => this.handleOnSubmit('edit')}
                      >
                        {t('buttonSaveAndEdit')}
                      </EssorButton>

                      <EssorButton
                        disabled={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        loading={
                          loadingUpdateCreditNote
                          || loadingChangeCreditNote
                        }
                        type="check"
                        size="tiny"
                        floated="right"
                        onClick={() => this.handleOnSubmit('save')}
                      >
                        {t('buttonSave')}
                      </EssorButton>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCreditNote: creditNote => dispatch(retrieveCreditNoteSuccess(creditNote)),
  getCreditNote: page => dispatch(retrieveCreditNote(page)),
  getCustomerList: page => dispatch(listCustomer(page)),
  getArticles: page => dispatch(listArticle(page)),
  updateCreditNote: (item, data) => dispatch(updateCreditNote(item, data)),
  changeCreditNote: (route, method) => dispatch(changeCreditNote(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setCreditNoteList: creditNotes => dispatch(setCreditNoteList(creditNotes)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset: () => {
    dispatch(resetListCustomer());
    dispatch(resetArticle());
    dispatch(resetChangeCreditNote());
    dispatch(resetUpdateCreditNote());
  },
});

const mapStateToProps = state => ({
  listCreditNote: state.creditNote.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  retrievedCreditNote: state.creditNote.update.retrieved,
  loadingRetrieveCreditNote: state.creditNote.update.retrieveLoading,
  errorRetrieveCreditNote: state.creditNote.update.retrieveError,
  updatedCreditNote: state.creditNote.update.updated,
  loadingUpdateCreditNote: state.creditNote.update.updateLoading,
  errorUpdateCreditNote: state.creditNote.update.updateError,

  changedCreditNote: state.creditNote.change.changed,
  loadingChangeCreditNote: state.creditNote.change.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditCreditNote);

export default withTranslation()(withRouter(Main));
