import React, { FC, ReactNode } from 'react';
import { Dimmer, Loader, Segment, Table, TableProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface OwnProps extends TableProps {
  status: boolean;
  label?: string;
  children: ReactNode;
  className?: string;
  table?: boolean;
  tableName?: string;
}

type Props = OwnProps;

const TableLoader: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { status, label, children, className, table, tableName, ...others } = props;

  if (table) {
    return (
      <TableLoader status={status} label={label} className={className}>
        <Table {...others} className={tableName}>
          {children}
        </Table>
      </TableLoader>
    );
  }

  return (
    <Segment
      basic
      className={className || classnames('table-container', {
        'is-loading': status,
      })}
    >
      <Dimmer active={status} inverted>
        <Loader>{label || t('loading')}</Loader>
      </Dimmer>
      {children}
    </Segment>
  );
};

export default TableLoader;
