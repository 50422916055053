import React, { FC } from 'react';
import { Form, Label } from 'semantic-ui-react';
import { CleaveOptions } from 'cleave.js/options';
import useAccessRights from '../../../../hooks/useAccessRights';
import { crudRights, Entities } from '../../../../types/accessRights';
import { getCurrency, getLocalFormat } from '../../../../utils/formatter';
import CurrencyCell from '../../../../components/cell/CurrencyCell';
import RightCell from '../../../../layouts/table/RightCell';
import CleaveInit from '../../../../components/input/CleaveInit';

interface OwnProps {
  onCleaveInit: () => void;
  handleBlur: () => void;
  handleChange: (name: string, value: string|number) => void;
  realizedResult: number;
  disable: boolean;
  realizedResultError: boolean;
}

type Props = OwnProps;

const RealizedResultCell: FC<Props> = (props) => {
  const {
    onCleaveInit,
    handleBlur,
    handleChange,
    realizedResult,
    disable,
    realizedResultError,
  } = props;

  const hasAccess = useAccessRights(Entities.budget, crudRights.update);

  if (!hasAccess) {
    return <CurrencyCell value={realizedResult} />;
  }

  return (
    <RightCell>
      <Form.Input error={realizedResultError} labelPosition="right">
        <CleaveInit
          className="right aligned"
          name="realizedResult"
          onInit={onCleaveInit}
          onBlur={handleBlur}
          handler={(value: string|number): void => handleChange('realizedResult', value)}
          value={realizedResult}
          disable={disable}
          options={getLocalFormat(true) as CleaveOptions}
        />
        <Label>{getCurrency()}</Label>
      </Form.Input>
    </RightCell>
  );
};

export default RealizedResultCell;
