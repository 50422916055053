import React, { FunctionComponent, ReactNode } from 'react';
import { Grid } from 'semantic-ui-react';

interface OwnProps {
  contextValue: any;
  children: ReactNode;
}

type Props = OwnProps;

export const DocumentContext = React.createContext({});

const DocumentContainer: FunctionComponent<Props> = ({ contextValue, children }) => (
  <DocumentContext.Provider value={contextValue}>
    <div className="document-container">
      <Grid>
        {children}
      </Grid>
    </div>
  </DocumentContext.Provider>
);

export default DocumentContainer;
