import React, { FunctionComponent } from 'react';
import InlineTooltip from './InlineTooltip';

interface OwnProps {
  tip: string;
}

type Props = OwnProps;

const QuestionTooltip: FunctionComponent<Props> = ({ tip }) => (
  <InlineTooltip icon="question circle outline" tip={tip} />
);

export default QuestionTooltip;
