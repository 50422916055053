import React, { FunctionComponent } from 'react';
import AlignedCell, { ExtendAlignedCellProps } from './AlignedCell';

type Props = ExtendAlignedCellProps;

const CenterCell: FunctionComponent<Props> = props => (
  <AlignedCell textAlign="center" {...props} />
);

export default CenterCell;
