import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectOffice } from 'actions/user-companies/select';
import { retrieve, reset } from 'actions/office-settings/show';
import { retrieve as retrieveOffice } from 'actions/office/show';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import ShowBankAccounts from './show';
import EditBankAccounts from './edit';
import NotFound from '../../../404';

class BankAccounts extends Component {
  componentDidMount() {
    const { selectedOffice, selectOffice, getSettings, getOffice, match } = this.props;

    if ((selectedOffice && selectedOffice.id !== Number(match.params.id)) || !selectedOffice) {
      selectOffice(null);
      getOffice(`/offices/${match.params.id}`);
    } else {
      getSettings(`/office_settings?office=${selectedOffice.id}&name=BANK_ACCOUNTS`);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      updated,
      retrievedOffice,
      selectedOffice,
      getSettings,
      selectOffice,
      match,
    } = this.props;

    if (!isEmpty(updated) && updated !== prevProps.updated) {
      getSettings(`/office_settings?office=${selectedOffice.id}&name=BANK_ACCOUNTS`);
    }

    if (!isEmpty(retrievedOffice) && !selectedOffice) {
      getSettings(`/office_settings?office=${match.params.id}&name=BANK_ACCOUNTS`);
      selectOffice(retrievedOffice);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const {
      selectedOffice,
      retrieved,
      loadingOfficeSettings,
      loadingOffice,
      office,
      t,
    } = this.props;

    if (loadingOfficeSettings || isEmpty(retrieved) || loadingOffice || isEmpty(selectedOffice)) {
      return (
        <div className="section-container">
          <Segment
            basic
            className="section-loading"
          >
            <Dimmer active={loadingOfficeSettings || loadingOffice} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
          </Segment>
        </div>
      );
    }

    return (
      <Switch>
        <Route exact path="/offices/:id/settings/bank-accounts" component={ShowBankAccounts} />
        <Route
          exact
          path="/offices/:id/settings/bank-accounts/edit"
          render={props => (
            <EditBankAccounts {...props} office={office} />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSettings: page => dispatch(retrieve(page)),
  getOffice: page => dispatch(retrieveOffice(page)),
  selectOffice: office => dispatch(selectOffice(office)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  selectedOffice: state.userCompanies.select.selectedOffice,

  retrieved: state.officeSettings.show.retrieved,
  loadingOfficeSettings: state.officeSettings.list.loading,

  retrievedOffice: state.office.show.retrieved,
  loadingOffice: state.office.show.loading,

  updated: state.officeSettings.update.updated,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(BankAccounts);

export default withTranslation()(withRouter(Main));
