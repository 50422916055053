import React, { FC, Fragment, ReactNode } from 'react';

interface OwnProps {
  children?: ReactNode;
  condition?: boolean;
}

const Hide: FC<OwnProps> = ({ children, condition = true }) => (
  condition ? <Fragment /> : <Fragment>{children}</Fragment>
);
export default Hide;
