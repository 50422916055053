import React from 'react';
import CompanyLogo from 'components/documents/companyLogo';
import { Grid } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

function CompanyAddressDef(props) {
  const { t, contactInformation, companyDetails, loadingCompanySettings } = props;

  return (
    <Grid.Column width={9}>
      <CompanyLogo
        loadingCompanySettings={loadingCompanySettings}
        contactInformation={contactInformation}
      />

      <h5 className="informative-field">
        {companyDetails ? companyDetails.legalName : `${t('loading')} ...`}
      </h5>

      <p>{contactInformation ? contactInformation.streetName : `${t('loading')} ...`}</p>
      <p>
        {contactInformation
          ? `${contactInformation.zipCode} ${contactInformation.city}`
          : `${t('loading')} ...`
        }
      </p>
      <p>{contactInformation ? contactInformation.country : `${t('loading')} ...`}</p>
    </Grid.Column>
  );
}
const CompanyAddress = withTranslation()(CompanyAddressDef);
export default CompanyAddress;
