import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { toggleSidebar } from '../../actions/layout/layout';
import { State } from '../../types/store';

const SidebarToggle: FC<{ icon: SemanticICONS }> = ({ icon }) => {
  const visible = useSelector((state: State) => state.layoutRight.sidebarIsVisible);
  const dispatch = useDispatch();

  return (
    <div
      className={classnames('info-toggle-bar', visible ? 'inside' : 'rotate')}
      onClick={() => dispatch(toggleSidebar())}
    >
      <Icon size="large" className="hov-icon" name={!visible ? icon : 'angle double right'} />
    </div>
  );
};

export default SidebarToggle;
