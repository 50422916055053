import React, { FunctionComponent } from 'react';
import IntegerCell from '../../../components/cell/IntegerCell';
import SituationRow, { SituationRowProps } from './SituationRow';

type Props = SituationRowProps;

const SituationIntegerRow: FunctionComponent<Props> = (props) => {
  const { data, noGap } = props;

  return (
    <SituationRow {...props}>
      <IntegerCell value={data && data.realised} />
      <IntegerCell value={data && data.planned} />
      <IntegerCell value={data && data.ratioed} />
      <IntegerCell value={data && data.current} />
      {!noGap && <IntegerCell value={data && data.gap} color noGreen />}
    </SituationRow>
  );
};

export default SituationIntegerRow;
