import React from 'react';
import { Switch } from 'react-router-dom';
import Routes from 'routes/admin/articles/routes';
import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import CreateProduct from './create';
import ListProduct from './home';
import ShowProduct from './show';

const Product = () => (
  <Switch>
    <CheckRoute
      exact
      path={Routes.listProducts}
      component={ListProduct}
      entity={Entities.product}
      right={crudRights.read}
    />
    <CheckRoute
      exact
      path={Routes.createProduct}
      component={CreateProduct}
      entity={Entities.product}
      right={crudRights.create}
    />
    <CheckRoute
      path={Routes.showProduct}
      component={ShowProduct}
      entity={Entities.product}
      right={crudRights.read}
    />
    <RouteNotFound />
  </Switch>
);

export default Product;
