import { combineReducers, ReducersMapObject } from 'redux';
import purchaseOrderToInvoice from './PurchaseOrderToInvoice';
import deliveryForm from './deliveryForm';
import workOrder from './workOrder';

const map: ReducersMapObject = {
  deliveryForm,
  purchaseOrderToInvoice,
  workOrder,
};

export default combineReducers(map);
