import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { success as successListEnsemble } from 'actions/ensemble/list';
import { del as deleteEnsemble, error as errorDelete } from 'actions/ensemble/delete';
import { isEmpty } from 'lodash';
import { DeleteConfirmation, TableList } from 'components';
import { backToHome } from 'utils/constants';
import { Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/articles/routes';
import { columnDefinition } from '../../../../../utils/tables';

class HomeEnsemble extends Component {
  state = {
    data: {},
    warningMessage: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && nextProps.data['hydra:member'] !== prevState.data) {
      return {
        data: nextProps.data['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedEnsemble, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.closeDeleteModal();
      resetError();
    }

    if (!isEmpty(deletedEnsemble)
      && deletedEnsemble !== prevProps.deletedEnsemble) {
      this.updateList();
    }
  }

  updateList = () => {
    const { data, setEnsembleList, deletedEnsemble } = this.props;
    const arr = data['hydra:member'].filter(ensemble => ensemble['@id'] !== deletedEnsemble['@id']);

    const newList = {
      ...data,
      'hydra:member': arr,
    };

    setEnsembleList(newList);
    this.closeDeleteModal();
  };

  handleDelete = () => {
    const { deleteItem } = this.state;
    const { deleteEnsemble } = this.props;
    deleteEnsemble(deleteItem);
  };

  showDeleteModal = (item) => {
    this.setState({
      deleteItem: item,
      warningMessage: true,
      itemName: item.label,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteItem: null,
      warningMessage: false,
      itemName: '',
    });
  };

  render() {
    const { data, warningMessage, itemName } = this.state;
    const { loading, loadingDelete, t, history } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('ensemblesHomeTitle')}
          to={Routes.createEnsemble}
          entity={Entities.ensemble}
        />

        <TableList
          columns={[
            columnDefinition('formLabel', 'label'),
            columnDefinition('formReference', 'reference'),
            columnDefinition('formSellingPrice', 'sellingPrice', 'currency'),
          ]}
          loading={loading}
          data={data}
          onView={item => history.push(`/articles${item['@id']}`)}
          onEdit={item => history.push(`/articles${item['@id']}/edit`, backToHome)}
          onDelete={this.showDeleteModal}
          entity={Entities.ensemble}
        />

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDelete}
          onClose={this.closeDeleteModal}
          onDelete={this.handleDelete}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteEnsemble: id => dispatch(deleteEnsemble(id)),
  resetError: () => dispatch(errorDelete(null)),
  setEnsembleList: list => dispatch(successListEnsemble(list)),
});

const mapStateToProps = state => ({
  data: state.ensemble.list.data,
  loading: state.ensemble.list.loading,
  error: state.ensemble.list.error,

  deletedEnsemble: state.ensemble.del.deleted,
  loadingDelete: state.ensemble.del.loading,
  errorDelete: state.ensemble.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeEnsemble);

export default withTranslation()(withRouter(Main));
