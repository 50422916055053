import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, upperFirst } from 'lodash';
import { create, error, loading, success } from 'actions/customer/create';
import { reset as resetCustomerList } from 'actions/customer/list';
import { reset as resetUpdateCustomer, retrieve as retrieveCustomer, update as updateCustomer } from 'actions/customer/update';
import { Form, Message } from 'semantic-ui-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import NotFound from 'routes/admin/404';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SaveButton from 'components/buttons/SaveButton';
import { honorificOptions } from '../../../../../utils/constants';

class CreateRecipient extends Component {
  state = {
    companyName: '',
    contactName: '',
    honorific: '',
    streetName: '',
    zipCode: '',
    additional: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    tvaNumber: '',
    cellphone: '',
    company: null,
    contactNameError: false,
    streetNameError: false,
    zipCodeError: false,
    cityError: false,
    additionalError: false,
    countryError: false,
    phoneError: false,
    emailError: false,
    tvaNumberError: false,
    cellphoneError: false,
    id: null,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { retrieveCustomer, match, selectedCompany } = this.props;

    if (match.params.id) {
      retrieveCustomer(`/customers/${match.params.id}`);
    }

    this.setState({
      company: selectedCompany['@id'],
    });
  }

  componentDidUpdate(prevProps) {
    const {
      createdCustomer,
      resetCustomerList,
    } = this.props;

    if (!isEmpty(createdCustomer) && createdCustomer !== prevProps.createdCustomer) {
      resetCustomerList();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrieved) && !isEmpty(nextProps.match.params)
      && prevState.id !== nextProps.retrieved.id) {
      return {
        companyName: nextProps.retrieved.companyName,
        contactName: nextProps.retrieved.contactName,
        ...nextProps.retrieved.details,
        zipCode: `${nextProps.retrieved.details.zipCode}`, // Trick to make sure we are using string value
        id: nextProps.retrieved.id,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleSelectChange = (e, obj) => {
    e.preventDefault();

    this.setState({
      [obj.name]: obj.value,
      hasDataChanged: true,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleOnSubmit = () => {
    const {
      companyName,
      contactName,
      honorific,
      streetName,
      additional,
      country,
      zipCode,
      city,
      phone,
      email,
      tvaNumber,
      cellphone,
      company,
    } = this.state;

    const {
      postCustomer,
      updateCustomer,
      retrieved,
    } = this.props;

    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = true;

    this.setState({
      contactNameError: false,
      streetNameError: false,
      additionalError: false,
      countryError: false,
      zipCodeError: false,
      cityError: false,
      phoneError: false,
      emailError: false,
      tvaNumberError: false,
      cellphoneError: false,
    });

    const checkEmptyField = (field, error) => {
      if (typeof field === 'undefined' || field.trim() === '') {
        isValid = false;

        this.setState({
          [error]: true,
        });
      }
    };

    checkEmptyField(contactName, 'contactNameError');
    checkEmptyField(streetName, 'streetNameError');
    checkEmptyField(zipCode, 'zipCodeError');
    checkEmptyField(city, 'cityError');

    if (email.trim() !== '' && !regex.test(email.trim())) {
      isValid = false;

      this.setState({
        emailError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const data = {
      company,
      companyName: upperFirst(companyName),
      contactName: upperFirst(contactName),
      details: {
        honorific,
        phone,
        email,
        tvaNumber,
        cellphone,
        streetName,
        additional,
        country,
        zipCode,
        city,
      },
    };

    this.setState({
      hasDataChanged: false,
    });

    if (retrieved) {
      updateCustomer(retrieved, data);
    } else {
      data.creationDate = moment().format();
      postCustomer(data);
    }
  };

  render() {
    const {
      companyName,
      contactName,
      honorific,
      streetName,
      additional,
      country,
      zipCode,
      city,

      phone,
      email,
      tvaNumber,
      cellphone,

      additionalError,
      countryError,
      contactNameError,
      streetNameError,
      zipCodeError,
      cityError,

      phoneError,
      emailError,
      tvaNumberError,
      cellphoneError,

      retrieveError,
      hasDataChanged,
    } = this.state;

    const {
      loading,
      error,
      createdCustomer,
      retrieveLoading,
      updateLoading,
      updateError,
      updated,
      match,
      t,
    } = this.props;

    const updateID = match.params.id;

    if (createdCustomer || updated) {
      return (
        <Redirect
          push
          to={updated ? `/contacts/recipients/${updateID}` : `/contacts/recipients/${createdCustomer.id}`}
        />
      );
    }

    if (retrieveError) {
      return <NotFound />;
    }

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader
          title={updateID ? t('customerUpdateTitle') : t('customerCreateTitle')}
          to={updateID ? `/contacts/recipients/${updateID}` : '/contacts/recipients/'}
        />
        <TwelveForm loading={loading || retrieveLoading || updateLoading}>
          <Form.Group inline>
            <Form.Input
              label={t('formCompanyName')}
              name="companyName"
              placeholder={t('formCompanyName')}
              value={companyName}
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Select
              label={t('formHonorific')}
              name="honorific"
              placeholder={t('formPHSelect')}
              value={honorific}
              options={honorificOptions}
              onChange={this.handleSelectChange}
              selectOnBlur={false}
              clearable
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formContactName')}
              name="contactName"
              placeholder={t('formPHContactName')}
              value={contactName}
              onChange={this.handleInputChange}
              error={contactNameError}
              required
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formEmail')}
              name="email"
              placeholder="example@email.com"
              value={email}
              onChange={this.handleInputChange}
              error={emailError}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formPhoneNumber')}
              name="phone"
              placeholder={t('formPHPhoneNumber')}
              value={phone}
              onChange={this.handleInputChange}
              error={phoneError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formCellphoneNumber')}
              name="cellphone"
              placeholder={t('formPHCellphoneNumber')}
              value={cellphone}
              onChange={this.handleInputChange}
              error={cellphoneError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formStreetName')}
              name="streetName"
              placeholder={t('formPHStreetName')}
              value={streetName}
              onChange={this.handleInputChange}
              error={streetNameError}
              required
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formAdditional')}
              name="additional"
              placeholder={t('formPHAdditional')}
              value={additional}
              onChange={this.handleInputChange}
              error={additionalError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formZipCode')}
              name="zipCode"
              placeholder={t('formPHZipCode')}
              value={zipCode}
              onChange={this.handleInputChange}
              error={zipCodeError}
              required
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formCity')}
              name="city"
              placeholder={t('formPHCity')}
              value={city}
              onChange={this.handleInputChange}
              error={cityError}
              required
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formCountry')}
              name="country"
              placeholder={t('formPHCountry')}
              value={country}
              onChange={this.handleInputChange}
              error={countryError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formVATNumber')}
              name="tvaNumber"
              placeholder={t('formPHVATNumber')}
              value={tvaNumber}
              onChange={this.handleInputChange}
              error={tvaNumberError}
            />
          </Form.Group>

          <Message negative hidden={!updateError} content={updateError} />
          <Message negative hidden={!error} content={error} />
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} floated="right" />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postCustomer: data => dispatch(create(data)),
  retrieveCustomer: page => dispatch(retrieveCustomer(page)),
  updateCustomer: (item, values) => dispatch(updateCustomer(item, values)),
  resetCustomerList: () => dispatch(resetCustomerList()),
  reset: () => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(resetUpdateCustomer());
  },
});

const mapStateToProps = state => ({
  createdCustomer: state.customer.create.created,
  loading: state.customer.create.loading,
  error: state.customer.create.error,
  data: state.customer.list.data,

  retrieveLoading: state.customer.update.retrieveLoading,
  updateError: state.customer.update.updateError,
  updateLoading: state.customer.update.updateLoading,
  retrieved: state.customer.update.retrieved,
  retrieveError: state.customer.update.retrieveError,
  updated: state.customer.update.updated,
  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateRecipient);

export default withTranslation()(withRouter(Main));
