import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt, Link, Redirect } from 'react-router-dom';
import { isEmpty, find } from 'lodash';
import { withTranslation } from 'react-i18next';
import { list as listProduct, reset as resetListProduct } from 'actions/product/list';
import { inputFormat } from 'utils/formatter';
import { retrieve as retrieveMovement, update as updateMovement, reset as resetUpdateMovement } from 'actions/stock-movement/update';
import { Form, Dropdown, Grid, Header, Input } from 'semantic-ui-react';
import { create as createMovement, error, loading, success } from 'actions/stock-movement/create';
import moment from 'moment';
import Cleave from 'cleave.js/react';
import { EssorButton } from 'components';
import NotFound from '../../../404';

class CreateMovement extends React.Component {
  state = {
    listProduct: [],
    selectedProduct: '',
    quantity: '',

    errorQuantity: false,
    errorSelectedProduct: false,

    hasDataChanged: false,
    isValid: true,
  };

  componentDidMount() {
    const {
      listMovement,
      getMovement,
      selectedCompany,
      match,
      getListProduct,
    } = this.props;

    if (match.params.id) {
      if (find(listMovement['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        getMovement(`/stock_movements/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });
      }
    }

    getListProduct(`/products?company=${selectedCompany.id}&stockManagement=true`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listProduct) && nextProps.listProduct['hydra:member'] !== prevState.listProduct) {
      return {
        listProduct: nextProps.listProduct['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.retrievedMovement)
      && nextProps.retrievedMovement !== prevState.retrievedMovement) {
      return {
        retrievedMovement: nextProps.retrievedMovement,
        selectedProduct: nextProps.retrievedMovement.product['@id'],
        quantity: nextProps.retrievedMovement.quantity,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { createdMovement, resetListProduct } = this.props;
    if (createdMovement && createdMovement !== prevProps.createdMovement) {
      resetListProduct();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;

    reset();
  }

  handleSelectChange = (e, { value, name }) => {
    e.preventDefault();
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: inputFormat(value),
      hasDataChanged: true,
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const {
      selectedProduct,
      quantity,
    } = this.state;

    const { selectedCompany, user, createMovement, retrievedMovement, updateMovement } = this.props;

    this.setState({
      errorQuantity: false,
      errorSelectedProduct: false,
    });

    let isValid = true;

    if (isEmpty(quantity)) {
      isValid = false;
      this.setState({
        errorQuantity: true,
      });
    }

    if (isEmpty(selectedProduct)) {
      isValid = false;
      this.setState({
        errorSelectedProduct: true,
      });
    }

    if (!isValid) return;

    const data = {
      product: selectedProduct,
      company: selectedCompany['@id'],
      type: 'OUT',
      identity: `/identities/${user}`,
      quantity,
      unitPrice: '0',
    };

    if (retrievedMovement) {
      updateMovement(retrievedMovement, data);
    } else {
      data.creationDate = moment().format('YYYY-MM-DD');
      createMovement(data);
    }
  }

  render() {
    const {
      loadingCreateMovement,
      loadingUpdateMovement,
      loadingProduct,
      createdMovement,
      updatedMovement,
      match,
      t,
    } = this.props;
    const {
      selectedProduct,
      quantity,
      listProduct,
      errorQuantity,
      errorSelectedProduct,
      hasDataChanged,
      isValid,
    } = this.state;

    let optionsProducts = [];
    const updateID = match.params.id;

    if (!isEmpty(listProduct)) {
      optionsProducts = listProduct.map(product => ({
        key: product['@id'],
        text: product.label,
        value: product['@id'],
      }));
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (createdMovement || updatedMovement) {
      return (
        <Redirect
          push
          to={updatedMovement ? `/articles/movement/${updateID}` : `/articles/movement/${createdMovement.id}`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {updateID ? t('movementUpdateTitle') : t('movementCreateTitle')}
            </Header>
            <EssorButton
              as={Link}
              to={updateID ? `/articles/movement/${updateID}` : '/articles/movement/'}
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          <Form className="margin-top-bot main-form" loading={loadingCreateMovement || loadingUpdateMovement} size="small">
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>

                  <Form.Group inline>
                    <Form.Select
                      label={t('ensembleProduct')}
                      control={Dropdown}
                      name="selectedProduct"
                      fluid
                      selection
                      options={optionsProducts}
                      loading={loadingProduct}
                      disabled={loadingProduct}
                      placeholder={t('formPHSelect')}
                      value={selectedProduct}
                      onChange={this.handleSelectChange}
                      selectOnBlur={false}
                      error={errorSelectedProduct}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field error={errorQuantity}>
                      <label>{t('formQuantity')}</label>
                      <Input>
                        <Cleave
                          value={inputFormat(quantity, true)}
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 2,
                            numeralDecimalMark: ',',
                          }}
                          onChange={this.handleInputChange}
                          name="quantity"
                          placeholder={t('formPHQuantity')}
                          autoComplete="off"
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {t('buttonSave')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getListProduct: page => dispatch(listProduct(page)),
  createMovement: data => dispatch(createMovement(data)),
  getMovement: page => dispatch(retrieveMovement(page)),
  updateMovement: (item, data) => dispatch(updateMovement(item, data)),
  resetListProduct: () => dispatch(resetListProduct()),
  reset: () => {
    dispatch(resetUpdateMovement());
    dispatch(success(null));
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(resetListProduct());
  },
});

const mapStateToProps = state => ({
  listMovement: state.movement.list.data,

  listProduct: state.product.list.data,
  loadingProduct: state.product.list.loading,

  createdMovement: state.movement.create.created,
  loadingCreateMovement: state.movement.create.loading,

  retrievedMovement: state.movement.update.retrieved,
  updatedMovement: state.movement.update.updated,
  loadingUpdateMovement: state.movement.update.updateLoading,
  loadingRetrieveMovement: state.movement.update.retrieveLoading,

  selectedCompany: state.userCompanies.select.selectedCompany,
  user: state.userCompanies.role.userID,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateMovement);

export default withTranslation()(withRouter(Main));
