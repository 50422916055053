import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import { Breakdown, Budget } from 'types/store';
import { BankAccount } from 'types/settings';

export const ALL_BANKS = 'all';

const addVAT = (amount: BigSource, vatRate: BigSource): number => (
  parseFloat(
    Big(amount).times(
      Big(vatRate).div(100).plus(1),
    ).toFixed(2),
  )
);

export const budgetWithVAT = (budget: Budget): number => (
  addVAT(budget.planned || 0, budget.vat || 0)
);
export const breakdownWithVAT = (breakdown: Breakdown, budget: Budget): number => (
  addVAT(breakdown.amount || 0, budget.vat || 0)
);
export const getDefaultBankId = (banks: BankAccount[]): number => {
  const defaultBank = banks && banks.find((account): boolean => account.default);
  return defaultBank ? defaultBank.id : 0;
};
export const getBankDataFromId = (id: number, banks: BankAccount[]): BankAccount | undefined => (
  banks && banks.find((account): boolean => account.id === id)
);

type Selected = number | 'all';
export const allBank = (selected: Selected): boolean => selected === ALL_BANKS;
export const allOrId = (selected: Selected, bankId: number): boolean => (
  allBank(selected) || selected === bankId
);
