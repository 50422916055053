import React, { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import RowColumn from 'layouts/RowColumn';
import { useTranslation } from 'react-i18next';
import TextEditor from '../textEditor';

const PaymentLegalText = (props) => {
  const { t } = useTranslation();
  const {
    showLatePaymentText,
    latePaymentLawText,
    handleCheckBoxChange,
    handlePaymentText,
    creationDate,
    paymentDate,
  } = props;

  const resetText = () => {
    const legalText = t('documentPaymentLegalText', {
      days: paymentDate ? paymentDate.diff(creationDate, 'days') : 7,
    });

    handlePaymentText(`<p><u><strong>${t('documentPaymentLegalTitle')}</strong></u></p><p>${legalText}</p>`);
  };

  useEffect(() => resetText(), [paymentDate, creationDate]);

  return (
    <RowColumn>
      <Checkbox
        name="showLatePaymentLawText"
        checked={showLatePaymentText}
        label={t('documentPaymentLegalCheckbox')}
        onChange={handleCheckBoxChange}
      />

      <div className="m-t-10">
        <TextEditor
          editorLabel={latePaymentLawText || ''}
          setLabel={editorState => handlePaymentText(editorState)}
          editorOnlyShow
        />
      </div>
    </RowColumn>
  );
};

export default PaymentLegalText;
