import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainNavLink from './mainNavLink';
// import kog from 'assets/images/sidebar-icons/9-layers.png';
import enterprise from '../../assets/images/sidebar-icons/8-layers.png';
import users from '../../assets/images/sidebar-icons/23-layers.png';
import rocket from '../../assets/images/sidebar-icons/12-layers.png';
import globe from '../../assets/images/sidebar-icons/36-layers.png';
import file from '../../assets/images/sidebar-icons/17-layers.png';
import gestion from '../../assets/images/sidebar-icons/15-layers.png';
import pin from '../../assets/images/sidebar-icons/11-layers.png';
import piggy from '../../assets/images/sidebar-icons/7-layers.png';
import { isEmployee } from '../../utils/roles';
import { toggleMainNav } from '../../actions/layout/layout';
import Routes from '../../routes/routes';
import CheckRights from '../../components/access/CheckRights';
import { Entities } from '../../types/accessRights';
import { keyVal } from '../../utils/functions';
import { State } from '../../types/store';

const MainNav: FC = () => {
  const dispatch = useDispatch();
  const mainNavIsOpen = useSelector((state: State) => state.layout.mainNavIsOpen);
  const userRole = useSelector((state: State) => state.userCompanies.role.userRole);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={classnames('main-nav', keyVal('active', mainNavIsOpen))}>
        <ul>
          {/* <MainNavLink label={t('dashboard')} routeId="dashboard" imgSrc={kog} /> */}
          <CheckRights entity={Entities.pageCompany}>
            <MainNavLink
              noDisable={!isEmployee(userRole)}
              label={t('companies')}
              target={isEmployee(userRole) ? '/company' : '/company/list'}
              routeId="company"
              imgSrc={enterprise}
            />
          </CheckRights>

          <CheckRights entity={Entities.pageEmployees}>
            <MainNavLink target="/employees" routeId="staff" imgSrc={users} />
          </CheckRights>

          <CheckRights entity={Entities.pageForecast}>
            <MainNavLink routeId="forecast" imgSrc={rocket} />
          </CheckRights>

          <CheckRights entity={Entities.pageContacts}>
            <MainNavLink target={Routes.Contacts.home} routeId="contacts" imgSrc={globe} />
          </CheckRights>

          <CheckRights entity={Entities.pageArticles}>
            <MainNavLink label={t('articlesShowTitle')} routeId="articles" imgSrc={file} />
          </CheckRights>

          <CheckRights entity={Entities.pageBusiness}>
            <MainNavLink routeId="business" imgSrc={gestion} />
          </CheckRights>

          <CheckRights entity={Entities.pageSubscriptions}>
            <MainNavLink routeId="subscriptions" imgSrc={pin} />
          </CheckRights>

          <CheckRights entity={Entities.pageFinances}>
            <MainNavLink routeId="payments" imgSrc={piggy} target={Routes.Finances.home} />
          </CheckRights>

          {/* <CheckRights entity={Entities.pageAnalysis}>
               <MainNavLink label={t('analysis')} routeId="analysis" imgSrc={dashboard} />
          </CheckRights>
          <MainNavLink label={t('statistics')} routeId="statistics" imgSrc={chartPie} /> */}
        </ul>
      </div>
      <div
        className={classnames('toggle-bar', keyVal('active', mainNavIsOpen))}
        onClick={() => dispatch(toggleMainNav())}
      >
        <Icon name={mainNavIsOpen ? 'angle left' : 'angle right'} />
      </div>
    </React.Fragment>
  );
};

export default MainNav;
