import { toast } from 'components';
import i18n from 'i18n';
import fetch from '../../utils/fetch';

export function error(error) {
  return {
    type: 'DOCUMENT_LIST_ERROR', error,
  };
}

export function loading(loading) {
  return {
    type: 'DOCUMENT_LIST_LOADING', loading,
  };
}

export function success(data) {
  return {
    type: 'DOCUMENT_LIST_SUCCESS', data,
  };
}

export function errorSecond(error) {
  return {
    type: 'DOCUMENT_SECOND_LIST_ERROR', error,
  };
}

export function loadingSecond(loading) {
  return {
    type: 'DOCUMENT_SECOND_LIST_LOADING', loading,
  };
}

export function successSecond(data) {
  return {
    type: 'DOCUMENT_SECOND_LIST_SUCCESS', data,
  };
}

export function list(page = '/company/documents', comparison = false) {
  return (dispatch) => {
    if (comparison) {
      dispatch(loadingSecond(true));
      dispatch(errorSecond(''));
    } else {
      dispatch(loading(true));
      dispatch(error(''));
    }
    fetch(page)
      .then(response => response.json())
      .then((data) => {
        if (comparison) {
          dispatch(loadingSecond(false));
          dispatch(successSecond(data));
        } else {
          dispatch(loading(false));
          dispatch(success(data));
        }
      })
      .catch((e) => {
        toast.error(i18n.t('toastError'));
        if (comparison) {
          dispatch(loadingSecond(false));
          dispatch(errorSecond(e.message));
        } else {
          dispatch(loading(false));
          dispatch(error(e.message));
        }
      });
  };
}

export function reset() {
  return {
    type: 'DOCUMENT_LIST_RESET',
  };
}
