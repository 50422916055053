import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Budget } from '../../types/store';

interface OwnProps {
  budget: Budget;
  createModal: (budget: Budget, edit: boolean) => void;
}

type Props = OwnProps;

const BudgetNotFoundRow: FC<Props> = (/* { budget, createModal } */) => {
  const { t } = useTranslation();

  return (
    <Table.Row key="noFound">
      <Table.Cell colSpan="7">{t('formNoResult')}</Table.Cell>
      <Table.Cell textAlign="center" className="b-r-1">
        {/* does not open the modal as intended
        <CheckRights entity={Entities.budget} right={crudRights.create}>
          <CreateButton onClick={(): void => createModal(budget, false)} />
        </CheckRights>
        */}
      </Table.Cell>
    </Table.Row>
  );
};

export default BudgetNotFoundRow;
