import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { list, reset } from 'actions/supplier/list';
import { isEmpty } from 'lodash';
import Routes from 'routes/admin/contacts/routes';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { useDispatch, useSelector } from 'react-redux';
import useCompany from 'hooks/useCompany';
import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import CreateSupplier from './create';
import HomeSupplier from './home';
import ViewSupplier from './view';

const Supplier = () => {
  const data = useSelector(state => state.supplier.list.data);
  const loading = useSelector(state => state.supplier.list.loading);
  const updated = useSelector(state => state.supplier.update.updated);
  const created = useSelector(state => state.supplier.create.created);
  const [, company] = useCompany();
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(list(`/suppliers?company=${company['@id']}`));
      return () => {
        dispatch(reset());
      };
    },
    [updated, created, company],
  );

  if (loading || isEmpty(data)) {
    return <ContainerGeneral status={loading} />;
  }

  return (
    <Switch>
      <CheckRoute
        exact
        path={Routes.listSuppliers}
        component={HomeSupplier}
        entity={Entities.supplier}
        right={crudRights.read}
      />
      <CheckRoute
        exact
        path={Routes.createSupplier}
        component={CreateSupplier}
        entity={Entities.supplier}
        right={crudRights.create}
      />
      <CheckRoute
        path={Routes.showSupplier}
        component={ViewSupplier}
        entity={Entities.supplier}
        right={crudRights.read}
      />
      <RouteNotFound />
    </Switch>
  );
};

export default Supplier;
