import React, { FunctionComponent } from 'react';
import { capitalize } from 'lodash';
import { DatePeriod } from '../../types/dto';

interface OwnProps {
  date: DatePeriod;
}

type Props = OwnProps;

const MonthAndYear: FunctionComponent<Props> = ({ date }) => {
  let startFormat = 'MMMM YYYY';
  let endFormat = 'MMMM YYYY';
  if (date.start.format(startFormat) === date.end.format(startFormat)) {
    endFormat = '';
  } else if (date.start.year() === date.end.year()) {
    startFormat = 'MMMM';
    endFormat = ` - ${endFormat}`;
  }

  return (
    <React.Fragment>
      {date.start && capitalize(date.start.format(startFormat))}
      {date.end && endFormat !== '' && capitalize(date.end.format(endFormat))}
    </React.Fragment>
  );
};

export default MonthAndYear;
