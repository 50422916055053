import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';

const ForecastSeparatorRow: FC = () => (
  <Table.Row>
    <Table.Cell colSpan="4" />
  </Table.Row>
);

export default ForecastSeparatorRow;
