import React, { FC, ReactNode } from 'react';
import { Input, Label, StrictInputProps } from 'semantic-ui-react';
import InlineField from './InlineField';
import { Translation } from '../hooks/useTrans';

interface OwnProps extends StrictInputProps {
  children?: ReactNode;
  error?: boolean;
  label?: string;
  title: Translation;
}

type Props = OwnProps;

const InlineInput: FC<Props> = ({ children, error, label, title, ...others }) => (
  <InlineField error={error}>
    <label>{title}</label>
    <Input labelPosition={label ? 'right' : undefined} {...others}>
      {children}
      {label && <Label>{label}</Label>}
    </Input>
  </InlineField>
);

export default InlineInput;
