import React, { Component } from 'react';
import { Header, Form, Dropdown, Grid, Message, Input } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { list as getListArticles } from 'actions/article/list';
import { EssorButton } from 'components';
import Cleave from 'cleave.js/react';
import moment from 'moment';

class PriceAverage extends Component {
  state = {
    listArticle: null,
    selectedItem: null,
    firstPeriodFrom: null,
    firstPeriodTo: null,
    secondPeriodFrom: null,
    secondPeriodTo: null,
    selectedItemError: false,
    firstPeriodFromError: false,
    firstPeriodToError: false,
    secondPeriodFromError: false,
    secondPeriodToError: false,
    compare: false,
    arrayError: [],
  };

  componentDidMount() {
    const { getListArticles, selectedCompany } = this.props;
    getListArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listArticle) && nextProps.listArticle !== prevState.listArticle) {
      return {
        listArticle: nextProps.listArticle,
      };
    }

    return null;
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isValid = true;

    this.setState({
      firstPeriodFromError: false,
      firstPeriodToError: false,
      secondPeriodFromError: false,
      secondPeriodToError: false,
    });

    if (value.length < 7) {
      isValid = false;
    }

    this.setState({
      [name]: value,
      [`${name}Error`]: !isValid,
    }, () => {
      this.autoCompleteDate();
    });
  };

  autoCompleteDate = () => {
    const { secondPeriodFrom, firstPeriodTo, firstPeriodFrom } = this.state;

    if (secondPeriodFrom && firstPeriodTo && firstPeriodFrom && firstPeriodTo.length === 7
      && secondPeriodFrom.length === 7 && firstPeriodFrom.length === 7) {
      const firstFrom = this.parseDate(firstPeriodFrom);
      const firstTo = this.parseDate(firstPeriodTo);
      const secondFrom = this.parseDate(secondPeriodFrom);

      const x = firstTo.diff(firstFrom, 'month');

      const dateFinal = secondFrom.add(x, 'M');

      this.setState({
        secondPeriodTo: moment(dateFinal).format('MM/YYYY'),
      });
    }
  };

  handleCheckBoxChange = (e, { name }) => {
    this.setState(prevState => ({
      [name]: !prevState[name],
      secondPeriodFromError: false,
      secondPeriodToError: false,
      secondPeriodFrom: null,
      secondPeriodTo: null,
    }));
  };

  handleSelectChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  parseDate = (date) => {
    const arr = date.split('/');
    return moment([parseInt(arr[1], 10), (parseInt(arr[0], 10) - 1)]);
  };

  handleOnSubmit = () => {
    const {
      selectedItem,
      firstPeriodFrom,
      firstPeriodTo,
      secondPeriodFrom,
      secondPeriodTo,
      compare,
    } = this.state;

    this.setState({
      selectedItemError: false,
      firstPeriodFromError: false,
      firstPeriodToError: false,
      secondPeriodFromError: false,
      secondPeriodToError: false,
      arrayError: [],
    });

    let validDates = true;

    if (!selectedItem) {
      validDates = false;

      this.setState({
        selectedItemError: true,
      });
    }

    if (firstPeriodFrom === '' || firstPeriodFrom === null) {
      validDates = false;
      this.setState({
        firstPeriodFromError: true,
      });
    }

    if (firstPeriodTo === '' || firstPeriodTo === null) {
      validDates = false;
      this.setState({
        firstPeriodToError: true,
      });
    }

    if (compare) {
      if (secondPeriodFrom === '' || secondPeriodFrom === null) {
        validDates = false;
        this.setState({
          secondPeriodFromError: true,
        });
      }
    }

    if (!validDates) return;

    const arrayError = [];
    let isValidPeriod1 = true;
    let isValidPeriods = true;

    const firstFrom = this.parseDate(firstPeriodFrom);
    const firstTo = this.parseDate(firstPeriodTo);
    const secondFrom = this.parseDate(secondPeriodFrom);
    const secondTo = this.parseDate(secondPeriodTo);

    if (firstFrom.isAfter(firstTo, 'month')) {
      arrayError.push('priceAverageFirstPeriodError1');
      isValidPeriod1 = false;
    } else if (firstFrom.isSame(firstTo, 'month')) {
      arrayError.push('priceAverageFirstPeriodError2');
      isValidPeriod1 = false;
    }

    if (!isValidPeriod1) {
      this.setState({
        firstPeriodFromError: true,
        firstPeriodToError: true,
      });
    }

    if (compare && isValidPeriod1) {
      if (secondFrom.isBetween(firstFrom, firstTo, 'month')
        || secondTo.isBetween(firstFrom, firstTo, 'month')
        || firstFrom.isBetween(secondFrom, secondTo, 'month')) {
        arrayError.push('priceAverageAllPeriodError');
        isValidPeriods = false;
      }

      if (!isValidPeriods) {
        this.setState({
          secondPeriodFromError: true,
        });
      }
    }

    if (!isValidPeriod1 || !isValidPeriods) {
      this.setState({
        arrayError,
      });
      return;
    }

    // eslint-disable-next-line
    const obj = {
      date1: firstPeriodFrom,
      date2: firstPeriodTo,
      date3: secondPeriodFrom,
      date4: secondPeriodTo,
    };

    // console.log('obj', obj);
  };

  render() {
    const { t } = this.props;

    const {
      listArticle,
      selectedItem,
      loadingListArticle,
      selectedItemError,
      firstPeriodFromError,
      firstPeriodToError,
      secondPeriodFromError,
      secondPeriodToError,
      secondPeriodTo,
      arrayError,
      compare,
    } = this.state;
    let articles = [];

    if (!isEmpty(listArticle)) {
      articles = listArticle.map((item, i) => ({
        key: i,
        text: item.label,
        value: JSON.stringify(item),
      }));
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('priceAverageTitle')}
            </Header>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" size="small">
                  <Form.Group inline>
                    <Form.Field className="multiple-inputs">
                      <label>
                        {t('Select period')}
                      </label>
                      <Input
                        error={firstPeriodFromError}
                      >
                        <Cleave
                          options={{
                            date: true,
                            numeralThousandsGroupStyle: 'none',
                            datePattern: ['m', 'Y'],
                          }}
                          onChange={this.handleInputChange}
                          name="firstPeriodFrom"
                          placeholder={t('MM / YYYY')}
                          autoComplete="off"
                        />
                      </Input>
                      <label className="separator">
                        -
                      </label>
                      <Input
                        error={firstPeriodToError}
                      >
                        <Cleave
                          options={{
                            date: true,
                            numeralThousandsGroupStyle: 'none',
                            datePattern: ['m', 'Y'],
                          }}
                          onChange={this.handleInputChange}
                          name="firstPeriodTo"
                          placeholder={t('MM / YYYY')}
                          autoComplete="off"
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('Compare')}
                      name="compare"
                      checked={compare}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  {compare
                  && (
                    <Form.Group inline>
                      <Form.Field className="multiple-inputs">
                        <label>
                          {t('Select period')}
                        </label>
                        <Input
                          style={{
                            zIndex: 100,
                          }}
                          error={secondPeriodFromError}
                        >
                          <Cleave
                            options={{
                              date: true,
                              numeralThousandsGroupStyle: 'none',
                              datePattern: ['m', 'Y'],
                            }}
                            onChange={this.handleInputChange}
                            name="secondPeriodFrom"
                            placeholder={t('MM / YYYY')}
                            autoComplete="off"
                          />
                        </Input>
                        <label className="separator">
                          -
                        </label>
                        <Input
                          style={{
                            opacity: 1,
                          }}
                          error={secondPeriodToError}
                          disabled
                          name="secondPeriodTo"
                          placeholder={t('MM / YYYY')}
                          value={secondPeriodTo || ''}
                        />
                      </Form.Field>
                    </Form.Group>
                  )}

                  <Form.Group inline>
                    <Form.Select
                      label={t('formItems')}
                      control={Dropdown}
                      placeholder={t('formPHSelect')}
                      selectOnBlur={false}
                      search
                      selection
                      loading={loadingListArticle}
                      noResultsMessage="No results"
                      options={articles}
                      disabled={loadingListArticle}
                      name="selectedItem"
                      value={selectedItem}
                      onChange={this.handleSelectChange}
                      error={selectedItemError}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="option-buttons-container clearfix">
            <EssorButton
              type="check"
              submit
              onClick={this.handleOnSubmit}
              size="small"
              floated="right"
              disabled={
                firstPeriodFromError || firstPeriodToError
                || secondPeriodFromError || secondPeriodToError
              }
            >
              {t('buttonSearch')}
            </EssorButton>
          </div>
          {
            !isEmpty(arrayError)
            && (
              <Message negative>
                <Message.Header>{t('priceAverageHeaderPeriodError')}</Message.Header>
                <Message.List>
                  {
                    arrayError.map((error, i) => (
                      <Message.Item key={i}>{t(`${error}`)}</Message.Item>
                    ))
                  }
                </Message.List>
              </Message>
            )
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getListArticles: api => dispatch(getListArticles(api)),
});

const mapStateToProps = state => ({
  listArticle: state.article.list.data,
  loadingListArticle: state.article.list.loading,
  errorListArticles: state.article.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(PriceAverage);

export default withTranslation()(withRouter(Main));
