import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { list as getListRole, reset as resetListRole } from 'actions/role/list';
import { Switch } from 'react-router-dom';

import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import ContainerGeneral from 'layouts/ContainerGeneral';
import RoleManagementCreate from './create';
import RoleManagementHome from './home';
import RoleManagementShow from './show';

class Role extends React.Component {
  componentDidMount() {
    const { getListRole, selectedCompany } = this.props;
    getListRole(`/roles?company=${selectedCompany['@id']}`);
  }

  componentDidUpdate(prevProps) {
    const { updated, created, getListRole, selectedCompany } = this.props;

    if (
      (!isEmpty(updated) && updated !== prevProps.updated)
      || (!isEmpty(created) && created !== prevProps.created)
    ) {
      getListRole(`/roles?company=${selectedCompany['@id']}`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { listRole, loadingListRole } = this.props;

    if (loadingListRole || isEmpty(listRole)) {
      return <ContainerGeneral status={loadingListRole} />;
    }

    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.role}
          right={crudRights.create}
          path="/company/settings/roles/create"
          component={RoleManagementCreate}
        />
        <CheckRoute
          exact
          entity={Entities.role}
          right={crudRights.update}
          path="/company/settings/roles/:id/edit"
          component={RoleManagementCreate}
        />
        <CheckRoute
          exact
          entity={Entities.role}
          path="/company/settings/roles/:id"
          component={RoleManagementShow}
        />
        <CheckRoute
          exact
          entity={Entities.role}
          path="/company/settings/roles"
          component={RoleManagementHome}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getListRole: api => dispatch(getListRole(api)),
  reset() {
    dispatch(resetListRole());
  },
});

const mapStateToProps = state => ({
  listRole: state.role.list.data,
  loadingListRole: state.role.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,

  updated: state.role.update.updated,
  created: state.role.create.created,
});

export default connect(mapStateToProps, mapDispatchToProps)(Role);
