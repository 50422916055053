import React, { FunctionComponent, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import TreasuryRow from './TreasuryRow';
import TurnoverDetails from './TurnoverDetails';
import { MonthlyData } from './types';

interface OwnProps {
  total: number;
}

type Props = OwnProps;

const MonthlyTurnoverRow: FunctionComponent<Props> = ({ total }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Moment>(moment());
  const [dateEnd, setDateEnd] = useState<Moment>(moment());

  const details = (o: string, t: string, month: MonthlyData, nextMonth: MonthlyData): void => {
    setDateStart(month.date);
    setDateEnd(nextMonth.date);
    setOpen(true);
  };

  return (
    <TreasuryRow
      label={t('treasuryCollectedVat')}
      monthKey="monthCollectedVat"
      total={total}
      onClick={details}
    >
      <TurnoverDetails
        open={open}
        close={(): void => setOpen(false)}
        dateStart={dateStart}
        dateEnd={moment(dateEnd).subtract(1, 'day')}
        display="vat"
        label={t('treasuryCollectedVat')}
      />
    </TreasuryRow>
  );
};

export default MonthlyTurnoverRow;
