import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { findIndex, isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listInvoices, reset as resetInvoiceList, success as setInvoiceList } from 'actions/invoice/list';
import Routes from 'routes/admin/business/routes';
import RouteNotFound from 'routes/RouteNotFound';
import CheckRoute from 'components/access/CheckRoute';
import { crudRights, Entities } from 'types/accessRights';
import ListInvoice from './list';
import CreateInvoice from './edit';
import ShowInvoice from './show';

class Invoice extends Component {
  componentDidMount() {
    const { getInvoices, selectedCompany, selectDocument, selectedFiscalYear } = this.props;

    let limit = '';
    if (selectedFiscalYear) {
      limit += `&creationDate[after]=${selectedFiscalYear.dateStart}`;
      limit += `&creationDate[before]=${selectedFiscalYear.dateEnd}`;
    }

    selectDocument(null);
    getInvoices(`/invoices?company=${selectedCompany.id}&pagination=true${limit}`);
  }

  componentDidUpdate(prevProps) {
    const {
      updatedInvoice,
      changedInvoice,
      listInvoice,
      selectedDocument,
      setInvoiceList,
      history,
    } = this.props;

    if (!isEmpty(changedInvoice)
      && changedInvoice !== prevProps.changedInvoice
      && changedInvoice['@id'] !== selectedDocument['@id']) {
      if (changedInvoice['@type'] === selectedDocument['@type']) {
        listInvoice['hydra:member'].push(changedInvoice);
        listInvoice['hydra:totalItems'] += 1;
        setInvoiceList(listInvoice);
      }

      history.push(`/business${changedInvoice['@id']}/edit`);
    }

    if (!isEmpty(updatedInvoice) && updatedInvoice !== prevProps.updatedInvoice) {
      const index = findIndex(listInvoice['hydra:member'], {
        '@id': updatedInvoice['@id'],
      });

      listInvoice['hydra:member'][index] = updatedInvoice;
      setInvoiceList(listInvoice);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    return (
      <Switch>
        <CheckRoute
          exact
          entity={Entities.invoice}
          path={Routes.listInvoices}
          component={ListInvoice}
        />
        <CheckRoute
          exact
          entity={Entities.invoice}
          right={crudRights.create}
          path={Routes.createInvoice}
          key="create"
          component={CreateInvoice}
        />
        <CheckRoute
          entity={Entities.invoice}
          right={crudRights.update}
          path={Routes.editInvoice}
          render={props => (<CreateInvoice key={props.match.params.id} {...props} />)}
        />
        <CheckRoute
          entity={Entities.creditNote}
          right={crudRights.update}
          exact
          path={Routes.creditNote}
          render={props => (<CreateInvoice key={props.match.params.id} {...props} />)}
        />
        <CheckRoute
          entity={Entities.receipt}
          right={crudRights.update}
          exact
          path={Routes.receipt}
          render={props => (<CreateInvoice key={props.match.params.id} {...props} />)}
        />
        <CheckRoute
          exact
          entity={Entities.invoice}
          path={Routes.showInvoices}
          component={ShowInvoice}
        />
        <RouteNotFound />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getInvoices: page => dispatch(listInvoices(page)),
  setInvoiceList: quotes => dispatch(setInvoiceList(quotes)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset: () => {
    dispatch(resetInvoiceList());
  },
});

const mapStateToProps = state => ({
  listInvoice: state.invoice.list.data,
  loadingInvoice: state.invoice.list.loading,
  errorInvoice: state.invoice.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  changedInvoice: state.invoice.change.changed,
  updatedInvoice: state.invoice.update.updated,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Invoice);

export default withRouter(Main);
