import React, { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import LiWrapper from './LiWrapper';
import { SubNavContext } from './SubNav';
import { CheckAccessRights } from '../../types/store';
import useAccessRights from '../../hooks/useAccessRights';
import SubNavSeparator from './SubNavSeparator';
import { crudRights } from '../../types/accessRights';
import { Route } from '../../routes/routes';

interface OwnProps extends CheckAccessRights {
  children?: React.ReactNode;
  className?: string;
  hide?: boolean;
  noLi?: boolean;
  to: Route;
  separator?: boolean;
  title?: string;
}

type Props = OwnProps;

interface LiWrapper {
  child: Element;
}

/**
 * Allow entity/right check on the whole component
 */
const SubNavLink: FC<Props> = (props) => {
  const {
    children,
    className,
    hide,
    noLi = false,
    to,
    entity,
    right,
    title,
    separator = false,
  } = props;

  const hasAccess = entity ? useAccessRights(entity, (right || crudRights.read)) : true;

  if (hide || !hasAccess) {
    return <React.Fragment />;
  }

  const context = useContext(SubNavContext);
  const { prefix } = context;

  return (
    <React.Fragment>
      {separator && (<SubNavSeparator />)}
      <LiWrapper noLi={noLi}>
        <NavLink
          to={`${prefix}${to}`}
          activeClassName="active"
          className={className || 'menu-item'}
        >
          {title || children}
        </NavLink>
      </LiWrapper>
    </React.Fragment>
  );
};

export default SubNavLink;
