/* eslint-disable */
import React, { FunctionComponent, SyntheticEvent } from 'react';
import { isEmpty, isNumber } from 'lodash';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ActivityDropdown from './ActivityDropdown';
import { Article } from '../../types/document';

interface ActivityBaseValue {
  id: number;
  label: string;
}

export interface MainActivity extends ActivityBaseValue {
  items: ActivityBaseValue[];
}

interface OwnProps {
  activityFormatData: MainActivity[];
  editable: boolean;
  handleAddition: (e: SyntheticEvent, d: DropdownProps) => void;
  handleChangeSelectItem: (e: SyntheticEvent, d: DropdownProps) => void;
  item: Article;
}

type Props = OwnProps;

const ArticleActivities: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    activityFormatData,
    editable,
    handleAddition,
    handleChangeSelectItem,
    item,
  } = props;

  let activityFirstOption: DropdownItemProps[] = [];
  let secondOption: DropdownItemProps[] = [];

  if (!isEmpty(activityFormatData)) {
    activityFirstOption = activityFormatData.map((e: ActivityBaseValue) => ({
      key: e.id,
      text: e.label,
      value: e.id,
    }));
  }

  if (isNumber(item.activity1)
    && !isEmpty(activityFormatData)
    && item.activity1 >= 0
    && activityFormatData[item.activity1] !== undefined) {
    secondOption = activityFormatData[item.activity1].items.map((e: ActivityBaseValue) => ({
      key: e.id,
      text: e.label,
      value: e.id,
    }));
  }

  return (
    <React.Fragment>
      <ActivityDropdown
        editable={editable}
        label={t('quoteActivity')}
        name="activity1"
        options={activityFirstOption}
        value={item.activity1}
        onAddItem={handleAddition}
        onChange={handleChangeSelectItem}
      />
      <ActivityDropdown
        editable={editable}
        label={t('quoteSubActivity')}
        name="activity2"
        options={secondOption}
        value={item.activity2}
        onAddItem={handleAddition}
        onChange={handleChangeSelectItem}
      />
    </React.Fragment>
  );
};

export default ArticleActivities;
