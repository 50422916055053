import { HydraList } from '../types/entity';

export interface AppendAction<T> {
  append: boolean;
  data: HydraList<T>;
  type: string;
}

export function appendIfNeeded<T>(state: HydraList<T>, action: AppendAction<T>): HydraList<T> {
  if (action.append && state['hydra:member']) {
    const members = state['hydra:member'];
    return {
      ...state,
      'hydra:member': [
        ...members,
        ...action.data['hydra:member'],
      ],
    };
  }
  return action.data;
}
