import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/entity';
import CellButton from './CellButton';
import { Product } from 'types/document';
import { IRI } from 'types/brand';

interface OwnProps {
  product: Product & Entity & { stockManagement: boolean};
  onClick: (productIRI: IRI) => void;
}

type Props = OwnProps;

const StockCellButton: FunctionComponent<Props> = ({ product, onClick }) => {
  const { t } = useTranslation();

  return product.stockManagement
    ? (
      <CellButton
        tip={t('stockCreateTitle')}
        icon="box"
        onClick={(): void => onClick(product['@id'])}
      />
    )
    : <Table.Cell className="b-r-1" />;
};

export default StockCellButton;
