import React, { FC, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import HeaderRow from '../../../../layouts/table/HeaderRow';
import LeftCell from '../../../../layouts/table/LeftCell';
import CenterCell from '../../../../layouts/table/CenterCell';
import RightCell from '../../../../layouts/table/RightCell';
import { MonthlyData } from './types';

interface OwnProps {
  months: MonthlyData[];
  loading?: boolean;
  banks?: DropdownItemProps[];
  selected?: number;
  handle?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}

type Props = OwnProps;

const TreasuryHeader: FC<Props> = ({ months, loading, banks, selected, handle }) => {
  const { t } = useTranslation();
  return (
    <HeaderRow textAlign="center" className="section-border-top section-border-side">
      {selected && banks && handle
        ? (
          <LeftCell className="cell-fixed-left fixed-1 select-cell">
            <Form size="small">
              <Form.Group>
                <Form.Select
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  name="selectedBank"
                  fluid
                  selection
                  loading={loading}
                  disabled={loading}
                  options={banks}
                  onChange={handle}
                  value={selected}
                  selectOnBlur={false}
                />
              </Form.Group>
            </Form>
          </LeftCell>
        )
        : <LeftCell className="cell-fixed-left fixed-1" content="&nbsp;" />
      }
      <CenterCell className="cell-fixed-left fixed-2" content={t('formType')} />
      <RightCell className="cell-fixed-left fixed-3" content={t('treasuryAnnualBudget')} />
      <RightCell className="cell-fixed-left fixed-4" content={t('treasuryBudgetGap')} />
      <RightCell className="cell-fixed-left fixed-5">{t('companiesTotal')}</RightCell>

      {!isEmpty(months) && months.map((monthData: MonthlyData, index: number): ReactNode => (
        <RightCell key={index} className="cell-month" content={monthData.label} />
      ))}
    </HeaderRow>
  );
};

export default TreasuryHeader;
