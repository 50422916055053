import React, { FC } from 'react';
import { Dropdown, DropdownItemProps, Form, FormDropdownProps, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useAccessRights from '../../hooks/useAccessRights';
import { EssorButton } from '../index';
import { crudRights } from '../../types/accessRights';

interface OwnProps extends FormDropdownProps {
  enableAutocomplete: boolean;
  disable: boolean;
  options: DropdownItemProps[];
  reset: () => void;
}

type Props = OwnProps;

const BudgetSelector: FC<Props> = (props) => {
  const { disable, enableAutocomplete, options, reset, onChange, ...other } = props;
  const { t } = useTranslation();
  const hasAccess = useAccessRights('Budget', crudRights.create);
  return (
    <Form className="margin-top-bot main-form" size="small">
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Form.Group inline>
              <Form.Select
                id="select-account"
                label={t('formAccount')}
                placeholder={t('formPHSelect')}
                noResultsMessage={t('formNoResult')}
                additionLabel={t('budgetAddAccount')}
                control={Dropdown}
                selectOnBlur={false}
                maxLength={9}
                fluid
                selection
                loading={disable}
                disabled={disable}
                allowAdditions={enableAutocomplete && hasAccess}
                options={options}
                onChange={hasAccess ? onChange : undefined}
                {...other}
              />
            </Form.Group>
          </Grid.Column>

          <Grid.Column width={4}>
            <Form.Group>
              <Form.Field>
                <EssorButton size="small" type="close" secondary onClick={reset}>
                  {t('buttonReset')}
                </EssorButton>
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default BudgetSelector;
