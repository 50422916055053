/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { escapeRegExp, find, findIndex, isEmpty, last, padEnd, sortBy } from 'lodash';
import papa from 'papaparse';
import { Big } from 'utils/functions';
import { list as listBudget, listPrev as listPrevBudget, resetActual as resetActualBudget, resetPrev as resetPreviousBudget } from 'actions/budget/list';
import { create as createBudget, error as errorCreateBudget, loading as loadingCreateBudget, success as successBudget } from 'actions/budget/create';
import { del as deleteBudget, error as errorDeleteBudget, loading as loadingDeleteBudget, success as successDeleteBudget } from 'actions/budget/delete';
import { reset as resetUpdateBudget, update as updateBudget } from 'actions/budget/update';
import { list as listSelfFinancing, reset as resetSelfFinancing } from 'actions/self-financing/list';
import { reset as resetFiscalYearUpdate, update as updateFiscalYear } from 'actions/fiscal-year/update';
import { reset as resetFiscalYear, retrieve as retrieveFiscalYear } from 'actions/fiscal-year/show';
import { Button, Form, Input, Label, Message, Modal, Table } from 'semantic-ui-react';
import { getCurrency, getFloatCleaveFormat, getLocalFormat, inputFormat } from 'utils/formatter';
import Cleave from 'cleave.js/react';
import { PromptModal } from 'components';
import { withTranslation } from 'react-i18next';
import csvFr from 'assets/csv/fr.csv';
import TableLoader from 'components/TableLoader';
import BudgetRow from 'components/budgets/BudgetRow';
import BudgetNotFoundRow from 'components/budgets/BudgetNotFoundRow';
import BudgetSelector from 'components/budgets/BudgetSelector';
import CurrencyCell from 'components/cell/CurrencyCell';
import RealizedResultCell from 'routes/admin/forecast/budgets/RealizedResultCell';
import RightCell from 'layouts/table/RightCell';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import CenterCell from 'layouts/table/CenterCell';
import CleaveInit from 'components/input/CleaveInit';
import EditCellButton from 'components/buttons/EditCellButton';
import DeleteCellButton from 'components/buttons/DeleteCellButton';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import ModalButtons from 'components/ModalButtons';

class ShowBudgets extends Component {
  state = {
    createBudgetModalOpen: false,
    results: {},
    selectedAccount: null,
    loadingAccounts: true,
    actualBudgetData: [],
    previousBudgetData: null,
    previousFilteredBudget: null,
    isBudgetEdit: null,

    budgetToDelete: null,

    account: '',
    budgetLabel: '',
    type: null,
    realised: '',
    planned: '',
    percentage: 0,
    vat: '',
    vatTemporal: '',
    lastSuccessRealizedResult: '',
    realizedResult: '',

    allocated: false,
    accountError: false,
    budgetLabelError: false,
    typeError: false,
    realisedError: false,
    plannedError: false,
    vatError: false,
    realizedResultError: false,

    accountsData: [],
    actualBudgetDataTemp: [],
    accountsDataFilter: [],

    enableAutocomplete: false,
    valueInputSelectAccount: '',
    enterSearchAccount: false,
    compareRealizedResult: false,

    breakdown: [],
    unallocated: 0,
    label: '',
    amount: '',

    labelError: false,
    amountError: false,

    hasDataChanged: false,
    loadedAccountsData: true,
    createBudgetItem: '',

    plannedMessage: false,
    breakDownTotalMessage: false,
    modalDataVerify: false,
    changeInputBudget: false,
  };

  componentDidMount() {
    const {
      getBudgets,
      getSelfFinancing,
      getPreviousFiscalYear,
      selectedFiscalYear,
      selectedCompany,
    } = this.props;

    getBudgets(`/budgets?fiscalYear=${selectedFiscalYear.id}&pagination=false`);
    getSelfFinancing(`/self_financings?fiscalYear=${selectedFiscalYear.id}`);
    getPreviousFiscalYear(`/fiscal_years?company=${selectedCompany.id}&dateEnd[before]=${selectedFiscalYear.dateStart}&pagination=false`);

    papa.parse(csvFr, {
      delimiter: ';',
      download: true,
      complete: (results) => {
        this.setState({
          results,
          loadingAccounts: false,
        });
      },
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.selectedFiscalYear)
      && prevState.realizedResult !== nextProps.selectedFiscalYear.realizedResult
      && !prevState.compareRealizedResult
    ) {
      return {
        lastSuccessRealizedResult: parseInt(nextProps.selectedFiscalYear.realizedResult),
        realizedResult: parseInt(nextProps.selectedFiscalYear.realizedResult),
      };
    }

    if (!isEmpty(nextProps.dataSelfFinancing) && prevState.selfFinancingData !== nextProps.dataSelfFinancing['hydra:member']) {
      return {
        selfFinancingData: nextProps.dataSelfFinancing['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.dataPrevBudget) && nextProps.dataPrevBudget['hydra:member'] !== prevState.previousBudgetData) {
      return {
        previousBudgetData: nextProps.dataPrevBudget['hydra:member'],
      };
    }

    if (prevState.actualBudgetDataTemp !== nextProps.dataBudget['hydra:member'] && !isEmpty(nextProps.dataBudget['hydra:member']) && !prevState.isActualBudgetLoaded) {
      const accountFormat = (account) => {
        if (account.length !== 9) {
          account = padEnd(account, 9, '0');
        }

        const one = account.slice(0, 3);
        const two = account.slice(3, 6);
        const three = account.slice(6, 9);

        return `${one}.${two}.${three}`;
      };

      const accountFormatDataBudget = nextProps.dataBudget['hydra:member'].map((e) => {
        const item = e;
        if (!item.account.includes('.')) {
          item.account = accountFormat(item.account);
        }
        return item;
      });

      return {
        actualBudgetData: accountFormatDataBudget,
        actualBudgetDataTemp: nextProps.dataBudget['hydra:member'],
        isActualBudgetLoaded: true,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { createBudgetModalOpen, actualBudgetData, loadedAccountsData } = this.state;
    const {
      retrievedFiscalYear,
      getPrevBudgets,
      updatedBudget,
      successBudget,
    } = this.props;

    if (!isEmpty(retrievedFiscalYear) && retrievedFiscalYear !== prevProps.retrievedFiscalYear) {
      if (retrievedFiscalYear['hydra:member'].length > 0) {
        const data = retrievedFiscalYear['hydra:member'];
        const previousFiscalYear = data[data.length - 1];

        getPrevBudgets(`/budgets?fiscalYear=${previousFiscalYear.id}`);
      }
    }

    if (prevState.createBudgetModalOpen !== createBudgetModalOpen) {
      this.removeNewBudget();
    }

    if (
      !isEmpty(actualBudgetData)
      && prevState.actualBudgetData !== actualBudgetData
      && loadedAccountsData
    ) {
      this.addActualBudgetOnAccounts();
    }

    if (!isEmpty(updatedBudget) && updatedBudget !== prevProps.updatedBudget) {
      this.updateFormatBudget();
    }

    if (!isEmpty(successBudget) && successBudget !== prevProps.successBudget) {
      this.addBudgetAfterSubmit();
    }

    this.filterAccountData();
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  addBudgetAfterSubmit = () => {
    const { accountsDataFilter, actualBudgetData } = this.state;
    const { successBudget } = this.props;
    accountsDataFilter[0].text = `${this.accountFormat(successBudget.account)} - ${successBudget.label}`;
    accountsDataFilter[0].value = JSON.stringify({
      account: this.accountFormat(successBudget.account),
      label: `${this.accountFormat(successBudget.account)} - ${successBudget.label}`,
    });
    successBudget.account = this.accountFormat(successBudget.account);
    actualBudgetData.push(successBudget);
    this.setState({
      selectedAccount: null,
      previousFilteredBudget: null,
      account: '',
      budgetLabel: '',
      type: null,
      realised: '',
      planned: '',
      vat: '',
      isActualBudgetLoaded: false,
      createBudgetModalOpen: false,
      actualBudgetData,
      accountsDataFilter,
      createBudgetItem: '',
    });

    this.resetBudgetList();
  };

  updateFormatBudget = () => {
    const { accountsDataFilter, actualBudgetData } = this.state;
    const { updatedBudget } = this.props;
    const indexBg = findIndex(actualBudgetData, {
      '@id': updatedBudget['@id'],
    });

    updatedBudget.account = this.accountFormat(updatedBudget.account);
    const indexFilterData = findIndex(accountsDataFilter, {
      key: updatedBudget.account,
    });

    accountsDataFilter[indexFilterData].text = `${updatedBudget.account} - ${updatedBudget.label}`;
    accountsDataFilter[indexFilterData].value = `{"account": "${updatedBudget.account}", "label": "${updatedBudget.label}"}`;
    actualBudgetData[indexBg] = updatedBudget;

    this.setState({
      selectedAccount: null,
      previousFilteredBudget: null,
      account: '',
      budgetLabel: '',
      type: null,
      realised: '',
      planned: '',
      vat: '',
      isActualBudgetLoaded: false,
      createBudgetModalOpen: false,
      actualBudgetData,
      accountsDataFilter,
      createBudgetItem: '',
    });
  };

  addActualBudgetOnAccounts = () => {
    const { actualBudgetData, accountsDataFilter } = this.state;
    const actualBudgetFormat = [];
    actualBudgetData.forEach((e) => {
      if (isEmpty(find(accountsDataFilter, {
        key: e.account,
      }))) {
        const setAccountZero = e.account.split('.').join('').replace(/[0]+$/, '');
        actualBudgetFormat.push({
          key: e.account,
          text: `${e.account} - ${e.label}`,
          value: `{"account": "${setAccountZero}","label": "${e.label}"}`,
        });
      }
    });

    this.setState({
      loadedAccountsData: false,
      accountsDataFilter: [...accountsDataFilter, ...actualBudgetFormat],
    });
  };

  // create new array from results.data
  filterAccountData = () => {
    const { accountsData, accountsDataFilter, results } = this.state;
    if (isEmpty(accountsData) && results.data) {
      const accountsData = results.data.map(item => ({
        key: item[0].startsWith('9') ? this.accountFormat(item[0], true) : this.accountFormat(item[0]),
        text: item[0].startsWith('9') ? `${this.accountFormat(item[0], true)} - ${item[1]}` : `${this.accountFormat(item[0])} - ${item[1]}`,
        value: JSON.stringify({
          account: item[0].startsWith('9') ? this.accountFormat(item[0], true) : item[0],
          label: item[1],
        }),
      }));
      this.setState({
        accountsData,
      });
    }

    if (!isEmpty(accountsData) && isEmpty(accountsDataFilter)) {
      this.setState({
        accountsDataFilter: accountsData.filter(f => f.key.startsWith('6') || f.key.startsWith('7') || f.key.startsWith('9')),
      });
    }
  };

  submitModal = (e) => {
    if (e.ctrlKey && e.keyCode === 13) {
      this.handleBudgetSubmit();
    }
  };

  calculatePlannedBreakdown = (breakdown, planned) => {
    let budgetBreakdownTotal = Big(0);
    if (!isEmpty(breakdown)) {
      budgetBreakdownTotal = Big(breakdown.map(e => Number(e.amount)).reduce((a, b) => a + b));
    }

    const unallocated = Big(planned || 0).minus(budgetBreakdownTotal).toFixed(0);

    this.setState({
      breakDownTotalMessage: !isEmpty(breakdown) && Big(budgetBreakdownTotal).gt(planned),
      unallocated,
    });
  };

  openCreateBudgetModal = (budget, isBudgetEdit) => {
    const { vatTemporal } = this.state;
    const regex = /^[A-Za-z].*$/;
    const objectFromLabel = regex.test(budget);
    let defaultType = null;


    if (!objectFromLabel && typeof budget === 'string') {
      budget = JSON.parse(budget);
    }

    if (budget.account && (budget.account.startsWith('60')
      || budget.account.startsWith('611')
      || budget.account.startsWith('615')
      || budget.account.startsWith('617')
      || budget.account.startsWith('618')
      || budget.account.startsWith('619')
      || budget.account.startsWith('621')
      || budget.account.startsWith('622')
      || budget.account.startsWith('623')
      || budget.account.startsWith('624')
      || budget.account.startsWith('625')
      || budget.account.startsWith('626')
      || budget.account.startsWith('628')
      || budget.account.startsWith('629')
      || budget.account.startsWith('66')
      || budget.account.startsWith('67')
      || budget.account.startsWith('69'))
    ) {
      defaultType = 'CV';
    } else if (budget.account && (budget.account.startsWith('612')
      || budget.account.startsWith('613')
      || budget.account.startsWith('614')
      || budget.account.startsWith('616')
      || budget.account.startsWith('627')
      || budget.account.startsWith('63')
      || budget.account.startsWith('64')
      || budget.account.startsWith('65')
      || budget.account.startsWith('68'))
    ) {
      defaultType = 'CF';
    }

    isBudgetEdit
      ? this.calculatePlannedBreakdown(budget.breakdown, budget.planned)
      : this.calculatePlannedBreakdown([], budget.planned);

    window.addEventListener('keydown', this.submitModal);
    this.setState({
      isBudgetEdit: isBudgetEdit ? budget : null,
      account: objectFromLabel
        ? ''
        : budget.account
          ? padEnd(budget.account, 9, '0')
          : padEnd(budget, 9, '0'),
      budgetLabel: objectFromLabel
        ? budget
        : budget.label,
      type: isBudgetEdit ? budget.type : defaultType,
      realised: isBudgetEdit ? budget.realised : '',
      planned: budget.planned ? budget.planned : '',
      percentage: isBudgetEdit ? this.getPercentage(budget.realised, budget.planned) : '',
      vat: budget.vat ? budget.vat : vatTemporal,
      allocated: budget.allocated,
      breakdown: isBudgetEdit ? budget.breakdown.filter(e => isEmpty(e.isNew)) : [],
      createBudgetModalOpen: true,
      createFromLabel: objectFromLabel,
      fromLabelDisableFields: objectFromLabel,
      accountFormatError: false,
      accountError: false,
      plannedMessage: false,
      typeError: false,
      realisedError: false,
      plannedError: false,
      vatError: false,
      labelError: false,
      amountError: false,
      label: '',
      amount: '',
    });
  };

  closeCreateBudgetModal = () => {
    const { accountsDataFilter, changeInputBudget } = this.state;
    if (accountsDataFilter.filter(e => (
      e.key === 'newAcc'
    ))) {
      this.resetBudgetList();
    }

    if (changeInputBudget) {
      this.setState({
        modalDataVerify: true,
      });
    } else {
      this.setState({
        createBudgetModalOpen: false,
        account: '',
        budgetLabel: '',
        type: null,
        realised: '',
        planned: '',
        vat: '',
        allocated: false,
        breakdown: [],
        label: '',
        amount: '',
      });
    }
  };

  removeNewBudget = () => {
    const { accountsDataFilter, createBudgetItem } = this.state;
    const indexDelete = findIndex(this.accountFormatRemoveDots, {
      key: createBudgetItem.key,
    });
    delete accountsDataFilter[indexDelete];

    this.setState({
      accountsDataFilter,
      hasDataChanged: false,
      changeInputBudget: false,
    });
  };

  openDeleteBudgetModal = (budget) => {
    // {t('budgetsDeleteConfirmationMessage', {
      // account: budgetToDelete ? this.accountFormat(budgetToDelete.account) : '',
      // label: budgetToDelete ? budgetToDelete.label : '',
    // })}
    this.setState({
      budgetToDelete: budget,
    });
  };

  closeDeleteBudgetModal = () => {
    this.setState({
      budgetToDelete: null,
    });
  };

  handleDeleteBudget = () => {
    const { budgetToDelete } = this.state;
    const { deleteBudget } = this.props;

    deleteBudget(budgetToDelete).then(() => {
      // TODO UI should be refreshed using state
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  getPercentage = (realised, planned) => {
    const p = new Big(planned || 0);
    const r = new Big(realised || 0);

    return r.eq(0) ? '0.00' : (p.minus(r)).times(100).div(r).toFixed(2);
  };

  handleSelectChange = (e, obj) => {
    e.preventDefault();

    this.setState({
      [obj.name]: obj.value,
      hasDataChanged: true,
    });
  };

  completeAccountFormat = (e) => {
    const { rawValue } = e.target;

    if (rawValue !== '') {
      this.setState({
        account: padEnd(rawValue, 9, '0'),
      });
    }
  };

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleCleaveChange = (name, value) => {
    const { planned, realised, breakdown, allocated, compareRealizedResult } = this.state;
    const nonEmptyValue = value || 0;

    if (name === 'realizedResult') {
      this.setState({
        compareRealizedResult: true,
        realizedResult: nonEmptyValue,
      }, () => {
        if (compareRealizedResult) {
          window.addEventListener('keydown', this.submitRealizedResult);
        }
      });
    }

    switch (name) {
      case 'planned': {
          this.setState({
            percentage: this.getPercentage(realised, value),
          });

        if (allocated && !isEmpty(breakdown)) {
          this.calculatePlannedBreakdown(breakdown, nonEmptyValue);
        }

        break;
      }
      case 'realised':
          this.setState({
            percentage: this.getPercentage(value, planned),
          });
        break;
      default:
        break;
    }

    this.setState({
      [name]: nonEmptyValue,
      hasDataChanged: true,
    });
  };

  handleInputChange = (e, numeral = false) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name !== 'realizedResult') {
      this.setState({
        [name]: numeral ? inputFormat(value) : value,
        hasDataChanged: true,
      });
    }

    this.setState({
      [name]: numeral ? inputFormat(value) : value,
      changeInputBudget: true,
    }, () => {
      const { createFromLabel, account, budgetLabel } = this.state;
      if (createFromLabel) {
        this.setState({
          fromLabelDisableFields: account.trim() === '' || budgetLabel.trim() === '',
        });
      }
      this.setState({
        accountFormatError: account.trim() !== '' && !account.startsWith('6') && !account.startsWith('7') && !account.startsWith('9'),
        accountError: account.trim() !== '' && !account.startsWith('6') && !account.startsWith('7') && !account.startsWith('9'),
      });
    });

    if (name === 'vat') {
      this.setState({
        vatTemporal: inputFormat(value),
      });
    }
  };

  handleCheckBoxChange = (e) => {
    e.preventDefault();
    this.setState(prevState => (
      {
        allocated: !prevState.allocated,
        hasDataChanged: true,
        changeInputBudget: true,
      }
    ), () => {
      const { allocated } = this.state;
      if (!allocated) {
        this.setState({
          plannedMessage: false,
          plannedError: false,
        });
      }
    });
  };

  handleOnBlurSelect = () => {
    const { selectedAccount } = this.state;
    if (isEmpty(selectedAccount)) {
      this.resetBudgetList();
    }
  };

  handleBlurResult = () => {
    const { compareRealizedResult } = this.state;

    if (compareRealizedResult) {
      this.handleResultFiscalYearSubmit();
    }
  };

  dotsSearchInput = (accountsDataFilter, query) => {
    const re = new RegExp(escapeRegExp(query));
    return accountsDataFilter.filter((opt) => {
      const testValue = re.test(opt.key) || re.test(opt.key.split('.')
        .join('')) || re.test(opt.text.split('.').join(''));

      if (testValue) {
        this.setState({
          enableAutocomplete: false,
        });
      }
      return testValue;
    });
  };

  inputValueAccount = (e) => {
    const { accountsDataFilter, createAccountObject, selectedAccount } = this.state;
    this.setState({
      enterSearchAccount: false,
      enableAutocomplete: false,
    });

    if (isEmpty(e.target.value)) {
      this.setState({
        valueInputSelectAccount: '',
      });
      this.resetBudgetList();
    } else {
      this.setState({
        valueInputSelectAccount: e.target.value,
      });
    }

    if ((createAccountObject && ((e.target.value).length === 1)) || selectedAccount) {
      // when typing an account that does not exist, a placeholder is added
      // in the dropdown list (Add XXXX) and is added to accountDataFilter in last
      // position. After object creation we need to remove it using pop so that
      // the placeholder does not appear again
      // accountsDataFilter.pop();
      this.setState({
        createAccountObject: false,
      });
    }

    const regex = /^[A-Za-z].*$/;

    if (e.target.value.startsWith('6') || e.target.value.startsWith('7') || regex.test(e.target.value)) {
      if (isEmpty(accountsDataFilter.filter(item => item.text.includes(this.accountFormat(e.target.value)) || item.key.split('.')
        .join('')
        .includes(this.accountFormat(e.target.value))))) {
        this.setState({
          enableAutocomplete: true,
        });
      } else {
        this.setState({
          enableAutocomplete: false,
        });
      }
    }
  };

  handleAddition = (e, { value }) => {
    const { accountsDataFilter } = this.state;
    const regex = /^[A-Za-z].*$/;

    const key = !regex.test(value) ? this.accountFormat(value) : '';
    const text = !regex.test(value) ? value : `${key} - ${value}`;

    this.setState({
      accountsDataFilter: [
        {
          key,
          text,
          value: JSON.stringify({
            account: key,
            label: text,
          }),
        }, ...accountsDataFilter,
      ],
      createBudgetItem: {
        key,
        text,
        value: JSON.stringify({
          account: key,
          label: text,
        }),
      },
    });
  };

  handleAccountChange = (e, { value }) => {
    const {
      actualBudgetData,
      previousBudgetData,
      valueInputSelectAccount,
      accountsDataFilter,
    } = this.state;

    const regex = /^[A-Za-z].*$/;
    const selectedAccount = !regex.test(value)
      ? JSON.parse(value.split('.').join(''))
      : value;

    const newAccTarget = selectedAccount.account;

    let onActual = '';

    if (isEmpty(selectedAccount.account)) {
      this.setState({
        accountsDataFilter,
        createAccountObject: true,
        selectedAccount: !regex.test(e.target.value)
          ? `{"account": "${valueInputSelectAccount}","label": ""}`
          : `{"account": "","label": "${valueInputSelectAccount}"}`,
      });
    } else {
      onActual = find(actualBudgetData, {
        account: this.accountFormat(newAccTarget),
      });

      this.setState({
        selectedAccount: value,
        enterSearchAccount: true,
        createAccountObject: false,
      });
    }

    // const actualAccount = valueInputSelectAccount;

    const onPrev = find(previousBudgetData, {
      account: selectedAccount.account,
    });

    this.setState({
      previousFilteredBudget: onPrev || {},
    });

    // Open modal on mouse click or on Enter key pressed only
    if ((e.nativeEvent && e.nativeEvent.type && e.nativeEvent.type === 'click')
      || e.keyCode === 13) {
      if (!isEmpty(onActual)) {
        this.openCreateBudgetModal(onActual, true);
      } else if (isEmpty(onActual)) {
        this.openCreateBudgetModal(
          selectedAccount,
          false,
        );
      }
    }
  };

  resetBudgetList = () => {
    this.setState({
      selectedAccount: null,
      previousFilteredBudget: null,
      valueInputSelectAccount: '',
    });
  };

  submitRealizedResult = (e) => {
    const { compareRealizedResult } = this.state;
    if (e.key === 'Enter' && compareRealizedResult) {
      this.handleResultFiscalYearSubmit();
    }
  };

  handleResultFiscalYearSubmit = () => {
    const { realizedResult, lastSuccessRealizedResult } = this.state;
    const { updateFiscalYear, selectedFiscalYear } = this.props;

    let isValidResult = true;

    this.setState({
      realizedResultError: false,
    });

    /*
     * If the user didn't input anything set the
     * input back to the last good realizedResult value
     */
    if (realizedResult.trim() === '') {
      isValidResult = false;
      this.setState({
        realizedResult: lastSuccessRealizedResult,
        compareRealizedResult: true,
        realizedResultError: true,
      });
    }

    if (!isValidResult || lastSuccessRealizedResult === realizedResult) {
      return;
    }

    const data = {
      realizedResult,
    };

    // TODO: find out what this is supposed to be/do
    updateFiscalYear(selectedFiscalYear, data).then(
      this.setState({
        compareRealizedResult: true,
        lastSuccessRealizedResult: realizedResult,
      }),
    );
  };

  handleBudgetSubmit = () => {
    const {
      createFromLabel,
      account,
      budgetLabel,
      type,
      realised,
      planned,
      vat,
      allocated,
      isBudgetEdit,
      accountsDataFilter,
      enableAutocomplete,
      enterSearchAccount,
      breakdown,

      plannedMessage,
      breakDownTotalMessage,
    } = this.state;

    const {
      selectedFiscalYear,
      postBudget,
      updateBudget,
    } = this.props;

    const id = selectedFiscalYear['@id'];

    let isValid = true;

    this.setState({
      accountError: false,
      budgetLabelError: false,
      typeError: false,
      realisedError: false,
      plannedError: false,
      vatError: false,
      labelError: false,
      amountError: false,
    });

    if (!isEmpty(accountsDataFilter.filter(e => e.key.includes(account.replace(/[0]+$/, '')))) && !isEmpty(isBudgetEdit) && enterSearchAccount && enableAutocomplete) {
      isValid = false;
      this.setState({
        accountError: true,
      });
    }
    if (createFromLabel && (account.trim() === '' || (!account.startsWith('6') && !account.startsWith('7')))) {
      isValid = false;

      this.setState({
        accountError: true,
      });
    }

    if (isEmpty(budgetLabel) || budgetLabel.trim() === '') {
      isValid = false;

      this.setState({
        budgetLabelError: true,
      });
    }

    if (account.startsWith('6') && !type) {
      isValid = false;

      this.setState({
        typeError: true,
      });
    }

    if (Number.isNaN(parseFloat(realised)) || realised.trim() === '') {
      isValid = false;

      this.setState({
        realisedError: true,
      });
    }

    if (Number.isNaN(parseFloat(planned)) || planned.trim() === '') {
      if (account.charAt(0) !== '7'
        && !account.startsWith('641')
        && !account.startsWith('642')
        && !account.startsWith('643')
        && !account.startsWith('644')
        && !account.startsWith('645')
        && !account.startsWith('646')
        && !account.startsWith('69')
      ) {
        isValid = false;

        this.setState({
          plannedError: true,
        });
      }
    }

    const parsedVAT = parseFloat(vat);
    if (Number.isNaN(parsedVAT) || (parsedVAT < 0) || (parsedVAT >= 100)) {
      isValid = false;

      this.setState({
        vatError: true,
      });
    }

    if (allocated && breakdown.length === 0) {
      isValid = false;
      this.setState({
        labelError: true,
        amountError: true,
      });
    }

    if (breakDownTotalMessage) {
      isValid = false;
    }

    if (plannedMessage) {
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    const validBreakdown = breakdown.map(item => ({
      id: item.id,
      label: item.label,
      amount: item.amount,
    }));

    const data = {
      account: account.split('.').join(''),
      label: budgetLabel,
      type,
      realised,
      planned: isEmpty(planned) ? '0' : planned,
      vat: parsedVAT.toString(),
      allocated: account.charAt(0) === '7' ? false : !!allocated,
      fiscalYear: id,
      breakdown: allocated ? validBreakdown : [],
    };

    this.setState({
      hasDataChanged: false,
    });

    isBudgetEdit
      ? updateBudget(isBudgetEdit, data)
      : postBudget(data);
  };

  accountFormat = (account, unity) => {
    let one;
    let two;
    let three;

    if (unity) {
      one = account.slice(0, 1);
      two = account.slice(1, 4);
      three = account.slice(4, 7);
    } else {
      if (account.length !== 9) {
        account = padEnd(account, 9, '0');
      }
      one = account.slice(0, 3);
      two = account.slice(3, 6);
      three = account.slice(6, 9);
    }

    return `${one}.${two}.${three}`;
  };

  accountFormatRemoveDots = (value) => {
    if (value) {
      return value.split('.').join('');
    }
  };

  addBreakdown = (item = null) => {
    const { label, amount, breakdown } = this.state;

    this.setState({
      labelError: false,
      amountError: false,
    });

    let isValid = true;

    if (label.trim() === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (amount.trim() === '') {
      isValid = false;

      this.setState({
        amountError: true,
      });
    }

    if (!isValid) {
      return;
    }

    if (item) {
      const index = findIndex(breakdown, {
        id: item.id,
      });

      breakdown[index].edit = false;
      breakdown[index].title = label;
      breakdown[index].amount = amount;

      this.setState({
        breakdownItemEdit: false,
      });
    } else {
      const data = {
        id: last(breakdown) ? last(breakdown).id + 1 : 1,
        label,
        amount,
        isNew: true,
      };

      breakdown.push(data);
    }

    this.setState({
      label: '',
      amount: '',
      breakdown,
    }, () => {
      const { planned } = this.state;
      const budgetBreakdownTotal = breakdown.map(e => Number(e.amount)).reduce((a, b) => a + b);

      if (!isEmpty(breakdown) && Big(budgetBreakdownTotal).gt(planned)) {
        this.setState({
          unallocated: Big(planned).minus(budgetBreakdownTotal).toFixed(0),
          breakDownTotalMessage: true,
          plannedMessage: false,
        });
      } else {
        this.setState({
          unallocated: Big(planned).minus(budgetBreakdownTotal).toFixed(0),
          breakDownTotalMessage: false,
        });
      }
    });
  };

  editBreakDown = (item) => {
    const { breakdown } = this.state;

    const index = findIndex(breakdown, {
      id: item.id,
    });

    // eslint-disable-next-line array-callback-return
    breakdown.filter((e, breakIndex) => {
      if (breakIndex !== index) {
        e.edit = false;
      }
    });

    breakdown[index].edit = true;
    this.setState({
      label: breakdown[index].label,
      amount: breakdown[index].amount,
      breakdown,
      breakdownItemEdit: true,
    });
  };

  cancelBreakdownEdit = (item) => {
    const { breakdown } = this.state;

    const index = findIndex(breakdown, {
      id: item.id,
    });

    breakdown[index].edit = false;

    this.setState({
      label: '',
      amount: '',
      breakdown,
      breakdownItemEdit: false,
    });
  };

  deleteBreakdown = (item) => {
    const { breakdown, planned } = this.state;

    const index = findIndex(breakdown, {
      id: item.id,
    });

    breakdown.splice(index, 1);

    this.calculatePlannedBreakdown(breakdown, planned);
    this.setState({
      breakdown,
    });
  };

  onWarningClose = () => {
    this.setState({
      modalDataVerify: false,
    });
  };

  setModelContent = () => {
    const { actualBudgetData } = this.state;

    this.setState({
      createBudgetModalOpen: false,
      modalDataVerify: false,
      account: '',
      budgetLabel: '',
      type: null,
      realised: '',
      planned: '',
      vat: '',
      allocated: false,
      breakdown: [],
      label: '',
      amount: '',
      actualBudgetData,
    });
  };

  render() {
    const {
      createBudgetModalOpen,
      account,
      budgetLabel,
      type,
      realised,
      planned,
      percentage,
      vat,
      allocated,
      realizedResult,
      accountError,
      budgetLabelError,
      typeError,
      realisedError,
      plannedError,
      vatError,
      realizedResultError,

      selectedAccount,
      loadingAccounts,
      actualBudgetData,
      previousFilteredBudget,
      isBudgetEdit,

      // budgetToDelete,

      accountsDataFilter,
      enableAutocomplete,
      createFromLabel,
      fromLabelDisableFields,
      accountFormatError,

      valueInputSelectAccount,
      enterSearchAccount,

      breakdown,
      unallocated,
      label,
      amount,
      labelError,
      amountError,
      breakdownItemEdit,

      hasDataChanged,
      plannedMessage,
      breakDownTotalMessage,
      modalDataVerify,
      changeInputBudget,
    } = this.state;

    const {
      loadingFiscalYear,
      loadingSelfFinancing,
      loadingBudget,
      loadingPrevBudget,
      loadingCreateBudget,
      loadingUpdateBudget,
      // loadingDeleteBudget,
      t,
      updateLoading,
    } = this.props;

    const prevLoading = loadingFiscalYear || loadingPrevBudget;
    const typeOptions = [
      {
        key: '1',
        text: 'CV',
        value: 'CV',
      },
      {
        key: '2',
        text: 'CF',
        value: 'CF',
      },
    ];

    let chargesFixesRealised = 0;
    let chargesVariablesRealised = 0;
    let chargesFixesPlanned = 0;
    let chargesVariablesPlanned = 0;
    let totalProducts = 0;

    if (!(loadingCreateBudget || loadingUpdateBudget)) {
      if (actualBudgetData) {
        actualBudgetData.map((budget) => {
          if (budget.account.startsWith('6') && budget.type === 'CF') {
            chargesFixesRealised += parseFloat(budget.realised);
            chargesFixesPlanned += parseFloat(budget.planned);
          }

          if (budget.account.startsWith('6') && budget.type === 'CV') {
            chargesVariablesRealised += parseFloat(budget.realised);
            chargesVariablesPlanned += parseFloat(budget.planned);
          }

          if (budget.account.startsWith('7')) {
            totalProducts += parseFloat(budget.realised);
          }

          return null;
        });
      }
    }
    let filterSearchBudget = '';
    const selectedAccountFormat = JSON.parse(selectedAccount);
    let selectedAccountPad = '';
    if (selectedAccountFormat) {
      selectedAccountPad = padEnd(selectedAccountFormat.account, 9, '0');
    }
    if (!isEmpty(actualBudgetData)) {
      if ((!isEmpty(valueInputSelectAccount) && !enterSearchAccount) || selectedAccountFormat) {
        filterSearchBudget = actualBudgetData.filter(
          (e) => {
            const filterForAccount = this.accountFormatRemoveDots(e.account).includes(
              !isEmpty(selectedAccountFormat)
                ? selectedAccountPad
                : this.accountFormatRemoveDots(valueInputSelectAccount),
            );
            const filterForLabel = e.label.includes(
              !isEmpty(selectedAccountFormat)
                ? selectedAccountPad
                : this.accountFormatRemoveDots(valueInputSelectAccount),
            );
            const resultFilter = filterForAccount || filterForLabel;
            return (
              resultFilter
            );
          },
        );
      } else if (
        (!isEmpty(valueInputSelectAccount)
          && enterSearchAccount)
        || selectedAccountFormat) {
        filterSearchBudget = actualBudgetData.filter(e => (
          this.accountFormatRemoveDots(e.account) === selectedAccountFormat
            ? selectedAccountPad
            : this.accountFormatRemoveDots(valueInputSelectAccount)
        ));
      } else {
        filterSearchBudget = '';
      }
    }


    const actualBudgetDataSort = sortBy(actualBudgetData, ['account']);
    let plannedEditDisabled = '';
    if (account) {
      plannedEditDisabled = account.startsWith('7')
        || account.startsWith('641')
        || account.startsWith('642')
        || account.startsWith('643')
        || account.startsWith('644')
        || account.startsWith('645')
        || account.startsWith('646')
        || account.startsWith('69');
    }

    let totalResultRealized = 0;

    if (!isEmpty(actualBudgetData)) {
      // eslint-disable-next-line array-callback-return
      actualBudgetData.filter((e) => {
        if (e.account.startsWith('7')) {
          totalResultRealized = Number(e.realised) + totalResultRealized;
          return {
            totalResultRealized,
          };
        }
      });
    }
    const totalChargesRealised = chargesFixesRealised + chargesVariablesRealised;
    const totalResultRealised = totalProducts - totalChargesRealised;
    const totalResultGap = totalResultRealised - realizedResult;

    const loading = loadingBudget || loadingSelfFinancing;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <PromptModal
          dataUnsaved={modalDataVerify && changeInputBudget}
          setModelContent={() => this.setModelContent()}
          onWarningClose={() => this.onWarningClose()}
        />
        <TitleHeader title={t('budgetsHomeTitle')} />

        <TableLoader status={loading} table celled tableName="BudgetsRecapTable">
          <HeaderRow>
            <RightCell />
            <RightCell content={t('budgetsTableCF')} />
            <RightCell content={t('budgetsTableCV')} />
            <RightCell content={t('budgetsTableTotalCharges')} />
            <RightCell content={t('budgetsTableTotalProducts')} />
            <RightCell content={t('budgetsTableResult')} />
            <RightCell content={t('budgetsTableResultTotal')} />
            <RightCell content={t('budgetsTableDifference')} />
          </HeaderRow>

          <Table.Body>
            <Table.Row>
              <LeftCell>{t('budgetTableRealized')}</LeftCell>
              <CurrencyCell value={chargesFixesRealised} decimal={0} />
              <CurrencyCell value={chargesVariablesRealised} decimal={0} />
              <CurrencyCell value={totalChargesRealised} decimal={0} />
              <CurrencyCell value={totalResultRealized} decimal={0} />
              <CurrencyCell value={totalResultRealised} decimal={0} color noGreen />
              <RealizedResultCell
                onCleaveInit={this.onCleaveInit}
                handleBlur={this.handleBlurResult}
                handleChange={this.handleCleaveChange}
                realizedResult={realizedResult}
                disable={updateLoading}
                realizedResultError={realizedResultError}
              />
              <CurrencyCell value={totalResultGap} decimal={0} color noGreen />
            </Table.Row>

            <Table.Row>
              <Table.Cell>{t('budgetTablePredictable')}</Table.Cell>
              <CurrencyCell value={chargesFixesPlanned} decimal={0} />
              <CurrencyCell value={chargesVariablesPlanned} decimal={0} />
              <CurrencyCell value={chargesFixesPlanned + chargesVariablesPlanned} decimal={0} />
              <RightCell content="-" />
              <RightCell content="-" />
              <RightCell content="-" />
              <RightCell content="-" />
            </Table.Row>
          </Table.Body>
        </TableLoader>

        <BudgetSelector
          disable={loadingAccounts || loadingBudget || prevLoading}
          options={accountsDataFilter}
          value={selectedAccount}
          searchQuery={valueInputSelectAccount}
          enableAutocomplete={enableAutocomplete}
          search={this.dotsSearchInput}
          onBlur={this.handleOnBlurSelect}
          onSearchChange={this.inputValueAccount}
          onChange={this.handleAccountChange}
          onAddItem={this.handleAddition}
          reset={this.resetBudgetList}
        />

        <TableLoader status={loadingBudget} table celled striped tableName="BudgetsListTable">
          <HeaderRow>
            <LeftCell content={t('formAccount')} />
            <LeftCell content={t('formLabel')} />
            <RightCell content={t('formRealised')} />
            <RightCell content={t('formPlanned')} />
            <CenterCell content={t('formType')} />
            <RightCell content={t('budgetDiffPercentage')} />
            <CenterCell colSpan="3" />
          </HeaderRow>
          <Table.Body>
            {(actualBudgetData && !filterSearchBudget)
            && actualBudgetDataSort.map((budget, index) => (
              <BudgetRow
                key={index}
                budget={budget}
                index={budget.account}
                editModal={this.openCreateBudgetModal}
                deleteModal={this.openDeleteBudgetModal}
              />
            ))}

            {(selectedAccount || valueInputSelectAccount.length > 0) && (
              isEmpty(filterSearchBudget)
                ? (
                  <BudgetNotFoundRow
                    budget={isEmpty(previousFilteredBudget)
                      ? selectedAccount
                      : previousFilteredBudget
                    }
                    createModal={this.openCreateBudgetModal}
                  />
                )
                : (
                  filterSearchBudget.map((budget, index) => (
                    <BudgetRow
                      budget={budget}
                      index={index}
                      editModal={this.openCreateBudgetModal}
                      deleteModal={this.openDeleteBudgetModal}
                    />
                  ))
                )
            )}
          </Table.Body>
        </TableLoader>

        <Modal open={createBudgetModalOpen} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>{isBudgetEdit ? t('budgetsUpdateTitle') : t('budgetsCreateTitle')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <TableLoader status={loadingCreateBudget || loadingUpdateBudget}>
                <Form className="margin-top-bot main-form" size="small">
                  <Form.Group inline>
                    <Form.Field error={accountError}>
                      <label>{t('formAccount')}</label>
                      <Input>
                        <Cleave
                          options={{
                            blocks: [3, 3, 3],
                            delimiter: '.',
                          }}
                          name="account"
                          placeholder={t('formAccount')}
                          value={account}
                          onChange={this.handleInputChange}
                          onBlur={this.completeAccountFormat}
                          readOnly={!createFromLabel}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  {accountFormatError && (
                    <Form.Group inline>
                      <Form.Field error={accountError}>
                        <label />
                        <Message
                          negative
                          size="small"
                          style={{
                            margin: 0,
                          }}
                        >
                          <p>{t('budgetsWarningAccountNumber')}</p>
                        </Message>
                      </Form.Field>
                    </Form.Group>
                  )}

                  <Form.Group inline>
                    <Form.Input
                      label={t('formLabel')}
                      name="budgetLabel"
                      placeholder={t('formPHLabel')}
                      value={budgetLabel}
                      onChange={this.handleTextChange}
                      error={budgetLabelError}
                    />
                  </Form.Group>

                  {account.startsWith('6') && (
                    <Form.Group inline>
                      <Form.Select
                        label={t('formType')}
                        name="type"
                        placeholder={t('formPHSelect')}
                        disabled={fromLabelDisableFields}
                        selectOnBlur={false}
                        value={type}
                        options={typeOptions}
                        onChange={this.handleSelectChange}
                        error={typeError}
                      />
                    </Form.Group>
                  )}

                  <Form.Group inline>
                    <Form.Field error={realisedError}>
                      <label>{t('formRealised')}</label>
                      <Input labelPosition="left">
                        <Label>{getCurrency()}</Label>
                        <CleaveInit
                          options={getLocalFormat(true, 0)}
                          handler={(value) => this.handleCleaveChange('realised', value)}
                          disabled={fromLabelDisableFields}
                          name="realised"
                          placeholder={t('formPHRealised')}
                          value={realised}
                          align="left"
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  {account.charAt(0) !== '7' && (
                    <React.Fragment>
                      <Form.Group inline>
                        <Form.Field error={plannedError || plannedMessage}>
                          <label>{t('formPlanned')}</label>
                          <Input labelPosition="left">
                            <Label>{getCurrency()}</Label>
                            <CleaveInit
                              options={getLocalFormat(true, 0)}
                              handler={(value) => this.handleCleaveChange('planned', value)}
                              name="planned"
                              placeholder={t('formPHPlanned')}
                              disabled={plannedEditDisabled || fromLabelDisableFields}
                              value={(plannedEditDisabled && !isBudgetEdit) ? 0 : planned}
                              align="left"
                            />
                          </Input>
                        </Form.Field>
                      </Form.Group>

                      {plannedMessage && (
                        <Form.Group inline>
                          <Form.Field>
                            <label>&nbsp;</label>
                            <p
                              style={{
                                margin: 0,
                                color: '#9f3a38',
                                fontWeight: 'normal',
                              }}
                            >
                              {t('budgetPlannedCalculate')}
                            </p>
                          </Form.Field>
                        </Form.Group>
                      )}

                      <Form.Group inline>
                        <Form.Field>
                          <label>{t('formPercentage')}</label>
                          <Input labelPosition="left">
                            <Label>%</Label>
                            <CleaveInit
                              options={getLocalFormat(true, 0)}
                              handler={(value) => this.handleCleaveChange('percentage', value)}
                              disabled={fromLabelDisableFields}
                              name="vat"
                              value={percentage}
                              align="left"
                              readOnly
                            />
                          </Input>
                        </Form.Field>
                      </Form.Group>

                    </React.Fragment>
                  )
                  }

                  <Form.Group inline>
                    <Form.Field error={vatError}>
                      <label>{t('formVat')}</label>
                      <Input labelPosition="left">
                        <Label>%</Label>
                        <Cleave
                          options={getFloatCleaveFormat()}
                          onChange={e => this.handleInputChange(e, true)}
                          disabled={fromLabelDisableFields}
                          name="vat"
                          placeholder={t('formPHVat')}
                          value={inputFormat(vat, true)}
                        />
                      </Input>
                    </Form.Field>
                  </Form.Group>

                  {account.charAt(0) !== '7' && (
                    <Form.Group inline>
                      <Form.Checkbox
                        label={t('formAllocated')}
                        disabled={fromLabelDisableFields}
                        name="allocated"
                        checked={allocated}
                        onChange={this.handleCheckBoxChange}
                      />
                    </Form.Group>
                  )}
                </Form>

                {allocated && (
                  <React.Fragment>
                    {breakDownTotalMessage && (
                      <Form.Group inline>
                        <Form.Field>
                          <Message
                            negative
                            style={{
                              margin: 0,
                            }}
                          >
                            <p>
                              {t('budgetAllocationCalculate')}
                            </p>
                          </Message>
                        </Form.Field>
                      </Form.Group>
                    )}
                    <div className="ui form">
                      <Table celled structured className="margin-top">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('formAmount')}</Table.HeaderCell>
                            <Table.HeaderCell colSpan={2} />
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {breakdown && breakdown.map(item => (
                            <Table.Row key={item.id}>
                              {item.edit
                                ? (
                                  <React.Fragment>
                                    <Table.Cell>
                                      <Form.Input
                                        name="label"
                                        placeholder={t('formPHLabel')}
                                        onChange={this.handleTextChange}
                                        value={label}
                                        error={labelError}
                                      />
                                    </Table.Cell>

                                    <Table.Cell collapsing>
                                      <Form.Group className="m-b-0">
                                        <Form.Field error={amountError}>
                                          <Input labelPosition="right">
                                            <CleaveInit
                                              options={getLocalFormat(false, 0)}
                                              handler={v => this.handleCleaveChange('amount', v)}
                                              name="amount"
                                              value={amount}
                                            />
                                            <Label>{getCurrency()}</Label>
                                          </Input>
                                        </Form.Field>
                                      </Form.Group>
                                    </Table.Cell>

                                    <CenterCell collapsing>
                                      <Button
                                        onClick={() => this.addBreakdown(item)}
                                        className="table-button"
                                        icon="check"
                                      />
                                    </CenterCell>

                                    <Table.Cell collapsing textAlign="center">
                                      <Button
                                        onClick={() => this.cancelBreakdownEdit(item)}
                                        className="table-button"
                                        icon="x"
                                      />
                                    </Table.Cell>
                                  </React.Fragment>
                                )
                                : (
                                  <React.Fragment>
                                    <LeftCell content={item.label} />
                                    <CurrencyCell value={item.amount} decimal={0} />
                                    <EditCellButton onClick={() => this.editBreakDown(item)} />
                                    <DeleteCellButton onClick={() => this.deleteBreakdown(item)} />
                                  </React.Fragment>
                                )
                              }
                            </Table.Row>
                          ))}

                          {!breakdownItemEdit && (
                            <Table.Row>
                              <Table.Cell>
                                <Form.Input
                                  name="label"
                                  placeholder={t('formPHLabel')}
                                  onChange={this.handleTextChange}
                                  value={label}
                                  error={labelError}
                                />
                              </Table.Cell>

                              <Table.Cell collapsing>
                                <Form.Group className="m-b-0">
                                  <Form.Field error={amountError}>
                                    <Input labelPosition="right">
                                      <CleaveInit
                                        options={getLocalFormat(false, 0)}
                                        handler={value => this.handleCleaveChange('amount', value)}
                                        name="amount"
                                        value={amount}
                                      />
                                      <Label>{getCurrency()}</Label>
                                    </Input>
                                  </Form.Field>
                                </Form.Group>
                              </Table.Cell>

                              <CenterCell collapsing colSpan={2}>
                                <Button
                                  onClick={() => this.addBreakdown()}
                                  className="table-button"
                                  icon="plus"
                                />
                              </CenterCell>
                            </Table.Row>
                          )}
                          <Table.Row>
                            <RightCell content="Montant restant à ventiler" />
                            <CurrencyCell value={unallocated} decimal={0} color noGreen />
                            <LeftCell colSpan={2} />
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </TableLoader>
            </Modal.Description>
          </Modal.Content>
          <ModalButtons
            disabled={loadingCreateBudget || loadingUpdateBudget}
            cancel={this.closeCreateBudgetModal}
            save={this.handleBudgetSubmit}
          />
        </Modal>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSelfFinancing: page => dispatch(listSelfFinancing(page)),

  getBudgets: page => dispatch(listBudget(page)),
  getPrevBudgets: page => dispatch(listPrevBudget(page)),

  updateFiscalYear: (item, data) => dispatch(updateFiscalYear(item, data)),

  postBudget: data => dispatch(createBudget(data)),

  updateBudget: (item, data) => dispatch(updateBudget(item, data)),

  deleteBudget: item => dispatch(deleteBudget(item)),

  getPreviousFiscalYear: page => dispatch(retrieveFiscalYear(page)),
  resetCreateBudget: () => {
    dispatch(successBudget(null));
    dispatch(loadingCreateBudget(false));
    dispatch(errorCreateBudget(null));
  },
  resetUpdateBudget: () => {
    dispatch(resetUpdateBudget());
  },
  resetDeleteBudget: () => {
    dispatch(successDeleteBudget(null));
    dispatch(loadingDeleteBudget(false));
    dispatch(errorDeleteBudget(null));
  },
  resetActualBudgetList: () => dispatch(resetActualBudget()),
  reset: () => {
    dispatch(resetFiscalYear());
    dispatch(resetActualBudget());
    dispatch(resetPreviousBudget());
    dispatch(resetSelfFinancing());
    dispatch(resetFiscalYearUpdate());
  },
});

const mapStateToProps = state => ({
  retrievedFiscalYear: state.fiscalYear.show.retrieved,
  loadingFiscalYear: state.fiscalYear.show.loading,
  errorFiscalYear: state.fiscalYear.show.error,

  dataBudget: state.budget.list.data,
  loadingBudget: state.budget.list.loading,
  errorBudget: state.budget.list.error,

  dataPrevBudget: state.budget.list.dataPrev,
  loadingPrevBudget: state.budget.list.loadingPrev,
  errorPrevBudget: state.budget.list.errorPrev,

  successDeleteBudget: state.budget.del.deleted,
  loadingDeleteBudget: state.budget.del.loading,
  errorDeleteBudget: state.budget.del.error,

  successBudget: state.budget.create.created,
  loadingCreateBudget: state.budget.create.loading,
  errorCreateBudget: state.budget.create.error,

  errorUpdateBudget: state.budget.update.updateError,
  loadingUpdateBudget: state.budget.update.updateLoading,
  updatedBudget: state.budget.update.updated,

  dataSelfFinancing: state.selfFinancing.list.data,
  loadingSelfFinancing: state.selfFinancing.list.loading,
  errorSelfFinancing: state.selfFinancing.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  updated: state.fiscalYear.update.updated,
  updateError: state.fiscalYear.update.updateError,
  updateLoading: state.fiscalYear.update.updateLoading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowBudgets);

export default withTranslation()(withRouter(Main));
