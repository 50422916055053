import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EssorButton } from 'components';
import { useTranslation } from 'react-i18next';
import { crudRights, Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TwelveForm from 'layouts/TwelveForm';
import Routes from 'routes/admin/contacts/routes';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import KeyValue from 'components/keyValue';

const ShowSupplier = ({ match }) => {
  const { t } = useTranslation();
  const retrieved = useSelector(state => state.supplier.show.retrieved);
  const loading = useSelector(state => state.supplier.show.loading);

  return (
    <ContainerGeneral>
      <ModifyHeader
        title={t('supplierShow')}
        to={`/contacts/suppliers/${match.params.id}/edit`}
        back={Routes.listSuppliers}
        entity={Entities.supplier}
      >
        <EssorButton
          as={Link}
          to={`/contacts/suppliers/${match.params.id}/update-prices`}
          type="arrow alternate circle up"
          size="tiny"
          floated="right"
          entity={Entities.supplier}
          right={crudRights.update}
        >
          {t('updatePrices')}
        </EssorButton>
      </ModifyHeader>

      <TwelveForm status={loading}>
        <KeyValue label={t('formSupplierName')}>{retrieved.supplierName}</KeyValue>
        <KeyValue label={t('formContactName')}>{retrieved.contactName}</KeyValue>
        <KeyValue label={t('formStreetName')}>{retrieved.streetName}</KeyValue>
        <KeyValue label={t('formZipCode')}>{retrieved.zipCode}</KeyValue>
        <KeyValue label={t('formCity')}>{retrieved.city}</KeyValue>
        <KeyValue label={t('formPhoneNumber')}>{retrieved.phone}</KeyValue>
        <KeyValue label={t('formEmail')}>{retrieved.email}</KeyValue>
        <KeyValue label={t('formWebsite')}>{retrieved.website}</KeyValue>
        <KeyValue label={t('formSupplyTime')}>{retrieved.supplyTime}</KeyValue>
        <KeyValue label={t('formComments')}>{retrieved.comment}</KeyValue>
        <KeyValue label={t('formAlert')}>{retrieved.alert}</KeyValue>
      </TwelveForm>
    </ContainerGeneral>
  );
};

export default withRouter(ShowSupplier);
