import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import { reset as resetChangeInvoice } from 'actions/invoice/change';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { list as listReceipt, reset as resetListReceipt, success as successReceipt } from 'actions/receipt/list';
import { list as listCreditNotes, reset as resetListCreditNotes } from 'actions/credit-note/list';
import { reset as resetUpdateInvoice, retrieve as retrieveInvoice } from 'actions/invoice/update';
import { selectDocument } from 'actions/user-companies/select';
import NotFound from '../../../404';

import 'moment/locale/fr';
import CreateInvoice from './invoice';
import CreateCreditNote from './credit-note';
import Receipt from './receipt';
import SectionLoader from '../../../../../components/sectionLoader';
import WrongDocument from '../../../../../layouts/error-pages/WrongDocument';

moment.locale('fr');

class EditInvoice extends Component {
  state = {
    creditNoteList: [],
    totalCreditNotes: 9999999999,
  };

  componentDidMount() {
    const {
      selectedDocument,
      getReceipt,
      getCreditNotes,
      getCustomerList,
      getInvoice,
      getArticles,
      selectedCompany,
      match,
    } = this.props;

    if (match.params.id) {
      getInvoice(`/invoices/${match.params.id}`);
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);

    if (selectedDocument && match.params.id) {
      getCreditNotes(`/credit_notes?company=${selectedCompany.id}&invoice=${match.params.id}`);
      getReceipt(`/receipts?company=${selectedCompany.id}&&invoice=${match.params.id}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listCreditNote) && nextProps.listCreditNote['hydra:member'] !== prevState.creditNoteList) {
      const totalCreditNotes = nextProps.listCreditNote['hydra:member'].reduce((a, b) => a + parseFloat(b.content.creditNoteAmount), 0);

      return {
        totalCreditNotes,
        creditNoteList: nextProps.listCreditNote['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedCompany,
      selectedDocument,
      getCreditNotes,
      getReceipt,
      listReceipt,
      setReceiptList,
      retrievedInvoice,
      createdReceipt,
      selectDocument,
      history,
      retrievedError,
    } = this.props;

    if (retrievedError) {
      return;
    }

    if (!isEmpty(selectedDocument) && selectedDocument !== prevProps.selectedDocument) {
      getCreditNotes(`/credit_notes?company=${selectedCompany.id}&invoice=${selectedDocument.id}`);
      getReceipt(`/receipts?company=${selectedCompany.id}&&invoice=${selectedDocument.id}`);
    }

    if (!isEmpty(createdReceipt) && createdReceipt !== prevProps.createdReceipt) {
      const newList = cloneDeep(listReceipt);
      newList['hydra:member'].push(createdReceipt);
      setReceiptList(newList);
      history.push(`/business/invoices/${selectedDocument.id}/receipt`);
    }

    if (!isEmpty(retrievedInvoice) && retrievedInvoice !== prevProps.retrievedInvoice) {
      selectDocument(retrievedInvoice);
    }

    if (isEmpty(retrievedInvoice) && retrievedInvoice !== prevProps.retrievedInvoice) {
      selectDocument(null);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { totalCreditNotes } = this.state;

    const {
      listReceipt,
      loadingCreateReceipt,
      loadingListReceipt,
      selectedDocument,
      retrievedInvoice,
      loadingRetrieveInvoice,
      loadingListCreditNote,
      loadingChangeInvoice,
      retrievedError,
      match,
      t,
    } = this.props;

    if (retrievedError) {
      return <WrongDocument />;
    }

    const isLoading = (selectedDocument && match.params.id && isEmpty(retrievedInvoice))
      || (selectedDocument && match.params.id && isEmpty(listReceipt))
      || loadingRetrieveInvoice
      || loadingListCreditNote || loadingListReceipt;

    if (isLoading) {
      return <SectionLoader status={isLoading} />;
    }

    const changeLoading = loadingChangeInvoice || loadingCreateReceipt;
    if (changeLoading) {
      if (loadingCreateReceipt) {
        return <SectionLoader status={changeLoading} label={t('documentToReceiptLoader')} />;
      }
      if (loadingChangeInvoice.includes('pending')) {
        return <SectionLoader status={changeLoading} label={t('documentValidate')} />;
      }
      if (loadingChangeInvoice.includes('model')) {
        return <SectionLoader status={changeLoading} label={t('documentToModelLoader')} />;
      }
      if (loadingChangeInvoice.includes('duplicate')) {
        return (
          <SectionLoader
            status={changeLoading}
            label={t('documentDuplicateLoader', {
              document: t('documentNumbering_INVOICE').toLowerCase(),
            })}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/business/invoices/create" key="create" component={CreateInvoice} />
          <Route
            exact
            path="/business/invoices/:id/edit"
            render={props => (
              <CreateInvoice key={props.match.params.id} {...props} />
            )}
          />
          <Route
            exact
            path="/business/invoices/:id/credit_note"
            render={(props) => {
              if (retrievedInvoice
                && totalCreditNotes >= retrievedInvoice.totalPrice + retrievedInvoice.totalVAT) {
                return (
                  <div className="section-container">
                    <NotFound />
                  </div>
                );
              }

              return (
                <CreateCreditNote {...props} />
              );
            }}
          />

          <Route
            exact
            path="/business/invoices/:id/receipt"
            render={(props) => {
              if (isEmpty(listReceipt['hydra:member'])) {
                return (
                  <div className="section-container">
                    <NotFound />
                  </div>
                );
              }

              return (
                <Receipt {...props} />
              );
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCustomerList: page => dispatch(listCustomer(page)),
  getArticles: page => dispatch(listArticle(page)),
  getCreditNotes: page => dispatch(listCreditNotes(page)),
  getReceipt: page => dispatch(listReceipt(page)),
  setReceiptList: receipt => dispatch(successReceipt(receipt)),
  getInvoice: page => dispatch(retrieveInvoice(page)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset: () => {
    dispatch(resetListCreditNotes());
    dispatch(resetListReceipt());
    dispatch(resetUpdateInvoice());
    dispatch(resetListCustomer());
    dispatch(resetArticle());
    dispatch(resetChangeInvoice());
  },
});

const mapStateToProps = state => ({
  listInvoice: state.invoice.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCreditNote: state.creditNote.list.data,
  loadingListCreditNote: state.creditNote.list.loading,
  errorListCreditNote: state.creditNote.list.error,

  retrievedInvoice: state.invoice.update.retrieved,
  retrievedError: state.invoice.update.retrieveError,
  loadingRetrieveInvoice: state.invoice.update.retrieveLoading,
  errorRetrieveInvoice: state.invoice.update.retrieveError,

  createdReceipt: state.receipt.create.created,
  loadingCreateReceipt: state.receipt.create.loading,

  listReceipt: state.receipt.list.data,
  loadingListReceipt: state.receipt.list.loading,

  loadingChangeInvoice: state.invoice.change.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditInvoice);

export default withTranslation()(withRouter(Main));
