import React, { FunctionComponent } from 'react';
import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import CurrencyCell from 'components/cell/CurrencyCell';

interface OwnProps {
  value?: BigSource;
  color?: boolean;
}

type Props = OwnProps;

const TreasuryTotalCell: FunctionComponent<Props> = ({ value = 0 }) => (
  <CurrencyCell
    value={Big(value).toFixed(2)}
    className="cell-fixed-left fixed-5 total-background"
    color
    noGreen
    emptyZero
  />
);

export default TreasuryTotalCell;
