import React, { FunctionComponent, ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
  noLi: boolean;
}

type Props = OwnProps;

const LiWrapper: FunctionComponent<Props> = ({ children, noLi }) => (
  noLi
    ? (
      <React.Fragment>{children}</React.Fragment>
    )
    : (
      <li>{children}</li>
    )
);

export default LiWrapper;
