import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { list as listServices, reset as resetServiceList } from 'actions/service/list';
import { crudRights, Entities } from 'types/accessRights';
import Routes from 'routes/admin/articles/routes';
import RouteNotFound from 'routes/RouteNotFound';
import ContainerGeneral from 'layouts/ContainerGeneral';
import CheckRoute from 'components/access/CheckRoute';
import CreateService from './create';
import ShowService from './show';
import HomeService from './home';

class Service extends Component {
  componentDidMount() {
    const { getServices, selectedCompany } = this.props;

    getServices(`/services?company=${selectedCompany.id}`);
  }

  componentDidUpdate(prevProps) {
    const { updated, created, getServices, selectedCompany } = this.props;

    if (
      (!isEmpty(updated) && updated !== prevProps.updated)
      || (!isEmpty(created) && created !== prevProps.created)
    ) {
      getServices(`/services?company=${selectedCompany.id}`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { dataService, loadingService } = this.props;

    if (loadingService || isEmpty(dataService)) {
      return <ContainerGeneral status={loadingService} />;
    }

    return (
      <React.Fragment>
        <Switch>
          <CheckRoute
            exact
            entity={Entities.service}
            right={crudRights.read}
            path={Routes.listServices}
            component={HomeService}
          />
          <CheckRoute
            exact
            entity={Entities.service}
            right={crudRights.create}
            path={Routes.createService}
            component={CreateService}
          />
          <CheckRoute
            exact
            entity={Entities.service}
            right={crudRights.update}
            path={Routes.editService}
            component={CreateService}
          />
          <CheckRoute
            exact
            entity={Entities.service}
            right={crudRights.read}
            path={Routes.showService}
            component={ShowService}
          />
          <RouteNotFound />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getServices: page => dispatch(listServices(page)),
  reset: () => dispatch(resetServiceList()),
});

const mapStateToProps = state => ({
  dataService: state.service.list.data,
  loadingService: state.service.list.loading,
  errorService: state.service.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,

  updated: state.service.update.updated,
  created: state.service.create.created,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Service);

export default Main;
