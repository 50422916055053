import React, { FunctionComponent, ReactNode } from 'react';
import { Grid, Header as Title } from 'semantic-ui-react';

interface OwnProps {
  children: ReactNode;
  className?: string;
  title: string;
}

type Props = OwnProps;

const RowWithTitle: FunctionComponent<Props> = ({ title, children, className = '' }) => (
  <React.Fragment>
    <Title as="h5">{title}</Title>
    <Grid.Row className={className}>{children}</Grid.Row>
  </React.Fragment>
);

export default RowWithTitle;
