import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLink from 'layouts/subNav/SubNavLink';
import SubNavLinkWithButton from 'layouts/subNav/subNavLinkWithButton';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckRoute from 'components/access/CheckRoute';
import CheckRouting from 'components/access/CheckRouting';
import useFiscalYear from 'hooks/useFiscalYear';
import useCompany from 'hooks/useCompany';
import { list as listSettings } from 'actions/company-settings/list';
import { crudRights, Entities } from 'types/accessRights';
import CreateEmployee from './create';
import HomeEmployee from './home';
import ViewEmployee from './view';
import Absences from './absence';
import NotFound from '../404/index';

const Employee = ({ match }) => {
  const { t } = useTranslation();
  const [hasFiscalYear] = useFiscalYear();
  const [hasCompany, company] = useCompany();
  const loading = useSelector(state => state.employee.show.loading);
  const employee = useSelector(state => state.userCompanies.select.selectedEmployee);
  const dispatch = useDispatch();
  const getSettings = query => dispatch(listSettings(query));

  const p = path => `${match.url}${path}`;

  const hasEmployee = employee && !loading;
  const id = to => (`/${hasEmployee ? employee.id : 0}${to}`);

  useEffect(() => {
    if (hasCompany) {
      getSettings(`/company_settings?company=${company['@id']}&name[]=CONTRACT_TYPE&name[]=DAY_OFF_REASONS`);
    }
  }, [hasCompany, company]);

  return (
    <CheckRouting entity={Entities.employee} right={crudRights.read}>
      <SubNav prefix="/employees">
        <SubNavTitle title={t('employees')} />

        <SubNavLinkWithButton to="/" create="/create" entity={Entities.employee} right="create">
          {t('employeeList')}
        </SubNavLinkWithButton>

        {hasFiscalYear && (
          <SubNavLink to="/absences" entity={Entities.absence} right={crudRights.read}>
            {t('employeeLeavesManagement')}
          </SubNavLink>
        )}

        {hasEmployee && (
          <React.Fragment>
            <SubNavTitle
              title={`${employee.firstName} ${employee.lastName}`}
              subtitle={employee.details.jobTitle}
            />

            <SubNavLink to={id('/')}>{t('employeeContractInformation')}</SubNavLink>
            <SubNavLink to={id('/work-schedule')}>{t('employeeWorkSchedule')}</SubNavLink>

            <SubNavLink
              to={id('/data-synthesis')}
              entity={Entities.employeeData}
              right={crudRights.read}
            >
              {t('employeeDataAndSynthesis')}
            </SubNavLink>

            {employee.identity && (
              <SubNavLink
                to={id('/roles')}
                entity={Entities.identity}
                right={crudRights.read}
              >
                {t('employeeProfileAndRoles')}
              </SubNavLink>
            )}
          </React.Fragment>
        )}
      </SubNav>

      <Switch>
        <CheckRoute
          entity={Entities.employee}
          right={crudRights.read}
          exact
          path={p('/')}
          component={HomeEmployee}
        />
        <CheckRoute
          exact
          path={p('/absences')}
          render={p => (!hasFiscalYear ? <Redirect to="/employees" /> : <Absences {...p} />)}
          entity={Entities.absence}
          right={crudRights.read}
        />
        <CheckRoute
          exact
          path={p('/create')}
          component={CreateEmployee}
          entity={Entities.employee}
          right={crudRights.create}
        />
        <Route path={p('/:id')} component={ViewEmployee} />
        <Route component={NotFound} />
      </Switch>
    </CheckRouting>
  );
};

export default withRouter(Employee);
