import React, { FC } from 'react';
import { Input, Label } from 'semantic-ui-react';
import { getCurrency } from '../../utils/formatter';
import CurrencyCleave, { CurrencyCleaveProps } from './CurrencyCleave';

interface Props extends CurrencyCleaveProps {
  error?: boolean;
  style?: {};
}

const CurrencyInput: FC<Props> = ({ error, style, ...others }) => (
  <Input size="small" fluid labelPosition="right" error={error} style={style}>
    <CurrencyCleave {...others} />
    <Label>{getCurrency()}</Label>
  </Input>
);

export default CurrencyInput;
