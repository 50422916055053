import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { keyVal } from '../../utils/functions';
import useCompany from '../../hooks/useCompany';
import ContextualData from './contextualData';
import LiveAnalysis from './liveAnalysis';
import { State } from '../../types/store';
import SidebarToggle from './SidebarToggle';

const Sidebar: FC = () => {
  const sidebarIsVisible = useSelector((state: State) => state.layoutRight.sidebarIsVisible);
  const [hasCompany] = useCompany();
  const location = useLocation();

  if (!hasCompany) {
    return <React.Fragment />;
  }

  const buildSidebar = (component: ReactNode, icon: SemanticICONS): ReactNode => (
    <React.Fragment>
      <div className={classnames('info-sidebar', keyVal('active', !sidebarIsVisible))}>
        {component}
      </div>
      <SidebarToggle icon={icon} />
    </React.Fragment>
  );

  const pattern = new RegExp('^/business/(invoices|purchase_orders|quotes)/.+');

  return (
    <div className="main-container">
      {pattern.test(location.pathname)
        ? buildSidebar(<LiveAnalysis />, 'chart pie')
        : buildSidebar(<ContextualData />, 'info circle')
      }
    </div>
  );
};

export default Sidebar;
