import React, { FC, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentContent, DocumentType } from '../../types/document';

interface OwnProps {
  recipient: {
    supplierName?: string;
    companyName?: string;
    contactName: string;
    details: {
      honorific?: string;
      streetName: string;
      zipCode: string;
      city: string;
      country: string;
    };
    streetName: string;
    zipCode: string;
    city: string;
  };
  type: DocumentType;
  content: DocumentContent;
}

type Props = OwnProps;

interface AddressProps {
  companyName?: string;
  honorific?: string;
  contactName: string;
  streetName: string;
  city: string;
  country?: string;
  zipCode: string;
}

const Address: FC<AddressProps> = (props) => {
  const { t } = useTranslation();
  const { companyName, honorific, contactName, streetName, city, country, zipCode } = props;

  const fullName = honorific ? `${honorific} ${contactName}` : contactName;

  return (
    <>
      <h5 className="informative-field">{companyName || fullName}</h5>
      {companyName && <p className="m-b-10">{`${t('quoteAttentionOf')} ${fullName}`}</p>}
      <p>{streetName}</p>
      <p>{`${zipCode} ${city}`}</p>
      <p>{country}</p>
    </>
  );
};

const RecipientAddress: FunctionComponent<Props> = ({ recipient, type, content }) => {
  if (type === 'SupplierOrder') {
    return (
      <Address
        companyName={recipient.supplierName}
        contactName={recipient.contactName}
        streetName={recipient.streetName}
        zipCode={recipient.zipCode}
        city={recipient.city}
      />
    );
  }

  const hasSiteAddress = (content: DocumentContent): boolean => (
    content.siteAddress
    && content.siteAddress.address !== undefined && content.siteAddress.address.length > 1
    && content.siteAddress.zipCode !== undefined && content.siteAddress.zipCode.length > 1
    && content.siteAddress.city !== undefined && content.siteAddress.city.length > 1
  );

  if (type === 'WorkOrder' && hasSiteAddress(content)) {
    return (
      <Address
        honorific={recipient.details.honorific}
        companyName={recipient.companyName}
        contactName={recipient.contactName}
        streetName={content.siteAddress.address}
        zipCode={content.siteAddress.zipCode}
        city={content.siteAddress.city}
        country={content.siteAddress.country}
      />
    );
  }

  return (
    <Address
      honorific={recipient.details.honorific}
      companyName={recipient.companyName}
      contactName={recipient.contactName}
      streetName={recipient.details.streetName}
      zipCode={recipient.details.zipCode}
      city={recipient.details.city}
      country={recipient.details.country}
    />
  );
};

export default RecipientAddress;
