import React, { Component, Dispatch, FC, FocusEvent, FocusEventHandler, SetStateAction, useEffect, useState } from 'react';
import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import Cleave from 'cleave.js/react';
import { ChangeEvent, InitHandler } from 'cleave.js/react/props';
import { CleaveOptions } from 'cleave.js/options';
import { Translation } from '../../hooks/useTrans';
import { getLocalFormat } from '../../utils/formatter';

export interface CurrencyCleaveProps {
  align?: 'right' | 'left';
  disable?: boolean;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onBlurOnInit?: boolean;
  onChange?: (value: BigSource) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  placeholder?: Translation;
  readOnly?: boolean;
  value: BigSource;
}

type cleaveState = [CleaveItf | undefined, Dispatch<SetStateAction<CleaveItf>>];

interface CleaveItf extends Component {
  setRawValue: (value: BigSource) => void;
}

type Props = CurrencyCleaveProps;

const CurrencyCleave: FC<Props> = ({ onFocus, align = 'right', onChange, onBlur, ...others }) => {
  const { value, name, placeholder, readOnly, disable, onBlurOnInit } = others;
  const [cleave, setCleave]: cleaveState = useState(undefined as unknown as CleaveItf);
  const options = getLocalFormat() as CleaveOptions;

  useEffect(() => {
    if (cleave) {
      cleave.setRawValue(value);
    }
  }, [value]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.rawValue);
    }
  };

  const forceDecimals = (cleaveObject?: CleaveItf): void => {
    if (options.numeralDecimalScale) {
      (cleave || cleaveObject as CleaveItf).setRawValue(
        Big(value || 0).toFixed(options.numeralDecimalScale),
      );
    }
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>): void => {
    forceDecimals();
    if (onBlur) {
      onBlur(e);
    }
  };

  const onFocusHandler = (e: FocusEvent<HTMLInputElement>): void => {
    if (onFocus) {
      onFocus(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const onCleaveInit: InitHandler = (cleaveObject: CleaveItf): void => {
    // cleaveObject.setRawValue(value);
    setCleave(cleaveObject);

    forceDecimals(cleaveObject);

    // 'hack' to trigger ensemble items price recalculation at init
    if (onBlurOnInit) {
      const e = {
        target: {
          value,
        },
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onBlur(e, onBlurOnInit);
    }
  };

  return (
    <Cleave
      className={readOnly || disable ? 'read-only' : undefined}
      onInit={onCleaveInit}
      options={options}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeHandler(e)}
      onBlur={(e: FocusEvent<HTMLInputElement>): void => onBlurHandler(e)}
      onFocus={(e: FocusEvent<HTMLInputElement>): void => onFocusHandler(e)}
      name={name as unknown as string}
      placeholder={placeholder as unknown as string}
      disabled={disable}
      readOnly={readOnly}
      style={{
        textAlign: align,
      }}
    />
  );
};

export default CurrencyCleave;
