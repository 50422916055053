import React, { FC, ReactNode, useContext } from 'react';
import { isEmpty } from 'lodash';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';
import Big from 'big.js';
import { TYPE_ADJUST } from '../../../../utils/constants';
import { keyVal } from '../../../../utils/functions';
import TreasuryAllocatedBlock from './TreasuryAllocatedBlock';
import { Breakdown, Budget } from '../../../../types/store';
import { AddMisc, BudgetData, MonthlyData, MonthlyDataKey } from './types';
import { budgetWithVAT } from './functions';
import PlannedCell from './PlannedCell';
import GapCell from './GapCell';
import TypeCell from './TypeCell';
import MonthCell from './MonthCell';
import TreasuryTotalCell from './TreasuryTotalCell';
import LabelCell from './LabelCell';
import TreasuryContext from './context';


interface OwnProps {
  gap?: number;
  planned?: number;
  monthKey: MonthlyDataKey;
  label: string;
  alt?: string;
  total?: number;

  onClick?: AddMisc;
  option?: 'encasement' | 'disbursement';
  miscType?: string;
  budget?: Budget;

  top?: boolean;
  bottom?: boolean;

  children?: ReactNode;
}

type Props = OwnProps;

const TreasuryRow: FC<Props> = ({ monthKey, label, alt, planned, gap, total, ...rest }) => {
  const { onClick, option = 'encasement', miscType = TYPE_ADJUST, budget } = rest;
  const { top, bottom, children } = rest;
  const { data } = useContext(TreasuryContext);

  const budgetData: BudgetData = {
    total: 0,
  };

  let realTotal = total;
  let realPlanned = planned;
  let realGap = gap;

  if (budget) {
    const id: string = budget['@id'];
    budgetData[id] = 0;
    for (const monthlyData of data) {
      if (!monthlyData.budgetDis[id]) {
        monthlyData.budgetDis[id] = {};
      }

      if (budget.allocated) {
        budget.breakdown.forEach((breakdown: Breakdown) => {
          if (budgetData[breakdown.id] && monthlyData.budgetDis[id][breakdown.id]) {
            budgetData[breakdown.id] += monthlyData.budgetDis[id][breakdown.id];
          } else if (!budgetData[breakdown.id] && monthlyData.budgetDis[id][breakdown.id]) {
            budgetData[breakdown.id] = monthlyData.budgetDis[id][breakdown.id];
          }
        });
      }

      budgetData[id] += monthlyData.budgetDis[id][id] || 0;

      budgetData.total = Object.keys(budgetData).reduce((prev, key) => {
        if (key === 'total') {
          return prev;
        }
        return (prev + parseFloat(Big(budgetData[key] || 0).valueOf()));
      }, 0);
    }

    realTotal = budgetData.total || 0;
    realPlanned = budgetWithVAT(budget);
    realGap = realPlanned - realTotal;
  }

  return (
    <React.Fragment>
      <Table.Row
        className={classNames(
          'section-border-side',
          keyVal('section-border-top', top),
          keyVal('section-border-bottom', bottom),
        )}
      >
        <LabelCell alt={alt} label={label}>{children}</LabelCell>
        <TypeCell type={budget ? budget.type : undefined} />
        <PlannedCell value={realPlanned || 0} />
        <GapCell value={realGap || 0} />
        <TreasuryTotalCell value={realTotal} />

        {!isEmpty(data) && data.map((monthly: MonthlyData, index: number) => (
          <MonthCell
            key={index}
            onClick={onClick && ((): void => onClick(
              option, miscType, monthly, data[index + 1], budget ? budget['@id'] : undefined,
            ))}
            value={monthly[monthKey]}
          />
        ))}
      </Table.Row>
      {budget && budget.allocated && onClick && (
        <TreasuryAllocatedBlock
          key={budget.id}
          budget={budget}
          budgetData={budgetData}
          data={data}
          onClick={onClick}
        />
      )}
    </React.Fragment>
  );
};

export default TreasuryRow;
