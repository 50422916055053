import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectEmployee } from 'actions/user-companies/select';
import { list as listSettings } from 'actions/company-settings/list';
import { list, reset } from 'actions/employee/list';
import { TableList } from 'components';
import { withTranslation } from 'react-i18next';
import AddHeader from 'components/pageHeaders/AddHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { crudRights, Entities } from 'types/accessRights';
import { columnDefinition, hiddenColumn } from '../../../../utils/tables';

class HomeEmployee extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    const { getEmployees, selectedCompany, getContractType, listCompanySettings } = this.props;

    getEmployees(`/employees?company=${selectedCompany.id}`);

    if (isEmpty(listCompanySettings)) {
      getContractType(`/company_settings?name=CONTRACT_TYPE&company=${selectedCompany['@id']}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && !nextProps.data['hydra:member'] !== prevState.data) {
      return {
        data: nextProps.data['hydra:member'],
      };
    }
    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setSelectedEmployee = (employee) => {
    const { selectEmployee, history } = this.props;

    selectEmployee(employee);
    history.push(`/employees/${employee.id}`);
  };

  editEmployee = (employee) => {
    const { selectEmployee, history } = this.props;

    selectEmployee(employee);
    history.push(`/employees/${employee.id}/edit`);
  };

  render() {
    const { data } = this.state;

    const { loading, t } = this.props;

    const columns = [
      columnDefinition('formFirstName', 'firstName'),
      columnDefinition('formLastName', 'lastName'),
      columnDefinition('formJobTitle', 'details.jobTitle'),
      hiddenColumn('formInDate', 'inDate', 'date'),
      hiddenColumn('formOutDate', 'outDate', 'date'),
      hiddenColumn('formBirthday', 'details.birthday', 'date'),
    ];

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('staff')}
          to="/employees/create"
          entity={Entities.employee}
          right={crudRights.create}
        />
        <TableList
          columns={columns}
          filterBtn
          exportBtn
          loading={loading}
          data={data}
          onView={this.setSelectedEmployee}
          onEdit={this.editEmployee}
          entity={Entities.employee}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectEmployee: employee => dispatch(selectEmployee(employee)),
  getEmployees: page => dispatch(list(page)),
  getContractType: query => dispatch(listSettings(query)),
  reset: () => dispatch(reset()),
});

const mapStateToProps = state => ({
  data: state.employee.list.data,
  loading: state.employee.list.loading,
  error: state.employee.list.error,
  selectedCompany: state.userCompanies.select.selectedCompany,
  listCompanySettings: state.companySettings.list.data,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeEmployee);

export default withTranslation()(withRouter(Main));
