import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import { findIndex, isEmpty } from 'lodash';
import { Article, DiscountType, Document, DocumentItem, Ensemble } from '../../types/document';
import { MarginCalculation } from '../../utils/marginCalculation';
import { isProduct } from './articles';

/**
 * If given document has a "dueAmount" value, we compute a matching "before tax" value to display
 */
export function getActualTotal(document: Document): Big {
  let beforeTaxes = Big(document.totalPrice);
  if (document.content.total.dueAmount) {
    const dueAmount = Big(document.content.total.dueAmount);
    const vat = Big(document.totalVat || document.totalVAT || 0);
    beforeTaxes = dueAmount.div(Big(1).plus(vat.div(beforeTaxes)));
  }
  return beforeTaxes;
}

/**
 * If given document has payments, remove their value for treasury display
 * @see getActualTotal
 */
export function getActualDueAmount(document: Document): Big {
  let beforeTaxes = getActualTotal(document);

  if (!isEmpty(document.payment)) {
    for (const payment of document.payment) {
      beforeTaxes = beforeTaxes.minus(Big(payment.amount));
    }
  }

  return beforeTaxes;
}

/**
 *  If given document has a "dueAmount" value, return actual VAT value from actual total
 * @see getActualTotal
 */
export function getActualVat(document: Document): Big {
  if (document.content.total.dueAmount) {
    return Big(document.content.total.dueAmount).minus(getActualTotal(document));
  }
  return Big(document.totalVat || document.totalVAT || 0);
}

/**
 * If given document has payments, return VAT left to pay based on actual VAT
 * @see getActualVat
 */
export function getActualDueVat(document: Document): Big {
  let vat = getActualVat(document);

  if (!isEmpty(document.payment)) {
    for (const payment of document.payment) {
      vat = vat.minus(Big(payment.vat));
    }
  }

  return vat;
}

export function isPercent(type: DiscountType): boolean {
  return type === 'p';
}

export function hasPercentDiscount(item: DocumentItem): boolean {
  return isPercent(item.discountType);
}

export function isAmount(type: DiscountType): boolean {
  return type === 'e';
}

export function hasAmountDiscount(item: DocumentItem): boolean {
  return isAmount(item.discountType);
}

export function changeItem(
  value: number, item: Article, name: keyof Article, marginCalc: MarginCalculation,
): Article {
  item[name] = value;

  switch (name) {
    case 'price':
      item.margin = parseFloat(
        marginCalc.getBigMargin(Big(item.unitCost || 0), Big(item.price || 0)).toFixed(2),
      );
      break;
    case 'margin':
      item.price = parseFloat(
        marginCalc.getBigPriceFromCost(Big(item.unitCost || 0), Big(item.margin || 0)).toFixed(2),
      );
      break;
    default:
      break;
  }

  if (hasPercentDiscount(item)) {
    item.totalPrice = parseFloat(
      Big(100)
        .minus(Big(item.discount || 0))
        .times(Big(item.price || 0).times(Big(item.quantity || 0)))
        .div(100)
        .toFixed(2),
    );
  } else if (hasAmountDiscount(item)) {
    item.totalPrice = parseFloat(
      Big(item.price || 0)
        .times(Big(item.quantity || 0))
        .minus(Big(item.discount || 0))
        .toFixed(2),
    );
  }

  return item;
}

export function computeEnsembleDiscount(
  ensemble: Ensemble,
  selectedItemList: DocumentItem[],
  currentDiscount: BigSource,
  marginCalculation: MarginCalculation,
): DocumentItem[] {
  const index = findIndex(selectedItemList, {
    id: ensemble.id,
  });

  let discount = (ensemble.price * ensemble.quantity) - ensemble.totalPrice;
  let productMaxDiscount = Infinity;
  let productQuantity = 0;

  for (const curItem of ensemble.items) {
    if (isProduct(curItem.item)) {
      productQuantity += 1;

      if (curItem.item.price < productMaxDiscount) {
        productMaxDiscount = curItem.item.originalPrice * curItem.quantity;
      }
    }
  }

  const maxDiscount = productMaxDiscount === Infinity ? 0 : productMaxDiscount;

  if (maxDiscount < discount) {
    if (hasPercentDiscount(ensemble)) {
      ensemble.discount = (maxDiscount * 100) / (ensemble.price * ensemble.quantity);
      ensemble.totalPrice = (100 - ensemble.discount)
        * (ensemble.price * ensemble.quantity) / 100;
    } else if (hasAmountDiscount(ensemble)) {
      ensemble.discount = maxDiscount;
      ensemble.totalPrice = (ensemble.price * ensemble.quantity) - ensemble.discount;
    }

    discount = (ensemble.price * ensemble.quantity) - ensemble.totalPrice;
    // discountCleave.setRawValue(article.discount);
  }

  const toProduct = (Big(discount).minus(Big(currentDiscount))).div(Big(productQuantity));

  for (const curArticle of ensemble.items) {
    if (isProduct(curArticle.item)) {
      curArticle.item.price -= parseFloat(toProduct.div(curArticle.quantity).toFixed(2));
      curArticle.item.totalPrice = curArticle.item.price * curArticle.quantity;

      curArticle.item.margin = parseFloat(marginCalculation.getBigMargin(
        Big(curArticle.item.unitCost),
        Big(curArticle.item.price),
      ).toFixed(2));
    }
  }
  selectedItemList[index] = ensemble;
  return selectedItemList;
}
