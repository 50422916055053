import Analysis from './admin/analysis/routes';
import Articles from './admin/articles/routes';
import Business from './admin/business/routes';
import Company from './admin/company/routes';
import Contacts from './admin/contacts/routes';
import Employees from './admin/employee/routes';
import Experts from './admin/expert/routes';
import Finances from './admin/payments/routes';
import Offices from './admin/office/routes';
import Subscriptions from './admin/subscriptions/routes';
import { Forecast } from './admin/forecast/routes';

interface Fillers {
  [key: string]: string | number;
}

export function fillPlaceholders(route: string, values: Fillers): string {
  Object.entries(values).forEach(([key, value]) => {
    route = route.replace(`:${key}`, value.toString());
  });

  return route;
}

export type Route = string |
  Analysis
  | Articles
  | Business
  | Company
  | Contacts
  | Employees
  | Experts
  | Finances
  | Forecast
  | Offices
  | Subscriptions;

const Routes = {
  Analysis,
  Articles,
  Business,
  Company,
  Contacts,
  Employees,
  Experts,
  Finances,
  Forecast,
  Offices,
  Subscriptions,
};

export default Routes;
