import React from 'react';
import SectionLoader from 'components/sectionLoader';
import PromptUnsavedData from 'components/PromptUnsavedData';
import SectionContainer from 'layouts/SectionContainer';
import SectionGeneral from 'layouts/SectionGeneral';

const ContainerGeneral = (props) => {
  const { children, status, prompt, label, ...others } = props;

  return (
    <SectionContainer {...others}>
      {status && (<SectionLoader status={status} label={label} />)}

      <SectionGeneral>{children}</SectionGeneral>

      {prompt && (<PromptUnsavedData hasDataChanged={prompt} />)}
    </SectionContainer>
  );
};

export default ContainerGeneral;
