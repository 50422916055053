import React, { FC, ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
}

const SectionGeneral: FC<OwnProps> = ({ children }) => (
  <div className="section-general">{children}</div>
);

export default SectionGeneral;
