import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubNavLink from 'layouts/subNav/SubNavLink';
import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLinkWithButton from 'layouts/subNav/subNavLinkWithButton';
import HomeArticles from 'routes/admin/articles/HomeArticles';
import { crudRights, Entities } from 'types/accessRights';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/articles/routes';
import Services from 'routes/admin/articles/service';
import Ensemble from 'routes/admin/articles/ensemble';
import Product from 'routes/admin/articles/product';
import Movement from 'routes/admin/articles/movement';
import CreateMovement from 'routes/admin/articles/movement/create';
import Stock from 'routes/admin/articles/product/stock';
import Prices from 'routes/admin/articles/prices';
import useCompany from 'hooks/useCompany';
import CheckRoute from 'components/access/CheckRoute';

const Articles = () => {
  const { t } = useTranslation();
  const [hasCompany] = useCompany();

  return (
    <React.Fragment>
      <SubNav hide={!hasCompany}>
        <SubNavTitle title={t('articlesShowTitle')} />

        <SubNavLinkWithButton
          to={Routes.listProducts}
          entity={Entities.product}
          title={t('productsShowTitle')}
        />
        <SubNavLinkWithButton
          to={Routes.listServices}
          entity={Entities.service}
          title={t('servicesShowTitle')}
        />
        <SubNavLinkWithButton
          to={Routes.listEnsembles}
          entity={Entities.ensemble}
          title={t('ensemblesShowTitle')}
        />

        <SubNavTitle title={t('stockHomeTitle')} />

        <SubNavLink
          to={Routes.listStocks}
          entity={Entities.stock}
          right={crudRights.read}
          title={t('stockSituation')}
        />
        <SubNavLink
          to={Routes.listMovements}
          entity={Entities.stockMovement}
          right={crudRights.read}
          title={t('stockMovements')}
        />
        <SubNavLink
          to={Routes.createMovement}
          entity={Entities.stockMovement}
          right={crudRights.create}
          title={t('stockCreateMovement')}
        />
        <SubNavLink
          to={Routes.updatePrices}
          entity={Entities.product}
          right={crudRights.update}
          title={t('stockPricesUpdate')}
        />
      </SubNav>

      <Switch>
        <Route
          exact
          path={Routes.home}
          component={HomeArticles}
        />
        <CheckRoute
          entity={Entities.product}
          right={crudRights.read}
          path={Routes.updatePrices}
          component={Prices}
        />
        <CheckRoute
          entity={Entities.stockMovement}
          right={crudRights.create}
          path={Routes.createMovement}
          component={CreateMovement}
        />
        <CheckRoute
          entity={Entities.stockMovement}
          right={crudRights.read}
          path={Routes.listMovements}
          component={Movement}
        />
        <CheckRoute
          entity={Entities.service}
          right={crudRights.read}
          path={Routes.listServices}
          component={Services}
        />
        <CheckRoute
          entity={Entities.ensemble}
          right={crudRights.read}
          path={Routes.listEnsembles}
          component={Ensemble}
        />
        <CheckRoute
          entity={Entities.product}
          right={crudRights.read}
          path={Routes.listProducts}
          component={Product}
        />
        <CheckRoute
          entity={Entities.stock}
          right={crudRights.read}
          path={Routes.routeStocks}
          component={Stock}
        />
        <RouteNotFound />
      </Switch>
    </React.Fragment>
  );
};

export default Articles;
