import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ForecastMonetaryRow from './ForecastMonetaryRow';
import CenterCell from '../../../../layouts/table/CenterCell';
import LeftCell from '../../../../layouts/table/LeftCell';
import { RowTuple } from './forecastTypes';
import ForecastInputRow from './ForecastInputRow';

interface OwnProps {
  mode: 'coef' | 'rate';
  stateKey: string;
  ghost: boolean;
  handleCleaveChange: (name: string, value: number) => void;
  purchaseLabel: string;
  purchaseTooltip: string;
  turnoverLabel: string;
  turnoverTooltip: string;
  marginLabel: string;
  data: {
    purchase: RowTuple;
    marginRate: RowTuple;
    turnover: RowTuple;
    marginValue: RowTuple;
  };
}

type Props = OwnProps;

const ForecastProductRows: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    mode,
    stateKey,
    ghost,
    handleCleaveChange,
    purchaseLabel,
    purchaseTooltip,
    turnoverLabel,
    turnoverTooltip,
    marginLabel,
    data,
  } = props;

  const {
    purchase,
    marginRate,
    turnover,
    marginValue,
  } = data;

  return (
    <React.Fragment>
      <ForecastMonetaryRow label={purchaseLabel} tooltip={purchaseTooltip} data={purchase} />

      <Table.Row>
        <LeftCell content={t('forecastModeCalc')} />
        <CenterCell textAlign="center" colSpan={2}>
          {mode === 'coef' ? t('forecastCoef') : t('forecastMarginRate')}
        </CenterCell>
        <CenterCell textAlign="center">
          {mode === 'coef' ? t('forecastMarginRate') : t('forecastCoef')}
        </CenterCell>
      </Table.Row>

      <ForecastInputRow
        stateKey={stateKey}
        data={marginRate}
        onChange={handleCleaveChange}
        ghost={ghost}
        mode={mode}
      />

      <ForecastMonetaryRow label={turnoverLabel} tooltip={turnoverTooltip} data={turnover} />

      <ForecastMonetaryRow label={marginLabel} data={marginValue} />

    </React.Fragment>
  );
};

export default ForecastProductRows;
