import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { list, reset } from 'actions/subscription/list';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLink from 'layouts/subNav/SubNavLink';
import SubNav from 'layouts/subNav/SubNav';
import Routes from 'routes/admin/subscriptions/routes';
import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import CheckRoute from 'components/access/CheckRoute';
import useCompany from 'hooks/useCompany';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateSubscription from './create';
import ShowSubscription from './show';
import Home from './home';

const Subscriptions = () => {
  const { t } = useTranslation();
  const [hasCompany, company] = useCompany();
  const listSubscription = useSelector(state => state.subscription.list.data);
  const loadingSubscription = useSelector(state => state.subscription.list.loading);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(list(`/subscriptions?company=${company.id}`));
      return () => {
        dispatch(reset());
      };
    },
    [company],
  );

  return (
    <React.Fragment>
      <SubNav hide={!hasCompany}>
        <SubNavTitle title={t('subscriptionsShowTitle')} />
        <SubNavLink
          entity={Entities.subscription}
          to={Routes.home}
          title={t('subscriptionsListTitle')}
        />
        <SubNavLink
          entity={Entities.subscription}
          right={crudRights.create}
          to={Routes.createSubscription}
          title={t('subscriptionsCreateTitle')}
        />
      </SubNav>

      {(loadingSubscription || isEmpty(listSubscription)) && (
        <ContainerGeneral status={loadingSubscription} />
      )}

      {!isEmpty(listSubscription) && (
        <Switch>
          <CheckRoute
            exact
            entity={Entities.subscription}
            path={Routes.home}
            component={Home}
          />
          <CheckRoute
            exact
            entity={Entities.subscription}
            path={Routes.createSubscription}
            component={CreateSubscription}
          />
          <CheckRoute
            exact
            entity={Entities.subscription}
            path={Routes.showSubscription}
            component={ShowSubscription}
          />
          <RouteNotFound />
        </Switch>
      )}
    </React.Fragment>
  );
};

export default Subscriptions;
