import React, { Component } from 'react';
import { Link, Redirect, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { retrieve, update, reset } from 'actions/company-settings/update';
import { create as createImage, success as successMedia, loading as loadingMedia, error as errorMedia } from 'actions/media/create';
import { Form, Header, Grid, Input, Image } from 'semantic-ui-react';
import { del as deleteImage } from 'actions/media/delete';
import { isEmpty } from 'lodash';
import { toast, EssorButton } from 'components';
import uploadDefaultImage from 'assets/images/uploadDefault.png';
import { withTranslation } from 'react-i18next';

class ContactInformation extends Component {
  state = {
    value: {
      streetName: '',
      additional: '',
      zipCode: '',
      city: '',
      country: '',
      phone: '',
      fax: '',
      website: '',
      image: '',
      picture: null,
    },

    imagePreview: null,
    hasDataChanged: false,
    streetNameError: false,
    additionalError: false,
    zipCodeError: false,
    cityError: false,
    countryError: false,
    phoneError: false,
    faxError: false,
    websiteError: false,
    isSettingsLoaded: false,
    wasUpdate: false,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=CONTACT_INFORMATION`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.retrieved && nextProps.retrieved['hydra:member'][0].name === 'CONTACT_INFORMATION' && !prevState.isSettingsLoaded) {
      return {
        isSettingsLoaded: true,
        value: nextProps.retrieved['hydra:member'][0].value,
        imagePreview: nextProps.retrieved['hydra:member'][0].value.picture || null,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      createdMedia,
      retrieved,
      updated,
      update,
      updatedMedia,
      t,
    } = this.props;
    const { value } = this.state;

    if ((!isEmpty(createdMedia) && createdMedia !== prevProps.createdMedia)
      || (!isEmpty(updatedMedia) && updatedMedia !== prevProps.updatedMedia)) {
      const data = {
        value: {
          ...value,
          picture: createdMedia ? createdMedia.contentUrl : updatedMedia.contentUrl,
          id: createdMedia ? createdMedia['@id'] : updatedMedia['@id'],
        },
      };

      update(retrieved['hydra:member'][0], data);
    }

    if (!isEmpty(updated) && updated !== prevProps.updated) {
      toast.success(t('contactUpdateSuccess'));
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: value,
        },
        hasDataChanged: true,
      }
    ));
  };

  handlePictureChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState({
          picture: file,
          imagePreview: reader.result,
          hasDataChanged: true,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        picture: null,
        imagePreview: null,
        hasDataChanged: true,
      });
    }
  };

  handleOnSubmit = () => {
    const { imagePreview, picture, value } = this.state;

    const { selectedCompany, createImage, retrieved, update, t, deleteImage, history } = this.props;

    this.setState({
      wasUpdate: true,
      hasDataChanged: false,
    });

    if (retrieved['hydra:member'][0].value.picture || imagePreview) {
      const data = new FormData();
      data.append('file', picture || retrieved['hydra:member'][0].value.picture);
      data.append('company', selectedCompany.id);

      if (imagePreview && value.id) {
        const object = {
          '@id': retrieved['hydra:member'][0].value.id,
        };

        Promise.all([deleteImage(object), createImage(data)])
          .then(() => toast.success(t('contactUpdateSuccess')));
      } else {
        createImage(data)
          .then(() => toast.success(t('contactUpdateSuccess')));
      }
    } else {
      const data = {
        value: {
          ...value,
        },
      };
      update(retrieved['hydra:member'][0], data)
        .then(() => {
          history.push('/company/settings/contact-information');
          toast.success(t('contactUpdateSuccess'));
        });
    }
  };

  render() {
    const {
      value: {
        streetName,
        additional,
        zipCode,
        city,
        country,
        phone,
        fax,
        website,
      },
      streetNameError,
      additionalError,
      zipCodeError,
      cityError,
      countryError,
      phoneError,
      faxError,
      websiteError,
      wasUpdate,
      hasDataChanged,
      imagePreview,
    } = this.state;

    const {
      retrieveLoading,
      updated,
      updateLoading,
      company,
      t,
      loadingDeleteMedia,
    } = this.props;

    if (updated && company && wasUpdate) {
      return (
        <Redirect
          push
          to="/company/"
        />
      );
    }

    if (updated && !company && wasUpdate) {
      return (
        <Redirect
          push
          to="/company/settings/contact-information"
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {company ? `${t('step')} 3 / 3: ${t('companiesContactInformation')}` : t('companiesContactInformation')}
            </Header>
            <EssorButton as={Link} to="/company/settings/contact-information" type="chevron left" size="tiny" floated="right">
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading || loadingDeleteMedia} size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formStreetName')}
                      name="streetName"
                      placeholder={t('formPHStreetName')}
                      value={streetName}
                      onChange={this.handleInputChange}
                      error={streetNameError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formAdditional')}
                      name="additional"
                      placeholder={t('formPHAdditional')}
                      value={additional}
                      onChange={this.handleInputChange}
                      error={additionalError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formZipCode')}
                      name="zipCode"
                      placeholder={t('formPHZipCode')}
                      value={zipCode}
                      onChange={this.handleInputChange}
                      error={zipCodeError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formCity')}
                      name="city"
                      placeholder={t('formPHCity')}
                      value={city}
                      onChange={this.handleInputChange}
                      error={cityError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formCountry')}
                      name="country"
                      placeholder={t('formPHCountry')}
                      value={country}
                      onChange={this.handleInputChange}
                      error={countryError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formPhoneNumber')}
                      name="phone"
                      placeholder={t('formPHPhoneNumber')}
                      value={phone}
                      onChange={this.handleInputChange}
                      error={phoneError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formFax')}
                      name="fax"
                      placeholder={t('formPHFax')}
                      value={fax}
                      onChange={this.handleInputChange}
                      error={faxError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formWebsite')}
                      name="website"
                      placeholder="www.example.com"
                      value={website}
                      onChange={this.handleInputChange}
                      error={websiteError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field>
                      <label htmlFor="picture">{t('formPicture')}</label>
                      <Input
                        type="file"
                        id="picture"
                        name="picture"
                        accept="image/*"
                        onChange={this.handlePictureChange}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Field>
                      <label />
                      <div style={{
                        width: '182px',
                      }}
                      >
                        {imagePreview
                          ? <Image src={imagePreview} />
                          : <Image src={uploadDefaultImage} />
                        }
                      </div>
                    </Form.Field>
                  </Form.Group>
                  {/* <EssorButton type="check" onClick={this.handleOnSubmit} size="small">
                    {company ? t('buttonNext') : t('buttonSave')}
                  </EssorButton> */}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {company ? t('buttonNext') : t('buttonSave')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  createImage: body => dispatch(createImage(body)),
  deleteImage: body => dispatch(deleteImage(body)),

  reset: () => {
    dispatch(reset());
    dispatch(successMedia(null));
    dispatch(loadingMedia(false));
    dispatch(errorMedia(null));
  },
});

const mapStateToProps = state => ({
  retrieveLoading: state.companySettings.update.retrieveLoading,
  updateLoading: state.companySettings.update.updateLoading,
  retrieved: state.companySettings.update.retrieved,
  updated: state.companySettings.update.updated,
  selectedCompany: state.userCompanies.select.selectedCompany,

  loadingMedia: state.media.create.loading,
  createdMedia: state.media.create.created,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(ContactInformation);

export default withTranslation()(withRouter(Main));
