import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import show from './show';
import del from './delete';

export default combineReducers({
  create, list, update, show, del,
});
