import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { list, reset } from 'actions/ensemble/list';
import Routes from 'routes/admin/articles/routes';
import RouteNotFound from 'routes/RouteNotFound';
import ContainerGeneral from 'layouts/ContainerGeneral';
import useCompany from 'hooks/useCompany';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import CreateEnsemble from './create';
import HomeEnsemble from './home';
import ShowEnsemble from './show';

const Ensemble = () => {
  const [, company] = useCompany();
  const dataEnsemble = useSelector(state => state.ensemble.list.data);
  const loadingEnsemble = useSelector(state => state.ensemble.list.loading);
  const updated = useSelector(state => state.ensemble.update.updated);
  const created = useSelector(state => state.ensemble.create.created);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(list(`/ensembles?company=${company.id}`));
      return () => {
        dispatch(reset());
      };
    },
    [created, updated, company],
  );

  if (loadingEnsemble || isEmpty(dataEnsemble)) {
    return <ContainerGeneral status={loadingEnsemble} />;
  }

  return (
    <Switch>
      <CheckRoute
        exact
        entity={Entities.ensemble}
        right={crudRights.read}
        path={Routes.listEnsembles}
        component={HomeEnsemble}
      />
      <CheckRoute
        exact
        entity={Entities.ensemble}
        right={crudRights.create}
        path={Routes.createEnsemble}
        component={CreateEnsemble}
      />
      <CheckRoute
        exact
        entity={Entities.ensemble}
        right={crudRights.update}
        path={Routes.editEnsemble}
        component={CreateEnsemble}
      />
      <CheckRoute
        exact
        entity={Entities.ensemble}
        right={crudRights.read}
        path={Routes.showEnsemble}
        component={ShowEnsemble}
      />
      <RouteNotFound />
    </Switch>
  );
};

export default Ensemble;
