import React, { FunctionComponent, SyntheticEvent } from 'react';
import { DropdownItemProps, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FieldWithLabel from './FieldWithLabel';

interface OwnProps {
  editable: boolean;
  label: string;
  name: string;
  options: DropdownItemProps[];
  value: number;

  onAddItem: (event: SyntheticEvent, data: any) => void;
  onChange: (event: SyntheticEvent, data: any) => void;
}

type Props = OwnProps;

const ActivityDropdown: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const {
    editable,
    label,
    name,
    options,
    value,
    onAddItem,
    onChange,
  } = props;

  return (
    <FieldWithLabel label={label}>
      {editable
        ? (
          <Select
            style={{
              width: '150px',
            }}
            options={options}
            placeholder={t('formPHSelect')}
            search
            allowAdditions
            name={name}
            value={value}
            onAddItem={onAddItem}
            onChange={onChange}
          />
        )
        : (
          <p className="info-line">{options[value] ? options[value].text : '-'}</p>
        )
      }
    </FieldWithLabel>
  );
};

export default ActivityDropdown;
