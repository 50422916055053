import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Message } from 'semantic-ui-react';
import CleaveInit from 'components/input/CleaveInit';
import SaveButton from 'components/buttons/SaveButton';
import TwelveForm from 'layouts/TwelveForm';
import { create, error, loading, success } from 'actions/working-capital/create';
import { reset as resetUpdateWorkingCapital, update as updateWorkingCapital } from 'actions/working-capital/update';
import Big from 'big.js';
import { getCurrency, getLocalFormat } from 'utils/formatter';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CurrencyCleave from 'components/input/CurrencyCleave';
import InlineInput from 'layouts/InlineInput';

class WorkingCapitalForm extends Component {
  state = {
    workingCapital: null,
    inventoryValue: Big(0.00),
    inventoryVAT: Big(0.00),
    customerPayables: Big(0.00),
    supplierDebts: Big(0.00),
    fiscalDebts: Big(0.00),
    otherDebts: Big(0.00),
    result: Big(0.00),

    inventoryValueError: false,
    inventoryVATError: false,
    customerPayablesError: false,
    supplierDebtsError: false,
    fiscalDebtsError: false,
    otherDebtsError: false,

    hasDataChanged: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const members = nextProps.retrievedWorkingCapital['hydra:member'];

    if (!isEmpty(members) && prevState.workingCapital !== members[0]) {
      return {
        workingCapital: members[0],
        inventoryValue: Big(members[0].inventoryValue),
        inventoryVAT: Big(members[0].inventoryVAT),
        customerPayables: Big(members[0].customerPayables),
        supplierDebts: Big(members[0].supplierDebts),
        fiscalDebts: Big(members[0].fiscalDebts),
        otherDebts: Big(members[0].otherDebts),
        result: Big(members[0].result),
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (name, value) => {
    const errorFormat = `${name}Error`;

    if (!isEmpty(value)) {
      this.setState({
        [errorFormat]: false,
      });
    }

    this.setState({
      [name]: Big(value || 0),
      hasDataChanged: true,
    },
    this.stateCallback);
  };

  stateCallback = () => {
    const {
      inventoryValue,
      inventoryVAT,
      customerPayables,
      supplierDebts,
      fiscalDebts,
      otherDebts,
    } = this.state;

    if (inventoryVAT.lt(100)) {
      this.setState({
        inventoryVATError: false,
      });
    } else {
      this.setState({
        inventoryVATError: true,
      });
    }

    if (inventoryVAT.lt(100)) {
      this.setState({
        result:
          Big(inventoryValue)
            .times(Big(1).plus(inventoryVAT.div(100)))
            .plus(customerPayables)
            .minus(supplierDebts)
            .minus(fiscalDebts)
            .minus(otherDebts),
      });
    }
  };

  handleOnSubmit = () => {
    const {
      workingCapital,
      inventoryValue,
      inventoryVAT,
      customerPayables,
      supplierDebts,
      fiscalDebts,
      otherDebts,
      result,
    } = this.state;

    const { postWorkingCapital, updateWorkingCapital, selectedFiscalYear } = this.props;

    let isValid = true;

    this.setState({
      inventoryValueError: false,
      inventoryVATError: false,
      customerPayablesError: false,
      supplierDebtsError: false,
      fiscalDebtsError: false,
      otherDebtsError: false,
    });

    if (inventoryValue.lt(0)) {
      isValid = false;

      this.setState({
        inventoryValueError: true,
      });
    }

    if (inventoryVAT.lt(0)) {
      isValid = false;

      this.setState({
        inventoryVATError: true,
      });
    }

    if (customerPayables.lt(0)) {
      isValid = false;

      this.setState({
        customerPayablesError: true,
      });
    }

    if (supplierDebts.lt(0)) {
      isValid = false;

      this.setState({
        supplierDebtsError: true,
      });
    }

    if (fiscalDebts.lt(0)) {
      isValid = false;

      this.setState({
        fiscalDebtsError: true,
      });
    }

    if (otherDebts.lt(0)) {
      isValid = false;

      this.setState({
        otherDebtsError: true,
      });
    }

    if (!isValid) return;

    const data = {
      fiscalYear: selectedFiscalYear['@id'],
      inventoryValue,
      inventoryVAT,
      customerPayables,
      supplierDebts,
      fiscalDebts,
      otherDebts,
      result: result.toFixed(2),
    };

    this.setState({
      hasDataChanged: false,
    });

    workingCapital ? updateWorkingCapital(workingCapital, data) : postWorkingCapital(data);
  };

  render() {
    const {
      workingCapital,
      inventoryValue,
      inventoryValueError,
      inventoryVAT,
      inventoryVATError,
      customerPayables,
      customerPayablesError,
      supplierDebts,
      supplierDebtsError,
      fiscalDebts,
      fiscalDebtsError,
      otherDebts,
      otherDebtsError,
      result,
      hasDataChanged,
    } = this.state;

    const {
      loading,
      error,
      success,
      retrieveLoading,
      updateLoading,
      updated,
      t,
    } = this.props;

    const currency = getCurrency();

    if (success || updated) {
      return <Redirect push to="/forecast/working-capital" />;
    }

    return (
      <ContainerGeneral prompt={hasDataChanged} status={retrieveLoading}>
        <BackHeader
          title={workingCapital ? t('workingCapitalUpdateTitle') : t('workingCapitalCreateTitle')}
          to="/forecast/working-capital"
        />

        <TwelveForm loading={loading || retrieveLoading || updateLoading}>
          <InlineInput error={inventoryValueError} label={currency} title={t('formInventoryValue')}>
            <CurrencyCleave
              onChange={value => this.handleInputChange('inventoryValue', value)}
              placeholder={t('formPHInventoryValue')}
              value={inventoryValue.valueOf()}
            />
          </InlineInput>

          <InlineInput error={inventoryVATError} title={t('formInventoryVAT')} label="%">
            <CleaveInit
              options={getLocalFormat(true, 2)}
              handler={value => this.handleInputChange('inventoryVAT', value)}
              placeholder={t('formPHInventoryVAT')}
              value={inventoryVAT.valueOf()}
            />
          </InlineInput>

          <InlineInput
            error={customerPayablesError}
            title={t('formCustomerPayables')}
            label={currency}
          >
            <CurrencyCleave
              onChange={value => this.handleInputChange('customerPayables', value)}
              placeholder={t('formPHCustomerPayables')}
              value={customerPayables.valueOf()}
            />
          </InlineInput>

          <InlineInput error={supplierDebtsError} label={currency} title={t('formSupplierDebts')}>
            <CurrencyCleave
              onChange={value => this.handleInputChange('supplierDebts', value)}
              placeholder={t('formPHSupplierDebts')}
              value={supplierDebts.valueOf()}
            />
          </InlineInput>

          <InlineInput error={fiscalDebtsError} label={currency} title={t('formFiscalDebts')}>
            <CurrencyCleave
              onChange={value => this.handleInputChange('fiscalDebts', value)}
              placeholder={t('formPHFiscalDebts')}
              value={fiscalDebts.valueOf()}
            />
          </InlineInput>

          <InlineInput error={otherDebtsError} label={currency} title={t('formOtherDebts')}>
            <CurrencyCleave
              options={getLocalFormat(true, 2)}
              onChange={value => this.handleInputChange('otherDebts', value)}
              placeholder={t('formPHOtherDebts')}
              value={otherDebts.valueOf()}
            />
          </InlineInput>

          <InlineInput title={t('formWCResult')} label={currency}>
            <CurrencyCleave
              value={result.toFixed(2)}
              readOnly
            />
          </InlineInput>

          <Message info hidden={result.lte(0)}>{t('formResultPositive')}</Message>
          <Message negative hidden={!error}>{error}</Message>
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} floated="right" />

      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postWorkingCapital: data => dispatch(create(data)),
  updateWorkingCapital: (item, values) => dispatch(updateWorkingCapital(item, values)),
  reset: () => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(resetUpdateWorkingCapital());
  },
});

const mapStateToProps = state => ({
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  success: state.workingCapital.create.created,
  loading: state.workingCapital.create.loading,
  error: state.workingCapital.create.error,

  retrievedWorkingCapital: state.workingCapital.show.retrieved,
  retrieveError: state.workingCapital.show.error,
  retrieveLoading: state.workingCapital.show.loading,

  updateError: state.workingCapital.update.updateError,
  updateLoading: state.workingCapital.update.updateLoading,
  updated: state.workingCapital.update.updated,

});

const Main = connect(mapStateToProps, mapDispatchToProps)(WorkingCapitalForm);

export default withTranslation()(Main);
