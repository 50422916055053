import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Company, State } from '../types/store';

type UseCompany = [boolean, Company];

export default function useCompany(): UseCompany {
  const company = useSelector((state: State) => state.userCompanies.select.selectedCompany);

  return [
    !isEmpty(company),
    company,
  ];
}
