import React, { FunctionComponent } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  hasDataChanged: boolean;
}

type Props = OwnProps;

const PromptUnsavedData: FunctionComponent<Props> = ({ hasDataChanged }) => {
  const { t } = useTranslation();
  return (
    <Prompt
      when={hasDataChanged}
      message={t('warningYouHaveUnsavedData')}
    />
  );
};

export default PromptUnsavedData;
