import React from 'react';
import GridWrapper from 'layouts/GridWrapper';
import SaveButton from 'components/buttons/SaveButton';
import { useTranslation } from 'react-i18next';

const SaveButtons = (props) => {
  const { t } = useTranslation();
  const { disabled, loading, onClick, isDraft } = props;

  return (
    <GridWrapper width={16}>
      {isDraft && (
        <SaveButton
          disabled={disabled}
          loading={loading}
          floated="right"
          onClick={() => onClick('validate')}
          label={t('buttonSaveValidate')}
        />
      )}

      <SaveButton
        disabled={disabled}
        loading={loading}
        floated="right"
        onClick={() => onClick('edit')}
        label={t('buttonSaveAndEdit')}
      />

      <SaveButton
        disabled={disabled}
        loading={loading}
        floated="right"
        onClick={() => onClick('save')}
      />
    </GridWrapper>
  );
};

export default SaveButtons;
