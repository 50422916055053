import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '../../../types/store';
import SelectableNavLink from '../../../layouts/subNav/SelectableNavLink';
import SelectedQuoteMenu from './SelectedQuoteMenu';
import SelectedPurchaseOrderMenu from './SelectedPurchaseOrderMenu';
import SelectedInvoiceMenu from './SelectedInvoiceMenu';
import { isSupplierOrder } from '../../../utils/documentType';
import CheckRights from '../../../components/access/CheckRights';
import { crudRights, Entities } from '../../../types/accessRights';

interface OwnProps {
  changeDocument: (path: string) => void;
  openCompanySettingsModal: (path: string, docType: string) => void;
  createReceipt: () => void;
}

type Props = OwnProps;

const SelectedTypeMenu: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { changeDocument, openCompanySettingsModal, createReceipt } = props;
  const selectedDocument = useSelector((s: State) => s.userCompanies.select.selectedDocument);
  const documentReceipt = useSelector((state: State) => state.receipt.list.data);

  if (!selectedDocument) {
    return (<React.Fragment />);
  }

  return (
    <li className="nested-nav">
      <ul className="business-list__document">
        <SelectableNavLink
          label={t('documentDuplicate')}
          onClick={(): void => changeDocument('/duplicate')}
        />

        <SelectedQuoteMenu openCompanySettingsModal={openCompanySettingsModal} />

        <SelectedPurchaseOrderMenu />

        <SelectedInvoiceMenu
          createReceipt={createReceipt}
          documentReceipt={documentReceipt}
        />

        {!isSupplierOrder(selectedDocument) && (
          <CheckRights entity={Entities.documentModel} right={crudRights.create}>
            <SelectableNavLink
              label={t('documentToModel')}
              onClick={(): void => changeDocument('/to_model')}
            />
          </CheckRights>
        )}
      </ul>
    </li>
  );
};

export default SelectedTypeMenu;
