import { combineReducers } from 'redux';

export function hoursInDocument(state = 0, action) {
  switch (action.type) {
    case 'HOURS_IN_DOCUMENT':
      return action.payload;
    default:
      return state;
  }
}

export function actionItemSelected(state = [], action) {
  switch (action.type) {
    case 'ITEMS_SELECTED':
      return action.payload;
    default:
      return state;
  }
}

export function showCalculation(state = false, action) {
  switch (action.type) {
    case 'SHOW_CALCULATION':
      return action.payload;
    default:
      return state;
  }
}

export function adaptedHours(state = 0, action) {
  switch (action.type) {
    case 'ADAPTED_HOURS':
      return action.payload;
    default:
      return state;
  }
}

export function plannedHours(state = 0, action) {
  switch (action.type) {
    case 'PLANNED_HOURS':
      return action.payload;
    default:
      return state;
  }
}

export function labourTurnover(state = 0, action) {
  switch (action.type) {
    case 'LABOUR_TURNOVER':
      return action.payload;
    default:
      return state;
  }
}

export function labourCost(state = null, action) {
  switch (action.type) {
    case 'LABOUR_COST':
      return action.payload;
    default:
      return state;
  }
}

export function totalBeforeTaxes(state = 0, action) {
  switch (action.type) {
    case 'TOTAL_BEFORE_TAXES':
      return action.payload;
    default:
      return state;
  }
}
export function netTotalBeforeTaxes(state = 0, action) {
  switch (action.type) {
    case 'NET_TOTAL_BEFORE_TAXES':
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  hoursInDocument,
  actionItemSelected,
  showCalculation,
  adaptedHours,
  plannedHours,
  labourTurnover,
  labourCost,
  totalBeforeTaxes,
  netTotalBeforeTaxes,
});
