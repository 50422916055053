import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { update, reset } from 'actions/office-settings/update';
import { Form, Grid, Header } from 'semantic-ui-react';
import { EssorButton } from 'components';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import 'moment/locale/fr';

moment.locale('fr');

class SubscriptionData extends Component {
  state = {
    value: {
      subscriptionAmount: '',
      accreditationAmount: '',
      renewDate: null,
      startDate: null,
      membershipLevy: '',
      timeCreditBase: '',
      timeCreditUsed: '',
    },
    subscriptionAmountError: false,
    accreditationAmountError: false,
    renewDateError: false,
    startDateError: false,
    membershipLevyError: false,
    timeCreditBaseError: false,
    timeCreditUsedError: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedSetting)
      && nextProps.retrievedSetting['hydra:member'][0]['@id'] !== prevState.id
      && nextProps.retrievedSetting['hydra:member'][0].name === 'SUBSCRIPTION_DATA'
    ) {
      const data = nextProps.retrievedSetting['hydra:member'][0].value;

      data.renewDate = moment(data.renewDate);
      data.startDate = moment(data.startDate);

      return {
        id: nextProps.retrievedSetting['hydra:member'][0]['@id'],
        value: data,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: value,
        },
      }
    ));
  };

  handleRenewDateChange = (date) => {
    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          renewDate: date,
        },
      }
    ));
  };

  handleStartDateChange = (date) => {
    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          startDate: date,
        },
      }
    ));
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const { value } = this.state;

    const { update, retrievedSetting } = this.props;

    const data = {
      value: {
        ...value,
        renewDate: value.renewDate.format(),
        startDate: value.startDate.format(),
      },
    };

    update(retrievedSetting['hydra:member'][0], data);
  };

  render() {
    const {
      id,
      subscriptionAmountError,
      accreditationAmountError,
      renewDateError,
      startDateError,
      membershipLevyError,
      timeCreditBaseError,
      timeCreditUsedError,
      value: {
        subscriptionAmount,
        accreditationAmount,
        renewDate,
        startDate,
        membershipLevy,
        timeCreditBase,
        timeCreditUsed,
      },
    } = this.state;

    const {
      retrieveLoading,
      selectedOffice,
      updated,
      updateLoading,
      office,
      match,
      t,
    } = this.props;

    if (updated && office && id) {
      return (
        <Redirect
          push
          to={{
            pathname: `/offices/${match.params.id}/settings/subscribed-option/edit`,
            office,
          }}
        />
      );
    }

    if (updated && !office && id) {
      return (
        <Redirect
          push
          to={`/offices/${match.params.id}/settings/subscription-data`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {office && `${t('step')} 5 / 6: `}
              {t('officesSubscriptionData')}
            </Header>
            {!office
              && (
                <EssorButton as={Link} to={`/offices/${selectedOffice.id}/settings/subscription-data`} type="edit" size="tiny" floated="right">
                  {t('buttonBack')}
                </EssorButton>
              )}
          </div>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formSubscriptionAmount')}
                      name="subscriptionAmount"
                      placeholder={t('formPHSubscriptionAmount')}
                      value={subscriptionAmount}
                      onChange={this.handleInputChange}
                      error={subscriptionAmountError}
                    />
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Input
                      label={t('formAccreditationAmount')}
                      name="accreditationAmount"
                      placeholder={t('formPHAccreditationAmount')}
                      value={accreditationAmount}
                      onChange={this.handleInputChange}
                      error={accreditationAmountError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formRenewDate')}
                      name="renewDate"
                      control={DatePicker}
                      selected={renewDate}
                      onChange={this.handleRenewDateChange}
                      locale="fr"
                      error={renewDateError}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formStartDate')}
                      name="startDate"
                      control={DatePicker}
                      selected={startDate}
                      onChange={this.handleStartDateChange}
                      locale="fr"
                      error={startDateError}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formMembershipLevy')}
                      name="membershipLevy"
                      placeholder={t('formPHMembershipLevy')}
                      value={membershipLevy}
                      onChange={this.handleInputChange}
                      error={membershipLevyError}
                    />
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Input
                      label={t('formTimeCreditBase')}
                      name="timeCreditBase"
                      placeholder={t('formPHTimeCreditBase')}
                      value={timeCreditBase}
                      onChange={this.handleInputChange}
                      error={timeCreditBaseError}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formTimeCreditUsed')}
                      name="timeCreditUsed"
                      placeholder={t('formPHTimeCreditUsed')}
                      value={timeCreditUsed}
                      onChange={this.handleInputChange}
                      error={timeCreditUsedError}
                    />
                  </Form.Group>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {office ? t('buttonNext') : t('buttonSubmit')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  updateError: state.officeSettings.update.updateError,
  updateLoading: state.officeSettings.update.updateLoading,
  updated: state.officeSettings.update.updated,

  retrievedSetting: state.officeSettings.show.retrieved,
  selectedOffice: state.userCompanies.select.selectedOffice,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(SubscriptionData);

export default withTranslation()(withRouter(Main));
