import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import { Entities } from 'types/accessRights';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import KeyValue from 'components/keyValue';
import Currency from 'components/currency';
import Percentage from 'components/percentage';
import TwelveGrid from 'layouts/TwelveGrid';
import Routes from 'routes/routes';

const ShowWorkingCapital = () => {
  const { t } = useTranslation();
  const retrieved = useSelector(state => state.workingCapital.show.retrieved);
  const loading = useSelector(state => state.workingCapital.show.loading);
  const [workingCapital, setWorkingCapital] = useState(undefined);

  useEffect(
    () => { setWorkingCapital(retrieved['hydra:member'][0]); },
    [retrieved],
  );

  const create = isEmpty(workingCapital);

  return (
    <ContainerGeneral status={loading}>
      {create
        ? (
          <AddHeader
            title={t('forecastWorkingCapital')}
            label={t('buttonCreate')}
            to={Routes.Forecast.editWorkingCapital}
            entity={Entities.workingCapital}
          />
        )
        : (
          <ModifyHeader
            title={t('forecastWorkingCapital')}
            to={Routes.Forecast.editWorkingCapital}
            entity={Entities.workingCapital}
          />
        )}

      <TwelveGrid>
        {!create ? (
          <Form className="margin-top-bot main-form" loading={loading}>
            <KeyValue label={t('formInventoryValue')}>
              <Currency value={workingCapital.inventoryValue} />
            </KeyValue>

            <KeyValue label={t('formInventoryVAT')}>
              <Percentage value={workingCapital.inventoryVAT} />
            </KeyValue>

            <KeyValue label={t('formCustomerPayables')}>
              <Currency value={workingCapital.customerPayables} />
            </KeyValue>

            <KeyValue label={t('formSupplierDebts')}>
              <Currency value={workingCapital.supplierDebts} />
            </KeyValue>

            <KeyValue label={t('formFiscalDebts')}>
              <Currency value={workingCapital.fiscalDebts} />
            </KeyValue>

            <KeyValue label={t('formOtherDebts')}>
              <Currency value={workingCapital.otherDebts} />
            </KeyValue>

            <KeyValue label={t('formWCResult')}>
              <Currency value={workingCapital.result} />
            </KeyValue>
          </Form>
        ) : (
          <div>{t('workingCapitalNoData')}</div>
        )}
      </TwelveGrid>
    </ContainerGeneral>
  );
};

export default ShowWorkingCapital;
