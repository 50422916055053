import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { Table, Header, Segment, Loader, Dimmer, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { STATUS_PENDING, STATUS_VALIDATED, STATUS_CLOSED, STATUS_DRAFT } from 'utils/constants';
import TableEmptyRow from 'components/TableEmptyRow';

class HomeDeliveryForm extends Component {
  state = {
    deliveryData: null,
    column: null,
    direction: null,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataDeliveryForm)
      && nextProps.dataDeliveryForm['hydra:member'] !== prevState.deliveryData) {
      return {
        deliveryData: nextProps.dataDeliveryForm['hydra:member'],
      };
    }

    return null;
  }

  handleSort = clickedColumn => () => {
    const { column, deliveryData, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        deliveryData: sortBy(deliveryData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      deliveryData: deliveryData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  render() {
    const { deliveryData, column, direction } = this.state;
    const { t, loadingDeliveryForm } = this.props;

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('deliveryFormHomeTitle')}</Header>
          </div>

          <Segment
            basic
            className={classnames('table-container', {
              'is-loading': loadingDeliveryForm,
            })}
          >
            <Dimmer active={loadingDeliveryForm} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            <Table sortable celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === 'uniqueID' ? direction : null}
                    onClick={this.handleSort('uniqueID')}
                  >
                    {t('documentNumber')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'origin' ? direction : null}
                    onClick={this.handleSort('origin')}
                  >
                    {t('deliveryCreatedFrom')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'creationDate' ? direction : null}
                    onClick={this.handleSort('creationDate')}
                  >
                    {t('formDate')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'status' ? direction : null}
                    onClick={this.handleSort('status')}
                  >
                    {t('documentStatus')}
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!isEmpty(deliveryData) && deliveryData.map((delivery, i) => {
                  let status = '';

                  switch (delivery.status) {
                    case STATUS_DRAFT:
                      status = t('documentStatusDraft');
                      break;
                    case STATUS_PENDING:
                      status = t('documentStatusPending');
                      break;
                    case STATUS_VALIDATED:
                      status = t('documentStatusBilled');
                      break;
                    case STATUS_CLOSED:
                      status = t('documentStatusLost');
                      break;
                    default: break;
                  }
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{delivery.status >= STATUS_PENDING ? delivery.uniqueID : '-'}</Table.Cell>
                      <Table.Cell>
                        {delivery.purchaseOrder
                          ? delivery.purchaseOrder.uniqueID
                          : delivery.invoice.uniqueID}
                      </Table.Cell>
                      <Table.Cell>{moment(delivery.creationDate).format('DD/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{status}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          as={Link}
                          to={`/business/delivery_forms/${delivery.id}/edit`}
                          className="table-button"
                          data-tip="show"
                          icon="eye"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                {deliveryData.length === 0 && (
                  <TableEmptyRow content={t('documentEmptyList')} span={5} />
                )}
              </Table.Body>
            </Table>
          </Segment>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: document => dispatch(selectDocument(document)),
});

const mapStateToProps = state => ({
  dataDeliveryForm: state.delivery.list.data,
  loadingDeliveryForm: state.delivery.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeDeliveryForm);

export default withTranslation()(withRouter(Main));
