import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import { list as listProduct, reset as resetListProduct } from 'actions/product/list';
import { list as listStock, reset as resetListStock } from 'actions/stock/list';
import { create as createBulkPrice, error as errorCreateBulkPrice, loading as loadingCreateBulkPrice, success as successCreateBulkPrice } from 'actions/bulk-update/create';
import { Form } from 'semantic-ui-react';
import { PricesTable, toast } from 'components';
import { withTranslation } from 'react-i18next';
import { inputFormat } from 'utils/formatter';
import DatePicker from 'react-datepicker';

import 'moment/locale/fr';
import BackHeader from 'components/pageHeaders/BackHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SmallForm from 'layouts/SmallForm';
import GridWrapper from 'layouts/GridWrapper';
import SaveButton from 'components/buttons/SaveButton';

moment.locale('fr');

class UpdatePrices extends Component {
  state = {
    supplierData: null,
    effectDate: null,
    effectDateError: false,
  };

  componentDidMount() {
    const { getProducts, getSupplierStocks, retrievedSupplier, selectedCompany } = this.props;

    getProducts(`/products?company=${selectedCompany.id}`);
    getSupplierStocks(`/stocks?supplier=${retrievedSupplier.id}&company=${selectedCompany.id}`);
  }

  componentDidUpdate() {
    const { supplierData } = this.state;
    const { listProduct, listStock } = this.props;

    if (!isEmpty(listProduct) && !isEmpty(listStock) && supplierData === null) {
      this.setSupplierData();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setSupplierData = () => {
    const { retrievedSupplier, listProduct, listStock } = this.props;

    let supplierProducts = listProduct['hydra:member']
      .filter(product => (
        product.supplier && product.supplier['@id'] === retrievedSupplier['@id']
      ))
      .map(item => ({
        id: item.id,
        type: item['@type'],
        reference: item.reference,
        label: item.label,
        '@id': item['@id'],
        cost: item.purchaseUnitCost,
        updatedCost: item.purchaseUnitCost,
        lastUpdated: item.lastUpdated,
        percentage: 0,
        checked: false,
      }));

    const supplierStock = listStock['hydra:member'].map((stock) => {
      const product = find(listProduct['hydra:member'], {
        '@id': stock.product,
      });

      return ({
        id: stock.id,
        type: stock['@type'],
        reference: product.reference,
        label: product.label,
        '@id': stock['@id'],
        cost: stock.supplierPrice,
        updatedCost: stock.supplierPrice,
        lastUpdated: stock.lastUpdated,
        percentage: 0,
        checked: false,
      });
    });

    supplierProducts = supplierProducts.concat(supplierStock);

    this.setState({
      supplierData: supplierProducts,
    });
  };

  handleInputChange = (e, item) => {
    const { name, value } = e.target;
    const { supplierData } = this.state;

    const newData = supplierData.map((obj) => {
      if (obj['@id'] === item['@id']) {
        const newObj = {
          ...obj,
          [name]: value === '' ? '0' : inputFormat(value),
        };

        return this.updateValues(newObj, name);
      }
      return obj;
    });

    this.setState({
      supplierData: newData,
    });
  };

  updateValues = (item, name = 'percentage') => {
    switch (name) {
      case 'updatedCost':
        item.percentage = (((item.updatedCost - item.cost) / item.cost) * 100).toFixed(2);
        break;
      case 'percentage':
        item.updatedCost = (((100 + parseFloat(item.percentage)) * item.cost) / 100).toFixed(2);
        break;
      default:
        break;
    }

    return item;
  };

  handleDateChange = (date) => {
    this.setState({
      effectDate: date,
    });
  };

  handleCheckboxChange = (item) => {
    const { supplierData } = this.state;

    const newArray = supplierData.map((obj) => {
      if (item['@id'] === obj['@id']) {
        obj.checked = !obj.checked;
      }

      return obj;
    });

    this.setState({
      supplierData: newArray,
    });
  };

  handlePercentageApply = (percentage) => {
    const { supplierData } = this.state;

    const arr = supplierData.map((item) => {
      if (item.checked) {
        return this.updateValues({
          ...item,
          percentage: parseFloat(percentage).toFixed(2),
        });
      }

      return item;
    });

    this.setState({
      supplierData: arr,
    });
  };

  handleSubmit = () => {
    const { supplierData, effectDate } = this.state;
    const { selectedCompany, postBulkPrice, t } = this.props;
    let isValid = true;

    this.setState({
      effectDateError: false,
    });

    if (!effectDate) {
      isValid = false;

      this.setState({
        effectDateError: true,
      });
    }

    if (!find(supplierData, 'checked')) {
      isValid = false;

      toast.error(t('documentNoArticlesSelected'));
    }

    if (!isValid) {
      return;
    }

    const stock = supplierData
      .filter(item => item.checked && item.type === 'Stock')
      .map(item => ({
        id: item.id,
        supplierPrice: item.updatedCost,
      }));
    const product = supplierData
      .filter(item => item.checked && item.type === 'Product')
      .map(item => ({
        id: item.id,
        purchaseUnitCost: item.updatedCost,
      }));

    const data = {
      company: selectedCompany['@id'],
      date: effectDate.format('YYYY-MM-DD'),
      product,
      stock,
    };

    postBulkPrice(data);
  };

  render() {
    const { supplierData, effectDate, effectDateError } = this.state;
    const {
      match,
      t,
      loadingListProduct,
      loadingListStock,
      loadingCreateBulkPrice,
      createdBulkPrice,
    } = this.props;

    if (!isEmpty(createdBulkPrice)) {
      return <Redirect push to="/articles/prices/" />;
    }

    return (
      <ContainerGeneral>
        <BackHeader title={t('supplierShow')} to={`/contacts/suppliers/${match.params.id}`} />

        <GridWrapper width={10} rowClassName="p-b-0">
          <SmallForm>
            <Form.Group inline>
              <Form.Input
                label={t('formEffectDate')}
                control={DatePicker}
                selected={effectDate}
                minDate={moment()}
                onChange={this.handleDateChange}
                locale="fr"
                error={effectDateError}
                autoComplete="off"
                disabled={loadingListProduct || loadingListStock || loadingCreateBulkPrice}
              />
            </Form.Group>
          </SmallForm>
        </GridWrapper>

        <PricesTable
          data={supplierData}
          loading={loadingListProduct || loadingListStock || loadingCreateBulkPrice}
          onChecked={this.handleCheckboxChange}
          onChange={this.handleInputChange}
          percentageApply={this.handlePercentageApply}
        />

        <div className="fixed-button">
          <SaveButton onClick={this.handleSubmit} />
        </div>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: page => dispatch(listProduct(page)),
  getSupplierStocks: page => dispatch(listStock(page)),
  postBulkPrice: data => dispatch(createBulkPrice(data)),
  reset: () => {
    dispatch(resetListProduct());
    dispatch(resetListStock());
    dispatch(successCreateBulkPrice(null));
    dispatch(loadingCreateBulkPrice(false));
    dispatch(errorCreateBulkPrice(null));
  },
});

const mapStateToProps = state => ({
  retrievedSupplier: state.supplier.show.retrieved,

  listProduct: state.product.list.data,
  loadingListProduct: state.product.list.loading,

  listStock: state.stock.list.data,
  loadingListStock: state.stock.list.loading,

  createdBulkPrice: state.bulkPrice.create.created,
  loadingCreateBulkPrice: state.bulkPrice.create.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(UpdatePrices);

export default withTranslation()(withRouter(Main));
