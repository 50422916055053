import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { retrieve } from 'actions/company-settings/update';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setQuoteList } from 'actions/quote/list';
import { list as listCustomer, reset as resetListCustomer } from 'actions/customer/list';
import { create as createQuote, error as errorQuote, loading as loadingQuote, success as successQuote } from 'actions/quote/create';
import { change as changeQuote, reset as resetChangeQuote } from 'actions/quote/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { reset as resetUpdateQuote, retrieve as retrieveQuote, retrieveSuccess as updateQuoteSuccess, update as updateQuote } from 'actions/quote/update';
import { list as listArticle, reset as resetArticle } from 'actions/article/list';
import { list as listModel, reset as resetModel } from 'actions/document-model/list';
import { create as createFollowUp, error as errorFollowUp, loading as loadingFollowUp, success as successFollowUp } from 'actions/follow-up/create';
import { selectDocument } from 'actions/user-companies/select';
import { Dropdown, Form, Message } from 'semantic-ui-react';
import { CreateCustomer, Document, DocumentTimer, EssorButton } from 'components';
import DatePicker from 'react-datepicker';
import { getCustomerList, getEstimationOptions, getFollowUpTypes, getModelList, getOfferReasons } from 'components/documents/documentOptions';
import { handleNextPropLists } from 'components/documents/documentCommon';
import { isDraft, isNotDraft, isPending } from 'utils/documentStatus';
import SaveButtons from 'components/documents/saveButtons';
import ActionButtons from 'components/documents/actionButtons';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import SaveButton from 'components/buttons/SaveButton';
import TwelveForm from 'layouts/TwelveForm';
import TitleHeader from 'components/pageHeaders/TitleHeader';
import InlineField from 'layouts/InlineField';
import BackHeader from 'components/pageHeaders/BackHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SectionLoader from 'components/sectionLoader';
import { keyVal } from 'utils/functions';
import ErrorMessages from 'routes/admin/business/ErrorMessages';
import ReplaceContentModal from 'routes/admin/business/ReplaceContentModal';
import { QUOTE } from 'utils/documentType';
import WrongDocument from '../../../../../layouts/error-pages/WrongDocument';

moment.locale('fr');

class EditQuote extends Component {
  static contextType = PrinterContext;

  state = {
    status: null,
    quoteId: null,
    documentData: {},

    modelList: null,
    selectedModel: null,
    customerList: null,
    selectedCustomer: null,
    openCustomerModal: false,

    followUpDate: null,
    followUpType: null,
    followUpComment: '',
    estimation: null,
    quoteReason: null,
    decisionFactors: '',

    address: '',
    zipCode: '',
    city: '',
    country: 'France',

    followUpDateError: false,
    followUpTypeError: false,
    estimationError: false,
    quoteReasonError: false,
    decisionFactorsError: false,

    isCreate: false,
    timeSpent: 0,
    loadedQuote: false,
    messageError: [],
    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getQuote,
      getModelList,
      getCustomerList,
      getArticles,
      selectedCompany,
      match,
    } = this.props;

    if (match.params.id) {
      getQuote(`/quotes/${match.params.id}`);
    }

    if (match.path === '/business/quotes/create') {
      this.setState({
        isCreate: true,
      });
    }

    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getModelList(`/document_models?company=${selectedCompany.id}`);
    getCustomerList(`/customers?company=${selectedCompany.id}`);
    getArticles(`/articles/${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedQuote)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedQuote.id !== prevState.quoteId
        || nextProps.retrievedQuote.status !== prevState.status)) {
      const documentData = {
        documentId: isNotDraft(nextProps.retrievedQuote)
          ? nextProps.retrievedQuote.uniqueID
          : '-',
        content: nextProps.retrievedQuote.content,
        reference: nextProps.retrievedQuote.reference,
        note: nextProps.retrievedQuote.note,
        status: nextProps.retrievedQuote.status,
        creationDate: moment(nextProps.retrievedQuote.creationDate),
        responseDate: moment(nextProps.retrievedQuote.responseDate),
        invoice: nextProps.retrievedQuote.invoice,
        purchaseOrder: nextProps.retrievedQuote.purchaseOrder,
      };

      return {
        documentData,
        quoteId: nextProps.retrievedQuote.id,
        status: nextProps.retrievedQuote.status,
        selectedCustomer: nextProps.retrievedQuote.customer['@id'],
        quoteReason: nextProps.retrievedQuote.quoteReason || null,
        estimation: nextProps.retrievedQuote.estimation || null,
        decisionFactors: nextProps.retrievedQuote.decisionFactors || '',
        address: nextProps.retrievedQuote.siteAddress.address,
        zipCode: nextProps.retrievedQuote.siteAddress.zipCode,
        city: nextProps.retrievedQuote.siteAddress.city,
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedQuote.timeSpent,
        loadedQuote: true,
      };
    }

    const handlerReturn = handleNextPropLists(nextProps, prevState);
    if (handlerReturn !== null) {
      return handlerReturn;
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedQuote,
      retrievedError,

      createdQuote,
      updatedQuote,
      changedQuote,
      listQuote,
      createdFollowUp,

      setQuote,
      changeQuote,
      selectDocument,
      setQuoteList,
      resetFollowUp,
      history,
    } = this.props;

    const { setFileName } = this.context;

    const { submitAction } = this.state;

    if (retrievedError) {
      return;
    }

    if (!isEmpty(retrievedQuote) && retrievedQuote !== prevProps.retrievedQuote) {
      selectDocument(retrievedQuote);
    }

    if (isEmpty(retrievedQuote) && retrievedQuote !== prevProps.retrievedQuote) {
      selectDocument(null);
    }

    if (!isEmpty(changedQuote)
      && changedQuote['@id'] === selectedDocument['@id']
      && changedQuote.status >= 4) {
      selectDocument(changedQuote);
    }

    if (!isEmpty(createdQuote) && createdQuote !== prevProps.createdQuote) {
      listQuote['hydra:member'].push(createdQuote);
      listQuote['hydra:totalItems'] += 1;
      setQuoteList(listQuote);

      switch (submitAction) {
        case 'save':
          history.push('/business/quotes');
          break;
        case 'edit':
          history.push(`/business/quotes/${createdQuote.id}/edit`);
          break;
        case 'validate':
          history.push(`/business/quotes/${createdQuote.id}/edit`);
          changeQuote(`/quotes/${createdQuote.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(updatedQuote) && updatedQuote !== prevProps.updatedQuote) {
      const index = findIndex(listQuote['hydra:member'], keyVal('id', updatedQuote.id));

      listQuote['hydra:member'][index] = updatedQuote;
      setQuoteList(listQuote);

      switch (submitAction) {
        case 'save':
          history.push('/business/quotes');
          break;
        case 'validate':
          changeQuote(`/quotes/${updatedQuote.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(changedQuote)
      && changedQuote !== prevProps.changedQuote
      && changedQuote['@id'] === selectedDocument['@id']) {
      const index = findIndex(listQuote['hydra:member'], {
        id: changedQuote.id,
      });

      setQuote(changedQuote);
      listQuote['hydra:member'][index] = changedQuote;
      setQuoteList(listQuote);
    }

    if (!isEmpty(createdFollowUp) && createdFollowUp !== prevProps.createdFollowUp) {
      resetFollowUp();
    }

    if (retrievedQuote) {
      setFileName(retrievedQuote.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  clearErrors = () => {
    this.setState({
      messageError: [],
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleOpenCreateCustomer = (e) => {
    e.preventDefault();

    this.setState({
      openCustomerModal: true,
      hasDataChanged: true,
    });
  };

  closeCustomerModal = () => {
    this.setState({
      openCustomerModal: false,
    });
  };

  customerCreateSuccess = (customer) => {
    const { customerList } = this.state;

    customerList.push(customer);

    this.setState({
      customerList,
      selectedCustomer: customer['@id'],
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFollowUpDateChange = (date) => {
    this.setState({
      followUpDate: date,
    });
  };

  onSubmit = (data, msgError) => {
    this.setState({
      validDocument: false,
    });

    const {
      status,
      selectedCustomer,
      estimation,
      quoteReason,
      decisionFactors,
      address,
      zipCode,
      city,
      country,
    } = this.state;

    const { postQuote, formTime, updateQuote, retrievedQuote, selectedCompany } = this.props;

    this.setState({
      followUpDateError: false,
      followUpTypeError: false,
      estimationError: false,
      quoteReasonError: false,
      decisionFactorsError: false,
    });

    let valid = true;

    if (status >= 4) {
      if (!estimation) {
        valid = false;

        this.setState({
          estimationError: true,
        });
      }

      if (!quoteReason) {
        valid = false;

        this.setState({
          quoteReasonError: true,
        });
      }

      if (decisionFactors.trim() === '') {
        valid = false;

        this.setState({
          decisionFactorsError: true,
        });
      }
    }

    if (!valid || !isEmpty(msgError)) {
      this.setState({
        messageError: msgError,
      });
      return;
    }

    data.customer = selectedCustomer;
    data.timeSpent = formTime;
    data.company = selectedCompany['@id'];

    if (isNotDraft(retrievedQuote)) {
      data.quoteReason = quoteReason;
      data.estimation = estimation;
      data.decisionFactors = decisionFactors;

      data.siteAddress = {
        address,
        zipCode,
        city,
        country,
      };
    }

    this.setState({
      hasDataChanged: false,
    });

    retrievedQuote ? updateQuote(retrievedQuote, data) : postQuote(data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
    });
  };

  onModelChange = (content) => {
    this.setState({
      validContent: false,
    });

    if (content.length > 0) {
      this.setState({
        warningContentModal: true,
      });
    } else {
      this.setModelContent();
    }
  };

  onWarningClose = () => {
    this.setState({
      selectedModel: null,
      warningContentModal: false,
    });
  };

  setModelContent = (append = false) => {
    const { selectedModel, modelList, documentData } = this.state;

    const model = find(modelList, keyVal('@id', selectedModel));

    let body = documentData.content ? documentData.content.body : [];
    if (append) {
      body.push(...model.content.body);
    } else {
      body = model.content ? model.content.body : [];
    }
    documentData.content = model.content;

    this.setState({
      documentData: {
        ...documentData,
        content: {
          ...documentData.content,
          body,
        },
      },
      warningContentModal: false,
    });
  };

  handleModelChange = (e, { value }) => {
    this.setState({
      selectedModel: value,
      validContent: true,
      hasDataChanged: true,
    });
  };

  handleFollowUpSubmit = () => {
    const {
      followUpDate,
      followUpType,
      followUpComment,
    } = this.state;

    const { postFollowUp, retrievedQuote, selectedCompany } = this.props;

    this.setState({
      followUpDateError: false,
      followUpTypeError: false,
    });

    let valid = true;

    if (!followUpDate) {
      valid = false;

      this.setState({
        followUpDateError: true,
      });
    }

    if (!followUpType) {
      valid = false;

      this.setState({
        followUpTypeError: true,
      });
    }

    if (!valid) {
      return;
    }

    const data = {
      quote: retrievedQuote['@id'],
      company: selectedCompany['@id'],
      date: followUpDate.format(),
      type: followUpType.toString(),
    };

    if (followUpComment.trim() !== '') {
      data.comment = followUpComment;
    }

    postFollowUp(data);
  };

  handleAction = (type) => {
    const { printDocument, changeQuote, selectedDocument } = this.props;

    if (selectedDocument) {
      if (type === 'edit') {
        changeQuote(`/quotes/${selectedDocument.id}/to_draft`, 'PUT');
      } else if (type === 'print') {
        printDocument(`/quotes/${selectedDocument.id}/print`);
      }
    }
  };

  render() {
    const {
      status,
      documentData,

      warningContentModal,

      modelList,
      selectedModel,
      customerList,
      selectedCustomer,
      openCustomerModal,

      followUpDate,
      followUpType,
      followUpComment,
      estimation,
      quoteReason,
      decisionFactors,

      address,
      zipCode,
      city,

      followUpDateError,
      followUpTypeError,
      estimationError,
      quoteReasonError,
      decisionFactorsError,

      validDocument,
      validContent,
      isCreate,
      timeSpent,
      hasDataChanged,
      loadedQuote,
      messageError,
    } = this.state;

    const {
      retrievedError,
      loadingPrintDocument,
      listCompanySettings,
      loadingListModel,
      loadingListCustomer,
      loadingCompanySettings,
      loadingRetrieveQuote,
      loadingCreateQuote,
      loadingUpdateQuote,
      loadingChangeQuote,
      loadingCreateFollowUp,
      match,
      t,
    } = this.props;

    const customers = getCustomerList(customerList);
    const models = getModelList(modelList);
    const offerReasons = getOfferReasons(listCompanySettings);
    const followUpTypeOptions = getFollowUpTypes();
    const estimationOptions = getEstimationOptions();
    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, keyVal('@id', selectedCustomer));
    }

    if (retrievedError) {
      return <WrongDocument />;
    }

    if (loadingChangeQuote) {
      let label;

      if (loadingChangeQuote.includes('duplicate')) {
        label = t('documentDuplicateLoader', {
          document: t('documentNumbering_QUOTE').toLowerCase(),
        });
      } else if (loadingChangeQuote.includes('pending')) {
        label = t('documentValidate');
      } else if (loadingChangeQuote.includes('model')) {
        label = t('documentToModelLoader');
      } else if (loadingChangeQuote.includes('purchase')) {
        label = t('documentTransform', {
          from: t('documentNumbering_QUOTE'),
          to: t('documentNumbering_PURCHASE_ORDER'),
        });
      } else if (loadingChangeQuote.includes('invoice')) {
        label = t('documentTransform', {
          from: t('documentNumbering_QUOTE'),
          to: t('documentNumbering_INVOICE'),
        });
      }

      return (
        <ContainerGeneral>
          <SectionLoader status={!!loadingChangeQuote} label={label} />
        </ContainerGeneral>
      );
    }

    const isLoading = loadingCreateQuote || loadingUpdateQuote || loadingChangeQuote;
    const formLoading = loadingRetrieveQuote || loadingCreateQuote || loadingUpdateQuote;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        {(loadedQuote || isCreate) && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveQuote}
          />
        )}

        <BackHeader
          title={match.params.id ? t('quotesUpdateTitle') : t('quotesCreateTitle')}
          to="/business/quotes/"
        />

        <ActionButtons
          isPending={isPending(documentData)}
          handleAction={this.handleAction}
          loadingEditDocument={loadingPrintDocument}
          loadingPrintDocument={loadingPrintDocument}
        />

        {isDraft(documentData) && (
          <TwelveForm loading={isLoading}>
            <Form.Group inline>
              <Form.Select
                label={t('formExistingCustomer')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                fluid
                search
                selection
                loading={loadingListCustomer}
                disabled={loadingListCustomer}
                noResultsMessage="No results"
                options={customers}
                name="selectedCustomer"
                onChange={this.handleSelectChange}
                value={selectedCustomer}
                selectOnBlur={false}
              />
            </Form.Group>

            <InlineField>
              <label>{t('formNewCustomer')}</label>
              <EssorButton icon type="plus" onClick={this.handleOpenCreateCustomer} />
            </InlineField>

            {customerObject && (
              <Form.Group inline>
                <Form.Select
                  label={t('quoteModel')}
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  fluid
                  search
                  selection
                  loading={loadingListModel}
                  disabled={loadingListModel}
                  noResultsMessage="No results"
                  options={models}
                  name="selectedModel"
                  onChange={this.handleModelChange}
                  value={selectedModel}
                  selectOnBlur={false}
                  clearable
                />
              </Form.Group>
            )}
          </TwelveForm>
        )}

        <CreateCustomer
          modalOpen={openCustomerModal}
          onClose={this.closeCustomerModal}
          onSuccess={customer => this.customerCreateSuccess(customer)}
        />

        <ReplaceContentModal
          warningContentModal={warningContentModal}
          onWarningClose={this.onWarningClose}
          setModelContent={this.setModelContent}
        />

        <hr />

        <Message negative className="center" hidden={status !== 8}>{t('quoteLost')}</Message>

        {customerObject && (
          <React.Fragment>
            <Document
              type={QUOTE}
              status={status}
              documentData={documentData}
              customer={customerObject}
              validData={validDocument}
              validContent={validContent}
              getData={(documentData, msgError) => this.onSubmit(documentData, msgError)}
              getContent={content => this.onModelChange(content)}
            />

            {(status && status >= 4 && status < 8) && (
              <TwelveForm loading={formLoading}>
                <Form.Group inline>
                  <Form.Select
                    label={t('formQuoteReason')}
                    control={Dropdown}
                    placeholder={t('formPHSelect')}
                    fluid
                    search
                    selection
                    selectOnBlur={false}
                    loading={loadingCompanySettings}
                    disabled={loadingCompanySettings}
                    noResultsMessage="No results"
                    options={offerReasons}
                    name="quoteReason"
                    onChange={this.handleSelectChange}
                    value={quoteReason}
                    error={quoteReasonError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Select
                    label={t('formEstimation')}
                    control={Dropdown}
                    placeholder={t('formPHSelect')}
                    fluid
                    selection
                    selectOnBlur={false}
                    options={estimationOptions}
                    name="estimation"
                    onChange={this.handleSelectChange}
                    value={estimation}
                    error={estimationError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.TextArea
                    label={t('quoteDecisionFactors')}
                    name="decisionFactors"
                    value={decisionFactors}
                    onChange={this.handleInputChange}
                    error={decisionFactorsError}
                  />
                </Form.Group>

                <div className="margin-top">
                  <TitleHeader title={t('quoteConstructionSite')} />
                  <Form.Group inline>
                    <Form.Input
                      label={t('formAddress')}
                      placeholder={t('formPHAddress')}
                      name="address"
                      value={address}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formZipCode')}
                      placeholder={t('formPHZipCode')}
                      name="zipCode"
                      value={zipCode}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formCity')}
                      placeholder={t('formPHCity')}
                      name="city"
                      value={city}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                </div>
              </TwelveForm>
            )}

            <ErrorMessages messages={messageError} />

            <SaveButtons
              disabled={isLoading}
              loading={isLoading}
              onClick={this.handleOnSubmit}
              isDraft={isDraft(documentData)}
            />
          </React.Fragment>
        )}

        {(status && status >= 4 && status < 8) && (
          <TwelveForm loading={loadingCreateFollowUp || loadingUpdateQuote}>
            <div className="section-general">
              <hr />
            </div>
            <TitleHeader title={t('followUpCreateTitle')} />
            <Form.Group inline>
              <Form.Input
                label={t('formFollowUpDate')}
                name="followUpDate"
                control={DatePicker}
                selected={followUpDate}
                minDate={moment()}
                onChange={this.handleFollowUpDateChange}
                locale="fr"
                autoComplete="off"
                error={followUpDateError}
              />
            </Form.Group>

            <Form.Group inline>
              <Form.Select
                label={t('formFollowUpType')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                fluid
                selection
                selectOnBlur={false}
                options={followUpTypeOptions}
                name="followUpType"
                onChange={this.handleSelectChange}
                value={followUpType}
                error={followUpTypeError}
              />
            </Form.Group>

            <Form.Group inline>
              <Form.TextArea
                label={t('formComments')}
                name="followUpComment"
                value={followUpComment}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <SaveButton onClick={this.handleFollowUpSubmit} floated="right" />
          </TwelveForm>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  getQuote: page => dispatch(retrieveQuote(page)),
  setQuote: quote => dispatch(updateQuoteSuccess(quote)),
  getCustomerList: page => dispatch(listCustomer(page)),
  getModelList: page => dispatch(listModel(page)),
  getArticles: page => dispatch(listArticle(page)),
  postQuote: data => dispatch(createQuote(data)),
  updateQuote: (item, data) => dispatch(updateQuote(item, data)),
  changeQuote: (route, method) => dispatch(changeQuote(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setQuoteList: quotes => dispatch(setQuoteList(quotes)),
  selectDocument: document => dispatch(selectDocument(document)),
  postFollowUp: data => dispatch(createFollowUp(data)),
  resetFollowUp: () => {
    dispatch(successFollowUp(null));
    dispatch(loadingFollowUp(false));
    dispatch(errorFollowUp(null));
  },
  reset: () => {
    dispatch(resetListCustomer());

    dispatch(resetArticle());
    dispatch(resetModel());

    dispatch(resetChangeQuote());

    dispatch(resetUpdateQuote());

    dispatch(successQuote(null));
    dispatch(loadingQuote(false));
    dispatch(errorQuote(null));

    dispatch(successFollowUp(null));
    dispatch(loadingFollowUp(false));
    dispatch(errorFollowUp(null));
  },
});

const mapStateToProps = state => ({
  listQuote: state.quote.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  listModel: state.model.list.data,
  loadingListModel: state.model.list.loading,
  errorListModel: state.model.list.error,

  createdQuote: state.quote.create.created,
  loadingCreateQuote: state.quote.create.loading,
  errorCreateQuote: state.quote.create.error,

  retrievedQuote: state.quote.update.retrieved,
  retrievedError: state.quote.update.retrieveError,
  loadingRetrieveQuote: state.quote.update.retrieveLoading,
  errorRetrieveQuote: state.quote.update.retrieveError,
  updatedQuote: state.quote.update.updated,
  loadingUpdateQuote: state.quote.update.updateLoading,
  errorUpdateQuote: state.quote.update.updateError,

  changedQuote: state.quote.change.changed,
  loadingChangeQuote: state.quote.change.loading,

  createdFollowUp: state.followUp.create.created,
  loadingCreateFollowUp: state.followUp.create.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditQuote);

export default withTranslation()(withRouter(Main));
