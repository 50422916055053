import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { reset as resetProductRetrieved } from 'actions/product/show';
import { reset as resetProductList } from 'actions/product/list';
import { create, error, loading, success } from 'actions/product/create';
import { reset as resetUpdateProduct, update as updateProduct } from 'actions/product/update';
import { create as createMedia, error as errorMedia, loading as loadingMedia, success as successMedia } from 'actions/media/create';
import { list as listSettings, reset as resetListCompanySettings } from 'actions/company-settings/list';
import { list as listSupplier, reset as resetListSupplier } from 'actions/supplier/list';
import { Dropdown, Form, Image, Input, Message } from 'semantic-ui-react';
import uploadDefaultImage from 'assets/images/uploadDefault.png';
import { withTranslation } from 'react-i18next';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import InlineField from 'layouts/InlineField';
import SaveButton from 'components/buttons/SaveButton';
import ContainerGeneral from 'layouts/ContainerGeneral';
import InlineCurrencyInput from 'layouts/InlineCurrencyInput';
import CurrencyCleave from 'components/input/CurrencyCleave';
import InlineInput from 'layouts/InlineInput';
import { getCalculationClass, getMarginName, modeIsPercent } from 'utils/marginCalculation';
import Big from 'big.js';

class CreateProduct extends Component {
  state = {
    id: null,

    company: null,
    companyError: false,

    label: '',
    labelError: false,

    unit: null,
    unitError: false,

    purchaseUnitCost: Big(0),
    purchaseUnitCostError: false,

    unitPrice: Big(0),
    unitPriceError: false,

    marginRate: Big(0),
    marginRateError: false,

    reference: '',
    picture: null,
    stockManagement: false,
    supplier: null,
    supplyTime: '',
    imagePreview: null,
    companySettingsList: null,
    supplierList: null,
    isPictureCreated: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getSuppliers,
      getCompanySettings,
      selectedCompany,
    } = this.props;

    getSuppliers(`/suppliers?company=${selectedCompany.id}`);
    getCompanySettings(`/company_settings?name=UNITS&company=${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.retrieved && !isEmpty(nextProps.match.params)
      && nextProps.retrieved.id !== prevState.id) {
      const { marginRate, purchaseUnitCost } = nextProps.retrieved;
      const calc = getCalculationClass(nextProps.selectedCompany);

      return {
        id: nextProps.retrieved.id,
        reference: nextProps.retrieved.reference,
        imagePreview: nextProps.retrieved.picture
          ? nextProps.retrieved.picture.contentUrl
          : null,
        label: nextProps.retrieved.label,
        unit: nextProps.retrieved.unit,
        stockManagement: nextProps.retrieved.stockManagement,
        marginRate,
        supplier: nextProps.retrieved.supplier ? nextProps.retrieved.supplier['@id'] : null,
        supplyTime: nextProps.retrieved.supplyTime,
        purchaseUnitCost,
        unitPrice: calc.getBigPriceFromCost(Big(purchaseUnitCost), Big(marginRate)),
      };
    }

    if (nextProps.selectedCompany && prevState.company !== nextProps.selectedCompany['@id']) {
      return {
        company: nextProps.selectedCompany['@id'],
      };
    }

    if (!isEmpty(nextProps.listSuppliers) && nextProps.listSuppliers['hydra:member'] !== prevState.supplierList) {
      return {
        supplierList: nextProps.listSuppliers['hydra:member'],
      };
    }

    if (!isEmpty(nextProps.listCompanySettings) && nextProps.listCompanySettings['hydra:member'][0] !== prevState.companySettingsList) {
      return {
        companySettingsList: nextProps.listCompanySettings['hydra:member'][0],
      };
    }

    if (nextProps.successMedia && !prevState.isPictureCreated) {
      const {
        company,
        reference,
        label,
        unit,
        stockManagement,
        marginRate,
        supplier,
        supplyTime,
        purchaseUnitCost,
        unitPrice,
      } = prevState;

      let data = {
        company,
        reference,
        label,
        unit,
        stockManagement,
        marginRate,
        purchaseUnitCost,
        unitPrice,
        picture: nextProps.successMedia['@id'],
      };

      if (supplier) {
        data = {
          ...data,
          supplier: JSON.parse(supplier)['@id'],
          supplyTime,
        };
      }

      isEmpty(nextProps.retrieved)
        ? nextProps.postProduct(data)
        : nextProps.updateProduct(nextProps.retrieved, data);

      return {
        isPictureCreated: true,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, supplierList } = this.state;
    const { successProduct, updated, resetProductList, resetProductRetrieved } = this.props;

    if (id && prevState.supplierList !== supplierList) {
      this.setSupplier();
    }

    if (!isEmpty(successProduct) && successProduct !== prevProps.successProduct) {
      resetProductList();
    }

    if (!isEmpty(updated) && updated !== prevProps.updated) {
      resetProductRetrieved();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  setSupplier = () => {
    const { supplier, supplierList } = this.state;
    const supply = find(supplierList, {
      '@id': supplier,
    });

    this.setState({
      supplier: JSON.stringify(supply),
    });
  };

  handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleFloatChange = (name, value) => {
    const { selectedCompany } = this.props;
    const calculation = getCalculationClass(selectedCompany);

    this.setState({
      [name]: Big(value || 0),
      hasDataChanged: true,
    }, () => {
      const { marginRate, purchaseUnitCost, unitPrice } = this.state;

      switch (name) {
        case 'marginRate':
        case 'purchaseUnitCost': {
          this.setState({
            unitPrice: calculation.getBigPriceFromCost(Big(purchaseUnitCost), Big(marginRate)),
          });
          break;
        }
        case 'unitPrice': {
          this.setState({
            marginRate: calculation.getBigMargin(purchaseUnitCost, unitPrice),
          });
          break;
        }
        default:
          break;
      }
    });
  };

  handleCheckBoxChange = (e, value) => {
    e.preventDefault();

    const { name } = value;

    this.setState(prevState => (
      {
        [name]: !prevState[name],
        supplier: null,
        supplyTime: '',
        purchaseUnitCost: Big(0),
        unitPrice: Big(0),
        hasDataChanged: true,
      }
    ));
  };

  handleSelectChange = (e, { value, name }) => {
    const prevValue = this.state[name]; // eslint-disable-line

    if (prevValue !== value) {
      this.setState({
        [name]: value,
        hasDataChanged: true,
      });

      if (name === 'supplier') {
        this.setState({
          supplyTime: JSON.parse(value).supplyTime,
          hasDataChanged: true,
        });
      }
    }
  };

  handlePictureChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState({
          picture: file,
          imagePreview: reader.result,
          hasDataChanged: true,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        picture: null,
        imagePreview: null,
        hasDataChanged: true,
      });
    }
  };

  handleOnSubmit = () => {
    const {
      company,
      reference,
      label,
      unit,
      picture,
      stockManagement,
      marginRate,
      supplier,
      supplyTime,
      purchaseUnitCost,
    } = this.state;

    const {
      postProduct,
      postImage,
      updateProduct,
      retrieved,
      selectedCompany,
    } = this.props;
    let isValid = true;

    this.setState({
      companyError: false,
      labelError: false,
      unitError: false,
      marginRateError: false,
      purchaseUnitCostError: false,
    });

    if (label === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (!unit) {
      isValid = false;

      this.setState({
        unitError: true,
      });
    }

    if (marginRate.lt(1)) {
      isValid = false;

      this.setState({
        marginRateError: true,
      });
    }

    if (!isValid) return;

    if (picture) {
      const data = new FormData();

      data.append('file', picture);
      data.append('company', selectedCompany.id);
      postImage(data);

      return;
    }

    let data = {
      company,
      reference,
      label,
      unit,
      stockManagement,
      marginRate: Big(marginRate),
      purchaseUnitCost: Big(purchaseUnitCost || 0),
    };

    if (supplier) {
      data = {
        ...data,
        supplier: JSON.parse(supplier)['@id'],
      };
    }

    if (!stockManagement) {
      data = {
        ...data,
        supplyTime,
      };
    }

    this.setState({
      hasDataChanged: false,
    });

    retrieved ? updateProduct(retrieved, data) : postProduct(data);
  };

  render() {
    const {
      companySettingsList,
      supplierList,
      reference,
      label,
      unit,
      stockManagement,
      marginRate,
      supplier,
      supplyTime,
      purchaseUnitCost,
      unitPrice,
      labelError,
      unitError,
      marginRateError,
      purchaseUnitCostError,
      unitPriceError,
      imagePreview,
      hasDataChanged,
    } = this.state;

    const {
      selectedCompany,
      loadingProduct,
      loadingMedia,
      errorProduct,
      successProduct,
      updated,
      retrieveLoading,
      updateLoading,
      loadingCompanySettings,
      loadingSuppliers,
      match,
      t,
    } = this.props;

    const updateID = match.params.id;
    const formLoading = loadingProduct || loadingMedia;

    let companySettings = [];
    let supplies = [];

    if (companySettingsList && companySettingsList.value.length > 0) {
      companySettings = companySettingsList.value.map((setting, index) => ({
        key: index,
        text: setting.label,
        value: setting.unit,
      }));
    }

    if (supplierList && supplierList.length > 0) {
      supplies = supplierList.map((supply, index) => ({
        key: index,
        text: supply.supplierName,
        value: JSON.stringify(supply),
      }));
    }

    if (updated) {
      return <Redirect push to={`/articles/products/${updateID}`} />;
    }

    if (successProduct) {
      return (
        <Redirect
          push
          to={{
            pathname: `/articles${successProduct['@id']}`,
            createStock: stockManagement,
          }}
        />
      );
    }

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <BackHeader
          title={updateID ? t('productsUpdateTitle') : t('productsCreateTitle')}
          to={updateID ? `/articles/products/${updateID}` : '/articles/products'}
        />

        <TwelveForm loading={formLoading || retrieveLoading || updateLoading}>
          <Form.Group inline>
            <Form.Input
              label={t('formLabel')}
              name="label"
              placeholder={t('formPHLabel')}
              value={label}
              onChange={this.handleTextChange}
              error={labelError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formReference')}
              name="reference"
              placeholder={t('formPHReference')}
              value={reference}
              onChange={this.handleTextChange}
            />
          </Form.Group>

          <InlineField>
            <label htmlFor="picture">{t('formPicture')}</label>
            <Input
              type="file"
              id="picture"
              name="picture"
              accept="image/*"
              onChange={this.handlePictureChange}
            />
          </InlineField>

          <InlineField>
            <label />
            {imagePreview ? <Image src={imagePreview} /> : <Image src={uploadDefaultImage} />}
          </InlineField>

          <Form.Group inline>
            <Form.Select
              label={t('formUnit')}
              control={Dropdown}
              placeholder={t('formPHSelect')}
              disabled={loadingCompanySettings}
              selectOnBlur={false}
              fluid
              search
              selection
              loading={loadingCompanySettings}
              noResultsMessage="No results"
              options={companySettings}
              name="unit"
              onChange={this.handleSelectChange}
              value={unit}
              error={unitError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Checkbox
              label={t('formStockManagement')}
              name="stockManagement"
              checked={stockManagement}
              onChange={this.handleCheckBoxChange}
              readOnly={!!updateID}
            />
          </Form.Group>

          {!stockManagement && (
            <React.Fragment>
              <Form.Group inline>
                <Form.Select
                  label={t('formSupplier')}
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  selectOnBlur={false}
                  fluid
                  clearable
                  search
                  selection
                  loading={loadingSuppliers}
                  disabled={loadingSuppliers}
                  noResultsMessage="No results"
                  options={supplies}
                  name="supplier"
                  onChange={this.handleSelectChange}
                  value={supplier}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Input
                  label={t('formSupplyTime')}
                  name="supplyTime"
                  placeholder={t('formPHSupplyTime')}
                  value={supplyTime}
                  onChange={this.handleTextChange}
                />
              </Form.Group>

              <InlineCurrencyInput
                error={purchaseUnitCostError}
                title={t('formPurchaseUnitCost')}
                onChange={value => this.handleFloatChange('purchaseUnitCost', value)}
                name="purchaseUnitCost"
                placeholder={t('formPHPurchaseUnitCost')}
                value={purchaseUnitCost.toString()}
              />

              <InlineCurrencyInput
                error={unitPriceError}
                title={t('formSellingPrice')}
                onBlur={e => this.handleFloatChange('unitPrice', e.target.rawValue)}
                name="unitPrice"
                placeholder={t('formSellingPrice')}
                value={unitPrice.toString()}
              />

            </React.Fragment>
          )}

          <InlineInput
            error={marginRateError}
            title={getMarginName(selectedCompany)}
            label={modeIsPercent(selectedCompany) ? '%' : undefined}
            size="small"
          >
            <CurrencyCleave
              onChange={value => this.handleFloatChange('marginRate', value)}
              name="marginRate"
              value={marginRate.toString()}
            />
          </InlineInput>

          <Message negative hidden={!errorProduct}>{errorProduct}</Message>
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postProduct: data => dispatch(create(data)),
  updateProduct: (item, data) => dispatch(updateProduct(item, data)),
  postImage: file => dispatch(createMedia(file)),
  getCompanySettings: page => dispatch(listSettings(page)),
  getSuppliers: page => dispatch(listSupplier(page)),
  resetProductList: () => dispatch(resetProductList()),
  resetProductRetrieved: () => dispatch(resetProductRetrieved()),
  reset: () => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(successMedia(null));
    dispatch(loadingMedia(false));
    dispatch(errorMedia(null));
    dispatch(resetUpdateProduct());
    dispatch(resetListCompanySettings());
    dispatch(resetListSupplier());
  },
});

const mapStateToProps = state => ({
  successProduct: state.product.create.created,
  loadingProduct: state.product.create.loading,
  errorProduct: state.product.create.error,

  retrieveLoading: state.product.show.retrieveLoading,
  retrieved: state.product.show.retrieved,
  updateError: state.product.update.updateError,
  updateLoading: state.product.update.updateLoading,
  updated: state.product.update.updated,

  successMedia: state.media.create.created,
  loadingMedia: state.media.create.loading,
  errorMedia: state.media.create.error,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,
  listSuppliers: state.supplier.list.data,
  loadingSuppliers: state.supplier.list.loading,
  errorSuppliers: state.supplier.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateProduct);

export default withTranslation()(withRouter(Main));
