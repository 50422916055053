import React, { Component } from 'react';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { retrieve, update, reset } from 'actions/company-settings/update';
import { Form, Header, Grid, Message, Icon, Table, Modal } from 'semantic-ui-react';
import { EssorButton, toast } from 'components';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

class Units extends Component {
  state = {
    value: {},
    label: '',
    unit: '',
    labelError: false,
    unitError: false,
    isSettingsLoaded: false,
    dataClone: [],
    idModal: '',
    modal: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { retrieve, selectedCompany } = this.props;

    retrieve(`/company_settings?company=${selectedCompany.id}&name=UNITS`);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.retrieved && nextProps.retrieved['hydra:member'][0].name === 'UNITS' && !prevState.isSettingsLoaded) {
      return {
        isSettingsLoaded: true,
        value: nextProps.retrieved['hydra:member'][0].value,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  capitalizeLabel = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const { modal, dataClone } = this.state;

    if (modal) {
      dataClone[name] = value;
      this.setState({
        dataClone,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleAddItem = () => {
    const {
      label,
      unit,
      value,
    } = this.state;

    this.setState({
      labelError: false,
      unitError: false,
    });

    let isValid = true;

    if (label.trim() === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (unit.trim() === '') {
      isValid = false;

      this.setState({
        unitError: true,
      });
    }

    if (!isValid) return;

    const data = {
      label,
      unit,
    };

    value.push(data);

    this.setState({
      value,
      label: '',
      unit: '',
      hasDataChanged: true,
    });
  };

  openModal = (e, index) => {
    const dataClone = Object.assign({}, e);

    this.setState({
      dataClone,
      modal: true,
      idModal: index,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
      dataClone: null,
    });
  };

  handleDelete = (index) => {
    const { value } = this.state;

    value.splice(index, 1);

    this.setState({
      value,
      hasDataChanged: true,
    });
  };

  handleOnSubmit = () => {
    const { value, modal, idModal, dataClone } = this.state;

    const { update, retrieved, history, t } = this.props;

    if (modal) {
      const updateValueData = [...value];
      updateValueData[idModal] = dataClone;

      this.setState({
        value: updateValueData,
        modal: false,
        hasDataChanged: true,
      });
    } else {
      const data = {
        value,
      };

      this.setState({
        hasDataChanged: false,
      });

      update(retrieved['hydra:member'][0], data)
        .then(() => {
          toast.success(t('unitsUpdateSuccess'));
          history.push('/company/settings/units');
        });
      this.setState({
        hasDataChanged: false,
      });
    }
  };

  render() {
    const {
      label,
      unit,
      labelError,
      unitError,
      modal,
      value,
      dataClone,
      idModal,
      hasDataChanged,
    } = this.state;

    const { retrieveLoading, updateLoading, updateError, t } = this.props;

    return (
      <div className="section-container">
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('companiesUnits')}</Header>
            <EssorButton as={Link} to="/company/settings/units" type="chevron left" size="tiny" floated="right">
              {t('buttonBack')}
            </EssorButton>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Input
                      label={t('formLabel')}
                      name="label"
                      placeholder={t('formPHLabel')}
                      value={label}
                      onChange={this.handleInputChange}
                      error={labelError}
                    />
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Input
                      label={t('formUnit')}
                      name="unit"
                      placeholder={t('formPHUnit')}
                      value={unit}
                      onChange={this.handleInputChange}
                      error={unitError}
                    />
                  </Form.Group>

                  {updateError
                    && (
                      <Message negative>
                        <p>{updateError}</p>
                      </Message>
                    )
                  }
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="clearfix">
            <EssorButton type="plus" onClick={this.handleAddItem} size="tiny" floated="right">
              {t('buttonSubmit')}
            </EssorButton>
          </div>

          {!isEmpty(value) && (
            <React.Fragment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">

                      <div className="select-list margin-top">
                        <Table celled structured className="margin-bot">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                              <Table.HeaderCell>{t('formUnit')}</Table.HeaderCell>
                              <Table.HeaderCell />
                              <Table.HeaderCell />
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {!isEmpty(value) && value.map((unit, index) => (
                              <Table.Row key={index}>
                                <Table.Cell>
                                  {unit.label}
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="center">
                                  {unit.unit}
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="center">
                                  <Icon
                                    className="table-button"
                                    name="edit"
                                    data-id={index}
                                    onClick={() => this.openModal(unit, index)}
                                  />
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="center">
                                  <Icon
                                    className="table-button"
                                    name="trash alternate"
                                    data-id={index}
                                    onClick={() => this.handleDelete(index)}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}

                          </Table.Body>
                        </Table>
                      </div>

                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <div className="clearfix">
                <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
                  {t('buttonSave')}
                </EssorButton>
              </div>
            </React.Fragment>
          )}
          <Modal
            open={modal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className="full-content"
          >
            <Modal.Header>{t('companiesEditTitle')}</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>

                <Form className="margin-top-bot main-form" size="small">

                  {idModal !== null && dataClone
                    && (
                      Object.entries(dataClone).map((item, indexCl) => (
                        item[0] !== 'id' && item[0] !== 'default' ? (
                          <Form.Group inline key={indexCl}>
                            <Form.Input
                              label={t(`form${this.capitalizeLabel(item[0])}`)}
                              name={item[0]}
                              placeholder={t(`form${this.capitalizeLabel(item[0])}`)}
                              value={item[1]}
                              onChange={this.handleInputChange}
                            />
                          </Form.Group>
                        ) : item[0] === 'default' ? (
                          <Form.Group inline key={indexCl}>
                            <Form.Checkbox
                              label={t(`form${this.capitalizeLabel(item[0])}`)}
                              name={item[0]}
                              checked={item[1]}
                              onChange={this.handleCheckBoxChange}
                            />
                          </Form.Group>
                        ) : ''
                      ))
                    )
                  }
                </Form>

              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <EssorButton disabled={updateLoading} secondary type="x" size="tiny" onClick={this.closeModal}>
                {t('buttonCancel')}
              </EssorButton>

              <EssorButton disabled={updateLoading} type="plus" size="tiny" onClick={this.handleOnSubmit}>
                {t('buttonSave')}
              </EssorButton>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  retrieveError: state.companySettings.update.retrieveError,
  retrieveLoading: state.companySettings.update.retrieveLoading,
  updateError: state.companySettings.update.updateError,
  updateLoading: state.companySettings.update.updateLoading,
  retrieved: state.companySettings.update.retrieved,
  updated: state.companySettings.update.updated,
  selectedCompany: state.userCompanies.select.selectedCompany,
});


const Main = connect(mapStateToProps, mapDispatchToProps)(Units);

export default withTranslation()(withRouter(Main));
