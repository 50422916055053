import React, { FunctionComponent } from 'react';
import CurrencyCell from '../../../components/cell/CurrencyCell';
import SituationRow, { SituationRowProps } from './SituationRow';

type Props = SituationRowProps;

const SituationMonetaryRow: FunctionComponent<Props> = (props) => {
  const { data, noGap } = props;
  return (
    <SituationRow {...props}>
      <CurrencyCell value={data && data.realised} color noGreen />
      <CurrencyCell value={data && data.planned} color noGreen />
      <CurrencyCell value={data && data.ratioed} color noGreen />
      <CurrencyCell value={data && data.current} />
      {!noGap && <CurrencyCell value={data && data.gap} color noGreen />}
    </SituationRow>
  );
};

export default SituationMonetaryRow;
