import React, { FC, ReactNode } from 'react';
import { Header } from 'semantic-ui-react';

interface OwnProps {
  title: string;
  children?: ReactNode;
}

type Props = OwnProps;

const TitleHeader: FC<Props> = ({ title, children }: Props) => (
  <div className="option-buttons-container clearfix">
    <Header as="h3">{title}</Header>
    {children}
  </div>
);

export default TitleHeader;
