import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { list as listQuotes, reset as resetQuoteList, success as setQuoteList } from 'actions/quote/list';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import Routes from 'routes/admin/business/routes';
import ListQuote from './list';
import CreateQuote from './edit';
import ShowQuote from './show';

class Quote extends Component {
  componentDidMount() {
    const { getQuotes, selectedCompany, selectDocument, selectedFiscalYear } = this.props;

    let limit = '';
    if (selectedFiscalYear) {
      limit += `&creationDate[after]=${selectedFiscalYear.dateStart}`;
      limit += `&creationDate[before]=${selectedFiscalYear.dateEnd}`;
    }

    selectDocument(null);
    getQuotes(`/quotes?company=${selectedCompany.id}&pagination=true${limit}`);
  }

  componentDidUpdate(prevProps) {
    const {
      changedQuote,
      listQuote,
      selectedDocument,
      setQuoteList,
      history,
    } = this.props;

    if (!isEmpty(changedQuote)
      && changedQuote !== prevProps.changedQuote
      && changedQuote['@id'] !== selectedDocument['@id']) {
      if (changedQuote['@type'] === selectedDocument['@type']) {
        listQuote['hydra:member'].push(changedQuote);
        listQuote['hydra:totalItems'] += 1;
        setQuoteList(listQuote);
      }

      history.push(`/business${changedQuote['@id']}/edit`);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <CheckRoute
            exact
            entity={Entities.quote}
            path={Routes.listQuotes}
            component={ListQuote}
          />
          <CheckRoute
            exact
            entity={Entities.quote}
            right={crudRights.create}
            path={Routes.createQuote}
            component={CreateQuote}
          />
          <CheckRoute
            exact
            entity={Entities.quote}
            path={Routes.editQuote}
            render={props => (
              <CreateQuote key={props.match.params.id} {...props} />
            )}
          />
          <CheckRoute
            exact
            entity={Entities.quote}
            path={Routes.showQuote}
            component={ShowQuote}
          />
          <RouteNotFound />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getQuotes: page => dispatch(listQuotes(page)),
  setQuoteList: quotes => dispatch(setQuoteList(quotes)),
  selectDocument: quote => dispatch(selectDocument(quote)),
  reset: () => {
    dispatch(resetQuoteList());
  },
});

const mapStateToProps = state => ({
  listQuote: state.quote.list.data,
  loadingQuote: state.quote.list.loading,
  errorQuote: state.quote.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  changedQuote: state.quote.change.changed,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(Quote);

export default withTranslation()(withRouter(Main));
