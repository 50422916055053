import React, { FC, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EssorButton } from '../components';

interface OwnProps {
  children: ReactNode;
  fullscreen?: boolean;
  handleFullscreen?: (isFullScreen: boolean) => void;
}

const SectionContainer: FC<OwnProps> = ({ children, fullscreen = false, handleFullscreen }) => {
  const { t } = useTranslation();
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => handleFullscreen && handleFullscreen(isFullscreen), [isFullscreen]);
  const classNames = classnames('section-container', {
    fullscreen: isFullscreen,
  });

  return (
    <div className={classNames}>
      {children}

      {fullscreen && (
        <div className="fixed-button">
          <EssorButton
            type={isFullscreen ? 'sign-out alternate' : 'expand'}
            onClick={(): void => setFullscreen(!isFullscreen)}
          >
            {isFullscreen ? t('leaveFullScreen') : t('enterFullScreen')}
          </EssorButton>
        </div>
      )}

    </div>
  );
};

export default SectionContainer;
