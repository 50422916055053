import React, { FunctionComponent } from 'react';
import ContainerGeneral from '../ContainerGeneral';

interface ErrorSolution {
  title: string;
  href?: string;
  onClick?: () => void;
}

interface OwnProps {
  title: string;
  body: string;
  tryTo: string;
  solutions: ErrorSolution[];
}

type Props = OwnProps;

const ErrorTemplate: FunctionComponent<Props> = ({ title, body, tryTo, solutions }) => (
  <ContainerGeneral>
    <div className="not-found">
      <h5>{title}</h5>
      <div>{body}</div>
      <div>&nbsp;</div>
      <div>{tryTo}</div>
      <ul>
        {solutions.map((solution: ErrorSolution, index) => (
          <li key={index}>
            {solution.href
              ? (<a href={solution.href}>{solution.title}</a>)
              : (<div onClick={solution.onClick}>{solution.title}</div>)
            }
          </li>
        ))}
      </ul>
    </div>
  </ContainerGeneral>
);

export default ErrorTemplate;
