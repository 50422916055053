import React, { FC } from 'react';
import { Button, Icon, SemanticICONS, StrictButtonProps } from 'semantic-ui-react';
import classnames from 'classnames';
import checkWhite from '../assets/images/checkwhite.png';
import useAccessRights from '../hooks/useAccessRights';
import { CheckAccessRights } from '../types/store';

export interface ExtendProps extends CheckAccessRights, StrictButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  to?: string;
  fixed?: boolean;
}

interface OwnProps extends ExtendProps {
  type: SemanticICONS;
}

type Props = OwnProps;

const EssorButton: FC<Props> = ({ fixed, ...props }) => {
  const { type, children, secondary, entity, right, ...others } = props;

  if (entity && right && !useAccessRights(entity, right)) {
    return <React.Fragment />;
  }

  if (fixed) {
    return (
      <div className="fixed-button">
        <EssorButton {...props}>{children}</EssorButton>
      </div>
    );
  }

  let icon: SemanticICONS = checkWhite;
  let tag = 'i';

  if (type) {
    if (type === 'check') {
      icon = checkWhite;
      tag = 'img';
    } else {
      icon = type;
    }
  }

  return (
    <Button
      className={classnames('essor-button', {
        secondary,
      })}
      {...others}
    >
      {tag === 'i' ? <Icon name={icon} /> : <Icon as="img" src={icon} />}
      {children}
    </Button>
  );
};

EssorButton.defaultProps = {
  as: undefined,
  disabled: false,
  onClick: undefined,
  secondary: false,
  to: undefined,
  children: undefined,
  fixed: false,
};

export default EssorButton;
