import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorTemplate from './ErrorTemplate';

const NoFiscalYear: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      title={t('noFiscalYearTitle')}
      body={t('noFiscalYearBody')}
      tryTo={t('noFiscalYearTry')}
      solutions={[
        {
          title: t('noFiscalYearSelect'),
          href: '/company/fiscal_years',
        },
        {
          title: t('noFiscalYearCreate'),
          href: '/company/fiscal_years/create',
        },
      ]}
    />
  );
};

export default NoFiscalYear;
