import React, { Component } from 'react';
import { Link, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { find, isEmpty } from 'lodash';
import { change as changeReceipt, reset as resetChangeReceipt } from 'actions/receipt/change';
import { selectDocument } from 'actions/user-companies/select';
import { Form, Grid, Header } from 'semantic-ui-react';
import { Document, DocumentTimer, EssorButton } from 'components';
import { STATUS_PENDING } from 'utils/constants';
import NotFound from 'routes/admin/404';
import SectionLoader from 'components/sectionLoader';

import 'moment/locale/fr';
import ActionButtons from 'components/documents/actionButtons';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import { RECEIPT } from 'utils/documentType';

moment.locale('fr');

class ReceiptView extends Component {
  static contextType = PrinterContext;

  state = {
    isValid: true,

    status: null,
    receiptId: null,
    documentData: {},

    customerList: null,
    selectedCustomer: null,

    isCreate: true,
    timeSpent: 0,
    loadedReceipt: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const { listCustomer } = this.props;

    if (!isEmpty(listCustomer)) {
      this.setState({
        customerList: listCustomer['hydra:member'],
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listReceipt)
      && !isEmpty(nextProps.listReceipt['hydra:member'])
      && (nextProps.listReceipt['hydra:member'][0].id !== prevState.receiptId)) {
      const receipt = nextProps.listReceipt['hydra:member'][0];
      const documentData = {
        documentId: receipt.status >= STATUS_PENDING
          ? receipt.uniqueID
          : '-',
        content: receipt.content,
        reference: receipt.reference,
        note: receipt.customerComment || receipt.comment,
      };

      return {
        documentData,
        receiptId: receipt.id,
        status: receipt.status,
        selectedCustomer: receipt.customer['@id'],
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedInvoice.timeSpent,
        loadedReceipt: true,
      };
    }

    if (!isEmpty(nextProps.listCustomer) && nextProps.listCustomer['hydra:member'] !== prevState.customerList) {
      return {
        customerList: nextProps.listCustomer['hydra:member'],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate() {
    const { changedReceipt } = this.props;

    const { setFileName } = this.context;

    if (changedReceipt) {
      setFileName(changedReceipt.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  onSubmit = () => {
    this.setState({
      validDocument: false,
    });

    const {
      listReceipt,
      changeReceipt,
    } = this.props;

    const receipt = listReceipt['hydra:member'][0];

    changeReceipt(`/receipts/${receipt.id}/to_pending`, 'PUT');
  };

  handleOnSubmit = () => {
    this.setState({
      validDocument: true,
      hasDataChanged: false,
    });
  };

  handlePrint = () => {
    const { printDocument, selectedDocument } = this.props;
    printDocument(`/receipts/${selectedDocument.id}/print`);
  };

  render() {
    const {
      isValid,
      status,
      documentData,

      customerList,
      selectedCustomer,

      validDocument,
      validContent,

      isCreate,
      timeSpent,
      loadedReceipt,

      hasDataChanged,
    } = this.state;

    const {
      loadingPrintDocument,
      loadingRetrieveInvoice,
      loadingChangeReceipt,
      match,
      t,
    } = this.props;

    let customerObject = null;

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    if (!isValid) {
      return (
        <div className="section-container">
          <NotFound />
        </div>
      );
    }

    if (loadingChangeReceipt) {
      let label = '';

      if (loadingChangeReceipt) {
        label = 'documentValidate';
      }

      return (
        <SectionLoader status={loadedReceipt} label={t(label)} />
      );
    }

    return (
      <div className="section-container">
        {(loadedReceipt || isCreate)
        && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveInvoice}
          />
        )}
        <div className="section-general">
          <Prompt
            when={hasDataChanged}
            message={t('warningYouHaveUnsavedData')}
          />
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {t('receiptTitle')}
            </Header>

            <EssorButton
              as={Link}
              to={`/business/invoices/${match.params.id}/edit`}
              type="chevron left"
              size="tiny"
              floated="right"
            >
              {t('buttonBack')}
            </EssorButton>
          </div>

          <ActionButtons
            isPending={false}
            handleAction={this.handlePrint}
            loadingPrintDocument={loadingPrintDocument}
            t={t}
          />

          {(!status || status < STATUS_PENDING)
          && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form
                    className="margin-top-bot main-form"
                    loading={loadingRetrieveInvoice || loadingChangeReceipt}
                    size="small"
                  >
                    <Form.Field inline>
                      <label>{t('formCustomer')}</label>
                      <h5 className="informative-field">
                        {customerObject && customerObject.companyName}
                      </h5>
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <hr />

          {customerObject
          && (
            <React.Fragment>
              <Document
                type={RECEIPT}
                // Pass status = STATUS_PENDING to show only informative fields and not input fields
                status={STATUS_PENDING}
                documentData={documentData}
                customer={customerObject}
                validData={validDocument}
                validContent={validContent}
                getData={(documentData, errors) => this.onSubmit(documentData, errors)}
                getContent={content => this.onModelChange(content)}
              />

              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <EssorButton
                      disabled={loadingChangeReceipt}
                      loading={loadingChangeReceipt}
                      type="check"
                      size="tiny"
                      floated="right"
                      onClick={() => this.handleOnSubmit()}
                    >
                      {t('buttonSaveValidate')}
                    </EssorButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeReceipt: (route, method) => dispatch(changeReceipt(route, method)),
  selectDocument: document => dispatch(selectDocument(document)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  reset: () => {
    dispatch(resetChangeReceipt());
  },
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  retrievedInvoice: state.invoice.update.retrieved,
  loadingRetrieveInvoice: state.invoice.update.retrieveLoading,
  errorRetrieveInvoice: state.invoice.update.retrieveError,

  listReceipt: state.receipt.list.data,
  loadingListReceipt: state.receipt.list.loading,

  changedReceipt: state.receipt.change.changed,
  loadingChangeReceipt: state.receipt.change.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ReceiptView);

export default withTranslation()(withRouter(Main));
