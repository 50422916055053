import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import KeyValue from 'components/keyValue';
import ContainerGeneral from 'layouts/ContainerGeneral';
import Currency from 'components/currency';
import AddHeader from 'components/pageHeaders/AddHeader';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import { Entities } from 'types/accessRights';
import TwelveGrid from 'layouts/TwelveGrid';
import Routes from 'routes/routes';

const ShowSelfFinancing = () => {
  const { t } = useTranslation();
  const retrieved = useSelector(state => state.selfFinancing.show.retrieved);
  const loading = useSelector(state => state.selfFinancing.show.loading);
  const [selfFinancing, setSelfFinancing] = useState(undefined);

  useEffect(
    () => { setSelfFinancing(retrieved['hydra:member'][0]); },
    [retrieved],
  );

  const create = isEmpty(selfFinancing);

  return (
    <ContainerGeneral status={loading}>
      {create
        ? (
          <AddHeader
            title={t('selfFinancingsHomeTitle')}
            label={t('buttonCreate')}
            to={Routes.Forecast.editSelfFinancing}
            entity={Entities.selfFinancing}
          />
        )
        : (
          <ModifyHeader
            title={t('selfFinancingsHomeTitle')}
            to={Routes.Forecast.editSelfFinancing}
            entity={Entities.selfFinancing}
          />
        )}

      <TwelveGrid>
        {!create ? (
          <Form className="margin-top-bot main-form" loading={loading}>
            <KeyValue label={t('formOperatingProfit')}>
              <Currency value={selfFinancing.operatingProfit} />
            </KeyValue>

            <KeyValue label={t('formProvisionAndDepreciation')}>
              <Currency value={selfFinancing.provisionAndDepreciation} />
            </KeyValue>

            <KeyValue label={t('formResult')}>
              <Currency value={selfFinancing.result} />
            </KeyValue>
          </Form>
        ) : (
          <div>{t('selfFinancingNoData')}</div>
        )}
      </TwelveGrid>
    </ContainerGeneral>
  );
};
export default ShowSelfFinancing;
