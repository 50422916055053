import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { list, reset } from 'actions/turnover-balance/list';

import ListTurnover from 'routes/admin/forecast/turnover-balance/list';
import CreateTurnover from 'routes/admin/forecast/turnover-balance/create';
import ShowTurnover from 'routes/admin/forecast/turnover-balance/show';
import NotFound from 'routes/admin/404';
import { crudRights, Entities } from 'types/accessRights';
import CheckRoute from 'components/access/CheckRoute';
import { Forecast as Routes } from 'routes/admin/forecast/routes';
import SectionLoader from 'components/sectionLoader';

const Turnover = () => {
  const dataTurnover = useSelector(state => state.turnoverBalance.list.data);
  const loadingTurnover = useSelector(state => state.turnoverBalance.list.loading);
  const selectedCompany = useSelector(state => state.userCompanies.select.selectedCompany);
  const updated = useSelector(state => state.turnoverBalance.update.updated);
  const created = useSelector(state => state.turnoverBalance.create.created);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(list(`/turnover_balances?company=${selectedCompany.id}`));
      return () => {
        dispatch(reset());
      };
    },
    [selectedCompany, updated, created],
  );

  if (loadingTurnover || isEmpty(dataTurnover)) {
    return <SectionLoader status={loadingTurnover} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <CheckRoute
          exact
          path={Routes.turnoverList}
          component={ListTurnover}
          entity={Entities.turnoverBalance}
          right={crudRights.read}
        />
        <CheckRoute
          exact
          path={Routes.turnoverCreate}
          component={CreateTurnover}
          entity={Entities.turnoverBalance}
          right={crudRights.create}
        />
        <CheckRoute
          exact
          path={Routes.turnoverShow}
          component={ShowTurnover}
          entity={Entities.turnoverBalance}
          right={crudRights.read}
        />
        <CheckRoute
          exact
          path={Routes.turnoverEdit}
          component={CreateTurnover}
          entity={Entities.turnoverBalance}
          right={crudRights.update}
        />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Turnover;
