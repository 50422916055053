import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function MainNavLink(props) {
  const { t } = useTranslation();
  const { label, target, routeId, imgSrc, noDisable } = props;

  const mainNavIsOpen = useSelector(state => state.layout.mainNavIsOpen);
  const selectedCompany = useSelector(state => state.userCompanies.select.selectedCompany);

  const path = target ? `${target}` : `/${routeId}`;
  const title = label || t(routeId);

  return (
    <li
      className={classnames({
        disable: !selectedCompany && !noDisable,
      })}
    >
      <NavLink to={path} activeClassName="active" className="menu-item">
        <div className="icon-container">
          <Image src={imgSrc} alt={title} data-tip={title} data-for={routeId} />
        </div>
        {title}

        {!mainNavIsOpen
        && (
          <ReactTooltip className="essor-tooltip" place="right" effect="solid" id={routeId} />
        )}
      </NavLink>
    </li>
  );
}

export default MainNavLink;
