import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { reset as resetServiceList } from 'actions/service/list';
import { create, error, loading, success } from 'actions/service/create';
import { reset as resetUpdateService, retrieve as retrieveService, update as updateService } from 'actions/service/update';
import { Dropdown, Form, Input, Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { inputFormat } from 'utils/formatter';
import Cleave from 'cleave.js/react';
import { list as listSettings } from 'actions/company-settings/list';
import ContainerGeneral from 'layouts/ContainerGeneral';
import PromptUnsavedData from 'components/PromptUnsavedData';
import Page404 from 'routes/admin/404';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import SaveButton from 'components/buttons/SaveButton';

class CreateService extends Component {
  state = {
    id: null,
    company: null,
    companyList: null,
    reference: '',
    label: '',
    unit: null,
    unitPrice: '',
    companyError: false,
    labelError: false,
    unitPriceError: false,
    isValid: true,
    companySettingsList: null,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      listService,
      retrieveService,
      match,
      getCompanySettings,
      selectedCompany,
    } = this.props;

    if (match.params.id) {
      if (find(listService['hydra:member'], {
        id: parseInt(match.params.id, 10),
      })) {
        retrieveService(`/services/${match.params.id}`);
      } else {
        this.setState({
          isValid: false,
        });
      }
    }
    getCompanySettings(`/company_settings?name=UNITS&company=${selectedCompany.id}`);
  }

  componentDidUpdate(prevProps) {
    const {
      success,
      resetServiceList,
    } = this.props;

    if (!isEmpty(success) && success !== prevProps.success) {
      resetServiceList();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrieved) && !isEmpty(nextProps.match.params)
      && nextProps.retrieved.id !== prevState.id) {
      return {
        id: nextProps.retrieved.id,
        reference: nextProps.retrieved.reference,
        label: nextProps.retrieved.label,
        unit: nextProps.retrieved.unit,
        unitPrice: (nextProps.retrieved.unitPrice).toString().replace('.', ','),
      };
    }

    if (nextProps.selectedCompany && prevState.company !== nextProps.selectedCompany['@id']) {
      return {
        company: nextProps.selectedCompany['@id'],
      };
    }

    if (!isEmpty(nextProps.listCompanySettings)
      && nextProps.listCompanySettings['hydra:member'][0] !== prevState.companySettingsList) {
      return {
        companySettingsList: nextProps.listCompanySettings['hydra:member'][0],
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
      hasDataChanged: true,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    const prevValue = this.state[name]; // eslint-disable-line

    if (prevValue !== value) {
      this.setState({
        [name]: value,
        hasDataChanged: true,
      });

      if (name === 'supplier') {
        this.setState({
          supplyTime: JSON.parse(value).supplyTime,
          hasDataChanged: true,
        });
      }
    }
  };

  handleOnSubmit = () => {
    const {
      company,
      reference,
      label,
      unit,
      unitPrice,
    } = this.state;
    const { postService, updateService, retrieved } = this.props;
    let isValid = true;

    this.setState({
      companyError: false,
      labelError: false,
      unitError: false,
      unitPriceError: false,

    });

    if (label === '') {
      isValid = false;

      this.setState({
        labelError: true,
      });
    }

    if (!unit) {
      isValid = false;

      this.setState({
        unitError: true,
      });
    }

    if (unitPrice.trim() === '') {
      isValid = false;

      this.setState({
        unitPriceError: true,
      });
    }

    if (!isValid) {
      return;
    }

    const data = {
      company,
      reference,
      label,
      unit,
      unitPrice: inputFormat(unitPrice),
    };

    this.setState({
      hasDataChanged: false,
    });

    retrieved ? updateService(retrieved, data) : postService(data);
  };

  render() {
    const {
      label,
      reference,
      unit,
      unitPrice,
      unitError,
      labelError,
      unitPriceError,
      isValid,
      hasDataChanged,
      companySettingsList,
    } = this.state;

    const {
      success,
      updated,
      error,
      loading,
      retrieveLoading,
      updateLoading,
      updateError,
      loadingCompanySettings,
      match,
      t,
      location,
    } = this.props;

    const updateID = match.params.id;
    let units = [];

    if (companySettingsList && companySettingsList.value.length > 0) {
      units = companySettingsList.value.map((setting, index) => ({
        key: index,
        text: setting.label,
        value: setting.unit,
      }));
    }

    if (!isValid) {
      return <Page404 />;
    }

    if (success || updated) {
      return (
        <Redirect
          push
          to={updated ? `/articles/services/${updateID}` : `/articles/services/${success.id}`}
        />
      );
    }
    const fromHome = location.state && location.state.fromHome;

    return (
      <ContainerGeneral>
        <PromptUnsavedData hasDataChanged={hasDataChanged} />
        <BackHeader
          title={updateID ? t('servicesUpdateTitle') : t('servicesCreateTitle')}
          to={updateID && !fromHome ? `/articles/services/${updateID}` : '/articles/services/'}
        />
        <TwelveForm loading={loading || retrieveLoading || updateLoading}>
          <Form.Group inline>
            <Form.Input
              label={t('formLabel')}
              name="label"
              placeholder={t('formPHLabel')}
              value={label}
              onChange={this.handleInputChange}
              error={labelError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Input
              label={t('formReference')}
              name="reference"
              placeholder={t('formPHReference')}
              value={reference}
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Select
              label={t('formUnit')}
              control={Dropdown}
              placeholder={t('formPHSelect')}
              disabled={loadingCompanySettings}
              fluid
              search
              selection
              loading={loadingCompanySettings}
              noResultsMessage="No results"
              clearable
              selectOnBlur={false}
              options={units}
              name="unit"
              onChange={this.handleSelectChange}
              value={unit}
              error={unitError}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Field error={unitPriceError}>
              <label>{t('formSellingPrice')}</label>
              <Input>
                <Cleave
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 2,
                    numeralDecimalMark: ',',
                  }}
                  onChange={this.handleInputChange}
                  name="unitPrice"
                  placeholder={t('formSellingPrice')}
                  value={unitPrice}
                />
              </Input>
            </Form.Field>
          </Form.Group>

          <Message negative hidden={!updateError}>
            <p>{updateError}</p>
          </Message>

          <Message negative hidden={!error}>
            <p>{error}</p>
          </Message>
        </TwelveForm>

        <SaveButton onClick={this.handleOnSubmit} floated="right" />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postService: data => dispatch(create(data)),
  retrieveService: page => dispatch(retrieveService(page)),
  updateService: (item, data) => dispatch(updateService(item, data)),
  resetServiceList: () => dispatch(resetServiceList()),
  getCompanySettings: page => dispatch(listSettings(page)),
  reset: () => {
    dispatch(resetUpdateService());
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
  },
});

const mapStateToProps = state => ({
  success: state.service.create.created,
  error: state.service.create.error,
  loading: state.service.create.loading,

  retrieveLoading: state.service.update.retrieveLoading,
  updateError: state.service.update.updateError,
  updateLoading: state.service.update.updateLoading,
  retrieved: state.service.update.retrieved,
  updated: state.service.update.updated,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
  listService: state.service.list.data,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(CreateService);

export default withTranslation()(withRouter(Main));
