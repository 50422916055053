import { useEffect, useState } from 'react';
import { find, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { list as listSettings } from 'actions/company-settings/list';
import { CompanySettings, State } from 'types/store';
import { SettingsKey, SettingsValue } from 'types/settings';
import useCompany from './useCompany';

const useCompanySettings = (settingName: SettingsKey, defaultValue?: any): SettingsValue => {
  const companySettings = useSelector((state: State) => state.companySettings.list.data);
  const loading = useSelector((state: State) => state.companySettings.list.loading);
  const [setting, setSetting] = useState(defaultValue || {});
  const [, company] = useCompany();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company && isEmpty(companySettings)) {
      dispatch(listSettings(`/company_settings?company=${company.id}`));
    }
  },
  [company]);

  useEffect(() => {
    if (!loading) {
      const result = find(companySettings['hydra:member'], {
        name: settingName,
      }) as CompanySettings;
      setSetting(result ? result.value : (defaultValue || {}));
    }
  },
  [companySettings, settingName]);

  return setting;
};

export default useCompanySettings;
