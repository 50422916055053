import React from 'react';
import { getStatusColoring } from 'utils/documentStatus';
import { Table } from 'semantic-ui-react';

function BusinessStatusCell(props) {
  const { document, handler } = props;

  return (
    <Table.Cell style={getStatusColoring(document)}>
      {handler(document)}
    </Table.Cell>
  );
}

export default BusinessStatusCell;
