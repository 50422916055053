import React from 'react';
import Header from 'layouts/header/index';
import MainView from 'layouts/MainView';

const DashboardAdmin = ({ children }) => (
  <div>
    <Header />
    <MainView>{children}</MainView>
  </div>
);

export default DashboardAdmin;
