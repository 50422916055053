import { floatFormat } from 'utils/formatter';
import React from 'react';
import Big from 'big.js';
import Nbsp from 'components/Nbsp';

const Currency = ({ value, decimal = 2, emptyZero = false }) => (
  emptyZero && Big(value || 0).eq(0) ? <Nbsp /> : <>{floatFormat(value, true, decimal)}</>
);

export default Currency;
