import React, { Component } from 'react';
import { Message, Modal } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';
import { change as changePO, error as errorPO, loading as loadingPO, success as successPO } from 'actions/purchase-order/change';
import { change as changeQuote, error as errorQuote, loading as loadingQuote, success as successQuote } from 'actions/quote/change';
import { floatFormat } from 'utils/formatter';
import { connect } from 'react-redux';
import { close } from 'actions/modal/PurchaseOrderToInvoice';
import { Big } from 'utils/functions';
import CloseButton from 'components/buttons/CloseButton';
import { isPurchaseOrder, isQuote } from 'utils/documentType';
import CurrencyInput from 'components/input/CurrencyInput';

class ToInvoiceModal extends Component {
  state = {
    dueAmount: 0.0,
    maxAmount: 0.0,
    displayAmountError: false,
  };

  componentDidMount() {
    this.resetComponent();
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (prevProps.isOpen !== isOpen) {
      this.resetComponent();
    }
  }

  setDueAmount = (value) => {
    this.setState({
      dueAmount: value,
    });
  };

  resetComponent() {
    const { selectedDocument } = this.props;

    let max = 0;
    let paid = 0;

    if (isPurchaseOrder(selectedDocument) || isQuote(selectedDocument)) {
      if (selectedDocument.invoice) {
        selectedDocument.invoice.forEach((item) => {
          paid += parseFloat(item.content.total.dueAmount || 0);
        });
      }
      max = Big(selectedDocument.content.total.withTaxes - paid);
    }

    this.setState({
      maxAmount: parseFloat(max.toFixed(2)),
      dueAmount: parseFloat(max.toFixed(2)),
      displayAmountError: false,
    });
  }

  createInvoice() {
    const { selectedDocument, postChangePurchaseOrder, postChangeQuote, closeSelf } = this.props;
    const { dueAmount, maxAmount } = this.state;

    if (dueAmount > maxAmount || dueAmount < 0.01) {
      this.setState({
        displayAmountError: true,
      });
      return;
    }

    let partialInvoice = false;
    if (dueAmount < maxAmount) {
      partialInvoice = true;
    }

    const body = {
      dueAmount,
      partialInvoice,
    };

    if (isPurchaseOrder(selectedDocument)) {
      postChangePurchaseOrder(`${selectedDocument['@id']}/to_invoice`, 'POST', body);
    } else if (isQuote(selectedDocument)) {
      postChangeQuote(`${selectedDocument['@id']}/to_invoice`, 'POST', body);
    }

    closeSelf();
  }

  render() {
    const { isOpen, closeSelf, t, loading } = this.props;
    const { dueAmount, maxAmount, displayAmountError } = this.state;

    return (
      <Modal
        open={isOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="small-width small-height"
      >
        <Modal.Header>{t('toInvoiceBilling')}</Modal.Header>
        <Modal.Content scrolling>
          {maxAmount < 0.01
            ? (
              <Modal.Description>
                <Message warning>{t('toInvoiceFullyBilled')}</Message>
              </Modal.Description>
            )
            : (
              <Modal.Description>
                <h5>{t('toInvoiceNewInvoiceAmount')}</h5>
                <EssorButton
                  loading={loading}
                  type="check"
                  onClick={() => this.createInvoice()}
                  floated="right"
                  size="small"
                >
                  {t('buttonCreate')}
                </EssorButton>
                <CurrencyInput
                  onChange={this.setDueAmount}
                  name="dueAmount"
                  value={dueAmount}
                />
                <Message info>
                  {t('toInvoiceDueAmount')}
                  &#58;&nbsp;
                  {floatFormat(maxAmount, true)}
                </Message>
                {displayAmountError
                && (
                  <Message negative>
                    {t('toInvoiceError')}
                  </Message>
                )}
              </Modal.Description>
            )
          }
        </Modal.Content>
        <Modal.Actions>
          <CloseButton disabled={loading} onClick={() => closeSelf()} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postChangePurchaseOrder: (data, method, body) => dispatch(changePO(data, method, body)),
  postChangeQuote: (data, method, body) => dispatch(changeQuote(data, method, body)),
  closeSelf: () => dispatch(close()),
  reset: () => {
    dispatch(successPO(null));
    dispatch(successQuote(null));
    dispatch(loadingPO(false));
    dispatch(loadingQuote(false));
    dispatch(errorPO(null));
    dispatch(errorQuote(null));
  },
});

const mapStateToProps = state => ({
  selectedDocument: state.userCompanies.select.selectedDocument,
  loading: state.purchaseOrder.change.loading,
  success: state.purchaseOrder.change.success,
  error: state.purchaseOrder.change.error,
  isOpen: state.modal.purchaseOrderToInvoice,
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(ToInvoiceModal);

export default withTranslation()(Connected);
