import React from 'react';
import { Switch } from 'react-router-dom';
import RouteNotFound from 'routes/RouteNotFound';
import { crudRights, Entities } from 'types/accessRights';
import Routes from 'routes/admin/contacts/routes';
import CreateRecipient from 'routes/admin/contacts/recipients/create/index';
import HomeRecipient from 'routes/admin/contacts/recipients/home/index';
import ShowRecipient from 'routes/admin/contacts/recipients/show';
import CheckRoute from 'components/access/CheckRoute';

const Recipient = () => (
  <Switch>
    <CheckRoute
      exact
      path={Routes.listRecipients}
      component={HomeRecipient}
      entity={Entities.customer}
      right={crudRights.read}
    />
    <CheckRoute
      path={Routes.createRecipient}
      component={CreateRecipient}
      entity={Entities.customer}
      right={crudRights.create}
    />
    <CheckRoute
      path={Routes.editRecipient}
      component={CreateRecipient}
      entity={Entities.customer}
      right={crudRights.update}
    />
    <CheckRoute
      exact
      path={Routes.showRecipient}
      component={ShowRecipient}
      entity={Entities.customer}
      right={crudRights.read}
    />
    <RouteNotFound />
  </Switch>
);

export default Recipient;
