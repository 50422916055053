import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { retrieve as retrieveStock } from 'actions/stock/show';
import { list as listProducts } from 'actions/product/list';
import { Header, Table } from 'semantic-ui-react';
import { floatFormat } from 'utils/formatter';
import { withTranslation } from 'react-i18next';

import NotFound from 'routes/admin/404';
import ContainerGeneral from 'layouts/ContainerGeneral';
import TableLoader from 'components/TableLoader';
import TwelveForm from 'layouts/TwelveForm';
import ModifyHeader from 'components/pageHeaders/ModifyHeader';
import KeyValue from 'components/keyValue';
import ViewCellButton from 'components/buttons/ViewCellButton';
import FloatCell from 'components/cell/FloatCell';
import { Entities } from 'types/accessRights';

class ShowStock extends Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const { getStock, match, retrievedProduct, getProducts, selectedCompany } = this.props;
    if (match.params.stockId) {
      if (find(retrievedProduct.stock, {
        '@id': `/stocks/${match.params.stockId}`,
      })) {
        getStock(`/stocks/${match.params.stockId}`);
      } else {
        this.setState({
          isValid: false,
        });
      }
    } else {
      getProducts(`/products?company=${selectedCompany.id}`);
    }
  }

  render() {
    const { isValid } = this.state;
    const {
      retrieved,
      loading,
      match,
      t,
      listProduct,
    } = this.props;

    let productFiltered = [];

    if (isEmpty(match.params.stockId)) {
      if (!isEmpty(listProduct) && listProduct['hydra:member']) {
        productFiltered = listProduct['hydra:member'].filter(product => !isEmpty(product.stock));
      }
    }

    if (!isValid) {
      return <NotFound />;
    }

    return productFiltered && isEmpty(match.params.stockId) ? (
      <ContainerGeneral>
        <Header as="h3">{t('stockSituation')}</Header>
        <TableLoader status={loading}>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>{t('formLabel')}</Table.HeaderCell>
                <Table.HeaderCell>{t('formQuantity')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {listProduct && productFiltered.map((product, index) => {
                const quantitySum = product.stock.reduce((a, b) => a + parseFloat(b.quantity), 0);
                return (
                  <Table.Row key={index}>
                    <Table.Cell textAlign="center" collapsing>{index + 1}</Table.Cell>
                    <Table.Cell>{product.label}</Table.Cell>
                    <FloatCell value={quantitySum} />
                    <ViewCellButton
                      as={Link}
                      to={{
                        pathname: `/articles${product['@id']}`,
                        state: {
                          fromShowStock: true,
                        },
                      }}
                    />
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </TableLoader>
      </ContainerGeneral>
    ) : (
      <ContainerGeneral>
        <ModifyHeader
          title={t('stockHomeTitle')}
          to={`/articles/products/${match.params.productId}/stock/${match.params.stockId}/edit`}
          back={`/articles/products/${match.params.productId}`}
          entity={Entities.stock}
        />
        <TwelveForm loading={loading}>
          <KeyValue label={t('formQuantity')}>
            {retrieved && floatFormat(retrieved.quantity)}
          </KeyValue>

          <KeyValue label={t('formSupplierPrice')}>
            {retrieved && floatFormat(retrieved.supplierPrice, true)}
          </KeyValue>

          <KeyValue label={t('formPrice')}>
            {retrieved && floatFormat(retrieved.price, true)}
          </KeyValue>

          <KeyValue label={t('formSupplier')}>
            {retrieved && !isEmpty(retrieved.supplier)
            && (
              <Link to={`/contacts${retrieved.supplier['@id']}`} target="_blank">
                {retrieved.supplier.supplierName}
              </Link>
            )}
          </KeyValue>
        </TwelveForm>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStock: page => dispatch(retrieveStock(page)),
  getProducts: page => dispatch(listProducts(page)),
});

const mapStateToProps = state => ({
  error: state.stock.show.error,
  loading: state.stock.show.loading,
  retrieved: state.stock.show.retrieved,

  retrievedProduct: state.product.show.retrieved,
  listProduct: state.product.list.data,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ShowStock);

export default withTranslation()(withRouter(Main));
