import React, { Fragment, FunctionComponent } from 'react';
import { Message } from 'semantic-ui-react';

interface OwnProps {
  content: string;
  show: boolean;
}

type Props = OwnProps;

const TopNotif: FunctionComponent<Props> = ({ show, content }) => (
  show ? (
    <div className="top-notif-container">
      <Message warning className="top-notif-message">
        {content}
      </Message>
    </div>
  ) : <Fragment />
);

export default TopNotif;
