import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { Translation } from '../hooks/useTrans';
import ModalButtons from './ModalButtons';

interface OwnProps {
  show: boolean;
  name: string;
  title?: Translation;
  onClose: () => void;
  onDelete?: () => void;
  loading: boolean;
}

const DeleteConfirmation: FC<OwnProps> = ({ show, name, title, onClose, onDelete, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal open={show} className="small-height small-width">
      <Modal.Header>{title || t('warning')}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {`${t('warningDeleteItem')} `}
          <label className="deleteWarning">{name}</label>
          {' ?'}
        </Modal.Description>
      </Modal.Content>
      <ModalButtons
        save={onDelete}
        disabled={loading}
        cancel={onClose}
      />
    </Modal>
  );
};

export default DeleteConfirmation;
