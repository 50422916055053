import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { update, reset } from 'actions/office-settings/update';
import { Form, Grid, Header } from 'semantic-ui-react';
import { EssorButton } from 'components';
import { withTranslation } from 'react-i18next';

class SubscribedOption extends Component {
  state = {
    value: {
      sellingSuccess: false,
      managementSecret: false,
      legal: false,
      managementSecret2: false,
      talents: false,
      websitesAndSocial: false,
      remoteProspecting: '',
    },
    remoteProspectingError: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.retrievedSetting)
      && nextProps.retrievedSetting['hydra:member'][0]['@id'] !== prevState.id
      && nextProps.retrievedSetting['hydra:member'][0].name === 'SUBSCRIBED_OPTION'
    ) {
      return {
        id: nextProps.retrievedSetting['hydra:member'][0]['@id'],
        value: nextProps.retrievedSetting['hydra:member'][0].value,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleCheckBoxChange = (e, value) => {
    e.preventDefault();

    const { name } = value;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: !prevState.value[name],
        },
      }
    ));
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState(prevState => (
      {
        value: {
          ...prevState.value,
          [name]: value,
        },
      }
    ));
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const { value } = this.state;

    const { update, retrievedSetting } = this.props;

    const data = {
      value,
    };

    update(retrievedSetting['hydra:member'][0], data);
  };

  render() {
    const {
      id,
      remoteProspectingError,
      value: {
        sellingSuccess,
        managementSecret,
        legal,
        managementSecret2,
        talents,
        websitesAndSocial,
        remoteProspecting,
      },
    } = this.state;

    const {
      retrieveLoading,
      selectedOffice,
      updated,
      updateLoading,
      office,
      match,
      t,
    } = this.props;

    if (updated && office && id) {
      return (
        <Redirect
          push
          to={`/offices/${match.params.id}/settings/contact-information`}
        />
      );
    }

    if (updated && !office && id) {
      return (
        <Redirect
          push
          to={`/offices/${match.params.id}/settings/subscribed-option`}
        />
      );
    }

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">
              {office && `${t('step')} 6 / 6: `}
              {t('officesSubscribedOption')}
            </Header>
            {!office
              && (
                <EssorButton as={Link} to={`/offices/${selectedOffice.id}/settings/subscribed-option`} type="edit" size="tiny" floated="right">
                  {t('buttonBack')}
                </EssorButton>
              )}
          </div>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form className="margin-top-bot main-form" loading={retrieveLoading || updateLoading} size="small">
                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formSellingSuccess')}
                      name="sellingSuccess"
                      checked={sellingSuccess}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formManagementSecret')}
                      name="managementSecret"
                      checked={managementSecret}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formLegal')}
                      name="legal"
                      checked={legal}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formManagementSecret2')}
                      name="managementSecret2"
                      checked={managementSecret2}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formTalents')}
                      name="talents"
                      checked={talents}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Checkbox
                      label={t('formWebsitesAndSocial')}
                      name="websitesAndSocial"
                      checked={websitesAndSocial}
                      onChange={this.handleCheckBoxChange}
                    />
                  </Form.Group>

                  <Form.Group inline>
                    <Form.Input
                      label={t('formRemoteProspecting')}
                      name="remoteProspecting"
                      placeholder={t('formPHRemoteProspecting')}
                      value={remoteProspecting}
                      onChange={this.handleInputChange}
                      error={remoteProspectingError}
                    />
                  </Form.Group>

                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="clearfix">
            <EssorButton type="check" onClick={this.handleOnSubmit} size="tiny" floated="right">
              {t('buttonSubmit')}
            </EssorButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update: (item, values) => dispatch(update(item, values)),
  reset: () => {
    dispatch(reset());
  },
});

const mapStateToProps = state => ({
  updateError: state.officeSettings.update.updateError,
  updateLoading: state.officeSettings.update.updateLoading,
  updated: state.officeSettings.update.updated,

  retrievedSetting: state.officeSettings.show.retrieved,
  selectedOffice: state.userCompanies.select.selectedOffice,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(SubscribedOption);

export default withTranslation()(withRouter(Main));
