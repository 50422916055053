import { DocumentType } from '../types/document';

export const QUOTE = 'Quote';
export const PURCHASE_ORDER = 'PurchaseOrder';
export const INVOICE = 'Invoice';
export const RECEIPT = 'Receipt';
export const WORK_ORDER = 'WorkOrder';
export const DELIVERY_FORM = 'DeliveryForm';
export const SUPPLIER_ORDER = 'SupplierOrder';
export const DOCUMENT_MODEL = 'DocumentModel';
export const CREDIT_NOTE = 'CreditNote';

interface TypedAsDocument {
  '@type': DocumentType;
}

export function getDocumentType(document: TypedAsDocument): string {
  return document ? document['@type'] : 'unknown';
}

export function isQuote(document: TypedAsDocument): boolean {
  return getDocumentType(document) === QUOTE;
}

export function isPurchaseOrder(document: TypedAsDocument): boolean {
  return getDocumentType(document) === PURCHASE_ORDER;
}

export function isInvoice(document: TypedAsDocument): boolean {
  return getDocumentType(document) === INVOICE;
}

export function isReceipt(document: TypedAsDocument): boolean {
  return getDocumentType(document) === RECEIPT;
}

export function isWorkOrder(document: TypedAsDocument): boolean {
  return getDocumentType(document) === WORK_ORDER;
}

export function isDeliveryForm(document: TypedAsDocument): boolean {
  return getDocumentType(document) === DELIVERY_FORM;
}

export function isSupplierOrder(document: TypedAsDocument): boolean {
  return getDocumentType(document) === SUPPLIER_ORDER;
}

export function isCreditNote(document: TypedAsDocument): boolean {
  return getDocumentType(document) === CREDIT_NOTE;
}

export function isDocumentModel(document: TypedAsDocument): boolean {
  return getDocumentType(document) === DOCUMENT_MODEL;
}
