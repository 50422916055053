import React from 'react';
import { Container } from 'semantic-ui-react';

const Footer = () => (
  <Container textAlign="center" className="footer-web">
    &copy; Tous drois réservés 2019 - GESTRA Coach SAS - 23a rue du Haut Village -
    67140 STOTZHEIM - France - SIREN 753 589 696
  </Container>
);

export default Footer;
