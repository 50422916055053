import React from 'react';
import Currency from 'components/currency';
import TotalLine from 'components/documents/TotalLine';
import CurrencyInput from 'components/input/CurrencyInput';

const TotalInputCell = ({ value, error, showInput, handler, name, label }) => (
  <TotalLine title={label}>
    {showInput
      ? <CurrencyInput onChange={handler} name={name} value={value} error={error} />
      : <Currency value={value} />
    }
  </TotalLine>
);

export default TotalInputCell;
