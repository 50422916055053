import React, { FunctionComponent, ReactNode } from 'react';
import RightCell from 'layouts/table/RightCell';
import LeftCell from 'layouts/table/LeftCell';

interface OwnProps {
  label: string;
  alt?: string;
  right?: boolean;
  children?: ReactNode;
}

type Props = OwnProps;

const LabelCell: FunctionComponent<Props> = ({ label, alt, children, right = false }) => {
  const CellType = right ? RightCell : LeftCell;

  return (
    <CellType className="cell-fixed-left fixed-1" title={alt}>
      {label}
      {children}
    </CellType>
  );
};
export default LabelCell;
