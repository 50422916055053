import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotesDTO } from '../../types/dto';
import QuotesCard from './QuotesCard';
import RowWithTitle from './RowWithTitle';

interface OwnProps {
  quotes: {
    pending: QuotesDTO;
    won: QuotesDTO;
    lost: QuotesDTO;
  };
}

type Props = OwnProps;

const BusinessMgmt: FunctionComponent<Props> = ({ quotes }) => {
  const { t } = useTranslation();

  return (
    <RowWithTitle title={t('dashboard_businessManagement')}>

      <QuotesCard
        quotes={quotes.pending}
        chartTitle={t('dashboard_offersOrigin')}
        tableTitle={t('dashboard_pendingQuotes')}
      />

      <QuotesCard
        quotes={quotes.won}
        chartTitle={t('dashboard_reasons')}
        tableTitle={t('dashboard_wonQuotes')}
      />

      <QuotesCard
        quotes={quotes.lost}
        chartTitle={t('dashboard_reasons')}
        tableTitle={t('dashboard_lostQuotes')}
      />

    </RowWithTitle>
  );
};

export default BusinessMgmt;
