import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import configureStore from './store/configureStore';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { name, version } from '../package.json';

const actualEnv = process.env.REACT_APP_ACTUAL_ENV || process.env.NODE_ENV;

Sentry.init({
  dsn: 'https://c69dc107a79c47a68a8e13c7957f15cc@o507387.ingest.sentry.io/5598432',
  environment: actualEnv,
  release: `${name}@${version}`,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: actualEnv !== 'local' ? 0.0 : 1.0,
});

const store = configureStore();

ReactDOM.render(
  React.createElement(App, {
    store,
  }),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
