import React, { FC, ReactNode } from 'react';
import { Message, SemanticWIDTHS } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { CompanyListEdit } from '../index';
import SaveButton from '../buttons/SaveButton';
import GridWrapper from '../../layouts/GridWrapper';
import AddButton from '../buttons/AddButton';
import TwelveForm from '../../layouts/TwelveForm';

interface OwnProps {
  dataValue?: any;
  format?: any;
  align?: string;
  keyWord?: string;
  onEditList?: (item: any, hasDataChanged: boolean) => void;
  loading?: boolean;
  inputsNumeric?: number[];

  onAdd: () => void;
  children: ReactNode;
  width: SemanticWIDTHS;
  onSave: () => void;
  error: string;
}

type Props = OwnProps;

const SettingsListAndModal: FC<Props> = ({ width, onAdd, onSave, children, error, ...others }) => (
  <React.Fragment>
    <TwelveForm loading={others.loading}>
      {children}
      <div className="clearfix">
        <AddButton onClick={onAdd} floated="right" />
      </div>
    </TwelveForm>

    <Message negative hidden={isEmpty(error)}>{error}</Message>

    <div className="clearfix m-t-20">
      {!isEmpty(others.dataValue) && (
        <GridWrapper width={width} rowClassName="border-top-color">
          <CompanyListEdit {...others} form={children} />
          <SaveButton onClick={onSave} floated="right" />
        </GridWrapper>
      )}
    </div>
  </React.Fragment>
);

export default SettingsListAndModal;
