import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentRelation from './documentRelation';
import RowColumn from '../../layouts/RowColumn';
import SmallForm from '../../layouts/SmallForm';
import { getQuoteID } from './documentCommon';
import { Document, Invoice, Receipt } from '../../types/document';

interface OwnProps {
  document: Document;
}

const RelatedDocuments: FC<OwnProps> = ({ document }) => {
  const { t } = useTranslation();

  if (document.quote || document.purchaseOrder || document.invoice) {
    return (
      <RowColumn>
        <SmallForm>
          {Array.isArray(document.invoice)
            ? document.invoice.map((invoice: Invoice, index: number) => (
              <DocumentRelation
                key={`invoice${index}`}
                label={t('documentLinkedInvoice')}
                document={invoice}
              />
            ))
            : <DocumentRelation label={t('documentLinkedInvoice')} document={document.invoice} />
          }

          <DocumentRelation
            label={t('documentLinkedPurchaseOrder')}
            document={document.purchaseOrder}
          />

          {document.purchaseOrder && getQuoteID(document.purchaseOrder) !== document['@id'] && (
            <DocumentRelation
              label={t('documentLinkedQuote')}
              document={document.purchaseOrder.quote}
            />
          )}

          <DocumentRelation
            label={t('documentLinkedQuote')}
            document={document.quote}
          />

          {document.receipt && document.receipt.map((receipt: Receipt, index: number) => (
            <DocumentRelation
              key={`receipt${index}`}
              label={t('documentLinkedReceipt')}
              document={receipt}
            />
          ))}
        </SmallForm>
      </RowColumn>
    );
  }

  return <React.Fragment />;
};

export default RelatedDocuments;
