import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import LabelCell from './LabelCell';
import TypeCell from './TypeCell';
import PlannedCell from './PlannedCell';
import GapCell from './GapCell';
import { AddMisc, BudgetData, MonthlyData } from './types';
import MonthCell from './MonthCell';
import { TYPE_BUDGET } from '../../../../utils/constants';
import TreasuryTotalCell from './TreasuryTotalCell';
import { Breakdown, Budget } from '../../../../types/store';
import { breakdownWithVAT } from './functions';

interface OwnProps {
  label?: string;
  planned?: number;
  gap?: number;
  id?: string;
  item: Breakdown;
  budget: Budget;
  budgetData: BudgetData;
  data: MonthlyData[];
  onClick: AddMisc;
}

type Props = OwnProps;

const TreasuryAllocatedRow: FC<Props> = (props) => {
  const { planned = 0, label, gap = 0, id, data, item, budget, budgetData, onClick } = props;

  const total = budgetData[id || item.id];
  return (
    <Table.Row textAlign="right" className="section-border-side">
      <LabelCell label={label || item.label} right />
      <TypeCell />
      <PlannedCell value={!isEmpty(item) ? breakdownWithVAT(item, budget) : planned} />
      <GapCell value={!isEmpty(item) ? breakdownWithVAT(item, budget) - total : gap} />

      {!isEmpty(data) && data.map((monthData: MonthlyData, index: number) => (
        <MonthCell
          key={index}
          onClick={(): void => onClick('disbursement', TYPE_BUDGET, monthData, data[index + 1], budget['@id'], item.id)}
          value={monthData.budgetDis[budget['@id']][id || item.id]}
        />
      ))}

      <TreasuryTotalCell value={total} />
    </Table.Row>
  );
};

export default TreasuryAllocatedRow;
