import React, { FC, ReactNode } from 'react';
import QuestionTooltip from '../../components/icons/QuestionTooltip';

interface OwnProps {
  label?: string;
  children: ReactNode;
  tooltip?: string;
}

type Props = OwnProps;

const SidebarListItem: FC<Props> = ({ label, tooltip, children }) => (
  <li className="menu-title">
    {label && (
      <h5>
        {label}
        {tooltip && <QuestionTooltip tip={tooltip} />}
      </h5>
    )}
    <span className="menu-item-info">{children}</span>
  </li>
);

export default SidebarListItem;
