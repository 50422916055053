import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { crudRights, Entities } from 'types/accessRights';
import TableLoader from 'components/TableLoader';
import LeftCell from 'layouts/table/LeftCell';
import HeaderRow from 'layouts/table/HeaderRow';
import ViewCellButton from 'components/buttons/ViewCellButton';
import EditCellButton from 'components/buttons/EditCellButton';
import TableBody from 'semantic-ui-react/dist/commonjs/collections/Table/TableBody';
import AddHeader from 'components/pageHeaders/AddHeader';
import CheckRights from 'components/access/CheckRights';

class RoleManagement extends React.Component {
  state = {
    listRole: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listRole) && nextProps.listRole['hydra:member'] !== prevState.listRole) {
      return {
        listRole: nextProps.listRole['hydra:member'],
      };
    }
    return null;
  }

  render() {
    const { listRole } = this.state;
    const { t, loadingListRole } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('roleTitleHome')}
          to="/company/settings/roles/create"
          entity={Entities.role}
        />

        <TableLoader status={loadingListRole} table celled>
          <HeaderRow>
            <LeftCell content={t('formRole')} />
            <Table.HeaderCell colSpan={2} />
          </HeaderRow>
          <TableBody>
            {!isEmpty(listRole) && listRole.map((role, i) => (
              <Table.Row key={i}>
                <LeftCell content={role.name} />
                <ViewCellButton to={`/company/settings/roles/${role.id}`} />
                <CheckRights entity={Entities.role} right={crudRights.update}>
                  <EditCellButton to={`/company/settings/roles/${role.id}/edit`} />
                </CheckRights>
              </Table.Row>
            ))}
          </TableBody>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapStateToProps = state => ({
  listRole: state.role.list.data,
  loadingListRole: state.role.list.loading,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps)(RoleManagement);

export default withTranslation()(withRouter(Main));
