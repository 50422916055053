import React, { FunctionComponent } from 'react';
import AlignedCell, { ExtendAlignedCellProps } from './AlignedCell';

type Props = ExtendAlignedCellProps;

const RightCell: FunctionComponent<Props> = props => (
  <AlignedCell textAlign="right" {...props} />
);

export default RightCell;
