import React from 'react';
import Currency from 'components/currency';
import FormatCell from 'components/cell/FormatCell';

const CurrencyCell = ({ value, decimal = 2, emptyZero = false, ...props }) => (
  <FormatCell value={value} {...props}>
    <Currency value={value} decimal={decimal} emptyZero={emptyZero} />
  </FormatCell>
);

export default CurrencyCell;
