import React, { FunctionComponent } from 'react';
import CenterCell from '../../../../layouts/table/CenterCell';
import Nbsp from '../../../../components/Nbsp';

interface OwnProps {
  type?: string;
}

type Props = OwnProps;

const TypeCell: FunctionComponent<Props> = ({ type }) => (
  <CenterCell className="cell-fixed-left fixed-2">{type || <Nbsp />}</CenterCell>
);

export default TypeCell;
