import React, { FunctionComponent } from 'react';
import CurrencyCell from '../../../../components/cell/CurrencyCell';

interface OwnProps {
  value: number;
}

type Props = OwnProps;

const PlannedCell: FunctionComponent<Props> = ({ value }) => (
  <CurrencyCell className="cell-fixed-left fixed-3" value={value} color noGreen emptyZero />
);

export default PlannedCell;
