import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { list as listBulkPrice, reset as resetListBulkPrice, success as setListBulkPrice } from 'actions/bulk-update/list';
import { del as deleteBulkPrice, error as errorDelete } from 'actions/bulk-update/delete';
import { Button, Dimmer, Header, Loader, Segment, Table } from 'semantic-ui-react';
import { EssorButton, DeleteConfirmation } from 'components';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

class ListBulkPrice extends Component {
  state = {
    bulkPriceList: null,
    warningMessage: false,
  };

  componentDidMount() {
    const { getBulkPrices, selectedCompany } = this.props;

    getBulkPrices(`/bulk_updates?company=${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.listBulkPrice) && prevState.bulkPriceList !== nextProps.listBulkPrice['hydra:member']) {
      return {
        bulkPriceList: nextProps.listBulkPrice['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedBulkPrice, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.dismissWarning();
      resetError();
    }

    if (!isEmpty(deletedBulkPrice)
      && deletedBulkPrice !== prevProps.deletedBulkPrice) {
      this.updateList();
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  updateList = () => {
    const { listBulkPrice, setListBulkPrice, deletedBulkPrice } = this.props;
    const arr = listBulkPrice['hydra:member']
      .filter(bulk => bulk['@id'] !== deletedBulkPrice['@id']);

    const newList = {
      ...listBulkPrice,
      'hydra:member': arr,
      'hydra:totalItems': listBulkPrice['hydra:totalItems'] - 1,
    };

    setListBulkPrice(newList);
    this.dismissWarning();
  };

  handleDelete = () => {
    const { toDelete } = this.state;
    const { deleteBulkPrice } = this.props;
    deleteBulkPrice(toDelete);
  };

  showDeleteWarning = (item) => {
    this.setState({
      toDelete: item,
      warningMessage: true,
      itemName: item.date,
    });
  };

  dismissWarning = () => {
    this.setState({
      toDelete: null,
      warningMessage: false,
      itemName: '',
    });
  };

  render() {
    const { bulkPriceList, warningMessage, itemName } = this.state;
    const { loadingListBulkPrice, t, loadingDeleteBulkPrice } = this.props;

    return (
      <div className="section-container">
        <div className="section-general">
          <div className="option-buttons-container clearfix">
            <Header as="h3">{t('stockPricesUpdate')}</Header>
            <EssorButton as={Link} to="/articles/prices/create" type="plus" size="tiny" floated="right">
              {t('buttonAdd')}
            </EssorButton>
          </div>

          <Segment
            basic
            className={classnames('table-container', {
              'is-loading': loadingListBulkPrice,
            })}
          >
            <Dimmer active={loadingListBulkPrice} inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('formDate')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('documentStatus')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {bulkPriceList && bulkPriceList.map((bulkPrice, index) => {
                  let status = t('documentStatusPending');

                  switch (bulkPrice.status) {
                    case 6: status = t('documentStatusDone');
                      break;
                    case 8: status = t('documentStatusCancelled');
                      break;
                    default: break;
                  }

                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{moment(bulkPrice.date).format('DD/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{status}</Table.Cell>

                      <Table.Cell textAlign="center">
                        <Button
                          className="table-button"
                          icon="trash"
                          onClick={() => this.showDeleteWarning(bulkPrice)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        </div>

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDeleteBulkPrice}
          onClose={this.dismissWarning}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBulkPrices: page => dispatch(listBulkPrice(page)),
  deleteBulkPrice: id => dispatch(deleteBulkPrice(id)),
  setListBulkPrice: list => dispatch(setListBulkPrice(list)),
  resetError: () => dispatch(errorDelete(null)),
  reset: () => dispatch(resetListBulkPrice()),
});

const mapStateToProps = state => ({
  listBulkPrice: state.bulkPrice.list.data,
  loadingListBulkPrice: state.bulkPrice.list.loading,
  errorListBulkPrice: state.bulkPrice.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,

  deletedBulkPrice: state.bulkPrice.del.deleted,
  loadingDeleteBulkPrice: state.bulkPrice.del.loading,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListBulkPrice);

export default withTranslation()(withRouter(Main));
