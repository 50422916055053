import React from 'react';

const SubNavTitle: React.FC<{ title: string; subtitle?: string }> = ({ title, subtitle }) => (
  <li className="menu-item menu-title">
    {title}
    {subtitle && (<p>{subtitle}</p>)}
  </li>
);

export default SubNavTitle;
