import React, { FunctionComponent } from 'react';
import { Select } from 'semantic-ui-react';
import { StrictSelectProps } from 'semantic-ui-react/dist/commonjs/addons/Select';
import Percentage from '../percentage';

interface OwnProps extends StrictSelectProps {
  size: string;
  name: string;
  displayOnly: boolean;
}

type Props = OwnProps;

const PercentageSelect: FunctionComponent<Props> = ({ displayOnly, value, ...others }) => (
  displayOnly ? <Percentage value={value} /> : <Select value={value} {...others} />
);

export default PercentageSelect;
