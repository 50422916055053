import React, { FC } from 'react';
import { Input, Label, Table } from 'semantic-ui-react';
import Big from 'big.js';
import CleaveInit from '../../../../components/input/CleaveInit';
import { getFloatCleaveFormat, inputFormat } from '../../../../utils/formatter';
import FloatCell from '../../../../components/cell/FloatCell';
import PercentageCell from '../../../../components/cell/PercentageCell';
import RightCell from '../../../../layouts/table/RightCell';
import { RowTuple } from './forecastTypes';
import Currency from '../../../../components/currency';
import Float from '../../../../components/float';
import Percentage from '../../../../components/percentage';

interface OwnProps {
  title?: string;
  stateKey: string;
  data: RowTuple;
  onChange: (key: string, value: number) => void;
  ghost: boolean;
  mode: 'rate' | 'coef' | 'currency';
}

type Props = OwnProps;

const ForecastInputRow: FC<Props> = ({ title, stateKey, data, onChange, ghost, mode }) => (
  <Table.Row>
    <Table.Cell content={title} />
    <Table.Cell className="format-number-fr">
      {ghost
        ? (
          <Input fluid labelPosition="right">
            <CleaveInit
              options={getFloatCleaveFormat()}
              name={`realised.${stateKey}`}
              value={data.realised ? inputFormat(data.realised, true) : 0}
              handler={(val: number): void => onChange(`realised.${stateKey}`, val)}
            />
            {mode === 'rate' && (<Label>%</Label>)}
            {mode === 'currency' && (<Label>€</Label>)}
          </Input>
        )
        : (
          <React.Fragment>
            {mode === 'rate' && <Percentage value={data.realised || 0} />}
            {mode === 'coef' && <Float value={data.realised || 0} />}
            {mode === 'currency' && <Currency value={data.realised || 0} />}
          </React.Fragment>
        )
      }
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Input fluid labelPosition="right">
        <CleaveInit
          options={getFloatCleaveFormat()}
          name={`planned.${stateKey}`}
          value={data.planned ? inputFormat(data.planned, true) : 0}
          handler={(val: number): void => onChange(`planned.${stateKey}`, val)}
        />
        {mode === 'rate' && (<Label>%</Label>)}
        {mode === 'currency' && (<Label>€</Label>)}
      </Input>
    </Table.Cell>
    {data.realised > 0 && data.planned > 0
      ? (mode === 'coef'
        ? <FloatCell value={Big(data.percentage || 0).toFixed(2)} />
        : <PercentageCell value={Big(data.percentage || 0).toFixed(2)} showPositive />
      )
      : <RightCell content="" />
    }
  </Table.Row>
);

export default ForecastInputRow;
