import React, { FunctionComponent, ReactNode } from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';

interface OwnProps {
  children: ReactNode;
  width: SemanticWIDTHS;
  rowClassName?: string;
}

type Props = OwnProps;

const GridWrapper: FunctionComponent<Props> = ({ children, width, rowClassName }) => (
  <Grid>
    <Grid.Row className={rowClassName}>
      <Grid.Column width={width}>
        {children}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default GridWrapper;
