import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import ButtonHeader, { ExtendProps } from './ButtonHeader';

const BackHeader: FC<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();
  return (
    <ButtonHeader
      type="chevron left"
      label={label || t('buttonBack')}
      {...others}
    />
  );
};

export default BackHeader;
