import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import notImplementedYet from 'utils/notImplementedYet';
import { toast } from 'components';

import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import SubNavLink from 'layouts/subNav/SubNavLink';
import Treasury from './treasury';
import PriceAverage from './price-average';
import Margins from './margin';
import NotFound from '../404';


const redirect = '/analysis';

const Analysis = ({ selectedCompany, selectedFiscalYear, t }) => {
  const checkFiscalYear = (props) => {
    if (isEmpty(selectedFiscalYear) || isEmpty(selectedFiscalYear.forecast)) {
      toast.warning(t('warningTreasury'));
      return (
        <Redirect to="/forecast/table" />
      );
    }

    return (
      <Treasury {...props} />
    );
  };

  return (
    <React.Fragment>
      <SubNav hide={!selectedCompany}>
        <SubNavTitle title={t('analysis')} />
        <SubNavLink to="/analysis/treasury">{t('analysisTreasuryTitle')}</SubNavLink>
        <SubNavLink hide to="/analysis/company">{t('analysisCompanyTitle')}</SubNavLink>
        <SubNavLink hide to="/analysis/rentability">{t('analysisRentabilityTitle')}</SubNavLink>
        <SubNavLink hide to="/analysis/gain-loss">{t('analysisGainLossTitle')}</SubNavLink>
        <SubNavLink hide to="/analysis/price-average">{t('analysisPriceAverageTitle')}</SubNavLink>
        <SubNavLink hide to="/analysis/margins">{t('analysisMarginsTitle')}</SubNavLink>
      </SubNav>

      <Switch>
        <Route
          exact
          path={['/analysis', '/analysis/treasury']}
          render={props => checkFiscalYear(props)}
        />
        <Route exact path="/analysis/price-average" component={PriceAverage} />
        <Route exact path="/analysis/margins" component={Margins} />
        <Route exact path="/analysis/company" render={() => notImplementedYet(t, redirect)} />
        <Route exact path="/analysis/rentability" render={() => notImplementedYet(t, redirect)} />
        <Route exact path="/analysis/gain-loss" render={() => notImplementedYet(t, redirect)} />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,
});

const Main = connect(mapStateToProps)(Analysis);

export default withTranslation()(Main);
