const SET_DELIVERY_MODAL = 'MODAL/SET_DELIVERY_MODAL';

const deliveryForm = (state = false, action: any) => {
  if (action.type === SET_DELIVERY_MODAL) {
    return action.visibility;
  }
  return state;
};

export default deliveryForm;
