import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectDocument } from 'actions/user-companies/select';
import { TableBody, TableRow } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TableLoader from 'components/TableLoader';
import { getHeaderItem, sortedDocuments } from 'components/documents/documentCommon';
import SortableHeader from 'components/sortableHeader';
import UniqueId from 'components/documents/uniqueId';
import BusinessStatusCell from 'routes/admin/business/businessStatusCell';
import { getSupplierOrderStatus } from 'utils/documentStatus';
import BusinessIconCell from 'routes/admin/business/businessIconCell';
import CurrencyCell from 'components/cell/CurrencyCell';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/business/routes';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { Entities } from 'types/accessRights';
import PrintCellButton from 'components/buttons/PrintCellButton';
import DateCell from 'components/cell/DateCell';
import LeftCell from 'layouts/table/LeftCell';
import TableEmptyRow from 'components/TableEmptyRow';

class ListSupplierOrder extends Component {
  state = {
    documents: null,
    column: null,
    direction: null,
  };

  componentDidMount() {
    const { selectDocument } = this.props;

    selectDocument(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.dataSupplierOrder) && nextProps.dataSupplierOrder['hydra:member'] !== prevState.documents) {
      return {
        documents: nextProps.dataSupplierOrder['hydra:member'],
      };
    }

    return null;
  }

  handleSort = (clickedColumn, nextDirection) => {
    this.setState({
      column: clickedColumn,
      direction: nextDirection,
    });
  };

  render() {
    const { documents, column, direction } = this.state;

    const { loadingSupplierOrder, t } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('supplierOrderHomeTitle')}
          to={Routes.createSupplierOrder}
          entity={Entities.supplyOrder}
        />

        <TableLoader status={loadingSupplierOrder} table sortable celled striped>
          <SortableHeader
            column={column}
            direction={direction}
            handler={this.handleSort}
            items={[
              getHeaderItem('uniqueID', t('documentNumber')),
              getHeaderItem('creationDate', t('documentsHeaderCreationDate')),
              getHeaderItem('supplier', t('formSupplier')),
              getHeaderItem('totalPrice', t('documentTotal')),
              getHeaderItem('status', t('documentStatus')),
            ]}
            span={2}
          />

          <TableBody>
            {sortedDocuments(documents, column, direction).map((document, index) => (
              <TableRow key={index}>
                <LeftCell><UniqueId document={document} /></LeftCell>
                <DateCell date={document.creationDate} />
                <LeftCell content={document.supplier.supplierName} />
                <CurrencyCell value={document.totalPrice} />
                <BusinessStatusCell document={document} handler={getSupplierOrderStatus} />
                <PrintCellButton url={`${document['@id']}/print`} document={document} />
                <BusinessIconCell type="supplier_orders" document={document} />
              </TableRow>
            ))}
            {documents.length === 0 && (
              <TableEmptyRow content={t('documentEmptyList')} span={7} />
            )}
          </TableBody>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectDocument: supplier => dispatch(selectDocument(supplier)),
});

const mapStateToProps = state => ({
  dataSupplierOrder: state.supplierOrder.list.data,
  loadingSupplierOrder: state.supplierOrder.list.loading,
  errorSupplierOrder: state.supplierOrder.list.error,

  selectedCompany: state.userCompanies.select.selectedCompany,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListSupplierOrder);

export default withTranslation()(withRouter(Main));
