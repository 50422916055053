import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { create as createDocumentNumbering, error as errorCreateDocumentNumbering, loading as loadingCreateDocumentNumbering, success as successCreateDocumentNumbering } from 'actions/document-numbering/create';
import { list as listDocumentNumberings, reset as resetDocumentNumbering } from 'actions/document-numbering/list';
import { reset as resetUpdateDocumentNumbering, update as updateDocumentNumbering } from 'actions/document-numbering/update';
import { Form, Header, Input, Modal, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import TableLoader from 'components/TableLoader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import SmallForm from 'layouts/SmallForm';
import { getIntCleaveFormat } from 'utils/formatter';
import ModalButtons from 'components/ModalButtons';
import EditCellButton from 'components/buttons/EditCellButton';
import LeftCell from 'layouts/table/LeftCell';
import RightCell from 'layouts/table/RightCell';
import HeaderRow from 'layouts/table/HeaderRow';
import CenterCell from 'layouts/table/CenterCell';
import { crudRights, Entities } from 'types/accessRights';
import CheckRights from 'components/access/CheckRights';


class DocumentNumbering extends Component {
  state = {
    documentNumberingData: null,
    fiscalYear: null,
    documentType: '',
    label: '',
    number: '',

    labelError: false,
    numberError: false,

    toEdit: null,
    createDocumentModalOpen: false,
    isDocumentLoaded: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getDocumentNumbering,
      selectedFiscalYear,
      reset,
    } = this.props;
    reset();

    getDocumentNumbering(`/document_numberings?fiscalYear=${selectedFiscalYear.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.successCreateDocumentNumbering || nextProps.updatedDocumentNumbering) {
      const {
        resetCreateDocumentNumbering,
        resetUpdateDocumentNumbering,
        resetDocumentNumberingList,
        getDocumentNumbering,
        selectedFiscalYear,
      } = nextProps;
      resetCreateDocumentNumbering();
      resetUpdateDocumentNumbering();
      resetDocumentNumberingList();
      getDocumentNumbering(`/document_numberings?fiscalYear=${selectedFiscalYear.id}`);

      return {
        documentNumberingData: null,
        documentType: '',
        label: '',
        number: '',
        toEdit: null,
        createDocumentModalOpen: false,
        isDocumentLoaded: false,
      };
    }

    if (!isEmpty(nextProps.dataDocumentNumbering) && !prevState.isDocumentLoaded) {
      return {
        documentNumberingData: nextProps.dataDocumentNumbering['hydra:member'],
        isDocumentLoaded: true,
      };
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  openModal = (id) => {
    const { documentNumberingData } = this.state;
    const item = find(documentNumberingData, {
      '@id': id,
    });

    this.setState({
      createDocumentModalOpen: true,
      toEdit: item || null,
      documentType: item ? item.documentType : '',
      label: item ? item.label : '',
      number: item ? item.number : '',
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value,
      hasDataChanged: true,
    });
  };

  handleCheckBoxChange = (e, value) => {
    const { name } = value;
    this.setState(prevState => (
      {
        [name]: !prevState[name],
        hasDataChanged: true,
      }
    ));
  };

  closeModal = () => {
    this.setState({
      documentType: '',
      label: '',
      number: '',

      labelError: false,
      numberError: false,

      toEdit: null,
      createDocumentModalOpen: false,
      hasDataChanged: false,
    });
  };

  handleSubmit = () => {
    const { label, number, toEdit } = this.state;
    const {
      selectedFiscalYear,
      updateDocumentNumbering,
      postDocumentNumbering,
    } = this.props;

    const id = selectedFiscalYear['@id'];

    let isValid = true;

    this.setState({
      labelError: false,
      numberError: false,
      resetError: false,
    });

    if (label === '') {
      isValid = false;
      this.setState({
        labelError: true,
      });
    }

    if (number === '') {
      isValid = false;
      this.setState({
        numberError: true,
      });
    }

    if (!isValid) return;

    const data = {
      fiscalYear: id,
      label,
      number: Number(number),
      reset: true,
    };

    this.setState({
      hasDataChanged: false,
    });

    toEdit ? updateDocumentNumbering(toEdit, data) : postDocumentNumbering(data);
  };

  render() {
    const {
      documentNumberingData,
      documentType,
      label,
      number,
      labelError,
      numberError,
      createDocumentModalOpen,
      toEdit,
      hasDataChanged,
    } = this.state;

    const {
      t,
      loadingDocumentNumbering,
      loadingCreateDocumentNumbering,
      loadingUpdateDocumentNumbering,
    } = this.props;

    const keysDocumentType = [
      'CREDIT_NOTE',
      'SUPPLY_ORDER',
      'RECEIPT',
      'WORK_ORDER',
      'DELIVERY_FORM',
      'INVOICE',
      'PURCHASE_ORDER',
      'QUOTE',
    ];

    const loading = loadingCreateDocumentNumbering || loadingUpdateDocumentNumbering;
    return (
      <ContainerGeneral prompt={hasDataChanged}>
        <div className="option-buttons-container clearfix">
          <Header as="h3">{t('documentNumberingTitle')}</Header>
          {/* <EssorButton type="plus" size="tiny" floated="right"
             onClick={this.openModal}>
              {t('buttonAdd')}
            </EssorButton> */}
        </div>

        <Modal
          open={createDocumentModalOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="full-content"
        >
          <Modal.Header>{toEdit ? t('documentNumberingUpdateTitle') : t('documentNumberingCreateTitle')}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <SmallForm loading={loading}>
                <Form.Group inline>
                  <div className="field">
                    <label>{t('documentNumberingType')}</label>
                    <div>
                      <span>{find(keysDocumentType, item => item === documentType) ? t(`documentNumbering_${documentType}`) : documentType}</span>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group inline>
                  <Form.Input
                    label={t('formLabel')}
                    name="label"
                    placeholder={t('formLabel')}
                    value={label}
                    onChange={this.handleInputChange}
                    error={labelError}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field error={numberError}>
                    <label>{t('formNumber')}</label>
                    <Input labelPosition="left">
                      <Cleave
                        options={getIntCleaveFormat()}
                        onChange={this.handleInputChange}
                        name="number"
                        placeholder="number"
                        value={number}
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </SmallForm>
            </Modal.Description>
          </Modal.Content>
          <ModalButtons disabled={loading} save={this.handleSubmit} cancel={this.closeModal} />
        </Modal>

        <TableLoader status={loadingDocumentNumbering} table celled striped>
          <HeaderRow>
            <LeftCell content={t('documentNumberingType')} />
            <LeftCell content={t('formLabel')} />
            <RightCell content={t('formNumber')} />
            <CheckRights entity={Entities.documentNumbering} right={crudRights.update}>
              <CenterCell />
            </CheckRights>
          </HeaderRow>

          <Table.Body>
            {documentNumberingData && documentNumberingData.map((document, index) => (
              <Table.Row key={index}>
                <LeftCell>{find(keysDocumentType, item => item === document.documentType) ? t(`documentNumbering_${document.documentType}`) : document.documentType}</LeftCell>
                <LeftCell>{document.label}</LeftCell>
                <RightCell>{document.number}</RightCell>
                <EditCellButton onClick={() => this.openModal(document['@id'])} entity={Entities.documentNumbering} />
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoader>
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDocumentNumbering: page => dispatch(listDocumentNumberings(page)),
  postDocumentNumbering: data => dispatch(createDocumentNumbering(data)),
  updateDocumentNumbering: (item, values) => dispatch(updateDocumentNumbering(item, values)),

  resetCreateDocumentNumbering: () => {
    dispatch(successCreateDocumentNumbering(null));
    dispatch(loadingCreateDocumentNumbering(false));
    dispatch(errorCreateDocumentNumbering(null));
  },

  resetUpdateDocumentNumbering: () => dispatch(resetUpdateDocumentNumbering()),
  resetDocumentNumberingList: () => dispatch(resetDocumentNumbering()),

  reset: () => {
    dispatch(resetDocumentNumbering());
  },
});

const mapStateToProps = state => ({
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  dataDocumentNumbering: state.documentNumbering.list.data,
  loadingDocumentNumbering: state.documentNumbering.list.loading,
  errorDocumentNumbering: state.documentNumbering.list.error,

  successCreateDocumentNumbering: state.documentNumbering.create.created,
  loadingCreateDocumentNumbering: state.documentNumbering.create.loading,
  errorCreateDocumentNumbering: state.documentNumbering.create.error,

  updatedDocumentNumbering: state.documentNumbering.update.updated,
  loadingUpdateDocumentNumbering: state.documentNumbering.update.updateLoading,
  errorUpdateDocumentNumbering: state.documentNumbering.update.updateError,

});
const Main = connect(mapStateToProps, mapDispatchToProps)(DocumentNumbering);

export default withTranslation()(withRouter(Main));
