import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import { find, findIndex, isEmpty } from 'lodash';
import { success as setInvoiceList } from 'actions/invoice/list';
import { create as createInvoice, error as errorInvoice, loading as loadingInvoice, success as successInvoice } from 'actions/invoice/create';
import { change as changeInvoice } from 'actions/invoice/change';
import { print as printDocument, reset as printReset } from 'actions/document/print';
import { retrieveSuccess as updateInvoiceSuccess, update as updateInvoice } from 'actions/invoice/update';
import { list as listModel, reset as resetModel } from 'actions/document-model/list';
import { selectDocument } from 'actions/user-companies/select';
import { Dropdown, Form, Grid, Header, Input } from 'semantic-ui-react';
import { CreateCustomer, Document, DocumentTimer, EssorButton, TextEditor } from 'components';
import DatePicker from 'react-datepicker';
import { getCustomerList, getModelList, getOfferReasons } from 'components/documents/documentOptions';
import { isDraft, isNotDraft, isPending } from 'utils/documentStatus';
import { handleNextPropLists } from 'components/documents/documentCommon';
import SaveButtons from 'components/documents/saveButtons';
import ActionButtons from 'components/documents/actionButtons';
import { PrinterContext } from 'routes/admin/business/DocumentPrinter';
import ContainerGeneral from 'layouts/ContainerGeneral';
import BackHeader from 'components/pageHeaders/BackHeader';
import TwelveForm from 'layouts/TwelveForm';
import InlineField from 'layouts/InlineField';
import SmallForm from 'layouts/SmallForm';
import ErrorMessages from 'routes/admin/business/ErrorMessages';
import ReplaceContentModal from 'routes/admin/business/ReplaceContentModal';
import SelectEmployees from 'routes/admin/business/SelectEmployees';
import { keyVal } from 'utils/functions';
import { INVOICE } from 'utils/documentType';

moment.locale('fr');

class EditInvoice extends Component {
  static contextType = PrinterContext;

  state = {
    status: null,
    invoiceId: null,
    documentData: {},

    modelList: null,
    selectedModel: null,
    customerList: null,
    selectedCustomer: null,
    openCustomerModal: false,

    invoiceReason: null,
    paymentDate: null,
    beginDate: null,
    comment: '',
    paymentComment: '',

    staff: [],
    plannedHoursError: false,

    isCreate: false,
    timeSpent: 0,
    loadedInvoice: false,

    hasDataChanged: false,
  };

  componentDidMount() {
    const {
      getModelList,
      selectedCompany,
      match,
    } = this.props;

    if (match.path === '/business/invoices/create') {
      this.setState({
        isCreate: true,
      });
    }

    getModelList(`/document_models?company=${selectedCompany.id}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.retrievedInvoice)
      && !isEmpty(nextProps.match.params)
      && (nextProps.retrievedInvoice.id !== prevState.invoiceId
        || nextProps.retrievedInvoice.status !== prevState.status)) {
      const documentData = {
        documentId: isNotDraft(nextProps.retrievedInvoice)
          ? nextProps.retrievedInvoice.uniqueID
          : '-',
        content: nextProps.retrievedInvoice.content,
        reference: nextProps.retrievedInvoice.reference,
        creationDate: moment(nextProps.retrievedInvoice.creationDate),
        responseDate: moment(nextProps.retrievedInvoice.responseDate),
        quote: nextProps.retrievedInvoice.quote,
        purchaseOrder: nextProps.retrievedInvoice.purchaseOrder,
        status: nextProps.retrievedInvoice.status,
        receipt: nextProps.listReceipt['hydra:member'],
      };

      return {
        documentData,
        invoiceId: nextProps.retrievedInvoice.id,
        status: nextProps.retrievedInvoice.status,
        selectedCustomer: nextProps.retrievedInvoice.customer['@id'],
        invoiceReason: nextProps.retrievedInvoice.invoiceReason,
        comment: nextProps.retrievedInvoice.comment || '',
        paymentComment: nextProps.retrievedInvoice.content.messages.paymentNotice || '',
        paymentDate: nextProps.retrievedInvoice.paymentDate
          ? moment(nextProps.retrievedInvoice.paymentDate)
          : null,
        beginDate: nextProps.retrievedInvoice.beginDate
          ? moment(nextProps.retrievedInvoice.beginDate)
          : null,
        staff: nextProps.retrievedInvoice.staff,
        timeSpent: prevState.isCreate ? 0 : nextProps.retrievedInvoice.timeSpent,
        loadedInvoice: true,
      };
    }

    const handlerReturn = handleNextPropLists(nextProps, prevState);
    if (handlerReturn !== null) {
      return handlerReturn;
    }

    if (prevState.hasDataChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDocument,
      retrievedInvoice,

      createdInvoice,
      updatedInvoice,
      changedInvoice,
      listInvoice,

      setInvoice,
      changeInvoice,
      selectDocument,
      setInvoiceList,
      history,
    } = this.props;

    const { setFileName } = this.context;

    const { submitAction, banks, paymentComment, paymentDate } = this.state;

    if (!isEmpty(retrievedInvoice)
      && retrievedInvoice !== prevProps.retrievedInvoice) {
      selectDocument(retrievedInvoice);
    }

    if (isEmpty(retrievedInvoice) && retrievedInvoice !== prevProps.retrievedInvoice) {
      selectDocument(null);
    }

    if (!isEmpty(retrievedInvoice) && !isEmpty(banks) && isEmpty(paymentComment) && paymentDate) {
      this.setPaymentComment();
    }

    if (!isEmpty(changedInvoice)
      && changedInvoice['@id'] === selectedDocument['@id'] && isNotDraft(changedInvoice)) {
      selectDocument(changedInvoice);
    }

    if (!isEmpty(createdInvoice) && createdInvoice !== prevProps.createdInvoice) {
      listInvoice['hydra:member'].push(createdInvoice);
      listInvoice['hydra:totalItems'] += 1;
      setInvoiceList(listInvoice);

      switch (submitAction) {
        case 'save':
          history.push('/business/invoices');
          break;
        case 'edit':
          history.push(`/business/invoices/${createdInvoice.id}/edit`);
          break;
        case 'validate':
          history.push(`/business/invoices/${createdInvoice.id}/edit`);
          changeInvoice(`/invoices/${createdInvoice.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(updatedInvoice) && updatedInvoice !== prevProps.updatedInvoice) {
      const index = findIndex(listInvoice['hydra:member'], {
        id: updatedInvoice.id,
      });

      listInvoice['hydra:member'][index] = updatedInvoice;
      setInvoiceList(listInvoice);

      switch (submitAction) {
        case 'save':
          history.push('/business/invoices');
          break;
        case 'validate':
          changeInvoice(`/invoices/${updatedInvoice.id}/to_pending`, 'PUT');
          break;
        default:
          break;
      }
      this.clearErrors();
    }

    if (!isEmpty(changedInvoice)
      && changedInvoice !== prevProps.changedInvoice
      && changedInvoice['@id'] === selectedDocument['@id']) {
      const index = findIndex(listInvoice['hydra:member'], {
        id: changedInvoice.id,
      });

      setInvoice(changedInvoice);
      listInvoice['hydra:member'][index] = changedInvoice;
      setInvoiceList(listInvoice);
    }

    if (retrievedInvoice) {
      setFileName(retrievedInvoice.uniqueID);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  clearErrors = () => {
    this.setState({
      messageError: [],
    });
  };

  makePaymentComment = (date) => {
    const { selectedCompany } = this.props;
    const { banks } = this.state;

    let paymentNotice;

    paymentNotice = `<p><b>À régler avant le : </b>${date && moment(date).format('DD/MM/YYYY')}</p>`;

    if (banks) {
      for (let i = 0; i < banks.value.length; i++) {
        if (banks.value[i].default) {
          paymentNotice = `${paymentNotice}<p><b>Par chèque :&nbsp;</b>à l'ordre de ${selectedCompany.name}</p><p><b>Par virement bancaire :</b><ul><li>IBAN: ${banks.value[i].IBAN}</li><li>BIC: ${banks.value[i].BIC}</li></p>`;
          break;
        }
      }
    }

    return paymentNotice;
  };

  setPaymentComment = (reset = false) => {
    const { paymentComment, paymentDate } = this.state;

    this.setState({
      paymentComment: (isEmpty(paymentComment) || reset)
        ? this.makePaymentComment(paymentDate)
        : paymentComment,
    });
  };

  handlePaymentDateChange = (date) => {
    const { paymentComment, paymentDate } = this.state;
    let comment = paymentComment;

    if (paymentDate && date) {
      const index = comment.search(paymentDate.format('DD/MM/YYYY'));
      if (index > 0) {
        comment = comment.replace(comment.substring(index, index + 10), date.format('DD/MM/YYYY'));
      }
    } else if (date) {
      comment = this.makePaymentComment(date);
    }

    this.setState({
      paymentDate: moment(date),
      paymentComment: comment,
      hasDataChanged: true,
    });
  };

  handleSelectChange = (e, { value, name }) => {
    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  handleBeginDateChange = (date) => {
    this.setState({
      beginDate: date,
      hasDataChanged: true,
    });
  };

  handleOpenCreateCustomer = (e) => {
    e.preventDefault();

    this.setState({
      openCustomerModal: true,
      hasDataChanged: true,
    });
  };

  closeCustomerModal = () => {
    this.setState({
      openCustomerModal: false,
    });
  };

  customerCreateSuccess = (customer) => {
    const { customerList } = this.state;

    customerList.push(customer);

    this.setState({
      customerList,
      selectedCustomer: customer['@id'],
    });
  };

  setStaff = (staff) => {
    this.setState({
      staff,
      hasDataChanged: true,
    });
  };

  setPlannedHoursError = (error) => {
    this.setState({
      plannedHoursError: error,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      hasDataChanged: true,
    });
  };

  onSubmit = (data, msgError) => {
    this.setState({
      validDocument: false,
    });

    const {
      selectedCustomer,
      invoiceReason,
      paymentDate,
      paymentComment,
      beginDate,
      comment,
      staff,
    } = this.state;

    const {
      postInvoice,
      formTime,
      updateInvoice,
      retrievedInvoice,
      selectedCompany,
    } = this.props;

    if (!isEmpty(msgError)) {
      this.setState({
        messageError: msgError,
      });
      return;
    }

    data.customer = selectedCustomer;
    data.timeSpent = formTime;
    data.company = selectedCompany['@id'];

    data.content.messages.internalNotice = comment;

    if (paymentDate) {
      data.paymentDate = paymentDate.format();
    }

    if (paymentDate && !isEmpty(paymentComment)) {
      data.content.messages.paymentNotice = paymentComment;
    }

    if (beginDate) {
      data.beginDate = beginDate.format();
    }

    if (invoiceReason) {
      data.invoiceReason = invoiceReason;
    }

    if (!isEmpty(staff)) {
      data.staff = staff;
    }

    retrievedInvoice ? updateInvoice(retrievedInvoice, data) : postInvoice(data);
  };

  handleOnSubmit = (action) => {
    this.setState({
      validDocument: true,
      submitAction: action,
      hasDataChanged: false,
    });
  };

  onModelChange = (content) => {
    this.setState({
      validContent: false,
    });

    if (content.length > 0) {
      this.setState({
        warningContentModal: true,
      });
    } else {
      this.setModelContent();
    }
  };

  onWarningClose = () => {
    this.setState({
      selectedModel: null,
      warningContentModal: false,
    });
  };

  setModelContent = (append = false) => {
    const { selectedModel, modelList, documentData } = this.state;

    const model = find(modelList, keyVal('@id', selectedModel));

    let body = documentData.content ? documentData.content.body : [];
    if (append) {
      body.push(...model.content.body);
    } else {
      body = model.content ? model.content.body : [];
    }
    documentData.content = model.content;

    this.setState({
      documentData: {
        ...documentData,
        content: {
          ...documentData.content,
          body,
        },
      },
      warningContentModal: false,
    });
  };

  handleModelChange = (e, { value }) => {
    this.setState({
      selectedModel: value,
      validContent: true,
      hasDataChanged: true,
    });
  };


  handleAction = (type) => {
    const { printDocument, changeInvoice, selectedDocument } = this.props;

    if (selectedDocument) {
      if (type === 'edit') {
        changeInvoice(`/invoices/${selectedDocument.id}/to_draft`, 'PUT');
      } else if (type === 'print') {
        printDocument(`/invoices/${selectedDocument.id}/print`);
      }
    }
  };

  setComment = (editorContent) => {
    this.setState({
      paymentComment: editorContent,
    });
  };

  render() {
    const {
      status,
      documentData,

      warningContentModal,

      modelList,
      selectedModel,
      customerList,
      selectedCustomer,
      openCustomerModal,

      invoiceReason,
      paymentDate,
      beginDate,
      comment,
      paymentComment,
      staff,

      validDocument,
      validContent,

      isCreate,
      timeSpent,
      loadedInvoice,
      hasDataChanged,
      messageError,
      plannedHoursError,
    } = this.state;

    const {
      loadingPrintDocument,
      listCompanySettings,
      loadingListModel,
      loadingListCustomer,
      loadingCompanySettings,
      loadingRetrieveInvoice,
      loadingCreateInvoice,
      loadingUpdateInvoice,
      loadingChangeInvoice,
      match,
      t,
    } = this.props;

    const resetPaymentComment = () => {
      this.setPaymentComment(true);
    };

    const customers = getCustomerList(customerList);
    const models = getModelList(modelList);
    let customerObject = null;
    const offerReasons = getOfferReasons(listCompanySettings);

    if (selectedCustomer) {
      customerObject = find(customerList, {
        '@id': selectedCustomer,
      });
    }

    const isLoading = loadingCreateInvoice || loadingUpdateInvoice || loadingChangeInvoice;
    const formLoading = loadingRetrieveInvoice || loadingCreateInvoice || loadingUpdateInvoice;

    return (
      <ContainerGeneral prompt={hasDataChanged}>
        {(loadedInvoice || isCreate) && (
          <DocumentTimer
            isCreate={isCreate}
            timeSpentTimer={timeSpent}
            loadingQuote={loadingRetrieveInvoice}
          />
        )}

        <BackHeader
          title={match.params.id ? t('invoicesUpdateTitle') : t('invoicesCreateTitle')}
          to="/business/invoices/"
        />

        <ActionButtons
          isPending={isPending(documentData)}
          handleAction={this.handleAction}
          loadingEditDocument={loadingPrintDocument}
          loadingPrintDocument={loadingPrintDocument}
        />

        {isDraft(documentData) && (
          <TwelveForm loading={isLoading}>
            <Form.Group inline>
              <Form.Select
                label={t('formExistingCustomer')}
                control={Dropdown}
                placeholder={t('formPHSelect')}
                fluid
                search
                selection
                loading={loadingListCustomer}
                disabled={loadingListCustomer}
                noResultsMessage="No results"
                options={customers}
                name="selectedCustomer"
                onChange={this.handleSelectChange}
                value={selectedCustomer}
                selectOnBlur={false}
              />
            </Form.Group>

            <InlineField>
              <label>{t('formNewCustomer')}</label>
              <EssorButton
                icon
                type="plus"
                onClick={this.handleOpenCreateCustomer}
              />
            </InlineField>

            {customerObject && (
              <Form.Group inline>
                <Form.Select
                  label={t('quoteModel')}
                  control={Dropdown}
                  placeholder={t('formPHSelect')}
                  fluid
                  search
                  selection
                  loading={loadingListModel}
                  disabled={loadingListModel}
                  noResultsMessage="No results"
                  options={models}
                  name="selectedModel"
                  onChange={this.handleModelChange}
                  value={selectedModel}
                  selectOnBlur={false}
                />
              </Form.Group>
            )}
          </TwelveForm>
        )}

        <CreateCustomer
          modalOpen={openCustomerModal}
          onClose={this.closeCustomerModal}
          onSuccess={customer => this.customerCreateSuccess(customer)}
        />

        <ReplaceContentModal
          warningContentModal={warningContentModal}
          onWarningClose={this.onWarningClose}
          setModelContent={this.setModelContent}
        />

        <hr />

        {customerObject && (
          <React.Fragment>
            <Document
              type={INVOICE}
              status={status}
              documentData={documentData}
              customer={customerObject}
              validData={validDocument}
              validContent={validContent}
              getData={(documentData, errors) => this.onSubmit(documentData, errors)}
              getContent={content => this.onModelChange(content)}
              paymentDate={paymentDate}
            />

            {isNotDraft(documentData) && (
              <SmallForm loading={formLoading}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <Form.Group inline>
                        <Form.Select
                          label={t('formInvoiceReason')}
                          control={Dropdown}
                          placeholder={t('formPHSelect')}
                          fluid
                          search
                          selection
                          loading={loadingCompanySettings}
                          disabled={loadingCompanySettings}
                          noResultsMessage="No results"
                          options={offerReasons}
                          name="invoiceReason"
                          onChange={this.handleSelectChange}
                          value={invoiceReason}
                          selectOnBlur={false}
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Input
                          label={t('quotePaymentDate')}
                          name="paymentDate"
                          isClearable
                          control={DatePicker}
                          selected={paymentDate}
                          onChange={this.handlePaymentDateChange}
                          locale="fr"
                          autoComplete="off"
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.Input
                          label={t('formInvoiceBeginDate')}
                          name="beginDate"
                          isClearable
                          control={DatePicker}
                          selected={beginDate}
                          onChange={this.handleBeginDateChange}
                          locale="fr"
                          autoComplete="off"
                        />
                      </Form.Group>

                      <Form.Group inline>
                        <Form.TextArea
                          label={t('formComments')}
                          name="comment"
                          placeholder={t('formPHComments')}
                          value={comment}
                          onChange={e => this.handleInputChange(e)}
                        />
                      </Form.Group>

                      {paymentDate && (
                        <div className="margin-top">
                          <div className="option-buttons-container clearfix">
                            <Header as="h3">
                              {t('documentPaymentComment')}
                            </Header>
                          </div>
                          <Form.Group inline>
                            <Form.Field error={false}>
                              <label>{t('documentPaymentMethod')}</label>
                              <Input>
                                <TextEditor
                                  textArea
                                  editorLabel={paymentComment}
                                  setLabel={editorContent => this.setComment(editorContent)}
                                />
                              </Input>
                            </Form.Field>
                          </Form.Group>
                          <div className="m-t-10 t-a-r">
                            <EssorButton onClick={resetPaymentComment} type="undo" size="mini">
                              {t('buttonReinit')}
                            </EssorButton>
                          </div>
                        </div>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <SelectEmployees
                  staff={staff}
                  setStaff={this.setStaff}
                  setPlannedHoursError={this.setPlannedHoursError}
                />

              </SmallForm>
            )}

            <ErrorMessages messages={messageError} />

            <SaveButtons
              disabled={isLoading || plannedHoursError}
              loading={isLoading}
              onClick={this.handleOnSubmit}
              isDraft={isDraft(documentData)}
            />
          </React.Fragment>
        )}
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setInvoice: invoice => dispatch(updateInvoiceSuccess(invoice)),
  getModelList: page => dispatch(listModel(page)),
  postInvoice: data => dispatch(createInvoice(data)),
  updateInvoice: (item, data) => dispatch(updateInvoice(item, data)),
  changeInvoice: (route, method) => dispatch(changeInvoice(route, method)),
  printDocument: id => dispatch(printDocument(id)),
  resetPrint: () => dispatch(printReset()),
  setInvoiceList: invoices => dispatch(setInvoiceList(invoices)),
  selectDocument: document => dispatch(selectDocument(document)),
  reset: () => {
    dispatch(resetModel());
    dispatch(successInvoice(null));
    dispatch(loadingInvoice(false));
    dispatch(errorInvoice(null));
  },
});

const mapStateToProps = state => ({
  listInvoice: state.invoice.list.data,
  selectedCompany: state.userCompanies.select.selectedCompany,
  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedFiscalYear: state.userCompanies.select.selectedFiscalYear,

  listCompanySettings: state.companySettings.list.data,
  loadingCompanySettings: state.companySettings.list.loading,
  errorCompanySettings: state.companySettings.list.error,

  listCustomer: state.customer.list.data,
  loadingListCustomer: state.customer.list.loading,
  errorListCustomer: state.customer.list.error,

  listModel: state.model.list.data,
  loadingListModel: state.model.list.loading,
  errorListModel: state.model.list.error,

  listReceipt: state.receipt.list.data,

  createdInvoice: state.invoice.create.created,
  loadingCreateInvoice: state.invoice.create.loading,
  errorCreateInvoice: state.invoice.create.error,

  retrievedInvoice: state.invoice.update.retrieved,
  loadingRetrieveInvoice: state.invoice.update.retrieveLoading,
  errorRetrieveInvoice: state.invoice.update.retrieveError,
  updatedInvoice: state.invoice.update.updated,
  loadingUpdateInvoice: state.invoice.update.updateLoading,
  errorUpdateInvoice: state.invoice.update.updateError,

  changedInvoice: state.invoice.change.changed,
  loadingChangeInvoice: state.invoice.change.loading,

  CreatedReceipt: state.receipt.create.created,
  loadingCreateReceipt: state.receipt.create.loading,

  urlDocument: state.document.print.url,
  loadingPrintDocument: state.document.print.loading,

  formTime: state.counterForm.create.formTime,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(EditInvoice);

export default withTranslation()(withRouter(Main));
