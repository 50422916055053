import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { list, reset } from 'actions/stock-movement/list';

import ContainerGeneral from 'layouts/ContainerGeneral';
import { crudRights, Entities } from 'types/accessRights';
import Routes from 'routes/admin/articles/routes';
import useCompany from 'hooks/useCompany';
import CheckRoute from 'components/access/CheckRoute';
import RouteNotFound from 'routes/RouteNotFound';
import CreateMovement from './create';
import HomeMovement from './home';
import ShowMovement from './show';

const Movement = () => {
  const [, company] = useCompany();
  const dataMovement = useSelector(state => state.movement.list.data);
  const loadingMovement = useSelector(state => state.movement.list.loading);
  const updated = useSelector(state => state.movement.update.updated);
  const created = useSelector(state => state.movement.create.created);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(list(`/stock_movements?company=${company.id}`));
      return () => {
        dispatch(reset());
      };
    },
    [created, updated, company],
  );

  if (loadingMovement || isEmpty(dataMovement)) {
    return <ContainerGeneral status={loadingMovement} />;
  }

  return (
    <Switch>
      <Route
        exact
        entity={Entities.stockMovement}
        right={crudRights.read}
        path={Routes.listMovements}
        component={HomeMovement}
      />
      <CheckRoute
        exact
        entity={Entities.stockMovement}
        right={crudRights.create}
        path={Routes.createMovement}
        component={CreateMovement}
      />
      <CheckRoute
        exact
        entity={Entities.stockMovement}
        right={crudRights.update}
        path={Routes.editMovement}
        component={CreateMovement}
      />
      <Route
        exact
        entity={Entities.stockMovement}
        right={crudRights.read}
        path={Routes.showMovement}
        component={ShowMovement}
      />
      <RouteNotFound />
    </Switch>
  );
};

export default Movement;
