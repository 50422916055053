import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Form, Icon, Modal, Table } from 'semantic-ui-react';
import { toast } from 'components';
import Cleave from 'cleave.js/react';
import { inputFormat } from 'utils/formatter';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ModalButtons from 'components/ModalButtons';
import DeleteCellButton from 'components/buttons/DeleteCellButton';
import HeaderRow from 'layouts/table/HeaderRow';
import LeftCell from 'layouts/table/LeftCell';
import EditCellButton from 'components/buttons/EditCellButton';
import SmallForm from 'layouts/SmallForm';

class CompanyListEdit extends Component {
  state = {
    open: false,
    idModal: null,
    dataClone: null,
    disabledCheck: false,

    updated: false,
  };

  componentDidMount() {
    const { dataValue } = this.props;
    if (dataValue) {
      this.setState({
        dataValue,
      });

      Object.keys(dataValue[0]).map(item => (
        this.setState({
          [item]: item === 'id' ? null : '',
        })
      ));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataValue && !prevState.mounted) {
      return {
        mounted: true,
        dataValueLenght: nextProps.dataValue.length,
      };
    }

    if (prevState.updated) {
      nextProps.onEditList(prevState.dataValue, prevState.updated);
      return {
        updated: false,
      };
    }

    return null;
  }

  handleInputChange = (e) => {
    e.preventDefault();

    const { dataClone } = this.state;
    const { name, value } = e.target;
    dataClone[name] = value;
    this.setState({
      dataClone,
    });
  };

  handleCheckBoxChange = (e, value) => {
    e.preventDefault();

    const { dataClone } = this.state;
    const { name } = value;

    dataClone[name] = !dataClone[name];
    this.setState({
      dataClone,
    });
  };

  openModal = (e, index) => {
    const dataClone = Object.assign({}, e);

    if (dataClone.default) {
      this.setState({
        disabledCheck: true,
      });
    } else {
      this.setState({
        disabledCheck: false,
      });
    }

    this.setState({
      dataClone,
      open: true,
      idModal: index,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  deleteItem = (item, index) => {
    const { dataValue, t } = this.props;
    if (!item.default) {
      dataValue.splice(index, 1);
      this.setState({
        dataValue,
        updated: true,
      });
    } else {
      toast.warning(t('toastWarningUsed'));
    }
  };

  handleOnSubmit = () => {
    const { idModal, dataClone } = this.state;
    const { dataValue, keyWord } = this.props;

    if (dataClone && dataClone.default) {
      dataValue.forEach((item) => {
        item.default = false;
      });
    }

    const updateValueData = [...dataValue];
    updateValueData[idModal] = dataClone;

    if (!isEmpty(keyWord)) {
      updateValueData.forEach((item) => {
        item[keyWord] = inputFormat(item[keyWord]);
      });
    }

    this.setState({
      dataValue: updateValueData,
      open: false,
      updated: true,
    });
  };

  capitalizeLabel = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const { open, idModal, dataClone, disabledCheck } = this.state;
    const { t, dataValue, updateLoading, format, keyWord = '', align, inputsNumeric = [] } = this.props;

    let position;
    if (dataValue[0]) {
      const keys = Object.keys(dataValue[0]);

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === keyWord) {
          position = i;
        }
      }
    }

    const arrayPosition = inputsNumeric.map(num => parseFloat(num) + 1);
    return (
      <div className="clearfix margin-top">
        <div className="select-list margin-top">
          {!isEmpty(dataValue) && (
            <Table celled structured className="margin-bot">
              <HeaderRow>
                {Object.keys(dataValue[0]).map((item, idx) => (
                  item !== 'id'
                    ? <LeftCell key={idx} content={t(`form${this.capitalizeLabel(item)}`)} />
                    : null
                ))}
                <Table.HeaderCell colSpan={2} />
              </HeaderRow>
              <Table.Body>
                {dataValue.map((itemData, index) => (
                  <Table.Row key={index}>
                    {Object.values(dataValue[index]).map((item, index2) => (
                      !(typeof item === 'number') && typeof item !== 'boolean' ? (
                        <Table.Cell
                          key={index2}
                          className={Number(index2) === Number(position) && format ? align : ''}
                        >
                          {Number(index2) === Number(position) && format ? format.func(item) : item}
                        </Table.Cell>
                      ) : typeof item === 'boolean' ? (
                        <Table.Cell textAlign="center" key={index2}>
                          <Icon
                            color={item ? 'green' : 'red'}
                            name={item ? 'check' : 'close'}
                            size="large"
                          />
                        </Table.Cell>
                      ) : null
                    ))}
                    <EditCellButton onClick={() => this.openModal(itemData, index)} />
                    <DeleteCellButton onClick={() => this.deleteItem(itemData, index)} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>

        <Modal open={open} className="small-height">
          <Modal.Header content={t('companiesEditTitle')} />
          <Modal.Content scrolling>
            <Modal.Description>
              <SmallForm>
                {idModal !== null && (
                  Object.entries(dataClone).map((item, indexCl) => (
                    item[0] !== 'id' && item[0] !== 'default'
                      ? (arrayPosition.find(num => num === indexCl)
                        ? (
                          <Form.Group inline key={indexCl}>
                            <Form.Input label={t(`form${this.capitalizeLabel(item[0])}`)}>
                              <Cleave
                                value={inputFormat(item[1], true)}
                                name={item[0]}
                                placeholder={t(`form${this.capitalizeLabel(item[0])}`)}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                options={{
                                  numeralDecimalMark: ',',
                                  numeral: true,
                                  numeralDecimalScale: 2,
                                  numeralPositiveOnly: true,
                                }}
                              />
                            </Form.Input>
                          </Form.Group>
                        )
                        : (
                          <Form.Group inline key={indexCl}>
                            <Form.Input
                              label={t(`form${this.capitalizeLabel(item[0])}`)}
                              name={item[0]}
                              placeholder={t(`form${this.capitalizeLabel(item[0])}`)}
                              value={item[1]}
                              onChange={this.handleInputChange}
                            />
                          </Form.Group>
                        )
                      ) : item[0] === 'default' ? (
                        <Form.Group inline key={indexCl}>
                          <Form.Checkbox
                            label={t(`form${this.capitalizeLabel(item[0])}`)}
                            name={item[0]}
                            checked={item[1]}
                            value={item[1]}
                            disabled={disabledCheck}
                            onChange={this.handleCheckBoxChange}
                          />
                        </Form.Group>
                      ) : null
                  )))}
              </SmallForm>
            </Modal.Description>
          </Modal.Content>
          <ModalButtons
            disabled={updateLoading}
            cancel={this.closeModal}
            save={this.handleOnSubmit}
          />
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CompanyListEdit));
