import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EssorButton } from '../index';
import { ExtendProps } from '../essorButton';
import { crudRights } from '../../types/accessRights';

const CreateButton: FC<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();
  return (
    <EssorButton type="plus" size="tiny" right={crudRights.create} {...others}>
      {label || t('buttonCreate')}
    </EssorButton>
  );
};

export default CreateButton;
