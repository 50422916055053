import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EssorButton } from '../index';
import { ExtendProps } from '../essorButton';

const PrintButton: FunctionComponent<ExtendProps> = ({ label, ...others }) => {
  const { t } = useTranslation();
  return (
    <EssorButton type="print" size="tiny" {...others}>
      {label || t('buttonPrint')}
    </EssorButton>
  );
};

PrintButton.defaultProps = {
  disabled: false,
  floated: undefined,
  label: undefined,
};

export default PrintButton;
