import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { isInvoice } from '../utils/documentType';
import { create, success } from '../actions/work-order/create';
import { list as getList, reset as resetList } from '../actions/work-order/list';
import { close } from '../actions/modal/workOrder';
import { State } from '../types/store';
import { computeProductArray, computeSubmitData, getProductList, handleCheckBox, ProductSelectionProps, ProductSelectionState, ProductShell, updateDataTable } from '../utils/documentModalCommon';
import ProductSelectionModal from './ProductSelectionModal';
import { WorkOrder } from '../types/document';

class WorkOrderModal
  extends React.Component<ProductSelectionProps<WorkOrder>, ProductSelectionState> {
  state: ProductSelectionState = {
    addProducts: false,
    arrayProducts: [],
    dataTable: [],
    documents: [],
    documentsOut: [],
    errorSelectedProducts: false,
    selectedProducts: [],
  };

  componentDidMount(): void {
    const {
      getList,
      selectedDocument,
      selectedCompany,
    } = this.props;

    getList(`/work_orders?company=${selectedCompany.id}&${isInvoice(selectedDocument) ? 'invoice' : 'purchaseOrder'}=${selectedDocument.id}`);

    this.setState({
      arrayProducts: getProductList(selectedDocument),
    });
  }

  static getDerivedStateFromProps(
    nProps: ProductSelectionProps<WorkOrder>,
    pState: ProductSelectionState,
  ): Partial<ProductSelectionState> {
    if (!isEmpty(nProps.list) && nProps.list['hydra:member'] !== pState.documentsOut) {
      return {
        documents: nProps.list['hydra:member'],
        documentsOut: nProps.list['hydra:member'],
      };
    }

    // tslint:disable-next-line:no-null-keyword
    return {};
  }

  componentDidUpdate(
    prevProps: ProductSelectionProps<WorkOrder>,
    prevState: ProductSelectionState,
  ): void {
    const { success } = this.props;
    const { documents } = this.state;

    if (!isEmpty(success) && success !== prevProps.success) {
      this.refreshList();
    }

    if (documents && documents !== prevState.documents) {
      this.updateDocument();
    }
  }

  componentWillUnmount(): void {
    const { reset } = this.props;
    reset();
  }

  updateDocument = (): void => {
    const {
      documents,
      arrayProducts,
    } = this.state;

    const dataTable = updateDataTable(documents, arrayProducts, true);

    this.setState({
      dataTable,
    });
  };

  refreshList = (): void => {
    const { success } = this.props;
    const { documents } = this.state;
    const newData = documents.concat(success);

    this.setState({
      addProducts: false,
      selectedProducts: [],
      documents: newData,
    });
  };

  handleInputChange = (e: any, index: number, diff: string): void => {
    e.preventDefault();
    const { selectedProducts } = this.state;

    const products = computeProductArray(e.target.value, selectedProducts, index, diff);

    this.setState({
      selectedProducts: products,
    });
  };

  handleCheckBoxChange = (value: ProductShell): void => {
    const { selectedProducts } = this.state;

    this.setState({
      selectedProducts: handleCheckBox(selectedProducts, value),
    });
  };

  handleAddProducts = (): void => {
    this.setState((prevState: ProductSelectionState) => ({
      addProducts: !prevState.addProducts,
      errorSelectedProducts: false,
    }));
  };

  handleSubmit = (): void => {
    const { selectedProducts } = this.state;
    const {
      create,
      selectedDocument,
      selectedCompany,
    } = this.props;

    const result = selectedProducts.every((product: ProductShell) => !product.error);

    if (!result) {
      return;
    }

    const data = computeSubmitData(selectedProducts, selectedDocument, selectedCompany);

    create(data);

    this.setState({
      errorSelectedProducts: false,
      selectedProducts: [],
    });
  };

  handleSetCommentProduct = (editorState: string, id: number): void => {
    const { selectedProducts } = this.state;
    const newSelectedProducts = selectedProducts.map((product: ProductShell) => {
      if (product.id === id) {
        product.workOrderComment = editorState;
      }
      return product;
    });

    this.setState({
      selectedProducts: newSelectedProducts,
    });
  };

  render(): ReactNode {
    const {
      isOpen,
      loadingList,
      loadingCreate,
      closeSelf,
    } = this.props;

    const {
      documents,
      errorSelectedProducts,
      selectedProducts,
      addProducts,
      dataTable,
    } = this.state;

    return (
      <ProductSelectionModal
        addProducts={addProducts}
        data={documents}
        dataTable={dataTable}
        errorSelectedProducts={errorSelectedProducts}
        isOpen={isOpen}
        loadingCreate={loadingCreate}
        loadingList={loadingList}
        message="workOrderAllProducts"
        path="/business/work_orders"
        selectedProducts={selectedProducts}
        title="createWorkOrderPurchaseTitle"
        closeSelf={closeSelf}
        handleAddProducts={this.handleAddProducts}
        handleInputChange={this.handleInputChange}
        handleCheckBoxChange={this.handleCheckBoxChange}
        handleSetCommentProduct={this.handleSetCommentProduct}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any): any => ({
  create: (data: {}): void => dispatch(create(data)),
  getList: (uri: string): void => dispatch(getList(uri)),
  closeSelf: (): void => dispatch(close()),
  reset(): void {
    dispatch(resetList());
    // tslint:disable-next-line:no-null-keyword
    dispatch(success(null));
  },
});

const mapStateToProps = (state: State): Partial<ProductSelectionProps<WorkOrder>> => ({
  success: state.workOrder.create.created,
  loadingCreate: state.workOrder.create.loading,

  list: state.workOrder.list.data,
  loadingList: state.workOrder.list.loading,

  selectedDocument: state.userCompanies.select.selectedDocument,
  selectedCompany: state.userCompanies.select.selectedCompany,

  isOpen: state.modal.workOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderModal);
