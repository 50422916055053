import React, { FunctionComponent, SyntheticEvent } from 'react';
import { DropdownProps, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BigSource } from 'big.js';
import { Big } from 'utils/functions';
import FieldWithLabel from './FieldWithLabel';
import Float from '../float';
import Percentage from '../percentage';
import { productSoldOptions } from './documentOptions';
import { State } from '../../types/store';
import { Product } from '../../types/document';
import CurrencyCleave from '../input/CurrencyCleave';

interface OwnProps {
  editable: boolean;
  handler: (event: SyntheticEvent, data: DropdownProps) => void;
  item: Product;
  onChange: (rawValue: BigSource) => void;
}

type Props = OwnProps;

const ProductSecondLine: FunctionComponent<Props> = ({ editable, handler, item, onChange }) => {
  const { t } = useTranslation();
  const selectedCompany = useSelector((state: State) => state.userCompanies.select.selectedCompany);

  return (
    <React.Fragment>
      <FieldWithLabel label={t('quoteUnitCost')}>
        <CurrencyCleave value={item.unitCost} onChange={onChange} />
      </FieldWithLabel>
      {selectedCompany.calculationMode === 'coef'
        ? (
          <FieldWithLabel label={t('formSellingCoefficient')}>
            {/* CAREFUL IF UNCOMMENTING : NOT FOR ENSEMBLES
            <FloatInput
              handler={e => this.handleChangeItem(e, item, 'margin')}
              name="margin"
              value={item.margin}
              style={{
                maxWidth: '100px',
              }}
              displayOnly={!editable}
            > */}
            <p className="info-line">
              <Float value={item.margin} />
            </p>
            {/* </FloatInput> */}
          </FieldWithLabel>
        )
        : (
          <FieldWithLabel label={t('formMarginRate')}>
            {/* CAREFUL IF UNCOMMENTING : NOT FOR ENSEMBLES
            <PercentInput
              handler={e => this.handleChangeItem(e, item, 'margin')}
              name="margin"
              value={item.margin}
              style={{
                maxWidth: '100px',
              }}
              displayOnly={!editable}
            > */}
            <p className="info-line">
              {Big(item.price || 0).lt(0.01) ? '-' : (<Percentage value={item.margin} />)}
            </p>
            {/* </PercentInput> */}
          </FieldWithLabel>
        )}
      <FieldWithLabel label={t('quoteSoldAs')}>
        {editable
          ? (
            <Select
              compact
              style={{
                maxWidth: '120px',
              }}
              name="soldAs"
              value={item.soldAs}
              options={productSoldOptions}
              onChange={handler}
            />
          )
          : (
            <p className="info-line">
              {item.soldAs.toUpperCase()}
            </p>
          )
        }
      </FieldWithLabel>
    </React.Fragment>
  );
};

export default ProductSecondLine;
