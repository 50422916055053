import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CellButton, { ExtendProps } from './CellButton';
import { crudRights } from '../../types/accessRights';

const EditCellButton: FC<ExtendProps> = ({ tip, ...others }) => {
  const { t } = useTranslation();
  return (
    <CellButton icon="edit" tip={tip || t('edit')} {...others} right={crudRights.update} />
  );
};

export default EditCellButton;
