export function selectCompany(company) {
  return {
    type: 'USER_COMPANY_SELECT_COMPANY_SUCCESS', company,
  };
}

export function selectFiscalYear(fiscalYear) {
  return {
    type: 'USER_COMPANY_SELECT_FISCAL_YEAR_SUCCESS', fiscalYear,
  };
}

export function selectEmployee(employee) {
  return {
    type: 'USER_COMPANY_SELECT_EMPLOYEE_SUCCESS', employee,
  };
}

export function selectDocument(document) {
  return {
    type: 'USER_COMPANY_SELECT_DOCUMENT_SUCCESS', document,
  };
}

export function selectOffice(office) {
  return {
    type: 'USER_COMPANY_SELECT_OFFICE_SUCCESS', office,
  };
}

export function selectTheme(theme) {
  window.location = window.location;
  return {
    type: 'USER_COMPANY_SELECT_THEME', theme,
  };
}
