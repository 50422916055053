import React from 'react';
import { Form } from 'semantic-ui-react';
import Info from 'layouts/Info';

function KeyValue(props) {
  const { children, label } = props;

  return (
    <Form.Group inline>
      <Form.Field>
        {label && (<label>{label}</label>)}
        <Info>{children}</Info>
      </Form.Field>
    </Form.Group>
  );
}

export default KeyValue;
