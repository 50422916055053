import React, { FC, Fragment } from 'react';
import { Checkbox, Input, Table } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import { CleaveOptions } from 'cleave.js/options';
import DraftEditor from './draftEditor/DraftEditor';
import { ProductShell } from '../utils/documentModalCommon';
import { getLocalFormat, inputFormat } from '../utils/formatter';
import Float from './float';
import { TextEditor } from './index';
import CenterCell from '../layouts/table/CenterCell';

interface OwnProps {
  value: ProductShell;
  selectedProducts: ProductShell[];
  setQuantity: any;
  selectProduct: any;
  setComment: any;
}

type Props = OwnProps;

const ProductSelectionItem: FC<Props> = (props) => {
  const {
    value,
    selectedProducts,
    setQuantity,
    selectProduct,
    setComment,
  } = props;

  const placeHolder = {
    error: false,
    quantity: 0,
  };

  const check = (value: ProductShell): void => {
    selectProduct(value);
  };

  const findIndex = selectedProducts.findIndex((prod: ProductShell) => prod.index === value.index);
  const findProduct = selectedProducts.find((product: ProductShell) => product.id === value.id);
  const curProduct = findProduct || placeHolder;

  return (
    <Fragment>
      <Table.Row>
        <Table.Cell>
          <DraftEditor
            editorLabel={value.label || ''}
            setLabel={(e): void => undefined}
            editorOnlyShow
          />
        </Table.Cell>
        <Table.Cell>{value.type}</Table.Cell>
        <Table.Cell className="format-number-fr box-width">
          {findIndex !== -1
            ? (
              <div className="box-flex-items">
                <Input fluid className="default-cell" error={curProduct.error}>
                  <Cleave
                    options={getLocalFormat() as CleaveOptions}
                    value={inputFormat(curProduct.quantity, true)}
                    onChange={
                      (e: React.ChangeEvent): void => setQuantity(
                        e, value.index, inputFormat(value.quantity),
                      )
                    }
                  />
                </Input>
                <label>/</label>
                <label><Float value={value.quantity} /></label>
              </div>
            )
            : <Float value={value.quantity} />
          }
        </Table.Cell>
        <CenterCell rowSpan={findProduct && 2}>
          <Checkbox
            name={`check${value.id}`}
            checked={findIndex !== -1}
            onChange={(): void => { check(value); }}
          />
        </CenterCell>
      </Table.Row>
      {findProduct && (
        <Table.Row>
          <Table.Cell colSpan="3">
            <TextEditor
              editorLabel=""
              setLabel={(editorState: string): void => { setComment(editorState, value.id); }}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </Fragment>
  );
};

export default ProductSelectionItem;
