import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { success as successListService } from 'actions/service/list';
import { del as deleteService, error as errorDelete } from 'actions/service/delete';
import { isEmpty } from 'lodash';
import { DeleteConfirmation, TableList } from 'components';
import { withTranslation } from 'react-i18next';
import { Entities } from 'types/accessRights';
import ContainerGeneral from 'layouts/ContainerGeneral';
import AddHeader from 'components/pageHeaders/AddHeader';
import Routes from 'routes/admin/articles/routes';
import { backToHome } from 'utils/constants';
import { columnDefinition } from '../../../../../utils/tables';

class HomeService extends Component {
  state = {
    data: {},
    warningMessage: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && nextProps.data['hydra:member'] !== prevState.data) {
      return {
        data: nextProps.data['hydra:member'],
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedService, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.closeDeleteModal();
      resetError();
    }

    if (!isEmpty(deletedService)
      && deletedService !== prevProps.deletedService) {
      this.updateList();
    }
  }

  updateList = () => {
    const { data, setServiceList, deletedService } = this.props;
    const arr = data['hydra:member'].filter(service => service['@id'] !== deletedService['@id']);

    const newList = {
      ...data,
      'hydra:member': arr,
    };

    setServiceList(newList);
    this.closeDeleteModal();
  };

  handleDelete = () => {
    const { deleteItem } = this.state;
    const { deleteService } = this.props;
    deleteService(deleteItem);
  };

  showDeleteModal = (item) => {
    this.setState({
      deleteItem: item,
      warningMessage: true,
      itemName: item.label,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteItem: null,
      warningMessage: false,
      itemName: '',
    });
  };

  render() {
    const { data, warningMessage, itemName } = this.state;
    const { loading, loadingDelete, t, history } = this.props;

    const columns = [
      columnDefinition('formLabel', 'label'),
      columnDefinition('formUnit', 'unit'),
      columnDefinition('formSellingPrice', 'unitPrice', 'currency'),
    ];

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('servicesHomeTitle')}
          to={Routes.createService}
          entity={Entities.service}
        />
        <TableList
          columns={columns}
          filterBtn
          exportBtn
          loading={loading}
          data={data}
          onView={item => history.push(`/articles${item['@id']}`)}
          onEdit={item => history.push(`/articles${item['@id']}/edit`, backToHome)}
          onDelete={this.showDeleteModal}
          entity={Entities.service}
        />

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDelete}
          onClose={this.closeDeleteModal}
          onDelete={this.handleDelete}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteService: id => dispatch(deleteService(id)),
  resetError: () => dispatch(errorDelete(null)),
  setServiceList: list => dispatch(successListService(list)),
});

const mapStateToProps = state => ({
  data: state.service.list.data,
  loading: state.service.list.loading,
  error: state.service.list.error,

  deletedService: state.service.del.deleted,
  loadingDelete: state.service.del.loading,
  errorDelete: state.service.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(HomeService);

export default withTranslation()(withRouter(Main));
