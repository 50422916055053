import React, { FC } from 'react';
import { Input, Label } from 'semantic-ui-react';
import InlineField from './InlineField';
import CurrencyCleave, { CurrencyCleaveProps } from '../components/input/CurrencyCleave';
import { getCurrency } from '../utils/formatter';
import { Translation } from '../hooks/useTrans';

interface OwnProps extends CurrencyCleaveProps {
  error?: boolean;
  style?: {};
  title: Translation;
}

type Props = OwnProps;

const InlineCurrencyInput: FC<Props> = ({ title, error, style, ...others }) => (
  <InlineField error={error}>
    <label>{title}</label>
    <Input size="small" fluid labelPosition="right" error={error} style={style}>
      <CurrencyCleave {...others} />
      <Label>{getCurrency()}</Label>
    </Input>
  </InlineField>
);

export default InlineCurrencyInput;
