import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { crudRights, Entities } from 'types/accessRights';
import Routes from 'routes/admin/contacts/routes';

import SubNav from 'layouts/subNav/SubNav';
import SubNavTitle from 'layouts/subNav/SubNavTitle';
import RouteNotFound from 'routes/RouteNotFound';
import Suppliers from 'routes/admin/contacts/supplier';
import Recipients from 'routes/admin/contacts/recipients';
import SubNavLinkWithButton from 'layouts/subNav/subNavLinkWithButton';
import CheckRights from 'components/access/CheckRights';
import HomeContacts from 'routes/admin/contacts/home';

const Contacts = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <SubNav>
        <SubNavTitle title={t('contacts')} />
        <CheckRights entity={Entities.customer} right={crudRights.read}>
          <SubNavLinkWithButton
            to={Routes.listRecipients}
            create={Routes.createRecipient}
            entity={Entities.customer}
            right={crudRights.create}
            title={t('contactsRecipient')}
          />
        </CheckRights>
        <CheckRights entity={Entities.supplier} right={crudRights.read}>
          <SubNavLinkWithButton
            to={Routes.listSuppliers}
            create={Routes.createSupplier}
            entity={Entities.supplier}
            right={crudRights.create}
            title={t('contactsSupplier')}
          />
        </CheckRights>
      </SubNav>

      <Switch>
        <Route
          exact
          path={Routes.home}
          component={HomeContacts}
        />
        <Route
          path={Routes.listRecipients}
          component={Recipients}
          entity={Entities.customer}
          right={crudRights.read}
        />
        <Route
          path={Routes.listSuppliers}
          component={Suppliers}
          entity={Entities.supplier}
          right={crudRights.read}
        />
        <RouteNotFound />
      </Switch>

    </React.Fragment>
  );
};

export default Contacts;
