import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const FullPageLoader: FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Segment className="full-page-loader">
        <Dimmer active>
          <Loader size="massive">{t('loginCheck')}</Loader>
        </Dimmer>
      </Segment>
    </React.Fragment>
  );
};

export default FullPageLoader;
