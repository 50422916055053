import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import CheckRoute from 'components/access/CheckRoute';
import { crudRights, Entities } from 'types/accessRights';
import ShowProfileRole from './show';
import EditProfileRole from './edit';
import NotFound from '../../../404';

const HomeProfileRole = () => {
  const employee = useSelector(state => state.userCompanies.select.selectedEmployee);

  if (isEmpty(employee) || isEmpty(employee.identity)) {
    return (
      <div className="section-container">
        <NotFound />
      </div>
    );
  }

  return (
    <Switch>
      <CheckRoute
        exact
        path="/employees/:id/roles"
        component={ShowProfileRole}
        entity={Entities.identity}
        right={crudRights.read}
      />
      <CheckRoute
        exact
        path="/employees/:id/roles/edit"
        component={EditProfileRole}
        entity={Entities.identity}
        right={crudRights.update}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default HomeProfileRole;
