import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { success as successListTurnover } from 'actions/turnover-balance/list';
import { del as deleteTurnover, error as errorDelete } from 'actions/turnover-balance/delete';
import { isEmpty } from 'lodash';
import { DeleteConfirmation } from 'components';
import AddHeader from 'components/pageHeaders/AddHeader';
import ContainerGeneral from 'layouts/ContainerGeneral';
import { Entities } from 'types/accessRights';
import AdvancedList from '../../../../../components/advancedList/AdvancedList';
import { columnDefinition } from '../../../../../utils/tables';

class ListTurnoverBalance extends React.Component {
  state = {
    data: [],
    warningMessage: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.data) && nextProps.data['hydra:member'] !== prevState.data) {
      return {
        data: nextProps.data['hydra:member'],
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { deletedTurnover, errorDelete, resetError } = this.props;

    if (!isEmpty(errorDelete)
      && errorDelete !== prevProps.errorDelete) {
      this.closeDeleteModal();
      resetError();
    }

    if (!isEmpty(deletedTurnover) && deletedTurnover !== prevProps.deletedTurnover) {
      this.updateList(deletedTurnover);
    }
  }

  updateList = (deletedTurnover) => {
    const { data, setTurnoverList } = this.props;
    const arr = data['hydra:member'].filter(turnover => turnover['@id'] !== deletedTurnover['@id']);

    const newList = {
      ...data,
      'hydra:member': arr,
    };

    setTurnoverList(newList);
    this.closeDeleteModal();
  };

  onView = (turnover) => {
    const { history } = this.props;
    history.push(`/forecast/turnover-balance/${turnover.id}`);
  };

  handleDelete = () => {
    const { deleteItem } = this.state;
    const { deleteTurnover } = this.props;
    deleteTurnover(deleteItem);
  };

  showDeleteModal = (item) => {
    this.setState({
      deleteItem: item,
      warningMessage: true,
      itemName: `${item.startDate} - ${item.endDate}`,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteItem: null,
      warningMessage: false,
      itemName: '',
    });
  };

  render() {
    const { data, warningMessage, itemName } = this.state;
    const { t, loadingTurnover, loadingDelete, history } = this.props;

    return (
      <ContainerGeneral>
        <AddHeader
          title={t('turnoverBalanceTitle')}
          to="/forecast/turnover-balance/create"
          entity={Entities.turnoverBalance}
        />

        <AdvancedList
          columns={[
            columnDefinition('formDateStart', 'startDate', 'date'),
            columnDefinition('formDateEnd', 'endDate', 'date'),
            columnDefinition('formAmount', 'totalTurnover', 'currency'),
          ]}
          loading={loadingTurnover}
          data={data}
          onView={this.onView}
          onEdit={turnover => history.push(`/forecast/turnover-balance/${turnover.id}/edit`)}
          onDelete={this.showDeleteModal}
          entity={Entities.turnoverBalance}
        />

        <DeleteConfirmation
          show={warningMessage}
          name={itemName}
          loading={loadingDelete}
          onClose={this.closeDeleteModal}
          onDelete={this.handleDelete}
        />
      </ContainerGeneral>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteTurnover: id => dispatch(deleteTurnover(id)),
  resetError: () => dispatch(errorDelete(null)),
  setTurnoverList: list => dispatch(successListTurnover(list)),
});

const mapStateToProps = state => ({
  selectedCompany: state.userCompanies.select.selectedCompany,
  data: state.turnoverBalance.list.data,
  loadingTurnover: state.turnoverBalance.list.loading,

  deletedTurnover: state.turnoverBalance.del.deleted,
  loadingDelete: state.turnoverBalance.del.loading,
  errorDelete: state.turnoverBalance.del.error,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ListTurnoverBalance);

export default withTranslation()(withRouter(Main));
