const SET_WORK_ORDER_MODAL = 'MODAL/SET_WORK_ORDER_MODAL';

const workOrder = (state = false, action: any) => {
  if (action.type === SET_WORK_ORDER_MODAL) {
    return action.visibility;
  }
  return state;
};

export default workOrder;
