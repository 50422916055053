import { SubmissionError } from 'redux-form';
import { toast } from 'components';
import i18n from 'i18n';
import fetch from '../../utils/fetch';

export function error(error) {
  return {
    type: 'QUOTE_CHANGE_ERROR', error,
  };
}

export function loading(loading) {
  return {
    type: 'QUOTE_CHANGE_LOADING', loading,
  };
}

export function success(changed) {
  return {
    type: 'QUOTE_CHANGE_SUCCESS', changed,
  };
}

export function change(route, method, body) {
  return (dispatch) => {
    dispatch(loading(route));

    setTimeout(() => fetch(route, {
      method,
      body: JSON.stringify(body || {}),
    })
      .then((response) => {
        dispatch(loading(false));

        return response.json();
      })
      .then((data) => {
        dispatch(success(data));
      })
      .catch((e) => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }
        toast.error(i18n.t('toastError'));
        dispatch(error(e.message));
      }), 2000);
  };
}

export function reset() {
  return {
    type: 'QUOTE_CHANGE_RESET',
  };
}
