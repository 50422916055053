import React, { FunctionComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import SaveButton from './buttons/SaveButton';
import CancelButton from './buttons/CancelButton';

interface OwnProps {
  disabled?: boolean;
  cancel?: () => void;
  save?: () => void;
}

type Props = OwnProps;

const ModalButtons: FunctionComponent<Props> = ({ disabled, cancel, save }) => (
  <Modal.Actions>
    {cancel && <CancelButton onClick={cancel} disabled={disabled} />}
    {save && <SaveButton onClick={save} disabled={disabled} />}
  </Modal.Actions>
);

export default ModalButtons;
